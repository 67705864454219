import React from 'react'
import SalaryComment from './SalaryComment'

const SalaryInfo = props => {
  return (
    <div
      style={{
        overflow: 'hidden',
        padding: '10px',
        backgroundColor: '#f4f3f8',
        borderRadius: 10,
      }}>
      {/*<div style={{height: 50, background: 'red'}}/>*/}
      <div
        style={{
          marginTop: 15,
          backgroundColor: '#fff',
          padding: '0 16px 16px',
          boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
        }}>
        <SalaryComment />
      </div>
    </div>
  )
}

SalaryInfo.propTypes = {}

export default SalaryInfo

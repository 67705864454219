import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const ChuTruongRequest = {
  getPolicyList: (page, size, status, permission, title, numOfStep) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/policy/current-user`,
      params: {
        page: page,
        size: size,
        ...(!utils.isNullish(status) && { status: status }),
        ...(!utils.isNullish(permission) && { permission: permission }),
        ...(!utils.isNullish(title) && { title: title }),
        ...(!utils.isNullish(numOfStep) && { numOfStep: numOfStep }),
      },
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  createPolicy: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/policy`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  getPolicyDetail: taskCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/policy/${taskCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  deletePolicy: taskCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/policy/${taskCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  changeStep: (taskCode, userName) =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/policy/${taskCode}/step`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        user_name: userName,
      },
    }),

  updatePolicyAssignees: (policyCode, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/policy/${policyCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
}

import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

const source = axios.CancelToken.source()
const apiUrlFake = 'https://json-server-demo-tho.herokuapp.com'

export const requests = {
  cancelRequest: () => {
    source.cancel()
  },

  get: (url, params = false, search) => {
    if (params) {
      return axios({
        method: 'get',
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(authenticationStore.appToken).access_token
          }`,
          'Content-Type': 'application/json',
        },
        params: search,
        cancelToken: source.token,
      })
    }
    return axios({
      method: 'get',
      url: `${apiUrl}${url}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      cancelToken: source.token,
    })
  },
  post: (url, body) => {
    return axios({
      method: 'post',
      url: `${apiUrl}${url}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: body,
      cancelToken: source.token,
    })
  },
  delete: url => {
    return axios({
      method: 'delete',
      url: `${apiUrl}${url}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      cancelToken: source.token,
    })
  },
  put: (url, body) => {
    return axios({
      method: 'put',
      url: `${apiUrl}${url}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: body,
      cancelToken: source.token,
    })
  },
  patch: (url, body) => {
    return axios({
      method: 'patch',
      url: `${apiUrl}${url}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: body,
      cancelToken: source.token,
    })
  },
}

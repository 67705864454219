import { action, observable } from 'mobx'
// Request
import { WorkScheduleRequest } from '../requests/WorkScheduleRequest'

class WorkScheduleStore {
  /** Work schedule list */
  @observable workScheduleList = []

  /** Work schedule detail */
  @observable workScheduleDetail = {}

  /** Get work schedule */
  @action getWorkSchedule = async (fromDate, toDate) => {
    const response = await WorkScheduleRequest.getWorkSchedule(fromDate, toDate)
    this.workScheduleList = response.data
    return response.data
  }

  /** Get work schedule */
  @action getPublicWorkSchedule = async (fromDate, toDate) => {
    const response = await WorkScheduleRequest.getPublicWorkSchedule(
      fromDate,
      toDate
    )
    return response.data
  }

  /** Get work schedule detail */
  @action getWorkScheduleDetail = scheduleCode => {
    return new Promise((resolve, reject) => {
      WorkScheduleRequest.getWorkScheduleDetail(scheduleCode)
        .then(response => {
          this.workScheduleDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Update work schedule */
  @action updateWorkSchedule = (scheduleCode, formData) => {
    return new Promise((resolve, reject) => {
      WorkScheduleRequest.updateWorkSchedule(scheduleCode, formData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Update work schedule detail */
  @action updateWorkScheduleDetail = detail => {
    this.workScheduleDetail = detail
  }

  /** Create work schedule */
  @action createWorkSchedule = submitData => {
    return new Promise((resolve, reject) => {
      WorkScheduleRequest.createWorkSchedule(submitData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Delete work schedule */
  @action delete = scheduleCode => {
    return new Promise((resolve, reject) => {
      WorkScheduleRequest.delete(scheduleCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear schedule */
  @action clearWorkSchedule = () => {
    this.workScheduleList.length = 0
  }

  /** Clear schedule detail */
  @action clearWorkScheduleDetail = () => {
    this.workScheduleDetail = {}
  }
}

export default new WorkScheduleStore()

import React, { useCallback, useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import ProposalAdvanceFilter from './ProposalAdvanceFilter'
import { Button, message } from 'antd'
import ProposalAdvanceList from './ProposalAdvanceList'
import { PlusOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import ProposalAdvanceCreateModal from './ProposalAdvanceCreateModal'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'

const ProposalAdvancePage = props => {
  const { proposalAdvanceStore } = props
  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search)
  const [
    isVisibleProposalAdvanceCreateModal,
    setIsVisibleProposalAdvanceCreateModal,
  ] = useState(false)

  const [isLoadingProposalAdvance, setIsLoadingProposalAdvance] = useState(
    false
  )
  const [accessRenderModal, setAccessRenderModal] = useState(false)
  const [isRefreshPage, setIsRefreshPage] = useState(true)

  const loadProposalAdvanceList = async () => {
    const queryParams = {
      page: proposalAdvanceStore.filterPage,
      size: proposalAdvanceStore.filterSize,
      proposal_permission: proposalAdvanceStore.filterProposalPermission,
      keyword: proposalAdvanceStore.filterKeyword,
      proposal_status: proposalAdvanceStore.filterProposalStatus,
    }
    try {
      setIsLoadingProposalAdvance(true)
      await proposalAdvanceStore.getProposalAdvanceList()
      const queryStringStringify = queryString.stringify(queryParams, {
        skipNull: true,
      })
      history.replace(`/proposal-advance?${queryStringStringify}`)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingProposalAdvance(false)
    }
  }

  useEffect(() => {
    proposalAdvanceStore.setQueryParams(
      'filterPage',
      queryStringParse.page ? parseInt(queryStringParse.page) : 0
    )
    proposalAdvanceStore.setQueryParams(
      'filterSize',
      queryStringParse.size ? parseInt(queryStringParse.size) : 10
    )
    proposalAdvanceStore.setQueryParams(
      'filterProposalPermission',
      queryStringParse.proposal_permission
    )
    proposalAdvanceStore.setQueryParams(
      'filterKeyword',
      queryStringParse.keyword
    )
    proposalAdvanceStore.setQueryParams(
      'filterProposalStatus',
      queryStringParse.proposal_status
    )
    ;(async () => {
      await loadProposalAdvanceList()
      setAccessRenderModal(true)
      setIsRefreshPage(false)
    })()
    return () => {
      proposalAdvanceStore.clearProposalAdvanceList()
      proposalAdvanceStore.clearQueryParams()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    ;(async () => {
      await loadProposalAdvanceList()
    })()
  }, [
    proposalAdvanceStore.filterPage,
    proposalAdvanceStore.filterSize,
    proposalAdvanceStore.filterProposalPermission,
    proposalAdvanceStore.filterKeyword,
    proposalAdvanceStore.filterProposalStatus,
  ])

  const handleOpenProposalAdvanceCreateModal = () => {
    setIsVisibleProposalAdvanceCreateModal(true)
  }
  const handleCancelProposalAdvanceCreateModal = useCallback(() => {
    setIsVisibleProposalAdvanceCreateModal(false)
  }, [])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạm ứng | VIMC Tạm ứng</title>
      </Helmet>
      <PageTitle location={props.location}>
        <AccessControlAction
          aclActionType={ACL_ACTION_TYPE.create__PROPOSAL_ADVANCE}>
          <Button
            onClick={handleOpenProposalAdvanceCreateModal}
            type={'primary'}
            icon={<PlusOutlined />}>
            Tạo mới
          </Button>
        </AccessControlAction>
      </PageTitle>
      <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
        <ProposalAdvanceFilter />
        <ProposalAdvanceList isLoading={isLoadingProposalAdvance} />
      </ContentBlockWrapper>
      {accessRenderModal && (
        <ProposalAdvanceCreateModal
          handleCloseModal={handleCancelProposalAdvanceCreateModal}
          isModalVisible={isVisibleProposalAdvanceCreateModal}
        />
      )}
    </DashboardLayout>
  )
}

ProposalAdvancePage.propTypes = {}

export default inject('proposalAdvanceStore')(observer(ProposalAdvancePage))

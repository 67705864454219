import React, { useEffect } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import validator from '../../../validator'
import createISOFolderStore from './createISOFolderStore'
import { observer } from 'mobx-react'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'

const CreateISOFolderModal = props => {
  const { visible, parentFolderId, onClose } = props
  const { isLoading } = createISOFolderStore
  const [form] = Form.useForm()

  useEffect(() => {
    console.log('isLoading', isLoading)

    return () => {
      createISOFolderStore.clearStore()
    }
  }, [])

  const onFinish = async values => {
    console.log('onFinish', values)
    const { folder_name } = values

    await createISOFolderStore.create(folder_name, parentFolderId)

    closeAndResetForm()
  }

  const closeAndResetForm = () => {
    form.resetFields()
    onClose()
  }

  return (
    <>
      <Modal
        footer={null}
        closable={true}
        visible={visible}
        onCancel={() => {
          closeAndResetForm()
        }}
        title="Tạo thư mục">
        <Form form={form} layout={'vertical'} onFinish={onFinish}>
          <Form.Item
            name={'folder_name'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên thư mục!' },
              { validator: validator.validateInputString },
            ]}
            label={'Tên thư mục:'}>
            <Input placeholder={'Nhập tên thư mục'} />
          </Form.Item>

          <Form.Item>
            <Button
              icon={<PlusOutlined />}
              loading={isLoading}
              htmlType={'submit'}
              type={'primary'}>
              Tạo
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default observer(CreateISOFolderModal)

import styled, { css } from 'styled-components'
import { blue } from '../../../color'
import { Drawer, List } from 'antd'

export const DrawerWrapper = styled(Drawer)`
  .ant-drawer-content-wrapper {
  }
  .ant-drawer-content,
  .ant-drawer-body {
    overflow: unset;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .spin {
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .star-icon {
    padding: 0px;
    cursor: pointer;
    &:hover {
      .anticon {
        transition: 0.3s;
        transform: scale(1.2);
        color: #fadb14;
      }
    }
    .anticon {
      color: #ffec3d;
      font-size: 20px;
    }
  }
`

export const ContentWrapper = styled.div`
  figure {
    width: unset !important;
  }

  margin-top: 14px;
`

export const HeaderDrawer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  background-color: #fff;
`

export const InfoDrawer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0 0 3px 0;
`

export const InfoDrawerLeftWrapper = styled.div`
  flex-grow: 1;
  height: 100vh;
  border-right: 1px solid #f0f0f0;

  .work-parent {
    display: block;
    margin: 10px 15px;
  }

  .work-parent:hover {
    text-decoration: underline;
  }
`

export const InfoDrawerLeft = styled.div`
  padding: 0px 15px;
  //height: calc(100% - 0px - 43px - 55px);
  //overflow: auto;
`

export const InfoDrawerRightWrapper = styled.div`
  overflow: auto;
  ${props =>
    props.isMobile
      ? css`
          height: auto;
        `
      : css`
          height: calc(100vh - 50px);
          flex: 0 0 255px;
          width: 255px;
          padding: 10px;
        `}
`
export const InfoDrawerRight = styled.dl`
  //display: flex;
  //flex-wrap: wrap;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: -10px;
  }

  dt {
    font-weight: 700;
    padding-right: 10px;
    margin-bottom: 4px;

    &:after {
      display: inline-block;
      content: ':';
    }
  }

  dd {
    word-break: break-all;
    margin-bottom: 15px;

    a:hover {
      text-decoration: underline;
    }
  }

  .col-date {
    display: flex;
    flex: 0 0 50%;
    width: 50%;
  }

  .row-date {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
  }
`

export const UpdateVotePopover = styled.div`
  text-align: center;
  .content-title {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .content-body {
    min-width: 200px;
    display: flex;
    & > div {
      width: 50%;
      cursor: pointer;
    }
  }
  .thumb-up {
    color: rgb(58, 175, 80);
  }
  .thumb-down {
    color: red;
  }
`
export const SubWork = styled.div`
  .ant-list-item {
    &:hover {
      background-color: #fff1c5;
      transition: 0.3s;
    }
  }
`

export const SubWorkTitle = styled.b`
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  //padding: 10px;
  //background-color: #E9F0FD;
`
export const CommentTitle = styled.div`
  font-weight: bold;
  margin: 14px 0;
`

export const CommentBlock = styled.div`
  padding: 15px 0;
  // min-height: 162px;
  background-color: #ffffff;
  // position: fixed;
  bottom: -20px;
  ${props =>
    props.isMobile
      ? css`
          left: 50px;
          right: 10px;
        `
      : css`
          right: 255px;
        `}
  margin: 0;
`

export const PopoverList = styled.ul`
  ::-webkit-scrollbar {
    width: 4px;
  }

  min-width: 300px;
  max-width: 450px;
  list-style: none;
  max-height: 600px;
  margin: -1rem;
  padding: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;

  .popover-head {
    display: flex;
    justify-content: space-between;
    margin: -1rem -1rem 10px -1rem;
    padding: 10px 15px;
    border-bottom: 1px solid #f2f2f2;
    color: ${blue};
    column-gap: 20px;
    background-color: #f0f0f0;
  }

  li {
    border-bottom: 1px solid #f0f0f0;
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;

    span {
      flex-grow: 1;
    }

    time {
      flex: 0 0 130px;
      width: 130px;
    }
  }
`

export const ListSubWork = styled(List)`
  .ant-list-item {
    padding: 5px !important;
    border-bottom: unset !important;
    &:first-child {
      margin-top: 5px !important;
    }
    &:last-child {
      margin-bottom: 10px !important;
    }
  }
`

export const HeadingButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //margin-top: 5px;
  .btn-style {
    color: #1890ff;
    width: 32px;
    height: 32px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 5px;
    background: rgb(255, 255, 255);
    cursor: pointer;

    &:hover {
      transition: 0.2s;
      opacity: 0.9;
    }
  }
`

export const ButtonCloseDrawer = styled.div`
  width: 43px;
  height: 43px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ff1518;
`

export const SpinWrapper = styled.div``

export const OpinionItem = styled.div`
  line-height: 22px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d6d64f;
  padding: 4px 0;
  margin-right: 20px;
  cursor: pointer;
  span {
    margin-right: 4px;
  }
  :last-child {
    border: none;
  }
  .vote-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    width: 38px;
    div {
      font-size: 8px;
      margin-top: -4px;
      font-weight: 600;
    }
  }
  .thumb-up {
    color: rgb(58, 175, 80);
  }
  .thumb-down {
    color: red;
  }
  .empty-circle {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid #6a6363;
    margin-right: 8px;
    display: inline-block;
  }
`

import { observable } from 'mobx'
import { ISODocumentRequest } from '../../../requests/ISODocumentRequest'
import isoDocumentStore from '../isoDocumentStore'

class CreateISOFolderStore {
  @observable isLoading = false

  create = async (newFolderName, parentFolderId) => {
    this.isLoading = true

    await ISODocumentRequest.createFolder(newFolderName, parentFolderId)

    this.isLoading = false

    isoDocumentStore.getItems()
  }

  clearStore = () => {
    this.isLoading = false
  }
}

export default new CreateISOFolderStore()

import React, { useState } from 'react'
import { Button, message } from 'antd'
import { ShareAltOutlined } from '@ant-design/icons'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

const ModuleHeader = props => {
  const { moduleStore } = props

  const [isUpdating, setIsUpdating] = useState(false)
  const handleUpdateModule = async () => {
    const payload = []
    moduleStore.moduleUpdatedList.forEach(item => {
      payload.push({
        ...item,
        sub_modules: null,
      })
      if (item.sub_modules?.length > 0) {
        item.sub_modules.forEach(el => {
          payload.push(toJS(el))
        })
      }
    })
    try {
      setIsUpdating(true)
      await moduleStore.updateModules(payload)
      await moduleStore.getModuleList()
      message.success('Bạn đã cập nhập module hệ thống!')
    } catch (err) {
      console.log(err)
      message.error(err.vi | 'Đã có lỗi xảy ra!')
    } finally {
      setIsUpdating(false)
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
        textTransform: 'uppercase',
      }}>
      <h3 style={{ fontSize: 18, marginBottom: 0 }}>Quản lý module hệ thống</h3>
      <Button
        icon={<ShareAltOutlined />}
        type={'primary'}
        onClick={handleUpdateModule}
        loading={isUpdating}>
        LƯU LẠI
      </Button>
    </div>
  )
}

ModuleHeader.propTypes = {}
export default inject('moduleStore')(observer(ModuleHeader))

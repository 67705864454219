import { action, observable } from 'mobx'
import { ConsultRequest } from '../requests/ConsultRequest'

class ConsultStore {
  /** Consult list */
  @observable consultList = []
  @observable consultListPageIndex = 0
  @observable consultListPageSize = 10
  @observable consultListTotalCount = 0
  @observable consultListTotalPage = 0
  @observable consultListFilterByTitle = undefined
  @observable consultListFilterByStatus = undefined
  @observable consultListFilterByPermission = undefined

  @action getConsultList = () => {
    return new Promise((resolve, reject) => {
      ConsultRequest.getConsultList(
        this.consultListPageIndex,
        this.consultListPageSize,
        this.consultListFilterByTitle,
        this.consultListFilterByStatus,
        this.consultListFilterByPermission
      )
        .then(response => {
          if (this.consultListPageIndex === 0) {
            this.consultList = response.data.data
          } else {
            this.consultList = [...this.consultList, ...response.data.data]
          }
          this.consultListTotalCount = response.data.total_count
          this.consultListTotalPage = response.data.total_page
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action setConsultListPageIndex = pageIndex => {
    this.consultListPageIndex = pageIndex
  }

  /** Filter consult */
  @action setConsultListFilterByTitle = title => {
    this.consultListPageIndex = 0
    this.consultList.length = 0
    this.consultListFilterByTitle = title
  }
  @action setConsultListFilterByStatus = status => {
    this.consultListPageIndex = 0
    this.consultList.length = 0
    this.consultListFilterByStatus = status
  }
  @action setConsultListFilterByPermission = permission => {
    this.consultListPageIndex = 0
    this.consultList.length = 0
    this.consultListFilterByPermission = permission
  }

  /** Fix bug same code khi search không lấy được detail */
  @observable sameCodeDetail = 0

  @action setSameCodeDetail = () => {
    this.sameCodeDetail = this.sameCodeDetail + 1
  }

  /** Selected consult */
  @observable selectedConsult = {}
  @action getConsultDetail = consultCode => {
    return new Promise((resolve, reject) => {
      ConsultRequest.getConsultDetail(consultCode)
        .then(response => {
          this.selectedConsult = response.data
          this.consultList.forEach(item => {
            if (item.code === consultCode) {
              item.read = true
            }
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Create consult */
  @action createConsult = consultData => {
    return new Promise((resolve, reject) => {
      ConsultRequest.createConsult(consultData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Update consult status */
  @action updateStatusConsult = (consultCode, consultStatus) => {
    return new Promise((resolve, reject) => {
      ConsultRequest.updateStatusConsult(consultCode, consultStatus)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Delete consult */
  @action deleteConsult = consultCode => {
    return new Promise((resolve, reject) => {
      ConsultRequest.deleteConsult(consultCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateConsultAssignees = (consultCode, data) => {
    return new Promise((resolve, reject) => {
      ConsultRequest.updateConsultAssignees(consultCode, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear */
  @action clearAllConsultListFilter = () => {
    this.consultList.length = 0
    this.consultListPageIndex = 0
    this.consultListPageSize = 10
    this.consultListTotalCount = 0
    this.consultListTotalPage = 0
    this.consultListFilterByTitle = undefined
    this.consultListFilterByStatus = undefined
    this.consultListFilterByPermission = undefined
  }

  @action clearAllConsultListFilterAdvance = () => {
    this.consultList.length = 0
    this.consultListPageIndex = 0
    this.consultListPageSize = 10
    this.consultListTotalCount = 0
    this.consultListTotalPage = 0
    this.consultListFilterByStatus = undefined
    this.consultListFilterByPermission = undefined
  }

  @action clearConsultList = () => {
    this.consultList.length = 0
  }

  @action clearSelectedConsult = () => {
    this.selectedConsult = {}
  }
}

export default new ConsultStore()

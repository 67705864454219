import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, message, Modal, Upload } from 'antd'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { blue } from '../../color'
import { SendOutlined, SyncOutlined } from '@ant-design/icons'

const SalaryImportFileModal = props => {
  const {
    handleCloseSalaryImportFileModal,
    isVisibleSalaryImportFileModal,
    fileList,
    handleChangeFile,
    salaryStore,
  } = props

  const history = useHistory()
  const [isImporting, setIsImporting] = useState(false)

  const handleImportFile = async () => {
    if (fileList.length === 0) {
      return message.warning('Hãy chọn file!')
    }
    try {
      setIsImporting(true)
      const formData = new FormData()
      formData.append('file', fileList[0])
      await salaryStore.importSalaryFile(formData)
      handleCloseSalaryImportFileModal()
      message.success('Nhập thành công bảng lương!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsImporting(false)
    }
  }

  return (
    <Modal
      footer={null}
      closable={false}
      title={
        <div
          style={{
            fontWeight: 500,
            fontSize: 18,
          }}>
          Bạn có chắc chắn muốn nhập file này?
        </div>
      }
      visible={isVisibleSalaryImportFileModal}>
      <div
        style={{
          marginBottom: 30,
          display: 'flex',
          alignItems: 'center',
        }}>
        <span
          style={{
            marginLeft: 8,
            color: blue,
            fontSize: 20,
          }}>
          {fileList.map(item => item.name)}
        </span>
      </div>
      <div
        className={'flex justify-end gap-3'}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 12,
        }}>
        <Button danger onClick={handleCloseSalaryImportFileModal}>
          Hủy
        </Button>
        <Upload
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          style={{ marginLeft: 4, marginRight: 4 }}
          valuePropName={'fileList'}
          fileList={fileList}
          onChange={handleChangeFile}
          beforeUpload={() => false}
          showUploadList={false}>
          <Button
            icon={<SyncOutlined />}
            style={{
              color: blue,
              fontWeight: 500,
            }}>
            Chọn file khác
          </Button>
        </Upload>
        <Button
          icon={<SendOutlined />}
          loading={isImporting}
          onClick={handleImportFile}
          type={'primary'}>
          IMPORT FILE
        </Button>
      </div>
    </Modal>
  )
}

SalaryImportFileModal.propTypes = {
  isVisibleSalaryImportFileModal: PropTypes.bool.isRequired,
  handleCloseSalaryImportFileModal: PropTypes.func.isRequired,
  fileList: PropTypes.array,
  handleChangeFile: PropTypes.func,
}

export default inject('salaryStore')(observer(SalaryImportFileModal))

import styled from 'styled-components'

export const SignDocumentWrapper = styled.div``
export const InfoSignDocWrapper = styled.div`
  label {
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
  }
`

export const BlockLeft = styled.div`
  width: calc(100% - 350px);
`
export const BlockRight = styled.div`
  width: 350px;
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      margin-right: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export const FomItem = styled.div`
  margin-bottom: ${props => (props.mb ? props.mb : '10px')};

  label {
    min-width: 120px;
    display: inline-block;
    font-weight: 500;
  }
`

export const SignDocumentList = styled.ul`
  list-style: none;
  padding: 0 10px;
  margin-bottom: 10px;
`
export const FileItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: all ease 0.3s;
  padding: 3px 0;
  border-bottom: 1px solid #ebebeb;

  .ant-btn span {
    text-decoration: underline;
  }
`

export const FileAction = styled.div``

export const TitleDoc = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`

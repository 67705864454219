import { action, observable } from 'mobx'
import { DocumentAccessibilityRequest } from '../requests/DocumentAccessibilityRequest'

class DocumentAccessibilityStore {
  @observable documentAccessibilities = []

  @action getDocumentAccessibilities = (type, access_type) => {
    return new Promise((resolve, reject) => {
      DocumentAccessibilityRequest.getDocumentAccessibilities(type, access_type)
        .then(response => {
          this.documentAccessibilities = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateDocumentAccessibilities = payload => {
    return new Promise((resolve, reject) => {
      DocumentAccessibilityRequest.updateDocumentAccessibilities(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new DocumentAccessibilityStore()

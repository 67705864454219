import React from 'react'
import { Pagination } from 'antd'
import { inject, observer } from 'mobx-react'

const stylePagination = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 20,
  marginBottom: 10,
}

const MySalaryListPagination = props => {
  const { salaryStore } = props

  const handleChangePagination = (page, size) => {
    salaryStore.setQueryParams('filterPageMySalary', page - 1)
    salaryStore.setQueryParams('filterSizeMySalary', size)
  }
  return (
    <div style={stylePagination}>
      <Pagination
        current={salaryStore.filterPageMySalary + 1}
        pageSize={salaryStore.filterSizeMySalary}
        total={salaryStore.mySalaryTotal}
        onChange={handleChangePagination}
      />
    </div>
  )
}

MySalaryListPagination.propTypes = {}
export default inject('salaryStore')(observer(MySalaryListPagination))

import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { TaskRequest } from '../../requests/TaskRequest'
import EmptyContent from '../EmptyContent'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import taskStore from '../../stores/taskStore'

const TaskTagPicker = props => {
  const [tagList, setTagList] = useState([])
  const { Option } = Select

  const [selectedTags, setSelectedTags] = useState([])
  const [isDirty, setIsDirty] = useState(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  useEffect(() => {
    setIsDirty(true)
  }, [selectedTags.join()])

  useEffect(() => {
    if (taskStore.missionQueryParams.tag) {
      setSelectedTags(taskStore.missionQueryParams.tag)
    }
  }, [taskStore.missionQueryParams.tag])

  useEffect(() => {
    ;(async () => {
      await TaskRequest.getTagList().then(res => {
        setTagList(
          res.data.map(tag => ({
            code: tag.code,
            name: tag.name,
            type: tag.type,
          }))
        )
      })
    })()
  }, [])

  if (props.triggerChangeOnBlur) {
    return (
      <Select
        {...props}
        mode="multiple"
        defaultValue={props.defaultValue}
        showSearch
        allowClear
        showArrow
        onClear={() => {
          props.onChangePicker(null)
        }}
        onChange={value => {
          setSelectedTags(value)
          props.handleChangeTag && props.handleChangeTag()
        }}
        onBlur={() => {
          if (isDirty) {
            setIsDirty(false)
            props.onChangePicker(selectedTags)
          }
        }}
        onDeselect={value => {
          if (!isDropdownVisible) {
            setIsDirty(true)
            props.onChangePicker(selectedTags.filter(val => val !== value))
          }
        }}
        onDropdownVisibleChange={value => {
          setIsDropdownVisible(value)
        }}
        notFoundContent={<EmptyContent />}
        style={{ width: '100%' }}
        placeholder="--Chọn loại nhiệm vụ--"
        value={props.value}>
        {tagList.map(tag => (
          <Option name={tag.name} value={tag.code} key={tag.code}>
            {tag.name}
          </Option>
        ))}
      </Select>
    )
  }

  return (
    <Select
      {...props}
      // mode="multiple"
      defaultValue={props.defaultValue}
      showSearch
      allowClear
      showArrow
      notFoundContent={<EmptyContent />}
      style={{ width: '100%' }}
      placeholder="--Chọn loại nhiệm vụ--"
      value={props.value}>
      {tagList.map(tag => (
        <Option name={tag.name} value={tag.code} key={tag.code}>
          {tag.name}
        </Option>
      ))}
    </Select>
  )
}

export default inject('taskStore')(observer(TaskTagPicker))

TaskTagPicker.propTypes = {
  triggerChangeOnBlur: PropTypes.bool,
  onChangePicker: PropTypes.func,
  defaultValue: PropTypes.arrayOf(PropTypes.string) || PropTypes.string,
  value: PropTypes.array,
}

import styled from 'styled-components'
import { Slider } from 'antd'
import { blue } from '../../color'

export const FormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
`

export const FormActionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 30px 0 15px;
`

export const UploadFileListWrapper = styled.ul`
  margin-bottom: 15px;
  padding-left: 0 !important;
`

export const UploadFileListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: all ease 0.3s;
  color: #1890ff;
  &:hover {
    background-color: #ebebeb;
    transition: all ease 0.3s;
    .anticon-check,
    .anticon-delete {
      opacity: 1;
      visibility: visible;
      transition: all ease 0.3s;
    }
  }
  .anticon {
    color: #8c8c8c;
    &:not(.anticon-paper-clip) {
      margin-left: 5px;
    }
    &.anticon-delete {
      margin-right: 5px;
    }
    &:hover {
      &.anticon-delete {
        color: red;
      }
    }
  }
  .anticon-check,
  .anticon-delete {
    visibility: hidden;
    opacity: 0;
    transition: all ease 0.3s;
    &:hover {
      cursor: pointer;
    }
  }
  .anticon-paper-clip {
    margin: 0 8px 0 5px;
    + span {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: auto;
      padding-right: 60px;
    }
  }
`

export const SliderWrapper = styled(Slider)`
  .ant-slider-track {
    background-color: ${props => (props.completed ? '#52c41a' : blue)};
  }
`

import { action, observable } from 'mobx'
import { DocumentRequest } from '../requests/DocumentRequest'

class LeavePageStore {
  @observable attachment = undefined
  @observable showModalApprover = false
  @observable listLeaveRecord = []
  @observable leaveRecordDetail = {}
  @observable totalCount = 0
  @observable filterLeaveTable = {
    isOpen: false,
    leave_form_name: '',
    page: 1,
    size: 10,
  }

  @action showModal = modalName => {
    this[modalName] = true
  }
  @action hideModal = modalName => {
    this[modalName] = false
  }

  @action setFilter = filter => {
    this.filterLeaveTable = Object.assign({}, this.filterLeaveTable, filter)
  }

  @action getListLeave = payload => {
    return new Promise((resolve, reject) => {
      // DocumentRequest.getISO(payload)
      //   .then(response => {
      //     this.totalCount = response.data.total_count
      //     this.listISO = response.data.data
      //     resolve(response)
      //   })
      //   .catch(error => {
      //     reject(error)
      //   })
      this.totalCount = Math.floor(Math.random() * 999999)
      this.listLeaveRecord = []
      for (
        let index = 0;
        index <
        (this.totalCount > this.filterLeaveTable.size
          ? this.filterLeaveTable.size
          : this.totalCount);
        index++
      ) {
        this.listLeaveRecord.push({
          id: Math.floor(Math.random() * 999999),
          title: 'Đơn xin nghỉ ' + Math.floor(Math.random() * 999999),
          description: 'Vì lí do ' + Math.floor(Math.random() * 999999),
          status: Math.floor(Math.random() * 4), // 0: chờ phê duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu, 3: cần phê duyệt
          from_date: new Date(),
          to_date: new Date(),
          createdAt: new Date(),
          updatedAt: new Date(),
          createdBy: `admin${
            [Math.floor(Math.random() * 999999), ''][
              Math.floor(Math.random() * 2)
            ]
          }@gmail.com`,
          file_id: '5f9d3110de1ef178272d11ba',
          file_name: 'JD_Frontend Developer.pdf',
          assignee: 'user' + Math.floor(Math.random() * 999999) + '@gmail.com',
          job_hand_over: 'ABC' + Math.floor(Math.random() * 999999),
          approve: {
            list: [
              [
                {
                  id: Math.floor(Math.random() * 999999),
                  name: 'Approver' + Math.floor(Math.random() * 999999),
                  status: 0, // 0: chưa duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu
                },
                {
                  id: Math.floor(Math.random() * 999999),
                  name: 'Approver' + Math.floor(Math.random() * 999999),
                  status: 1, // 0: chưa duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu
                },
                {
                  id: Math.floor(Math.random() * 999999),
                  name: 'Approver' + Math.floor(Math.random() * 999999),
                  status: 2, // 0: chưa duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu
                },
              ],
              [
                {
                  id: Math.floor(Math.random() * 999999),
                  name: 'Approver' + Math.floor(Math.random() * 999999),
                  status: 0, // 0: chưa duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu
                },
              ],
              [
                {
                  id: Math.floor(Math.random() * 999999),
                  name: 'Approver' + Math.floor(Math.random() * 999999),
                  status: 0, // 0: chưa duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu
                },
              ],
            ],
          },
          comment: [
            {
              name: 'Approver' + Math.floor(Math.random() * 999999),
              text: 'text' + Math.floor(Math.random() * 999999),
              fileId: 'File' + Math.floor(Math.random() * 999999),
              fileName: 'Name' + Math.floor(Math.random() * 999999),
              status: Math.floor(Math.random() * 3), // 0: chỉ comment, 1: duyệt phiếu, 2: huỷ phiếu
            },
            {
              name: 'Approver' + Math.floor(Math.random() * 999999),
              text: 'text' + Math.floor(Math.random() * 999999),
              fileId: 'File' + Math.floor(Math.random() * 999999),
              fileName: 'Name' + Math.floor(Math.random() * 999999),
              status: Math.floor(Math.random() * 3), // 0: chỉ comment, 1: duyệt phiếu, 2: huỷ phiếu
            },
            {
              name: 'Approver' + Math.floor(Math.random() * 999999),
              text: 'text' + Math.floor(Math.random() * 999999),
              fileId: 'File' + Math.floor(Math.random() * 999999),
              fileName: 'Name' + Math.floor(Math.random() * 999999),
              status: Math.floor(Math.random() * 3), // 0: chỉ comment, 1: duyệt phiếu, 2: huỷ phiếu
            },
          ],
        })
      }
      resolve()
    })
  }

  @action getRecordDetailById = payload => {
    return new Promise((resolve, reject) => {
      const tmp = {
        id: Math.floor(Math.random() * 999999),
        title: 'Đơn xin nghỉ ' + Math.floor(Math.random() * 999999),
        description: 'Vì lí do ' + Math.floor(Math.random() * 999999),
        status: Math.floor(Math.random() * 4), // 0: chờ phê duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu, 3: cần phê duyệt
        from_date: new Date(),
        to_date: new Date(),
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: `admin${
          [Math.floor(Math.random() * 999999), ''][
            Math.floor(Math.random() * 2)
          ]
        }@gmail.com`,
        file_id: '5f9d3110de1ef178272d11ba',
        file_name: 'JD_Frontend Developer.pdf',
        assignee: 'user' + Math.floor(Math.random() * 999999) + '@gmail.com',
        job_hand_over: 'ABC' + Math.floor(Math.random() * 999999),
        approve: {
          list: [
            [
              {
                id: Math.floor(Math.random() * 999999),
                name: 'Approver' + Math.floor(Math.random() * 999),
                status: 0, // 0: chưa duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu
              },
              {
                id: Math.floor(Math.random() * 999999),
                name: 'Approver' + Math.floor(Math.random() * 999),
                status: 1, // 0: chưa duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu
              },
              {
                id: Math.floor(Math.random() * 999999),
                name: 'Approver' + Math.floor(Math.random() * 999),
                status: 2, // 0: chưa duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu
              },
            ],
            [
              {
                id: Math.floor(Math.random() * 999999),
                name: 'Approver' + Math.floor(Math.random() * 999),
                status: 0, // 0: chưa duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu
              },
            ],
            [
              {
                id: Math.floor(Math.random() * 999999),
                name: 'Approver' + Math.floor(Math.random() * 999),
                status: 0, // 0: chưa duyệt, 1: đã duyệt phiếu, 2: huỷ phiếu
              },
            ],
          ],
        },
        comment: [
          {
            name: 'Approver' + Math.floor(Math.random() * 999999),
            text: 'text' + Math.floor(Math.random() * 999999),
            fileId: 'File' + Math.floor(Math.random() * 999999),
            fileName: 'Name' + Math.floor(Math.random() * 999999),
            status: Math.floor(Math.random() * 3), // 0: chỉ comment, 1: duyệt phiếu, 2: huỷ phiếu
          },
          {
            name: 'Approver' + Math.floor(Math.random() * 999999),
            text: 'text' + Math.floor(Math.random() * 999999),
            fileId: 'File' + Math.floor(Math.random() * 999999),
            fileName: 'Name' + Math.floor(Math.random() * 999999),
            status: Math.floor(Math.random() * 3), // 0: chỉ comment, 1: duyệt phiếu, 2: huỷ phiếu
          },
          {
            name: 'Approver' + Math.floor(Math.random() * 999999),
            text: 'text' + Math.floor(Math.random() * 999999),
            fileId: 'File' + Math.floor(Math.random() * 999999),
            fileName: 'Name' + Math.floor(Math.random() * 999999),
            status: Math.floor(Math.random() * 3), // 0: chỉ comment, 1: duyệt phiếu, 2: huỷ phiếu
          },
        ],
      }
      this.leaveRecordDetail = tmp
      resolve({
        data: tmp,
      })
    })
  }

  @action getViewAttachment = payload => {
    return new Promise((resolve, reject) => {
      // TODO: need change
      DocumentRequest.getViewAttachmentISO(payload)
        .then(response => {
          this.attachment = new Blob([response.data], {
            type: 'application/pdf',
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAttachment = payload => {
    return new Promise((resolve, reject) => {
      // TODO: need change
      DocumentRequest.getAttachmentISO(payload)
        .then(response => {
          this.attachment = new Blob([response.data], {
            type: 'application/pdf',
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearLeaveData = () => {
    this.listLeaveRecord = []
    this.totalCount = 0
  }

  @action clearLeaveDataDetail = () => {
    this.leaveRecordDetail = {}
    this.attachment = undefined
  }
}

export default new LeavePageStore()

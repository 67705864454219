import React, { useState } from 'react'
import { Button } from 'antd'
import ConfigDefaultGroupModal from './ConfigDefaultGroupModal'
import { inject, observer } from 'mobx-react'

const SetDefaultGroupButton = props => {
  const { isNhomNhiemVu, authenticationStore } = props
  const [isVisibleModal, setVisibleModal] = useState(false)
  const { isAccountAdmin, isSuperAdmin } = authenticationStore

  const showConfigModal = () => {
    setVisibleModal(true)
  }

  const handleCancel = () => {
    setVisibleModal(false)
  }

  const textButton = () =>
    `Thiết lập nhóm ${isNhomNhiemVu ? `Thư ký` : `Văn thư`}`

  return isAccountAdmin || isSuperAdmin ? (
    <>
      <Button onClick={showConfigModal}>{textButton()}</Button>

      <ConfigDefaultGroupModal
        isNhomNhiemVu={isNhomNhiemVu}
        isVisibleModal={isVisibleModal}
        handleCancel={handleCancel}
      />
    </>
  ) : (
    <></>
  )
}

SetDefaultGroupButton.propTypes = {}

export default inject('authenticationStore')(observer(SetDefaultGroupButton))

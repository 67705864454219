import styled from 'styled-components'

export const EditorWrapper = styled.div`
  .ck-content {
    figure.table {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      th,
      td {
        vertical-align: top;
      }
    }
  }
`

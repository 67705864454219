import React, { useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { BoxWrapper, PanelItem } from './SelectGroupMemberBoxStyled'
import { blue } from '../../../color'
import { CaretRightOutlined, SearchOutlined } from '@ant-design/icons'
import { Checkbox, Collapse, Input, message, Tooltip } from 'antd'
import { EmptyText } from '../../Common/CellText'
import utils from '../../../utils'
import { AvatarHasName } from '../../Common/Avatar'
import {
  ASSIGNEE_TYPE,
} from '../../../constants'

const SelectGroupMemberBox = props => {
  const { loadingAnimationStore, selectPeopleStore } = props
  const {
    groupList,
    originGroupList,
    selectGroupData,
    setSelectGroupData,
  } = selectPeopleStore

  const handleSearchGroup = useCallback(
    event => {
      if (event.target.value.trim() === '') {
        selectPeopleStore.setGroupList(originGroupList)
        return
      }
      const listGroupFilter = originGroupList.filter(group =>
        utils
          .nonAccentVietnamese(group.name)
          .includes(utils.nonAccentVietnamese(event.target.value))
      )
      selectPeopleStore.setGroupList(listGroupFilter)
    },
    [originGroupList]
  )

  const handleSelectGroup = useCallback(
    group => {
      if (!group) return
      const isSelectedGroup = selectGroupData[ASSIGNEE_TYPE.HANDLER].some(
        el => parseInt(el.id) === parseInt(group.id)
      )
      if (isSelectedGroup) {
        setSelectGroupData({
          ...selectGroupData,
          [ASSIGNEE_TYPE.HANDLER]: selectGroupData[ASSIGNEE_TYPE.HANDLER].filter(
            el => parseInt(el.id) !== parseInt(group.id)
          ),
        })
        return
      }
      setSelectGroupData({
        ...selectGroupData,
        [ASSIGNEE_TYPE.HANDLER]: [
          ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
          {
            assignee_type: ASSIGNEE_TYPE.GROUP,
            full_name: group.name,
            id: group.id,
            users: group.users,
            permission: ASSIGNEE_TYPE.HANDLER,
          },
        ],
      })
    },
    [selectGroupData]
  )

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        const response = await selectPeopleStore.getGroupList()
        response.data.data.map(
          async group => {
            await selectPeopleStore.getMemberGroup(group.id)}
        )
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => selectPeopleStore.clearSelectGroupMemberData()
  }, [])

  return (
    <BoxWrapper>
      <div className={'search-box'}>
        <label style={{ fontWeight: 500, marginRight: 10 }}>
          <SearchOutlined style={{ marginRight: 4, color: blue }} />
          Tìm kiếm:
        </label>
        <Input
          placeholder={'Nhập tên nhóm...'}
          style={{ width: 300 }}
          onChange={event => handleSearchGroup(event)}
        />
      </div>
      <div className={'collapse-wrapper'}>
        <Collapse
          collapsible={'header'}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}>
          {groupList.map((group, index) => {
            const checked = selectGroupData[ASSIGNEE_TYPE.HANDLER].some(
              el => el.id == group.id
            )
            return (
              <PanelItem
                extra={<div className={'extra'} />}
                checked={checked}
                collapsible
                key={index}
                header={
                  <Tooltip>
                    <div
                      onClick={e => {
                        e.stopPropagation()
                        handleSelectGroup(group)
                      }}
                      className={`group-item ${
                        checked && 'group-item-checked'
                      }`}>
                      <Checkbox checked={checked} />
                      <span className={'group-name'}>{group.name}</span>
                    </div>
                  </Tooltip>
                }>
                {group.users.length > 0 ? (
                  group.users.map((user, index) => {
                    return (
                      <div className={'user-item'} key={index}>
                        <AvatarHasName
                          name={user.user.name_lowercase}
                          imgId={user.image}
                        />
                      </div>
                    )
                  })
                ) : (
                  <div style={{ padding: '10px 0 10px 60px' }}>
                    <EmptyText>Không có người dùng</EmptyText>
                  </div>
                )}
              </PanelItem>
            )
          })}
        </Collapse>
      </div>
    </BoxWrapper>
  )
}

SelectGroupMemberBox.propTypes = {}

export default inject(
  'userStore',
  'selectPeopleStore',
  'loadingAnimationStore',
)(observer(SelectGroupMemberBox))

import { Menu, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import DocumentManagementLayout from '../DocumentManagementLayout'
import {
  DocumentManagementTabContent,
  DocumentManagementTabLayout,
} from '../DocumentManagementStyled'

const ConnectedCommiteeDocumentLayout = props => {
  const history = useHistory()
  const [activeKey, setActiveKey] = useState('organization')
  const pathName = window.location.pathname

  const onChangeTabs = key => {
    let path = '/document-management/connected-committee-document'
    if (props.historyPath) {
      path = props.historyPath
    }
    history.push(`${path}/${key}`)
  }

  return (
    <DocumentManagementLayout selectedKey="connected-committee-document">
      <DocumentManagementTabLayout>
        <Tabs
          tabPosition={'left'}
          activeKey={activeKey}
          onChange={onChangeTabs}>
          <Tabs.TabPane tab="Công ty nhận" key="organization" />
        </Tabs>
        <div style={{ flexGrow: 1 }}>{props.children}</div>
      </DocumentManagementTabLayout>
    </DocumentManagementLayout>
  )
}

export default ConnectedCommiteeDocumentLayout

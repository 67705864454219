import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const AuthorityRequest = {
  getAuthorityIssueds: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/authority-issueds`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),
  updateAuthority: (id, payload) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/authority-issueds/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }),
  createAuthority: payload =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/authority-issueds`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }),
  deleteAuthority: id =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/authority-issueds/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getAuthorityLevels: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/authority-levels`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}

import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Container } from '../../layouts/Container/Container'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import {
  DeleteOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { ASSIGNEE_TYPE, DATE_FORMAT_LIST } from '../../constants'
import {
  FormActionFooter,
  UploadFileListItem,
  UploadFileListWrapper,
} from './ConnectedDocumentOutgoingDocCreatePageStyled'
import { inject, observer } from 'mobx-react'
import EmptyContent from '../../components/EmptyContent'
import TrucWrapper from '../../components/TrucWrapper/TrucWrapper'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import validator from '../../validator'
import { Document } from 'react-pdf'
import { toJS } from 'mobx'
import moment from 'moment'
import SelectPeoplePopupTruc from '../../components/SelectPeoplePopupTruc/SelectPeoplePopupTruc'
import SelectGroupPopupTruc from '../../components/SelectGroupPopupTruc/SelectGroupPopupTruc'
import {
  SelectList,
  SelectTitle,
} from '../Documents/InternalDocumentOutgoingDocCreatePage/InternalDocumentOutgoingDocCreatePageStyled'
import { blue, yellowPrimary } from '../../color'
import { AvatarHasName } from '../../components/Common/Avatar'

const { Option } = Select
const { TextArea } = Input

const ConnectedDocumentOutgoingDocCreatePage = props => {
  const {
    history,
    connectedDocumentStore,
    fileStore,
    loadingAnimationStore,
    commonStore,
    signedDocumentStore,
    userTrucStore,
  } = props

  const { dataReceiveDocument } = connectedDocumentStore
  const {
    handleOpenSelectUserPopupTruc,
    isVisibleSelectUserPopupTruc,
    handleCancelSelectUserTruc,
    handleCancelSelectGroupTruc,
    selectUserDataTruc,
    selectGroupDataTruc,
    handleOpenSelectGroupPopupTruc,
    isVisibleSelectGroupPopupTruc,
  } = userTrucStore

  const [selectedUserListTruc, setSelectedUserListTruc] = useState([])

  useEffect(() => {
    setSelectedUserListTruc([
      ...toJS(selectUserDataTruc),
      ...toJS(selectGroupDataTruc),
    ])
  }, [selectUserDataTruc, selectGroupDataTruc])

  const [form] = Form.useForm()
  const [formRenameFile] = Form.useForm()

  // For Rename file
  const [fileExt, setFileExt] = useState(undefined)
  const [fileTargetRename, setFileTargetRename] = useState(null)
  const [isRenameFileFromInternal, setIsRenameFileFromInternal] = useState(
    false
  )
  const [isModalVisibleRenameFile, setIsModalVisibleRenameFile] = useState(
    false
  )
  // List file nhận từ thiết bị
  const [fileList, setFileList] = useState([])
  // List file nhận từ trục
  const [fileFromInternal, setFileFromInternal] = useState([])
  // vẽ ngầm canvas ký số
  const [viewPortPdf, setViewPortPdf] = useState()
  const [fileRender, setFileRender] = useState()

  const handleCreateOutgoingDocument = submitData => {
    connectedDocumentStore
      .createOutgoingDocument(submitData)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        handleCancelCreate()
        message.success(`Tạo văn bản thành công!`)
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }

  const onFinish = async values => {
    if (selectedUserListTruc.length === 0) {
      return message.warning('Vui lòng chọn công ty nhận!')
    }

    const selectUserDataValue = selectUserDataTruc.map(user => {
      return {
        id: user.id,
        type: ASSIGNEE_TYPE.USER,
      }
    })

    const selectGroupDataValue = selectGroupDataTruc.map(group => {
      return {
        id: group.id,
        type: ASSIGNEE_TYPE.GROUP,
      }
    })

    const listUserToValue = [...selectUserDataValue, ...selectGroupDataValue]

    const fileSignedList = fileList
      .filter(file => file.isSigned)
      .map(file => file.id)
    const {
      appendixes,
      code,
      competence,
      content,
      doc_reason,
      document_type,
      document_type_name,
      due_date,
      page_amount,
      promulgation_amount,
      promulgation_date,
      sphere_of_promulgation,
      steering_type,
      priority,
      signer,
      signer_position,
      subject,
      place,
      to_places,
      typer_notation,
    } = values
    loadingAnimationStore.showSpinner(true)
    // download -> upload file từ văn bản nội bộ vimc
    let fileBoldFormInternalList = []
    await Promise.all(
      fileFromInternal.map(file => fileStore.downloadAttachment(file.id))
    )
      .then(response => {
        fileBoldFormInternalList = response
      })
      .catch(err => {
        console.log(err)
        message.warning(
          'Download file từ trục liên thông có lỗi, bạn hãy thử nhận lại văn bản!'
        )
      })
    const uploadedFileFromInternal = await Promise.all(
      fileBoldFormInternalList.map((fileBold, index) => {
        const formData = new FormData()
        formData.append('file', fileBold.data, fileFromInternal[index].name)
        return fileStore.uploadFileLTVB(formData)
      })
    )
    const idFileUploadFromInternal = uploadedFileFromInternal.map(
      file => file.data.fileId
    )
    const batchUploadList = []
    fileList.forEach(file => {
      if (file.isSigned) return
      const formData = new FormData()
      formData.append('file', file.originFileObj, file.name)
      batchUploadList.push(fileStore.uploadFileLTVB(formData))
    })
    Promise.all(batchUploadList)
      .then(response => {
        console.log('response', response)
        const submitValues = {
          appendixes: appendixes,
          attachments: response
            .map(response => response.data.fileId)
            .concat(fileSignedList)
            .concat(idFileUploadFromInternal),
          code: code,
          competence: competence,
          content: content,
          docReason: doc_reason,
          documentType: document_type,
          documentTypeName: document_type_name,
          dueDate: due_date,
          pageAmount: page_amount,
          priority: priority,
          place: place,
          promulgationAmount: promulgation_amount,
          promulgationDate: promulgation_date,
          signer: signer,
          signer_position: signer_position,
          sphereOfPromulgation: sphere_of_promulgation,
          steeringType: steering_type,
          subject: subject,
          toPlaces: to_places,
          typeNotation: typer_notation,
          listUserTo: listUserToValue,
        }
        handleCreateOutgoingDocument(submitValues)
      })
      .catch(error => {
        message.error(error.vi)
        loadingAnimationStore.showSpinner(false)
      })
  }

  const handleAddFileToUpload = () => false

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ attachments: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList.filter(
      (elem, index, fileList) =>
        fileList.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const submitRenameFile = value => {
    const index = fileTargetRename?.index
    setIsModalVisibleRenameFile(false)
    // đổi tên file nhận từ trục
    if (isRenameFileFromInternal) {
      const fileFromInternalAfterRename = [...fileFromInternal]
      fileFromInternalAfterRename[index] = {
        id: fileTargetRename.id,
        name: value.rename + fileExt,
      }
      setFileFromInternal(fileFromInternalAfterRename)
      return
    }
    // đổi tên file nhận từ thiết bị
    const newFileList = [...fileList]
    newFileList[index].name = value.rename + fileExt
    newFileList[index].originFileObj = renameFile(
      newFileList[index].originFileObj,
      newFileList[index].name
    )
    setFileList(newFileList)
  }

  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
  }

  const handleCancelCreate = () => {
    form.resetFields()
    history.push('/connected-document/outgoing-document')
  }

  const renderPage = async pdf => {
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 1 })
    setViewPortPdf({ height: viewport.height, width: viewport.width })
  }

  useEffect(() => {
    console.log(viewPortPdf)
  }, [viewPortPdf])

  const renderDocument = () => {
    return (
      <Document
        noData={null}
        style={{ display: 'none' }}
        file={fileRender}
        onLoadSuccess={pdf => onDocumentLoadSuccess(pdf)}
      />
    )
  }

  const onDocumentLoadSuccess = pdf => {
    renderPage(pdf, 1)
  }

  const handleBeforeSign = file => {
    setFileRender(file)
  }

  const handleSignSavis = useCallback(
    (file, index) => {
      loadingAnimationStore.showSpinner(true)
      const formData = new FormData()
      formData.append('file', file)
      fileStore
        .uploadFileLTVB(formData)
        .then(res => {
          const dataSubmit = {
            image: '60ac5e444eddb22d40cab8a1',
            reason: 'Signed',
            location: 'VietNam',
            provider: 'SAVIS',
            type: 'SYSTEM',
            contactInfo: '',
            isVisible: 1,
            page: 1,
            llx: -20,
            lly: viewPortPdf?.height - 50,
            urx: 300,
            ury: 54.3,
            listSignFile: [
              {
                fileId: res.data.fileId,
              },
            ],
          }
          signedDocumentStore
            .signDocumentSavisTruc(dataSubmit)
            .then(res => {
              const {
                fileSignId,
                fileOriginName,
                fileSignName,
              } = res.data.listSignFile[0]
              const newFileList = [...fileList]
              newFileList[index] = {
                name: fileSignName,
                id: fileSignId,
                uid: file.uid,
                isSigned: true,
                type: 'application/pdf',
              }
              setFileList(newFileList)
              loadingAnimationStore.showSpinner(false)
              message.success(`Văn bản "${fileOriginName}" đã được kí`)
            })
            .catch(() => {
              loadingAnimationStore.showSpinner(false)
              message.error('Ký số thất bại!')
            })
        })
        .catch(() => loadingAnimationStore.showSpinner(false))
    },
    [viewPortPdf, fileList]
  )

  const handleRemoveFileFromUploadList = useCallback(
    fileUID => {
      const newFileList = fileList.filter(file => file.uid !== fileUID)
      setFileList(newFileList)
      if (newFileList.length === 0) {
        form.setFieldsValue({
          attachments: [],
        })
      }
    },
    [fileList]
  )

  const handleRemoveFileFromInternal = file_id => {
    setFileFromInternal(
      toJS([...fileFromInternal]).filter(file => file.id !== file_id)
    )
  }

  const getTypeFile = nameFile => '.' + nameFile.split('.').pop()

  const removeFileExt = fileName => {
    return fileName.split('.').slice(0, -1).join('.')
  }

  useEffect(() => {
    console.log('nhan van ban', toJS(dataReceiveDocument))
  }, [connectedDocumentStore])

  useEffect(() => {
    if (
      !dataReceiveDocument ||
      !dataReceiveDocument.attachments ||
      !dataReceiveDocument.attachments.length
    )
      return
    setFileFromInternal([...toJS(dataReceiveDocument.attachments)])
  }, [])

  return (
    <TrucWrapper>
      <DashboardLayout>
        <Helmet>
          <title>Tạo văn bản đi | Liên thông văn bản</title>
        </Helmet>
        <PageTitle location={props.location} title={'Tạo văn bản đi'} />
        <ContentBlockWrapper>
          <Container maxWidth={820}>
            <Form
              form={form}
              scrollToFirstError={true}
              name={'create-outgoing-document'}
              layout={'vertical'}
              onFinish={onFinish}
              initialValues={{
                signer: dataReceiveDocument && dataReceiveDocument.signer,
                subject: dataReceiveDocument && dataReceiveDocument.title,
                promulgation_date:
                  dataReceiveDocument &&
                  dataReceiveDocument.date_issued &&
                  moment(dataReceiveDocument.date_issued),
              }}>
              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={'Số hiệu'}
                    name={'code'}
                    rules={[
                      {
                        required: true,
                        message: ' Vui lòng nhập số hiệu văn bản!',
                      },
                    ]}>
                    <Input
                      maxLength={30}
                      placeholder={'Nhập số hiệu văn bản'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label={'Loại chỉ đạo'} name={'steering_type'}>
                    <Select
                      notFoundContent={<EmptyContent />}
                      placeholder={'-- Chọn loại chỉ đạo --'}>
                      <Option value={'0'}>Không phải là chỉ đạo</Option>
                      <Option value={'1'}>Chỉ đạo</Option>
                      <Option value={'2'}>Báo cáo chỉ đạo</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item label={'Cơ quan ban hành'} name={'place'}>
                    <Input
                      maxLength={50}
                      placeholder={'Nhập cơ quan ban hành văn bản'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label={'Ngày ban hành'} name={'promulgation_date'}>
                    <DatePicker
                      style={{ width: '100%' }}
                      format={DATE_FORMAT_LIST}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label={'Loại văn bản'} name={'document_type'}>
                <Select
                  notFoundContent={<EmptyContent />}
                  placeholder={'-- Chọn loại văn bản --'}>
                  <Option value={'1'}>Văn bản hành chính</Option>
                  <Option value={'2'}>Văn bản quy phạm pháp luật</Option>
                </Select>
              </Form.Item>

              <Form.Item label={'Tên loại văn bản'} name={'document_type_name'}>
                <Input maxLength={100} placeholder={'Nhập tên loại văn bản'} />
              </Form.Item>

              <Form.Item
                label={'Trích yếu'}
                name={'subject'}
                rules={[
                  { required: true, message: ' Vui lòng nhập trích yếu!' },
                  { validator: validator.validateCharactersCannotExceed },
                ]}>
                <Input maxLength={500} placeholder={'Nhập trích yếu'} />
              </Form.Item>
              <Form.Item label={'Nội dung văn bản'} name={'content'}>
                <TextArea
                  rows={4}
                  placeholder={
                    'Nhập nội dung văn bản, chỉ áp dụng đối với văn bản dạng phi cấu trúc có độ dài không quá 500 ký tự'
                  }
                />
              </Form.Item>

              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item label={'Thẩm quyền'} name={'competence'}>
                    <Input maxLength={100} placeholder={'Nhập thẩm quyền'} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label={'Người ký'} name={'signer'}>
                    <Input maxLength={50} placeholder={'Nhập tên người ký'} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={'Chức vụ người ký văn bản'}
                name={'signer_position'}>
                <Input
                  maxLength={500}
                  placeholder={'Nhập chức danh người ký'}
                />
              </Form.Item>

              <Form.Item label={'Hạn trả lời văn bản'} name={'due_date'}>
                <DatePicker
                  format={DATE_FORMAT_LIST}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item label={'Độ khẩn'} name={'priority'}>
                <Select
                  notFoundContent={<EmptyContent />}
                  placeholder={'-- Chọn độ khẩn --'}>
                  <Option value={'0'}>Thường</Option>
                  <Option value={'1'}>Khẩn</Option>
                  <Option value={'2'}>Thượng khẩn</Option>
                  <Option value={'3'}>Hỏa tốc</Option>
                  <Option value={'4'}>Hỏa tốc hẹn giờ</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={'Lĩnh vực ban hành'}
                name={'sphere_of_promulgation'}>
                <Input maxLength={100} placeholder={'Nhập lĩnh vực ban hành'} />
              </Form.Item>

              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={24}>
                  <Form.Item
                    label={
                      'Ký hiệu đơn vị, tổ chức, chữ viết tắt tên người soạn thảo văn bản'
                    }
                    name={'typer_notation'}>
                    <Input maxLength={100} placeholder={'Nhập ký hiệu'} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    label={'Số lượng ban hành'}
                    name={'promulgation_amount'}>
                    <InputNumber
                      style={{ width: '100%' }}
                      min={1}
                      max={10000}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row type={'flex'} gutter={30}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={'Tổng số trang của văn bản'}
                    name={'page_amount'}>
                    <InputNumber
                      style={{ width: '100%' }}
                      min={1}
                      max={10000}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={'Ban hành theo chỉ đạo'}
                    name={'direction'}
                    valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label={'Phụ lục'} name={'appendixes'}>
                <Input maxLength={200} placeholder={'Nhập thông tin phụ lục'} />
              </Form.Item>
              <Form.Item label={'Nơi nhận'} name={'to_places'}>
                <Input maxLength={1000} placeholder={'Nhập nơi nhận'} />
              </Form.Item>

              <Form.Item label={'Lý do ban hành'} name={'doc_reason'}>
                <Input maxLength={200} placeholder={'Nhập lí do ban hành'} />
              </Form.Item>

              <Form.Item
                name={'attachments'}
                label={
                  fileFromInternal.length !== 0 ? (
                    <>
                      <span
                        style={{
                          color: '#ff4d4f',
                          fontFamily: 'SimSun, sans-serif',
                          marginRight: '2px',
                        }}>
                        *{' '}
                      </span>
                      Tài liệu đính kèm
                    </>
                  ) : (
                    'Tài liệu đính kèm'
                  )
                }
                rules={
                  fileFromInternal.length !== 0
                    ? null
                    : [
                        {
                          required: true,
                          message: ' Vui lòng chọn tài liệu đính kèm!',
                        },
                      ]
                }>
                <Upload
                  valuePropName={'fileList'}
                  fileList={fileList}
                  multiple={true}
                  beforeUpload={handleAddFileToUpload}
                  onChange={handleChangeFile}
                  showUploadList={false}>
                  <Button icon={<UploadOutlined />}>
                    Chọn tài liệu đính kèm
                  </Button>
                </Upload>
              </Form.Item>

              {fileList.length !== 0 && (
                <UploadFileListWrapper>
                  {fileList.map((file, index) => (
                    <UploadFileListItem
                      key={file.uid}
                      theme={commonStore.appTheme}
                      isSigned={file.isSigned}
                      isNotPDF={file.type !== 'application/pdf'}>
                      <FileTextOutlined />
                      <span>{file.name}</span>
                      {/*{*/}
                      {/*  file.isSigned ? (*/}
                      {/*      <Button*/}
                      {/*        className={'digital-signature'}*/}
                      {/*        type='text'*/}
                      {/*        onClick={() => message.info('Đã ký!')}*/}
                      {/*      >*/}
                      {/*        Đã ký*/}
                      {/*      </Button>*/}
                      {/*    ) :*/}
                      {/*    file.type !== 'application/pdf'*/}
                      {/*      ? (*/}
                      {/*        <Button*/}
                      {/*          className={'digital-signature'}*/}
                      {/*          type='text'*/}
                      {/*          onClick={() => message.info('Chỉ ký được file PDF, vui lòng chọn file khác!')}*/}
                      {/*        >*/}
                      {/*          Ký số*/}
                      {/*        </Button>*/}
                      {/*      )*/}
                      {/*      :*/}
                      {/*      (<Popconfirm*/}
                      {/*        title='Bạn có muốn ký file này không?'*/}
                      {/*        onConfirm={() => handleSignSavis(file.originFileObj, index)}*/}
                      {/*        okText='Đồng ý'*/}
                      {/*        cancelText='Không'*/}
                      {/*      >*/}
                      {/*        <Button*/}
                      {/*          className={'digital-signature'}*/}
                      {/*          type='text'*/}
                      {/*          onClick={() => handleBeforeSign(file.originFileObj)}*/}
                      {/*        >*/}
                      {/*          Ký số*/}
                      {/*        </Button>*/}
                      {/*      </Popconfirm>)*/}
                      {/*}*/}
                      {
                        <Button
                          className={'rename'}
                          type="text"
                          onClick={() => {
                            setIsRenameFileFromInternal(false)
                            setFileExt(getTypeFile(file.name))
                            setFileTargetRename({
                              name: file.name,
                              index: index,
                            })
                            setIsModalVisibleRenameFile(true)
                          }}>
                          Đổi tên
                        </Button>
                      }
                      <Tooltip title={'Xoá tập tin'}>
                        <DeleteOutlined
                          onClick={() =>
                            handleRemoveFileFromUploadList(file.uid)
                          }
                        />
                      </Tooltip>
                    </UploadFileListItem>
                  ))}
                </UploadFileListWrapper>
              )}
              {fileFromInternal.map((file, index) => (
                <UploadFileListItem key={file.id}>
                  <FileTextOutlined style={{ color: '1890FF' }} />
                  <span style={{ color: '#1890FF' }}>{file.name}</span>
                  <Button
                    className={'rename'}
                    type="text"
                    onClick={() => {
                      setIsRenameFileFromInternal(true)
                      setFileExt(getTypeFile(file.name))
                      setFileTargetRename({
                        ...file,
                        index: index,
                      })
                      setIsModalVisibleRenameFile(true)
                    }}>
                    Đổi tên
                  </Button>
                  <Tooltip title={'Xoá tập tin'}>
                    <DeleteOutlined
                      onClick={() =>
                        handleRemoveFileFromInternal(file.id, index)
                      }
                    />
                  </Tooltip>
                </UploadFileListItem>
              ))}
              <SelectTitle>
                <span>
                  <span
                    style={{
                      color: '#ff4d4f',
                      fontFamily: 'SimSun, sans-serif',
                      marginRight: '-4px',
                    }}>
                    *{' '}
                  </span>
                  <span style={{ marginRight: '10px' }}>Công ty nhận:</span>
                </span>
                <Space>
                  <Tooltip title="Chọn người dùng" color={blue}>
                    <Button
                      icon={<UserOutlined style={{ color: blue }} />}
                      onClick={() => handleOpenSelectUserPopupTruc(false)}
                    />
                  </Tooltip>
                  <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                    <Button
                      icon={<TeamOutlined />}
                      style={{ color: yellowPrimary }}
                      onClick={() => handleOpenSelectGroupPopupTruc(false)}
                    />
                  </Tooltip>
                </Space>
              </SelectTitle>
              <SelectList>
                {selectedUserListTruc.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => userTrucStore.handleRemoveSelectTruc(el)}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.name}
                      icon={typeof el.id === 'number' && <TeamOutlined />}
                    />
                  </Tag>
                ))}
              </SelectList>

              <FormActionFooter>
                <Button onClick={handleCancelCreate}>Huỷ bỏ</Button>
                <Button
                  style={{ marginLeft: 10 }}
                  type={'primary'}
                  htmlType={'submit'}>
                  Tạo văn bản
                </Button>
              </FormActionFooter>
            </Form>
          </Container>
          {/*Modal remane file */}
          <Modal
            title="Đổi tên file Trục"
            visible={isModalVisibleRenameFile}
            onOk={() => setIsModalVisibleRenameFile(false)}
            onCancel={() => setIsModalVisibleRenameFile(false)}
            footer={null}>
            <Form
              form={formRenameFile}
              scrollToFirstError={true}
              onFinish={submitRenameFile}
              fields={[
                {
                  name: ['rename'],
                  value:
                    fileTargetRename && removeFileExt(fileTargetRename.name),
                },
              ]}>
              <Form.Item
                name={'rename'}
                rules={[
                  { required: true, message: ' Vui lòng nhập tên file!' },
                  { validator: validator.validateCharactersCannotExceed },
                ]}>
                <Input
                  placeholder={'Nhập tên file thay đổi'}
                  addonAfter={fileExt}
                />
              </Form.Item>
              <FormActionFooter>
                <Button onClick={() => setIsModalVisibleRenameFile(false)}>
                  Huỷ bỏ
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  type={'primary'}
                  htmlType={'submit'}>
                  Đổi tên
                </Button>
              </FormActionFooter>
            </Form>
          </Modal>
        </ContentBlockWrapper>
        {renderDocument()}
      </DashboardLayout>
      <SelectPeoplePopupTruc
        isVisibleSelectPeoplePopupTruc={isVisibleSelectUserPopupTruc}
        handleCancelSelectUserTruc={handleCancelSelectUserTruc}
        handleSubmitSelectUserTruc={() =>
          userTrucStore.setIsVisibleSelectUserPopupTruc(false)
        }
      />
      <SelectGroupPopupTruc
        isVisibleSelectGroupPopupTruc={isVisibleSelectGroupPopupTruc}
        handleCancelSelectGroupTruc={handleCancelSelectGroupTruc}
        handleSubmitSelectGroupTruc={() =>
          userTrucStore.setIsVisibleSelectGroupPopupTruc(false)
        }
      />
    </TrucWrapper>
  )
}

ConnectedDocumentOutgoingDocCreatePage.propTypes = {}

export default inject(
  'connectedDocumentStore',
  'fileStore',
  'loadingAnimationStore',
  'commonStore',
  'signedDocumentStore',
  'userTrucStore',
)(observer(ConnectedDocumentOutgoingDocCreatePage))

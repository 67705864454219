import { observable } from 'mobx'
import fileStore from '../../../stores/fileStore'
import isoDocumentStore from '../isoDocumentStore'

class UploadISOFileStore {
  @observable isLoading = false

  upload = async formData => {
    this.isLoading = true

    await fileStore.uploadFileCloud(formData)

    this.isLoading = false

    isoDocumentStore.getItems()
  }

  clearStore = () => {
    this.isLoading = false
  }
}

export default new UploadISOFileStore()

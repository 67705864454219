import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const BookRequest = {
  //Book Group
  getBookGroup: type =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/book-groups`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      ...(!utils.isNullish(type) && { params: { type: type } }),
    }),
  createBookGroup: (name, type) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/book-groups`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        name: name,
        type: type,
      },
    }),
  updateBookGroup: (id, name, type) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/book-groups/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        name: name,
        type: type,
      },
    }),
  deleteBookGroup: id =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/book-groups/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  //Book

  getBookByBookGroupId: bookGroupId =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/book-groups/${bookGroupId}/books`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  createBook: (group_id, name) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/book-groups/${group_id}/books`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        name: name,
      },
    }),
  updateBook: (group_id, id, name, type) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/book-groups/books/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        book_group_id: group_id,
        name: name,
      },
    }),
  deleteBook: id =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/book-groups/books/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}

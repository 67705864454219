import styled from 'styled-components'
import { blue } from '../../color'

import { List } from 'antd'

export const WorkPageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  .main-wrapper {
    padding-top: ${props => (props.isVisibleDrawerWork ? '50px' : 0)};
  }
`

export const HeaderWork = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  background-color: ${blue};
  color: #fff;

  .title-work {
    flex-grow: 1;
    padding: 20px 10px;
  }

  .title-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fffb8f;
    border-color: #fff566;

    &::after {
      border-color: #2c65ac;
      transform: rotate(45deg) scale(1.3) translate(-50%, -50%);
    }
  }
`
export const TaskItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -12px -15px -12px -20px;
  padding: 12px 5px 12px 66px;
  background-color: ${props => props.workActive && '#D7E3FA'};
  background-color: ${props => props.isUnread && '#ebf2ff'};
  font-weight: ${props => props.isUnread && 'bold'};
`

export const ListWrapper = styled(List)`
  min-height: 200px;
  width: 1050px;
  min-width: 100%;
  table-layout: auto;
  .ant-list-header {
    background-color: ${blue};
    color: #fff;
    padding-left: 20px;
    padding-right: 5px;
  }
  .ant-spin-nested-loading {
    //min-height: calc(100vh - 350px) !important;
  }

  .spinner {
    min-height: 150px;
  }
  .ant-list-item {
    padding-left: 20px;
    padding-right: 5px;
    cursor: pointer;
    border-bottom: 1px solid #fff;
    &:hover {
      background-color: #e9f0fd !important;
    }
  }

  .work-active {
    background-color: #d7e3fa !important;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  .date-work {
    margin-left: 20px;
    width: 80px;
  }

  .assign {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 220px;
    @media screen and (max-width: 1366px) {
      width: 180px;
    }

    ul {
      justify-content: flex-start;
    }
  }

  .title-work {
    flex-grow: 1;
    margin: -12px 0px -12px 8px;
    padding: 20px 10px;
  }

  .title-header {
    margin-left: -16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
  }

  .title-subwork {
    flex-grow: 1;
    margin: -12px 0px -12px -48px;
    padding: 10px 10px 10px 60px;
  }

  .ant-collapse-item {
    border: none;
  }

  .parent-work-item {
    cursor: pointer;
  }

  .item-unread {
    background-color: #ebf2ff;
    font-weight: bold;
  }

  .ant-collapse-header {
    padding-left: 20px !important;
    position: relative;

    &:hover {
      background-color: #e9f0fd !important;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: block;
  }

  .complete-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 58px;
  }

  .start-children {
    cursor: pointer;
    transition: 0.4s;
    z-index: 4;
    position: relative;
    &:hover {
      transform: scale(1.2);
    }
  }
`
export const StarIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${props => (props.hasSubWork ? '12px' : '34px')};
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    transform: scale(1.2) translateY(-50%);
  }
`

export const PopoverList = styled.ul`
  ::-webkit-scrollbar {
    width: 4px;
  }

  min-width: 300px;
  max-width: 450px;
  list-style: none;
  max-height: 600px;
  margin: -1rem;
  padding: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;

  .popover-head {
    display: flex;
    justify-content: space-between;
    margin: -1rem -1rem 10px -1rem;
    padding: 10px 15px;
    border-bottom: 1px solid #f2f2f2;
    color: ${blue};
    column-gap: 20px;
    background-color: #f0f0f0;
  }

  li {
    border-bottom: 1px solid #f0f0f0;
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;

    span {
      flex-grow: 1;
    }

    time {
      flex: 0 0 130px;
      width: 130px;
    }
  }
`

export const ContentBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.user {
    justify-content: flex-start;
    width: 220px;
    @media screen and (max-width: 1366px) {
      width: 180px;
    }
  }

  &.work-type-width {
    width: 110px;
  }

  &.time-width {
    width: 130px !important;
  }
`

export const BlockLeft = styled.div`
  display: flex;
  column-gap: 10px;
  margin-right: 40px;
`

export const BlockContent = styled.div`
  overflow: auto;
  max-height: 750px;
`

import { PlusOutlined } from '@ant-design/icons'
import { Button, Menu, message, Modal } from 'antd'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { memo, useEffect, useState } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import AccessControlAction from '../../../components/AccessControlAction'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import DashboardLayout from '../../../layouts/DashboardLayout'
import ApprovalTemplateCreate from '../ApprovalTemplate/ApprovalTemplateCreate'

const AdministrativeManagementLayout = props => {
  const { selectedKey, children, title } = props
  const { authenticationStore } = props
  const { isAccountAdmin, isSuperAdmin } = authenticationStore
  const history = useHistory()
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)

  useEffect(() => {
    if (isAccountAdmin || isSuperAdmin || !authenticationStore.currentUser) {
      return
    }

    history.replace('/')
    message.error('Bạn không có quyền truy cập trang này!')
  }, [authenticationStore.currentUser])

  const handleToggleCreateModal = bool => event => {
    setIsVisibleCreateModal(bool)
    if (!bool) {
      runInAction(() => {
        props.approvalTemplateStore.approvalTemplateLevels = []
      })
    }
  }

  return (
    <DashboardLayout>
      {title}
      <PageTitle location={props.location} title={'Quản trị văn bản'}>
        {selectedKey === 'approval-templates' && (
          <AccessControlAction
          // aclActionType={ACL_ACTION_TYPE.create__PROPOSAL_ADVANCE}
          >
            <Button
              onClick={handleToggleCreateModal(true)}
              type={'primary'}
              icon={<PlusOutlined />}>
              Tạo mới
            </Button>
          </AccessControlAction>
        )}
      </PageTitle>
      <ContentBlockWrapper>
        <Menu
          mode="horizontal"
          selectedKeys={[selectedKey]}
          style={{ marginBottom: 16 }}>
          <Menu.Item key="approval-templates">
            <Link to={'/administrative-management/approval-templates'}>
              Mẫu duyệt
            </Link>
          </Menu.Item>
          <Menu.Item key="content-template">
            <Link to={'/administrative-management/content-template'}>
              Biểu mẫu
            </Link>
          </Menu.Item>
        </Menu>
        {children}
      </ContentBlockWrapper>

      <Modal
        width={1000}
        title={'Tạo mới mẫu duyệt'}
        visible={isVisibleCreateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleCreateModal && (
          <ApprovalTemplateCreate onCancel={handleToggleCreateModal(false)} />
        )}
      </Modal>
    </DashboardLayout>
  )
}

export default memo(
  withRouter(
    inject('authenticationStore', 'approvalTemplateStore')(observer(AdministrativeManagementLayout))
  )
)

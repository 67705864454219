import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ProposalSalaryRequest = {
  createSalary: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/proposal-salary-request`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

    getSalaryDetail: salaryCode =>
      axios({
        method: 'get',
        url: `${apiUrl}/api/v1/proposal-salary-request/${salaryCode}`,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(authenticationStore.appToken).access_token
          }`,
          'Content-Type': 'application/json',
        },
      }),

    getSalaryRequestDetail: salaryRequestCode => 
      axios({
        method: 'get',
        url: `${apiUrl}/api/v1/salary/${salaryRequestCode}`,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(authenticationStore.appToken).access_token
          }`,
          'Content-Type': 'application/json',
        },
    }),

    updateProposalSalary: (salaryCode, payload) =>
      axios({
        method: "put",
        url: `${apiUrl}/api/v1/proposal-salary-request/${salaryCode}`,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(authenticationStore.appToken).access_token
          }`,
          "Content-Type": "application/json",
        },
        data: payload,
    })
}

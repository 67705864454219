import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Dropdown,
  Empty,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Space,
} from 'antd'
import TableComponent from '../../components/Common/TableComponent'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  FolderOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import FolderAddOutlined from '@ant-design/icons/lib/icons/FolderAddOutlined'
import CloudUploadOutlined from '@ant-design/icons/lib/icons/CloudUploadOutlined'
import CreateISOFolderModal from './CreateISOFolder/CreateISOFolderModal'
import UploadIsoFileModal from './UploadISOFile/UploadISOFileModal'
import { iconMoreVert } from '../../ui_constants'
import { blue } from '../../color'
import { ACL_ACTION_TYPE } from '../../constants'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import RenameDocumentModal from './RenameDocumentModal/RenameDocumentModal'
import { toJS } from 'mobx'
import HomeOutlined from '@ant-design/icons/lib/icons/HomeOutlined'
import { SearchBar } from './ISODocumentPageStyled'
import date_format from '../../date_format'
import date_utils from '../../date_utils'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import MoveDocumentModal from './MoveDocumentModal/MoveDocumentModal'

const { Search } = Input

const IsoDocumentPage = props => {
  const history = useHistory()
  const location = useLocation()
  const queryStringParsed = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  const { isoDocumentStore, aclStore } = props

  const { aclActionsByUser } = aclStore

  const { items, breadCrumb, isLoading } = isoDocumentStore
  const { currentFolderId, keyword } = isoDocumentStore
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  const [showUploadFileModal, setShowUploadFileModal] = useState(false)

  const [showRenameDocumentModal, setShowRenameDocumentModal] = useState(false)
  const [showMoveDocumentModal, setShowMoveDocumentModal] = useState(false)
  const [currentDocument, setCurrentDocument] = useState(null)
  const [textKeyword, setTextKeyword] = useState(null)

  useEffect(() => {
    // console.log('currentFolderId', currentFolderId)
    // console.log('queryStringParsed', queryStringParsed.folder_id)
    // console.log('keyword', keyword)

    if (queryStringParsed.folder_id) {
      isoDocumentStore.currentFolderId = queryStringParsed.folder_id
    }

    if (keyword) {
      isoDocumentStore.search()
    } else {
      isoDocumentStore.getItems()
    }
  }, [currentFolderId, keyword])

  useEffect(() => {
    return () => {
      isoDocumentStore.clear()
    }
  }, [])

  function handleChange(pagination, filters, sorter) {
    // console.log('params', pagination, filters, sorter)
    isoDocumentStore.sortInfo = {
      sortedInfo: sorter,
    }
  }

  const columns = () => {
    const sortedInfo = isoDocumentStore.sortInfo.sortedInfo || {}
    return [
      {
        align: 'left',
        title: 'Tên',
        key: 'displayName',
        dataIndex: 'displayName',
        sorter: (a, b) => {
          if (a && b) return b.displayName.localeCompare(a.displayName)
          else return 0
        },
        sortOrder: sortedInfo.columnKey === 'displayName' && sortedInfo.order,
        render: (text, record) => {
          return (
            <div key={record.id}>
              {record.extension === null ? (
                <span onClick={() => openFolder(record.id)}>
                  {<FolderOutlined />} {record.displayName}
                </span>
              ) : (
                <>
                  <FileItem
                    key={record.id}
                    file_id={record.id}
                    file_name={`${record.displayName}.${record.extension}`}
                    file_type={record.extension}
                  />
                </>
              )}
            </div>
          )
        },
      },
      {
        title: 'Lần truy cập gần nhất',
        key: 'lastViewAt',
        dataIndex: 'lastViewAt',
        sorter: (a, b) => {
          if (a.lastViewAt && b.lastViewAt)
            return date_utils.compare(
              date_utils.fromString(b.lastViewAt),
              date_utils.fromString(a.lastViewAt)
            )
          else return 0
        },
        sortOrder: sortedInfo.columnKey === 'lastViewAt' && sortedInfo.order,
        render: (text, record) => (
          <div key={record.id}>
            {record.lastViewAt
              ? date_format.HH_mm_DD_MM_YYYY(record.lastViewAt)
              : ''}
          </div>
        ),
      },
      {
        align: 'right',
        title: 'Thao tác',
        render: record => (
          <Dropdown
            overlay={() => menuControlNotification(record)}
            placement="bottomLeft"
            arrow
            trigger={['click']}>
            <MoreOutlined style={iconMoreVert} />
          </Dropdown>
        ),
      },
    ]
  }

  const handleClickRename = item => {
    setCurrentDocument(item)
    setShowRenameDocumentModal(true)
  }

  const handleClickMove = item => {
    setCurrentDocument(item)
    setShowMoveDocumentModal(true)
  }

  const { confirm } = Modal

  const handleClickDelete = id => {
    function showDeleteConfirm(id) {
      confirm({
        title: 'Bạn có chắc muốn xóa tài liệu này ?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Đồng ý',
        okType: 'danger',
        cancelText: 'Không',
        onOk: async () => {
          try {
            await isoDocumentStore.delete(id)
          } catch (e) {
            message.error(e.vi)
          } finally {
            isoDocumentStore.isLoading = false
          }
        },
        onCancel() {},
      })
    }

    showDeleteConfirm(id)
  }

  const menuControlNotification = item => {
    return (
      <Menu>
        {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.rename__ISO) && (
          <Menu.Item
            key={'rename'}
            onClick={() => handleClickRename(item)}
            style={{ color: blue }}
            icon={<EditOutlined />}>
            Đổi tên
          </Menu.Item>
        )}
        {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.move__ISO) && (
          <Menu.Item
            key={'move'}
            onClick={() => handleClickMove(item)}
            style={{ color: blue }}
            icon={<ArrowRightOutlined />}>
            Di chuyển
          </Menu.Item>
        )}
        {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.delete__ISO) && (
          <Menu.Item
            key={'delete'}
            onClick={() => handleClickDelete(item.id)}
            danger
            icon={<DeleteOutlined />}>
            Xóa
          </Menu.Item>
        )}
      </Menu>
    )
  }

  const renderBreadcrumb = () => {
    return (
      <Breadcrumb style={{ marginBottom: 0 }}>
        <Breadcrumb.Item key="home" href="/dashboard">
          <HomeOutlined />
        </Breadcrumb.Item>
        {breadCrumb.map(item => (
          <Breadcrumb.Item onClick={() => openFolder(item.id)} key={item.id}>
            <a>{item.name}</a>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    )
  }

  const openFolder = folderId => {
    isoDocumentStore.currentFolderId = folderId
    isoDocumentStore.keyword = null
    setTextKeyword(null)

    const queryStringStringify = queryString.stringify({
      folder_id: folderId,
    })
    // window.history.replaceState(null, null, `${queryStringStringify}`)
    history.replace({ search: `?folder_id=${folderId}` })
  }

  const onCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false)
  }

  const onCloseUploadFileModal = () => {
    setShowUploadFileModal(false)
  }

  const onCloseRenameDocumentModal = () => {
    setShowRenameDocumentModal(false)
  }

  const onCloseMoveDocumentModal = () => {
    setShowMoveDocumentModal(false)
  }

  const onSearch = keyword => {
    isoDocumentStore.keyword = keyword
  }

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Tài liệu ISO</title>
        </Helmet>
        <PageTitle customBreadcrumb={renderBreadcrumb()} />
        <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
          <Row align={'middle'}>
            <Col flex={'auto'}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={onSearch}
                  onChange={value => setTextKeyword(value.target.value)}
                  value={textKeyword}
                  placeholder={'Tìm kiếm thư mục, tài liệu'}
                />
              </SearchBar>
            </Col>
            <Col>
              <Row justify="space-between" align="middle">
                <Space direction={'horizontal'}>
                  {aclStore.checkAccessControlAction(
                    ACL_ACTION_TYPE.create__ISO
                  ) && (
                    <Button
                      icon={<FolderAddOutlined />}
                      type={'primary'}
                      onClick={() => setShowCreateFolderModal(true)}>
                      Tạo thư mục
                    </Button>
                  )}
                  {aclStore.checkAccessControlAction(
                    ACL_ACTION_TYPE.upload__ISO
                  ) && (
                    <Button
                      icon={<CloudUploadOutlined />}
                      onClick={() => setShowUploadFileModal(true)}>
                      Tải file lên
                    </Button>
                  )}
                </Space>
              </Row>
            </Col>
          </Row>

          <Divider style={{ marginTop: 16, marginBottom: 0 }} />

          <TableComponent
            rowKey="id"
            dataSource={items}
            columns={columns()}
            showHeader={true}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'Không có dữ liệu'}
                />
              ),
            }}
            loading={isLoading}
            onChange={handleChange}
          />
        </ContentBlockWrapper>
      </DashboardLayout>

      <CreateISOFolderModal
        visible={showCreateFolderModal}
        parentFolderId={isoDocumentStore.currentFolderId}
        onClose={onCloseCreateFolderModal}
      />

      <UploadIsoFileModal
        visible={showUploadFileModal}
        parentFolderId={isoDocumentStore.currentFolderId}
        onClose={onCloseUploadFileModal}
      />

      <RenameDocumentModal
        visible={showRenameDocumentModal}
        currentDocument={toJS(currentDocument)}
        onClose={onCloseRenameDocumentModal}
      />

      <MoveDocumentModal
        visible={showMoveDocumentModal}
        currentDocument={toJS(currentDocument)}
        parentFolderId={currentFolderId}
        onClose={onCloseMoveDocumentModal}
      />
    </>
  )
}

IsoDocumentPage.propTypes = {}

export default inject('isoDocumentStore', 'aclStore')(observer(IsoDocumentPage))

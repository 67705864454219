import React, { useEffect, useState } from 'react'
import { Empty, Table } from 'antd'
import { SalaryManagerWrapper } from './SalaryPageStyled'
import { toJS } from 'mobx'
import utils from '../../utils'
import { EmptyText } from '../../components/Common/CellText'
import SalaryManagerModal from './SalaryManagerModal'

const SalaryManager = props => {
  const { managerSalaryData } = props

  const [managerSalaryList, setManagerSalaryList] = useState([])

  useEffect(() => {
    if (
      !managerSalaryData.manager_salary_list ||
      managerSalaryData.manager_salary_list.length === 0
    )
      return

    setManagerSalaryList([
      ...toJS(managerSalaryData).manager_salary_list,
      {
        isTotal: true,
        full_name: 'Tổng cộng',
        so_ngay_cong_di_lam: managerSalaryData.tong_so_ngay_di_lam,
        con_nhan: managerSalaryData.tong_con_nhan,
        tong_tien_luong_nhan: managerSalaryData.tong_tien_luong_trong_thang,
        tong_cac_khoan_khau_tru: managerSalaryData.tong_cac_khoan_khau_tru,
        ma_nv: 1,
      },
    ])
  }, [managerSalaryData])

  const [
    isVisibleSalaryManagerModal,
    setIsVisibleSalaryManagerModal,
  ] = useState(false)
  const [salaryDetail, setSalaryDetail] = useState({})
  const tableColumns = [
    {
      align: 'center',
      // width: 130,
      title: 'Họ tên',
      render: record => (
        <span
          style={{
            textTransform: 'capitalize',
            fontWeight: `${record.isTotal ? 'bold' : 400}`,
          }}>
          {record.full_name}
        </span>
      ),
    },
    {
      align: 'center',
      // width: 180,
      title: (
        <span>
          Ngày công
          <br /> đi làm thực tế
        </span>
      ),
      render: record => (
        <span style={{ fontWeight: `${record.isTotal ? 'bold' : 400}` }}>
          {record.so_ngay_cong_di_lam ? (
            utils.formatCurrency(record.so_ngay_cong_di_lam)
          ) : (
            <EmptyText>Không rõ</EmptyText>
          )}
        </span>
      ),
    },
    {
      align: 'center',
      title: 'Tiền lương được nhận',
      render: record => (
        <span style={{ fontWeight: `${record.isTotal ? 'bold' : 400}` }}>
          {record.tong_tien_luong_nhan ? (
            utils.formatCurrency(record.tong_tien_luong_nhan)
          ) : (
            <EmptyText>Không rõ</EmptyText>
          )}
        </span>
      ),
    },
    {
      align: 'center',
      title: 'Các khoản khấu trừ',
      render: record => (
        <span style={{ fontWeight: `${record.isTotal ? 'bold' : 400}` }}>
          {record.tong_cac_khoan_khau_tru ? (
            utils.formatCurrency(record.tong_cac_khoan_khau_tru)
          ) : (
            <EmptyText>Không rõ</EmptyText>
          )}
        </span>
      ),
    },
    {
      align: 'center',
      title: 'Còn nhận',
      render: record => (
        <span style={{ fontWeight: `${record.isTotal ? 'bold' : 400}` }}>
          {record.con_nhan ? (
            utils.formatCurrency(record.con_nhan)
          ) : (
            <EmptyText>Không rõ</EmptyText>
          )}
        </span>
      ),
    },
  ]

  return (
    <SalaryManagerWrapper>
      <Table
        onRow={record => {
          return {
            onClick: () => {
              if (record.isTotal) return
              setSalaryDetail(record)
              setIsVisibleSalaryManagerModal(true)
            },
          }
        }}
        rowClassName={'row-table'}
        rowKey={'ma_nv'}
        dataSource={managerSalaryList}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 900 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
      />
      <SalaryManagerModal
        salaryDetail={salaryDetail}
        isVisibleSalaryManagerModal={isVisibleSalaryManagerModal}
        handleCloseSalaryManagerModal={() =>
          setIsVisibleSalaryManagerModal(false)
        }
      />
    </SalaryManagerWrapper>
  )
}

SalaryManager.propTypes = {}

export default SalaryManager

import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, message, Modal, TreeSelect } from 'antd'
import { inject, observer } from 'mobx-react'
import { FormButtonGroup } from './StaffModalStyled'

const { SHOW_PARENT } = TreeSelect

const StaffModal = props => {
  const [form] = Form.useForm()

  const {
    modalVisible,
    onClose,
    onOk,
    isMultiSelect,
    title,
    departmentStore,
    staffStore,
  } = props

  const { departmentList } = departmentStore
  const { selectedStaffList } = staffStore

  const onChange = useCallback(value => {
    if (Array.isArray(value)) {
      staffStore.setSelectedStaffList(value)
      return
    }
    staffStore.setSelectedStaffList([value])
  }, [])
  const handleClear = useCallback(() => {
    staffStore.clearSelectedStaffList()
    form.setFieldsValue({
      staff_list: isMultiSelect ? [] : undefined,
    })
  }, [isMultiSelect])
  const handleCloseModal = useCallback(() => {
    onClose()
    handleClear()
  }, [onClose])
  const handleAddStaff = useCallback(() => {
    if (selectedStaffList.length === 0) {
      message.error('Vui lòng chọn thành viên!')
      return
    }
    onOk()
    handleClear()
  }, [selectedStaffList])

  return (
    <Modal
      title={title || 'Danh sách thành viên'}
      onCancel={handleCloseModal}
      visible={modalVisible}
      footer={false}>
      <Form form={form} name={'staff-selection-form'} layout={'vertical'}>
        <Form.Item name={'staff_list'}>
          <TreeSelect
            treeDataSimpleMode
            onChange={onChange}
            style={{ width: '100%' }}
            treeData={departmentList}
            filterTreeNode
            treeNodeFilterProp={'name'}
            showArrow
            allowClear
            showSearch
            labelInValue={true}
            {...(isMultiSelect ? { showCheckedStrategy: SHOW_PARENT } : {})}
            treeCheckable={isMultiSelect}
            placeholder={'Chọn thành viên'}
          />
        </Form.Item>
        <FormButtonGroup>
          <Button key="cancel" onClick={handleCloseModal}>
            Huỷ bỏ
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            onClick={handleAddStaff}
            key="submit"
            type="primary">
            Thêm thành viên
          </Button>
        </FormButtonGroup>
      </Form>
    </Modal>
  )
}

StaffModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

export default memo(
  inject('departmentStore', 'staffStore')(observer(StaffModal))
)

import styled, { css } from 'styled-components'
import { Button } from 'antd'
import { green } from '../../color'

export const ManagementListWrapper = styled.div`
  height: calc(100vh - 92px);
  margin-bottom: 0;
  background-color: #fff;
  border-radius: 6px;
`
export const ManagementHeader = styled.div`
  height: 100px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem;
`

export const FormButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 30px 0;
`

export const BtnFilter = styled(Button)`
  position: relative;
  width: 80px;

  &:focus {
    .badge {
      position: absolute;
    }
  }

  .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: ${green};
    border-radius: 50%;
    color: #fff;
  }
`

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  h3 {
    margin-bottom: 0;
  }
`

export const ListWrapper = styled.div`
  height: calc(100vh - 192px);
  padding: 1rem;
  overflow-y: auto;
  ${props =>
    !props.hasData &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`
export const PolicyItem = styled.div`
  position: relative;
  padding: 12px;
  background: ${props => (props.isSelected ? '#EBF5FD' : 'white')};
  border-radius: 10px;
  border: ${props =>
    props.isSelected
      ? `1px solid ${props.theme.solidColor}`
      : '1px solid transparent'};
  transition: all ease 0.3s;
  margin-bottom: 1rem;

  &:after {
    content: '';
    left: 0.5rem;
    right: 0.5rem;
    border-bottom: 1px solid #f0f0f0;
    bottom: -0.5rem;
    position: absolute;
    display: block;
  }

  .small {
    font-size: 12px;
  }

  .anticon {
    font-size: 12px;
    color: ${props => props.theme.solidColor};
    margin-right: 0.25rem;
  }

  .task-status {
    margin-top: 0.35rem;

    .anticon {
      font-size: 14px;
      color: inherit;
    }
  }

  .ant-row {
    margin-bottom: 0.35rem;
  }

  .ant-col {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  h4 {
    font-size: 1rem;
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }

  ${props =>
    !props.isRead &&
    css`
      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 20px;
        left: 2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${props => props.theme.solidColor};
      }
    `}
  &:last-child {
    margin-bottom: 0;
  }

  .task-code {
    color: #999;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .policy-title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    .ant-typography {
      font-weight: bold;
    }
  }

  .task-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: -1em;
    font-size: 12px;
    color: #999;
    margin-bottom: 5px;
  }

  .task-status {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    span:not(:first-child) {
      font-size: 12px;
    }
  }
`

import { observable } from 'mobx'
import isoDocumentStore from '../isoDocumentStore'
import fileStore from '../../../stores/fileStore'

class RenameISOFolderStore {
  @observable isLoading = false

  rename = async (newName, objectId) => {
    this.isLoading = true

    await fileStore.renameFile(objectId, newName)

    this.isLoading = false

    isoDocumentStore.getItems()
  }

  clearStore = () => {
    this.isLoading = false
  }
}

export default new RenameISOFolderStore()

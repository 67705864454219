import styled from 'styled-components'
import { blue } from '../../../color'
import { Tabs } from 'antd'
import React from 'react'

export const SignatureListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
`

export const SignatureBox = styled.div`
  .card {
    .img-box {
      height: 220px;
      padding: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 200px;
        width: 100%;
        object-fit: scale-down;
        max-width: fit-content;
      }
    }

    .ant-card-meta-title {
      margin-bottom: 0;
    }

    .ant-card-body {
      border-top: 1px solid #eaeaea;
      padding: 15px 10px;
      background-color: #ececec;
    }
  }

  .ant-card-actions {
    li {
      margin: 0;
      padding: 8px;
    }

    li:first-child {
      &:hover {
        span {
          color: #ffffff !important;
        }
      }
    }

    li:nth-child(2) {
      &:hover {
        background-color: #ff4d4f !important;

        span {
          color: #ffffff !important;
        }
      }
    }
  }
`

export const TabsWrapper = styled(Tabs)`
  .ant-tabs-nav {
    max-height: calc(100vh - 200px);
  }

  .ant-tabs-tab {
    width: 250px;
  }

  .ant-tabs-tab-active {
    background-color: #fafafa;

    a {
      color: ${blue} !important;
    }
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0;
`

export const TableSignatureWrapper = styled.div`
  .ant-table-cell:last-child {
    text-align: center;
  }
`

import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const InternalMessagesRequest = {
  getReceiveList: (page, size, keyword) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-message/get-receive-message`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
        keyword: keyword
      },
    }),

  getSendList: (page, size, keyword) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-message/get-send-message`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
        keyword: keyword
      },
    }),

  getReceiveMessageById: id =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-message/receive/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getSendMessageById: id =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-message/send/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getInternalMessageUserCodeByInternalMessageCode: id =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-message/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  create: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/internal-message/create-message`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
}

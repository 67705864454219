import { PlusOutlined } from '@ant-design/icons'
import { Button, Menu, message, Space } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { memo, useEffect, useState } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import AccessControlAction from '../../components/AccessControlAction'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { ACL_ACTION_TYPE, MODULE_CODE } from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout'
import AddUserGroupModal from '../UserGroupPage/AddUserGroupModal'
import SetDefaultGroupButton from '../UserGroupPage/ConfigDefaultGroupModal/SetDefaultGroupButton'

const DocumentManagementLayout = props => {
  const {
    title,
    selectedKey,
    children,
    authenticationStore,
    aclStore,
    moduleStore,
  } = props
  const { isAccountAdmin, isSuperAdmin } = authenticationStore
  const history = useHistory()
  const pathName = window.location.pathname

  const [accessVBNoiBo, setCheckVBNoiBo] = useState(false)
  const [accessLienThongVB, setAccessLienThongVB] = useState(false)
  const [accessLienThongVanBanUBQLV, setAccessLienThongVanBanUBQLV] = useState(
    false
  )
  const [accessNhomNguoiDung, setAccessNhomNguoiDung] = useState(false)

  const [isAddUserGroupModalVisible, setIsAddUserGroupModalVisible] = useState(
    false
  )

  useEffect(() => {
    if (moduleStore.checkAccessModule(MODULE_CODE.van_ban_noi_bo))
      setCheckVBNoiBo(true)

    if (moduleStore.checkAccessModule(MODULE_CODE.lien_thong_van_ban)) {
      setAccessLienThongVB(true)
    }

    if (moduleStore.checkAccessModule(MODULE_CODE.lien_thong_van_ban_ubqlv)) {
      setAccessLienThongVanBanUBQLV(true)
    }

    if (moduleStore.checkAccessModule(MODULE_CODE.nhom_nguoi_dung)) {
      setAccessNhomNguoiDung(true)
    }
  }, [moduleStore.moduleList])

  useEffect(() => {
    if (isAccountAdmin || isSuperAdmin || !authenticationStore.currentUser) {
      return
    }

    history.replace('/')
    message.error('Bạn không có quyền truy cập trang này!')
  }, [authenticationStore.currentUser])

  const isAccessTabCauHinhVB =
    pathName.includes('document-management') &&
    aclStore.checkAccessControlAction(
      ACL_ACTION_TYPE.tab_cau_hinh_vb__INTERNAL_DOCUMENT
    )

    const showAddUserGroupModal = () => {
      setIsAddUserGroupModalVisible(true)
    }

    const handleCloseAddUserGroupModal = () => {
      setIsAddUserGroupModalVisible(false)
    }

  return (
    <DashboardLayout>
      {title}
      <PageTitle location={props.location} title={'Quản trị văn bản'}>
        {selectedKey === 'user-group' && (
          <Space direction={'horizontal'}>
            <SetDefaultGroupButton isNhomNhiemVu={false} />
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.create__NHOM_NGUOI_DUNG}>
              <Button
                icon={<PlusOutlined />}
                onClick={showAddUserGroupModal}
                type="primary">
                Tạo nhóm mới
              </Button>
            </AccessControlAction>
          </Space>
        )}
      </PageTitle>
      <ContentBlockWrapper>
        <Menu
          mode="horizontal"
          selectedKeys={[selectedKey]}
          style={{ marginBottom: 16 }}>
          {isAccessTabCauHinhVB && accessVBNoiBo && (
            <Menu.Item key="internal-document">
              <Link to={'/document-management/internal-document'}>
                Văn bản nội bộ
              </Link>
            </Menu.Item>
          )}
          {accessLienThongVB && (
            <Menu.Item key="connected-document">
              <Link to={'/document-management/connected-document'}>
                Liên thông văn bản
              </Link>
            </Menu.Item>
          )}
          {accessLienThongVanBanUBQLV && (
            <Menu.Item key="connected-committee-document">
              <Link to={'/document-management/connected-committee-document'}>
                UBQLV
              </Link>
            </Menu.Item>
          )}

          {accessNhomNguoiDung && (
            <Menu.Item key="user-group">
              <Link to={'/document-management/user-group'}>
                Nhóm người dùng
              </Link>
            </Menu.Item>
          )}
        </Menu>
        {children}
      </ContentBlockWrapper>

      <AddUserGroupModal
        isAddUserGroupModalVisible={isAddUserGroupModalVisible}
        handleCloseAddUserGroupModal={handleCloseAddUserGroupModal}
      />
    </DashboardLayout>
  )
}

export default memo(
  withRouter(
    inject(
      'authenticationStore',
      'aclStore',
      'moduleStore'
    )(observer(DocumentManagementLayout))
  )
)

import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import { Dropdown, Menu, message, Modal } from 'antd'
import {
  BlockLeft,
  BlockRight,
  DocumentContentWrapper,
  DocumentHeadingWrapper,
  DocumentInfoList,
  HeadingButtonWrapper,
  HeadingTitleWrapper,
} from '../../layouts/ConnectedDocumentDetailLayout/ConnectedDocumentDetailLayoutStyled'
// PDF Viewer
import {
  CalendarOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import PDFViewer from '../../components/PDFViewer'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { useMediaQuery } from 'react-responsive'
import TrucWrapper from '../../components/TrucWrapper/TrucWrapper'
import {
  EmptyText,
  TitleContentBox,
  WordBreak,
} from '../../components/Common/CellText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import { blue } from '../../color'
import utils from '../../utils'
import moment from 'moment'
import { ACL_ACTION_TYPE, DATE_FORMAT_DEFAULT } from '../../constants'
import ConnectedDocumentIncomingDocHistoryBlock from '../../components/ConnectedDocumentIncomingDocHistoryBlock'
import PopoverWatchTime from '../../components/PopoverWatchTime'

const { confirm } = Modal

const ConnectedDocumentIncomingDocDetailPage = props => {
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const {
    match,
    commonStore,
    connectedDocumentStore,
    loadingAnimationStore,
    authenticationStore,
    fileStore,
    aclStore,
  } = props

  const { trucToken, currentUserTruc } = authenticationStore
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore
  const { documentId } = match.params

  const { selectedIncomingDocument, actionLogList } = connectedDocumentStore
  const {
    attachments,
    code,
    documentType,
    userFrom,
    priority,
    promulgationDate,
    signer,
    subject,
    listUserTo,
    view_logs,
  } = selectedIncomingDocument

  const [loading, setLoading] = useState(false)

  const renderPriorityName = priorityId => {
    switch (priorityId) {
      case '0':
      case 0:
        return 'Thường'
      case '1':
      case 1:
        return 'Khẩn'
      case '2':
      case 2:
        return 'Thượng khẩn'
      case '3':
      case 3:
        return 'Hỏa tốc'
      case '4':
      case 4:
        return 'Hỏa tốc hẹn giờ'
    }
  }

  const renderDocumentTypeName = typeId => {
    switch (typeId) {
      case '1':
        return 'Văn bản hành chính'
      case '2':
        return 'Văn bản quy phạm pháp luật'
      default:
        return ''
    }
  }

  const [attachmentForView, setAttachmentForView] = useState()

  useEffect(() => {
    if (!attachments) return
    setAttachmentForView(
      attachments.find(file =>
        ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.name))
      )
    )
  }, [attachments])

  const renderAttachmentFile = useMemo(
    () => (
      <PDFViewer
        fileId={attachmentForView?.fileId}
        customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
        fileType={utils.getExtensionFile(attachmentForView?.name)}
      />
    ),
    [attachmentForView]
  )

  const handleDeleteDoc = () => {
    loadingAnimationStore.showSpinner(true)
    connectedDocumentStore
      .deleteIncomingDocumentByID(documentId)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        props.history.goBack()
        message.success('Xóa văn bản thành công!')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }

  const handleClickReceiveDocument = () => {
    connectedDocumentStore.setDataReceiveDocument(selectedIncomingDocument)
    props.history.push('/internal-document/incoming-document/create')
  }

  const showConfirm = () => {
    confirm({
      title: 'Bạn có muốn xóa văn bản này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      onOk() {
        handleDeleteDoc()
      },
    })
  }

  const renderButtonHandleDocumentDropDown = (
    <Menu>
      {aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.receive__LIEN_THONG_INCOMING
      ) && (
        <Menu.Item
          icon={<ProfileOutlined />}
          onClick={handleClickReceiveDocument}
          style={{ color: blue }}>
          Nhận văn bản
        </Menu.Item>
      )}
      {aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.delete__LIEN_THONG_INCOMING
      ) && (
        <Menu.Item danger onClick={showConfirm} icon={<DeleteOutlined />}>
          Xóa văn bản
        </Menu.Item>
      )}
    </Menu>
  )

  useEffect(() => {
    ;(async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        const response = await connectedDocumentStore.getIncomingDocumentById(
          documentId
        )
        await connectedDocumentStore.getLogDocument(documentId, 'DOC_OUTGOING')
        const filePDFList = response.data.attachments
          ? response.data.attachments.filter(x =>
              x.name.toLocaleLowerCase().includes('.pdf')
            )
          : []
        if (filePDFList.length === 0) {
          loadingAnimationStore.showSpinner(false)
          return
        }
        await connectedDocumentStore.getIncomingDocumentAttachmentById(
          documentId,
          filePDFList[0].fileId
        )
      } catch (err) {
        props.history.push('/connected-document/incoming-document')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      connectedDocumentStore.clearSelectedIncomingDocument()
      connectedDocumentStore.clearLogDocument()
    }
  }, [documentId])

  useEffect(() => {
    authenticationStore
      .checkCurrentUserTruc()
      .then(() => Promise.resolve())
      .catch(err => console.log(err))
  }, [])

  return (
    <TrucWrapper>
      <DashboardLayout>
        <Helmet>
          <title>{`${subject} | VIMC Liên thông văn bản`}</title>
        </Helmet>
        <DocumentHeadingWrapper>
          <HeadingTitleWrapper>
            <PageTitle
              location={props.location}
              title={subject}
              showTitle={true}
              color={commonStore.appTheme.solidColor}
            />
          </HeadingTitleWrapper>
          <HeadingButtonWrapper>
            <Dropdown
              overlay={renderButtonHandleDocumentDropDown}
              trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </HeadingButtonWrapper>
        </DocumentHeadingWrapper>
        <DocumentContentWrapper>
          <BlockLeft>{renderAttachmentFile}</BlockLeft>

          <BlockRight>
            <ContentBlockWrapper>
              <TitleContentBox>Thông tin chung</TitleContentBox>
              <DocumentInfoList>
                <dt>Số hiệu</dt>
                <dd>{code && code}</dd>
                <dt>Loại văn bản</dt>
                <dd>
                  {documentType ? (
                    renderDocumentTypeName(documentType)
                  ) : (
                    <EmptyText>Không có loại văn bản.</EmptyText>
                  )}
                </dd>
                <dt>CQ ban hành</dt>
                <dd>{userFrom && WordBreak(userFrom)}</dd>
                <dt>Nơi nhận</dt>
                <dd style={{ marginBottom: 8 }}>
                  {listUserTo && listUserTo.length > 0 ? (
                    <PopoverWatchTime
                      userList={listUserTo}
                      title={'Nơi nhận'}
                    />
                  ) : (
                    <p>Không có</p>
                  )}
                </dd>
                <dt>Độ quan trọng</dt>
                <dd>
                  {priority ? (
                    renderPriorityName(priority)
                  ) : (
                    <EmptyText>Không có độ quan trọng.</EmptyText>
                  )}
                </dd>
                <dt>Ngày ban hành</dt>
                <dd>
                  {promulgationDate ? (
                    <>
                      <CalendarOutlined
                        style={{ color: blue, marginRight: 4 }}
                      />
                      <span>
                        {moment(promulgationDate).format(DATE_FORMAT_DEFAULT)}
                      </span>
                    </>
                  ) : (
                    <EmptyText>Không rõ ngày ban hành.</EmptyText>
                  )}
                </dd>
                <dt>Người ký</dt>
                <dd>
                  {signer ? signer : <EmptyText>Không có người ký.</EmptyText>}
                </dd>
                <dt>Tài liệu đính kèm</dt>
                <dd>
                  <div>
                    {attachments?.length ? (
                      attachments.map(file => (
                        <FileItem
                          key={file.fileId}
                          file_id={file.fileId}
                          file_name={file.name}
                          file_type={utils.getExtensionFile(file.name)}
                        />
                      ))
                    ) : (
                      <EmptyText>Không có tài liệu đính kèm.</EmptyText>
                    )}
                  </div>
                </dd>
                <dt>Đã xem</dt>
                <dd style={{ marginBottom: 8 }}>
                  {view_logs && view_logs.length > 0 ? (
                    <PopoverWatchTime userList={view_logs} title={'Đã xem'} />
                  ) : (
                    <p>Không có</p>
                  )}
                </dd>
              </DocumentInfoList>
            </ContentBlockWrapper>
            {actionLogList.length > 0 && (
              <>
                <div style={{ marginBottom: 15 }} />
                <ContentBlockWrapper>
                  <ConnectedDocumentIncomingDocHistoryBlock
                    history={actionLogList}
                    title={'Lịch sử văn bản đến'}
                  />
                </ContentBlockWrapper>
              </>
            )}
          </BlockRight>
        </DocumentContentWrapper>
      </DashboardLayout>
    </TrucWrapper>
  )
}

ConnectedDocumentIncomingDocDetailPage.propTypes = {}

export default inject(
  'commonStore',
  'connectedDocumentStore',
  'loadingAnimationStore',
  'fileStore',
  'authenticationStore',
  'aclStore',
)(observer(ConnectedDocumentIncomingDocDetailPage))

import styled from 'styled-components'
import { Modal } from 'antd'
import { blue } from '../../../../color'

export const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    min-height: 250px;
  }
`

export const TitleModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title-modal {
    font-size: 14px;
    font-weight: 500;
    color: ${blue};
  }
`

export const LableItem = styled.div`
  font-weight: 500;
  margin-bottom: 6px;
  padding-left: ${props => props.required && '8px'};
  position: relative;

  &:before {
    content: '*';
    color: red;
    position: absolute;
    font-weight: bold;
    left: 0;
    top: 0;
    opacity: ${props => (props.required ? 1 : 0)};
  }
`

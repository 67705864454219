import React from 'react'
import CommentEditor from '../../components/RichEditor/CommentEditor'

const Demo = () => {
  return (
    <div className="">
      {/* <DemoTagPage/> */}
      <CommentEditor />
    </div>
  )
}

export default Demo

import React, { useEffect, useState } from 'react'
import { TaskInfoList } from '../MissionDetailPage/MissionDetailPageStyled'
import { blue } from '../../color'
import { Col, message, Row } from 'antd'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { TasDetailInfoBox } from './MissionPageStyled'
import MissionDetailGeneralInfo from '../MissionDetailPage/MissionDetailGeneralInfo'
import MissionDetailUpdate from '../MissionDetailPage/MissionDetailUpdate'
import MissionDetailAssign from '../MissionDetailPage/MissionDetailAssign'
import MissionDetailFiles from '../MissionDetailPage/MissionDetailFiles'
import MissionDetailWorks from '../MissionDetailPage/MissionDetailWorks'

const MissionPageDetail = props => {
  const {
    style,
    taskStore,
    taskCode,
    taskId,
    missionDetail,
    isMissionFull,
    handleSetIsRefreshListTask,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [taskDetail, setTaskDetail] = useState({
    contentTask: null,
    resultTask: null,
    leader: [],
    handler: [],
    combiner: [],
    canAddWork: false,
    canUpdateProgress: false,
  })

  useEffect(() => {
    if (isMissionFull) {
      setTaskDetail(missionDetail)
      return
    }
    ;(async () => {
      try {
        setIsLoading(true)
        await taskStore.getTaskDetail(taskCode)
        setTaskDetail(toJS(taskStore.taskDetail))
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra')
      } finally {
        // await new Promise(resolve => setTimeout(resolve, 1500))
        setIsLoading(false)
      }
    })()
  }, [missionDetail])

  useEffect(() => {
    if (isMissionFull) return
    if (isLoading) {
      props.onLoading(taskId)
    } else {
      props.onLoading(null)
    }
  }, [isLoading])

  const handleSetTaskDetail = payload => {
    setTaskDetail(payload)
  }

  return (
    <div style={{ backgroundColor: '#f0f0f0', ...style }}>
      <Row
        gutter={[15, 15]}
        style={{
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 7,
          marginTop: 7,
        }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
          <TasDetailInfoBox>
            <ContentBlockWrapper style={{ paddingTop: 5 }}>
              <h3
                style={{
                  marginBottom: 0,
                  color: blue,
                  fontSize: 16,
                  textDecoration: 'underline',
                }}>
                Thông tin chung
              </h3>
              <TaskInfoList>
                {taskDetail.contentTask && (
                  <MissionDetailGeneralInfo
                    contentTask={taskDetail.contentTask}
                  />
                )}
                {taskDetail.resultTask && (
                  <MissionDetailUpdate
                    handleSetIsRefreshListTask={handleSetIsRefreshListTask}
                    handleSetTaskDetail={handleSetTaskDetail}
                    canUpdateProgress={!!taskDetail.canUpdateProgress}
                    isDetailList
                    missionId={taskCode}
                    files={taskDetail.resultTask.files || []}
                    resultTask={taskDetail.resultTask}
                    contentTask={taskDetail.contentTask}
                  />
                )}
              </TaskInfoList>
            </ContentBlockWrapper>
          </TasDetailInfoBox>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          {taskDetail.contentTask && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
              <MissionDetailAssign
                isDetailList
                leader={taskDetail.leader}
                handler={taskDetail.handler}
                combiner={taskDetail.combiner}
                capGiao={taskDetail.contentTask?.cap_giao}
              />
              <MissionDetailFiles
                handleSetIsRefreshListTask={handleSetIsRefreshListTask}
                handleSetTaskDetail={handleSetTaskDetail}
                canUpdateProgress={!!taskDetail.canUpdateProgress}
                missionId={taskCode}
                isDetailList
                files={taskDetail.resultTask?.files || []}
                resultTask={taskDetail.resultTask}
                title="Tài liệu đính kèm"
              />
              <MissionDetailWorks
                handleSetTaskDetail={handleSetTaskDetail}
                canAddWork={!!taskDetail.canAddWork}
                missionId={taskCode}
                isDetailList
                works={taskDetail.contentTask?.linkedWork}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

MissionPageDetail.propTypes = {}
export default inject(
  'taskStore',
  'authenticationStore',
  'selectPeopleStore',
)(observer(MissionPageDetail))

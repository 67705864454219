import React, { useEffect } from 'react'

import { Alert, Card } from 'antd'
import { EmptyText } from '../../components/Common/CellText'
import { ModalSalaryDetailWrapper } from './SalaryPageStyled'
import utils from '../../utils'

const SalaryManagerModal = props => {
  const {
    salaryDetail,
    isVisibleSalaryManagerModal,
    handleCloseSalaryManagerModal,
  } = props

  useEffect(() => {
    console.log(
      'quyet_toan_tncn_con_phai_thu_hoan',
      salaryDetail?.quyet_toan_tncn_con_phai_thu_hoan
    )
  }, [salaryDetail])

  return (
    <ModalSalaryDetailWrapper
      style={{ top: 20 }}
      footer={null}
      title="Bảng lương chi tiết"
      width={800}
      visible={isVisibleSalaryManagerModal}
      onCancel={handleCloseSalaryManagerModal}>
      <Card
        style={{ marginTop: -10 }}
        title="Thông tin"
        type={'inner'}
        size="small">
        <div className={'row-card'}>
          <div className={'label-card'}>Họ tên:</div>
          <div className={'content-row-card'}>
            {salaryDetail.full_name || <EmptyText>rõ</EmptyText>}
          </div>
        </div>
        <div className={'row-card row-card-end'}>
          <div className={'label-card'}>Chức danh:</div>
          <div className={'content-row-card'}>
            {salaryDetail.chuc_danh || <EmptyText>Không rõ</EmptyText>}
          </div>
        </div>
      </Card>
      <Card
        style={{ marginTop: 16 }}
        title="Tiền lương trong tháng"
        type={'inner'}
        size="small">
        <div className={'row-card '}>
          <div className={'label-card'}>Tiền lương chức danh:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tien_luong_chuc_danh ? (
              utils.formatCurrency(salaryDetail.tien_luong_chuc_danh)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>Tiền lương kinh doanh:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tien_luong_kinh_doanh ? (
              utils.formatCurrency(salaryDetail.tien_luong_kinh_doanh)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>Tạm ứng 80% thù lao TV.HĐQT:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tam_ung_thu_lao_tv_hdqt ? (
              utils.formatCurrency(salaryDetail.tam_ung_thu_lao_tv_hdqt)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>Tổng tiền lương:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tong_tien_luong ? (
              utils.formatCurrency(salaryDetail.tong_tien_luong)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Số ngày công đi làm:</div>
          <div className={'content-row-card'}>
            {salaryDetail.so_ngay_cong_di_lam ? (
              utils.formatCurrency(salaryDetail.so_ngay_cong_di_lam)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>
            Tiền lương tính theo
            <br />
            ngày công:
          </div>
          <div className={'content-row-card'}>
            {salaryDetail.tien_luong_tinh_theo_ngay_cong ? (
              utils.formatCurrency(salaryDetail.tien_luong_tinh_theo_ngay_cong)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Khoản bổ sung khác (nếu có):</div>
          <div className={'content-row-card'}>
            {salaryDetail.khoan_bo_sung_khac ? (
              utils.formatCurrency(salaryDetail.khoan_bo_sung_khac)
            ) : (
              <EmptyText>Không có</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Tiền ăn:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tien_an ? (
              utils.formatCurrency(salaryDetail.tien_an)
            ) : (
              <EmptyText>Không có</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card row-card-end'} style={{ fontWeight: 'bold' }}>
          <div className={'label-card'}>Tổng tiền lương được nhận:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tong_tien_luong_nhan ? (
              utils.formatCurrency(salaryDetail.tong_tien_luong_nhan)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
      </Card>
      <Card
        style={{ marginTop: 16 }}
        title="Các khoản khấu trừ trong tháng"
        type={'inner'}
        size="small">
        <div className={'row-card '}>
          <div className={'label-card'}>
            Quyết toán thuế TNCN phải thu/hoàn:
          </div>
          <div className={'content-row-card'}>
            {salaryDetail.quyet_toan_tncn_con_phai_thu_hoan ? (
              utils.formatCurrency(
                salaryDetail.quyet_toan_tncn_con_phai_thu_hoan
              )
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>Thuế thu nhập cá nhân tháng:</div>
          <div className={'content-row-card'}>
            {salaryDetail.thue_thu_nhap_ca_nhan_thang ? (
              utils.formatCurrency(salaryDetail.thue_thu_nhap_ca_nhan_thang)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>Tổng BHXH, BHYT, BHTN = 10,5%:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tong_bhxh_bhyt_bhtn ? (
              utils.formatCurrency(salaryDetail.tong_bhxh_bhyt_bhtn)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>1% đoàn phí:</div>
          <div className={'content-row-card'}>
            {salaryDetail.doan_phi ? (
              utils.formatCurrency(salaryDetail.doan_phi)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>Các khoản khấu trừ khác:</div>
          <div className={'content-row-card'}>
            {salaryDetail.cac_khoan_khau_tru_khac ? (
              utils.formatCurrency(salaryDetail.cac_khoan_khau_tru_khac)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card row-card-end'} style={{ fontWeight: 'bold' }}>
          <div className={'label-card'}>Tổng:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tong_cac_khoan_khau_tru ? (
              utils.formatCurrency(salaryDetail.tong_cac_khoan_khau_tru)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
      </Card>
      <div style={{ marginTop: 16 }}>
        <Alert
          message={
            <div
              className={'row-card row-card-end'}
              style={{ fontWeight: 'bold', fontSize: 14 }}>
              <div className={'label-card'}>Còn nhận:</div>
              <div className={'content-row-card'}>
                {salaryDetail.con_nhan ? (
                  utils.formatCurrency(salaryDetail.con_nhan)
                ) : (
                  <EmptyText>Không rõ</EmptyText>
                )}
              </div>
            </div>
          }
          type="success"
        />
      </div>
    </ModalSalaryDetailWrapper>
  )
}

SalaryManagerModal.propTypes = {}

export default SalaryManagerModal

import React from 'react'
import {
  EmptyText,
  TitleContentBox,
  WordBreak,
} from '../../components/Common/CellText'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import { Tag } from 'antd'
import { AvatarHasName } from '../../components/Common/Avatar'

const InfoWrapper = styled.div`
  .info-row {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;
    .info-label {
      font-weight: 700;
      flex: 0 0 120px;
      width: 100px;
    }
    .user-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .user {
        border-radius: 35px;
        padding: 3px 8px 3px 4px;
        display: flex;
        margin-right: 0;
        align-items: center;
      }
    }
  }
  .last-row {
    margin-bottom: 0;
  }
`

const ProposalAdvanceInfo = props => {
  const { className, proposalAdvanceStore } = props

  const { proposalAdvanceDetail } = proposalAdvanceStore

  return (
    <ContentBlockWrapper className={className}>
      <TitleContentBox>Thông tin chung</TitleContentBox>
      <InfoWrapper>
        <div className={'info-row'}>
          <div className={'info-label'}>Tiêu đề:</div>
          <div>
            {proposalAdvanceDetail.title || (
              <EmptyText>Không có tiêu đề.</EmptyText>
            )}
          </div>
        </div>
        <div className={'info-row'}>
          <div className={'info-label'}>Nội dung:</div>
          <div>
            {proposalAdvanceDetail.content ? (
              WordBreak(proposalAdvanceDetail.content, true)
            ) : (
              <EmptyText>Không có nội dung.</EmptyText>
            )}
          </div>
        </div>
        <div className={'info-row'}>
          <div className={'info-label'}>Tài liệu đính kèm:</div>
          <div>
            {proposalAdvanceDetail.attachments &&
            proposalAdvanceDetail.attachments.length > 0 ? (
              proposalAdvanceDetail.attachments.map(file => (
                <FileItem
                  key={file.file_id}
                  file_id={file.file_id}
                  file_name={file.file_name}
                  file_type={utils.getExtensionFile(file.file_name)}
                />
              ))
            ) : (
              <EmptyText>Không có tài liệu đính kèm.</EmptyText>
            )}
          </div>
        </div>
        <div className={'info-row last-row'}>
          <div className={'info-label'}>Người theo dõi:</div>
          <div className={'user-list'}>
            {proposalAdvanceDetail.followers &&
            proposalAdvanceDetail.followers.length > 0 ? (
              proposalAdvanceDetail.followers.map(el => (
                <Tag className={'user'} color={'blue'} key={el.user_name}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.name_uppercase}
                  />
                </Tag>
              ))
            ) : (
              <EmptyText>Không có người theo dõi.</EmptyText>
            )}
          </div>
        </div>
      </InfoWrapper>
    </ContentBlockWrapper>
  )
}

ProposalAdvanceInfo.propTypes = {}

export default inject('proposalAdvanceStore')(observer(ProposalAdvanceInfo))

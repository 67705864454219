import React from 'react'
import TableComponent from '../../components/Common/TableComponent'
import { Empty, Pagination } from 'antd'
import { SalaryListTableWrapper } from '../SalaryPage/SalaryPageStyled'
import moment from 'moment'
import { DATE_FORMAT_DEFAULT, TYPE_STATUS } from '../../constants'
import { StatusTag } from '../../components/Common/StatusTag'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'

const SalaryListTable = props => {
  const { salaryStore, loadingAnimationStore } = props
  const { salaryListPage, salaryListPageTotal, salaryQueryParam } = salaryStore

  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search)
  const tableColumns = [
    {
      align: 'center',
      // width: 130,
      title: 'Tiêu đề',
      render: record => <span>{record.title}</span>,
    },
    {
      align: 'center',
      // width: 180,
      title: 'Người trình duyệt',
      render: record => (
        <span style={{ textTransform: 'capitalize' }}>
          {record.created_user?.name_lowercase}
        </span>
      ),
    },
    {
      align: 'center',
      title: 'Trạng thái',
      render: record => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {StatusTag(TYPE_STATUS.SALARY, record.status)}
        </div>
      ),
    },
    {
      align: 'center',
      title: 'Ngày trình',
      render: record => (
        <span>
          {record.created_date &&
            moment(record.created_date).format(DATE_FORMAT_DEFAULT)}
        </span>
      ),
    },
  ]

  const onChangePagination = async pageIndex => {
    salaryStore.setSalaryQueryParam({
      ...salaryQueryParam,
      page: pageIndex - 1,
      size: 10,
    })
    try {
      loadingAnimationStore.setTableLoading(true)
      await salaryStore.getSalaryList()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: pageIndex - 1,
        size: 10,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/salary?${queryStringStringify}`)
  }
  return (
    <SalaryListTableWrapper>
      <TableComponent
        rowClassName={'row-table'}
        rowKey={'code'}
        dataSource={salaryListPage}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 800 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={{
          tip: 'Đang tải...',
          spinning: loadingAnimationStore.tableLoading,
        }}
        onRow={record => {
          return {
            onClick: () => {
              history.push(`/salary/view/${record.code}`)
            },
          }
        }}
      />

      <div
        style={{
          marginTop: 20,
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Pagination
          onChange={e => onChangePagination(e)}
          total={salaryListPageTotal}
          pageSize={parseInt(salaryQueryParam.size)}
          current={parseInt(salaryQueryParam.page) + 1}
          showSizeChanger={false}
          showLessItems
        />
      </div>
    </SalaryListTableWrapper>
  )
}

SalaryListTable.propTypes = {}

export default inject(
  'salaryStore',
  'loadingAnimationStore',
)(observer(SalaryListTable))

import { requests } from './DefaultRequest'

export const ConsultCommentRequest = {
  getConsultComment: consultCode =>
    requests.get(`/api/v1/consult/${consultCode}/comments`),
  postConsultComment: (consultCode, data) =>
    requests.post(`/api/v1/consult/${consultCode}/comments`, data),
  deleteConsultComment: (consultCode, commentId) =>
    requests.delete(`/api/v1/consult/${consultCode}/comments/${commentId}`),
}

import { observable } from 'mobx'
import { CommonHistoryLogRequest } from '../../requests/CommonHistoryLogRequest'

class ListHistoryLogStore {
  @observable list = []
  @observable isLoading = false
  @observable message = null

  getList = async documentCode => {
    this.isLoading = true

    var response = await CommonHistoryLogRequest.getList(documentCode)
    this.list = response.data
    this.message = this.list.length == 0 ? 'Không có lịch sử' : null

    this.isLoading = false
  }

  clear = () => {
    this.list = []
    this.isLoading = false
  }
}

export default new ListHistoryLogStore()

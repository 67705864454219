import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const TaskRequest = {
  getTaskList: (params, cancelToken) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/tasks/current-user?${
        params.assignee ? params.assignee : ''
      }`,
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { ...params, assignee: null },
      cancelToken: cancelToken,
    }),

  getTaskListByGroup: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/tasks/statistic-by-group?date_by=${params.date_by}&from_date=${params.from_date}&to_date=${params.to_date}`,
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getTaskLevelList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/tasks/level`,
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getUnitList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/tasks/unit`,
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  createTask: formData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/tasks`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: formData,
    }),

  deleteTask: taskCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/tasks/${taskCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  updateTask: (taskID, body) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/tasks/${taskID}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: body,
    }),

  moveTask: payload =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/tasks/move`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }),

  editTask: (taskID, body) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/tasks/${taskID}/edit`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: body,
    }),

  getTaskDetail: taskID =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/tasks/${taskID}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  downloadFile: (taskID, fileID) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/tasks/${taskID}/attachments/${fileID}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }),

  getTagList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/tag/TASK`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}

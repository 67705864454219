import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, message, Select, Space, Tag, Tooltip } from 'antd'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { blue } from '../../color'
import { AvatarHasName } from '../../components/Common/Avatar'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import {
  ASSIGNEE_TYPE,
  DOCUMENT_ACCESS_TYPE,
  TYPE_STATUS,
} from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout'
import { SelectList } from '../MissionCreatePage/MissionCreatePageStyled'
import { FormButtonGroup } from '../MissionDetailPage/MissionDetailPageStyled'

const SalaryAccessibility = props => {
  const {
    selectPeopleStore,
    documentAccessibilityStore,
    authenticationStore,
    loadingAnimationStore,
  } = props
  const {
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    selectUserData,
    selectDepartmentData,
    selectGroupData,
  } = selectPeopleStore

  const [form] = Form.useForm()

  const history = useHistory()

  const {isAccountAdmin, isSuperAdmin} = authenticationStore

  const formStyle = {
    paddingLeft: '16px',
  }

  const formItemStyle = {
    width: '300px',
  }

  const labelStyle = {
    display: 'inline-block',
    margin: '15px 8px 25px 0',
  }

  const [followerList, setFollowerList] = useState([])
  const [accessType, setAccessType] = useState()

  useEffect(() => {
    if (isAccountAdmin || isSuperAdmin || !authenticationStore.currentUser) {
      return
    }

    history.replace('/')
    message.error('Bạn không có quyền truy cập trang này!')
  }, [authenticationStore.currentUser])

  useEffect(() => {
    return () => {
      selectPeopleStore.clearSelected()
      runInAction(() => {
        documentAccessibilityStore.documentAccessibilities = []
      })
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!accessType) {
        selectPeopleStore.clearSelected()
        return
      }
      try {
        selectPeopleStore.clearSelected()
        loadingAnimationStore.showSpinner(true)
        await documentAccessibilityStore.getDocumentAccessibilities(
          TYPE_STATUS.SALARY,
          accessType
        )
      } catch (error) {
        message.error(error.vi)
        console.log(error)
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
  }, [accessType])

  useEffect(() => {
    documentAccessibilityStore.documentAccessibilities
      .map(accessibility => accessibility.assignee)
      .forEach(follower => {
        if (follower?.user_name) {
          followerList.push({
            assignee_type: ASSIGNEE_TYPE.USER,
            full_name: follower.name_uppercase,
            id: follower.user_name,
            image_id: null,
            permission: ASSIGNEE_TYPE.USER,
          })
        }
      })

    if (followerList.length > 0) {
      selectPeopleStore.setSelectUserData({
        ...selectUserData,
        USER: followerList,
      })
    }
  }, [documentAccessibilityStore.documentAccessibilities])

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.USER],
      ...selectDepartmentData[ASSIGNEE_TYPE.USER],
      ...selectGroupData[ASSIGNEE_TYPE.USER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const onChangeAccessType = value => {
    console.log(value)
    setAccessType(value)
  }

  const onFinish = async values => {
    try {
      await documentAccessibilityStore.updateDocumentAccessibilities({
        type: TYPE_STATUS.SALARY,
        access_type: values.accessType,
        username_list: followerList.map(follow => follow.id),
      })
      message.success('Cập nhật quyền thao tác thành công!')
    } catch (error) {
      message.error(error.vi)
      console.log(error)
    }
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý bảng lương | Bảng lương</title>
      </Helmet>
      <PageTitle location={props.location} />
      <ContentBlockWrapper>
        <Form
          style={formStyle}
          onFinish={onFinish}
          scrollToFirstError={true}
          name={'document-accessbilities'}
          layout={'vertical'}
          form={form}>
          <p>
            Quyền được cấp chỉ có tác dụng với dữ liệu tính từ thời điểm cấu
            hình được áp dụng trở về sau
          </p>

          <Form.Item
            style={formItemStyle}
            label="Quyền thao tác"
            name="accessType"
            allowClear
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn quyền thao tác!',
              },
            ]}>
            <Select placeholder="Quyền thao tác" onChange={onChangeAccessType} allowClear>
              <Select.Option value={DOCUMENT_ACCESS_TYPE.VIEW_ALL}>
                Xem toàn bộ
              </Select.Option>
            </Select>
          </Form.Item>
          <div>
            <label style={labelStyle}>Người được cấp quyền:</label>

            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.USER)
                  }
                />
              </Tooltip>
            </Space>

            {followerList.length > 0 && (
              <SelectList style={{ maxHeight: 'unset' }}>
                {followerList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={
                        (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                          <BankOutlined />
                        )) ||
                        (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                          <TeamOutlined />
                        ))
                      }
                    />
                  </Tag>
                ))}
              </SelectList>
            )}
          </div>
          <FormButtonGroup>
            <Button type={'primary'} htmlType={'submit'}>
              Lưu
            </Button>
          </FormButtonGroup>

          <SelectPeoplePopup
            allowChangeCompany={false}
            companyCode={authenticationStore.currentUser?.company?.code}
            disableSelectDepartment
            handleCancelSelectUser={handleCancelSelectUserDepartment}
            handleSubmitSelectUser={() =>
              setIsVisibleSelectUserDepartmentPopup(false)
            }
            isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
          />
        </Form>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'selectUserStore',
  'documentAccessibilityStore',
  'selectPeopleStore',
  'loadingAnimationStore',
  'authenticationStore'
)(observer(SalaryAccessibility))

import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Empty, Form, message, Modal } from 'antd'
import moveDocumentStore from './moveDocumentStore'
import TableComponent from '../../../components/Common/TableComponent'
import { CheckCircleFilled, FolderOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'

const MoveDocumentModal = props => {
  const { visible, onClose } = props
  const { currentDocument, parentFolderId } = props
  const { folders, breadCrumb, isLoading } = moveDocumentStore

  const [selectedDestFolderId, setSelectedDestFolderId] = useState()

  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      changeParentFolderId(parentFolderId)
    }

    return () => {
      moveDocumentStore.clearStore()
    }
  }, [parentFolderId, visible])

  useEffect(() => {
    if (currentDocument) {
      moveDocumentStore.getListFolder(currentDocument.id)
    }
  }, [moveDocumentStore.parentFolderId])

  useEffect(() => {
    // console.log(breadCrumb)
  }, [breadCrumb])

  const closeAndResetForm = () => {
    setSelectedDestFolderId(null)
    form.resetFields()
    moveDocumentStore.clearStore()
    onClose()
  }

  const columns = [
    {
      align: 'left',
      title: 'Tên',
      key: 'displayName',
      dataIndex: 'displayName',
      render: (text, record) => {
        return (
          <div key={record.id}>
            {
              <span onClick={() => setSelectedDestFolderId(record.id)}>
                {selectedDestFolderId === record.id ? (
                  <CheckCircleFilled style={{ color: '#52c41a' }} />
                ) : (
                  <FolderOutlined />
                )}
                <span
                  style={{
                    fontWeight: selectedDestFolderId === record.id ? 700 : 400,
                    marginLeft: 8,
                  }}>
                  {record.displayName}
                </span>
              </span>
            }
          </div>
        )
      },
    },
    {
      align: 'right',
      title: 'Chọn',
      render: record => (
        <Button type="text" onClick={() => changeParentFolderId(record.id)}>
          Mở
        </Button>
      ),
    },
  ]

  const changeParentFolderId = id => {
    moveDocumentStore.parentFolderId = id
  }

  const move = () => {
    try {
      if (currentDocument && selectedDestFolderId) {
        moveDocumentStore.move(currentDocument.id, selectedDestFolderId)

        message.success('Di chuyển tài liệu thành công')
      } else {
        message.warn('Vui lòng chọn thư mục cần di chuyển tới')
      }

      closeAndResetForm()
    } catch (error) {
      moveDocumentStore.isLoading = false
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
      console.log(error)
    }
  }

  const title = () => {
    if (currentDocument) {
      let textTitle
      if (currentDocument.extension == null)
        textTitle = `Di chuyển ${currentDocument?.displayName}`
      else
        textTitle = `Di chuyển ${currentDocument?.displayName}.${currentDocument?.extension}`

      return (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>{textTitle}</div>
            <div style={{ display: 'flex', gap: 10 }}>
              <Button onClick={() => closeAndResetForm()} danger>
                Đóng
              </Button>
              <Button onClick={move} type={'primary'} loading={isLoading}>
                Di chuyển
              </Button>
            </div>
          </div>
        </>
      )
    }

    return 'Di chuyển'
  }

  return (
    <>
      <Modal
        width={1000}
        footer={null}
        closable={false}
        visible={visible}
        title={title()}>
        <Form form={form} layout={'vertical'}>
          <Breadcrumb style={{ marginBottom: 0 }}>
            {breadCrumb.map(item => (
              <Breadcrumb.Item
                onClick={() => {
                  changeParentFolderId(item.id)
                  setSelectedDestFolderId(item.id)
                }}
                key={item.id}>
                <a>{item.name}</a>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>

          <Form.Item>
            <TableComponent
              rowKey="id"
              dataSource={folders}
              columns={columns}
              showHeader={false}
              pagination={false}
              onRow={record => {
                return {
                  onClick: () => {
                    setSelectedDestFolderId(record.id)
                  },
                }
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={'Không có dữ liệu'}
                  />
                ),
              }}
              loading={isLoading}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

MoveDocumentModal.propTypes = {}

export default observer(MoveDocumentModal)

import React, { useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { BoxWrapper, PanelItem } from './SelectGroupBoxTrucStyled'
import { blue } from '../../../color'
import { CaretRightOutlined, SearchOutlined } from '@ant-design/icons'
import { Checkbox, Collapse, Input, message, Tooltip } from 'antd'
import { EmptyText } from '../../Common/CellText'
import utils from '../../../utils'
import { AvatarHasName } from '../../Common/Avatar'

const SelectGroupBoxTruc = props => {
  const { loadingAnimationStore, userTrucStore } = props
  const {
    groupListTruc,
    originGroupListTruc,
    selectGroupDataTruc,
  } = userTrucStore

  const handleSearchGroup = useCallback(
    event => {
      if (event.target.value.trim() === '') {
        userTrucStore.setGroupListTruc(originGroupListTruc)
        return
      }
      const listGroupFilter = originGroupListTruc.filter(group =>
        utils
          .nonAccentVietnamese(group.name)
          .includes(utils.nonAccentVietnamese(event.target.value))
      )
      userTrucStore.setGroupList(listGroupFilter)
    },
    [originGroupListTruc]
  )

  const handleSelectGroup = useCallback(
    group => {
      if (!group) return
      const isSelectedGroup = selectGroupDataTruc.some(
        el => el.id === group.groupId
      )
      if (isSelectedGroup) {
        userTrucStore.setSelectGroupDataTruc(
          selectGroupDataTruc.filter(el => el.id !== group.groupId)
        )
        return
      }
      userTrucStore.setSelectGroupDataTruc([
        ...selectGroupDataTruc,
        {
          name: group.name,
          id: group.groupId,
          users: group.users,
        },
      ])
    },
    [selectGroupDataTruc]
  )

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        const response = await userTrucStore.getGroupListTruc()
        response.data.data.map(
          async group => await userTrucStore.getMemberGroupTruc(group.groupId)
        )
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => userTrucStore.clearSelectGroupDataTruc()
  }, [])

  return (
    <BoxWrapper>
      <div className={'search-box'}>
        <label style={{ fontWeight: 500, marginRight: 10 }}>
          <SearchOutlined style={{ marginRight: 4, color: blue }} />
          Tìm kiếm:
        </label>
        <Input
          placeholder={'Nhập tên nhóm...'}
          style={{ width: 300 }}
          onChange={event => handleSearchGroup(event)}
        />
      </div>
      <div className={'collapse-wrapper'}>
        <Collapse
          collapsible={'header'}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}>
          {groupListTruc.map(group => {
            const checked = selectGroupDataTruc.some(
              el => el.id === group.groupId
            )
            return (
              <PanelItem
                extra={<div className={'extra'} />}
                checked={checked}
                collapsible
                key={group.groupId}
                header={
                  <Tooltip>
                    <div
                      onClick={e => {
                        e.stopPropagation()
                        handleSelectGroup(group)
                      }}
                      className={`group-item ${
                        checked && 'group-item-checked'
                      }`}>
                      <Checkbox checked={checked} />
                      <span className={'group-name'}>{group.name}</span>
                    </div>
                  </Tooltip>
                }>
                {group.users.length > 0 ? (
                  group.users.map(user => {
                    return (
                      <div className={'user-item'} key={user.groupUserId}>
                        <AvatarHasName
                          name={user.user.fullName}
                          imgId={user.image_id}
                        />
                      </div>
                    )
                  })
                ) : (
                  <div style={{ padding: '10px 0 10px 60px' }}>
                    <EmptyText>Không có người dùng</EmptyText>
                  </div>
                )}
              </PanelItem>
            )
          })}
        </Collapse>
      </div>
    </BoxWrapper>
  )
}

SelectGroupBoxTruc.propTypes = {}

export default inject(
  'userTrucStore',
  'loadingAnimationStore',
)(observer(SelectGroupBoxTruc))

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../../components/PageTitle'
import { BlockLeft, BlockRight, PageContentWrapper } from './ConsultPageStyled'
import ConsultList from '../../../components/ConsultList'
import ConsultDetail from '../../../components/ConsultDetail'
import { inject, observer } from 'mobx-react'
import { Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import DashboardLayout from '../../../layouts/DashboardLayout'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent/SpinnerInlineComponent'

const consultWrapperStyle = {
  height: 'calc(100vh - 92px)',
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}

const ConsultPage = props => {
  const {
    history,
    loadingAnimationStore,
    match,
    consultStore,
    consultCommentStore,
  } = props

  const { selectedConsult, consultList } = consultStore
  const { consultId } = match.params

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    consultStore.clearSelectedConsult()
    consultStore.clearAllConsultListFilter()
    consultStore.setConsultListPageIndex(0)
    consultStore.getConsultList().finally(() => {
      loadingAnimationStore.showSpinner(false)
    })
  }, [])

  useEffect(() => {
    if (!consultId) return
    ;(async () => {
      try {
        loadingAnimationStore.setShowSpinInline(true)
        await consultStore.getConsultDetail(consultId)
        await consultCommentStore.getConsultComment(consultId)
      } catch (error) {
        history.push('/administrative/consult')
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
        console.log(error)
      } finally {
        loadingAnimationStore.setShowSpinInline(false)
      }
    })()
    return () => {
      consultStore.clearSelectedConsult()
    }
  }, [consultId, consultStore.sameCodeDetail])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý ý kiến | VIMC Quản lý ý kiến</title>
      </Helmet>
      <PageTitle location={props.location}>
        <Button
          onClick={() => history.push('/administrative/consult/create')}
          type={'primary'}
          icon={<PlusOutlined />}>
          Tạo ý kiến mới
        </Button>
      </PageTitle>
      <PageContentWrapper hasContent={selectedConsult.code}>
        <BlockLeft>
          <ConsultList />
        </BlockLeft>
        <BlockRight hasContent={selectedConsult.code}>
          {
            <div style={consultWrapperStyle}>
              {selectedConsult.code && consultList.length !== 0 ? (
                <ConsultDetail />
              ) : (
                <span style={{ fontSize: 20 }}>
                  {!loadingAnimationStore.showSpinInline &&
                    'Chọn ý kiến để xem chi tiết'}
                </span>
              )}
              <SpinnerInlineComponent />
            </div>
          }
        </BlockRight>
      </PageContentWrapper>
    </DashboardLayout>
  )
}

ConsultPage.propTypes = {}

export default inject(
  'loadingAnimationStore',
  'consultStore',
  'consultCommentStore'
)(observer(ConsultPage))

import { action, observable } from 'mobx'
// Request
import { TinTucRequest } from '../requests/TinTucRequest'

class TinTucStore {
  @observable newsList = []
  @observable newDetails = {}
  @observable tinTucTotalCount = 0

  @observable tintucQueryParams = {
    page: 0,
    size: 10,
  }

  setTintucQueryParams = payload => {
    this.tintucQueryParams = payload
  }

  /** Get work schedule */
  @action getAllNews = () => {
    return new Promise((resolve, reject) => {
      TinTucRequest.getAllNews(
        this.tintucQueryParams.page,
        this.tintucQueryParams.size
      )
        .then(response => {
          this.newsList = [...this.newsList, ...response.data.data]
          this.tinTucTotalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getById = id => {
    return new Promise((resolve, reject) => {
      TinTucRequest.getById(id)
        .then(response => {
          // console.log(response.data)
          this.newDetails = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action create = tinTuc => {
    return new Promise((resolve, reject) => {
      TinTucRequest.create(tinTuc)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action update = tinTuc => {
    return new Promise((resolve, reject) => {
      TinTucRequest.update(tinTuc)
        .then(response => {
          console.log(response.data.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action delete = id => {
    return new Promise((resolve, reject) => {
      TinTucRequest.delete(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clear = () => {
    this.newsList.length = 0
    this.tinTucTotalCount = 0
    this.tintucQueryParams = {
      page: 0,
      size: 10,
    }
  }

  @action clearNewDetails = () => {
    this.newDetails = {}
  }
}

export default new TinTucStore()

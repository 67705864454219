import styled from 'styled-components'

export const ImgSignatureDemo = styled.div`
  border: 1px solid #f8f6f6;
  margin-top: 20px;
  padding: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;

  .img-signature {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

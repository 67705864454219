import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const KpiRequest = {
  getKpiList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/kpi`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getKpiDetail: kpiId =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/kpi/${kpiId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getTargetKpiDetail: (kpiId, targetId) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/kpi/${kpiId}/${targetId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  create: params =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/kpi`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        name: params.name,
        type: params.type,
        parent_code: params.parent_code,
        work_code: params.work_code,
        expect_weight: params.expect_weight,
        kpi_quarter_code: params.kpi_quarter_code,
      },
    }),

  updateKpiValues: (kpiList, kpiQuarterCode) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/kpi`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        kpi_quarter_code: kpiQuarterCode,
        data: kpiList.map(kpi => {
          return {
            code: kpi.code,
            expect_weight: kpi.expect_weight,
          }
        }),
      },
    }),
  delete: kpiCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/kpi/${kpiCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getAssignedWorks: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/kpi-assigned-works`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}

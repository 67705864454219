import React, { useEffect, useState } from 'react'
import {
  ListUserGroupWrapper,
  TabUserGroupWrapper,
} from './TableSelectUserGroupStyled'
import { TextWrap, trimOverlengthString } from '../Common/CellText'
import { Avatar, Checkbox, Comment, Tabs, Tooltip } from 'antd'
import { CellEclipseBox } from '../Common/Table'
import { apiUrl } from './../../config'
import { inject, observer } from 'mobx-react'
import { blue } from '../../color'
import { toJS } from 'mobx'
import { ASSIGNEE_TYPE } from '../../constants'
import TableComponent from '../Common/TableComponent'

const { TabPane } = Tabs

/** @deprecated not use **/
const TableSelectUserGroup = props => {
  const {
    userGroupStore,
    loadingAnimationStore,
    groupSelectedList,
    handleSelectGroup,
    selectUserStore,
  } = props

  const { dataGroupStoreList } = userGroupStore

  const { tableGroupType, selectData, setSelectData } = selectUserStore

  const [disableGroupList, setDisableGroupList] = useState([])

  useEffect(() => {
    if (!tableGroupType) return
    const assigneesListAll = [
      ...toJS(selectData.handler),
      ...toJS(selectData.combiner),
      ...toJS(selectData.follower),
      ...toJS(selectData.leader),
    ]
    const filterAssigneesListToId = tableDepartmentType =>
      assigneesListAll
        .filter(
          el =>
            el.assignee_type === ASSIGNEE_TYPE.GROUP &&
            el.permission !== tableDepartmentType
        )
        .map(el => el.id)
    if (tableGroupType === 'handler') {
      setDisableGroupList(filterAssigneesListToId(ASSIGNEE_TYPE.HANDLER))
    }
    if (tableGroupType === 'combiner') {
      setDisableGroupList(filterAssigneesListToId(ASSIGNEE_TYPE.COMBINER))
    }
    if (tableGroupType === 'follower') {
      setDisableGroupList(filterAssigneesListToId(ASSIGNEE_TYPE.FOLLOWER))
    }
    if (tableGroupType === 'leader') {
      setDisableGroupList(filterAssigneesListToId(ASSIGNEE_TYPE.LEADER))
    }
  }, [tableGroupType])

  const tableColumn = [
    {
      title: 'Thông tin',
      render: record => (
        <Comment
          author={
            <span style={{ textTransform: 'capitalize' }}>
              {record.name_lowercase}
            </span>
          }
          avatar={
            <Avatar
              style={{ backgroundColor: blue }}
              src={record.image && `${apiUrl}/api/v1/images/${record.image}`}>
              {record?.name_lowercase?.split('')[0].toString().toUpperCase()}
            </Avatar>
          }
        />
      ),
    },
    {
      title: 'Email',
      render: record => (
        <Tooltip title={record?.email}>
          <CellEclipseBox>{record?.email}</CellEclipseBox>
        </Tooltip>
      ),
    },
    {
      title: 'Chức vụ',
      render: record => (record.job_title ? record?.job_title : 'Chưa rõ'),
    },
    {
      title: 'Phòng ban',
      render: record => (
        <Tooltip title={record?.deparment}>
          <CellEclipseBox>{record?.deparment}</CellEclipseBox>
        </Tooltip>
      ),
    },
  ]

  const changeSelectUserGroup = (e, group_name, group_id) => {
    const groupData = {
      group_id: group_id,
      group_name: group_name,
    }
    handleSelectGroup(e, groupData)
  }

  const onChangeTabUserGroup = key => {
    const groupSelected = dataGroupStoreList.find(
      group => group.id_group === parseInt(key)
    )
    if (groupSelected.user_list.length !== 0) return
    loadingAnimationStore.setTableLoading(true)
    userGroupStore
      .getMemberGroupByIdGroup(parseInt(key))
      .finally(() => loadingAnimationStore.setTableLoading(false))
  }

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    userGroupStore
      .getGroupList()
      .then(responseGroupList => {
        userGroupStore
          .getMemberGroupByIdGroup(responseGroupList[0].id_group)
          .catch(err => console.log(err))
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
      .catch(() => loadingAnimationStore.showSpinner(false))
  }, [])

  return (
    <ListUserGroupWrapper>
      <TabUserGroupWrapper tabPosition={'left'} onChange={onChangeTabUserGroup}>
        {dataGroupStoreList.map(userGroup => {
          const userGroupHasId = {
            ...userGroup,
            user_list: userGroup.user_list.map(user => {
              return {
                ...user,
                id: userGroup.id_group,
              }
            }),
          }
          return (
            <TabPane
              tab={
                <>
                  <TextWrap>
                    {trimOverlengthString(userGroup.name_group, 200)}
                  </TextWrap>
                  <Tooltip
                    title={
                      disableGroupList.includes(userGroup.id_group)
                        ? 'Nhóm này đã tồn tại trong danh sách khác'
                        : null
                    }>
                    <Checkbox
                      checked={groupSelectedList
                        .map(group => group.group_id || group.id)
                        .includes(userGroup.id_group)}
                      onChange={e =>
                        changeSelectUserGroup(
                          e,
                          userGroup.name_group,
                          userGroup.id_group
                        )
                      }
                      disabled={disableGroupList.includes(userGroup.id_group)}
                    />
                  </Tooltip>
                </>
              }
              key={userGroup.id_group}>
              <TableComponent
                rowKey={record => record.user_name + userGroup.id_group}
                columns={tableColumn}
                dataSource={userGroupHasId.user_list}
                pagination={false}
                loading={loadingAnimationStore.tableLoading}
              />
            </TabPane>
          )
        })}
      </TabUserGroupWrapper>
    </ListUserGroupWrapper>
  )
}

TableSelectUserGroup.propTypes = {}

export default inject(
  'userGroupStore',
  'loadingAnimationStore',
  'selectUserStore',
)(observer(TableSelectUserGroup))

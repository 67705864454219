import { action, observable, toJS } from 'mobx'
import { TaskRequest } from '../requests/TaskRequest'
import { ASSIGNEE_TYPE, ISO_DATE_FORMAT } from '../constants'
import { clone } from 'lodash'
import { message } from 'antd'
import moment from 'moment'
import queryString from 'query-string'

class TaskStore {
  @observable taskList = []
  @observable taskGroupList = []
  @observable taskDetail = {
    contentTask: null,
    resultTask: null,
    leader: [],
    handler: [],
    combiner: [],
    canAddWork: false,
    canUpdateProgress: false,
  }
  @observable currentViewingDetailTaskCodes = []
  @observable taskLevelList = []
  @observable unitList = []
  @observable missionQueryParams = {
    tien_do: null,
    from_date: null,
    to_date: null,
    id_unit: null,
    id_user_unit: null,
    id_task_level: null,
    loai_nhiem_vu: null,
    assignee: null,
    date_by: 'DISPLAY_DATE',
    tag: null,
  }

  @observable updateTaskItem = {
    isRowUser: false,
    status: false,
  }

  @action setUpdateTaskItem = payload => {
    this.updateTaskItem = payload
  }

  setMissionQueryParams = payload => {
    this.missionQueryParams = payload
  }

  @observable counterTask = {
    completed: 0,
    uncompleted: 0,
  }

  @action setCounterTask = payload => {
    this.counterTask = payload
  }

  @action getTaskList = cancelToken => {
    return new Promise((resolve, reject) => {
      let params = clone(this.missionQueryParams)
      if (params.loai_nhiem_vu) {
        params.loai_nhiem_vu = Object.values({ ...params.loai_nhiem_vu }).join(
          ','
        )
      }

      if (params.tag) {
        params.tag = Object.values({ ...params.tag }).join(',')
      }

      TaskRequest.getTaskList(params, cancelToken)
        .then(response => {
          if (!response?.data) {
            return resolve()
          }
          resolve(response.data)
          let taskListData = []
          Array.isArray(response.data) &&
            response.data.forEach(el => {
              if (el.content.length === 0) return
              const userUnitTask = {
                task_code:
                  el.content.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue.task_code,
                    ''
                  ) + 'USER',
                id:
                  el.content.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue.task_code,
                    ''
                  ) +
                  'USER' +
                  el.leader_name,
                taskNumbers: el.content.length,
                ten_don_vi: el.ten_don_vi,
                isUser: true,
                overDue: false,
                uncompleted: el.content.filter(
                  el =>
                    el.ket_qua.phan_tram_hoan_thanh !== '100' ||
                    el.ket_qua.phan_tram_hoan_thanh === null
                ).length,
                completed: el.content.filter(
                  el => el.ket_qua.phan_tram_hoan_thanh === '100'
                ).length,
              }
              taskListData.push(userUnitTask)
              el.content.forEach(el => {
                let overdue = false
                let isCompleted = false
                const ngay_ket_thuc = el.ngay_ket_thuc
                  ? moment(el.ngay_ket_thuc).format(ISO_DATE_FORMAT)
                  : null
                const today = moment().format(ISO_DATE_FORMAT)
                if (
                  el.ket_qua.phan_tram_hoan_thanh !== '100' &&
                  ngay_ket_thuc &&
                  moment(today).isAfter(ngay_ket_thuc)
                ) {
                  overdue = true
                }
                if (el.ket_qua.phan_tram_hoan_thanh === '100') {
                  isCompleted = true
                }
                taskListData.push({
                  ...el,
                  id: el.task_code + ' ' + el.leader_name,
                  overdue: overdue,
                  isCompleted: isCompleted,
                })
              })
            })
          this.taskList = taskListData
          this.counterTask = {
            uncompleted: taskListData.filter(
              item =>
                !item.isUser &&
                (item.ket_qua.phan_tram_hoan_thanh !== '100' ||
                  item.ket_qua.phan_tram_hoan_thanh === null)
            ).length,
            completed: taskListData.filter(
              item =>
                !item.isUser && item.ket_qua.phan_tram_hoan_thanh === '100'
            ).length,
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getCounterTask = () => {
    return new Promise((resolve, reject) => {
      TaskRequest.getTaskList(this.missionQueryParams)
        .then(response => {
          if (!response?.data) {
            return resolve()
          }
          resolve(response.data)
          let taskListData = []
          Array.isArray(response.data) &&
            response.data.forEach(el => {
              if (el.content.length === 0) return
              const userUnitTask = {
                task_code:
                  el.content.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue.task_code,
                    ''
                  ) + 'USER',
                id:
                  el.content.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue.task_code,
                    ''
                  ) +
                  'USER' +
                  el.leader_name,
                taskNumbers: el.content.length,
                ten_don_vi: el.ten_don_vi,
                isUser: true,
                overDue: false,
                uncompleted: el.content.filter(
                  el =>
                    el.ket_qua.phan_tram_hoan_thanh !== '100' ||
                    el.ket_qua.phan_tram_hoan_thanh == null
                ).length,
                completed: el.content.filter(
                  el => el.ket_qua.phan_tram_hoan_thanh === '100'
                ).length,
              }
              taskListData.push(userUnitTask)
              el.content.forEach(el => {
                let overdue = false
                let isCompleted = false
                const ngay_ket_thuc = el.ngay_ket_thuc
                  ? moment(el.ngay_ket_thuc).format(ISO_DATE_FORMAT)
                  : null
                const today = moment().format(ISO_DATE_FORMAT)
                if (
                  el.ket_qua.phan_tram_hoan_thanh !== '100' &&
                  ngay_ket_thuc &&
                  moment(today).isAfter(ngay_ket_thuc)
                ) {
                  overdue = true
                }
                if (el.ket_qua.phan_tram_hoan_thanh === '100') {
                  isCompleted = true
                }
                taskListData.push({
                  ...el,
                  id: el.task_code + ' ' + el.leader_name,
                  overdue: overdue,
                  isCompleted: isCompleted,
                })
              })
            })
          this.counterTask = {
            uncompleted: taskListData.filter(
              item =>
                !item.isUser &&
                (item.ket_qua.phan_tram_hoan_thanh !== '100' ||
                  item.ket_qua.phan_tram_hoan_thanh == null)
            ).length,
            completed: taskListData.filter(
              item =>
                !item.isUser && item.ket_qua.phan_tram_hoan_thanh === '100'
            ).length,
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getTaskListByGroup = () => {
    return new Promise((resolve, reject) => {
      TaskRequest.getTaskListByGroup(this.missionQueryParams)
        .then(response => {
          this.taskGroupList = response.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getTaskListDetailByTaskCode = taskCode => {
    return new Promise((resolve, reject) => {
      TaskRequest.getTaskDetail(taskCode)
        .then(response => {
          const taskListData = [...this.taskList]
          const taskTargetIndex = taskListData.findIndex(
            task => task.task_code === taskCode
          )
          taskListData[taskTargetIndex] = {
            ...taskListData[taskTargetIndex],
            task_detail: response.data,
            isShowDetail: true,
          }
          this.taskList = taskListData
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action updatePercentCompleteByTaskCode = (
    taskCode,
    percentCompleteOrigin,
    percentCompleteUpdate
  ) => {
    if (percentCompleteOrigin === percentCompleteUpdate) return
    const taskListData = [...this.taskList]
    const taskTargetIndex = taskListData.findIndex(
      task => task.task_code === taskCode
    )
    const taskTargetUserIndex = taskListData.findIndex(
      task =>
        task.task_code.includes(taskCode) && task.task_code.includes('USER')
    )
    if (taskTargetIndex === -1 || taskTargetUserIndex === -1) return
    taskListData[taskTargetIndex] = {
      ...taskListData[taskTargetIndex],
      isCompleted: percentCompleteUpdate === 100,
      ket_qua: {
        ...taskListData[taskTargetIndex].ket_qua,
        phan_tram_hoan_thanh: percentCompleteUpdate + '',
      },
    }

    let completed = taskListData[taskTargetUserIndex].completed
    let uncompleted = taskListData[taskTargetUserIndex].uncompleted
    if (percentCompleteUpdate === 100) {
      completed = completed + 1
      uncompleted = uncompleted - 1
    }
    if (percentCompleteUpdate === 0) {
      uncompleted = uncompleted + 1
      completed = completed - 1
    }

    taskListData[taskTargetUserIndex] = {
      ...taskListData[taskTargetUserIndex],
      completed: completed,
      uncompleted: uncompleted,
    }
    this.taskList = taskListData
  }

  @action handleCloseTaskDetail = taskCode => {
    const taskListData = [...this.taskList]
    const taskTargetIndex = taskListData.findIndex(
      task => task.task_code === taskCode
    )
    taskListData[taskTargetIndex] = {
      ...taskListData[taskTargetIndex],
      isShowDetail: false,
    }
    this.taskList = taskListData
  }

  @action getTaskLevelList = () => {
    return new Promise((resolve, reject) => {
      TaskRequest.getTaskLevelList()
        .then(response => {
          this.taskLevelList = response.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getUnitList = () => {
    return new Promise((resolve, reject) => {
      TaskRequest.getUnitList()
        .then(response => {
          this.unitList = response.data
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createTask = formData => {
    return new Promise((resolve, reject) => {
      TaskRequest.createTask(formData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteTask = codeTask => {
    return new Promise((resolve, reject) => {
      TaskRequest.deleteTask(codeTask)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action updateTask = (taskID, body) => {
    return new Promise((resolve, reject) => {
      TaskRequest.updateTask(taskID, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action moveTask = payload => {
    return new Promise((resolve, reject) => {
      TaskRequest.moveTask(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action editTask = (taskID, body) => {
    return new Promise((resolve, reject) => {
      TaskRequest.editTask(taskID, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action getTaskDetail = taskID => {
    return new Promise((resolve, reject) => {
      TaskRequest.getTaskDetail(taskID)
        .then(response => {
          const { data } = response
          const contentTask = data?.content?.length && data?.content[0]
          const resultTask = data?.content?.length
            ? data?.content[0].ket_qua
            : {}
          const leader = toJS(contentTask)?.assignees.filter(
            el => el.permission === ASSIGNEE_TYPE.LEADER
          )
          const handler = toJS(contentTask)?.assignees.filter(
            el => el.permission === ASSIGNEE_TYPE.HANDLER
          )
          const combiner = toJS(contentTask)?.assignees.filter(
            el => el.permission === ASSIGNEE_TYPE.COMBINER
          )

          const canAddWork = contentTask?.can_add_work
          const canUpdateProgress = contentTask?.can_update_progress

          this.taskDetail = {
            contentTask,
            resultTask,
            leader,
            handler,
            combiner,
            canAddWork,
            canUpdateProgress,
          }

          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action handleChangeMonth = (value, queryStringParse, history, type) => {
    this.setMissionQueryParams({
      ...this.missionQueryParams,
      from_date: value
        ? moment(value).startOf('month').format(ISO_DATE_FORMAT)
        : null,
      to_date: value
        ? moment(value).endOf('month').format(ISO_DATE_FORMAT)
        : null,
    })

    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        from_date: value
          ? moment(value).startOf('month').format(ISO_DATE_FORMAT)
          : null,
        to_date: value
          ? moment(value).endOf('month').format(ISO_DATE_FORMAT)
          : null,
        all_time: value ? null : 'true',
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/${type}?${queryStringStringify}`)
  }
  @action handleChangeDateBy = (value, queryStringParse, history, type) => {
    this.setMissionQueryParams({
      ...this.missionQueryParams,
      date_by: value,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        date_by: value,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/${type}?${queryStringStringify}`)
  }
  @action handleNextMonth = async (
    taskCode,
    type,
    setLoadingList,
    setTextLoading
  ) => {
    try {
      setLoadingList(true)
      setTextLoading('Đang cập nhập nhiệm vụ...')
      let taskCodes = []
      if (type === 'ALL') {
        taskCodes = this.taskList
          .filter(item => !item.isUser && !item.isCompleted)
          .map(item => item.task_code)
      } else {
        taskCodes = [taskCode]
      }
      const payload = {
        display_date:
          this.missionQueryParams.from_date &&
          moment(this.missionQueryParams.from_date).add(1, 'months'),
        task_codes: taskCodes,
      }
      await this.moveTask(payload)
      await this.getTaskList()
      message.success('Đã chuyển nhiệm vụ này sang tháng sau!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingList(false)
      setTextLoading('Đang tải nhiệm vụ...')
    }
  }

  @action clearTaskStoreFilter = () => {
    this.missionQueryParams = {
      tien_do: null,
      from_date: null,
      to_date: null,
      id_unit: null,
      id_user_unit: null,
      loai_nhiem_vu: null,
      id_task_level: null,
      date_by: 'DISPLAY_DATE',
      tag: null,
    }
  }

  @action clearTaskList = () => {
    this.taskList.length = 0
  }
  @action clearTaskGroupList = () => {
    this.taskGroupList.length = 0
  }
  @action clearTaskDetail = () => {
    this.taskDetail = {
      contentTask: null,
      resultTask: null,
      leader: [],
      handler: [],
      combiner: [],
      canAddWork: false,
      canUpdateProgress: false,
    }
  }

  @action downloadFile = (taskID, fileID) => {
    return new Promise((resolve, reject) => {
      TaskRequest.downloadFile(taskID, fileID)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  @observable selectedIncomingDocument = []
  @observable selectedOutgoingDocument = []

  @action setSelectedIncomingDocument = data => {
    this.selectedIncomingDocument = data
  }

  @action setSelectedOutgoingDocument = data => {
    this.selectedOutgoingDocument = data
  }

  @action clearCreateMissionStore = () => {
    this.selectedIncomingDocument.length = 0
    this.selectedOutgoingDocument.length = 0
  }
}

export default new TaskStore()

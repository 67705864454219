import React from 'react'

import { Alert, Card } from 'antd'
import { EmptyText } from '../../components/Common/CellText'
import { ModalSalaryDetailWrapper } from './SalaryPageStyled'
import utils from '../../utils'

const SalaryDetailModal = props => {
  const {
    salaryDetail,
    isVisibleSalaryDetailModal,
    handleCloseSalaryDetailModal,
  } = props

  return (
    <ModalSalaryDetailWrapper
      style={{ top: 20 }}
      footer={null}
      title="Bảng lương chi tiết"
      width={800}
      visible={isVisibleSalaryDetailModal}
      onCancel={handleCloseSalaryDetailModal}>
      <Card
        style={{ marginTop: -10 }}
        title="Thông tin"
        type={'inner'}
        size="small">
        <div className={'row-card'}>
          <div className={'label-card'}>Mã nhân viên:</div>
          <div className={'content-row-card'}>
            {salaryDetail.ma_nv || <EmptyText>Không rõ</EmptyText>}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Họ tên:</div>
          <div className={'content-row-card'}>
            {salaryDetail.full_name || <EmptyText>rõ</EmptyText>}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Mã số thuế:</div>
          <div className={'content-row-card'}>
            {salaryDetail.ma_so_thue || <EmptyText>Không rõ</EmptyText>}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Chức danh quy chuẩn:</div>
          <div className={'content-row-card'}>
            {salaryDetail.chuc_danh_quy_chuan || (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Mã chức danh:</div>
          <div className={'content-row-card'}>
            {salaryDetail.ma_chuc_danh || <EmptyText>Không rõ</EmptyText>}
          </div>
        </div>
        <div className={'row-card row-card-end'}>
          <div className={'label-card'}>Đơn vị/bộ phận:</div>
          <div className={'content-row-card'}>
            {salaryDetail.don_vi_bo_phan || <EmptyText>Không rõ</EmptyText>}
          </div>
        </div>
      </Card>
      <Card
        style={{ marginTop: 16 }}
        title="Lương lý thuyết"
        type={'inner'}
        size="small">
        <div className={'row-card '}>
          <div className={'label-card'}>Tổng lương lý thuyết tháng:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tong_luong_ly_thuyet_thang_p1_p2_p3 ? (
              utils.formatCurrency(
                salaryDetail.tong_luong_ly_thuyet_thang_p1_p2_p3
              )
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>Lương lý thuyết:</div>
          <div className={'content-row-card'}>
            {salaryDetail.luong_p1_p2_ly_thuyet ? (
              utils.formatCurrency(salaryDetail.luong_p1_p2_ly_thuyet)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Ngày công đi làm thực tế:</div>
          <div className={'content-row-card'}>
            {salaryDetail.ngay_cong_di_lam_thuc_te ? (
              utils.formatCurrency(salaryDetail.ngay_cong_di_lam_thuc_te)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>{' '}
        <div className={'row-card'}>
          <div className={'label-card'}>
            Công phép, công chế độ
            <br />
            có hưởng lương:
          </div>
          <div className={'content-row-card'}>
            {salaryDetail.cong_phep_cong_che_do_co_huong_luong ? (
              utils.formatCurrency(
                salaryDetail.cong_phep_cong_che_do_co_huong_luong
              )
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>
            Công ốm, công nghỉ
            <br />
            không hưởng lương:
          </div>
          <div className={'content-row-card'}>
            {salaryDetail.cong_om_cong_nghi_khong_huong_luong ? (
              utils.formatCurrency(
                salaryDetail.cong_om_cong_nghi_khong_huong_luong
              )
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card row-card-end'} style={{ fontWeight: 'bold' }}>
          <div className={'label-card'}>Lương thực nhận:</div>
          <div className={'content-row-card'}>
            {salaryDetail.luong_p1_p2_thuc_nhan ? (
              utils.formatCurrency(salaryDetail.luong_p1_p2_thuc_nhan)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
      </Card>
      <Card
        style={{ marginTop: 16 }}
        title="Phụ cấp"
        type={'inner'}
        size="small">
        <div className={'row-card '}>
          <div className={'label-card'}>
            Các khoản phụ cấp từ nguồn
            <br />
            quỹ tiền lương gồm: PC tiến sĩ,
            <br />
            thạc sĩ, phụ cấp khác từ lương:
          </div>
          <div className={'content-row-card'}>
            {salaryDetail.cac_khoan_phu_cap_tu_nguon_quy_tien_luong ? (
              utils.formatCurrency(
                salaryDetail.cac_khoan_phu_cap_tu_nguon_quy_tien_luong
              )
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>Phụ cấp điện thoại:</div>
          <div className={'content-row-card'}>
            {salaryDetail.phu_cap_dien_thoai ? (
              utils.formatCurrency(salaryDetail.phu_cap_dien_thoai)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>
            Điều tiết chuyển một lương phần
            <br />
            P3 để nhận vào hàng tháng:
          </div>
          <div className={'content-row-card'}>
            {salaryDetail.dieu_tiet_chuyen_mot_phan_luong_p3 ? (
              utils.formatCurrency(
                salaryDetail.dieu_tiet_chuyen_mot_phan_luong_p3
              )
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>
            Truy lĩnh lương/bổ sung lương,
            <br />
            Lkk (nếu có):
          </div>
          <div className={'content-row-card'}>
            {salaryDetail.truy_linh_luong_bo_sung_luong ? (
              utils.formatCurrency(salaryDetail.truy_linh_luong_bo_sung_luong)
            ) : (
              <EmptyText>Không có</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card '}>
          <div className={'label-card'}>Lương thêm giờ:</div>
          <div className={'content-row-card'}>
            {salaryDetail.luong_them_gio ? (
              utils.formatCurrency(salaryDetail.luong_them_gio)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Tiền ăn:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tien_an ? (
              utils.formatCurrency(salaryDetail.tien_an)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Thuế TNCN được hoàn:</div>
          <div className={'content-row-card'}>
            {salaryDetail.thue_tncn_duoc_hoan ? (
              utils.formatCurrency(salaryDetail.thue_tncn_duoc_hoan)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card row-card-end'} style={{ fontWeight: 'bold' }}>
          <div className={'label-card'}>Tổng:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tong_tien_phu_cap_thang ? (
              utils.formatCurrency(salaryDetail.tong_tien_phu_cap_thang)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
      </Card>
      <Card
        style={{ marginTop: 16 }}
        title="Khấu trừ"
        type={'inner'}
        size="small">
        <div className={'row-card'}>
          <div className={'label-card'}>Thuế thu nhập cá nhân tháng:</div>
          <div className={'content-row-card'}>
            {salaryDetail.thue_thu_nhap_ca_nhan ? (
              utils.formatCurrency(salaryDetail.thue_thu_nhap_ca_nhan)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>
            Khấu trừ các khoản bảo hiểm bắt buộc:
          </div>
          <div className={'content-row-card'}>
            {salaryDetail.khau_tru_bao_hiem_bat_buoc ? (
              utils.formatCurrency(salaryDetail.khau_tru_bao_hiem_bat_buoc)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>1% Đoàn phí công đoàn:</div>
          <div className={'content-row-card'}>
            {salaryDetail.doan_phi_cong_doan ? (
              utils.formatCurrency(salaryDetail.doan_phi_cong_doan)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>
            Các khoản khấu trừ khác (truy thu
            <br />
            BHXH, BHYT, BHTN do tăng LCB),
            <br />
            lĩnh quá tạm ứng lương, bổ sung
            <br />
            lương, khấu trừ các khoản ủn hộ, từ thiện:
          </div>
          <div className={'content-row-card'}>
            {salaryDetail.cac_khoan_khau_tru_khac ? (
              utils.formatCurrency(salaryDetail.cac_khoan_khau_tru_khac)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Thuế TNCN truy thu:</div>
          <div className={'content-row-card'}>
            {salaryDetail.thue_tncn_truy_thu ? (
              utils.formatCurrency(salaryDetail.thue_tncn_truy_thu)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card'}>
          <div className={'label-card'}>Giảm trừ tiền ăn:</div>
          <div className={'content-row-card'}>
            {salaryDetail.giam_tru_tien_an ? (
              utils.formatCurrency(salaryDetail.giam_tru_tien_an)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
        <div className={'row-card row-card-end'} style={{ fontWeight: 'bold' }}>
          <div className={'label-card'}>Tổng:</div>
          <div className={'content-row-card'}>
            {salaryDetail.tong_tien_khau_tru ? (
              utils.formatCurrency(salaryDetail.tong_tien_khau_tru)
            ) : (
              <EmptyText>Không rõ</EmptyText>
            )}
          </div>
        </div>
      </Card>
      <div style={{ marginTop: 16 }}>
        <Alert
          message={
            <div
              className={'row-card row-card-end'}
              style={{ fontWeight: 'bold', fontSize: 14 }}>
              <div className={'label-card'}>Tổng tháng lương thực nhận:</div>
              <div className={'content-row-card'}>
                {salaryDetail.tien_luong_thang_duoc_nhan ? (
                  utils.formatCurrency(salaryDetail.tien_luong_thang_duoc_nhan)
                ) : (
                  <EmptyText>Không rõ</EmptyText>
                )}
              </div>
            </div>
          }
          type="success"
        />
      </div>
    </ModalSalaryDetailWrapper>
  )
}

SalaryDetailModal.propTypes = {}

export default SalaryDetailModal

import React, { useEffect, useState } from 'react'
import { ExportOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import date_format from '../../date_format'
import { HeadingButtonWrapper } from '../MissionDetailPage/MissionDetailPageStyled'
import { range } from 'lodash-es'
import { maxBy } from 'lodash-es/math'
import RichText from '../../components/RichEditor/RichText'
import { apiUrl } from '../../config'

const ExportTaskToExcel = props => {
  const { data } = props

  const [isVisibleModal, setVisibleModal] = useState(false)

  const [table, setTable] = useState({})

  useEffect(() => {
    if (data.length > 0) {
      data.forEach(item => {
        item.countVb = item.van_bans.length
        item.countTaiLieuDinhKem = item.ket_qua.files.length
      })

      // console.log('data', data)

      let maxColVanBan = maxBy(data, 'countVb').countVb
      if (maxColVanBan === 0) maxColVanBan = 1

      let maxColTaiLieuDinhKem = maxBy(data, 'countTaiLieuDinhKem')
        .countTaiLieuDinhKem
      if (maxColTaiLieuDinhKem === 0) maxColTaiLieuDinhKem = 1
      // console.log('maxSpanColVanBan', maxColVanBan)

      const columns = (
        <tr>
          <td>STT</td>
          <td>Nội dung</td>
          <td>Ghi chú</td>
          <td>Ngày giao</td>
          <td>Ngày hoàn thành</td>
          <td colSpan={maxColVanBan}>Văn bản</td>
          <td>[Kết quả] Nội dung</td>
          <td>[Kết quả] Kiến nghị</td>
          <td colSpan={maxColTaiLieuDinhKem}>[Kết quả] Tài liệu đính kèm</td>
          <td>[Kết quả] Phần trăm thực hiện</td>
          <td>Cấp giao</td>
          <td>Hiển thị vào tháng</td>
          <td>Ngày tạo</td>
        </tr>
      )

      const rows = data.map(item => (
        <tr>
          <td>{item.stt}</td>
          <td>
            <RichText htmlText={item.noi_dung} />
          </td>
          <td>
            <RichText htmlText={item.ghi_chu} />
          </td>
          <td>{date_format.DEFAULT_DATE(item.ngay_bat_dau)}</td>
          <td>
            {item.ngay_ket_thuc && date_format.DEFAULT_DATE(item.ngay_ket_thuc)}
          </td>
          {range(maxColVanBan)
            .map(i => item.van_bans[i] ?? null)
            .map(vb => {
              if (vb) {
                let url =
                  vb.loai_van_ban === '001'
                    ? `${apiUrl}/internal-document/incoming-document/view/${vb.code_van_ban}`
                    : `${apiUrl}/internal-document/outgoing-document/view/${vb.code_van_ban}`
                return (
                  <td>
                    <a href={url}>
                      {vb.document_number} - {vb.ten_van_ban}
                    </a>
                  </td>
                )
              } else return <td></td>
            })}
          <td>
            <RichText htmlText={item.ket_qua.noi_dung_ket_qua_giao} />
          </td>
          <td>
            <RichText htmlText={item.ket_qua.kien_nghi} />
          </td>
          {range(maxColTaiLieuDinhKem)
            .map(i => item.ket_qua.files[i] ?? null)
            .map(file => {
              if (file) {
                return (
                  <td>
                    <a href={`${apiUrl}/attachments/${file.file_id}`}>
                      {file.file_title}
                    </a>
                  </td>
                )
              } else return <td></td>
            })}
          <td>{item.ket_qua.phan_tram_hoan_thanh}%</td>
          <td>{item.cap_giao.ten_cap_giao}</td>
          <td>{date_format.MMMM_YYYY(item.display_date)}</td>
          <td>{date_format.DEFAULT_DATE(item.created_at)}</td>
        </tr>
      ))

      setTable({
        columns: columns,
        rows: rows,
      })
    } else {
      setTable({})
    }
  }, [data])

  const htmlTableToExcel = async () => {
    var wb = XLSX.utils.table_to_book(document.getElementById('tableau'), {
      raw: true,
    })
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = 'export'
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <>
      <Button icon={<ExportOutlined />} onClick={() => setVisibleModal(true)}>
        Xuất dữ liệu
      </Button>

      <Modal
        width={1200}
        style={{ top: 40 }}
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>Xem trước dữ liệu xuất ra</div>
            <div style={{ display: 'flex', gap: 10 }}>
              <Button onClick={() => setVisibleModal(false)} danger>
                Đóng
              </Button>
              <HeadingButtonWrapper>
                <Button
                  onClick={htmlTableToExcel}
                  type={'primary'}
                  icon={<ExportOutlined />}>
                  Thực hiện
                </Button>
              </HeadingButtonWrapper>
            </div>
          </div>
        }
        visible={isVisibleModal}
        maskClosable={false}
        closable={false}
        onCancel={() => {
          setVisibleModal(false)
        }}
        footer={null}>
        {isVisibleModal ? (
          <table id="tableau">
            {table.columns && table.columns}
            {table.rows && table.rows}
          </table>
        ) : (
          <></>
        )}
      </Modal>
    </>
  )
}

ExportTaskToExcel.propTypes = {}

export default ExportTaskToExcel

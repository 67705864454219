import styled from 'styled-components'
import { Button } from 'antd'
import { green } from '../../color'

export const SelectList = styled.div`
  max-height: 80px;
  overflow-y: auto;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .tag-selected {
    border-radius: 35px;
    padding: 3px 8px 3px 4px;
    display: flex;
    margin-right: 0;
    align-items: center;
  }
`
export const SelectTitle = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`
export const UploadFileListWrapper = styled.ul`
  margin-bottom: 15px;
  padding-left: 0 !important;
`
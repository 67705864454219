import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import SalaryListFilter from './SalaryListFilter'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import SalaryListTable from './SalaryListTable'
import { inject, observer } from 'mobx-react'
import { message } from 'antd'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const SalaryListPage = props => {
  const { salaryStore, loadingAnimationStore } = props

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  useEffect(() => {
    salaryStore.setSalaryQueryParam({
      ...salaryStore.salaryQueryParam,
      ...queryStringParse,
    })
    ;(async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        await salaryStore.getSalaryList()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
    return () => {
      salaryStore.clearSalaryListPage()
    }
  }, [])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Duyệt lương | VIMC duyệt lương</title>
      </Helmet>
      <PageTitle location={props.location} />
      <ContentBlockWrapper
        style={{
          minHeight: 'calc(100vh - 150px)',
        }}>
        <SalaryListFilter />
        <SalaryListTable />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

SalaryListPage.propTypes = {}

export default inject(
  'salaryStore',
  'loadingAnimationStore'
)(observer(SalaryListPage))

import { action, observable } from 'mobx'
// Request
import { SampleDocumentRequest } from '../requests/SampleDocumentRequest'

class SampleDocumentStore {
  /** Sample document list */
  @observable sampleDocumentList = []

  /** Sample document detail */
  @observable sampleDocumentDetail = undefined
  @observable page = 0
  @observable size = 10
  @observable totalCount = 0

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  /** Sample document action */
  @action createSampleDocument = documentData => {
    return new Promise((resolve, reject) => {
      SampleDocumentRequest.createSampleDocument(documentData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getSampleDocumentList = () => {
    return new Promise((resolve, reject) => {
      SampleDocumentRequest.getSampleDocumentList(this.page, this.size)
        .then(response => {
          this.sampleDocumentList = response.data.data
          this.totalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getSampleDocumentById = documentId => {
    return new Promise((resolve, reject) => {
      SampleDocumentRequest.getSampleDocumentById(documentId)
        .then(response => {
          this.sampleDocumentDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action deleteSampleDocument = documentId => {
    return new Promise((resolve, reject) => {
      SampleDocumentRequest.deleteSampleDocument(documentId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateSampleDocument = (documentId, documentData) => {
    return new Promise((resolve, reject) => {
      SampleDocumentRequest.updateSampleDocument(documentId, documentData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear data */
  @action clearSampleDocumentList = () => {
    this.sampleDocumentList.length = 0
  }
  @action clearSampleDocumentDetail = () => {
    this.sampleDocumentDetail = undefined
  }
}

export default new SampleDocumentStore()

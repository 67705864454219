import PortalPageV2 from './pages/PortalPageV2/PortalPageV2'
import DashboardPageV2 from './pages/DashboardPageV2/DashboardPageV2'
import UserAccountManagementPage from './pages/UserAccountManagementPage/UserAccountManagementPage'
import UserAppManagementPage from './pages/UserAppManagementPage/UserAppManagementPage'
import MyProfilePage from './pages/MyProfilePage/MyProfilePage'
import NotificationPage from './pages/NotificationPage/NotificationPage'
import EmailRedirectPage from './pages/EmailRedirectPage'
import CloudRedirectPage from './pages/CloudRedirectPage'
import ConnectedDocumentIncomingDocPage from './pages/ConnectedDocumentIncomingDocPage/ConnectedDocumentIncomingDocPage'
import ConnectedDocumentIncomingDocCreatePage from './pages/ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePage'
import ConnectedDocumentIncomingDocDetailPage from './pages/ConnectedDocumentIncomingDocDetailPage/ConnectedDocumentIncomingDocDetailPage'
import ConnectedDocumentOutgoingDocPage from './pages/ConnectedDocumentOutgoingDocPage/ConnectedDocumentOutgoingDocPage'
import ConnectedDocumentOutgoingDocCreatePage from './pages/ConnectedDocumentOutgoingDocCreatePage/ConnectedDocumentOutgoingDocCreatePage'
import ConnectedDocumentOutgoingDocDetailPage from './pages/ConnectedDocumentOutgoingDocDetailPage/ConnectedDocumentOutgoingDocDetailPage'
import ConnectedCommitteeOrganizationPage from './pages/ConnectedCommitteeOrganizationPage/ConnectedCommitteeOrganizationPage'
import ConnectedCommitteeDocumentIncomingDocPage from './pages/ConnectedCommitteeDocumentIncomingDocPage/ConnectedCommitteeDocumentIncomingDocPage'
import ConnectedCommitteeDocumentIncomingDocDetailPage from './pages/ConnectedCommitteeDocumentIncomingDocDetailPage/ConnectedCommitteeDocumentIncomingDocDetailPage'
import ConnectedCommitteeDocumentOutgoingDocPage from './pages/ConnectedCommitteeDocumentOutgoingDocPage/ConnectedCommitteeDocumentOutgoingDocPage'
import ConnectedCommitteeDocumentOutgoingDocCreatePage from './pages/ConnectedCommitteeDocumentOutgoingDocCreatePage/ConnectedCommitteeDocumentOutgoingDocCreatePage'
import ConnectedCommitteeDocumentOutgoingDocDetailPage from './pages/ConnectedCommitteeDocumentOutgoingDocDetailPage/ConnectedCommitteeDocumentOutgoingDocDetailPage'
import ConnectedCommitteeDocumentOutgoingDocUpdatePage from './pages/ConnectedCommitteeDocumentOutgoingDocUpdatePage/ConnectedCommitteeDocumentOutgoingDocUpdatePage'
import InternalDocumentIncomingDocPage from './pages/Documents/InternalDocumentIncomingDocPage/InternalDocumentIncomingDocPage'
import InternalDocumentIncomingDocDetailPage from './pages/Documents/InternalDocumentIncomingDocDetailPage/InternalDocumentIncomingDocDetailPage'
import InternalDocumentIncomingDocCreatePage from './pages/Documents/InternalDocumentIncomingDocCreatePage/InternalDocumentIncomingDocCreatePage'
import InternalDocumentOutgoingDocPage from './pages/Documents/InternalDocumentOutgoingDocPage/InternalDocumentOutgoingDocPage'
import InternalDocumentOutgoingDocDetailPage from './pages/Documents/InternalDocumentOutgoingDocDetailPage/InternalDocumentOutgoingDocDetailPage'
import InternalDocumentOutgoingDocCreatePage from './pages/Documents/InternalDocumentOutgoingDocCreatePage/InternalDocumentOutgoingDocCreatePage' //
import InternalDocumentSignDocPage from './pages/Documents/InternalDocumentSignDocPage'
import InternalDocumentSignDocDetailPage from './pages/Documents/InternalDocumentSignDocDetailPage' //
import SampleDocumentPage from './pages/SampleDocumentPage/SampleDocumentPage'
import SampleDocumentCreatePage from './pages/SampleDocumentCreatePage/SampleDocumentCreatePage'
import DocumentBooksPage from './pages/DocumentBooksPage/DocumentBooksPage'
import AuthoritiesPage from './pages/AuthoritiesPage/AuthoritiesPage'
import CompanyWorkScheduleListPage from './pages/CompanyWorkScheduleListPage/CompanyWorkScheduleListPage'
import CompanyWorkScheduleCreatePage from './pages/CompanyWorkScheduleCreatePage/CompanyWorkScheduleCreatePage'
import DocumentISOFormPage from './pages/DocumentISO/Form/Form'
import DocumentVBPCPage from './pages/DocumentVBPC/DocumentVBPCPage'
import DocumentVBPCCreatePage from './pages/DocumentVBPC/DocumentVBPCCreate/DocumentVBPCCreate'
import DocumentVBPCUpdatePage from './pages/DocumentVBPC/DocumentVBPCUpdate/DocumentVBPCUpdate'
import DocumentVBPCDetailPage from './pages/DocumentVBPC/DocumentVBPCDetail/DocumentVBPCDetail'
import PositionPage from './pages/Position/PositionPage'
import ContactPage from './pages/Contacts/Contact'
import DigitalSignaturePage from './pages/DigitalSignaturePage/DigitalSignaturePage'
import TaskManagementPage from './pages/TaskManagementPage/TaskManagementPage'
import TaskManagementCreatePage from './pages/TaskManagementCreatePage/TaskManagementCreatePage'
import ConsultPage from './pages/Administrative/Consult/ConsultPage'
import ConsultCreatePage from './pages/ConsultCreatePage/ConsultCreatePage'
import ChuTruongPage from './pages/Administrative/ChuTruong'
import ChuTruongCreatePage from './pages/Administrative/ChuTruong/ChuTruongCreatePage'
import TrangBiCNTTPage from './pages/CNTT/TrangBiCNTT/TrangBiCNTTPage'
import TrangBiCNTTChiTietPage from './pages/CNTT/TrangBiCNTT/TrangBiCNTTChiTietPage'
import HoTroCNTTPage from './pages/CNTT/HoTroCNTT/HoTroCNTTPage'
import HoTroCNTTChiTietPage from './pages/CNTT/HoTroCNTT/HoTroCNTTChiTietPage'
import LeaveDocumentPage from './pages/Administrative/LeaveForm/LeaveTable'
import LeaveForm from './pages/Administrative/LeaveForm/Form/Form'
import ProposalPage from './pages/Administrative/Proposal/ProposalPage/ProposalPage'
import ProposalDetailPage from './pages/Administrative/Proposal/ProposalDetailPage/ProposalDetailPage'
import XeCoPage from './pages/Administrative/PhieuTrinh/XeCo/XeCoPage'
import XeCoChiTietPage from './pages/Administrative/PhieuTrinh/XeCo/XeCoChiTietPage'
import CongTacPhiPage from './pages/Administrative/PhieuTrinh/CongTacPhi/CongTacPhiPage'
import CongTacPhiChiTietPage from './pages/Administrative/PhieuTrinh/CongTacPhi/CongTacPhiChiTietPage'
import TiepKhachPage from './pages/Administrative/PhieuTrinh/TiepKhach/TiepKhachPage'
import TiepKhachChiTietPage from './pages/Administrative/PhieuTrinh/TiepKhach/TiepKhachChiTietPage'
import TamUngPage from './pages/Administrative/PhieuTrinh/TamUng/TamUngPage'
import TamUngChiTietPage from './pages/Administrative/PhieuTrinh/TamUng/TamUngChiTietPage'
import QuyetToanPage from './pages/Administrative/PhieuTrinh/QuyetToan/QuyetToanPage'
import QuyetToanChiTietPage from './pages/Administrative/PhieuTrinh/QuyetToan/QuyetToanChiTietPage'
import AdvancePaymentCreatePage from './pages/Administrative/AdvancePayment/AdvancePaymentCreate'
import AdvancePaymentPage from './pages/Administrative/AdvancePayment'
import ChamCongPage from './pages/Administrative/ChamCong/ChamCongPage'
import ChamCongForm from './pages/Administrative/ChamCong/Form/ChamCongForm'
import Payslip from './pages/Administrative/Payslip/Payslip'
import MissionPage from './pages/MissionPage/MissionPage'
import MissionCreatePage from './pages/MissionCreatePage/MissionCreatePage'
import MissionEditPage from './pages/MissionEditPage/MissionEditPage'
import UserGroupPage from './pages/UserGroupPage/UserGroupPage'
import TinTucPage from './pages/TinTuc/TinTucPage'
import TinTucCreatePage from './pages/TinTucCreatePage/TinTucCreatePage'
import TinTucDetailPage from './pages/TinTucDetailPage/TinTucDetailPage'
import MissionDetailPage from './pages/MissionDetailPage'
import MissionFullPage from './pages/MissionPage/MissionFullPage'
import SignAccountPage from './pages/SignAccountPage/SignAccountPage'
import WorkPage from './pages/WorkPage'
import InternalDocumentOutgoingUpdatePage from './pages/Documents/InternalDocumentOutgoingUpdatePage'
import InternalDocumentIncomingUpdatePage from './pages/Documents/InternalDocumentIncomingUpdatePage'
import MessagesPage from './pages/MessagesPage'
import MessageDetailPage from './pages/MessageDetailPage'
import InternalMessageCreateModal from './pages/InternalMessage/InternalMessageCreateModal'
import InternalMessageReceivePage from './pages/InternalMessage/InternalMessageReceivePage'
import InternalMessageReceiveDetailPage from './pages/InternalMessage/InternalMessageReceiveDetailPage'
import InternalMessageSendPage from './pages/InternalMessage/InternalMessageSendPage'
import InternalMessageSendDetailPage from './pages/InternalMessage/InternalMessageSendDetailPage'
import ConnectedDocumentOutgoingDocUpdatePage from './pages/ConnectedDocumentOutgoingDocUpdatePage'
import KpiPage from './pages/KpiPage'
import KpiEditTargetPage from './pages/KpiEditTargetPage'
import KpiEditSubTargetPage from './pages/KpiEditSubTargetPage'
import SalaryPage from './pages/SalaryPage'
import SalaryListPage from './pages/SalaryListPage'
import MySalaryPage from './pages/MySalaryPage'
import ModulePage from './pages/ModulePage'
import ACLPage from './pages/ACLPage'
import ACLCreatePage from './pages/ACLCreatePage'
import InternalDocumentConfigPage from './pages/Documents/InternalDocumentConfigPage'
import ProposalAdvancePage from './pages/ProposalAdvancePage'
import ProposalAdvanceDetailPage from './pages/ProposalAdvanceDetailPage'
import ISODocumentPage from './pages/ISODocumentPage/ISODocumentPage'
import KpiQuarterPage from './pages/KpiQuarterPage/KpiQuarterPage'
import ManageDepartmentPage from './pages/ManageDepartment/ManageDepartmentPage'
import ApprovalTemplatePage from './pages/Administrative/ApprovalTemplate'
import ContentTemplatePage from './pages/ContentTemplate/ContentTemplatePage'
import DocumentManagement from './pages/DocumentManagement'
import DocumentsBookGroup from './pages/DocumentManagement/InternalDocument/DocumentsBookGroup'
import InternalDocument from './pages/DocumentManagement/InternalDocument'
import Authorities from './pages/DocumentManagement/InternalDocument/Authorities'
import ConnectedDocument from './pages/DocumentManagement/ConnectedDocument'
import ConnectedTrucAccount from './pages/DocumentManagement/ConnectedDocument/ConnectedTrucAccount'
import ConnectedCommitteeOrganization from './pages/DocumentManagement/ConnectedCommitteeDocument/ConnectedCommiteeOrganization'
import DocumentAccessibility from './pages/DocumentManagement/InternalDocument/DocumentAccessibility'
import AdministrativeManagement from './pages/Administrative/AdministrativeManagement'
import AdministrativeManagementContentTemplate from './pages/Administrative/AdministrativeManagement/AdministrativeManagementContentTemplate'
import AdministrativeManagementApprovalTemplate from './pages/Administrative/AdministrativeManagement'
import MissionManagement from './pages/MissionManagement'
import MissionManagementMissionGroup from './pages/MissionManagement/MissionManagementMissionGroup'
import MissionManagementAccessibility from './pages/MissionManagement/MissionManagementAccessibility'
import SalaryManagement from './pages/SalaryManagement'
import UserGroup from './pages/DocumentManagement/UserGroup'
import ProposalSalaryCreate from './pages/ProposalSalaryRequestPage/ProposalSalaryCreate'
import ProposalSalaryRequestPage from './pages/ProposalSalaryRequestPage'
import ProposalSalaryDetail from './pages/ProposalSalaryRequestPage/ProposalSalaryDetail'
import MissionGroupPage from './pages/MissionGroupPage'
export const normalRoutes = [
  {
    path: '/login',
    component: DashboardPageV2,
    name: 'Login',
  },
  {
    path: '/portalv2',
    component: PortalPageV2,
    name: 'Trang chủ',
  },
]

export const routes = [
  // {
  //   path: '/login',
  //   component: PortalPageV2,
  //   name: 'Login',
  // },
  // {
  //   path: '/portalv2',
  //   component: PortalPageV2,
  //   name: 'Trang chủ',
  // },
  {
    path: '/',
    component: PortalPageV2,
    name: 'Trang chủ',
  },
  {
    path: '/dashboard',
    component: DashboardPageV2,
    name: 'DashboardPage',
  },
  {
    path: '/admin/user-account-management',
    component: UserAccountManagementPage,
    name: 'Quản lý tài khoản',
  },
  {
    path: '/admin/user-app-management',
    component: UserAppManagementPage,
    name: 'Quản lý phần mềm',
  },
  {
    path: '/my-profile',
    component: MyProfilePage,
    name: 'Thông tin của bạn',
  },
  {
    path: '/notification',
    component: NotificationPage,
    name: 'Thông báo',
  },
  //
  {
    path: '/eMail',
    component: EmailRedirectPage,
    name: 'eMail',
  },
  {
    path: '/cloud',
    component: CloudRedirectPage,
    name: 'cloud',
  },
  //Liên thông văn bản VIMC
  {
    path: '/connected-document',
    component: ConnectedDocumentIncomingDocPage,
    name: 'Liên thông văn bản VIMC',
  },
  {
    path: '/connected-document/incoming-document',
    component: ConnectedDocumentIncomingDocPage,
    name: 'Văn bản đến',
  },
  {
    path: '/connected-document/incoming-document/create',
    component: ConnectedDocumentIncomingDocCreatePage,
    name: 'Tạo văn bản đến',
  },
  {
    path: '/connected-document/incoming-document/view/:documentId',
    component: ConnectedDocumentIncomingDocDetailPage,
    name: 'Chi tiết',
  },
  {
    path: '/connected-document/outgoing-document',
    component: ConnectedDocumentOutgoingDocPage,
    name: 'Văn bản đi',
  },
  {
    path: '/connected-document/outgoing-document/create',
    component: ConnectedDocumentOutgoingDocCreatePage,
    name: 'Tạo văn bản đi',
  },
  {
    path: '/connected-document/outgoing-document/:documentId',
    component: ConnectedDocumentOutgoingDocUpdatePage,
    name: 'Chỉnh sửa văn bản đi',
  },
  {
    path: '/connected-document/outgoing-document/view/:documentId',
    component: ConnectedDocumentOutgoingDocDetailPage,
    name: 'Chi tiết',
  },
  //
  //Liên thông văn bản UBQLV
  {
    path: '/connected-committee-document',
    component: ConnectedCommitteeDocumentIncomingDocPage,
    name: 'Liên thông văn bản UBQLV',
  },
  {
    path: '/connected-committee-document/incoming-document',
    component: ConnectedCommitteeDocumentIncomingDocPage,
    name: 'Văn bản đến',
  },
  {
    path: '/connected-committee-document/incoming-document/view/:documentId',
    component: ConnectedCommitteeDocumentIncomingDocDetailPage,
    name: 'Chi tiết',
  },
  {
    path: '/connected-committee-document/outgoing-document',
    component: ConnectedCommitteeDocumentOutgoingDocPage,
    name: 'Văn bản đi',
  },
  {
    path: '/connected-committee-document/outgoing-document/create',
    component: ConnectedCommitteeDocumentOutgoingDocCreatePage,
    name: 'Tạo văn bản đi',
  },
  {
    path: '/connected-committee-document/outgoing-document/view/:documentId',
    component: ConnectedCommitteeDocumentOutgoingDocDetailPage,
    name: 'Chi tiết',
  },
  {
    path: '/connected-committee-document/outgoing-document/update/:documentId',
    component: ConnectedCommitteeDocumentOutgoingDocUpdatePage,
    name: 'Chỉnh sửa',
  },
  {
    path: '/connected-committee-document-organization',
    component: ConnectedCommitteeOrganizationPage,
    name: 'Danh sách công ty nhận',
  },
  {
    path: '/connected-committee-document/organization',
    component: ConnectedCommitteeOrganizationPage,
    name: 'Danh sách công ty nhận',
  },
  //Văn bản VIMC
  {
    path: '/internal-document',
    component: InternalDocumentIncomingDocPage,
    name: 'Quản lý VB nội bộ',
  },
  {
    path: '/internal-document/incoming-document',
    component: InternalDocumentIncomingDocPage,
    name: 'Văn bản đến',
  },
  {
    path: '/internal-document/incoming-document/view/:documentId',
    component: InternalDocumentIncomingDocDetailPage,
    name: 'Chi tiết',
  },
  {
    path: '/internal-document/incoming-document/create',
    component: InternalDocumentIncomingDocCreatePage,
    name: 'Tạo văn bản đến',
  },
  {
    path: '/internal-document/incoming-document/:documentId',
    component: InternalDocumentIncomingUpdatePage,
    name: 'Chỉnh sửa văn bản đến',
  },
  {
    path: '/internal-document/outgoing-document',
    component: InternalDocumentOutgoingDocPage,
    name: 'Văn bản đi',
  },
  {
    path: '/internal-document/outgoing-document/view/:documentId',
    component: InternalDocumentOutgoingDocDetailPage,
    name: 'Chi tiết',
  },
  {
    path: '/internal-document/outgoing-document/create',
    component: InternalDocumentOutgoingDocCreatePage,
    name: 'Tạo văn bản đi',
  },
  {
    path: '/internal-document/outgoing-document/:documentId',
    component: InternalDocumentOutgoingUpdatePage,
    name: 'Chỉnh sửa văn bản đi',
  },

  // =---------------==//
  {
    path: '/internal-document/sign-document',
    component: InternalDocumentSignDocPage,
    name: 'Phát hành văn bản điện tử',
  },
  {
    path: '/internal-document/sign-document/view/:documentId',
    component: InternalDocumentSignDocDetailPage,
    name: 'Chi tiết trình ký',
  },

  {
    path: '/internal-document/config-document/document-books',
    component: InternalDocumentConfigPage,
    name: 'Cấu hình nhóm sổ văn bản',
  },
  {
    path: '/internal-document/config-document/authorities',
    component: InternalDocumentConfigPage,
    name: 'Cấu hình cơ quan ban hành',
  },

  //Sample document
  {
    path: '/management/sample-document',
    component: SampleDocumentPage,
    name: 'Danh sách văn bản mẫu',
  },
  {
    path: '/management/sample-document/create',
    component: SampleDocumentCreatePage,
    name: 'Tạo văn bản mẫu',
  },
  {
    path: '/management/sample-document/edit/:documentId',
    component: SampleDocumentCreatePage,
    name: 'Chi tiết',
  },
  //Document books page
  {
    path: '/management/document-books',
    component: DocumentBooksPage,
    name: 'Sổ văn bản',
  },
  //Authorities page
  {
    path: '/eoffice-config/authorities',
    component: AuthoritiesPage,
    name: 'Cơ quan ban hành',
  },
  //Utilities page
  {
    path: '/company-work-schedule',
    component: CompanyWorkScheduleListPage,
    name: 'Lịch cơ quan',
  },
  {
    path: '/company-work-schedule/create',
    component: CompanyWorkScheduleCreatePage,
    name: 'Tạo lịch cơ quan',
  },
  {
    path: '/company-work-schedule/view/:scheduleId',
    component: CompanyWorkScheduleCreatePage,
    name: 'Chi tiết',
  },
  {
    path: '/utility/iso',
    component: ISODocumentPage,
    name: 'Tài liệu ISO',
  },
  {
    path: '/utility/iso/:documentId',
    component: DocumentISOFormPage,
    name: 'Chi tiết',
  },
  {
    path: '/utility/vbpc',
    component: DocumentVBPCPage,
    name: 'VB Pháp chế',
  },
  {
    path: '/utility/vbpc/create',
    component: DocumentVBPCCreatePage,
    name: 'Tạo mới vbpc',
  },
  {
    path: '/utility/vbpc/:documentId',
    component: DocumentVBPCUpdatePage,
    name: 'Chỉnh sửa vbpc',
  },
  {
    path: '/utility/vbpc/view/:documentId',
    component: DocumentVBPCDetailPage,
    name: 'Chi tiết vbpc',
  },

  {
    path: '/management/position',
    component: PositionPage,
    name: 'Quản lý chức danh',
  },
  {
    path: '/utility/messages',
    component: MessagesPage,
    name: 'Tin nhắn liên thông',
  },
  {
    path: '/utility/messages/view/:messageId',
    component: MessageDetailPage,
    name: 'Chi tiết',
  },
  {
    path: '/utility/internal-message/create',
    component: InternalMessageCreateModal,
    name: 'Tạo tin nhắn',
  },
  {
    path: '/utility/internal-message',
    component: InternalMessageReceivePage,
    name: 'Tin nhắn nội bộ',
  },
  {
    path: '/utility/internal-message/receive',
    component: InternalMessageReceivePage,
    name: 'Tin nhắn đến',
  },
  {
    path: '/utility/internal-message/receive/view/:code',
    component: InternalMessageReceiveDetailPage,
    name: 'Chi tiết tin nhắn nội bộ đến',
  },
  {
    path: '/utility/internal-message/send',
    component: InternalMessageSendPage,
    name: 'Tin nhắn đi',
  },
  {
    path: '/utility/internal-message/send/view/:code',
    component: InternalMessageSendDetailPage,
    name: 'Chi tiết tin nhắn nội bộ đi',
  },
  {
    path: '/utility/contacts',
    component: ContactPage,
    name: 'Danh bạ',
  },
  {
    path: '/management/department',
    component: ManageDepartmentPage,
    name: 'Quản lý phòng ban',
  },
  {
    path: '/utility/digital-signature',
    component: DigitalSignaturePage,
    name: 'Ký số điện tử',
  },
  //Hành chính -> Công việc của tôi
  {
    path: '/my-tasks',
    component: TaskManagementPage,
    name: 'Công việc của tôi',
  },
  {
    path: '/my-tasks/view/:taskId',
    component: TaskManagementPage,
    name: 'Chi tiết',
  },
  {
    path: '/my-tasks/create',
    component: TaskManagementCreatePage,
    name: 'Tạo công việc',
  },
  // Xin chủ trương
  {
    path: '/administrative/policy',
    component: ChuTruongPage,
    name: 'Xin chủ trương',
  },
  {
    path: '/administrative/policy/view/:policyId',
    component: ChuTruongPage,
    name: 'Chi tiết',
  },
  {
    path: '/administrative/policy/create',
    component: ChuTruongCreatePage,
    name: 'Thêm mới chủ trương',
  },
  // Xin ý kiến
  {
    path: '/administrative/consult',
    component: ConsultPage,
    name: 'Xin ý kiến',
  },
  {
    path: '/administrative/consult/view/:consultId',
    component: ConsultPage,
    name: 'Chi tiết',
  },
  {
    path: '/administrative/consult/create',
    component: ConsultCreatePage,
    name: 'Thêm mới ý kiến',
  },
  //Hành chính -> CNTT
  {
    path: '/administrative/trang-bi-cntt',
    component: TrangBiCNTTPage,
    name: 'Trang bị CNTT',
  },
  {
    path: '/administrative/trang-bi-cntt/:id',
    component: TrangBiCNTTChiTietPage,
    name: 'Chi tiết',
  },
  {
    path: '/administrative/ho-tro-cntt',
    component: HoTroCNTTPage,
    name: 'Hỗ trợ CNTT',
  },
  {
    path: '/administrative/ho-tro-cntt/:id',
    component: HoTroCNTTChiTietPage,
    name: 'Chi tiết',
  },
  //Hành chính -> Nghỉ phép
  {
    path: '/administrative/leave-form',
    component: LeaveDocumentPage,
    name: 'Nghỉ phép',
  },
  {
    path: '/administrative/leave-form/:leaveDocId',
    component: LeaveForm,
    name: 'Chi tiết',
  },
  //Hành chính
  {
    path: '/proposal',
    component: ProposalPage,
    name: 'Hành chính',
  },
  {
    path: '/proposal/:typePage/view/:proposalId',
    component: ProposalDetailPage,
    name: 'Chi tiết',
  },
  // Tạm ứng
  {
    path: '/proposal-advance',
    component: ProposalAdvancePage,
    name: 'Tạm ứng',
  },
  {
    path: '/proposal-advance/view/:id',
    component: ProposalAdvanceDetailPage,
    name: 'Chi tiết trình tạm ứng',
  },

  {
    path: '/approval-templates',
    component: ApprovalTemplatePage,
    name: 'Mẫu duyệt',
  },
  //Quản lí hành chính
  {
    path: '/administrative-management',
    component: AdministrativeManagement,
    name: 'Quản lý hành chính'
  },
  {
    path: '/administrative-management/content-template',
    component: AdministrativeManagementContentTemplate,
    name: 'Biểu mẫu'
  },
  {
    path: '/administrative-management/approval-templates',
    component: AdministrativeManagementApprovalTemplate,
    name: 'Mẫu duyệt'
  },
  //Phiếu trình xe cộ
  {
    path: '/administrative/phieu-trinh/xe-co',
    component: XeCoPage,
    name: 'Phiếu trình xe cộ',
  },
  {
    path: '/administrative/phieu-trinh/xe-co/:id',
    component: XeCoChiTietPage,
    name: 'Chi tiết',
  },
  {
    path: '/administrative/phieu-trinh/cong-tac-phi',
    component: CongTacPhiPage,
    name: 'Công tác phí',
  },
  {
    path: '/administrative/phieu-trinh/cong-tac-phi/:id',
    component: CongTacPhiChiTietPage,
    name: 'Chi tiết',
  },
  //Tiếp khách
  {
    path: '/administrative/phieu-trinh/tiep-khach',
    component: TiepKhachPage,
    name: 'Tiếp khách',
  },
  {
    path: '/administrative/phieu-trinh/tiep-khach/:id',
    component: TiepKhachChiTietPage,
    name: 'Chi tiết',
  },
  //Tạm ứng
  {
    path: '/administrative/phieu-trinh/tam-ung',
    component: TamUngPage,
    name: 'Tạm ứng',
  },
  {
    path: '/administrative/phieu-trinh/tam-ung/:id',
    component: TamUngChiTietPage,
    name: 'Chi tiết',
  },
  //Quyết toán
  {
    path: '/administrative/phieu-trinh/quyet-toan',
    component: QuyetToanPage,
    name: 'Quyết toán',
  },
  {
    path: '/administrative/phieu-trinh/quyet-toan/:id',
    component: QuyetToanChiTietPage,
    name: 'Chi tiết',
  },
  {
    path: '/administrative/advance-payment/create',
    component: AdvancePaymentCreatePage,
    name: 'Thêm mới hồ sơ giao nhận với KBNN',
  },
  {
    path: '/administrative/advance-payment',
    component: AdvancePaymentPage,
    name: 'Danh sách hồ sơ giao nhận với KBNN',
  },
  {
    path: '/administrative/cham-cong',
    component: ChamCongPage,
    name: 'Chấm công',
  },
  {
    path: '/administrative/cham-cong/:chamCongId',
    component: ChamCongForm,
    name: 'Chi tiết',
  },
  //
  {
    path: '/administrative/payslip',
    component: Payslip,
    name: 'Bảng lương',
  },
  //Nhiệm vụ
  {
    path: '/mission',
    component: MissionPage,
    name: 'Nhiệm vụ',
  },
  {
    path: '/mission/full',
    component: MissionFullPage,
    name: 'Nhiệm vụ',
  },
  {
    path: '/mission/create',
    component: MissionCreatePage,
    name: 'Tạo mới nhiệm vụ',
  },
  {
    path: '/mission/edit/:id',
    component: MissionEditPage,
    name: 'Chỉnh sửa nhiệm vụ',
  },
  {
    path: '/mission/view/:missionId',
    component: MissionDetailPage,
    name: 'Chi tiết',
  },
  {
    path: '/mission-group',
    component: MissionGroupPage,
    name: 'Lãnh đạo',
  },
  {
    path: '/mission-management',
    component: MissionManagement,
    name: 'Quản lý nhiệm vụ',
  },
  {
    path: '/mission-management/mission-group',
    component: MissionManagementMissionGroup,
    name: 'Nhóm nhiệm vụ',
  },
  {
    path: '/mission-management/mission-accessibility',
    component: MissionManagementAccessibility,
    name: 'Quyền thao tác',
  },
  //
  {
    path: '/management/group',
    component: UserGroupPage,
    name: 'Danh sách nhóm người dùng',
  },
  {
    path: '/management/nhiem-vu-group',
    component: UserGroupPage,
    name: 'Danh sách nhóm nhiệm vụ',
  },
  {
    path: '/management/sign-account',
    component: SignAccountPage,
    name: 'Tài khoản ký số',
  },
  // Tin tuc
  {
    path: '/utility/general-notifications',
    component: TinTucPage,
    name: 'Thông báo chung',
  },
  {
    path: '/utility/general-notifications/create',
    component: TinTucCreatePage,
    name: 'Đăng thông báo',
  },
  {
    path: '/utility/general-notifications/update/:id',
    component: TinTucCreatePage,
    name: 'Chỉnh sửa thông báo',
  },
  {
    path: '/utility/general-notifications/view/:id',
    component: TinTucDetailPage,
    name: 'Chi tiết thông báo',
  },
  // Công việc
  {
    path: '/works',
    component: WorkPage,
    name: 'Công việc',
  },
  {
    path: '/works/view/:code_work',
    component: WorkPage,
    name: 'Chi tiết công việc',
  },
  //KPI Quarter
  {
    path: '/kpi_quarter',
    component: KpiQuarterPage,
    name: 'KPI Quý',
  },
  {
    path: '/kpi_quarter/view/:kpi_quarter_code',
    component: KpiPage,
    name: 'KPI Quarter Details',
  },
  // KPI
  {
    path: '/kpi/view/:kpi_quarter_code/:id',
    component: KpiEditTargetPage,
    name: 'KPI',
  },
  {
    path: '/kpi/target/view/:kpi_quarter_code/:kpiId/:targetId',
    component: KpiEditSubTargetPage,
    name: 'KPI',
  },

  // Lương
  {
    path: '/salary/create',
    component: SalaryPage,
    name: 'Trình duyệt bảng lương',
  },
  {
    path: '/salary/view/:id',
    component: SalaryPage,
    name: 'Chi tiết bảng lương',
  },
  {
    path: '/salary',
    component: SalaryListPage,
    name: 'Duyệt lương',
  },
  {
    path: '/proposal-salary-request',
    component: ProposalSalaryRequestPage,
    name: 'Duyệt lương',
  },
  {
    path: '/proposal-salary-request/view/:proposalId',
    component: ProposalSalaryDetail,
    name: 'Trình duyệt lương',
  },
  {
    path: '/proposal-salary-request/create',
    component: ProposalSalaryCreate,
    name: 'Trình duyệt bảng lương',
  },
  {
    path: '/my-salary',
    component: MySalaryPage,
    name: 'Bảng lương của tôi',
  },
  {
    path: '/proposal-salary-management',
    component: SalaryManagement,
    name: 'Quản lý bảng lương',
  },
  // Module
  {
    path: '/module',
    component: ModulePage,
    name: 'Quản lý phân hệ',
  },
  {
    path: '/acl',
    component: ACLPage,
    name: 'Quản lý phân quyền',
  },
  {
    path: '/acl/create',
    component: ACLCreatePage,
    name: 'Thiết lập phân quyền',
  },
  {
    path: '/acl/view/:code',
    component: ACLCreatePage,
    name: 'Thiết lập phân quyền',
  },

  //Content template Biểu mẫu
  {
    path: '/content-template',
    component: ContentTemplatePage,
    name: 'Biểu mẫu',
  },
  // {
  //   path : '/content-template/:key',
  //   component: ContentTemplatePage,
  //   name : 'Sửa Biểu mẫu'
  // }

  {
    path: '/document-management',
    component: DocumentManagement,
    name: 'Quản trị văn bản',
  },
  {
    path: '/document-management/internal-document',
    component: InternalDocument,
    name: 'Văn bản nội bộ',
  },
  {
    path: '/document-management/internal-document/document-books',
    component: DocumentsBookGroup,
    name: 'Nhóm sổ văn bản',
  },
  {
    path: '/document-management/internal-document/authorities',
    component: Authorities,
    name: 'Cơ quan ban hành',
  },
  {
    path: '/document-management/connected-document',
    component: ConnectedDocument,
    name: 'Liên thông văn bản'
  },
  {
    path: '/document-management/internal-document/document-accessibility',
    component: DocumentAccessibility,
    name: 'Quyền xem văn bản'
  },
  {
    path: '/document-management/connected-document/connected-truc-account',
    component: ConnectedTrucAccount,
    name: 'Liên kết tài khoản trục'
  },
  {
    path: '/document-management/connected-committee-document',
    component: ConnectedCommitteeOrganization,
    name: 'Liên thông văn bản UBQLV'
  },
  {
    path: '/document-management/user-group',
    component: UserGroup,
    name: 'Nhóm người dùng'
  },
  {
    path: '/document-management/connected-committee-document/organization',
    component: ConnectedCommitteeOrganization,
    name: 'Công ty nhận'
  },
]

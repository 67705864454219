import {
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Dropdown, Menu, message, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useHistory, useParams } from 'react-router-dom'
import { blue, green, purple9, redPrimary } from '../../color'
import ApproverListModal from '../../components/ApproverListModal'
import { EmptyText, TitleContentBox } from '../../components/Common/CellText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import { StatusTag } from '../../components/Common/StatusTag'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import FileViewPopup from '../../components/FileViewPopup'
import PageTitle from '../../components/PageTitle'
import PopoverWatchTime from '../../components/PopoverWatchTime'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { AttachmentList } from '../../components/TaskManagementDetail/TaskManagementDetailStyled'
import { ACL_ACTION_TYPE, TYPE_STATUS } from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import utils from '../../utils'
import ProposalComment from '../Administrative/Proposal/ProposalDetailPage/ProposalComment'
import { DocumentInfoList } from '../ConnectedCommitteeDocumentIncomingDocDetailPage/ConnectedCommitteeDocumentIncomingDocDetailPageStyled'
import { ViewDetailContent } from '../DocumentISO/Form/FormStyled'
import {
  BlockLeft,
  BlockRight,
  HeadingButtonWrapper,
} from '../MissionDetailPage/MissionDetailPageStyled'
import SalaryInfo from '../SalaryPage/SalaryInfo'
import { ContentWrapper } from '../WorkPage/DrawerWork/DrawerWorkStyled'
import ProposalSalaryDetailAction from './ProposalSalaryDetailAction'
import ProposalSalaryDetailUpdate from './ProposalSalaryDetailUpdate'
import SalaryList from './SalaryList'

const show = {
  flex: '0 0 360px',
  width: 360,
  transition: '0.5s',
  overflow: 'hidden',
}
const hide = {
  flex: '0 0 0',
  width: 0,
  transition: '0.5s',
  overflow: 'hidden',
}

const salaryTableTitleStyle = {
    display: 'flex', 
    flexWrap: 'wrap',
    fontWeight: 600,
    gap: '4px'
}
const ProposalSalaryDetail = props => {
  const { proposalSalaryStore, commonStore, aclStore, authenticationStore, proposalStore, loadingAnimationStore } = props
  const { isAccountAdmin } = authenticationStore
  const { isShowSalaryInfo, salaryInfo } = proposalSalaryStore
  
  const [isVisibleFileViewPopup, setIsVisibleFileViewPopup] = useState(false)
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)

  const [isLoadingSalary, setIsLoadingSalary] = useState(false)
  const [isLoadingDetailSalary, setIsLoadingDetailSalary] = useState(false)

  const { proposalId: salaryCode } = useParams()
  const history = useHistory()
  const { confirm } = Modal

  useEffect(() => {
    if (salaryCode) {
      ;(async () => {
        try {
          setIsLoadingDetailSalary(true)
          await proposalSalaryStore.getSalaryDetail(salaryCode)
        } catch (err) {
          console.log(err)
          message.error(err.vi || 'Đã có lỗi xảy ra!')
        } finally {
          setIsLoadingDetailSalary(false)
        }
      })()
    }
    return () => {
      proposalSalaryStore.clearSalary()
    }
  }, [salaryCode])


  const renderFileIcon = file_type => {
    let iconFile = <FileTextOutlined />
    if (file_type === 'pdf') {
      iconFile = <FilePdfOutlined style={{ color: redPrimary }} />
      return iconFile
    }
    if (file_type === 'doc' || file_type === 'docx') {
      iconFile = <FileWordOutlined style={{ color: blue }} />
      return iconFile
    }
    if (
      [
        'jpg',
        'bmp',
        'dib',
        'jpe',
        'jfif',
        'gif',
        'tif',
        'tiff',
        'png',
        'heic',
      ].includes(file_type)
    ) {
      iconFile = <FileImageOutlined style={{ color: purple9 }} />
      return iconFile
    }
    if (file_type === 'rar' || file_type === 'zip') {
      iconFile = <FileZipOutlined style={{ color: '#e67e22' }} />
      return iconFile
    }
    if (file_type === 'xlsx') {
      iconFile = <FileExcelOutlined style={{ color: green }} />
      return iconFile
    }
  }

  const renderAttachmentList = useCallback(() => {
    return salaryInfo.attachments?.map(item => {
      return (
        <FileItem
          key={item.file_id}
          file_id={item.file_id}
          file_name={item.file_name}
          file_type={utils.getExtensionFile(item.file_name)}
        />
      )
    })
  }, [commonStore.appTheme, renderFileIcon, salaryInfo])

  const renderTitlePage = useMemo(() => {
    switch (salaryInfo.proposal_type) {
      case 'BUSINESS_TRIP':
        return 'Đăng ký công tác'
      case 'EVALUATE_MEMBER':
        return 'Đánh giá cán bộ < Nhân viên >'
      case 'EVALUATE_LEADER':
        return 'Đánh giá cán bộ < Trưởng ban >'
      case 'TIMEKEEPER':
        return 'Tổng hợp chấm công'
      case 'STATIONERY':
        return 'Văn phòng phẩm'
      case 'MEETING':
        return 'Mời họp'
      case 'LEAVE':
        return 'Nghỉ phép'
      case 'LEAVE_ANNUAL':
        return 'Nghỉ phép hàng năm'
      case 'LEAVE_NO_SALARY':
        return 'Nghỉ phép không lương'
      case 'VEHICLE':
        return 'Xin xe'
      case 'PROPOSAL_SALARY':
        return 'Duyệt lương'
    }
  }, [salaryInfo])

  const handleToggleUpdateModal = (bool) => (event) => {
    setIsVisibleUpdateModal(bool)
  }

  const checkHasNoArppovedLevel = () => {
    if (salaryInfo.has_no_config_approval_levels) return true

    if (salaryInfo.approval_levels)
      return salaryInfo.approval_levels
        .slice(1)
        .every(level => level.status !== 'APPROVED')
  }

  const checkShowHandleDocument = () => {
    const checkAllowDelete =
      aclStore.checkAccessControlAction(ACL_ACTION_TYPE.delete__PROPOSAL) ||
      isAccountAdmin

    return (
      salaryInfo &&
        !salaryInfo?.has_no_config_approval_levels &&
        checkHasNoArppovedLevel()
         && (checkAllowDelete || checkAccessControlUpdateAction())
    )
  }

  const handleDeleteProposalSalary = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await proposalStore.deleteProposalItem(salaryCode)
      history.push('/proposal-salary-request')
      message.success('Xóa thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra, vui lòng thử lại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showConfirmDelete = () => {
    confirm({
      title: 'Bạn có muốn xóa thông tin này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteProposalSalary()
      },
    })
  }

  const checkAccessControlUpdateAction = () => {
    return (
      authenticationStore.currentUser.username ===
      salaryInfo.create_user?.user_name
    )
  }

  const menuHandleDocument = (
    <Menu>
      {salaryInfo &&
        !salaryInfo?.has_no_config_approval_levels &&
        checkHasNoArppovedLevel() && (
          <>
          {checkAccessControlUpdateAction() && (
            <Menu.Item
              style={{ color: blue }}
              onClick={handleToggleUpdateModal(true)}
              icon={<EditOutlined />}>
              Chỉnh sửa
            </Menu.Item>
          )}
            {(aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.delete__PROPOSAL
            ) ||
              isAccountAdmin) && (
              <Menu.Item
                danger
                onClick={showConfirmDelete}
                icon={<DeleteOutlined />}>
                Xóa
              </Menu.Item>
            )}
          </>
        )}
    </Menu>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>Bảng lương | VIMC bảng lương</title>
      </Helmet>
      <PageTitle
        title={'Trình duyệt lương'}
        showTitle={true}
        status={StatusTag(TYPE_STATUS.PROPOSAL, salaryInfo.proposal_status)}
        location={props.location}>
        <HeadingButtonWrapper>
          {checkShowHandleDocument() ? (
            <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          ) : (
            <></>
          )}
        </HeadingButtonWrapper>
      </PageTitle>
      {!isLoadingDetailSalary &&
      salaryInfo &&
      !salaryInfo?.has_no_config_approval_levels ? (
        <ProposalSalaryDetailAction />
      ) : (
        <></>
      )}

      {!isLoadingDetailSalary && (
        <>
          <ViewDetailContent>
            <ContentWrapper style={{ display: 'flex' }}>
              <BlockLeft>
                <ContentBlockWrapper>
                  <h2 style={salaryTableTitleStyle}>
                    Chi tiết bảng lương từ file:
                    <FileItem
                      key={salaryInfo.file_id}
                      file_id={salaryInfo.file_id}
                      file_name={salaryInfo.file_name}
                      file_type={utils.getExtensionFile(salaryInfo.file_name)}
                      noPreview
                      noIcon
                    />
                  </h2>
                  <SalaryList
                    isLoadingSalary={isLoadingSalary}
                    isShowSalaryInfo={isShowSalaryInfo}
                  />
                  <div style={isShowSalaryInfo ? show : hide}>
                    <SalaryInfo />
                  </div>
                </ContentBlockWrapper>
              </BlockLeft>

              <BlockRight>
                <ContentBlockWrapper>
                  <TitleContentBox>Thông tin chung</TitleContentBox>
                  <DocumentInfoList>
                    <dt>Loại</dt>
                    <dd>{renderTitlePage}</dd>

                    <dt>Tài liệu đính kèm</dt>
                    <dd>
                      {salaryInfo.attachments?.length !== 0 ? (
                        <>
                          <AttachmentList>
                            {renderAttachmentList()}
                          </AttachmentList>
                        </>
                      ) : (
                        <EmptyText>Không có tài liệu đính kèm.</EmptyText>
                      )}
                    </dd>

                    <dt>Người theo dõi</dt>
                    <dd>
                      {salaryInfo.followers &&
                      salaryInfo.followers.length > 0 ? (
                        <PopoverWatchTime
                          userList={salaryInfo.followers.map(item => {
                            return {
                              full_name: item.name_lowercase,
                              id: item.user_name,
                              assignee_type: 'USER',
                              last_date_read: item.last_date_read,
                            }
                          })}
                          title={'Danh sách người theo dõi'}
                        />
                      ) : (
                        <EmptyText>Không có người theo dõi.</EmptyText>
                      )}
                    </dd>
                  </DocumentInfoList>
                </ContentBlockWrapper>

                <ContentBlockWrapper style={{ position: 'relative' }}>
                  <ProposalComment proposalId={salaryInfo.proposal_code} />
                  <SpinnerInlineComponent sizeSpin={'small'} />
                </ContentBlockWrapper>
              </BlockRight>
            </ContentWrapper>
          </ViewDetailContent>

          <ApproverListModal />
        </>
      )}

      {isLoadingDetailSalary && (
        <ContentBlockWrapper style={{ height: 600, position: 'relative' }}>
          <SpinnerInlineComponent
            noBackground
            alignItems
            isLoading={isLoadingDetailSalary}
            tip={'Đang tải bảng lương...'}
          />
        </ContentBlockWrapper>
      )}
      <FileViewPopup
        file={{
          file_name: salaryInfo.file_name,
          file_id: salaryInfo.file_id,
        }}
        isVisibleFileViewPopup={isVisibleFileViewPopup}
        handleCloseFileViewPopup={() => setIsVisibleFileViewPopup(false)}
      />

      <Modal
        title={'Chỉnh trình duyệt lương'}
        onCancel={handleToggleUpdateModal(false)}
        width={1000}
        footer={null}
        maskClosable={false}
        visible={isVisibleUpdateModal}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <ProposalSalaryDetailUpdate onCancel={handleToggleUpdateModal(false)} />
        )}
      </Modal>
    </DashboardLayout>
  )
}

ProposalSalaryDetail.propTypes = {}

export default inject(
  'proposalSalaryStore',
  'proposalStore',
  'loadingAnimationStore',
  'commonStore', 
  'authenticationStore',
  'aclStore'
)(observer(ProposalSalaryDetail))

import { Drawer, message, Switch } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import ListAccountOfCommand from '../ListAccountOfCommand/ListAccountOfCommand'

const ConnectedTrucAccountUserConfigPanel = props => {
  const {
    selectedCommandCode,
    drawerVisible,
    commandStore,
    onClose,
    userStore,
    accountEditStore,
    authenticationStore,
    loadingAnimationStore,
  } = props

  const { selectedUser } = userStore
  const { accountList, userCommands } = accountEditStore

  const [isActive, setIsActive] = useState()

  const trucCommand = commandStore.commandList.find(
    command => command.code === selectedCommandCode
  )

  useEffect(() => {
    // console.log('userCommands', toJS(userCommands))
    setIsActive(
      userCommands.find(command => command.code === selectedCommandCode)
    )
  }, [userCommands])

  // useEffect(() => {
  //   console.log(
  //     'accountList',
  //     toJS(accountList.filter(item => item.command.code === trucCommand.code))
  //   )
  // }, [accountList])

  const handleChangeCommand = async (status, commandCode, commandName) => {
    try {
      let updatedStatusText
      const commandObj = {
        code: commandCode,
        name: commandName,
      }
      const selectedCommandArr = selectedUser && selectedUser.commands
      if (
        selectedCommandArr &&
        selectedCommandArr.findIndex(
          command => command.code === commandCode
        ) === -1
      ) {
        userStore.updateSelectedUser('commands', [
          ...selectedCommandArr,
          commandObj,
        ])
        updatedStatusText = 'kích hoạt'
      } else {
        userStore.updateSelectedUser(
          'commands',
          selectedCommandArr.filter(command => command.code !== commandCode)
        )
        updatedStatusText = 'tắt'
      }

      //
      loadingAnimationStore.showSpinner(true)
      await userStore.updateUserCommands(
        selectedUser.code,
        selectedUser.commands.map(command => command.code)
      )
      await Promise.all([
        authenticationStore.checkCurrentUser(),
        accountEditStore.getCommandsByUser(selectedUser.code), //to refresh isActive
      ])
      loadingAnimationStore.showSpinner(false)
      message.success(`Đã ${updatedStatusText} dịch vụ ${commandName}!`)
    } catch (e) {
      message.error(e?.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <>
      <Drawer
        title={
          <div style={{ display: 'flex', gap: '12px' }}>
            <span>{trucCommand?.name}</span>
            <Switch
              onChange={status =>
                handleChangeCommand(
                  status,
                  trucCommand?.code,
                  trucCommand?.name
                )
              }
              checked={isActive}
            />
          </div>
        }
        placement={'left'}
        visible={drawerVisible}
        width={680}
        onClose={onClose}>
        {accountList && trucCommand ? (
          <ListAccountOfCommand
            selectedCommand={trucCommand}
            commandAccounts={accountList.filter(
              item => item.command.code === trucCommand.code
            )}
          />
        ) : (
          <>Không tìm thấy account</>
        )}
      </Drawer>
    </>
  )
}

export default inject(
  'userStore',
  'authenticationStore',
  'loadingAnimationStore',
  'commandStore',
  'accountEditStore'
)(observer(ConnectedTrucAccountUserConfigPanel))

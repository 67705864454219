import React, { memo, useEffect, useState } from 'react'

// Styled component
import { ModalRelease } from './PopupReleaseElectronicDocumentStyled'
// Ant design
import { Button } from 'antd'
// Other
import { inject, observer } from 'mobx-react'

import InternalDocumentOutgoingDocCreateForm from '../../InternalDocumentOutgoingDocCreatePage/InternalDocumentOutgoingDocCreateForm'
import { CloseOutlined } from '@ant-design/icons'

const PopupReleaseElectronicDocument = props => {
  const {
    fileActive,
    isVisiblePopupRelease,
    electronicDocumentStore,
    handleClosePopupElectronicDocument,
  } = props

  const { selectedElectronicDocument } = electronicDocumentStore
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [fileRelease, setFileRelease] = useState()

  useEffect(() => {
    if (!fileActive) return
    setFileRelease({
      file_id: fileActive.file_id,
      file_name: fileActive.file_name,
    })
  }, [fileActive])

  useEffect(() => {
    setUploadedFiles(selectedElectronicDocument.attachments)
  }, [selectedElectronicDocument.attachments])

  return (
    <ModalRelease
      visible={isVisiblePopupRelease}
      footer={null}
      closable={false}
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <span>Phát hành tài liệu</span>
          <Button
            type={'text'}
            icon={<CloseOutlined />}
            onClick={handleClosePopupElectronicDocument}
          />
        </div>
      }
      width={900}
      style={{ top: 40 }}>
      <InternalDocumentOutgoingDocCreateForm
        fileReleaseAttachList={uploadedFiles}
        fileRelease={fileRelease}
        isReleaseDocument
        handleCancel={handleClosePopupElectronicDocument}
      />
    </ModalRelease>
  )
}

PopupReleaseElectronicDocument.propTypes = {}

export default memo(
  inject('electronicDocumentStore')(observer(PopupReleaseElectronicDocument))
)

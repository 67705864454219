import { action, observable } from 'mobx'
// Request
import { StatisticRequest } from '../requests/StatisticRequest'

class StatisticStore {
  @observable statistic = null
  @action getStatistic = () => {
    return new Promise((resolve, reject) => {
      StatisticRequest.getStatistic()
        .then(response => {
          this.statistic = response.data
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action clearStatistic = () => {
    this.statistic = null
  }
}

export default new StatisticStore()

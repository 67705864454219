import React, { memo, useCallback, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Collapse, Modal } from 'antd'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  ActionLevel,
  ApproverBox,
  BGWhite,
  FlexBetween,
  FlexCenter,
  LineBox,
  ModalBox,
  PanelTitle,
  SpacingBox,
  StatusBox,
} from './ModalStyle'

const ApprovalList = props => {
  const { Panel } = Collapse
  const { loadingAnimationStore, leavePageStore, history, match } = props
  const { showModalApprover } = leavePageStore
  const [selectedRowKeys, setSelectedRowKeys] = useState(['1'])

  const handleCollapse = useCallback(value => {
    console.log(value)
  }, [])

  return (
    <Modal
      visible={showModalApprover}
      footer={null}
      width={600}
      getContainer={false}
      onCancel={() => {
        leavePageStore.hideModal('showModalApprover')
      }}
      title="Danh sách nhân sự phê duyệt">
      <ModalBox>
        <StatusBox>
          Trạng thái: <strong>Còn 6 người chưa phê duyệt tại cấp 1</strong>
        </StatusBox>

        <Collapse
          activeKey={selectedRowKeys}
          ghost
          expandIconPosition={'right'}
          onChange={handleCollapse()}>
          <Panel
            header={
              <BGWhite onClick={() => setSelectedRowKeys(['1'])}>
                <FlexBetween>
                  <PanelTitle>Level 1</PanelTitle>
                  <LineBox></LineBox>
                  <SpacingBox className="action">
                    <Button ghost type={'primary'}>
                      <PlusOutlined />
                      Thêm người duyệt
                    </Button>
                  </SpacingBox>
                </FlexBetween>
              </BGWhite>
            }
            key="1">
            <ApproverBox>
              <FlexBetween>
                <FlexCenter>
                  <div className="avatar">A</div>
                  <div className="name">Nhân viên 001</div>
                </FlexCenter>
                <FlexCenter>
                  <div className="icon warning">
                    <ExclamationCircleOutlined />
                  </div>
                  <div className="status">Chờ duyệt</div>
                </FlexCenter>
              </FlexBetween>
            </ApproverBox>

            <ApproverBox>
              <FlexBetween>
                <FlexCenter>
                  <div className="avatar">B</div>
                  <div className="name">Nhân viên 002</div>
                </FlexCenter>
                <FlexCenter>
                  <div className="icon success">
                    <CheckCircleOutlined />
                  </div>
                  <div className="status">Đã duyệt</div>
                </FlexCenter>
              </FlexBetween>
            </ApproverBox>

            <ApproverBox>
              <FlexBetween>
                <FlexCenter>
                  <div className="avatar">C</div>
                  <div className="name">Nhân viên 003</div>
                </FlexCenter>
                <FlexCenter>
                  <div className="icon error">
                    <CloseCircleOutlined />
                  </div>
                  <div className="status">Hủy Phiếu</div>
                </FlexCenter>
              </FlexBetween>
            </ApproverBox>
          </Panel>

          <Panel
            header={
              <BGWhite onClick={() => setSelectedRowKeys(['2'])}>
                <FlexBetween>
                  <PanelTitle>Level 2</PanelTitle>
                  <LineBox></LineBox>
                  <SpacingBox className="action">
                    <Button ghost type={'primary'}>
                      <PlusOutlined />
                      Thêm người duyệt
                    </Button>
                  </SpacingBox>
                </FlexBetween>
              </BGWhite>
            }
            key="2">
            <p>text</p>
          </Panel>

          <Panel
            header={
              <BGWhite onClick={() => setSelectedRowKeys(['3'])}>
                <FlexBetween>
                  <PanelTitle>Level 3</PanelTitle>
                  <LineBox></LineBox>
                  <SpacingBox className="action">
                    <Button ghost type={'primary'}>
                      <PlusOutlined />
                      Thêm người duyệt
                    </Button>
                  </SpacingBox>
                </FlexBetween>
              </BGWhite>
            }
            key="3">
            <p>text</p>
          </Panel>
        </Collapse>

        <ActionLevel>
          <FlexBetween>
            <SpacingBox>
              <Button ghost type={'primary'}>
                <PlusOutlined />
                Thêm cấp duyệt
              </Button>
            </SpacingBox>
            <LineBox></LineBox>
          </FlexBetween>
        </ActionLevel>

        <br />
        <FlexCenter>
          <Button
            type={'default'}
            onClick={() => {
              leavePageStore.hideModal('showModalApprover')
            }}>
            Hủy bỏ
          </Button>
          <Button type={'primary'}>Lưu</Button>
        </FlexCenter>
      </ModalBox>
    </Modal>
  )
}

ApprovalList.propTypes = {}

export default memo(
  inject(
    'authenticationStore',
    'loadingAnimationStore',
    'leavePageStore',
  )(observer(ApprovalList)),
)

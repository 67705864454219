import React, { useEffect, useState } from 'react'
import { Input } from 'antd'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const ProposalSalaryInputFilter = props => {
  const { proposalSalaryStore } = props
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)
  const [isChangeInput, setIsChangeInput] = useState(false)
  const [inputValue, setInputValue] = useState(queryStringParse.keyword)
  const [isInputting, setIsInputting] = useState(false)

  useEffect(() => {
    if (!isChangeInput) return
    setIsInputting(true)
    const delayFilter = setTimeout(() => {
      proposalSalaryStore.setQueryParams('filterPage', 0)
      proposalSalaryStore.setQueryParams('filterKeyword', inputValue)
      setIsInputting(false)
    }, 500)
    return () => {
      clearTimeout(delayFilter)
    }
  }, [inputValue])

  const onchangeInput = e => {
    setInputValue(e.target.value)
    setIsChangeInput(true)
  }

  return (
    <Input
      value={inputValue}
      style={{ maxWidth: 400 }}
      allowClear
      onChange={onchangeInput}
      suffix={isInputting ? <LoadingOutlined /> : <SearchOutlined />}
      placeholder={'Tìm kiếm trình duyệt lương...'}
    />
  )
}

ProposalSalaryInputFilter.propTypes = {}

export default inject('proposalSalaryStore')(
  observer(ProposalSalaryInputFilter)
)

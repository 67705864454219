import React, { useState } from 'react'
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Popconfirm,
  Space,
  Upload,
} from 'antd'
import { SalaryFilterWrapper } from './SalaryPageStyled'
import {
  CloudUploadOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import SalaryImportFileModal from './SalaryImportFileModal'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { ACL_ACTION_TYPE, SALARY_STATUS } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction'

const SalaryFilter = props => {
  const { salaryStore, fileStore } = props

  const { isShowSalaryInfo, salaryInfo } = salaryStore

  const { id: salaryCode } = useParams()

  const history = useHistory()

  const [
    isVisibleSalaryImportFileModal,
    setIsVisibleSalaryImportFileModal,
  ] = useState(false)

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState([])
  const [attachmentList, setAttachmentList] = useState([])
  const handleChangeFile = info => {
    setFileList([info.file])
    setIsVisibleSalaryImportFileModal(true)
  }

  const handleChangeAttachments = info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setAttachmentList(filteredFileList)
  }

  const [monthCreateSalary, setMonthCreateSalary] = useState(moment())
  const [isCreatingSalary, setIsCreatingSalary] = useState(false)

  const handleCreateSalary = async () => {
    try {
      setIsCreatingSalary(true)
      const batchUploadList = []
      attachmentList.forEach(file => {
        const formData = new FormData()
        formData.append('file', file.originFileObj)
        batchUploadList.push(fileStore.uploadFile(formData))
      })
      const response = await Promise.all(batchUploadList)
      const payload = {
        salary_month: monthCreateSalary,
        title: form.getFieldValue('title'),
        from_file_code:
          salaryInfo.from_file_code || salaryInfo.salary_from_file_code,
        attachments: response.map(response => response.data.file_id),
      }
      await salaryStore.createSalary(payload)
      message.success('Tạo trình duyệt lương thành công!')
      history.push('/salary')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsCreatingSalary(false)
    }
  }

  const handleApproveSalary = async () => {
    try {
      setIsCreatingSalary(true)
      await salaryStore.approveSalary(salaryCode)
      await salaryStore.getSalaryDetail(salaryCode)
      message.success('Duyệt bảng lương thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsCreatingSalary(false)
    }
  }

  return (
    <SalaryFilterWrapper>
      {!salaryInfo.status ? (
        <div>
          <div className={'btn-group-left'}>
            <Form form={form} layout={'vertical'}>
              <Form.Item name={'title'} label={'Tiêu đề:'}>
                <Input placeholder={'Tiêu đề trình duyệt lương'} />
              </Form.Item>

              <Form.Item>
                <Space align="end" direction="horizontal">
                  <div>
                    <div className={'label-date-picker'}>Chọn tháng:</div>
                    <DatePicker
                      style={{ width: 173.7 }}
                      allowClear={false}
                      value={monthCreateSalary}
                      placeholder={'Chọn tháng'}
                      onChange={date => setMonthCreateSalary(moment(date))}
                      format={'MM-YYYY'}
                      picker="month"
                    />
                  </div>

                  <Upload
                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ marginLeft: 4, marginRight: 4 }}
                    valuePropName={'fileList'}
                    fileList={fileList}
                    onChange={handleChangeFile}
                    beforeUpload={() => false}
                    showUploadList={false}>
                    <Button icon={<CloudUploadOutlined />} type={'primary'}>
                      Nhận từ file bảng lương
                    </Button>
                  </Upload>
                </Space>
              </Form.Item>
            </Form>
          </div>

          {/*tài liệu đính kèm*/}
          <div>
            <div className={'label-date-picker'}>Tài liệu đính kèm:</div>
            <Upload
              valuePropName={'fileList'}
              fileList={attachmentList}
              multiple={true}
              beforeUpload={() => false}
              onChange={handleChangeAttachments}>
              <Button icon={<UploadOutlined />}>Chọn tài liệu</Button>
            </Upload>
          </div>
        </div>
      ) : (
        <div />
      )}
      <Space>
        {(salaryInfo.from_file_code || salaryInfo.salary_from_file_code) &&
          !salaryCode && (
            <Popconfirm
              placement="top"
              title={'Bạn có muồn trình duyệt bảng lương này?'}
              onConfirm={handleCreateSalary}
              okText="Trình duyệt"
              cancelText="Không">
              <Button
                loading={isCreatingSalary}
                type={'primary'}
                style={{ textTransform: 'uppercase', marginTop: 22 }}
                icon={<SendOutlined />}>
                Trình duyệt
              </Button>
            </Popconfirm>
          )}

        {(salaryInfo.from_file_code || salaryInfo.salary_from_file_code) &&
          (salaryInfo.status === SALARY_STATUS.TRUONG_BAN_APPROVED ||
            salaryInfo.status === SALARY_STATUS.PENDING) && (
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.approve__SALARY}>
              <Popconfirm
                placement="top"
                title={'Bạn có muồn duyệt bảng lương này?'}
                onConfirm={handleApproveSalary}
                okText="Trình duyệt"
                cancelText="Không">
                <Button
                  loading={isCreatingSalary}
                  type={'primary'}
                  style={{ textTransform: 'uppercase' }}
                  icon={<SendOutlined />}>
                  {salaryInfo.status === SALARY_STATUS.TRUONG_BAN_APPROVED
                    ? 'Tổng giám đốc duyệt'
                    : 'Trưởng ban duyệt'}
                </Button>
              </Popconfirm>
            </AccessControlAction>
          )}
      </Space>

      <SalaryImportFileModal
        fileList={fileList}
        handleChangeFile={handleChangeFile}
        isVisibleSalaryImportFileModal={isVisibleSalaryImportFileModal}
        handleCloseSalaryImportFileModal={() =>
          setIsVisibleSalaryImportFileModal(false)
        }
      />
    </SalaryFilterWrapper>
  )
}

SalaryFilter.propTypes = {}

export default inject('salaryStore', 'fileStore')(observer(SalaryFilter))

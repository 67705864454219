import styled from 'styled-components'
import { blue } from '../../../color'
import { Collapse } from 'antd'

export const BoxWrapper = styled.div`
  .collapse-wrapper {
    height: calc(100vh - 180px);
    overflow-y: auto;

    .ant-collapse-content-box {
      padding: 0;
    }
    .user-item {
      cursor: pointer;
      padding: 8px 0px 8px 60px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      gap: 20px;

      &:hover {
        background: #e9f7ff;
        color: ${blue};
      }
      .user-item-info {
        display: flex;
      }
    }
    .user-item.user-item-checked {
      background-color: #e9f7ff;
      color: ${blue};
    }

    .user-item-disabled {
      background-color: #eaeae72b !important;
      opacity: 0.6;
    }
  }

  .department-title {
    padding: 12px 16px 12px 10px;
    margin: -12px -16px -12px 0px;

    .department-name {
      font-weight: 500;
    }

    &:hover {
      .department-name {
        color: ${blue};
      }
    }

    .department-name {
      margin-left: 10px;
    }
  }

  .department-title-disabled {
    background-color: #eaeae72b !important;
    opacity: 0.6;
  }

  .search-box {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 12px;
  }
`

export const PanelItem = styled(Collapse.Panel)`
  .ant-collapse-header {
    background-color: ${props => props.checked && '#e9f7ff'};
  }
`

import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import {
  FilterButton,
  SearchBar,
  TableFooterActionWrapper,
} from './ConnectedCommitteeDocumentOutgoingDocPageStyled'
import { Button, Col, Empty, Input, Pagination, Row, Space, Tag } from 'antd'
import {
  CloseCircleOutlined,
  FilterOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import utils from '../../utils'
import AdvanceFilterBlock from './AdvanceFilterBlock'
import DocumentsLayout from '../../layouts/DocumentsLayout'
import UnreadText from '../../components/UnreadText/UnreadText'
import TableComponent from '../../components/Common/TableComponent'
import { DATE_FORMAT_DEFAULT } from '../../constants'

const { Search } = Input

const ConnectedCommitteeDocumentOutgoingDocPage = props => {
  const {
    history,
    connectedCommitteeDocumentStore,
    loadingAnimationStore,
    connectedDocumentStore,
    authenticationStore,
  } = props

  const {
    outgoingCommitteeDocumentList,
    outgoingCommitteeDocumentListTotalCount,
    outgoingCommitteeDocumentListPageIndex,
    outgoingCommitteeDocumentListPageSize,
    setFilter,
    outgoingCommitteeDocumentListFilterByKeyword,
  } = connectedCommitteeDocumentStore
  const { isEOfficeClerical, currentUser } = authenticationStore

  const renderBussinessDocTypeName = typeId => {
    switch (typeId) {
      case '0':
        return 'Tạo văn bản mới'
      case '1':
        return 'Thu hồi văn bản đã gửi'
      case '2':
        return 'Cập nhật văn bản đã gửi'
      case '3':
        return 'Thay thế văn bản đã gửi'
      default:
        return ''
    }
  }

  const NGAY_GUI_WIDTH = 120
  const SO_VAN_BAN_WIDTH = 150
  const TRICH_YEU_WIDTH = 450
  const TRANG_THAI_WIDTH = 100
  const LOAI_NGHIEP_VU_WIDTH = 250
  const SCROLL_WIDTH = 1000

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)
  const [disabledSearchBtn, setDisabledSearchBtn] = useState(true)
  const [reload, setReload] = useState(false)

  let [searchOptionTitle, setSearchOptionTitle] = useState('')
  let [searchOption, setSearchOption] = useState({
    bussiness_doc_type: undefined,
    status: undefined,
    title: undefined,
  })

  const [exportLoading, setExportLoading] = useState(false)
  // const dropdownMenu = (
  //   <Menu>
  //     <Menu.Item onClick={() => handleChangePageSize(10)}>
  //       Hiển thị 10 trên {outgoingCommitteeDocumentListTotalCount} kết quả
  //     </Menu.Item>
  //     <Menu.Item onClick={() => handleChangePageSize(20)}>
  //       Hiển thị 20 trên {outgoingCommitteeDocumentListTotalCount} kết quả
  //     </Menu.Item>
  //     <Menu.Item onClick={() => handleChangePageSize(50)}>
  //       Hiển thị 50 trên {outgoingCommitteeDocumentListTotalCount} kết quả
  //     </Menu.Item>
  //   </Menu>
  // )
  // const handleAdvanceFilter = () => {
  //   if (showAdvanceFilter) {
  //     connectedCommitteeDocumentStore.clearOutgoingCommitteeDocumentList()
  //     connectedCommitteeDocumentStore.clearOutgoingCommitteeDocumentListFilter()
  //   }
  //   setShowAdvanceFilter(!showAdvanceFilter)
  // }

  const tableColumns = [
    {
      title: 'Ngày gửi',
      width: NGAY_GUI_WIDTH,
      render: record => (
        <UnreadText
          text={moment(record.sent_time).format(DATE_FORMAT_DEFAULT)}
          isUnread={checkUnread(record)}
          width={NGAY_GUI_WIDTH}
        />
      ),
    },
    {
      title: 'Số văn bản',
      width: SO_VAN_BAN_WIDTH,
      render: record => (
        <UnreadText
          text={record.code}
          isUnread={checkUnread(record)}
          width={SO_VAN_BAN_WIDTH}
        />
      ),
    },
    {
      title: 'Trích yếu',
      width: TRICH_YEU_WIDTH,
      render: record => (
        <UnreadText
          text={record.title}
          isUnread={checkUnread(record)}
          width={TRICH_YEU_WIDTH}
        />
      ),
    },
    {
      title: 'Trạng thái',
      width: TRANG_THAI_WIDTH,
      align: 'center',
      render: record => (
        <Tag color={utils.renderDocumentCommitteeTag(record.status).color}>
          {record.status !== undefined &&
            utils.renderDocumentCommitteeTag(record.status).name}
        </Tag>
      ),
    },
    {
      title: 'Loại nghiệp vụ',
      width: LOAI_NGHIEP_VU_WIDTH,
      render: record => (
        <UnreadText
          text={renderBussinessDocTypeName(record.bussiness_doc_type)}
          isUnread={checkUnread(record)}
          width={LOAI_NGHIEP_VU_WIDTH}
        />
      ),
    },
  ]

  const checkUnread = record => false

  const handleCloseAdvanceFilter = () => {
    if (!reload) return
    setReload(false)
    connectedCommitteeDocumentStore.clearOutgoingCommitteeDocumentListFilter()
    loadingAnimationStore.setTableLoading(true)
    connectedCommitteeDocumentStore
      .getOutgoingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
  }

  const exportDocument = useCallback(() => {
    setExportLoading(true)

    if (!showAdvanceFilter) {
      setFilter(
        'outgoingCommitteeDocumentListFilterByKeyword',
        searchOptionTitle ? searchOptionTitle.trim() : undefined
      )
    } else {
      setFilter('outgoingCommitteeDocumentListAllPageIndex', 0)
      setFilter(
        'outgoingCommitteeDocumentListFilterByKeyword',
        searchOption.title ? searchOption.title.trim() : undefined
      )
      setFilter(
        'outgoingCommitteeDocumentListFilterByStatus',
        searchOption.status
      )
      setFilter(
        'outgoingCommitteeDocumentListFilterByBussinessDocType',
        searchOption.bussiness_doc_type
      )
    }

    const col = [
      'Ngày gửi',
      'Số văn bản',
      'Trích yếu',
      'Trạng thái',
      'Loại nghiệp vụ',
    ]

    let rows = []
    rows.push(col)
    connectedCommitteeDocumentStore
      .getOutgoingCommitteeDocumentListAll()
      .then(res => {
        if (res && res.data && res.data.data) {
          res.data.data.map(item => {
            rows.push([
              moment(item.sent_time).format(DATE_FORMAT_DEFAULT),
              item.code,
              item.title,
              utils.renderDocumentCommitteeTag(item.status).name,
              renderBussinessDocTypeName(item.bussiness_doc_type),
            ])
          })
        }
        utils.exportExcel(
          rows,
          'VBLT - VB đi - ' + moment().format('YYYY-MM-DD hmms')
        )
      })
      .finally(() => setExportLoading(false))
  }, [searchOptionTitle, searchOption, showAdvanceFilter])

  const handleSearch = values => {
    const { title, status, bussiness_doc_type } = values

    /** Kiểm tra việc reload lại trang để lấy lại list vb */
    if (title || bussiness_doc_type || status) {
      setReload(true)
    } else {
      setReload(false)
    }

    setFilter('outgoingCommitteeDocumentListPageIndex', 0)
    setFilter(
      'outgoingCommitteeDocumentListFilterByKeyword',
      title ? title.trim() : undefined
    )
    setFilter('outgoingCommitteeDocumentListFilterByStatus', status)
    setFilter(
      'outgoingCommitteeDocumentListFilterByBussinessDocType',
      bussiness_doc_type
    )

    loadingAnimationStore.setTableLoading(true)
    connectedCommitteeDocumentStore
      .getOutgoingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
    setDisabledSearchBtn(true)
  }
  const onQuickSearch = title => {
    setFilter('outgoingCommitteeDocumentListPageIndex', 0)
    setFilter(
      'outgoingCommitteeDocumentListFilterByKeyword',
      title ? title.trim() : undefined
    )

    loadingAnimationStore.setTableLoading(true)
    connectedCommitteeDocumentStore
      .getOutgoingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
    setDisabledSearchBtn(true)
  }
  const onChangePagination = pageIndex => {
    setFilter('outgoingCommitteeDocumentListPageIndex', pageIndex - 1)
    loadingAnimationStore.setTableLoading(true)
    connectedCommitteeDocumentStore
      .getOutgoingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
  }

  useEffect(() => {
    return () => {
      connectedCommitteeDocumentStore.clearOutgoingCommitteeDocumentList()
      connectedCommitteeDocumentStore.clearOutgoingCommitteeDocumentListFilter()
    }
  }, [])
  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore
      .getOutgoingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [])

  return (
    <DocumentsLayout
      title={
        <PageTitle location={props.location} title="Văn bản đi" hiddenGoBack>
          <Button
            onClick={() => {
              connectedDocumentStore.clearDataReceiveDocument()
              history.push(
                '/connected-committee-document/outgoing-document/create'
              )
            }}
            type={'primary'}>
            Tạo mới văn bản đi
          </Button>
        </PageTitle>
      }
      selectedKey="outgoing-document"
      linkToIncomingDocument="/connected-committee-document/incoming-document"
      linkToOutgoingDocument="/connected-committee-document/outgoing-document"
      linkToConnectedCommitteeOrg="/connected-committee-document/organization">
      <Helmet>
        <title>Văn bản đi | VIMC Liên thông lên UBQLV</title>
      </Helmet>

      <div>
        <Row>
          <Col span={12}>
            {!showAdvanceFilter ? (
              <SearchBar>
                <Search
                  allowClear
                  onSearch={onQuickSearch}
                  defaultValue={outgoingCommitteeDocumentListFilterByKeyword}
                  placeholder={'Tìm kiếm văn bản theo trích yếu'}
                  onChange={e => {
                    setSearchOptionTitle(e.target.value)
                  }}
                  value={searchOptionTitle}
                />
              </SearchBar>
            ) : null}
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <FilterButton
              type={showAdvanceFilter ? 'primary' : 'default'}
              ghost={showAdvanceFilter}
              onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}
              style={{ marginBottom: showAdvanceFilter ? 5 : 0 }}>
              {showAdvanceFilter ? (
                <Space onClick={handleCloseAdvanceFilter}>
                  <CloseCircleOutlined />
                  Tắt tìm kiếm nâng cao
                </Space>
              ) : (
                <Space>
                  <FilterOutlined />
                  Tìm kiếm nâng cao
                </Space>
              )}
            </FilterButton>
            <Button loading={exportLoading} onClick={exportDocument}>
              <PrinterOutlined />
              In
            </Button>
          </Col>
        </Row>
        {showAdvanceFilter ? (
          <>
            <AdvanceFilterBlock
              onChangeSearchForm={value => {
                setSearchOption(Object.assign(searchOption, value))
                setDisabledSearchBtn(false)
              }}
              handleSearch={handleSearch}
              titleSearch={outgoingCommitteeDocumentListFilterByKeyword}
              disabledSearchBtn={disabledSearchBtn}
            />
          </>
        ) : null}
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(
                  `/connected-committee-document/outgoing-document/view/${record.doc_id}`
                ),
            }
          }}
          rowKey={record => record.doc_id}
          dataSource={outgoingCommitteeDocumentList}
          columns={tableColumns}
          pagination={false}
          scroll={{ x: SCROLL_WIDTH }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
          loading={loadingAnimationStore.tableLoading}
        />
        <TableFooterActionWrapper>
          <Pagination
            onChange={e => onChangePagination(e)}
            pageSize={outgoingCommitteeDocumentListPageSize}
            showSizeChanger={false}
            showLessItems
            current={outgoingCommitteeDocumentListPageIndex + 1}
            total={outgoingCommitteeDocumentListTotalCount}
            hideOnSinglePage={true}
          />
        </TableFooterActionWrapper>
      </div>
    </DocumentsLayout>
  )
}

ConnectedCommitteeDocumentOutgoingDocPage.propTypes = {}

export default withRouter(
  inject(
    'connectedCommitteeDocumentStore',
    'loadingAnimationStore',
    'authorityStore',
    'authenticationStore',
    'connectedDocumentStore',
  )(observer(ConnectedCommitteeDocumentOutgoingDocPage)),
)

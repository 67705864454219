import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const VoteRequest = {
  createVote: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/vote`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  updatePersonVote: (userCode, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/vote/${userCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  updateVote: (userCode, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/vote-user/${userCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  getVoteDetail: workCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/vote/${workCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}

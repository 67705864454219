import React, { memo } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'

const DocumentsLayout = props => {
  const { children, title, selectedKey } = props

  return (
    <DashboardLayout>
      {title}
      <ContentBlockWrapper>
        <Menu
          mode="horizontal"
          selectedKeys={[selectedKey]}
          style={{ marginBottom: 16 }}>
          <Menu.Item key="user-account-management">
            <Link to={'/admin/user-account-management'}>Tài khoản</Link>
          </Menu.Item>
          <Menu.Item key="user-app-management">
            <Link to={'/admin/user-app-management'}>Phần mềm</Link>
          </Menu.Item>
        </Menu>
        {children}
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

DocumentsLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  selectedKey: PropTypes.string,
}

export default memo(inject()(observer(DocumentsLayout)))

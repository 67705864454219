import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import AddUserGroupModal from './AddUserGroupModal'
import ListUserGroup from './ListUserGroup'
// Layout
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
// Ant design
import { PlusOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
// other
import { inject, observer } from 'mobx-react'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import SetDefaultGroupButton from './ConfigDefaultGroupModal/SetDefaultGroupButton'

const UserGroupPage = props => {
  const location = useLocation()
  const queryStringParsed = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  const { userGroupStore } = props

  const [isNhomNhiemVu, setIsNhomNhiemVu] = useState(false)
  useEffect(() => {
    if(props.isNhomNhiemVu){
      setIsNhomNhiemVu(true)
    }
  }, [])

  useEffect(() => {
    if(props.isNhomNhiemVu) return 

    setIsNhomNhiemVu(queryStringParsed.group_type === 'nhiem_vu')
  }, [queryStringParsed])

  const [isAddUserGroupModalVisible, setIsAddUserGroupModalVisible] = useState(
    false
  )

  const handleCloseAddUserGroupModal = () => {
    setIsAddUserGroupModalVisible(false)
  }

  const showAddUserGroupModal = () => {
    setIsAddUserGroupModalVisible(true)
  }

  useEffect(() => {
    return () => {
      userGroupStore.clearGroupList()
    }
  }, [])

  return !props.isTab ? (
    <DashboardLayout>
      <Helmet>
        <title>Nhóm người dùng</title>
      </Helmet>

      <PageTitle location={props.location} hiddenGoBack>
        <Space direction={'horizontal'}>
          <SetDefaultGroupButton isNhomNhiemVu={isNhomNhiemVu} />
          <AccessControlAction
            aclActionType={
              isNhomNhiemVu
                ? ACL_ACTION_TYPE.create__NHOM_NHIEM_VU
                : ACL_ACTION_TYPE.create__NHOM_NGUOI_DUNG
            }>
            <Button
              icon={<PlusOutlined />}
              onClick={showAddUserGroupModal}
              type="primary">
              Tạo nhóm mới
            </Button>
          </AccessControlAction>
        </Space>
      </PageTitle>
      <ContentBlockWrapper>
        <ListUserGroup isNhomNhiemVu={isNhomNhiemVu} />
        <AddUserGroupModal
          isAddUserGroupModalVisible={isAddUserGroupModalVisible}
          handleCloseAddUserGroupModal={handleCloseAddUserGroupModal}
        />
      </ContentBlockWrapper>
    </DashboardLayout>
  ) : (
    <ListUserGroup isNhomNhiemVu={isNhomNhiemVu} />
  )
}

export default inject(
  'authenticationStore',
  'userGroupStore'
)(observer(UserGroupPage))

import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const InternalDocumentRequest = {
  /** Incoming **/

  getInternalDocumentIncomingList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/incoming`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),

  getForExportInternalDocumentIncomingList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/incoming/export`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),

  createInternalDocumentIncoming: documentInfo =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/internal-documents/incoming`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: documentInfo,
    }),

  updateInternalDocument: (id, documentInfo) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/internal-documents/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: documentInfo,
    }),

  getInternalDocumentIncomingById: internal_document_code =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/${internal_document_code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  deleteIncomingInternalDocumentById: documentCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/internal-documents/${documentCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getWarningDocumentNumber: (document_number, type) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/incoming/warning-document-number`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        document_number: document_number,
        type: type,
      },
    }),

  suggestIncomingNumber: book_id =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/incoming/suggest-incoming-number`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { book_id },
    }),

  createInternalDocumentComment: (internal_document_code, data) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/internal-documents/${internal_document_code}/comments`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  getInternalDocumentComment: (internal_document_code, sort) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/${internal_document_code}/comments`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      // params: {
      //   sort_by_date_post: sort,
      // },
    }),
  getSelectedInternalDocumentAttachment: (internal_document_code, file_id) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents-incoming/${internal_document_code}/attachments/${file_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }),
  deleteInternalDocumentComment: (internal_document_code, comment_id) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/internal-documents/${internal_document_code}/comments/${comment_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  createInternalDocumentIncomingAssignUser: (documentId, submitValues) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/internal-documents-incoming/${documentId}/user`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: { ...submitValues },
    }),
  updateInternalDocumentIncomingAssignUser: (documentId, submitValues) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/internal-documents-incoming/${documentId}/user/update`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: { ...submitValues },
    }),
  deleteInternalDocumentIncomingAssignUser: (documentId, submitValues) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/internal-documents-incoming/${documentId}/user/delete`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: { ...submitValues },
    }),
  updateInternalDocumentIncomingStatus: internal_document_id =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/internal-documents-incoming/${internal_document_id}/status`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  /** Outgoing **/

  getInternalDocumentOutgoingList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/outgoing`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),

  getForExportInternalDocumentOutgoingList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/outgoing/export`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),

  createInternalDocumentOutgoing: documentInfo =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/internal-documents/outgoing`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: documentInfo,
    }),
  getInternalDocumentOutgoingById: internal_document_code =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/${internal_document_code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getSelectedInternalDocumentOutgoingAttachment: (
    internal_document_code,
    file_id
  ) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents-outgoing/${internal_document_code}/attachments/${file_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }),

  deleteOutgoingInternalDocumentById: documentCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/internal-documents/${documentCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  updateOutgoingInternalDocumentFollower: (documentCode, followerList) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/internal-documents-outgoing/${documentCode}/user`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: followerList,
    }),

  changeOutgoingInternalDocumentFollower: (documentCode, followerList) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/internal-documents-outgoing/${documentCode}/user/update`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: followerList,
    }),

  changeIncomingInternalDocumentFollower: (documentCode, followerList) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/internal-documents-incoming/${documentCode}/user/update`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: followerList,
    }),

  eSignDocument: formData =>
    axios({
      url: `${apiUrl}/api/v1/esign/sign`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }),

  updateAssignees: (documentCode, data) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/internal-documents/${documentCode}/user/update`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  /**document system*/

  getInternalDocumentIncomingSystemList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/incoming/system`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),

  getInternalDocumentOutgoingSystemList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/internal-documents/outgoing/system`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),
  markAsRead: documentCodes =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/internal-documents/markAsRead`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        document_codes: documentCodes,
      },
    }),
}

import React, { memo, useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Tooltip,
  Upload,
} from 'antd'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import {
  CloudUploadOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from '@ant-design/icons'
import {
  DraggerUploadStyled,
  FormActionFooter,
  UploadFileListItem,
  UploadFileListWrapper,
  ViewDetailContent,
} from './DocumentVBPCUpdateStyled'
import validator from '../../../validator'
import { Container } from '../../../layouts/Container/Container'
import { DATE_FORMAT_LIST } from '../../../constants'
import moment from 'moment'

const DocumentVBPCFormPage = props => {
  const { Option } = Select
  const { Dragger } = Upload
  const {
    loadingAnimationStore,
    documentStore,
    match,
    fileStore,
    history,
  } = props

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState([])

  const [fileListUpdate, setFileListUpdate] = useState([])

  const { documentId } = match.params

  const handleAddFileToUpload = () => false
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const handleChangeFile = useCallback(
    info => {
      form.setFieldsValue({
        file_id: info.fileList.slice(
          info.fileList.length - 1,
          info.fileList.length
        ),
      })
      setFileList(
        info.fileList.slice(info.fileList.length - 1, info.fileList.length)
      )
    },
    [form]
  )

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    documentStore.getVBPCDetail(documentId).then(
      res => {
        loadingAnimationStore.showSpinner(false)
        documentStore.getViewAttachmentVBPC({
          recordId: res.data.id,
          fileId: res.data.file_id,
        })
        form.setFieldsValue({
          file_id: res.data.file_id,
          document_name: res.data.document_name,
          document_number: res.data.document_number,
          contruction_board: res.data.contruction_board,
          issue_date: moment(res.data.issue_date),
          status: res.data.status,
          file_name: res.data.file_name,
          issue_level: res.data.issue_level,
        })

        setFileList([
          {
            name: res.data.file_name,
            id: res.data.file_id,
            uid: 1,
            type: 'application/pdf',
          },
        ])
      },
      error => {
        message.error(error.vi)
        loadingAnimationStore.showSpinner(false)
      }
    )
    return () => documentStore.clearDocumentDetail()
  }, [documentId, documentStore, loadingAnimationStore])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitForm = async values => {
    loadingAnimationStore.showSpinner(true)
    if (
      values.file_id &&
      values.file_id.length > 0 &&
      values.file_id[0] &&
      values.file_id[0].originFileObj
    ) {
      const formData = new FormData()
      formData.append('file', values.file_id[0].originFileObj)
      fileStore
        .uploadFile(formData)
        .then(response => {
          values.file_id = response.data.file_id
          handleUpdateVBPC(documentId, values)
          loadingAnimationStore.showSpinner(false)
        })
        .catch(error => {
          message.error(error.vi)
          loadingAnimationStore.showSpinner(false)
        })
    } else {
      // not update new file
      values.file_id = fileList[0].id
      handleUpdateVBPC(documentId, values)
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleUpdateVBPC = useCallback(
    (documentId, submitData) => {
      documentStore
        .updateVBPC(documentId, submitData)
        .then(() => {
          loadingAnimationStore.showSpinner(false)
          message.success(
            `Cập nhật văn bản pháp chế ${submitData.document_name} thành công`
          )
          history.push(`/utility/vbpc/view/${documentId}`)
        })
        .catch(error => {
          loadingAnimationStore.showSpinner(false)
          message.error(error.vi)
        })
    },
    [documentStore, loadingAnimationStore]
  )

  const handleCancel = () => {
    form.resetFields()
    history.push(`/utility/vbpc/view/${documentId}`)
  }

  const renderListFileUpdate =
    fileListUpdate &&
    fileListUpdate.map((file, index) => (
      <UploadFileListItem key={file.id}>
        <FileTextOutlined style={{ color: '#1890FF' }} />
        <span style={{ color: '#1890FF' }}>{file.name}</span>

        <Tooltip title={'Xoá tập tin'}>
          <DeleteOutlined onClick={() => setFileListUpdate([])} />
        </Tooltip>
      </UploadFileListItem>
    ))

  const renderFormContent = () => (
    <ContentBlockWrapper>
      <Container maxWidth={820}>
        <Form
          scrollToFirstError={true}
          {...layout}
          form={form}
          name={'add-vbpc-form'}
          onFinish={handleSubmitForm}>
          <Form.Item
            name={'document_name'}
            label={'Tên văn bản'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên tài liệu!' },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên tài liệu'} />
          </Form.Item>

          <Form.Item
            name={'document_number'}
            label={'Số quyết định ban hành'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số quyết định ban hành!',
              },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập số quyết định ban hành'} />
          </Form.Item>

          <Form.Item
            name={'issue_date'}
            label={'Ngày ban hành'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số quyết định ban hành!',
              },
            ]}>
            <DatePicker style={{ width: '100%' }} format={DATE_FORMAT_LIST} />
          </Form.Item>

          <Form.Item name={'issue_level'} label={'Cấp ban hành'}>
            <Input placeholder={'Nhập cấp ban hành'} />
          </Form.Item>

          <Form.Item name={'contruction_board'} label={'Ban chủ trì xây dựng'}>
            <Input placeholder={'Nhập ban chủ trì xây dựng'} />
          </Form.Item>

          <Form.Item name={'status'} label={'Trạng thái'}>
            <Select
              showSearch
              placeholder={'Trạng thái'}
              filterOption={true}
              optionFilterProp={'name'}>
              {['Đã bãi bỏ', 'Hết hiệu lực', 'Còn hiệu lực'].map(
                (status, index) => {
                  return (
                    <Option key={index} name={status} value={index + 1 + ''}>
                      {status}
                    </Option>
                  )
                }
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name={'file_id'}
            label={'Tài liệu đính kèm'}
            rules={[{ required: true, message: 'Vui lòng thêm tài liệu!' }]}>
            <Dragger
              valuePropName={'fileList'}
              fileList={fileList}
              onChange={handleChangeFile}
              beforeUpload={handleAddFileToUpload}>
              <DraggerUploadStyled>
                <CloudUploadOutlined />
                <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
              </DraggerUploadStyled>
            </Dragger>
          </Form.Item>
          {
            <UploadFileListWrapper>
              {renderListFileUpdate}
            </UploadFileListWrapper>
          }

          <FormActionFooter>
            <Button onClick={handleCancel}>Huỷ bỏ</Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              htmlType={'submit'}>
              Cập nhật văn bản
            </Button>
          </FormActionFooter>
        </Form>
      </Container>
    </ContentBlockWrapper>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết văn bản pháp chế | Tài liệu</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'Chi tiết văn bản pháp chế'}
        routerGoback={'/utility/vbpc'}
      />
      <ViewDetailContent>{renderFormContent()}</ViewDetailContent>
    </DashboardLayout>
  )
}

DocumentVBPCFormPage.propTypes = {}

export default memo(
  inject(
    'loadingAnimationStore',
    'commonStore',
    'documentStore',
    'fileStore',
  )(observer(DocumentVBPCFormPage)),
)

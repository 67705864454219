import React from 'react'
import {
  layout,
  makeResponsive,
  measureItems,
  SpringGrid,
} from 'react-stonecutter'
import { ItemBg, ItemSubject } from './GridTinTucStyled'
import { Col, Divider, Dropdown, Menu, Modal, Row } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { ControlNotificationFix } from '../NotificationPage/NotificationPageStyled'
import { iconMoreVert } from '../../ui_constants'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import { blue } from '../../color'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import utils from '../../utils'
import { EmptyText, WordBreak } from '../../components/Common/CellText'
import { UploadFileListWrapper } from '../../components/CommentComponent/CommentComponentStyled'
import { inject, observer } from 'mobx-react'
import { ACL_ACTION_TYPE } from '../../constants'

const MyFirstGrid = props => {
  let { parentWidth, tintucStore, aclStore } = props
  const gutterWidth = 16
  const gutterHeight = 24
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore
  const { newsList } = tintucStore

  const Grid = makeResponsive(measureItems(SpringGrid), {
    maxWidth: 1920,
  })

  parentWidth = parentWidth - gutterWidth

  const handleClickEdit = id => {
    props.onClickEdit(id)
  }

  const handleClickView = id => {
    props.onClickView(id)
  }

  const handleClickDelete = id => {
    showDeleteConfirm(id)
  }

  const { confirm } = Modal

  function showDeleteConfirm(id) {
    confirm({
      title: 'Bạn có chắc muốn xóa tin này ?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      okType: 'danger',
      cancelText: 'Không',
      onOk() {
        props.onClickDelete(id)
      },
      onCancel() {},
    })
  }

  const menuControlNotification = id => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => handleClickView(id)}
          style={{ color: blue }}
          icon={<EyeOutlined />}>
          Xem chi tiết
        </Menu.Item>
        {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.edit__NEWS) && (
          <Menu.Item
            onClick={() => handleClickEdit(id)}
            style={{ color: blue }}
            icon={<EditOutlined />}>
            Sửa thông tin
          </Menu.Item>
        )}
        {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.delete__NEWS) && (
          <Menu.Item
            onClick={() => handleClickDelete(id)}
            danger
            icon={<DeleteOutlined />}>
            Xóa
          </Menu.Item>
        )}
      </Menu>
    )
  }

  const items = newsList.map(item => (
    <div
      key={item.id}
      onClick={() => {
        props.onItemClick(item)
      }}
      style={{ width: parentWidth / 2 }}>
      <ItemBg>
        <Row wrap={false}>
          <Col flex="auto">
            <ItemSubject>{item.subject}</ItemSubject>
          </Col>
          <Col flex="none">
            <ControlNotificationFix className={'control-notification'}>
              <Dropdown
                overlay={() => menuControlNotification(item.id)}
                placement="bottomLeft"
                arrow
                trigger={['click']}>
                <MoreOutlined style={iconMoreVert} />
              </Dropdown>
            </ControlNotificationFix>
          </Col>
        </Row>
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        {WordBreak(item.content, true)}
        <div style={{ display: 'flex' }}>
          <span style={{ margin: '10px 10px 15px 0px', fontWeight: 'bold' }}>
            Tài liệu đính kèm:
          </span>
          <UploadFileListWrapper>
            {item.attachments ? (
              item.attachments.map(file => (
                <FileItem
                  key={file.file_id}
                  file_id={file.file_id}
                  file_name={file.file_name}
                  file_type={utils.getExtensionFile(file.file_name)}
                />
              ))
            ) : (
              <EmptyText>Không có tài liệu đính kèm.</EmptyText>
            )}
          </UploadFileListWrapper>
        </div>
      </ItemBg>
    </div>
  ))

  return (
    <div>
      <Grid
        component="ul"
        columns={2}
        columnWidth={parentWidth / 2}
        gutterWidth={gutterWidth}
        gutterHeight={gutterHeight}
        layout={layout.pinterest}>
        {items}
      </Grid>
    </div>
  )
}

export default inject('tintucStore', 'aclStore')(observer(MyFirstGrid))

import { action, observable } from 'mobx'
// Request
import { ProposalAdvanceRequest } from '../requests/ProposalAdvanceRequest'

class ProposalAdvanceStore {
  /** Position list */
  @observable proposalAdvanceList = []
  @observable proposalAdvanceTotal = 0

  // filter
  @observable filterPage = 0
  @observable filterSize = 10
  @observable filterProposalPermission = null
  @observable filterKeyword = null
  @observable filterProposalStatus = null
  @action setQueryParams = (param, value) => {
    this[param] = value
  }
  @action clearQueryParams = () => {
    this.filterPage = 0
    this.filterSize = 10
    this.filterProposalPermission = null
    this.filterKeyword = null
    this.filterProposalStatus = null
  }
  // end filter

  @action getProposalAdvanceList = () => {
    return new Promise((resolve, reject) => {
      ProposalAdvanceRequest.getProposalAdvanceList({
        page: this.filterPage,
        size: this.filterSize,
        proposal_permission: this.filterProposalPermission,
        keyword: this.filterKeyword,
        proposal_status: this.filterProposalStatus,
      })
        .then(response => {
          this.proposalAdvanceList = response.data.data
          this.proposalAdvanceTotal = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action clearProposalAdvanceList = () => {
    this.proposalAdvanceList.length = 0
    this.proposalAdvanceTotal = 0
  }

  @observable defaultApprovalConfig = {
    approvalCode: null,
    approvalLevels: [],
  }
  @observable defaultApprovalConfigUpdate = {
    approvalCode: null,
    approvalLevels: [],
  }

  @action setDefaultApprovalConfigUpdate = payload => {
    this.defaultApprovalConfigUpdate = {
      ...this.defaultApprovalConfigUpdate,
      approvalLevels: payload,
    }
  }

  @action getDefaultApprovalConfig = () => {
    return new Promise((resolve, reject) => {
      ProposalAdvanceRequest.getDefaultApprovalConfig()
        .then(response => {
          this.defaultApprovalConfig = {
            approvalCode: response.data.approval_code,
            approvalLevels: response.data.approval_levels || [],
          }
          this.defaultApprovalConfigUpdate = {
            approvalCode: response.data.approval_code,
            approvalLevels: response.data.approval_levels || [],
          }

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action createProposalAdvance = payload => {
    return new Promise((resolve, reject) => {
      ProposalAdvanceRequest.createProposalAdvance(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // Proposal detail
  @observable proposalAdvanceDetail = {}
  @action getProposalAdvanceDetail = proposalCode => {
    return new Promise((resolve, reject) => {
      ProposalAdvanceRequest.getProposalAdvanceDetail(proposalCode)
        .then(response => {
          this.proposalAdvanceDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action clearProposalAdvanceDetail = () => {
    this.proposalAdvanceDetail = {}
  }

  //approve Proposal Advance
  @action approveProposalAdvance = (proposalCode, payload) => {
    return new Promise((resolve, reject) => {
      ProposalAdvanceRequest.approveProposalAdvance(proposalCode, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // Proposal Advance Comment
  @observable proposalAdvanceCommentList = []

  @action getProposalAdvanceComment = proposalCode => {
    return new Promise((resolve, reject) => {
      ProposalAdvanceRequest.getProposalAdvanceComment(proposalCode)
        .then(response => {
          this.proposalAdvanceCommentList = response.data.reverse()
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action clearProposalAdvanceCommentList = () => {
    this.proposalAdvanceCommentList.length = 0
  }

  @action deleteProposalAdvanceComment = commentId => {
    return new Promise((resolve, reject) => {
      ProposalAdvanceRequest.deleteProposalAdvanceComment(commentId)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action createProposalAdvanceComment = (proposalCode, commentData) => {
    return new Promise((resolve, reject) => {
      ProposalAdvanceRequest.createProposalAdvanceComment(
        proposalCode,
        commentData
      )
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
}

export default new ProposalAdvanceStore()

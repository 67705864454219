import React from 'react'
import { Modal } from 'antd'
import MissionEditForm from '../MissionEditPage/MissionEditForm'
import { inject, observer } from 'mobx-react'
import { MissionCreateModal } from '../MissionCreatePage/MissionCreatePageStyled'

const MissionFullUpdateModal = props => {
  const {
    isVisibleMissionFullUpdateModal,
    handleCloseUpdateMissionModal,
    handleAfterUpdate,
    taskCode,
  } = props
  const onClearForm = React.useRef(null)

  return (
    <MissionCreateModal
      title={'Chỉnh sửa nhiệm vụ'}
      visible={isVisibleMissionFullUpdateModal}
      maskClosable={false}
      onCancel={handleCloseUpdateMissionModal}
      footer={null}
      closable={false}>
      <MissionEditForm
        handleAfterUpdate={handleAfterUpdate}
        handleCancelUpdate={() => handleCloseUpdateMissionModal('REFRESH')}
        taskCode={taskCode}
      />
    </MissionCreateModal>
  )
}

MissionFullUpdateModal.propTypes = {}

export default inject('selectPeopleStore')(observer(MissionFullUpdateModal))

import { PlusOutlined } from '@ant-design/icons'
import { Button, Menu, message, Space } from 'antd'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { memo } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import AccessControlAction from '../../components/AccessControlAction'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { ACL_ACTION_TYPE, MODULE_CODE } from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout'
import AddUserGroupModal from '../UserGroupPage/AddUserGroupModal'
import SetDefaultGroupButton from '../UserGroupPage/ConfigDefaultGroupModal/SetDefaultGroupButton'

const MissionManagementLayout = props => {
  const {
    children,
    title,
    selectedKey,
    moduleStore,
    authenticationStore,
  } = props
  const [isAccessMissionGroupTab, setIsAccessMissionGroupTab] = useState(false)
  const { isAccountAdmin, isSuperAdmin } = authenticationStore
  const [isAddUserGroupModalVisible, setIsAddUserGroupModalVisible] = useState(
    false
  )
  const history = useHistory()

  useEffect(() => {
    if (isAccountAdmin || isSuperAdmin || !authenticationStore.currentUser) {
      return
    }

    history.replace('/')
    message.error('Bạn không có quyền truy cập trang này!')
  }, [authenticationStore.currentUser])

  useEffect(() => {
    if (moduleStore.checkAccessModule(MODULE_CODE.nhom_nhiem_vu))
      setIsAccessMissionGroupTab(true)
  }, [moduleStore.moduleList])

  const handleCloseAddUserGroupModal = () => {
    setIsAddUserGroupModalVisible(false)
  }

  const showAddUserGroupModal = () => {
    setIsAddUserGroupModalVisible(true)
  }
  return (
    <DashboardLayout>
      {title}
      <PageTitle location={props.location} title={'Quản trị nhiệm vụ'}>
        {selectedKey === 'mission-group' && (
          <Space direction={'horizontal'}>
            <SetDefaultGroupButton isNhomNhiemVu={true} />
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.create__NHOM_NHIEM_VU}>
              <Button
                icon={<PlusOutlined />}
                onClick={showAddUserGroupModal}
                type="primary">
                Tạo nhóm mới
              </Button>
            </AccessControlAction>
          </Space>
        )}
      </PageTitle>
      <ContentBlockWrapper>
        <Menu
          mode="horizontal"
          selectedKeys={[selectedKey]}
          style={{ marginBottom: 16 }}>
          {isAccessMissionGroupTab && (
            <Menu.Item key="mission-group">
              <Link to={'/mission-management/mission-group'}>
                Nhóm nhiệm vụ
              </Link>
            </Menu.Item>
          )}

          <Menu.Item key="mission-accessibility">
            <Link to={'/mission-management/mission-accessibility'}>
              Quyền thao tác
            </Link>
          </Menu.Item>
        </Menu>
        {children}
      </ContentBlockWrapper>

      <AddUserGroupModal
        isAddUserGroupModalVisible={isAddUserGroupModalVisible}
        handleCloseAddUserGroupModal={handleCloseAddUserGroupModal}
      />
    </DashboardLayout>
  )
}

export default memo(
  withRouter(
    inject(
      'authenticationStore',
      'aclStore',
      'moduleStore'
    )(observer(MissionManagementLayout))
  )
)

import { useState } from 'react'

export default function useOpenItem(initialValue = false) {
  const [isOpen, setIsOpen] = useState(initialValue)
  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  return {
    isOpen,
    handleOpen,
    handleClose,
  }
}

import React from 'react'
import { InfoList } from './MyProfilePageStyled'
import { Col, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { EmptyText } from '../../components/Common/CellText'

const Job = props => {
  const { authenticationStore } = props

  const { currentUser } = authenticationStore
  const nullText = <EmptyText>Chưa cập nhật</EmptyText>

  return (
    <Row gutter={[20, 10]}>
      <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
        <InfoList dtWidth={'205px'}>
          <dt>Phòng ban</dt>
          <dd>{currentUser?.department?.name || nullText}</dd>
          <dt>Chức danh</dt>
          <dd>{currentUser?.position?.name || nullText}</dd>
          <dt>Nghề nghiệp khi được tuyển dụng</dt>
          <dd>{nullText}</dd>
          <dt>Ngày tuyển dụng</dt>
          <dd>{nullText}</dd>
          <dt>Ngạch công chức</dt>
          <dd>{nullText}</dd>
          <dt>Mã ngạch</dt>
          <dd>{nullText}</dd>
        </InfoList>
      </Col>
      <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
        <InfoList dtWidth={'205px'}>
          <dt>Công việc chính được giao</dt>
          <dd>{nullText}</dd>
          <dt>Bậc lương</dt>
          <dd>{nullText}</dd>
          <dt>Hệ số</dt>
          <dd>{nullText}</dd>
          <dt>Ngày hưởng</dt>
          <dd>{nullText}</dd>
          <dt>Phụ cấp chức vụ</dt>
          <dd>{nullText}</dd>
          <dt>Phụ cấp khác</dt>
          <dd>{nullText}</dd>
        </InfoList>
      </Col>
    </Row>
  )
}

Job.propTypes = {}

export default inject('authenticationStore')(observer(Job))

import React, { useEffect, useState } from 'react'
// Component
import SpinnerInlineComponent from '../../../../components/SpinnerInlineComponent'
// Styled Component
import { ModalCreateSignDocWrapper } from './PopupCreateSignDocStyled'
import {
  FileAction,
  FileItem,
  SignDocumentList,
} from '../../InternalDocumentSignDocDetailPage/InternalDocumentSignDocDetailPageStyled'
// Ant design
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import {
  BankOutlined,
  CloseOutlined,
  DeleteOutlined,
  FileTextOutlined,
  PlusOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
// Other
import { blue, redPrimary, yellowPrimary } from '../../../../color'
import { inject, observer } from 'mobx-react'
import { ASSIGNEE_TYPE } from '../../../../constants'
import { SelectList } from '../../../MissionCreatePage/MissionCreatePageStyled'
import { AvatarHasName } from '../../../../components/Common/Avatar'
import SelectPeoplePopup from '../../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../../components/SelectGroupPopup/SelectGroupPopup'

const PopupCreateSignDoc = props => {
  const {
    loadingAnimationStore,
    electronicDocumentStore,
    fileStore,
    isVisiblePopupCreateSignDoc,
    handleClosePopupCreateSignDoc,
    selectPeopleStore,
  } = props

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    originDepartmentWithUserList,
  } = selectPeopleStore

  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [electronicDocument, setElectronicDocument] = useState([])

  const [leaderList, setLeaderList] = useState([])
  const [combinerList, setCombinerList] = useState([])

  useEffect(() => {
    setLeaderList([
      ...selectUserData[ASSIGNEE_TYPE.LEADER],
      ...selectDepartmentData[ASSIGNEE_TYPE.LEADER],
      ...selectGroupData[ASSIGNEE_TYPE.LEADER],
    ])
    setCombinerList([
      ...selectUserData[ASSIGNEE_TYPE.COMBINER],
      ...selectDepartmentData[ASSIGNEE_TYPE.COMBINER],
      ...selectGroupData[ASSIGNEE_TYPE.COMBINER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ file_upload: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList.filter(
      (elem, index, fileList) =>
        fileList.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const onFinish = async values => {
    const uploadFileList = fileList
      ? fileList
          .filter(
            (elem, index, fileList) =>
              fileList.findIndex(file => file.name === elem.name) === index
          )
          .map(file => file.originFileObj)
          .filter(file => file)
      : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    const combinerListData = combinerList.map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    const leaderData = leaderList.map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    if (leaderList.length === 0)
      return message.info('Bạn chưa chọn lãnh đạo ký duyệt!')
    try {
      loadingAnimationStore.showSpinner(true)
      const responseAttachments = await Promise.all(batchUploadArr)
      const formData = new FormData()
      formData.append('file', electronicDocument[0].originFileObj)
      const responseSignDoc = await fileStore.uploadFile(formData)
      const submitData = {
        title: values.title,
        attachments: responseAttachments.map(response => response.data.file_id),
        file_id: responseSignDoc.data.file_id,
        assignees: [...leaderData, ...combinerListData],
      }
      await electronicDocumentStore.createElectronicDocument(submitData)
      form.resetFields()
      setElectronicDocument([])
      setFileList([])
      selectPeopleStore.clearSelected()
      electronicDocumentStore.clearFilter()
      await electronicDocumentStore.getElectronicDocumentList()
      handleClosePopupCreateSignDoc()
      message.success('Tạo trình ký thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleChangeElectronicDoc = info => {
    if (info.fileList.length === 0) return setElectronicDocument([])
    setElectronicDocument([info.fileList[info.fileList.length - 1]])
  }

  return (
    <ModalCreateSignDocWrapper
      visible={isVisiblePopupCreateSignDoc}
      footer={null}
      closable={false}
      title={'Tạo trình ký văn bản'}
      width={800}
      style={{ top: 60 }}>
      <SpinnerInlineComponent />
      <Form onFinish={onFinish} layout={'vertical'} name={'form'} form={form}>
        <Form.Item
          label={'Tiêu đề:'}
          name={'title'}
          rules={[
            { required: true, message: ' Vui lòng nhập tiêu đề trình ký!' },
            { max: 200, message: 'Tiêu đề không được dài quá 200 kí tự' },
          ]}>
          <Input placeholder={'Nhập tiêu đề...'} />
        </Form.Item>
        <Form.Item
          label={'Tài liệu trình ký:'}
          name={'electronic_document'}
          rules={[
            { required: true, message: ' Vui lòng chọn tài liệu trình ký!' },
          ]}>
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={false}
            beforeUpload={() => false}
            onChange={handleChangeElectronicDoc}
            showUploadList={false}
            accept={'.doc,.docx,application/pdf'}>
            <Button icon={<UploadOutlined />}>Chọn tài liệu trình ký</Button>
          </Upload>
        </Form.Item>
        <SignDocumentList>
          {electronicDocument.map((file, index) => {
            return (
              <FileItem key={file.uid}>
                <span>
                  <FileTextOutlined style={{ marginRight: 5 }} />
                  {file.name}
                </span>
                <FileAction>
                  <Tooltip title={'Xóa'}>
                    <Button
                      type={'text'}
                      icon={<DeleteOutlined style={{ color: redPrimary }} />}
                      onClick={() => setElectronicDocument([])}
                    />
                  </Tooltip>
                </FileAction>
              </FileItem>
            )
          })}
        </SignDocumentList>
        <Form.Item label={'Tài liệu đính kèm:'} name={'file'}>
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={true}
            beforeUpload={() => false}
            onChange={handleChangeFile}
            showUploadList={false}>
            <Button icon={<UploadOutlined />}>Chọn tài liệu đính kèm</Button>
          </Upload>
        </Form.Item>
        <SignDocumentList>
          {fileList.map((file, index) => {
            return (
              <FileItem key={file.uid}>
                <span>
                  <FileTextOutlined style={{ marginRight: 5 }} />
                  {file.name}
                </span>
                <FileAction>
                  <Tooltip title={'Xóa'}>
                    <Button
                      type={'text'}
                      icon={<DeleteOutlined style={{ color: redPrimary }} />}
                      onClick={() =>
                        setFileList(fileList.filter(el => el.uid !== file.uid))
                      }
                    />
                  </Tooltip>
                </FileAction>
              </FileItem>
            )
          })}
        </SignDocumentList>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <label
              style={{ display: 'inline-block', margin: '15px 12px 25px 0' }}>
              <span style={{ color: redPrimary, marginRight: 4 }}>*</span>Lãnh
              đạo ký duyệt:
            </label>
            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.LEADER)
                  }
                />
              </Tooltip>
            </Space>
            <SelectList>
              {leaderList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          </Col>

          <Col span={12}>
            <label
              style={{ display: 'inline-block', margin: '15px 12px 25px 0' }}>
              Người phối hợp:
            </label>

            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.COMBINER)
                  }
                />
              </Tooltip>
              <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                <Button
                  icon={<TeamOutlined />}
                  style={{ color: yellowPrimary }}
                  onClick={() =>
                    handleOpenSelectGroupPopup(ASSIGNEE_TYPE.COMBINER)
                  }
                />
              </Tooltip>
            </Space>

            <SelectList>
              {combinerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 20,
          }}>
          <Space>
            <Button
              danger
              icon={<CloseOutlined />}
              onClick={() => {
                form.resetFields()
                setElectronicDocument([])
                setFileList([])
                selectPeopleStore.clearSelected()
                handleClosePopupCreateSignDoc()
              }}>
              Hủy tạo
            </Button>
            <Button
              htmlType={'submit'}
              icon={<PlusOutlined />}
              type={'primary'}>
              Tạo trình ký
            </Button>
          </Space>
        </div>
      </Form>

      <SelectPeoplePopup
        onlyOneUser={
          selectPeopleStore.assigneePopupType === ASSIGNEE_TYPE.LEADER
        }
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </ModalCreateSignDocWrapper>
  )
}

PopupCreateSignDoc.propTypes = {}

export default inject(
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore',
  'electronicDocumentStore',
  'selectPeopleStore',
)(observer(PopupCreateSignDoc))

import React, { useCallback, useEffect, useState } from 'react'
import { Button, Tag, Tooltip } from 'antd'
import { blue } from '../../../color'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { SelectList } from '../../MissionCreatePage/MissionCreatePageStyled'
import { AvatarHasName } from '../../../components/Common/Avatar'
import { ASSIGNEE_TYPE } from '../../../constants'
import { inject, observer } from 'mobx-react'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'

const SelectFollower = props => {
  const { selectPeopleStore } = props

  const { selectUserData, selectDepartmentData } = selectPeopleStore
  const [isVisibleSelectUserPopup, setIsVisibleSelectUserPopup] = useState(
    false
  )

  const [followerList, setFollowerList] = useState([])

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData])
  const handleOpenSelectFollower = () => {
    selectPeopleStore.setAssigneePopupType(ASSIGNEE_TYPE.FOLLOWER)
    setIsVisibleSelectUserPopup(true)
  }
  const handleSubmitSelectUser = useCallback(() => {
    setIsVisibleSelectUserPopup(false)
  }, [])

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 8,
          alignItems: 'center',
          marginTop: 15,
          marginBottom: 10,
        }}>
        <div>Người theo dõi:</div>
        <Tooltip title="Chọn người theo dõi" color={blue}>
          <Button
            icon={<UserOutlined style={{ color: blue }} />}
            onClick={handleOpenSelectFollower}
          />
        </Tooltip>
      </div>
      <SelectList>
        {followerList.map(el => (
          <Tag
            className={'tag-selected'}
            color={'blue'}
            key={el.id}
            closable
            onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
            <AvatarHasName
              imgId={el.image_id}
              size={22}
              name={el.full_name}
              icon={
                (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                  <BankOutlined />
                )) ||
                (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
              }
            />
          </Tag>
        ))}
      </SelectList>

      <SelectPeoplePopup
        noLoadSelectData
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleSubmitSelectUser}
        handleSubmitSelectUser={handleSubmitSelectUser}
        isVisibleSelectPeoplePopup={isVisibleSelectUserPopup}
      />
    </>
  )
}

SelectFollower.propTypes = {}

export default inject(
  'proposalAdvanceStore',
  'selectPeopleStore'
)(observer(SelectFollower))

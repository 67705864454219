import { action, observable } from 'mobx'
import { KpiRequest } from '../requests/KpiRequest'
import { KpiQuarterRequest } from '../requests/KpiQuarterRequest'

class KpiStore {
  @observable quarterCode = null

  //  KPI List
  @observable kpiList = []
  @observable actualWeightQuarter = null
  @observable originKpiList = []
  @observable kpiTotal = null

  @action getKpiList = () => {
    return new Promise((resolve, reject) => {
      KpiQuarterRequest.getDetail(this.quarterCode)
        .then(response => {
          this.kpiList = response.data.children
          this.originKpiList = response.data.children
          this.actualWeightQuarter = response.data.total
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action setKpiList = data => {
    this.kpiList = data
  }

  @action setActualWeightQuarter = num => {
    this.actualWeightQuarter = num
  }

  @action clearKpiList = () => {
    this.kpiList.length = 0
  }

  // detail KPI
  @observable kpiDetail = {}
  @observable actualWeightKpi = null
  @observable targetListByKpiId = []
  @observable originTargetListByKpiId = []

  @action getKpiDetail = kpiId => {
    return new Promise((resolve, reject) => {
      KpiRequest.getKpiDetail(kpiId)
        .then(response => {
          this.kpiDetail = response.data
          this.actualWeightKpi = response.data.actual_weight
          this.targetListByKpiId = response.data.children
          this.originTargetListByKpiId = response.data.children
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action setTargetListByKpiId = newTargetList => {
    this.targetListByKpiId = newTargetList
  }

  @action setActualWeightKpi = num => {
    this.actualWeightKpi = num
  }

  @action clearKpiDetail = () => {
    this.kpiDetail = {}
    this.actualWeightKpi = null
    this.targetListByKpiId.length = 0
    this.originTargetListByKpiId.length = 0
  }

  // Detail target by KPI id
  @observable targetKpiDetail = {}
  @observable subTargetListByTargetId = []
  @observable originSubTargetListByTargetId = []
  @observable actualWeightTarget = null

  mapWorkListState = [] //key: sub_target_id, value: workListState

  getWorkListStateBy = subTargetId => {
    return this.mapWorkListState[subTargetId] ?? []
  }

  setWorkListStateBy = (subTargetId, workListState) => {
    this.mapWorkListState[subTargetId] = workListState
  }

  @action getTargetKpiDetail = targetId => {
    return new Promise((resolve, reject) => {
      KpiRequest.getKpiDetail(targetId)
        .then(response => {
          // console.log(response)
          this.targetKpiDetail = response.data
          this.actualWeightTarget = response.data.actual_weight
          this.subTargetListByTargetId = response.data.children
          this.originSubTargetListByTargetId = response.data.children
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action setSubTargetListByTargetId = newList => {
    this.subTargetListByTargetId = newList
  }

  @action setActualWeightTarget = num => {
    this.actualWeightTarget = num
  }

  createKpi = async (name, type, parentCode, workCode) => {
    return KpiRequest.create({
      name: name,
      type: type,
      parent_code: parentCode,
      work_code: workCode,
      expect_weight: 0,
      kpi_quarter_code: this.quarterCode,
    })
  }

  updateKpiValues = async () => {
    return KpiRequest.updateKpiValues(this.kpiList, this.quarterCode)
  }

  updateTargetValues = async () => {
    return KpiRequest.updateKpiValues(this.targetListByKpiId, this.quarterCode)
  }

  updateSubTargetValues = async () => {
    // console.log('subTargetListByTargetId', toJS(this.subTargetListByTargetId))
    let workListPayload = this.subTargetListByTargetId.flatMap(
      subTarget => this.mapWorkListState[subTarget.code]
    )
    console.log('mapWorkListState', workListPayload)

    return KpiRequest.updateKpiValues(
      [...this.subTargetListByTargetId, ...workListPayload],
      this.quarterCode
    )
  }

  delete = async kpiCode => {
    return KpiRequest.delete(kpiCode)
  }

  @action clearTargetKpiDetail = () => {
    this.targetKpiDetail = {}
    this.subTargetListByTargetId.length = 0
    this.originSubTargetListByTargetId.length = 0
    this.actualWeightTarget = null
    this.mapWorkListState = []
  }

  // validate button save
  @observable allowSaveKpi = true
  @observable allowSaveTarget = true
  @observable allowSaveSubTarget = true

  @action setAllowSaveKpi = bool => {
    this.allowSaveKpi = bool
  }

  @action setAllowSaveTarget = bool => {
    this.allowSaveTarget = bool
  }

  @action setAllowSaveSubTarget = bool => {
    this.allowSaveSubTarget = bool
  }
}

export default new KpiStore()

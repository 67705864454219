import axios from 'axios'
import { apiUrl, apiUrlTruc } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const OrganizationRequest = {
  getOrganization: (page, size, keyword) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/edocs/organizations`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
        ...(!utils.isNullish(keyword) && { keyword: keyword }),
      },
    }),

  updateOrganization: (organ_id, organizationsInfo) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/edocs/organizations/${organ_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: organizationsInfo,
    }),

  createOrganization: organizationsInfo =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/edocs/organizations`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: organizationsInfo,
    }),

  deleteOrganization: organ_id =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/edocs/organizations/${organ_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getOrganizationLTVB: () =>
    axios({
      method: 'get',
      url: `${apiUrlTruc}/api/v1/docs/organizations`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.trucToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}

import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import {
  BlockLeft,
  BlockRight,
  PageContentWrapper,
} from './TaskManagementPageStyled'
import TaskManagementList from '../../components/TaskManagementList'
import TaskManagementDetail from '../../components/TaskManagementDetail'
import { inject, observer } from 'mobx-react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import DashboardLayout from '../../layouts/DashboardLayout'

const TaskManagementPage = props => {
  const {
    taskManagementStore,
    loadingAnimationStore,
    taskManagementCommentStore,
    history,
    match,
  } = props

  const { selectedTask, taskList } = taskManagementStore
  const { taskId } = match.params

  useEffect(() => {
    taskManagementStore.clearSelectedTask()
    taskManagementStore.clearAllTaskListFilter()
  }, [])

  useEffect(() => {
    if (!taskId) return
    ;(async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        let response = await taskManagementStore.getTaskDetail(taskId)
        await taskManagementCommentStore.getTaskComment(
          taskId,
          response.data.step
        )
      } catch (error) {
        console.log(error)
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
  }, [taskId, taskManagementStore.sameCodeDetail])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Công việc | VIMC Công việc</title>
      </Helmet>
      <PageTitle location={props.location}>
        <Button
          onClick={() => history.push('/my-tasks/create')}
          type={'primary'}
          icon={<PlusOutlined />}>
          Tạo công việc mới
        </Button>
      </PageTitle>
      <PageContentWrapper hasContent={selectedTask.code}>
        <BlockLeft>
          <TaskManagementList />
        </BlockLeft>
        <BlockRight hasContent={selectedTask.code}>
          {selectedTask.code && taskList.length !== 0 ? (
            <TaskManagementDetail taskId={taskId} />
          ) : (
            <span style={{ fontSize: 20 }}>Chọn công việc để xem chi tiết</span>
          )}
        </BlockRight>
      </PageContentWrapper>
    </DashboardLayout>
  )
}

TaskManagementPage.propTypes = {}

export default inject(
  'taskManagementStore',
  'loadingAnimationStore',
  'taskManagementCommentStore'
)(observer(TaskManagementPage))

import React, { useCallback, useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'
import ProposalSalaryList from './ProposalSalaryList'
import ProposalSalaryFilter from './ProposalSalaryFilter'

const ProposalSalaryRequestPage = props => {
  const { proposalSalaryStore } = props
  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search)
  const [
    isVisibleProposalAdvanceCreateModal,
    setIsVisibleProposalAdvanceCreateModal,
  ] = useState(false)

  const [isLoadingProposalSalary, setIsLoadingProposalSalary] = useState(
    false
  )
  const [accessRenderModal, setAccessRenderModal] = useState(false)
  const [isRefreshPage, setIsRefreshPage] = useState(true)

  const loadProposalSalaryList = async () => {
    const queryParams = {
      page: proposalSalaryStore.filterPage,
      size: proposalSalaryStore.filterSize,
      proposal_permission: proposalSalaryStore.filterProposalPermission,
      keyword: proposalSalaryStore.filterKeyword,
      proposal_status: proposalSalaryStore.filterProposalStatus,
    }
    try {
      setIsLoadingProposalSalary(true)
      await proposalSalaryStore.getProposalSalaryList()
      const queryStringStringify = queryString.stringify(queryParams, {
        skipNull: true,
      })
      history.replace(`/proposal-salary-request?${queryStringStringify}`)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingProposalSalary(false)
    }
  }

  useEffect(() => {
    proposalSalaryStore.setQueryParams(
      'filterPage',
      queryStringParse.page ? parseInt(queryStringParse.page) : 0
    )
    proposalSalaryStore.setQueryParams(
      'filterSize',
      queryStringParse.size ? parseInt(queryStringParse.size) : 10
    )
    proposalSalaryStore.setQueryParams(
      'filterProposalPermission',
      queryStringParse.proposal_permission
    )
    proposalSalaryStore.setQueryParams(
      'filterKeyword',
      queryStringParse.keyword
    )
    proposalSalaryStore.setQueryParams(
      'filterProposalStatus',
      queryStringParse.proposal_status
    )
    ;(async () => {
      await loadProposalSalaryList()
      setAccessRenderModal(true)
      setIsRefreshPage(false)
    })()
    return () => {
      proposalSalaryStore.clearProposalSalaryList()
      proposalSalaryStore.clearQueryParams()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    ;(async () => {
      await loadProposalSalaryList()
    })()
  }, [
    proposalSalaryStore.filterPage,
    proposalSalaryStore.filterSize,
    proposalSalaryStore.filterProposalPermission,
    proposalSalaryStore.filterKeyword,
    proposalSalaryStore.filterProposalStatus,
  ])

  const handleOpenProposalAdvanceCreateModal = () => {
    setIsVisibleProposalAdvanceCreateModal(true)
  }
  const handleCancelProposalAdvanceCreateModal = useCallback(() => {
    setIsVisibleProposalAdvanceCreateModal(false)
  }, [])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Duyệt lương | VIMC Duyệt lương</title>
      </Helmet>
      <PageTitle location={props.location}>
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__SALARY}>
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={() => history.push('/proposal-salary-request/create')}>
            Tạo trình duyệt lương
          </Button>
        </AccessControlAction>
      </PageTitle>
      <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
        <ProposalSalaryFilter />
        <ProposalSalaryList isLoading={isLoadingProposalSalary} />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

ProposalSalaryRequestPage.propTypes = {}

export default inject('proposalSalaryStore')(observer(ProposalSalaryRequestPage))

import React, { memo, useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Dropdown, Menu, message, Modal } from 'antd'
import TinTucDetail from './TinTucDetail'
import { HeadingButtonWrapper } from '../../layouts/InternalDocumentDetailLayout/InternalDocumentDetailLayoutStyled'
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { blue } from '../../color'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import { ACL_ACTION_TYPE } from '../../constants'

const { confirm } = Modal

const TinTucDetailPage = props => {
  const { history, loadingAnimationStore, tintucStore, match, aclStore } = props
  const { id } = match.params

  const [isLoading, setIsLoading] = useState(false)
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore

  useEffect(() => {
    if (!id) return
    ;(async () => {
      try {
        setIsLoading(true)
        await tintucStore.getById(id)
      } catch (error) {
        history.push('/utility/general-notifications')
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoading(false)
      }
    })()
    return () => {
      tintucStore.clearNewDetails()
    }
  }, [id])

  const handleClickEdit = () => {
    history.push(`/utility/general-notifications/update/${id}?isDetail=true`)
  }

  const onClickDelete = async () => {
    loadingAnimationStore.showSpinner(true)
    await tintucStore
      .delete(id)
      .then(() => {
        history.push('/utility/general-notifications')
        message.success('Đã xóa tin tức!')
      })
      .catch(err => {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
    return () => {}
  }

  function showDeleteConfirm() {
    confirm({
      title: 'Bạn có chắc muốn xóa tin này ?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      okType: 'danger',
      cancelText: 'Không',
      onOk: async () => {
        await onClickDelete()
      },
      onCancel() {},
    })
  }

  const handleClickDelete = () => {
    showDeleteConfirm(id)
  }
  const menuControlNotification = (
    <Menu>
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.edit__NEWS) && (
        <Menu.Item
          onClick={handleClickEdit}
          style={{ color: blue }}
          icon={<EditOutlined />}>
          Sửa thông tin
        </Menu.Item>
      )}
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.delete__NEWS) && (
        <Menu.Item onClick={handleClickDelete} danger icon={<DeleteOutlined />}>
          Xóa
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết tin tức</title>
      </Helmet>
      <PageTitle
        routerGoBack={'/utility/general-notifications'}
        location={props.location}
        title={'Chi tiết thông báo'}
        showTitle={true}>
        <HeadingButtonWrapper>
          <Dropdown overlay={menuControlNotification} trigger={['click']}>
            <span className={'btn-style'}>
              <MoreOutlined style={{ color: blue }} />
            </span>
          </Dropdown>
        </HeadingButtonWrapper>
      </PageTitle>
      {isLoading ? (
        <ContentBlockWrapper style={{ height: 400, position: 'relative' }}>
          <SpinnerInlineComponent
            sizeSpin={'small'}
            isLoading={isLoading}
            noBackground
            tip={'Đang tải thông báo....'}
          />
        </ContentBlockWrapper>
      ) : (
        <TinTucDetail />
      )}
    </DashboardLayout>
  )
}

TinTucDetailPage.propTypes = {}

export default memo(
  withRouter(
    inject(
      'history',
      'tintucStore',
      'loadingAnimationStore',
      'aclStore'
    )(observer(TinTucDetailPage))
  )
)

import React, { memo } from 'react'

import Editor from 'ckeditor5-custom-build/build/ckeditor'
import CKEditor from '@ckeditor/ckeditor5-react'
import { EditorWrapper } from './RichEditorStyled'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import { apiUrl } from '../../config'
import { toJS } from 'mobx'

const toolbar = [
  'Autoformat',
  'Bold',
  'Italic',
  'Paragraph',
  'AutoLink',
  'Link',
  'Heading',
  'PasteFromOffice',
  'WordCount',
]

const CommentEditor = props => {
  const { userStore, height } = props

  const { mentionUserList } = userStore

  // useEffect(() => {
  //   console.log(`mentionUserList.length=${mentionUserList.length}`)
  // }, [mentionUserList])

  const getFeedItems = queryText => {
    const isItemMatching = item => {
      const searchString = queryText.toLowerCase()

      return (
        item.display.toLowerCase().includes(searchString) ||
        item.username.toLowerCase().includes(searchString)
      )
    }

    return new Promise(resolve => {
      console.log('😊', mentionUserList.length)
      setTimeout(() => {
        const itemsToDisplay = mentionUserList
          .map(item => {
            item.id = `@${item.display} (${item.username})`
            return item
          })
          .filter(isItemMatching)
          .slice(0, 10)

        console.log(toJS(itemsToDisplay))

        resolve(itemsToDisplay)
      }, 100)
    })
  }

  const customItemRenderer = item => {
    const itemElement = document.createElement('span')
    itemElement.classList.add('custom-item')
    itemElement.id = `mention-list-item-id-${item.display}`
    itemElement.style.display = 'block'
    // itemElement.textContent = `${item.display}`

    const usernameElement = document.createElement('span')
    usernameElement.classList.add('custom-item-username')
    usernameElement.textContent = `${item.display}(${item.username})`
    usernameElement.style.marginLeft = '8px'
    usernameElement.style.fontFamily = 'Noto Sans Display'

    //avatar
    const wrapAvatar = document.createElement('span')
    wrapAvatar.classList.add('ant-avatar')
    wrapAvatar.style.margin = '8px 0px'
    wrapAvatar.style.width = '24px'
    wrapAvatar.style.height = '24px'
    wrapAvatar.style.background = 'aliceblue'

    const avatar = document.createElement('img')
    if (item.image) {
      avatar.src = `${apiUrl}/api/v1/images/${item.image}`
      avatar.classList.add('custom-item-avatar')
      avatar.style.width = '24px'
    }

    wrapAvatar.appendChild(avatar)

    itemElement.appendChild(wrapAvatar)
    itemElement.appendChild(usernameElement)

    return itemElement
  }

  return mentionUserList.length > 0 ? (
    <EditorWrapper>
      <CKEditor
        ref={props.EDITOR_REF}
        editor={Editor}
        onChange={() => {
          props.onHandleChange()
        }}
        config={{
          toolbar: toolbar,
          placeholder: props.placeholder ?? '',
          link: {
            addTargetToExternalLinks: true,
          },
          mention: {
            feeds: [
              {
                marker: '@',
                feed: getFeedItems,
                minimumCharacters: 1,
                itemRenderer: customItemRenderer,
              },
            ],
          },
        }}
        onInit={editor => {
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            )

          editor.editing.view.change(writer => {
            writer.setStyle(
              'height',
              `${height}px`,
              editor.editing.view.document.getRoot()
            )
          })

          //convert up-down cast
          editor.conversion.for('upcast').elementToAttribute({
            view: {
              name: 'a',
              key: 'data-mention',
              classes: 'mention',
              attributes: {
                href: true,
                'data-user-id': true,
              },
            },
            model: {
              key: 'mention',
              value: viewItem => {
                const mentionAttribute = editor.plugins
                  .get('Mention')
                  .toMentionAttribute(viewItem, {
                    // Add any other properties that you need.
                    link: viewItem.getAttribute('href'),
                    userId: viewItem.getAttribute('data-user-id'),
                  })

                return mentionAttribute
              },
            },
            converterPriority: 'high',
          })

          // Downcast the model 'mention' text attribute to a view <a> element.
          editor.conversion.for('downcast').attributeToElement({
            model: 'mention',
            view: (modelAttributeValue, { writer }) => {
              if (!modelAttributeValue) {
                return
              }

              let aTag = writer.createAttributeElement(
                'a',
                {
                  class: 'mention',
                  'data-mention': modelAttributeValue.id,
                  'data-user-id': modelAttributeValue.userId,
                  href: '#',
                },
                {
                  priority: 20,
                  id: modelAttributeValue.uid,
                }
              )

              return aTag
            },
            converterPriority: 'high',
          })
        }}
        data={props.editorContent}
      />
    </EditorWrapper>
  ) : (
    <div></div>
  )
}

export default memo(withRouter(inject('userStore')(observer(CommentEditor))))

import React, { useState } from 'react'
import { EmptyText } from '../../../components/Common/CellText'
import { useHistory } from 'react-router-dom'
import { StarOutlined } from '@ant-design/icons'
import { blue } from '../../../color'
import { inject, observer } from 'mobx-react'
import SubTargetCreateModal from '../../KpiEditSubTargetPage/SubTargetCreateModal'

const SubTargetList = props => {
  const { subTargetList, authenticationStore } = props
  let history = useHistory()

  const [
    isVisibleSubTargetCreateModal,
    setIsVisibleSubTargetCreateModal,
  ] = useState(false)

  return (
    <div style={{ margin: -16 }}>
      {subTargetList.length > 0 ? (
        subTargetList.map(subTarget => (
          <div key={subTarget.code} className={'sub-target-item'}>
            <div className={'sub-target-item-row'}>
              <StarOutlined
                className={'icon-star'}
                style={{ fontSize: 14, color: blue }}
              />
              <div style={{ flexGrow: 1 }}>{subTarget.name}</div>
              <div style={{ display: 'flex', gap: 10 }}>
                <div
                  style={{
                    width: 120,
                    textAlign: 'center',
                    fontWeight: 500,
                    color: '#f5222d',
                  }}>
                  {subTarget.expect_weight}%
                </div>
                <div
                  style={{
                    width: 120,
                    textAlign: 'center',
                    fontWeight: 500,
                    color: '#52c41a',
                  }}>
                  {subTarget.actual_weight}%
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            padding: '12px 16px 12px 40px',
            borderBottom: '1px solid #f0f0f0',
            backgroundColor: '#fff',
          }}>
          <EmptyText>Chưa có chỉ tiêu nào!</EmptyText>
        </div>
      )}

      <SubTargetCreateModal
        isVisibleSubTargetCreateModal={isVisibleSubTargetCreateModal}
        handleCloseSubTargetCreateModal={() =>
          setIsVisibleSubTargetCreateModal(false)
        }
      />
    </div>
  )
}

SubTargetList.propTypes = {}

export default inject(
  'kpiStore',
  'authenticationStore'
)(observer(SubTargetList))

import { List, Spin } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Transition } from 'react-transition-group'
import MissionPageDetail from './MissionPageDetail'
import MissionPageListItem from './MissionPageListItem'
import { ListWrapper } from './MissionPageStyled'

const duration = 500

const defaultStyle = {
  transition: `all ${duration}ms cubic-bezier(.4,0,.2,1)`,
  opacity: 0,
  padding: '15px 0',
  margin: 0,
  maxHeight: 0,
}

const transitionStyles = {
  entering: { padding: 0, maxHeight: 0, opacity: 0 },
  entered: { padding: '15px 0', maxHeight: 5000, opacity: 1 },
  exiting: { padding: 0, maxHeight: 0, opacity: 0 },
  exited: { padding: 0, maxHeight: 0, opacity: 0 },
}

const MissionPageList = props => {
  const {
    taskStore,
    textLoading,
    loadingList,
    activeTab,
    handleNextMonth,
  } = props

  const { taskList } = taskStore

  const [items, setItems] = useState([])
  const [pageIndex, setPageIndex] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const [
    currentViewingDetailTaskCodes,
    setCurrentViewingDetailTaskCodes,
  ] = useState([])

  const [currentLoadingTaskCode, setCurrentLoadingTaskCode] = useState()

  const onToggleTaskDetail = taskId => {
    const found = currentViewingDetailTaskCodes.find(code => code === taskId)
    if (!found) {
      setCurrentViewingDetailTaskCodes(prev => [...prev, taskId])
    } else {
      setCurrentViewingDetailTaskCodes(prev => prev.filter(id => id !== found))
    }
  }

  const isOpenDetail = taskId => {
    return currentViewingDetailTaskCodes.includes(taskId)
  }

  const handleDetailLoading = taskId => {
    setCurrentLoadingTaskCode(taskId)
  }

  const isLoadingDetail = taskId => {
    return taskId === currentLoadingTaskCode
  }

  const fetchMoreData = () => {
    if (items.length >= toJS(taskList).length) {
      setHasMore(false)
      return
    }
    setPageIndex(pageIndex + 1)
    const newItems = [...toJS(taskList).slice(items.length, items.length + 10)]
    setItems([...items].concat(newItems))
  }

  useEffect(() => {
    setHasMore(true)
    setItems([...toJS(taskList).slice(0, 30)])
  }, [taskList])

  return (
    <ListWrapper>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={hasMore}
        scrollableTarget="infinityScrollableDiv"
        loader={
          <div className={'center-spin'}>
            {items.length > 30 ? <Spin tip="Đang tải..." /> : ''}
          </div>
        }>
        <List
          loading={{
            tip: textLoading,
            spinning: loadingList,
            style: { height: 300 },
          }}
          itemLayout="horizontal"
          dataSource={items}
          renderItem={item => (
            <div key={item.id + item.ten_don_vi}>
              <Spin spinning={isLoadingDetail(item.id)}>
                {(activeTab === 'ALL' || !item.isUser) && (
                  <MissionPageListItem
                    handleNextMonth={handleNextMonth}
                    item={item}
                    activeTab={activeTab}
                    onToggleTaskDetail={onToggleTaskDetail}
                  />
                )}
              </Spin>
              <Transition
                in={isOpenDetail(item.id)}
                mountOnEnter={true}
                unmountOnExit={true}
                timeout={duration}>
                {state => (
                  <MissionPageDetail
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                    taskId={item.id}
                    taskCode={item.task_code}
                    onLoading={handleDetailLoading}
                  />
                )}
              </Transition>
            </div>
          )}
        />
      </InfiniteScroll>
    </ListWrapper>
  )
}

MissionPageList.propTypes = {}

export default inject('taskStore')(observer(MissionPageList))

import React, { memo, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import DocumentsLayout from '../../../layouts/DocumentsLayout'
import AdvanceFilterBlock from './AdvanceFilterBlock'
import { TableBottomPaginationBlock } from '../../../components/Common/Table'
import PageTitle from '../../../components/PageTitle'
import { StatusTag } from '../../../components/Common/StatusTag'
import { trimOverlengthString } from '../../../components/Common/CellText'
// Styled Component
import { SearchBar } from '../InternalDocumentOutgoingDocPage/InternalDocumentOutgoingDocPageStyled'
import { FilterButtonWrapper } from './InternalDocumentSignDocPageStyled'
// Ant Design
import {
  Button,
  Col,
  Empty,
  Input,
  message,
  Pagination,
  Row,
  Space,
} from 'antd'
import {
  CloseCircleOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons'
// Other
import { useLocation, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import PopupCreateSignDoc from './PopupCreateSignDoc'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  DATE_FORMAT_DEFAULT,
  TYPE_STATUS,
} from '../../../constants'
import moment from 'moment'
import { AvatarHasName } from '../../../components/Common/Avatar'
import TableComponent from '../../../components/Common/TableComponent'
import queryString from 'query-string'
import AccessControlAction from '../../../components/AccessControlAction'

const { Search } = Input

const InternalDocumentSignDocPage = props => {
  const { history, electronicDocumentStore, loadingAnimationStore } = props

  const {
    electronicDocumentList,
    totalCount,
    queryParamsSignDocument,
  } = electronicDocumentStore

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)
  const [
    isVisiblePopupCreateSignDoc,
    setIsVisiblePopupCreateSignDoc,
  ] = useState(false)
  const [countFilter, setCountFilter] = useState(0)
  const [isRefreshPage, setIsRefreshPage] = useState(true)

  const tableColumns = [
    {
      title: 'Tiêu đề',
      render: record => trimOverlengthString(record.title, 450),
    },
    {
      title: 'Người trình ký',
      width: 180,
      render: record => (
        <AvatarHasName
          imgId={
            record.assignees.find(
              user => user.permission === ASSIGNEE_TYPE.CREATE
            ).image
          }
          name={
            record.assignees.find(
              user => user.permission === ASSIGNEE_TYPE.CREATE
            ).name_uppercase
          }
        />
      ),
    },
    {
      title: 'Lãnh đạo ký',
      width: 180,
      render: record => (
        <AvatarHasName
          imgId={
            record.assignees.find(
              user => user.permission === ASSIGNEE_TYPE.LEADER
            ).image
          }
          name={
            record.assignees.find(
              user => user.permission === ASSIGNEE_TYPE.LEADER
            ).name_uppercase
          }
        />
      ),
    },
    {
      align: 'center',
      title: 'Trạng thái',
      width: 100,
      render: record => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {StatusTag(TYPE_STATUS.ELECTRONIC_DOCUMENT, record.status)}
        </div>
      ),
    },
    {
      align: 'center',
      title: 'Ngày trình',
      width: 100,
      render: record => moment(record.created_date).format(DATE_FORMAT_DEFAULT),
    },
  ]

  const getElectronicDocumentList = async () => {
    try {
      loadingAnimationStore.setTableLoading(true)
      await electronicDocumentStore.getElectronicDocumentList()
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  useEffect(() => {
    electronicDocumentStore.setQueryParamsSignDocument({
      ...queryParamsSignDocument,
      ...queryStringParse,
    })
    setIsRefreshPage(false)
    ;(async () => {
      try {
        loadingAnimationStore.setTableLoading(true)
        setIsRefreshPage(false)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
    return () => {
      electronicDocumentStore.clearFilter()
      electronicDocumentStore.clearElectronicDocumentList()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    ;(async () => {
      await getElectronicDocumentList()
    })()
  }, [queryParamsSignDocument])

  const onChangePagination = pageIndex => {
    electronicDocumentStore.setQueryParamsSignDocument({
      ...queryParamsSignDocument,
      page: pageIndex - 1,
      size: 10,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: pageIndex - 1,
        size: 10,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/internal-document/sign-document?${queryStringStringify}`)
  }

  const handleQuickSearch = async title => {
    electronicDocumentStore.setQueryParamsSignDocument({
      ...queryParamsSignDocument,
      page: 0,
      title: title && title.trim(),
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: 0,
        size: 10,
        title: title ? title.trim() : null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/internal-document/sign-document?${queryStringStringify}`)
  }

  useEffect(() => {
    // Đếm số filter hiện tại
    let count = 0
    if (queryStringParse.title) {
      count++
    }
    if (queryStringParse.status) {
      count++
    }
    if (queryStringParse.from_date && queryStringParse.to_date) {
      count++
    }
    setCountFilter(count)
  }, [queryStringParse])

  return (
    <DocumentsLayout
      title={
        <PageTitle location={props.location} title="Phát hành văn bản điện tử">
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.create__PHAT_HANH_VB}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => setIsVisiblePopupCreateSignDoc(true)}
              type={'primary'}>
              Tạo trình ký
            </Button>
          </AccessControlAction>
        </PageTitle>
      }
      selectedKey="sign-document"
      linkToIncomingDocument="/internal-document/incoming-document"
      linkToOutgoingDocument="/internal-document/outgoing-document"
      linkToSignDocument="/internal-document/sign-document"
      linkToConfigDocument="/internal-document/config-document/document-books">
      <Helmet>
        <title>Phát hành văn bản điện tử | VIMC Quản lý VB VIMC</title>
      </Helmet>
      <div>
        <Row>
          <Col span={12}>
            {!showAdvanceFilter ? (
              <SearchBar>
                <Search
                  allowClear
                  onSearch={value => handleQuickSearch(value)}
                  defaultValue={queryStringParse.title}
                  placeholder={'Tìm kiếm trình ký theo tiêu đề...'}
                />
              </SearchBar>
            ) : null}
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <FilterButtonWrapper>
              <span
                className={'count-filter'}
                style={{ display: !countFilter && 'none' }}>
                {countFilter}
              </span>
              <Button
                type={showAdvanceFilter ? 'primary' : 'default'}
                ghost={showAdvanceFilter}
                onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}>
                {showAdvanceFilter ? (
                  <Space>
                    <CloseCircleOutlined />
                    Ẩn tìm kiếm nâng cao
                  </Space>
                ) : (
                  <Space>
                    <FilterOutlined />
                    Tìm kiếm nâng cao
                  </Space>
                )}
              </Button>
            </FilterButtonWrapper>
          </Col>
        </Row>
        {showAdvanceFilter ? <AdvanceFilterBlock /> : null}
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(
                  `/internal-document/sign-document/view/${record.code}`
                ),
            }
          }}
          rowKey={record => record.code}
          dataSource={electronicDocumentList}
          columns={tableColumns}
          pagination={false}
          scroll={{ x: 1000 }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
          loading={{
            tip: 'Đang tải văn bản...',
            spinning: loadingAnimationStore.tableLoading,
          }}
        />

        <TableBottomPaginationBlock>
          <Pagination
            onChange={e => onChangePagination(e)}
            // pageSize={pageSize}
            showSizeChanger={false}
            showLessItems
            current={
              queryStringParse.page ? parseInt(queryStringParse.page) + 1 : 1
            }
            total={totalCount}
            hideOnSinglePage={true}
          />
        </TableBottomPaginationBlock>
      </div>
      {isVisiblePopupCreateSignDoc && (
        <PopupCreateSignDoc
          isVisiblePopupCreateSignDoc={isVisiblePopupCreateSignDoc}
          handleClosePopupCreateSignDoc={() =>
            setIsVisiblePopupCreateSignDoc(false)
          }
        />
      )}
    </DocumentsLayout>
  )
}

InternalDocumentSignDocPage.propTypes = {}

export default memo(
  withRouter(
    inject(
      'loadingAnimationStore',
      'electronicDocumentStore',
      'departmentStore',
    )(observer(InternalDocumentSignDocPage)),
  ),
)

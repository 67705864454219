import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Menu, message, Modal } from 'antd'
import { HeadingButtonWrapper } from '../MissionDetailPage/MissionDetailPageStyled'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import MissionPageDetail from './MissionPageDetail'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import {
  BranchesOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  NodeExpandOutlined,
} from '@ant-design/icons'
import { blue } from '../../color'
import moment from 'moment'
import { MissionFullDetailModalWrapper } from './MissionPageStyled'
import MissionFullUpdateModal from './MissionFullUpdateModal'
import { ACL_ACTION_TYPE } from '../../constants'

const { confirm } = Modal

const MissionFullDetailModal = props => {
  const {
    isVisibleMissionFullDetailModal,
    handleCloseDetailMission,
    taskInfo,
    taskStore,
    aclStore,
  } = props
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore
  const [missionDetail, setMissionDetail] = useState({
    contentTask: null,
    resultTask: null,
    leader: [],
    handler: [],
    combiner: [],
    canAddWork: false,
    canUpdateProgress: false,
  })
  const [isLoadingActionBtn, setIsLoadingActionBtn] = useState(false)

  const [isLoadingDetailMission, setIsLoadingDetailMission] = useState(false)

  const onClickDelete = async taskID => {
    try {
      setIsLoadingActionBtn(true)
      await taskStore.deleteTask(taskID)
      handleCloseDetailMission('REFRESH')
      message.success('Xóa nhiệm vụ thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      setIsLoadingActionBtn(false)
    }
  }

  useEffect(() => {
    if (!taskInfo.code) return
    ;(async () => {
      try {
        setIsLoadingDetailMission(true)
        await taskStore.getTaskDetail(taskInfo.code)
        setMissionDetail(toJS(taskStore.taskDetail))
      } catch (err) {
        handleCloseDetailMission()
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingDetailMission(false)
      }
    })()
  }, [taskInfo])

  const deleteConfirm = id => {
    return confirm({
      title: 'Bạn có muốn xóa nhiệm vụ này?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      okType: 'danger',
      cancelText: 'Không',
      onOk: async () => {
        await onClickDelete(id)
      },
      onCancel() {},
    })
  }

  const handleNextMonth = async () => {
    try {
      setIsLoadingActionBtn(true)
      const payload = {
        display_date:
          missionDetail.contentTask?.display_date &&
          moment(missionDetail.contentTask?.display_date).add(1, 'months'),
        task_codes: [taskInfo.code],
      }
      await taskStore.moveTask(payload)
      handleCloseDetailMission('REFRESH')
      message.success('Đã chuyển nhiệm vụ này sang tháng sau!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingActionBtn(false)
    }
  }

  const nextMonthConfirm = () => {
    return confirm({
      title: 'Bạn có muốn chuyển nhiệm vụ này sang tháng sau không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      onOk: async () => {
        await handleNextMonth()
      },
      onCancel() {},
    })
  }

  const menuAction = (
    <Menu>
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.edit__MISSION) && (
        <Menu.Item
          onClick={() => setIsVisibleMissionFullUpdateModal(true)}
          icon={<EditOutlined style={{ color: blue }} />}>
          <span style={{ color: blue }}>Chỉnh sửa nhiệm vụ</span>
        </Menu.Item>
      )}
      {missionDetail.contentTask?.ket_qua?.phan_tram_hoan_thanh + '' !==
        '100' &&
        aclStore.checkAccessControlAction(
          ACL_ACTION_TYPE.chuyen_sang_thang__MISSION
        ) && (
          <Menu.Item
            onClick={nextMonthConfirm}
            icon={<NodeExpandOutlined style={{ color: blue }} />}>
            <span style={{ color: blue }}>Chuyển nhiệm vụ sang tháng sau</span>
          </Menu.Item>
        )}
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.delete__MISSION) && (
        <Menu.Item
          danger
          onClick={() => deleteConfirm(taskInfo.code)}
          icon={<DeleteOutlined />}>
          Xóa nhiệm vụ
        </Menu.Item>
      )}
    </Menu>
  )

  const [
    isVisibleMissionFullUpdateModal,
    setIsVisibleMissionFullUpdateModal,
  ] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const handleAfterUpdate = type => {
    setIsVisibleMissionFullUpdateModal(false)
    handleCloseDetailMission(type)
  }

  const [isRefreshListTask, setIsRefreshListTask] = useState(false)

  const handleSetIsRefreshListTask = () => {
    setIsRefreshListTask(true)
  }

  const handleCloseUpdateMissionModal = async () => {
    setIsVisibleMissionFullUpdateModal(false)
    try {
      setIsLoading(true)
      await taskStore.getTaskDetail(taskInfo.code)
      setMissionDetail(toJS(taskStore.taskDetail))
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <MissionFullDetailModalWrapper
      width={1300}
      style={{ top: 40 }}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <div>
            Chi tiết nhiệm vụ{' '}
            {isLoading && (
              <LoadingOutlined style={{ marginLeft: 10 }} spin={true} />
            )}
          </div>
          <div style={{ display: 'flex', gap: 10 }}>
            <Button
              onClick={() =>
                handleCloseDetailMission(isRefreshListTask ? 'REFRESH' : null)
              }
              danger>
              Đóng
            </Button>
            <HeadingButtonWrapper>
              <Dropdown overlay={menuAction} trigger={['click']}>
                <Button
                  type={'primary'}
                  icon={<BranchesOutlined />}
                  loading={isLoadingActionBtn}>
                  Thao tác
                </Button>
              </Dropdown>
            </HeadingButtonWrapper>
          </div>
        </div>
      }
      closable={false}
      visible={isVisibleMissionFullDetailModal}
      maskClosable={false}
      footer={null}>
      {isLoadingDetailMission ? (
        <div
          style={{
            position: 'relative',
            height: 500,
          }}>
          <SpinnerInlineComponent
            isLoading={isLoadingDetailMission}
            noBackground
            tip={'Đang tải chi tiết nhiệm vụ....'}
          />
        </div>
      ) : (
        <MissionPageDetail
          handleSetIsRefreshListTask={handleSetIsRefreshListTask}
          style={{
            backgroundColor: '#fff !important',
            marginLeft: -7.5,
            marginRight: -7.5,
          }}
          missionDetail={missionDetail}
          taskId={taskInfo.id}
          taskCode={taskInfo.code}
          isMissionFull
        />
      )}
      <MissionFullUpdateModal
        taskCode={taskInfo.code}
        isVisibleMissionFullUpdateModal={isVisibleMissionFullUpdateModal}
        handleCloseUpdateMissionModal={handleCloseUpdateMissionModal}
        handleAfterUpdate={handleAfterUpdate}
      />
    </MissionFullDetailModalWrapper>
  )
}

MissionFullDetailModal.propTypes = {}

export default inject(
  'taskStore',
  'loadingAnimationStore',
  'fileStore',
  'selectPeopleStore',
  'aclStore',
)(observer(MissionFullDetailModal))

import React, { memo, useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Col, Form, Input, Row, Upload } from 'antd'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import PageTitle from '../../../../components/PageTitle'
import {
  CloseCircleOutlined,
  CloudUploadOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'
import FileDownload from 'js-file-download'
import {
  DocumentInfoContent,
  DraggerUploadStyled,
  FormButtonGroup,
  TitleBlock,
  ValueBlock,
  ViewDetailContent,
} from '../../LeaveForm/Form/FormStyle'
import validator from '../../../../validator'
import ApprovalList from '../../LeaveForm/ApproverListModal/Modal'
import ApprovalFlow from '../../../../components/ApprovalFlow'
import { Container } from '../../../../layouts/Container/Container'

const XeCoChiTietPage = props => {
  const { Dragger } = Upload
  const { TextArea } = Input

  const {
    authenticationStore,
    loadingAnimationStore,
    phieuTrinhFakeDataStore,
    history,
    match,
  } = props

  const { currentUser } = authenticationStore
  const [form] = Form.useForm()
  const [editMode, setEditMode] = useState(true)
  const [showCancel, setShowCancel] = useState(false)
  const [fileList, setFileList] = useState([])
  const { recordDetail } = phieuTrinhFakeDataStore
  const LOAI_PHIEU_TRINH = 'phiếu trình xe cộ'

  const pageType = !editMode ? 'Chi tiết' : 'Tạo mới'
  const { id } = match.params
  const handleAddFileToUpload = () => false

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }
  useEffect(() => {
    console.log(currentUser?.email, recordDetail?.createdBy)
    console.log(currentUser && recordDetail.createdBy === currentUser.email)
    setShowCancel(currentUser && recordDetail.createdBy === currentUser.email)
  }, [currentUser, recordDetail])

  useEffect(() => {
    setEditMode(id === 'create')
    if (id === 'create') {
      return
    }
    loadingAnimationStore.showSpinner(true)
    phieuTrinhFakeDataStore
      .getRecordDetailById(id, LOAI_PHIEU_TRINH)
      .then(res => {
        loadingAnimationStore.showSpinner(false)

        phieuTrinhFakeDataStore.getViewAttachment({
          // recordId: res.data.id,
          recordId: 10, // TODO: remove
          fileId: res.data.file_id,
        })
      })
    return () => phieuTrinhFakeDataStore.clearLeaveDataDetail()
  }, [id, loadingAnimationStore, phieuTrinhFakeDataStore])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitForm = useCallback(values => {
    console.log('submit', values)
  })

  const downloadFile = useCallback(
    (id, file_id, file_name) => {
      loadingAnimationStore.showSpinner(true)
      phieuTrinhFakeDataStore
        .getAttachment({
          // recordId: id,
          recordId: 10, // TODO: remove
          fileId: file_id,
        })
        .then(response => {
          console.log(response)
          loadingAnimationStore.showSpinner(false)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          FileDownload(blob, `${file_name}`)
        })
        .catch(() => {
          loadingAnimationStore.showSpinner(false)
        })
    },
    [loadingAnimationStore, phieuTrinhFakeDataStore]
  )

  const handleChangeFile = useCallback(
    info => {
      form.setFieldsValue({
        file_id: info.fileList.slice(
          info.fileList.length - 1,
          info.fileList.length
        ),
      })
      setFileList(
        info.fileList.slice(info.fileList.length - 1, info.fileList.length)
      )
    },
    [form]
  )

  const renderViewContent = () => (
    <Row type={'flex'} gutter={30}>
      <Col span={24} style={{ marginBottom: '20px' }}>
        <ContentBlockWrapper>
          <DocumentInfoContent>
            <div className="list-group-item">
              <TitleBlock>
                <strong>Người xử lý:</strong>
              </TitleBlock>
              <ValueBlock>Admin</ValueBlock>
            </div>

            <div className="list-group-item">
              <TitleBlock>
                <strong>Nội dung:</strong>
              </TitleBlock>
              <ValueBlock>{recordDetail.title}</ValueBlock>
            </div>

            <div className="list-group-item">
              <TitleBlock>
                <strong>Trạng thái:</strong>
              </TitleBlock>
              <ValueBlock>
                <div
                  style={{
                    color: ['black', 'green', 'red', 'blue'][
                      recordDetail.status
                    ],
                  }}>
                  {
                    [
                      'chờ phê duyệt',
                      'đã duyệt phiếu',
                      'huỷ phiếu',
                      'cần phê duyệt',
                    ][recordDetail.status]
                  }
                </div>
              </ValueBlock>
            </div>
            <div>
              <Button
                block
                style={{ marginBottom: 8 }}
                onClick={() =>
                  downloadFile(
                    recordDetail.id,
                    recordDetail.file_id,
                    recordDetail.file_name
                  )
                }
                type={'primary'}>
                <PaperClipOutlined className={'cursor-pointer'} /> Download File
              </Button>
              {showCancel ||
              recordDetail.status !== 2 ||
              recordDetail.status !== 1 ? (
                <Button
                  block
                  onClick={() => {
                    console.log('huỷ đơn')
                  }}
                  type={'danger'}>
                  <CloseCircleOutlined className={'cursor-pointer'} /> Huỷ đơn
                </Button>
              ) : undefined}
            </div>
          </DocumentInfoContent>
        </ContentBlockWrapper>
      </Col>
    </Row>
  )

  const renderFormContent = () => (
    <ContentBlockWrapper>
      <Container maxWidth={820}>
        <Form
          scrollToFirstError={true}
          {...layout}
          form={form}
          name={'phieu-trinh-xe-co-form'}
          onFinish={handleSubmitForm}>
          <Form.Item
            name={'id'}
            label={'Người xử lý'}
            rules={[
              { required: true, message: 'Vui lòng nhập số đề xuất!' },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên người xử lý'} />
          </Form.Item>

          <Form.Item name={'description'} label={'Nội dung'} rules={[]}>
            <TextArea row={5} />
          </Form.Item>

          <Form.Item name={'file_id'} label="Tài liệu đính kèm">
            <Dragger
              valuePropName={'fileList'}
              fileList={fileList}
              onChange={handleChangeFile}
              beforeUpload={handleAddFileToUpload}>
              <DraggerUploadStyled>
                <CloudUploadOutlined />
                <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
              </DraggerUploadStyled>
            </Dragger>
          </Form.Item>

          <FormButtonGroup>
            <Button
              htmlType={'submit'}
              style={{ marginLeft: 10 }}
              type={'primary'}>
              Duyệt
            </Button>
          </FormButtonGroup>
        </Form>
      </Container>
    </ContentBlockWrapper>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>
          {pageType} {LOAI_PHIEU_TRINH} | Phiếu trình
        </title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={`${pageType} ${LOAI_PHIEU_TRINH}`}
      />
      <ViewDetailContent>
        {!editMode ? (
          <Row gutter={24}>
            <Col span={16}>
              <ContentBlockWrapper>
                <ApprovalFlow recordDetail={recordDetail} />
              </ContentBlockWrapper>
            </Col>
            <Col xs={8}>{renderViewContent()}</Col>
          </Row>
        ) : (
          renderFormContent()
        )}
      </ViewDetailContent>
      <ApprovalList />
    </DashboardLayout>
  )
}

XeCoChiTietPage.propTypes = {}

export default memo(
  inject(
    'authenticationStore',
    'authenticationStore',
    'loadingAnimationStore',
    'phieuTrinhFakeDataStore',
  )(observer(XeCoChiTietPage)),
)

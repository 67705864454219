import styled from 'styled-components'
import { grayPrimary } from '../../color'

export const OverviewWrapper = styled.div`
  display: flex;
  column-gap: 40px;
  padding-top: 10px;
  .avatar {
    padding: 5px;
    background-color: #ffe6bb;
    border-radius: 50%;
    position: relative;
    height: 190px;

    .edit-avatar-icon {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background-color: #e4e6eb;
      font-size: 22px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #504f4f;

      &:hover {
        font-size: 24px;
        transition: 0.4s;
      }
    }
  }

  .overview-info {
    flex-grow: 1;

    .item-info {
      display: flex;
      column-gap: 8px;
      margin-bottom: 10px;

      .desc {
        font-size: 10px;
        color: ${grayPrimary};
      }
    }
  }

  .ant-typography {
    margin-bottom: 0;
  }

  .ant-typography.ant-typography-edit-content {
    transition: 0.3s;
    transform: translateX(10px);
  }

  .ant-radio-group {
    display: block;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

export const UserName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .username {
    margin-left: 4px;
    font-weight: 500;
    font-size: 14px;
    color: ${grayPrimary};
    text-transform: lowercase;
  }
`

import { PlusOutlined } from '@ant-design/icons'
import { Button, Tabs } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PageTitle from '../../../components/PageTitle'
import { MODULE_CODE } from '../../../constants'
import DocumentsLayout from '../../../layouts/DocumentsLayout/DocumentsLayout'
import AuthoritiesPage from '../../AuthoritiesPage'
import Authorities from '../../DocumentManagement/InternalDocument/Authorities'
import InternalDocumentConfigAuthorities from './InternalDocumentConfigAuthorities'
import InternalDocumentConfigDocumentBooks from './InternalDocumentConfigDocumentBooks'

const InternalDocumentConfigPage = props => {
  const pathName = window.location.pathname
  const { moduleStore } = props

  const { moduleList } = moduleStore
  const history = useHistory()

  const [activeKey, setActiveKey] = useState('document-books')

  useEffect(() => {
    if (pathName.includes('authorities')) {
      setActiveKey('authorities')
    }

    if (pathName.includes('document-accessibility')) {
      setActiveKey('document-accessibility')
    }

    if (!moduleStore.checkAccessModule(MODULE_CODE.co_quan_ban_hanh)) {
      setActiveKey('document-books')
    }
  }, [pathName, moduleList])

  const onChangeTabs = key => {
    let path = '/internal-document/config-document'
    if (props.historyPath) {
      path = props.historyPath
    }
    history.push(`${path}/${key}`)
  }

  const getActiveTabContent = () => {
    if (activeKey === 'document-books') {
      return (
        <InternalDocumentConfigDocumentBooks
          historyPath={`${props.historyPath}/document-books`}
        />
      )
    }

    if (activeKey === 'document-accessibility') {
      return (
        <InternalDocumentConfigDocumentBooks
        historyPath={`${props.historyPath}/document-accessibility`}
      />
      )
    }

    return (
      <AuthoritiesPage isTab historyPath={`${props.historyPath}/authorities`} />
    )
  }

  return !props.isDocumentManagementTab ? (
    <DocumentsLayout
      title={
        <PageTitle location={props.location} title="Văn bản đến" hiddenGoBack>
          <Button
            icon={<PlusOutlined />}
            type={'primary'}
            style={{ visibility: 'hidden' }}>
            Button
          </Button>
        </PageTitle>
      }
      selectedKey="config-document"
      linkToIncomingDocument="/internal-document/incoming-document"
      linkToOutgoingDocument="/internal-document/outgoing-document"
      linkToSignDocument="/internal-document/sign-document"
      linkToConfigDocument="/internal-document/config-document/document-books">
      <div style={{ display: 'flex', minHeight: 'calc(100vh - 200px)' }}>
        <Tabs
          tabPosition={'left'}
          activeKey={activeKey}
          onChange={onChangeTabs}
          style={{ flex: '0 0 160px' }}>
          {moduleStore.checkAccessModule(MODULE_CODE.so_van_ban) && (
            <Tabs.TabPane tab="Nhóm sổ văn bản" key="document-books" />
          )}
          {moduleStore.checkAccessModule(MODULE_CODE.co_quan_ban_hanh) && (
            <Tabs.TabPane tab="Cơ quan ban hành" key="authorities" />
          )}
        </Tabs>
        <div style={{ flexGrow: 1 }}>
          {activeKey === 'document-books' ? (
            <InternalDocumentConfigDocumentBooks />
          ) : (
            <InternalDocumentConfigAuthorities />
          )}
        </div>
      </div>
    </DocumentsLayout>
  ) : (
    getActiveTabContent()
  )
}

InternalDocumentConfigPage.propTypes = {}

export default inject('moduleStore')(observer(InternalDocumentConfigPage))

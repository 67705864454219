import {
  BankOutlined,
  CloseOutlined,
  EditOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Space, Tag, Tooltip } from 'antd'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { blue } from '../../../color'
import { AvatarHasName } from '../../../components/Common/Avatar'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup'
import { ASSIGNEE_TYPE } from '../../../constants'
import {
  SelectList,
  SelectTitle,
} from '../../MissionCreatePage/MissionCreatePageStyled'
import { ButtonFooter } from '../../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import { nanoid } from 'nanoid'

const ApprovalLevelCreate = props => {
  const [form] = Form.useForm()
  const { selectPeopleStore, approvalTemplateStore } = props
  const {
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    setIsVisibleSelectUserDepartmentPopup,
    isVisibleSelectUserDepartmentPopup,
    selectUserData,
    selectDepartmentData,
    selectGroupData,
  } = selectPeopleStore
  const [handlerList, setHandlerList] = useState([])

  useEffect(() => {
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const onFinish = value => {
    let approvalLevels = approvalTemplateStore.approvalTemplateSortableLevels

    const level = {
      code: nanoid(),
      name: value.title,
      allow_edit: value.allow_edit,
      is_deleted: false,
      is_temp: true,
    }

    if (handlerList.length > 0) {
      level.handler = {
        full_name: handlerList[0].full_name,
        username: handlerList[0].id,
      }
    } else {
      level.allow_edit = true
    }

    approvalLevels.push(level)

    runInAction(() => {
      approvalTemplateStore.setApprovalTemplateSortableLevels = approvalLevels
    })

    props.onCancel()
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ allow_edit: false }}>
        <Form.Item
          label={'Tên cấp duyệt:'}
          name={'title'}
          rules={[
            { required: true, message: 'Vui lòng nhập tên cấp duyệt duyệt' },
            {
              max: 500,
              message: 'Tên cấp duyệt duyệt không được dài quá 500 ký tự!',
            },
          ]}>
          <Input placeholder={'Nhập tên cấp duyệt duyệt...'} />
        </Form.Item>

        <SelectTitle>
          <span>
            <span style={{ marginRight: '10px' }}>Người xử lý:</span>
          </span>
          <Space>
            <Tooltip title="Chọn người dùng" color={blue}>
              <Button
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() =>
                  handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)
                }
              />
            </Tooltip>
          </Space>
        </SelectTitle>

        <SelectList>
          {handlerList.map(el => (
            <Tag
              className={'tag-selected'}
              color={'blue'}
              key={el.id}
              closable
              onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
              <AvatarHasName
                imgId={el.image_id}
                size={22}
                name={el.full_name}
                icon={
                  (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                    <BankOutlined />
                  )) ||
                  (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
                }
              />
            </Tag>
          ))}
        </SelectList>

        <Form.Item name="allow_edit" valuePropName="checked">
          <Checkbox disabled={handlerList.length === 0}>
            Cho phép người duyệt thay người xử lý
          </Checkbox>
        </Form.Item>

        <ButtonFooter>
          <Space>
            <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
              Đóng
            </Button>
            <Button
              icon={<EditOutlined />}
              type={'primary'}
              htmlType={'submit'}>
              Tạo mới
            </Button>
          </Space>
        </ButtonFooter>
      </Form>
      <SelectPeoplePopup
        allowChangeCompany={false}
        onlyOneUser
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
    </>
  )
}

export default inject(
  'selectPeopleStore',
  'approvalTemplateStore',
)(observer(ApprovalLevelCreate))

import React, { useEffect } from 'react'
import { Empty, message, Modal, Select } from 'antd'
import OtherSubmissionForm from './otherSubmissionForm'
import { inject, observer } from 'mobx-react'
import FileDownload from 'js-file-download'
import selectUserStore from '../../stores/selectUserStore'
import { runInAction } from 'mobx'

const SubmissionForm = props => {
  const {
    handleCancel,
    showSubmissionMenu,
    showModal,
    onCloseModal,
    proposalStore,
    loadingAnimationStore,
  } = props
  const { formProposalType } = proposalStore

  /** Render thông tin khi thay đổi form phiếu trình  */
  const handleChangeFormType = value => {
    proposalStore.setFormProposalType(value)
    selectUserStore.clearSelectData()
  }

  /** Xử lý download văn bản mẫu */
  const handleDownloadAttachment = (fileId, fileTitle) => {
    loadingAnimationStore.showSpinner(true)
    proposalStore
      .downloadAttachment(fileId)
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        const blob = new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        FileDownload(blob, fileTitle)
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  /** Render form phiếu trình tương ứng */
  const renderSubmissionForm = () => {
    switch (formProposalType?.value) {
      case 'VEHICLE':
      case 'LEAVE':
      case 'LEAVE_ANNUAL':
      case 'LEAVE_NO_SALARY':
      case 'BUSINESS_TRIP':
      case 'EVALUATE_MEMBER':
      case 'EVALUATE_LEADER':
      case 'TIMEKEEPER':
      case 'STATIONERY':
      case 'MEETING':
        return (
          <OtherSubmissionForm
            onCloseModal={onCloseModal}
            proposalType={formProposalType?.value}
            handleDownloadAttachment={handleDownloadAttachment}
          />
        )
      default:
        return <Empty description={<span>Bạn chưa chọn loại!</span>} />
    }
  }

  useEffect(() => {
    if (!formProposalType) return
    ;(async () => {
      try {
        await proposalStore.getProposalTypeApprovalConfig(
          formProposalType?.value
        )
      } catch (error) {
        message.error('Loại thủ tục này chưa được thiết lập cấp duyệt')
      }
    })()

    return () => {
      runInAction(() => {
        proposalStore.proposalApproveConfig = {
          approvalCode: null,
          approvalLevels: [],
        }
      })
    }
  }, [formProposalType?.value])

  return (
    <Modal
      title={formProposalType ? formProposalType?.title : 'Tạo mới'}
      visible={showModal}
      onCancel={handleCancel}
      width={1000}
      maskClosable={false}
      footer={null}>
      <label style={{ fontWeight: 'bold' }}>Loại:</label>
      <Select
        style={{ width: '100%', marginBottom: 30, marginTop: 5 }}
        placeholder="Chọn loại"
        onChange={handleChangeFormType}
        value={formProposalType?.value}>
        {showSubmissionMenu}
      </Select>

      {proposalStore.proposalApproveConfig.approvalLevels.length !== 0 &&
        renderSubmissionForm()}
    </Modal>
  )
}

SubmissionForm.propTypes = {}

export default inject(
  'proposalStore',
  'selectUserStore',
  'loadingAnimationStore'
)(observer(SubmissionForm))

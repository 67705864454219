import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { Alert, Empty, Tabs } from 'antd'
import { blue } from '../../color'
import utils from '../../utils'
import { EmptyText } from '../../components/Common/CellText'
import { useParams } from 'react-router-dom'
import { SALARY_TYPE } from '../../constants'
import { toJS } from 'mobx'
import { SalaryListWrapper } from '../SalaryPage/SalaryPageStyled'
import SalaryListDepartment from '../SalaryPage/SalaryListDepartment'
import SalaryManager from '../SalaryPage/SalaryManager'

const { TabPane } = Tabs
const hidden = {
  visibility: 'hidden',
  height: 0,
  overflow: 'hidden',
}
const SalaryList = props => {
  const { proposalSalaryStore, isLoadingSalary, isShowSalaryInfo } = props
  const { id: salaryCode } = useParams()

  const { employeeSalaryData, managerSalaryData } = proposalSalaryStore

  const [salaryTabActive, setSalaryTabActive] = useState(SALARY_TYPE.EMPLOYEE)

  const onChangeSalaryTabs = tab => {
    setSalaryTabActive(tab)
  }

  const employeeSalaryList = useMemo(() => {
    return (
      <div>
        {employeeSalaryData.emp_departments?.map(item => {
          return (
            <SalaryListDepartment
              key={item.department_code}
              salaryItem={item}
            />
          )
        })}
      </div>
    )
  }, [employeeSalaryData])

  const salaryManagerList = useMemo(() => {
    return <SalaryManager managerSalaryData={managerSalaryData} />
  }, [managerSalaryData])

  return (
    <SalaryListWrapper isShowSalaryInfo={isShowSalaryInfo}>
      {employeeSalaryData.emp_departments && (
        <Tabs activeKey={salaryTabActive} onChange={onChangeSalaryTabs}>
          <TabPane tab="Người lao động" key={SALARY_TYPE.EMPLOYEE} />
          <TabPane tab="Lương người quản lý" key={SALARY_TYPE.MANAGER} />
        </Tabs>
      )}
      <div>
        <div style={salaryTabActive === SALARY_TYPE.MANAGER ? hidden : {}}>
          {employeeSalaryList}
        </div>
        <div style={salaryTabActive === SALARY_TYPE.EMPLOYEE ? hidden : {}}>
          {salaryManagerList}
        </div>
      </div>
      {!isLoadingSalary &&
      (!employeeSalaryData.emp_departments ||
        !managerSalaryData.manager_salary_list) ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={`${
            salaryCode
              ? 'Không có dữ liệu'
              : 'Hãy nhập từ file bảng lương để xem dữ liệu!'
          }`}
        />
      ) : (
        <div
          style={{
            display: `${
              salaryTabActive === SALARY_TYPE.EMPLOYEE ? 'block' : 'none'
            }`,
          }}>
          <Alert
            message={
              <div className={'salary-total'}>
                <div
                  className={'salary-total-content'}
                  style={{ color: blue, marginBottom: 8 }}>
                  <div style={{ flex: '0 0 186px' }}>
                    Tổng ngày công thực tế
                  </div>
                  <div>Tổng phụ cấp</div>
                  <div>Tổng khấu trừ</div>
                  <div>Tổng lương tháng</div>
                </div>
                <div className={'salary-total-content'}>
                  <div style={{ flex: '0 0 180px' }}>
                    {employeeSalaryData.tong_so_ngay_di_lam_thuc_te ? (
                      utils.formatCurrency(
                        employeeSalaryData.tong_so_ngay_di_lam_thuc_te
                      )
                    ) : (
                      <EmptyText>Không rõ</EmptyText>
                    )}
                  </div>
                  <div>
                    {employeeSalaryData.tong_phu_cap ? (
                      utils.formatCurrency(employeeSalaryData.tong_phu_cap)
                    ) : (
                      <EmptyText>Không rõ</EmptyText>
                    )}
                  </div>
                  <div>
                    {employeeSalaryData.tong_khau_tru ? (
                      utils.formatCurrency(employeeSalaryData.tong_khau_tru)
                    ) : (
                      <EmptyText>Không rõ</EmptyText>
                    )}
                  </div>
                  <div>
                    {employeeSalaryData.tong_luong_thang ? (
                      utils.formatCurrency(employeeSalaryData.tong_luong_thang)
                    ) : (
                      <EmptyText>Không rõ</EmptyText>
                    )}
                  </div>
                </div>
              </div>
            }
            type="success"
          />
        </div>
      )}

      <SpinnerInlineComponent noBackground isLoading={isLoadingSalary} />
    </SalaryListWrapper>
  )
}

SalaryList.propTypes = {
  isLoadingSalary: PropTypes.bool,
}

export default inject('proposalSalaryStore')(observer(SalaryList))

import React from 'react'
import { EmptyText, TitleContentBox } from '../Common/CellText'
import { Timeline } from 'antd'
import { TimelineItemWrapper } from './ConnectedDocumentIncomingDocHistoryBlockStyled'
import utils from '../../utils'
import {
  AuditOutlined,
  MailOutlined,
  MobileOutlined,
  UserOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { DATE_FORMAT_SLASH, HH_mm } from '../../constants'

const ConnectedDocumentIncomingDocHistoryBlock = props => {
  const { title, history } = props
  return (
    <>
      <TitleContentBox>{title}</TitleContentBox>
      <Timeline>
        {history &&
          history.length > 0 &&
          history.map((item, index) => {
            const authoriserInfo = JSON.parse(item.authoriserInfo)
            return (
              <Timeline.Item key={index}>
                <TimelineItemWrapper>
                  <div className="content">
                    <p>{`Chỉnh sửa lúc ${moment(item.createdTime).format(
                      HH_mm
                    )} - ${moment(item.createdTime).format(
                      DATE_FORMAT_SLASH
                    )}`}</p>
                    <p>
                      <UserOutlined />{' '}
                      {authoriserInfo?.full_name ? (
                        utils.getNameInCapitalize(authoriserInfo?.full_name)
                      ) : (
                        <EmptyText>Không rõ</EmptyText>
                      )}
                    </p>
                    <p>
                      <MailOutlined />{' '}
                      {authoriserInfo?.email ? (
                        authoriserInfo?.email
                      ) : (
                        <EmptyText>Không rõ</EmptyText>
                      )}
                    </p>
                    <p>
                      <MobileOutlined />{' '}
                      {authoriserInfo?.phone ? (
                        authoriserInfo?.phone
                      ) : (
                        <EmptyText>Không rõ</EmptyText>
                      )}
                    </p>
                    <p>
                      <AuditOutlined />{' '}
                      {authoriserInfo?.department ? (
                        authoriserInfo?.department
                      ) : (
                        <EmptyText>Không rõ</EmptyText>
                      )}
                    </p>
                  </div>
                </TimelineItemWrapper>
              </Timeline.Item>
            )
          })}
      </Timeline>
    </>
  )
}

ConnectedDocumentIncomingDocHistoryBlock.propTypes = {}

export default ConnectedDocumentIncomingDocHistoryBlock

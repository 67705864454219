import React from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import ContentTemplateListItem from './ContentTemplateListItem'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'

const ContentTemplatePage = props => {
  return !props.isAdministrativeManagementTab ? (
    <DashboardLayout>
      <Helmet>
        <title>Biểu mẫu</title>
      </Helmet>
      <PageTitle location={props.location}></PageTitle>
      <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
        <ContentTemplateListItem />
      </ContentBlockWrapper>
    </DashboardLayout>
  ) : (
    <ContentTemplateListItem />
  )
}

ContentTemplatePage.propTypes = {
  isAdministrativeManagementTab: PropTypes.bool
}

export default inject('contentTemplateStore')(observer(ContentTemplatePage))

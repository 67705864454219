import { message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import { MODULE_CODE } from '../../../constants'
import AuthoritiesPage from '../../AuthoritiesPage'
import InternalDocumentLayout from './InternalDocumentLayout'

const Authorities = (props) => {
  const history = useHistory()

  useEffect(() => {
    if(props.moduleStore.moduleList.length === 0) return 

    if (!props.moduleStore.checkAccessModule(MODULE_CODE.van_ban_noi_bo)) {
      message.error('Không được quyền truy cập vào trang này')
      history.push('/dashboard')
    }
  }, [props.moduleStore.moduleList])
  
  return (
    <>
      <InternalDocumentLayout>
        <Helmet>
          <title>
            Cơ quan ban hành | Văn bản nội bộ | Quản trị | VIMC Quản lý VB VIMC
          </title>
        </Helmet>
        <ContentBlockWrapper style={{paddingTop: 0}} >
          <AuthoritiesPage
            isTab
            historyPath="/document-management/internal-document/authorities"
          />
        </ContentBlockWrapper>
      </InternalDocumentLayout>
    </>
  )
}
export default inject(
  'moduleStore',
)(observer(Authorities))

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import { withRouter } from 'react-router-dom'
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Empty,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Steps,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import {
  CalendarOutlined,
  CheckOutlined,
  CommentOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { EmptyText, subStringAvatar, WordBreak } from '../Common/CellText'
import MentionTextInput from '../MentionTextInput/MentionTextInput'
import {
  AttachmentFile,
  AttachmentList,
  CommentBlock,
  CommentBox,
  FlexBetweenBox,
  FormButtonGroup,
  PolicyManagementDetailContent,
  PolicyManagementDetailWrapper,
  StepsWrapper,
  UploadFileListWrapper,
  UploadWrapper,
} from './ChuTruongDetailStyled'
import ChuTruongApprovalModal from './ChuTruongApprovalModal'
import { apiUrl } from '../../config'
import { blue, green, purple9, redPrimary } from '../../color'
import date_format from '../../date_format'
import { toJS } from 'mobx'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_DEFAULT,
  POLICY_STATUS,
  TYPE_STATUS,
} from '../../constants'
import { CommentHidden } from '../CommentComponent/CommentComponentStyled'
import PopoverWatchTime from '../PopoverWatchTime'
import PopupUpdateAssignees from '../../pages/Administrative/Consult/PopupUpdateAssignees/PopupUpdateAssignees'
import { StatusTag } from '../Common/StatusTag'
import FileItem from '../Common/FileItemComponent/FileItem'
import UploadFileItem from '../Common/FileItemComponent/UploadFileItem'
import moment from 'moment'

const { Step } = Steps

const ChuTruongDetail = props => {
  const {
    loadingAnimationStore,
    commonStore,
    chuTruongStore,
    selectUserStore,
    authenticationStore,
    fileStore,
    chuTruongCommentStore,
    history,
  } = props

  const { selectedPolicy } = chuTruongStore
  const { currentUser, isSuperAdmin } = authenticationStore
  const { policyCommentList, policyCommentCurrentStep } = chuTruongCommentStore
  const { title, attachments, description, assignees, status } = selectedPolicy

  const [comment, setComment] = useState('')
  const [fileList, setFileList] = useState([])
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [isGetCommentList, setIsGetCommentList] = useState(true) // Chỉ chạy useEffect lần đầu tiên
  const [commentList, setCommentList] = useState([])
  const [
    isVisiblePopupUpdateAssignees,
    setisVisiblePopupUpdateAssignees,
  ] = useState(false)

  const createList = toJS(assignees).filter(
    el => el.permission === ASSIGNEE_TYPE.CREATE
  )
  const followerList = toJS(assignees).filter(
    el => el.permission === ASSIGNEE_TYPE.FOLLOWER
  )
  const combinerList = toJS(assignees).filter(
    el => el.permission === ASSIGNEE_TYPE.COMBINER
  )
  const leaderList = toJS(assignees).filter(
    el => el.permission === ASSIGNEE_TYPE.LEADER
  )

  const renderContentComment = (del, id, username, index) => {
    if (isSuperAdmin || currentUser?.username === username) {
      if (!del) {
        return (
          <Popconfirm
            title={'Bạn có muốn xoá bình luận này?'}
            okType={'danger'}
            okText={'Xoá'}
            onConfirm={() => handleDeleteComment(id, index)}
            placement={'topRight'}
            cancelText={'Không'}
            icon={<QuestionCircleOutlined style={{ color: '#fc0000' }} />}>
            <Tooltip title={'Xóa bình luận'}>
              <DeleteTwoTone
                style={{
                  display:
                    selectedPolicy.status === POLICY_STATUS.COMPLETE &&
                    currentUser?.username === username &&
                    !isSuperAdmin &&
                    'none',
                }}
                twoToneColor={'#fc0000'}
              />
            </Tooltip>
          </Popconfirm>
        )
      } else {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Tag
              style={{ marginRight: 0, fontSize: 10, borderRadius: '10px' }}
              color={'red'}>
              Đã xóa
            </Tag>
          </div>
        )
      }
    } else {
      return null
    }
  }
  const renderCommentList = useMemo(() => {
    if (commentList.length === 0) {
      return (
        <Empty
          style={{ color: '#ccc' }}
          image={<CommentOutlined style={{ fontSize: '60px' }} />}
          imageStyle={{ height: 60 }}
          description={
            <span style={{ fontStyle: 'italic' }}>Không có ý kiến</span>
          }></Empty>
      )
    }
    return (
      commentList &&
      commentList.map((comment, index) => {
        return (
          <CommentHidden key={comment.comment_id}>
            {comment.isHidden && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip
                  title={`${comment.arrCommentDel.length} bình luận được xóa đi`}>
                  <EllipsisOutlined
                    onClick={() => handleShowCommentDel(comment.comment_id)}
                    style={{ fontSize: '30px', width: '30px' }}
                  />
                </Tooltip>
              </div>
            )}
            {!comment.isHidden && (
              <CommentBox
                key={comment.comment_id}
                deleted={comment.deleted}
                className={'listCommentWrapper'}>
                <div className={'left'}>
                  <Avatar
                    style={{ backgroundColor: blue }}
                    src={
                      comment.image_id &&
                      `${apiUrl}/api/v1/images/${comment.image_id}`
                    }>
                    {subStringAvatar(comment.name_uppercase)}
                  </Avatar>
                </div>
                <div className={'right'}>
                  <div className={'content'}>
                    <div className={'name-create'}>
                      <div>
                        {utils.getNameInCapitalize(comment.name_uppercase)}
                      </div>
                      {renderContentComment(
                        comment.deleted,
                        comment.comment_id,
                        comment.user_name,
                        index
                      )}
                    </div>
                    <div className={'content-comment'}>
                      <MentionTextInput
                        value={comment.content.trim()}
                        readonly={true}
                      />
                    </div>
                  </div>
                  <div className={'file'}>
                    <FlexBetweenBox>
                      {comment.files
                        ? comment.files.map(file => (
                            <FileItem
                              key={file.file_id}
                              file_id={file.file_id}
                              file_name={file.file_name}
                              file_type={utils.getExtensionFile(file.file_name)}
                              deleted={comment.deleted}
                            />
                          ))
                        : []}
                    </FlexBetweenBox>
                  </div>
                  <div className={'time'}>
                    {date_format.renderTime(comment.created_at)}
                  </div>
                </div>
              </CommentBox>
            )}
          </CommentHidden>
        )
      })
    )
  }, [commentList])
  const handleOnchangeComment = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    setComment(newValue)
  }
  const handleRemoveFileFromUploadList = useCallback(
    fileUID => {
      const newFileList = fileList.filter(file => file.uid !== fileUID)
      setFileList(newFileList)
    },
    [fileList]
  )
  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }, [])
  const sendComment = async () => {
    if (utils.isNullish(comment)) return
    if (comment.length > 500) {
      return message.error('Bình luận không vượt quá 500 ký tự!')
    }
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    loadingAnimationStore.setShowSpinInline(true)
    try {
      const response = await Promise.all(batchUploadArr)
      uploadedFiles = response.map(response => response.data.file_id)
      let commentData = {
        content: comment,
        fileIds: uploadedFiles,
      }
      const scrollCommentWrapper = document.querySelector('.scrollBox')
      let newCommentList = [...commentList]
      await chuTruongCommentStore.postPolicyComment(
        selectedPolicy.code,
        commentData
      )
      const getCommentResponse = await chuTruongCommentStore.getPolicyComment(
        selectedPolicy.code
      )
      const newComment =
        getCommentResponse.data[getCommentResponse.data.length - 1]
      newCommentList.push(newComment)
      setCommentList(newCommentList)
      setComment('')
      scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
      setFileList([])
    } catch (err) {
      console.log(err)
      message.error('Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }
  const renderCommentForm = () => {
    if (
      (isSuperAdmin ||
        currentUser?.username === createList[0].assignee_code ||
        leaderList
          .map(el => el.assignee_code)
          .includes(currentUser?.username) ||
        combinerList
          .map(el => el.assignee_code)
          .includes(currentUser?.username)) &&
      selectedPolicy.status !== POLICY_STATUS.COMPLETE
    ) {
      return (
        <CommentBlock>
          <MentionTextInput
            value={comment}
            readonly={false}
            onChange={handleOnchangeComment}
            placeHolder={'Viết ý kiến...'}
          />
          {fileList.length !== 0 && (
            <UploadFileListWrapper>
              {fileList.map(file => (
                <UploadFileItem
                  key={file.uid}
                  file_id={file.uid}
                  file_name={file.name}
                  file_type={utils.getExtensionFile(file.name)}
                  handleRemoveFileFromUploadList={() =>
                    handleRemoveFileFromUploadList(file.uid)
                  }
                />
              ))}
            </UploadFileListWrapper>
          )}
          <FormButtonGroup>
            <UploadWrapper>
              <Upload
                style={{ marginLeft: 4, marginRight: 4 }}
                valuePropName={'fileList'}
                fileList={fileList}
                multiple={true}
                onChange={handleChangeFile}
                beforeUpload={() => false}
                showUploadList={false}>
                <Button htmlType={'button'}>
                  <UploadOutlined /> Tải file
                </Button>
              </Upload>
            </UploadWrapper>
            <Button
              onClick={sendComment}
              type={'primary'}
              icon={<SendOutlined />}>
              Đăng ý kiến
            </Button>
          </FormButtonGroup>
        </CommentBlock>
      )
    }
    return null
  }
  const handleDeleteComment = async (commentID, index) => {
    try {
      loadingAnimationStore.setShowSpinInline(true)
      await chuTruongCommentStore.deletePolicyComment(
        selectedPolicy.code,
        commentID
      )
      const newCommentList = [...commentList]
      newCommentList[index] = {
        ...newCommentList[index],
        deleted: true,
      }
      setCommentList(newCommentList)
      message.success('Xóa bình luận thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }
  const handleShowCommentDel = id_comment => {
    const indexArrCommentDel = commentList.findIndex(
      cmt => cmt.comment_id === id_comment
    )
    commentList[indexArrCommentDel] = {
      ...commentList[indexArrCommentDel],
      isHidden: false,
    }
    /** Danh sách comment mới gồm mảng những comment đã xóa đi */
    const newCommentList = []
    commentList.map(el => {
      if (el.comment_id === commentList[indexArrCommentDel].comment_id) {
        newCommentList.push(...commentList[indexArrCommentDel].arrCommentDel)
      }
      if (el.comment_id !== commentList[indexArrCommentDel].comment_id) {
        newCommentList.push(el)
      }
    })
    setCommentList(newCommentList)
  }
  const renderFileIcon = file_type => {
    let iconFile = <FileTextOutlined />
    if (file_type === 'pdf') {
      iconFile = <FilePdfOutlined style={{ color: redPrimary }} />
      return iconFile
    }
    if (file_type === 'doc' || file_type === 'docx') {
      iconFile = <FileWordOutlined style={{ color: blue }} />
      return iconFile
    }
    if (
      [
        'jpg',
        'bmp',
        'dib',
        'jpe',
        'jfif',
        'gif',
        'tif',
        'tiff',
        'png',
        'heic',
      ].includes(file_type)
    ) {
      iconFile = <FileImageOutlined style={{ color: purple9 }} />
      return iconFile
    }
    if (file_type === 'rar' || file_type === 'zip') {
      iconFile = <FileZipOutlined style={{ color: '#e67e22' }} />
      return iconFile
    }
    if (file_type === 'xlsx') {
      iconFile = <FileExcelOutlined style={{ color: green }} />
      return iconFile
    }
  }
  const handleDeletePolicy = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await chuTruongStore.deletePolicy(selectedPolicy.code)
      await chuTruongStore.clearSelectedPolicy()
      await chuTruongStore.getPolicyList()
      history.push('/administrative/policy')
      message.success('Xoá chủ trương thành công!')
    } catch (e) {
      console.log(e)
      message.error(e.vi || 'Có lỗi xảy ra, xóa chủ trương thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  const showConfirmDeletePolicy = () => {
    Modal.confirm({
      title: 'Xoá chủ trương',
      icon: <ExclamationCircleOutlined style={{ color: '#fc0000' }} />,
      content: 'Bạn có chắc muốn xoá chủ trương này?',
      okType: 'danger',
      okText: 'Đồng ý',
      cancelText: 'Hủy',
      onOk() {
        handleDeletePolicy().then()
      },
    })
  }
  const renderPostAction = () => {
    if (
      !isSuperAdmin &&
      currentUser?.username !== leaderList[0].assignee_code &&
      !combinerList.map(el => el.assignee_code).includes(currentUser?.username)
    )
      return
    return (
      <>
        {currentUser?.username === leaderList[0].assignee_code &&
          status !== POLICY_STATUS.COMPLETE && (
            <Button
              onClick={() => setShowApprovalModal(true)}
              type={'primary'}
              style={{ marginRight: '10px' }}
              icon={<CheckOutlined />}>
              {selectedPolicy.currentStep === selectedPolicy.numOfStep
                ? 'Duyệt'
                : ' Chuyển giai đoạn'}
            </Button>
          )}
        <Dropdown
          trigger={'click'}
          overlay={
            <Menu>
              {(isSuperAdmin ||
                currentUser?.username === leaderList[0].assignee_code ||
                combinerList
                  .map(el => el.assignee_code)
                  .includes(currentUser?.username)) && (
                <Menu.Item
                  onClick={() => setisVisiblePopupUpdateAssignees(true)}
                  style={{ color: blue }}>
                  <EditOutlined />
                  Thêm người tham gia
                </Menu.Item>
              )}
              {isSuperAdmin ? (
                <Menu.Item
                  danger
                  icon={<DeleteOutlined />}
                  onClick={showConfirmDeletePolicy}>
                  Xóa chủ trương
                </Menu.Item>
              ) : null}
            </Menu>
          }>
          {!isSuperAdmin &&
          selectedPolicy.status === POLICY_STATUS.COMPLETE &&
          currentUser?.username !== leaderList[0].assignee_code &&
          !combinerList
            .map(el => el.assignee_code)
            .includes(currentUser?.username) ? (
            <div />
          ) : (
            <Tooltip title={'Xử lý ý kiến'}>
              <span
                style={{
                  fontSize: 16,
                  cursor: 'pointer',
                }}
                className={'action-icon'}>
                <MoreOutlined />
              </span>
            </Tooltip>
          )}
        </Dropdown>
      </>
    )
  }
  const renderDropdownMenu = attachment => {
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            fileStore.handleDownloadFile(
              attachment.file_id,
              attachment.file_name
            )
          }>
          Tải về
        </Menu.Item>
      </Menu>
    )
  }
  const renderStepsNavigator = useCallback(() => {
    switch (selectedPolicy.numOfStep) {
      case '2':
        return (
          <StepsWrapper>
            <Steps
              size="small"
              current={parseInt(policyCommentCurrentStep) - 1}>
              <Step title="Giai đoạn 1" />
              <Step
                title="Giai đoạn 2"
                disabled={selectedPolicy.currentStep < 2}
              />
            </Steps>
          </StepsWrapper>
        )
      case '3':
        return (
          <StepsWrapper>
            <Steps
              size="small"
              current={parseInt(policyCommentCurrentStep) - 1}>
              <Step title="Giai đoạn 1" />
              <Step
                title="Giai đoạn 2"
                disabled={selectedPolicy.currentStep < 2}
              />
              <Step
                title="Giai đoạn 3"
                disabled={selectedPolicy.currentStep < 3}
              />
            </Steps>
          </StepsWrapper>
        )
      default:
        return null
    }
  }, [
    selectedPolicy.process,
    selectedPolicy.currentStep,
    policyCommentCurrentStep,
  ])
  const handleApprovePolicy = async userName => {
    const endMessage = `Chủ trương đã được duyệt bởi ${utils.getNameInCapitalize(
      currentUser?.name_lowercase
    )}!`
    const approvedMessage = `Giai đoạn  ${
      selectedPolicy.currentStep
    } đã được duyệt bởi ${utils.getNameInCapitalize(
      currentUser?.name_lowercase
    )}!`
    loadingAnimationStore.showSpinner(true)
    try {
      let newCommentList = [...commentList]
      await chuTruongStore.changeStep(
        selectedPolicy.code,
        userName ? userName : currentUser.username
      )
      await chuTruongStore.getPolicyDetail(
        selectedPolicy.code,
        selectedPolicy.currentStep
      )
      await chuTruongStore.getPolicyList()
      const response = await chuTruongCommentStore.getPolicyComment(
        selectedPolicy.code
      )
      const newComment = response.data[response.data.length - 1]
      newCommentList.push(newComment)
      setCommentList(newCommentList)
      setComment('')
      if (selectedPolicy.currentStep < selectedPolicy.numOfStep) {
        message.success(approvedMessage)
        return
      }
      message.success(endMessage)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra. Vui lòng thử lại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  const renderAttachmentList = useCallback(() => {
    if (attachments.length === 0) {
      return (
        <div style={{ marginBottom: '10px' }}>
          <EmptyText>Không có tài liệu đính kèm.</EmptyText>
        </div>
      )
    }
    return attachments.map(attachment => {
      return (
        <Dropdown
          key={attachment.file_id}
          overlay={() => renderDropdownMenu(attachment)}>
          <AttachmentFile theme={commonStore.appTheme}>
            <div className="file-icon">
              {renderFileIcon(utils.getExtensionFile(attachment.file_name))}
            </div>
            <div className="file-info">
              <Tooltip title={attachment.file_name}>
                <div className="title">
                  {attachment.file_name.length >= 20
                    ? attachment.file_name.slice(0, 20).concat('...')
                    : attachment.file_name}
                </div>
              </Tooltip>
            </div>
          </AttachmentFile>
        </Dropdown>
      )
    })
  }, [])

  useEffect(() => {
    /** Danh sách comment sau khi xóa chứa các comment đã xóa liền nhau gộp thành 1 mảng */
    if (!isGetCommentList) return
    if (policyCommentList.length > 0) setIsGetCommentList(false)

    let commentAfterDeleteList = []
    let key = -1
    policyCommentList.map((el, i) => {
      if (!el.deleted) {
        commentAfterDeleteList.push(toJS(el))
      }
      if (el.deleted && i > key) {
        const arrCommentDel = []
        for (let index = i; index < policyCommentList.length; index++) {
          key = index
          if (policyCommentList[index].deleted === el.deleted) {
            arrCommentDel.push(toJS(policyCommentList[index]))
          }
          if (
            policyCommentList[index].deleted === !el.deleted ||
            index === policyCommentList.length - 1
          )
            break
        }
        commentAfterDeleteList.push({
          comment_id: arrCommentDel[0].comment_id,
          arrCommentDel: arrCommentDel,
          isHidden: true,
        })
      }
    })
    setCommentList(commentAfterDeleteList)
  }, [policyCommentList])
  useEffect(() => {
    chuTruongCommentStore.clearPolicyCommentList()
    return () => chuTruongCommentStore.clearPolicyCommentList()
  }, [])

  return (
    <PolicyManagementDetailWrapper
      showFormComment={
        (isSuperAdmin ||
          currentUser?.username === createList[0].assignee_code ||
          leaderList
            .map(el => el.assignee_code)
            .includes(currentUser?.username) ||
          combinerList
            .map(el => el.assignee_code)
            .includes(currentUser?.username)) &&
        selectedPolicy.status !== POLICY_STATUS.COMPLETE
      }>
      <div className="scrollBox">
        <div className="d-flex">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 'calc(100% - 190px)',
            }}>
            <Tooltip placement="top" title={title}>
              <h1
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}>
                {title}
              </h1>
            </Tooltip>
            {StatusTag(TYPE_STATUS.POLICY, status)}
          </div>
          <div className="action-wrapper">{renderPostAction()}</div>
          <ChuTruongApprovalModal
            visible={showApprovalModal}
            onCancel={() => setShowApprovalModal(false)}
            onOk={handleApprovePolicy}
          />
        </div>
        <PolicyManagementDetailContent>
          {renderStepsNavigator()}
          <div className="content">
            {description ? (
              WordBreak(description, true)
            ) : (
              <EmptyText>Không có nội dung</EmptyText>
            )}
          </div>
          <Row type={'flex'} gutter={15}>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <div style={{ marginBottom: 12 }}>
                <strong>Lãnh đạo phê duyệt</strong>
              </div>
              {leaderList.length > 0 ? (
                <PopoverWatchTime
                  userList={selectUserStore.convertListToDefault(leaderList)}
                  title={'Danh sách lãnh đạo phê duyệt'}
                />
              ) : (
                <EmptyText>Không có lãnh đạo phê duyệt</EmptyText>
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <div style={{ marginBottom: 12 }}>
                <strong style={{ marginRight: 2 }}>Người phối hợp</strong>
                <span style={{ color: '#999' }}>{combinerList.length}</span>
              </div>
              {combinerList.length > 0 ? (
                <PopoverWatchTime
                  userList={selectUserStore.convertListToDefault(combinerList)}
                  title={'Danh sách người phối hợp'}
                />
              ) : (
                <EmptyText>Không có người phối hợp</EmptyText>
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <div style={{ marginBottom: 12 }}>
                <strong style={{ marginRight: 2 }}>Người theo dõi</strong>
                <span style={{ color: '#999' }}>{followerList.length}</span>
              </div>
              {followerList.length > 0 ? (
                <PopoverWatchTime
                  userList={selectUserStore.convertListToDefault(followerList)}
                  title={'Danh sách người theo dõi'}
                />
              ) : (
                <EmptyText>Không có người theo dõi</EmptyText>
              )}
            </Col>
          </Row>
          <div>
            <strong>Thời hạn: </strong>
            <>
              <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
              <span>
                {moment(selectedPolicy?.deadline).format(DATE_FORMAT_DEFAULT)}
              </span>
            </>
          </div>
          <div style={{ marginBottom: 12, marginTop: 16 }}>
            <strong>Tài liệu đính kèm</strong>{' '}
            <span style={{ color: '#999' }}>{attachments.length}</span>
          </div>
          <AttachmentList>{renderAttachmentList()}</AttachmentList>
          {renderCommentList}
        </PolicyManagementDetailContent>
      </div>
      {renderCommentForm()}
      <PopupUpdateAssignees
        type={'policy'}
        isVisiblePopupUpdateAssignees={isVisiblePopupUpdateAssignees}
        handleClosePopupUpdateAssignees={() =>
          setisVisiblePopupUpdateAssignees(false)
        }
      />
    </PolicyManagementDetailWrapper>
  )
}

ChuTruongDetail.propTypes = {}

export default withRouter(
  inject(
    'loadingAnimationStore',
    'commonStore',
    'fileStore',
    'chuTruongStore',
    'authenticationStore',
    'chuTruongCommentStore',
    'selectUserStore',
  )(observer(ChuTruongDetail)),
)

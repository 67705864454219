import styled from 'styled-components'

export const ViewDetailContent = styled.div`
  padding-top: 0px;
`
export const DraggerUploadStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .anticon {
    font-size: 24px;
  }
`
export const FormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const DocumentInfoContent = styled.div`
  margin: 15px 10px;
  .ant-row {
    margin-bottom: 10px;
  }
`

export const FileViewBlock = styled.div`
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
`

export const TitleBlock = styled.div`
  width: 200px;
`
export const ValueBlock = styled.div`
  flex-grow: 1;
`
export const FormActionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 30px 0;
`

import { Button } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import AccessControlAction from '../../components/AccessControlAction'
import { TitleContentBox } from '../../components/Common/CellText'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import ProposalStepApprove from '../../components/SubmissionForm/ProposalStepApprove'
import { ACL_ACTION_TYPE, PROPOSAL_STATUS } from '../../constants'
import ProposalSalaryActionConfirmModal from './ProposalSalaryActionConfirmModal'

const groupBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 10,
  marginBottom: 10,
}

const ProposalSalaryDetailAction = props => {
  const { proposalSalaryStore, authenticationStore } = props
  const { currentUser } = authenticationStore
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [confirmModalType, setConfirmModalType] = useState(null)
  const [isShowButtonAction, setIsShowButtonAction] = useState(false)

  useEffect(() => {
    if (!currentUser) return
    const currentStepInfo = proposalSalaryStore.salaryInfo.approval_levels?.find(
      item => item.status === PROPOSAL_STATUS.PENDING
    )
    const previousStep = currentStepInfo ? proposalSalaryStore.salaryInfo.approval_levels?.find(
      item => item.level === currentStepInfo.level - 1
    ) : undefined

    if (
      currentUser.username === currentStepInfo?.username && previousStep &&
      previousStep.status !== PROPOSAL_STATUS.REJECTED
    ) {
      setIsShowButtonAction(true)
    } else {
      setIsShowButtonAction(false)
    }
  }, [proposalSalaryStore.salaryInfo, currentUser])

  const handleCloseModal = useCallback(() => {
    setIsConfirmModalVisible(false)
    setConfirmModalType(null)
  }, [])

  const handleOpenConfirmModal = modalType => {
    setConfirmModalType(modalType)
    setIsConfirmModalVisible(true)
  }

  return (
    <ContentBlockWrapper style={{ marginBottom: 15 }}>
      <TitleContentBox>Tiến trình duyệt</TitleContentBox>
      <ProposalStepApprove
        approvalLevelList={proposalSalaryStore.salaryInfo.approval_levels || []}
      />
      {isShowButtonAction && (
        <div style={groupBtnStyle}>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.approve__SALARY}>
            <Button
              danger
              onClick={() => handleOpenConfirmModal(PROPOSAL_STATUS.REJECT)}>
              Từ chối
            </Button>
          </AccessControlAction>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.approve__SALARY}>
            <Button
              type={'primary'}
              onClick={() => handleOpenConfirmModal(PROPOSAL_STATUS.APPROVAL)}>
              Duyệt yêu cầu
            </Button>
          </AccessControlAction>
        </div>
      )}

      <ProposalSalaryActionConfirmModal
        isModalVisible={isConfirmModalVisible}
        handleCloseModal={handleCloseModal}
        confirmModalType={confirmModalType}
      />
    </ContentBlockWrapper>
  )
}

ProposalSalaryDetailAction.propTypes = {}

export default inject(
  'proposalSalaryStore',
  'authenticationStore'
)(observer(ProposalSalaryDetailAction))

import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import {
  CloseOutlined,
  SendOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  BankOutlined,
} from '@ant-design/icons'
import RichEditor from '../../components/RichEditor/RichEditor'
import UploadFileItem from '../../components/Common/FileItemComponent/UploadFileItem'
import utils from '../../utils'
import {
  SelectList,
  SelectTitle,
  UploadFileListWrapper,
} from './InternalMessageCreateModalStyled'
import { inject, observer } from 'mobx-react'
import { blue, yellowPrimary } from '../../color'
import SelectPeoplePopup from '../../components/SelectPeoplePopup/SelectPeoplePopup'
import { AvatarHasName } from '../../components/Common/Avatar'
import SelectGroupMemberPopup from '../../components/SelectGroupMemberPopup'
import {
  ASSIGNEE_TYPE,
} from '../../constants'

const styleFooter = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 30,
}

const InternalMessageCreateModal = props => {
  const {
    isModalVisible,
    handleCloseCreateModal,
    fileStore,
    loadingAnimationStore,
    internalMessageStore,
    selectPeopleStore
  } = props

  const {
    selectUserData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
  } = selectPeopleStore

  const [form] = Form.useForm()

  const EDITOR_REF = useRef()
  const [editorContent, setEditorContent] = useState(null)
  const [fileList, setFileList] = useState([])
  const [userDataList, setUserDataList] = useState([])

  useEffect(() => {
    setUserDataList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
  }, [selectUserData, selectGroupData])

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ files: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList.filter(
      (elem, index, fileList) =>
        fileList.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }

  const handleCancelForm = (event, isRefressList) => {
    form.resetFields()
    setFileList([])
    setUserDataList([])
    handleCloseCreateModal(isRefressList)
    selectPeopleStore.clearSelectUserData()
    selectPeopleStore.clearSelectGroupData()
  }

  const onFinish = async values => {
    const selectIdList = selectUserData[ASSIGNEE_TYPE.HANDLER].map(user => user.id)
    const selectIdUserGroupList = selectGroupData[ASSIGNEE_TYPE.HANDLER]
      .map(group => group.users.map(user => user.user.user_name))
      .flat()

    const selectIdHandlerList = [
      ...selectIdList,
      ...selectIdUserGroupList,
    ]
    const receiverValue = [...new Set(selectIdHandlerList)] // Filter ID trùng

    if (receiverValue.length === 0) {
      return message.warning(
        'Chưa chọn người gửi hoặc Nhóm được chọn không có thành viên'
      )
    }

    if (values.title === 0) {
      return message.warning(
        'Chưa nhập tiêu đề'
      )
    }

    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    loadingAnimationStore.showSpinner(true)
    try {
      const responseFile = await Promise.all(batchUploadArr)
      let submitValues = {
        ...values,
        file_ids: responseFile.map(response => response.data.file_id),
        content: EDITOR_REF.current.editor.getData(),
        receiver: receiverValue,
      }
      await internalMessageStore.create(submitValues)
      handleCancelForm({}, true)
      message.success('Đã gửi tin nhắn thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Modal
      footer={null}
      visible={isModalVisible}
      width={1000}
      onCancel={handleCancelForm}
      maskClosable={false}>
      <Form layout={'vertical'} onFinish={onFinish} form={form}>
        <SelectTitle>
          <span>
            <span
              style={{
                color: '#ff4d4f',
                fontFamily: 'SimSun, sans-serif',
                marginRight: '-4px',
              }}>
              *{' '}
            </span>
            <span style={{ marginRight: '10px' }}>Gửi tới:</span>
          </span>
          <Space>
            <Tooltip title="Chọn người dùng" color={blue}>
              <Button
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() =>
                  handleOpenSelectUserDepartmentPopup(
                    ASSIGNEE_TYPE.HANDLER
                  )
                }
              />
            </Tooltip>
            <Tooltip title="Chọn nhóm" color={'#ffc069'}>
              <Button
                icon={<TeamOutlined />}
                style={{ color: yellowPrimary }}
                onClick={() =>
                  handleOpenSelectGroupPopup(ASSIGNEE_TYPE.HANDLER)
                }
              />
            </Tooltip>
          </Space>
        </SelectTitle>
        <SelectList>
          {userDataList.map(el => (
            <Tag
              className={'tag-selected'}
              color={'blue'}
              key={el.id}
              closable
              onClose={() =>
                selectPeopleStore.handleRemoveSelect(el)
              }>
              <AvatarHasName
                imgId={el.image_id}
                size={22}
                name={el.full_name}
                icon={
                  (el.assignee_type ===
                    ASSIGNEE_TYPE.DEPARTMENT && <BankOutlined />) ||
                  (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                    <TeamOutlined />
                  ))
                }
              />
            </Tag>
          ))}
        </SelectList>
        <Form.Item
          label={'Tiêu đề'}
          name={'title'}
          style={{ marginTop: "5px" }}
          rules={[{ required: true, message: 'Trường này bắt buộc!' }]}>
          <Input placeholder={'Nhập tiêu đề...'} maxLength={255}/>
        </Form.Item>
        <Form.Item label={'Nội dung'} name={'content'}>
          <RichEditor
            EDITOR_REF={EDITOR_REF}
            placeholder={'Nhập nội dung...'}
            editorContent={editorContent}
          />
        </Form.Item>
        <Form.Item label={'Tài liệu đính kèm'} name={'file_ids'}>
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            onChange={handleChangeFile}
            beforeUpload={() => false}
            multiple={true}
            showUploadList={false}>
            <Button icon={<UploadOutlined />}>Chọn tài liệu đính kèm</Button>
          </Upload>
        </Form.Item>
        {fileList.length !== 0 && (
          <UploadFileListWrapper>
            {fileList.map(file => (
              <UploadFileItem
                key={file.uid}
                file_id={file.uid}
                file_name={file.name}
                file_type={utils.getExtensionFile(file.name)}
                handleRemoveFileFromUploadList={() =>
                  handleRemoveFileFromUploadList(file.uid)
                }
              />
            ))}
          </UploadFileListWrapper>
        )}
        <div style={styleFooter}>
          <Space>
            <Button icon={<CloseOutlined />} danger onClick={handleCancelForm}>
              Đóng
            </Button>
            <Button
              icon={<SendOutlined />}
              type={'primary'}
              htmlType={'submit'}>
              Gửi tin nhắn
            </Button>
          </Space>
        </div>
      </Form>
      <SelectPeoplePopup
        disableSelectDepartment={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupMemberPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </Modal>
  )
}

InternalMessageCreateModal.propTypes = {}

export default inject(
  'loadingAnimationStore',
  'fileStore',
  'internalMessageStore',
  'selectPeopleStore',
)(observer(InternalMessageCreateModal))

import React, { memo, useState } from 'react'
// Components
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import ChangePassword from './ChangePassword'
import DashboardAuthLayout from '../../layouts/DashboardAuthLayout'
import Contact from './Contact'
import Job from './Job'
import Education from './Education'
import Health from './Health'
import PageTitle from '../../components/PageTitle'
import { TitleContentBox } from '../../components/Common/CellText'
import Overview from './Overview'
// Styled component
import { MenuWrapper } from './MyProfilePageStyled'
// Antd design
import { Menu } from 'antd'
import {
  ContactsFilled,
  HeartFilled,
  ReadFilled,
  RedditCircleFilled,
  ScheduleFilled,
  UnlockFilled,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import { blue } from '../../color'

const MyProfilePage = props => {
  const menuList = [
    {
      key: 'overview',
      title: 'Tổng quan',
      icon: <RedditCircleFilled style={{ color: blue, fontSize: 16 }} />,
    },
    {
      key: 'contact',
      title: 'Thông tin liên hệ',
      icon: <ContactsFilled style={{ color: blue, fontSize: 16 }} />,
    },
    {
      key: 'job',
      title: 'Công việc',
      icon: <ScheduleFilled style={{ color: blue, fontSize: 16 }} />,
    },
    {
      key: 'education',
      title: 'Trình độ học vấn',
      icon: <ReadFilled style={{ color: blue, fontSize: 16 }} />,
    },
    {
      key: 'health',
      title: 'Sức khỏe',
      icon: <HeartFilled style={{ color: blue, fontSize: 16 }} />,
    },
    {
      key: 'change-password',
      title: 'Thay đổi mật khẩu',
      icon: <UnlockFilled style={{ color: blue, fontSize: 16 }} />,
    },
  ]

  const renderInfo = () => {
    if (keyInfoActive.key === 'overview') return <Overview />
    if (keyInfoActive.key === 'contact') return <Contact />
    if (keyInfoActive.key === 'job') return <Job />
    if (keyInfoActive.key === 'education') return <Education />
    if (keyInfoActive.key === 'health') return <Health />
    if (keyInfoActive.key === 'change-password')
      return (
        <ChangePassword
          finishChangePassword={() =>
            setKeyInfoActive({
              key: 'overview',
              title: 'Tổng quan',
            })
          }
        />
      )
  }

  const [keyInfoActive, setKeyInfoActive] = useState({
    key: 'overview',
    title: 'Tổng quan',
  })

  return (
    <DashboardAuthLayout>
      <div style={{ marginLeft: 330, marginTop: 50 }}>
        <PageTitle location={props.location} />
      </div>
      <div style={{ display: 'flex', gap: 24 }}>
        <div style={{ width: 300, flex: '0 0 300px' }}>
          <ContentBlockWrapper>
            <TitleContentBox>Thông tin của bạn</TitleContentBox>
            <MenuWrapper
              selectedKeys={[keyInfoActive.key]}
              onSelect={item => {
                setKeyInfoActive({
                  key: item.key,
                  title: item.item.props.title,
                })
              }}>
              {menuList.map(menuItem => (
                <Menu.Item
                  key={menuItem.key}
                  icon={menuItem.icon}
                  title={menuItem.title}>
                  {menuItem.title}
                </Menu.Item>
              ))}
            </MenuWrapper>
          </ContentBlockWrapper>
        </div>
        <div style={{ flexGrow: 1 }}>
          <ContentBlockWrapper>
            <TitleContentBox>{keyInfoActive.title}</TitleContentBox>
            <div style={{ minHeight: 288 }}>{renderInfo()}</div>
          </ContentBlockWrapper>
        </div>
      </div>
    </DashboardAuthLayout>
  )
}

export default memo(
  inject(
    'authenticationStore',
    'commonStore',
    'loadingAnimationStore'
  )(observer(MyProfilePage))
)

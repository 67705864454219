import React, { memo, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Avatar, Checkbox, message, Pagination, Tabs, Tooltip } from 'antd'
import { CellEclipseBox } from '../Common/Table'
import { apiUrl } from './../../config'
import {
  CommentWrapper,
  TabsDepartmentWrapper,
} from './TableSelectDepartmentStyled'
import { TextWrap, trimOverlengthString } from '../Common/CellText'
import loadingAnimationStore from '../../stores/loadingAnimationStore'
import { toJS } from 'mobx'
import { ASSIGNEE_TYPE } from '../../constants'
import TableComponent from '../Common/TableComponent'

const { TabPane } = Tabs

/** @deprecated not use **/
const TableSelectDepartment = props => {
  const {
    departmentStore,
    departmentSelectedList,
    handleSelectDepartment,
    userStore,
    selectUserStore,
  } = props

  // tableUserType
  const {
    userOfDepartmentListTotalCount,
    userOfDepartmentListPageIndex,
    userOfDepartmentListPageSize,
  } = userStore

  const { departmentList } = departmentStore
  const { tableDepartmentType, selectData } = selectUserStore

  const [userOfDepartmentData, setUserOfDepartmentData] = useState([])
  const [disableDepartmentList, setDisableDepartmentList] = useState([])

  useEffect(() => {
    if (!tableDepartmentType) return
    const assigneesListAll = [
      ...toJS(selectData.handler),
      ...toJS(selectData.combiner),
      ...toJS(selectData.follower),
      ...toJS(selectData.leader),
    ]
    const filterAssigneesListToId = tableDepartmentType =>
      assigneesListAll
        .filter(
          el =>
            el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT &&
            el.permission !== tableDepartmentType
        )
        .map(el => el.id)
    if (tableDepartmentType === 'handler') {
      setDisableDepartmentList(filterAssigneesListToId(ASSIGNEE_TYPE.HANDLER))
    }
    if (tableDepartmentType === 'combiner') {
      setDisableDepartmentList(filterAssigneesListToId(ASSIGNEE_TYPE.COMBINER))
    }
    if (tableDepartmentType === 'follower') {
      setDisableDepartmentList(filterAssigneesListToId(ASSIGNEE_TYPE.FOLLOWER))
    }
    if (tableDepartmentType === 'leader') {
      setDisableDepartmentList(filterAssigneesListToId(ASSIGNEE_TYPE.LEADER))
    }
  }, [tableDepartmentType])

  const tableColumn = [
    {
      title: 'Thông tin',
      render: record => (
        <CommentWrapper
          author={
            <span style={{ textTransform: 'capitalize' }}>
              {record.name_lowercase}
            </span>
          }
          avatar={
            <Avatar
              style={{ backgroundColor: '#1890ff' }}
              src={
                record.imageId && `${apiUrl}/api/v1/images/${record?.imageId}`
              }>
              {record?.name_uppercase.split('')[0].toString().toUpperCase()}
            </Avatar>
          }
        />
      ),
    },
    {
      title: 'ID',
      width: 100,
      render: record => record?.username,
    },
    {
      title: 'Email',
      render: record => (
        <Tooltip title={record?.email}>
          <CellEclipseBox>{record?.email}</CellEclipseBox>
        </Tooltip>
      ),
    },
    {
      title: 'Chức vụ',
      render: record => (record.position ? record?.position.name : 'Chưa rõ'),
    },
    {
      title: 'Phòng ban',
      render: record => (
        <Tooltip title={record?.department?.name}>
          <CellEclipseBox>{record?.department?.name}</CellEclipseBox>
        </Tooltip>
      ),
    },
  ]

  const changeSelectDepartment = (e, department_name, department_code) => {
    const departmentData = {
      department_code: department_code,
      department_name: department_name,
    }
    handleSelectDepartment(e, departmentData)
  }

  const onChangeTabDepartment = key => {
    userStore.setFilter('userOfDepartmentListByDepartmentCode', key)
    userStore.setFilter('userOfDepartmentListPageIndex', 0)
    loadingAnimationStore.setTableLoading(true)
    userStore.getUserListByDepartment().then(response => {
      setUserOfDepartmentData(response.data)
      loadingAnimationStore.setTableLoading(false)
    })
  }

  const onChangePagination = (pageIndex, pageSize) => {
    userStore.setFilter('userOfDepartmentListPageIndex', pageIndex - 1)
    userStore.setFilter('userOfDepartmentListPageSize', pageSize)
    loadingAnimationStore.setTableLoading(true)
    userStore
      .getUserListByDepartment()
      .then(response => {
        setUserOfDepartmentData(response.data)
        loadingAnimationStore.setTableLoading(false)
      })
      .catch(() => {
        loadingAnimationStore.setTableLoading(false)
        message.warning('Lỗi lấy thông tin người dùng trong phòng ban!').then()
      })
  }

  useEffect(() => {
    departmentStore.clearDepartmentList()
    loadingAnimationStore.showSpinner(true)
    departmentStore
      .getAllDepartmentList()
      .then(resAllDepartmentList => {
        userStore.setFilter(
          'userOfDepartmentListByDepartmentCode',
          resAllDepartmentList.data[0].code
        )
        userStore
          .getUserListByDepartment()
          .then(response => {
            setUserOfDepartmentData(response.data)
            loadingAnimationStore.showSpinner(false)
          })
          .catch(() => {
            loadingAnimationStore.showSpinner(false)
            message.warning('Lỗi lấy thông tin phòng ban!').then()
          })
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
        message.warning('Lỗi lấy thông tin phòng ban!').then()
      })
  }, [])

  return (
    <TabsDepartmentWrapper
      tabPosition={'left'}
      onChange={onChangeTabDepartment}>
      {departmentList?.map(department => {
        return (
          <TabPane
            tab={
              <div
                className={
                  disableDepartmentList.includes(department.code)
                    ? 'disable'
                    : ''
                }>
                <TextWrap>
                  {trimOverlengthString(department.name, 200)}
                </TextWrap>
                <Tooltip
                  title={
                    disableDepartmentList.includes(department.code)
                      ? 'Phòng ban này đã tồn tại trong danh sách khác'
                      : null
                  }>
                  <Checkbox
                    checked={departmentSelectedList
                      .map(
                        departmentSelected =>
                          departmentSelected.department_code ||
                          departmentSelected.id
                      )
                      .includes(department.code)}
                    onChange={e =>
                      changeSelectDepartment(
                        e,
                        department.name,
                        department.code
                      )
                    }
                    disabled={disableDepartmentList.includes(department.code)}
                  />
                </Tooltip>
              </div>
            }
            key={department.code}>
            <TableComponent
              rowKey={record => record.code + department.code}
              columns={tableColumn}
              dataSource={userOfDepartmentData}
              scroll={{ x: 1180 }}
              loading={loadingAnimationStore.tableLoading}
              pagination={false}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: '15px 0',
              }}>
              <Pagination
                onChange={onChangePagination}
                hideOnSinglePage={true}
                total={userOfDepartmentListTotalCount}
                pageSize={userOfDepartmentListPageSize}
                current={userOfDepartmentListPageIndex + 1}
                showSizeChanger={false}
                showLessItems
              />
            </div>
          </TabPane>
        )
      })}
    </TabsDepartmentWrapper>
  )
}

TableSelectDepartment.propTypes = {}

export default memo(
  inject(
    'departmentStore',
    'userStore',
    'selectUserStore',
  )(observer(TableSelectDepartment)),
)

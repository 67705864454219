import React, { useEffect, useState } from 'react'
import { blue, grayPrimary } from '../../color'
import {
  DeleteOutlined,
  LineChartOutlined,
  TrophyFilled,
  TrophyOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import './KpiEditTargetPage.css'
import { Button, InputNumber, Popconfirm, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import { useHistory, useParams } from 'react-router-dom'
import { ACL_ACTION_TYPE } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'

const TargetList = props => {
  let history = useHistory()
  const { kpi_quarter_code } = useParams()

  const { kpiStore, authenticationStore, onDelete } = props

  const { targetListByKpiId, kpiDetail, originTargetListByKpiId } = kpiStore

  const [validateInputExpectWeight, setValidateInputExpectWeight] = useState({
    correct: true,
    targetId: null,
  })

  useEffect(() => {
    kpiStore.quarterCode = kpi_quarter_code
  }, [kpi_quarter_code])

  useEffect(() => {
    kpiStore.setAllowSaveTarget(validateInputExpectWeight.correct)
  }, [validateInputExpectWeight])

  const onChangeExpectWeight = (value, targetId) => {
    const newTargetList = [...targetListByKpiId]
    const index = newTargetList.findIndex(item => item.code === targetId)
    const originExpectWeight = originTargetListByKpiId[index].expect_weight
    const originActualWeight = originTargetListByKpiId[index].actual_weight

    const newActualWeight =
      originExpectWeight > 0
        ? Math.round(
            ((originActualWeight * value) / originExpectWeight) * 100
          ) / 100
        : 0
    newTargetList[index] = {
      ...newTargetList[index],
      expect_weight: value,
      actual_weight: newActualWeight,
    }

    setValidateInputExpectWeight({
      ...validateInputExpectWeight,
      targetId: targetId,
    })
    kpiStore.setTargetListByKpiId(newTargetList)
  }

  useEffect(() => {
    const expectWeightTotal = targetListByKpiId.reduce(
      (previousValue, currentValue) => {
        return previousValue + currentValue.expect_weight
      },
      0
    )
    const actualWeightTotal = targetListByKpiId.reduce(
      (previousValue, currentValue) => {
        return previousValue + currentValue.actual_weight
      },
      0
    )
    if (expectWeightTotal === 100) {
      setValidateInputExpectWeight({
        ...validateInputExpectWeight,
        correct: true,
      })
    } else {
      setValidateInputExpectWeight({
        ...validateInputExpectWeight,
        correct: false,
      })
    }
    kpiStore.setActualWeightKpi(
      Math.round(((actualWeightTotal * kpiDetail.expect_weight) / 100) * 100) /
        100
    )
  }, [targetListByKpiId, kpiDetail])

  return (
    <div>
      <div className={'header-target-list'}>
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 6,
            fontSize: 14,
          }}>
          <TrophyFilled
            style={{ fontSize: 16, color: '#ffec3d', fontWeight: 700 }}
          />
          Danh sách mục tiêu
        </div>
        <div style={{ display: 'flex', gap: 10 }}>
          <div style={{ width: 120, textAlign: 'center', lineHeight: '22px' }}>
            <LineChartOutlined
              style={{ fontSize: 16, marginRight: 4, color: '#ff6870' }}
            />
            Tỉ trọng cần đạt
          </div>
          <div style={{ width: 120, textAlign: 'center', lineHeight: '22px' }}>
            <LineChartOutlined
              style={{ fontSize: 16, marginRight: 4, color: '#52c41a' }}
            />
            Tỉ trọng đạt được
          </div>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.delete__KPI}>
            <div style={{ width: 30, textAlign: 'center', lineHeight: '22px' }}>
              Xóa
            </div>
          </AccessControlAction>
        </div>
      </div>
      {targetListByKpiId.map(target => (
        <div className={'target-edit-item'} key={target.code}>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={e => {
              e.stopPropagation()
              history.push(
                `/kpi/target/view/${kpi_quarter_code}/${kpiDetail.code}/${target.code}`
              )
            }}>
            <TrophyOutlined
              style={{ marginRight: 6, color: blue, fontSize: 14 }}
            />
            {target.name}
          </div>
          <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 6,
              }}>
              <InputNumber
                min={0}
                max={100}
                step={5}
                addonAfter="%"
                formatter={value => `${value}`}
                // parser={value => value.replace('%', '')}
                style={{
                  width: 86,
                  color: '#f5222d',
                  borderColor: `${
                    !validateInputExpectWeight.correct &&
                    target.code !== validateInputExpectWeight.targetId
                      ? '#fa8c16'
                      : '#d9d9d9'
                  }`,
                }}
                value={target.expect_weight}
                onChange={value =>
                  onChangeExpectWeight(value ?? 0, target.code)
                }
              />
              <Tooltip
                title={
                  'Tổng số tỉ trọng cần đạt của các mục tiêu phải bằng 100%!'
                }>
                <WarningOutlined
                  style={{
                    color: '#fa8c16',
                    marginRight: 6,
                    opacity: `${
                      !validateInputExpectWeight.correct &&
                      target.code !== validateInputExpectWeight.targetId
                        ? 1
                        : 0
                    }`,
                  }}
                />
              </Tooltip>
            </div>
            <div
              style={{
                width: 120,
                textAlign: 'center',
                fontWeight: 500,
                color: '#52c41a',
              }}>
              {target.actual_weight}%
            </div>

            <AccessControlAction aclActionType={ACL_ACTION_TYPE.delete__KPI}>
              <Tooltip title={'Xóa'}>
                <Popconfirm
                  placement="topRight"
                  title={'Bạn có chắc chắn muốn xóa mục tiêu này không?'}
                  onConfirm={() => {
                    onDelete(target)
                  }}
                  okText="Xóa"
                  okType={'danger'}
                  cancelText="Không">
                  <Button
                    block
                    size={'small'}
                    danger
                    type="text"
                    style={{ fontWeight: 500, width: 30 }}
                    icon={<DeleteOutlined style={{ color: 'f5222d' }} />}
                  />
                </Popconfirm>
              </Tooltip>
            </AccessControlAction>
          </div>
        </div>
      ))}

      <div
        style={{
          fontStyle: 'italic',
          color: grayPrimary,
          marginTop: 12,
          paddingLeft: 6,
        }}>
        <WarningOutlined
          style={{ color: '#fa8c16', marginRight: 6, fontSize: 16 }}
        />
        <span style={{ fontWeight: 500, marginRight: 10 }}>Lưu ý:</span> Tổng số
        tỉ trọng cần đạt của các mục tiêu phải bằng 100%!
      </div>
    </div>
  )
}

TargetList.propTypes = {}

export default inject('kpiStore', 'authenticationStore')(observer(TargetList))

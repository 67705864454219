import { Card } from 'antd'
import styled from 'styled-components'

export const ApprovalLevel = styled(Card)`
  z-index: 99999;
  margin-bottom: 12px;

  .ant-card-body {
    padding: 12px;

    .approval-level__content {
      display: flex;
      flex-wrap: wrap;

      dt,
      dd {
        //padding: 0.5rem 0;
        padding-bottom: 14px;
        padding-top: 14px;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }

      dt {
        width: 95px;
        font-weight: 500;

        &:after {
          display: inline-block;
          content: ':';
        }

        &:nth-last-child(2) {
          border-bottom: none;
        }
      }
      dd {
        width: calc(100% - 120px);
        word-break: break-all;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .approval-level__action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
  }
`

export const AddButton = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: underline;
  justify-content: flex-end;
  margin-top: 8px;
`

import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ChuTruongCommentRequest = {
  getPolicyComment: taskCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/policy/${taskCode}/comments`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  postPolicyComment: (taskCode, data) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/policy/${taskCode}/comments`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  deletePolicyComment: (taskCode, commentId) =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/policy/${taskCode}/comments/${commentId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}

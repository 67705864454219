import React, { useEffect, useState } from 'react'
import { blue, grayPrimary } from '../../color'
import {
  DeleteOutlined,
  LineChartOutlined,
  StarFilled,
  StarOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { Button, Empty, InputNumber, Popconfirm, Tooltip } from 'antd'
import './KpiEditSubTargetPage.css'
import SubTargetWorkList from './SubTargetWorkList'
import { inject, observer } from 'mobx-react'
import { ACL_ACTION_TYPE } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'

const SubTargetList = props => {
  const { authenticationStore, kpiStore, onDelete } = props
  const {
    subTargetListByTargetId,
    targetKpiDetail,
    originSubTargetListByTargetId,
  } = kpiStore

  const [validateInputExpectWeight, setValidateInputExpectWeight] = useState({
    correct: true,
    subTargetId: null,
  })

  useEffect(() => {
    kpiStore.setAllowSaveSubTarget(validateInputExpectWeight.correct)
  }, [validateInputExpectWeight])

  useEffect(() => {
    const expectWeightTotal = subTargetListByTargetId.reduce(
      (previousValue, currentValue) => {
        return previousValue + currentValue.expect_weight
      },
      0
    )
    const actualWeightTotal = subTargetListByTargetId.reduce(
      (previousValue, currentValue) => {
        return previousValue + currentValue.actual_weight
      },
      0
    )
    if (expectWeightTotal === 100) {
      setValidateInputExpectWeight({
        ...validateInputExpectWeight,
        correct: true,
      })
    } else {
      setValidateInputExpectWeight({
        ...validateInputExpectWeight,
        correct: false,
      })
    }
    kpiStore.setActualWeightTarget(
      Math.round(
        ((actualWeightTotal * targetKpiDetail.expect_weight) / 100) * 100
      ) / 100
    )
  }, [subTargetListByTargetId, targetKpiDetail])

  const onChangeExpectWeight = (value, subTargetId) => {
    const newSubTargetList = [...subTargetListByTargetId]
    const index = newSubTargetList.findIndex(item => item.code === subTargetId)
    const originExpectWeight =
      originSubTargetListByTargetId[index].expect_weight
    const originActualWeight =
      originSubTargetListByTargetId[index].actual_weight
    const newActualWeight =
      originExpectWeight > 0
        ? Math.round(
            ((originActualWeight * value) / originExpectWeight) * 100
          ) / 100
        : 0
    newSubTargetList[index] = {
      ...newSubTargetList[index],
      expect_weight: value,
      actual_weight: newActualWeight,
    }
    setValidateInputExpectWeight({
      ...validateInputExpectWeight,
      subTargetId: subTargetId,
    })
    kpiStore.setSubTargetListByTargetId(newSubTargetList)
  }

  const handleDeleteSubTarget = () => {}

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 20,
          padding: '12px 16px',
          backgroundColor: blue,
          color: '#fff',
          fontWeight: 500,
        }}>
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 6,
            fontSize: 14,
          }}>
          <StarFilled style={{ fontSize: 16, color: '#ffec3d' }} />
          Danh sách chỉ tiêu
        </div>
        <div style={{ display: 'flex', gap: 10 }}>
          <div style={{ width: 120, textAlign: 'center', lineHeight: '22px' }}>
            <LineChartOutlined
              style={{ fontSize: 16, marginRight: 4, color: '#ff6870' }}
            />
            Tỉ trọng cần đạt
          </div>
          <div style={{ width: 120, textAlign: 'center', lineHeight: '22px' }}>
            <LineChartOutlined
              style={{ fontSize: 16, marginRight: 4, color: '#52c41a' }}
            />
            Tỉ trọng đạt được
          </div>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.delete__KPI}>
            <div style={{ width: 30, textAlign: 'center', lineHeight: '22px' }}>
              Xóa
            </div>
          </AccessControlAction>
        </div>
      </div>
      {subTargetListByTargetId.length > 0 ? (
        subTargetListByTargetId.map(subTarget => (
          <div key={subTarget.code}>
            <div className={'sub-target-edit-item'}>
              <div style={{ flexGrow: 1 }}>
                <StarOutlined
                  style={{ fontSize: 14, color: blue, marginRight: 8 }}
                />
                {subTarget.name}
              </div>
              <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#f5222d',
                    gap: 6,
                  }}>
                  <InputNumber
                    min={0}
                    max={100}
                    step={5}
                    addonAfter="%"
                    formatter={value => `${value}`}
                    // parser={value => value.replace('%', '')}
                    style={{
                      width: 86,
                      color: '#f5222d',
                      borderColor: `${
                        (!validateInputExpectWeight.correct &&
                          subTarget.code !==
                            validateInputExpectWeight.subTargetId) ||
                        (!validateInputExpectWeight.correct &&
                          subTargetListByTargetId.length === 1)
                          ? '#fa8c16'
                          : '#d9d9d9'
                      }`,
                    }}
                    onChange={value =>
                      onChangeExpectWeight(value ?? 0, subTarget.code)
                    }
                    value={subTarget.expect_weight}
                  />

                  <Tooltip
                    title={
                      'Tổng số tỉ trọng cần đạt của các chỉ tiêu phải bằng 100%!'
                    }>
                    <WarningOutlined
                      style={{
                        color: '#fa8c16',
                        marginRight: 6,
                        opacity: `${
                          (!validateInputExpectWeight.correct &&
                            subTarget.code !==
                              validateInputExpectWeight.subTargetId) ||
                          (!validateInputExpectWeight.correct &&
                            subTargetListByTargetId.length === 1)
                            ? 1
                            : 0
                        }`,
                      }}
                    />
                  </Tooltip>
                </div>
                <div
                  style={{
                    width: 120,
                    textAlign: 'center',
                    color: '#52c41a',
                    fontWeight: 500,
                  }}>
                  {subTarget.actual_weight}%
                </div>
                <AccessControlAction
                  aclActionType={ACL_ACTION_TYPE.delete__KPI}>
                  <Tooltip title={'Xóa'}>
                    <Popconfirm
                      placement="topRight"
                      title={'Bạn có chắc chắn muốn xóa chỉ tiêu này không?'}
                      onConfirm={() => onDelete(subTarget)}
                      okText="Xóa"
                      okType={'danger'}
                      cancelText="Không">
                      <Button
                        block
                        size={'small'}
                        danger
                        type="text"
                        style={{ fontWeight: 500, width: 30 }}
                        icon={<DeleteOutlined style={{ color: 'f5222d' }} />}
                      />
                    </Popconfirm>
                  </Tooltip>
                </AccessControlAction>
              </div>
            </div>
            <SubTargetWorkList
              workList={subTarget.children || []}
              subTargetId={subTarget.code}
              onDelete={onDelete}
            />
          </div>
        ))
      ) : (
        <div style={{ margin: '40px 0' }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'Không có chỉ tiêu nào!'}
          />
        </div>
      )}
      <div
        style={{
          fontStyle: 'italic',
          color: grayPrimary,
          marginTop: 12,
          paddingLeft: 6,
        }}>
        <WarningOutlined style={{ color: '#fa8c16', marginRight: 6 }} />
        <span style={{ marginRight: 12, fontWeight: 500 }}>Lưu ý:</span>* Tổng
        số tỉ trọng cần đạt của các chỉ tiêu phải bằng 100%!
        <div style={{ marginLeft: 62 }}>
          * Tổng số tỉ trọng cần đạt của các công việc trong một chỉ tiêu phải
          bằng 100%!
        </div>
      </div>
    </div>
  )
}

SubTargetList.propTypes = {}

export default inject(
  'kpiStore',
  'authenticationStore',
)(observer(SubTargetList))

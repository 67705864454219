import React from 'react'
// Component
import { EmptyText, subStringAvatar } from '../../components/Common/CellText'
//Styled Component
import { OverviewWrapper, UserName } from './UserOverviewStyled'
// Ant design
import {
  Avatar,
  Row,
  Col,
  Modal
} from 'antd'
import {
  BankFilled,
  CalendarFilled,
  CrownFilled,
  HeartFilled,
  IdcardFilled,
  MailFilled,
  MobileFilled,
  PhoneFilled,
  SlackCircleFilled,
} from '@ant-design/icons'

// Other
import { blue } from '../../color'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import { apiUrl } from '../../config'

const styleIcon = {
  color: blue,
  fontSize: 14,
  marginTop: 5,
}
const nullText = <EmptyText>Chưa cập nhật</EmptyText>

const UserOverview = props => {
  const {
    aclStore,
    isVisibleUser,
    handleCancelViewUser,
    userStore,
  } = props

  const { aclAclGroupName } = aclStore
  const { selectedUser } = userStore
  
  const closeAndResetForm = () => {
    handleCancelViewUser()
  }

  return (
    <Modal
      getContainer={false}
      title={'Thông tin người dùng'}
      visible={isVisibleUser}
      width={700}
      footer={null}
      onCancel={closeAndResetForm}
      closable={true}
      >
      <OverviewWrapper>
        <div className={'avatar'}>
          <Avatar
            size={180}
            style={{ backgroundColor: blue }}
            src={selectedUser.imageId && `${apiUrl}/api/v1/images/${selectedUser.imageId}`}>
            {subStringAvatar(selectedUser?.name_uppercase)}
          </Avatar>
        </div>
        <div className={'overview-info'}>
          <UserName>
            <h2 style={{ marginBottom: 0, textTransform: 'capitalize' }}>
              {selectedUser?.name_lowercase || nullText}
              <span className={'username'}>({selectedUser?.username})</span>
            </h2>
          </UserName>
          <Row gutter={[20, 0]}>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className={'item-info'}>
                <MailFilled style={styleIcon} />
                <div>
                  <div>{selectedUser?.email || nullText}</div>
                  <span className={'desc'}>Email</span>
                </div>
              </div>
              <div className={'item-info'}>
                <IdcardFilled style={styleIcon} />
                <div>
                  <div>{selectedUser?.ma_nv || nullText}</div>
                  <span className={'desc'}>Mã nhân viên</span>
                </div>
              </div>
              <div className={'item-info'}>
                <PhoneFilled style={styleIcon} />
                <div>
                  <div>{selectedUser?.home_phone || nullText}</div>
                  <span className={'desc'}>Số máy nội bộ</span>
                </div>
              </div>
              <div className={'item-info'}>
                <MobileFilled style={styleIcon} />
                <div>
                  <div>{selectedUser?.phone || nullText}</div>
                  <span className={'desc'}>Điện thoại di động</span>
                </div>
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className={'item-info'}>
                <HeartFilled style={styleIcon} />
                <div>
                  <div>
                      {utils.getGenderName(selectedUser?.gender) || nullText}
                    </div>
                  <span className={'desc'}>Giới tính</span>
                </div>
              </div>
              <div className={'item-info'}>
                <BankFilled style={styleIcon} />
                <div>
                  <div>{selectedUser?.department?.name || nullText}</div>
                  <span className={'desc'}>Phòng ban</span>
                </div>
              </div>
              <div className={'item-info'}>
                <CrownFilled style={styleIcon} />
                <div>
                  <div>{selectedUser?.position?.name || nullText}</div>
                  <span className={'desc'}>Chức danh</span>
                </div>
              </div>
              <div className={'item-info'}>
                <CalendarFilled style={styleIcon} />
                <div>
                  <div>{nullText}</div>
                  <span className={'desc'}>Ngày sinh</span>
                </div>
              </div>
              <div className={'item-info'}>
                <SlackCircleFilled style={styleIcon} />
                <div>
                  <div>{aclAclGroupName}</div>
                  <span className={'desc'}>Phân quyền</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </OverviewWrapper>
    </Modal>
  )
}

UserOverview.propTypes = {}

export default inject(
  'commonStore',
  'loadingAnimationStore',
  'userStore',
  'aclStore',
)(observer(UserOverview))

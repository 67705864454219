import React from 'react'
import { Button } from 'antd'
import { SalaryListFilterWrapper } from './SalaryListPageStyled'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'

const SalaryListFilter = props => {
  const history = useHistory()

  return (
    <SalaryListFilterWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <h3
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: '#e67e22',
          }}>
          Danh sách đơn trình duyệt lương
        </h3>
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__SALARY}>
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={() => history.push('/salary/create')}>
            Tạo trình duyệt lương
          </Button>
        </AccessControlAction>
      </div>
    </SalaryListFilterWrapper>
  )
}

SalaryListFilter.propTypes = {}

export default SalaryListFilter

import styled from 'styled-components'

export const TabContainer = styled.div`
  .ant-tabs-nav {
    margin-bottom: 0px;
  }
`

export const UserTabPanelContainer = styled.div``

export const TabPanelContainer = styled.div``

import styled from 'styled-components'
import { Comment } from 'antd'

export const TableSelectUserWrapper = styled.div`
  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-row.disabled-row {
    background-color: #ececec;
    pointer-events: none;
    position: relative;
    &::after {
      transition: 0.3s;
      position: absolute;
      right: 36%;
      border-radius: 5px;
      padding: 10px 20px;
      transform: translateY(21px);
      background-color: white;
      color: #f5222d;
      content: 'Người dùng này đã tồn tại ở danh sách khác.';
    }
  }
`

export const PaginationWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const NameUser = styled.span`
  font-weight: 500;
  text-transform: capitalize;
  color: #000;
`

export const CommentWrapper = styled(Comment)`
  .ant-comment-inner {
    display: flex;
    align-items: center;
    font-size: 15px;

    img {
      width: 36px;
      height: 36px;
      line-height: 36px;
    }
  }
`

import React, { useEffect, useState } from 'react'
// Ant design
import { Button, Tabs, Tag } from 'antd'
// Mobx
import { inject, observer } from 'mobx-react'

import TableSelectUser from '../TableSelectUser'
import TableSelectDepartment from '../TableSelectDepartment'
import TableSelectUserGroup from '../TableSelectUserGroup'
import {
  ModalWrapper,
  SelectedWrapper,
  TitleModal,
} from './SelectUserModalStyled'

const { TabPane } = Tabs

/** @deprecated not use **/
const SelectUserModal = props => {
  const {
    selectUserStore,
    isVisibleSelectUserModal,
    handleCloseSelectUserModal,
    handleSubmitSelect,
    selectUserModalType,
    onChangeTabs,
    activeTabKey,
    renderTitleModal,
    disabled,
  } = props

  const { selectedListData } = selectUserStore

  const [userSelectedList, setUserSelectedList] = useState([])
  const [departmentSelectedList, setDepartmentSelectedList] = useState([])
  const [groupSelectedList, setGroupSelectedList] = useState([])

  useEffect(() => {
    // Nếu chưa có data hoăc chưa có type modal (chưa mở modal)
    if (!selectedListData || !selectUserModalType) return
    // set list user, department, group theo đúng các key của obj data đã tự định nghĩa ở các trang sử dụng selectUserModal
    // các key này phải trung với tên của các modal tương ứng
    setUserSelectedList([...selectedListData[selectUserModalType].user])
    setDepartmentSelectedList([
      ...selectedListData[selectUserModalType].department,
    ])
    setGroupSelectedList([...selectedListData[selectUserModalType].group])
  }, [selectUserModalType, selectedListData])

  // Click vào mỗi checkbox chọn hoặc bỏ chọn user
  const rowSelection = {
    onSelect: user => {
      const userCodeList = userSelectedList.map(
        userSelected => userSelected.username
      )
      // Nếu đã chọn -> click bỏ chọn user
      // Khi chọn user sẽ xóa danh sách department và group
      if (userCodeList.includes(user.username)) {
        selectUserStore.setSelectedListData({
          ...selectedListData,
          [selectUserModalType]: {
            user: selectedListData[selectUserModalType].user.filter(
              el => el.username !== user.username
            ),
            department: [],
            group: [],
          },
        })
      }
      // Nếu chưa chọn -> click chọn user
      // Khi chọn user sẽ xóa danh sách department và group
      else {
        selectUserStore.setSelectedListData({
          ...selectedListData,
          [selectUserModalType]: {
            user: [
              ...selectedListData[selectUserModalType].user,
              {
                user_code: user.code,
                user_name: user.name_uppercase,
                username: user.username,
              },
            ],
            department: [],
            group: [],
          },
        })
      }
    },
    selectedRowKeys: userSelectedList.map(
      userSelected => userSelected.username
    ),
  }

  // Click vào mỗi row chọn hoặc bỏ chọn user
  const onSelectUser = user => {
    const userCodeList = userSelectedList.map(
      userSelected => userSelected.username
    )
    // Nếu đã chọn -> click bỏ chọn user
    // Khi chọn user sẽ xóa danh sách department và group
    if (userCodeList.includes(user.username)) {
      selectUserStore.setSelectedListData({
        ...selectedListData,
        [selectUserModalType]: {
          user: selectedListData[selectUserModalType].user.filter(
            el => el.username !== user.username
          ),
          department: [],
          group: [],
        },
      })
    }
    // Nếu chưa chọn -> click chọn user
    // Khi chọn user sẽ xóa danh sách department và group
    else {
      selectUserStore.setSelectedListData({
        ...selectedListData,
        [selectUserModalType]: {
          user: [
            ...selectedListData[selectUserModalType].user,
            {
              user_code: user.code,
              user_name: user.name_uppercase,
              username: user.username,
            },
          ],
          department: [],
          group: [],
        },
      })
    }
  }

  // Bỏ chọn user trong "danh sách người dùng đã chọn"
  const unselectUser = username => {
    selectUserStore.setSelectedListData({
      ...selectedListData,
      [selectUserModalType]: {
        user: selectedListData[selectUserModalType].user.filter(
          user => user.username !== username
        ),
        department: [],
        group: [],
      },
    })
  }

  // Bỏ chọn department trong "danh sách phòng ban đã chọn"
  const unselectDepartment = department_code => {
    selectUserStore.setSelectedListData({
      ...selectedListData,
      [selectUserModalType]: {
        user: [],
        department: selectedListData[selectUserModalType].department.filter(
          department => department.department_code !== department_code
        ),
        group: [],
      },
    })
  }

  // Bỏ chọn group trong "danh sách phòng ban đã chọn"
  const unselectGroup = group_id => {
    selectUserStore.setSelectedListData({
      ...selectedListData,
      [selectUserModalType]: {
        user: [],
        department: [],
        group: selectedListData[selectUserModalType].group.filter(
          group => group.group_id !== group_id
        ),
      },
    })
  }

  // Chọn và bỏ chọn group (click checkbox)
  const handleSelectGroup = (e, groupData) => {
    // click -> chọn group và xóa danh sách user và department
    if (e.target.checked) {
      selectUserStore.setSelectedListData({
        ...selectedListData,
        [selectUserModalType]: {
          user: [],
          department: [],
          group: [...selectedListData[selectUserModalType].group, groupData],
        },
      })
    }
    // click -> bỏ chọn group và xóa danh sách user và department
    else {
      selectUserStore.setSelectedListData({
        ...selectedListData,
        [selectUserModalType]: {
          user: [],
          department: [],
          group: selectedListData[selectUserModalType].group.filter(
            group => group.group_id !== groupData.group_id
          ),
        },
      })
    }
  }

  // Chọn và bỏ chọn phòng ban (Click checkbox)
  const handleSelectDepartment = (e, departmentData) => {
    // nếu đã chọn -> click bỏ chọn và xóa danh sách user và group
    if (!e.target.checked) {
      selectUserStore.setSelectedListData({
        ...selectedListData,
        [selectUserModalType]: {
          user: [],
          department: selectedListData[selectUserModalType].department.filter(
            department =>
              department.department_code !== departmentData.department_code
          ),
          group: [],
        },
      })
    }
    // nếu chưa chọn -> click chọn và xóa danh sách user và group
    else {
      selectUserStore.setSelectedListData({
        ...selectedListData,
        [selectUserModalType]: {
          user: [],
          department: [
            ...selectedListData[selectUserModalType].department,
            departmentData,
          ],
          group: [],
        },
      })
    }
  }

  return (
    <ModalWrapper
      title={
        <TitleModal>
          <div>
            <span>{renderTitleModal()}</span>
          </div>

          <div>
            <Button onClick={handleCloseSelectUserModal}>Huỷ bỏ</Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              onClick={handleSubmitSelect}>
              Đồng ý
            </Button>
          </div>
        </TitleModal>
      }
      visible={isVisibleSelectUserModal}
      footer={null}
      width={1200}
      style={{ top: 20 }}>
      <SelectedWrapper>
        {userSelectedList.length ||
        groupSelectedList.length ||
        departmentSelectedList.length ? (
          <span className={'label-list'}>
            Danh sách{' '}
            {userSelectedList.length
              ? 'người dùng'
              : groupSelectedList.length
              ? 'nhóm người dùng'
              : 'phòng ban'}{' '}
            đã chọn:
          </span>
        ) : null}
        {userSelectedList.map(userSelected => (
          <Tag
            key={userSelected.username}
            closable
            color={'#0fbcf9'}
            onClose={() => unselectUser(userSelected.username)}>
            {userSelected.user_name}
          </Tag>
        ))}
        {departmentSelectedList.map(departmentSelected => (
          <Tag
            key={departmentSelected.department_code}
            closable
            color={'#10ac84'}
            onClose={() =>
              unselectDepartment(departmentSelected.department_code)
            }>
            {departmentSelected.department_name}
          </Tag>
        ))}
        {groupSelectedList.map(group => (
          <Tag
            key={group.group_id}
            closable
            color={'#f39c12'}
            onClose={() => unselectGroup(group.group_id)}>
            {group.group_name}
          </Tag>
        ))}
      </SelectedWrapper>
      <Tabs
        defaultActiveKey={'user'}
        onChange={onChangeTabs}
        activeKey={activeTabKey}>
        <TabPane tab="Người dùng" key="user" forceRender>
          <TableSelectUser
            rowSelection={rowSelection}
            onSelectUser={onSelectUser}
          />
        </TabPane>
        <TabPane tab="Phòng ban" key="department" disabled={disabled}>
          <TableSelectDepartment
            departmentSelectedList={departmentSelectedList}
            handleSelectDepartment={handleSelectDepartment}
          />
        </TabPane>
        <TabPane tab="Nhóm người dùng" key="user-group" disabled={disabled}>
          <TableSelectUserGroup
            groupSelectedList={groupSelectedList}
            handleSelectGroup={handleSelectGroup}
          />
        </TabPane>
      </Tabs>
    </ModalWrapper>
  )
}

SelectUserModal.propTypes = {}

export default inject(
  'userStore',
  'userGroupStore',
  'loadingAnimationStore',
  'selectUserStore',
)(observer(SelectUserModal))

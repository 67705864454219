import styled from 'styled-components'
import { Button } from 'antd'

export const SearchBar = styled.div`
  width: 300px;
`
export const FilterButton = styled(Button)``
export const TableFooterActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
`
export const StatusTag = styled.span`
  color: ${props => (props.active ? '#2196F3' : '#F44336')};
  background-color: ${props => (props.active ? '#eaf4fe' : '#feecec')};
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 30px;
  width: 120px;
  height: 30px;
`

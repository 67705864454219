import { action, observable } from 'mobx'
import { SystemOptionRequest } from '../requests/SystemOptionRequest'

class ConfigDefaultGroupStore {
  @observable isLoading = false
  @observable preSelectedGroupId = null

  @action getDefaultGroupByKey = async key => {
    this.isLoading = true

    const response = await SystemOptionRequest.getDefaultGroup(key)

    this.preSelectedGroupId = response.data.group_id

    this.isLoading = false
  }

  @action setDefaultGroupByKey = async (key, groupId) => {
    await SystemOptionRequest.setDefaultGroup(key, groupId)
  }

  clear = () => {
    this.isLoading = false
    this.preSelectedGroupId = null
  }
}

export default new ConfigDefaultGroupStore()

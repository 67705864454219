import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ProposalAdvanceRequest = {
  getProposalAdvanceList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/proposals/current-user?type=ADVANCE`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),

  getDefaultApprovalConfig: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/approval_template/apply/ADVANCE`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  createProposalAdvance: payload =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/proposal-advance`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }),

  getProposalAdvanceDetail: proposalCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/proposal-advance/${proposalCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  approveProposalAdvance: (proposalCode, payload) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/proposals/${proposalCode}/approve`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }),

  getProposalAdvanceComment: proposalCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/proposals/${proposalCode}/comments`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  deleteProposalAdvanceComment: commentId =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/proposals/comments/${commentId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  createProposalAdvanceComment: (proposalCode, commentData) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/proposals/${proposalCode}/comments`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: commentData,
    }),
}

import React, { useEffect, useState } from 'react'
import { ModuleChildrenItemWrapper } from './ModulePageStyled'
import Checkbox from 'antd/es/checkbox/Checkbox'
import { inject, observer } from 'mobx-react'

const ModuleChildrenItem = props => {
  const { last, moduleStore, record } = props

  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(record.status)
  }, [record])

  const handleChangeCheckBox = () => {
    setIsChecked(!isChecked)
    const newModuleList = [...moduleStore.moduleUpdatedList]
    const i = newModuleList.findIndex(item => item.code === record.parent_code)
    if (i !== -1) {
      const j = newModuleList[i].sub_modules.findIndex(
        item => item.code === record.code
      )
      if (j !== -1) {
        newModuleList[i].sub_modules[j].status = !isChecked
      }
    }
    moduleStore.setModuleUpdatedList(newModuleList)
  }

  return (
    <ModuleChildrenItemWrapper
      checked={isChecked}
      onClick={handleChangeCheckBox}>
      <span className={`module-name ${last ? 'module-last' : ''}`}>
        {record.name}
      </span>
      <Checkbox checked={isChecked} onChange={handleChangeCheckBox} />
    </ModuleChildrenItemWrapper>
  )
}

ModuleChildrenItem.propTypes = {}

export default inject('moduleStore')(observer(ModuleChildrenItem))

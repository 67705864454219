// Cơ quan ban hành
import { action, observable } from 'mobx'
// Request
import { AuthorityRequest } from '../requests/AuthorityRequest'
import { DOCUMENT_TYPE } from '../constants'

class AuthorityStore {
  /** Authority Issueds */
  @observable authorityIssuedsList = []

  @observable authorityIssuedsListOrigin = []

  @observable authorityIssuedsQueryParams = {
    type: DOCUMENT_TYPE.INCOMING,
    key: null,
  }

  @action setAuthorityIssuedsQueryParams = payload => {
    this.authorityIssuedsQueryParams = payload
  }

  @action setAuthorityIssuedsList = payload => {
    this.authorityIssuedsList = payload
  }

  @action getAuthorityIssueds = () => {
    return new Promise((resolve, reject) => {
      AuthorityRequest.getAuthorityIssueds(this.authorityIssuedsQueryParams)
        .then(response => {
          this.authorityIssuedsList = response.data
          this.authorityIssuedsListOrigin = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateAuthority = (id, payload) => {
    return new Promise((resolve, reject) => {
      AuthorityRequest.updateAuthority(id, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createAuthority = payload => {
    return new Promise((resolve, reject) => {
      AuthorityRequest.createAuthority(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteAuthority = id => {
    return new Promise((resolve, reject) => {
      AuthorityRequest.deleteAuthority(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearAuthorityIssuedsList = () => {
    this.authorityIssuedsList.length = 0
    this.authorityIssuedsListOrigin.length = 0
    this.authorityIssuedsQueryParams = {
      type: DOCUMENT_TYPE.INCOMING,
      key: null,
    }
  }

  /** Authority Levels */
  @observable authorityLevelsList = []
  @action getAuthorityLevels = () => {
    return new Promise((resolve, reject) => {
      AuthorityRequest.getAuthorityLevels()
        .then(response => {
          console.log(response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new AuthorityStore()

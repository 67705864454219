import { Checkbox, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { TaskRequest } from '../../requests/TaskRequest'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import taskStore from '../../stores/taskStore'

const CheckboxTaskTagPicker = props => {
  const [tagList, setTagList] = useState([])
  const { Option } = Select

  const [selectedTags, setSelectedTags] = useState([])
  const [isDirty, setIsDirty] = useState(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  useEffect(() => {
    setIsDirty(true)
  }, [selectedTags.join()])

  useEffect(() => {
    if (taskStore.missionQueryParams.tag) {
      setSelectedTags(taskStore.missionQueryParams.tag)
    }
  }, [taskStore.missionQueryParams.tag])

  useEffect(() => {
    ;(async () => {
      await TaskRequest.getTagList().then(res => {
        setTagList(
          res.data.map(tag => ({
            code: tag.code,
            name: tag.name,
            type: tag.type,
          }))
        )
      })
    })()
  }, [])

  const onChange = values => {
    console.log('CheckboxTaskTagPicker', values)
    props.onChangePicker(values)
  }

  return (
    <Checkbox.Group
      defaultValue={props.defaultValue}
      options={tagList.map(item => {
        return {
          label: item.name,
          value: item.code,
        }
      })}
      onChange={onChange}
    />
  )
}

export default inject('taskStore')(observer(CheckboxTaskTagPicker))

CheckboxTaskTagPicker.propTypes = {
  triggerChangeOnBlur: PropTypes.bool,
  onChangePicker: PropTypes.func,
  defaultValue: PropTypes.arrayOf(PropTypes.string) || PropTypes.string,
  value: PropTypes.array,
}

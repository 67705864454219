import styled from 'styled-components'
import { Comment, Tabs } from 'antd'

export const ListUserGroupWrapper = styled.div`
  margin-top: 1rem;

  .ant-collapse-borderless {
    background-color: transparent;

    > .ant-collapse-item {
      border-bottom-color: #f0f0f0;
    }
  }

  .ant-collapse-item-active {
    background: #cddaf4;

    a {
      color: #1890ff !important;
    }
  }

  .ant-collapse-header {
    line-height: 27px !important;

    &:hover {
      transition: 0.4s;
      background-color: #cddaf4;
    }
  }
`

export const TabUserGroupWrapper = styled(Tabs)`
  .ant-tabs-tabpane {
    padding-left: 10px !important;
  }

  .ant-tabs-nav {
    max-height: 75vh;
  }

  .ant-tabs-tab-active {
    background-color: #fafafa;

    a {
      color: #1890ff !important;
    }
  }

  .ant-tabs-tab:hover {
    transition: 0.3s;
    background-color: #fafafa;
  }

  .ant-tabs-tab {
    padding-right: 10px;

    a {
      display: inline-block !important;
      text-align: left;
    }

    .ant-tabs-tab-btn {
      display: flex;
      align-items: center;
    }
  }
`

export const CommentWrapper = styled(Comment)`
  .ant-comment-inner {
    display: flex;
    align-items: center;
    font-size: 15px;

    img {
      width: 36px;
      height: 36px;
      line-height: 36px;
    }
  }
`

import { action, observable } from 'mobx'
import { KpiQuarterRequest } from '../../requests/KpiQuarterRequest'

class KpiQuarterPageStore {
  @observable list = []
  @observable isLoading = false
  @observable page = 0
  @observable limit = 10
  @observable total = 0
  @observable keyword = null

  @action setQueryParams = (param, value) => {
    this[param] = value
  }

  load = async () => {
    this.isLoading = true

    let response = await KpiQuarterRequest.getList(this.page, this.limit)
    this.list = response.data.data
    this.total = response.data.total_count

    this.isLoading = false
  }

  create = async name => {
    this.isLoading = true

    await KpiQuarterRequest.create(name)
    await this.load()
  }

  delete = async kpiQuarterCode => {
    this.isLoading = true

    await KpiQuarterRequest.delete(kpiQuarterCode)
    await this.load()
  }

  clearStore = () => {
    this.list = []
    this.isLoading = false
    this.page = 0
    this.limit = 10
    this.total = 0
    this.keyword = null
  }
}

export default new KpiQuarterPageStore()

import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const PositionRequest = {
  getPositionList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/positions`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  create: position =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/position/`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: position,
    }),
  update: (code, position) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/position/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: position,
    }),
  delete: code =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/position/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  sort: codes =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/positions/sort`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        data: codes,
      },
    }),
}

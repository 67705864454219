import React from 'react'
import { Pagination } from 'antd'
import { inject, observer } from 'mobx-react'

const stylePagination = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 20,
  marginBottom: 10,
}

const ProposalAdvanceListPagination = props => {
  const { proposalAdvanceStore } = props

  const handleChangePagination = (page, size) => {
    proposalAdvanceStore.setQueryParams('filterPage', page - 1)
    proposalAdvanceStore.setQueryParams('filterSize', size)
  }
  return (
    <div style={stylePagination}>
      <Pagination
        current={proposalAdvanceStore.filterPage + 1}
        pageSize={proposalAdvanceStore.filterSize}
        total={proposalAdvanceStore.proposalAdvanceTotal}
        onChange={handleChangePagination}
      />
    </div>
  )
}

ProposalAdvanceListPagination.propTypes = {}
export default inject('proposalAdvanceStore')(
  observer(ProposalAdvanceListPagination)
)

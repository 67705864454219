import styled from 'styled-components'
import { blue } from '../../color'

export const PopoverList = styled.ul`
  ::-webkit-scrollbar {
    width: 4px;
  }
  min-width: 300px;
  max-width: 450px;
  list-style: none;
  max-height: 600px;
  margin: -1rem;
  padding: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 12px;

  .popover-head {
    display: flex;
    justify-content: space-between;
    margin: -1rem -1rem 10px -1rem;
    padding: 10px 15px;
    border-bottom: 1px solid #f2f2f2;
    color: ${blue};
    column-gap: 20px;
    background-color: #f0f0f0;
  }

  li {
    border-bottom: 1px solid #f0f0f0;
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;

    span {
      flex-grow: 1;
    }

    time {
      flex: 0 0 130px;
      width: 130px;
    }
  }
`

export const TagWrapper = styled.div`
  cursor: pointer;
  ul {
    li {
      text-transform: capitalize;
      font-size: 10.5px;
    }
    span {
      transition: 0.3s;
      width: 20px;
      height: 20px;
    }
  }
  &:hover {
    ul {
      li {
        text-decoration: underline;
        border-color: #71a2d0;
      }
    }

    span {
      transform: scale(1.3);
    }
  }
`

export const PopoverTitle = styled.div`
  height: 32px;
  color: #fff;
  margin: -5px -16px;
  padding: 5px 16px;
  background-color: ${blue};
  text-align: center;
`

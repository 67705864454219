import React, { useState } from 'react'
import { MessageWrapper } from './ReplyMessageStyled'
import { Form, Input, message } from 'antd'
import moment from 'moment'
import { DATE_FORMAT_SLASH, HH_mm } from '../../constants'
import FileItem from '../Common/FileItemComponent/FileItem'
import utils from '../../utils'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../ContentBlockWrapper'
import { systemName } from '../../config'

const { TextArea } = Input
const ReplyMessage = props => {
  const {
    fileStore,
    loadingAnimationStore,
    messagesStore,
    replyMessages,
    authenticationStore,
    messageID,
    messageDetail,
  } = props
  const { currentUser } = authenticationStore

  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])

  const handleChangeFile = info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }

  const sendComment = async value => {
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    loadingAnimationStore.showSpinner(true)
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    try {
      const response = await Promise.all(batchUploadArr)
      uploadedFiles = response.map(response => response.data.fileId)
      const messageData = {
        content: value.content,
        file_ids: uploadedFiles,
        owner: currentUser?.userName,
        ownerName: currentUser?.fullName,
        systemInfo: systemName,
        parentId: messageDetail?.messageId,
      }
      await messagesStore.sendMessage(messageData)
      await messagesStore.getMessageById(messageID)
      setFileList([])
      form.resetFields()
      message.success('Gửi phản hồi thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <ContentBlockWrapper style={{ marginTop: 20 }}>
      {replyMessages &&
        replyMessages.length > 0 &&
        replyMessages.map(message => {
          return (
            <MessageWrapper key={message.messageId}>
              <p className="title">{`${utils.getNameInCapitalize(
                message.ownerName
              )} - ${moment(message.createdTime).format(
                DATE_FORMAT_SLASH
              )} - ${moment(message.createdTime).format(HH_mm)}`}</p>
              <p className="content">{message.content}</p>
              <div>
                {message.fileIds?.length > 0 &&
                  message.fileIds.map(file => (
                    <FileItem
                      key={file.fileId}
                      file_id={file.fileId}
                      file_name={file.name}
                      file_type={utils.getExtensionFile(file.name)}
                    />
                  ))}
              </div>
            </MessageWrapper>
          )
        })}
      {/*<CommentBlock>*/}
      {/*  <Form*/}
      {/*    form={form}*/}
      {/*    onFinish={sendComment}*/}
      {/*  >*/}
      {/*    <Form.Item name={'content'}>*/}
      {/*      <TextArea rows={4} placeholder='Nội dung phản hồi' />*/}
      {/*    </Form.Item>*/}
      {/*    {*/}
      {/*      fileList.length !== 0 &&*/}
      {/*      <UploadFileListWrapper>*/}
      {/*        {*/}
      {/*          fileList.map((file) =>*/}
      {/*            <UploadFileItem*/}
      {/*              key={file.uid}*/}
      {/*              file_id={file.uid}*/}
      {/*              file_name={file.name}*/}
      {/*              file_type={utils.getExtensionFile(file.name)}*/}
      {/*              handleRemoveFileFromUploadList={() => handleRemoveFileFromUploadList(file.uid)}*/}
      {/*            />,*/}
      {/*          )*/}
      {/*        }*/}
      {/*      </UploadFileListWrapper>*/}
      {/*    }*/}
      {/*    <FormButtonGroup>*/}
      {/*      <UploadWrapper>*/}
      {/*        <Upload*/}
      {/*          style={{ marginLeft: 4, marginRight: 4 }}*/}
      {/*          valuePropName={'fileList'}*/}
      {/*          fileList={fileList}*/}
      {/*          multiple={true}*/}
      {/*          onChange={handleChangeFile}*/}
      {/*          beforeUpload={() => false}*/}
      {/*          showUploadList={false}*/}
      {/*        >*/}
      {/*          <Button htmlType={'button'}>*/}
      {/*            <UploadOutlined /> Tải file*/}
      {/*          </Button>*/}
      {/*        </Upload>*/}
      {/*      </UploadWrapper>*/}
      {/*      <Button htmlType={'submit'} type={'primary'} style={{ padding: '4px 20px' }}>*/}
      {/*        Gửi phản hồi*/}
      {/*      </Button>*/}
      {/*    </FormButtonGroup>*/}
      {/*  </Form>*/}
      {/*</CommentBlock>*/}
    </ContentBlockWrapper>
  )
}

ReplyMessage.propTypes = {}

export default inject(
  'fileStore',
  'loadingAnimationStore',
  'messagesStore',
  'authenticationStore',
)(observer(ReplyMessage))

import React, { useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import validator from '../../validator'
import kpiStore from '../../stores/kpiStore'
import { KPI_TYPE } from '../../constants'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'

const KpiCreateModal = props => {
  const { isVisible, handleCloseModal } = props

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  const onFinish = async values => {
    setIsLoading(true)

    const { target_name } = values

    await kpiStore.createKpi(target_name, KPI_TYPE.KPI, null, null)

    kpiStore.getKpiList()

    closeAndResetForm()

    setIsLoading(false)

    message.success('Tạo KPI thành công')
  }

  const closeAndResetForm = () => {
    form.resetFields()
    handleCloseModal()
  }

  return (
    <Modal
      title="Thêm mới KPI"
      footer={null}
      closable={true}
      visible={isVisible}
      onCancel={handleCloseModal}>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'target_name'}
          rules={[
            { required: true, message: 'Vui lòng nhập tên KPI!' },
            { validator: validator.validateInputString },
          ]}
          label={'Tên KPI:'}>
          <Input placeholder={'Nhập tên KPI'} />
        </Form.Item>

        <Form.Item>
          <Button
            icon={<PlusOutlined />}
            loading={isLoading}
            htmlType={'submit'}
            type={'primary'}>
            Tạo
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

KpiCreateModal.propTypes = {}

export default KpiCreateModal

import React, { useEffect, useState } from 'react'
import { ModuleParentItemWrapper } from './ModulePageStyled'
import Checkbox from 'antd/es/checkbox/Checkbox'
import { inject, observer } from 'mobx-react'

const ModuleParentItem = props => {
  const { icon, moduleStore, record } = props

  const [isChecked, setIsChecked] = useState(false)
  useEffect(() => {
    setIsChecked(record.status)
  }, [record])

  const handleChangeCheckBox = () => {
    setIsChecked(!isChecked)
    const newModuleList = [...moduleStore.moduleUpdatedList]
    const i = newModuleList.findIndex(item => item.code === record.code)
    if (i !== -1) {
      newModuleList[i].status = !isChecked
    }
    moduleStore.setModuleUpdatedList(newModuleList)
  }

  return (
    <ModuleParentItemWrapper checked={isChecked} onClick={handleChangeCheckBox}>
      <div>
        {icon}
        <span>{record.name}</span>
      </div>
      <Checkbox checked={isChecked} onChange={handleChangeCheckBox} />
    </ModuleParentItemWrapper>
  )
}

ModuleParentItem.propTypes = {}

export default inject('moduleStore')(observer(ModuleParentItem))

import styled from 'styled-components'
import { Modal } from 'antd'

export const FormActionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 30px 0;
`
export const UserListSelected = styled.div`
  margin-bottom: 8px;

  .lable {
    font-weight: 500;
    margin-bottom: 6px;
  }

  .ant-tag {
    margin-bottom: 8px;
    padding: 5px 14px;
    border-radius: 25px;
    cursor: pointer;
  }
`

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    min-height: 95vh;
  }

  .ant-modal-close {
    display: none;
  }
`

export const TitleModal = styled.div`
  display: flex;
  justify-content: space-between;
`

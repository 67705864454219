import styled from 'styled-components'
import { Button } from 'antd'

export const TableFooterActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
`
export const SearchBar = styled.div`
  width: 300px;
  margin-right: 15px;
  margin-bottom: 15px;
`
export const FilterButton = styled(Button)`
  margin-bottom: 15px;
`

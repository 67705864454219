import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const SampleDocumentRequest = {
  getSampleDocumentList: (page, size) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/document-sample`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
      },
    }),
  createSampleDocument: documentData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/document-sample`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        code: documentData.code,
        content: documentData.content || null,
        title: documentData.title,
      },
    }),
  getSampleDocumentById: documentId =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/document-sample/${documentId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  deleteSampleDocument: documentId =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/document-sample/${documentId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  updateSampleDocument: (documentId, documentData) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/document-sample/${documentId}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        code: documentData.code,
        content: documentData.content || null,
        title: documentData.title,
      },
    }),
}

import styled from 'styled-components'
import { Modal, Typography } from 'antd'
import { blue, green } from '../../color'

const { Text } = Typography

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

export const TimeMissionWrapper = styled.h3`
  color: #e67e22;
  position: relative;
  flex: 0 0 300px;
  max-width: 300px;
  margin-bottom: 16px;
  margin-top: 5px;

  .select-date {
    width: 20px;
    border-radius: 2px;
    cursor: pointer;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -6px;
    right: -20px;
    transition: 0.3s;

    &:hover {
      background: ${blue};

      span {
        color: #fff !important;
        font-weight: bold;
      }
    }
  }

  &:hover {
    .date-picker {
      text-decoration: underline;
    }
  }
`
export const TextStatus = styled(Text)`
  margin-top: 6px;
  font-size: 10px;
  display: block;
`

export const ListWrapper = styled.div`
  .center-spin {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-list {
    /* min-height: 100vh; */
  }
  .ant-spin-nested-loading {
    /* min-height: calc(100vh - 260px); */
  }

  .ant-list-item-meta-title {
    margin-bottom: 0;
  }

  .ant-list-item-action > li {
    padding: 0 2px;
  }

  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }

  .ant-list-item-action {
    margin-left: 30px;
  }

  .ant-list-item {
    cursor: pointer;
    padding: 12px 10px;
    border-bottom: 1px solid #f0f0f0 !important;
    transition: all 0.2s;
    &:hover {
      background-color: #fffff1;
    }
  }

  .ant-list-item.is-user {
    background-color: #e9f0fd;
    padding: 12px 10px;
    pointer-events: none;
    &:hover {
      background-color: #e9f0fd;
    }
  }

  .ant-list-item.is-my-task {
    display: none;
    padding-bottom: 10px !important;
  }

  .ant-list-item.my-task-item {
    padding: 12px 10px 25px 10px;
  }
  .is-show-detail-task {
    background-color: #fffff1;
  }

  .ant-list-item.task-item {
    position: relative;
    .collapse-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f7efef;
      padding: 2px 18px;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      &:hover {
        box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
          rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
      }
    }
  }

  .view-detail-btn {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

export const ButtonAction = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background: #f5f5f5;
  }
`

export const TopMission = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 22px;
  width: 22px;
  margin-right: 10px;
`

export const FilterButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 10px;
  transition: 0.3s;

  .count-filter {
    z-index: 9;
    background-color: ${green};
    color: #fff;
    font-size: 11px;
    text-align: center;
    position: absolute;
    top: -7px;
    right: -7px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
  }
`

export const SelectTitle = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

export const TasDetailInfoBox = styled.div`
  border-radius: 10px;
  padding: 2px;
  border: 1px solid ${blue};
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
`

export const TableWrapper = styled.div`
  height: calc(100vh - 300px);
  .ant-spin-nested-loading {
    background-color: white;
  }

  .ant-table-tbody {
    .ant-table-row {
      vertical-align: top;
    }
  }

  .ant-table-thead {
  }
  .ant-table-thead > tr > th {
    background-color: #2c65ac;
    color: white;
  }

  .ant-comment-inner {
    padding: 0;
    align-items: center;

    .ant-comment-content-author {
      margin-bottom: 0;
    }
  }

  .ant-comment-content-author a,
  .ant-comment-content-author span {
    font-size: 14px;
    font-weight: 500;
    color: black;
  }
`

export const MissionFullPageWrapper = styled.div`
  .button-text {
    cursor: pointer;
    color: ${blue};
    &:hover {
      text-decoration: underline;
    }
  }
`

export const MissionFullDetailModalWrapper = styled(Modal)`
  .ant-modal-body {
    background-color: #fbfbfb;
  }
`

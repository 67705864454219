import styled from 'styled-components'

export const ListWrapper = styled.ul`
  padding-left: 0;
`

export const SortItem = styled.li`
  padding: 10px 0px;
  list-style: none;
  font-size: 14px;
  z-index: 99999;
  border-bottom: 1px solid #efefef;
  background: white;
`

import React from 'react'
import { Button, Empty, Menu, Modal, Pagination, Table, Tag } from 'antd'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { TableFooterActionWrapper } from './ConnectedCommitteeDocumentUserReadingStatusModalStyled'
import uuid from 'uuid'
import moment from 'moment'

const ConnectedCommitteeDocumentUserReadingStatusModal = props => {
  const { modalVisible, onClose, connectedDocumentStore } = props
  const {
    userLogsList,
    userLogsTotalCount,
    userLogsTotalPage,
    userLogsPageIndex,
    userLogsPageSize,
  } = connectedDocumentStore
  const renderStatusTag = status => {
    switch (status) {
      case '012':
        return <Tag color={'blue'}>Đã xử lý</Tag>
      case '011':
        return <Tag color={'orange'}>Đã đọc</Tag>
      case '010':
        return <Tag color={'red'}>Chưa đọc</Tag>
    }
  }
  const Text = props => (
    <a
      style={{
        color: 'rgba(0,0,0)',
      }}>
      {props.children}
    </a>
  )

  const tableColumns = [
    {
      title: 'Người nhận',
      render: record =>
        record.receiver && (
          <Text style={{ textTransform: 'capitalize' }}>{record.receiver}</Text>
        ),
    },
    {
      title: 'Phòng ban',
      render: record =>
        record.department_name && (
          <Text style={{ textTransform: 'capitalize' }}>
            {record.department_name}
          </Text>
        ),
    },
    {
      title: 'Ngày đọc lần đầu',
      render: record =>
        record.first_date_read && (
          <Text>
            {moment(record.first_date_read).format('DD-MM-YYYY HH:mm')}
          </Text>
        ),
    },
    {
      title: 'Lần đọc cuối cùng',
      render: record =>
        record.first_date_read && (
          <Text>
            {moment(record.last_date_read).format('DD-MM-YYYY HH:mm')}
          </Text>
        ),
    },
  ]
  const handleChangePageSize = pageSize => {
    connectedDocumentStore.setUserLogsPageSize(pageSize)
  }
  const dropdownMenu = (
    <Menu>
      <Menu.Item onClick={() => handleChangePageSize(10)}>
        Hiển thị 10 trên {userLogsTotalCount} kết quả
      </Menu.Item>
      <Menu.Item onClick={() => handleChangePageSize(20)}>
        Hiển thị 20 trên {userLogsTotalCount} kết quả
      </Menu.Item>
      <Menu.Item onClick={() => handleChangePageSize(50)}>
        Hiển thị 50 trên {userLogsTotalCount} kết quả
      </Menu.Item>
    </Menu>
  )

  return (
    <Modal
      wrapClassName={'user-reading-status-modal'}
      title={'Thành viên đọc văn bản'}
      visible={modalVisible}
      onCancel={onClose}
      footer={<Button onClick={onClose}>Đóng cửa sổ</Button>}>
      <Table
        rowKey={() => uuid()}
        dataSource={userLogsList}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 1200 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
      />

      <TableFooterActionWrapper>
        {/*<Dropdown placement={'topLeft'} overlay={dropdownMenu}>*/}
        {/*  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>*/}
        {/*    Hiển thị {userLogsPageSize} trên {userLogsTotalCount} kết quả*/}
        {/*  </a>*/}
        {/*</Dropdown>*/}
        <Pagination
          onChange={e => connectedDocumentStore.setUserLogsPageIndex(e)}
          pageSize={userLogsPageSize}
          showSizeChanger={false}
          showLessItems
          current={userLogsPageIndex + 1}
          total={userLogsTotalCount}
          hideOnSinglePage={true}
        />
      </TableFooterActionWrapper>
    </Modal>
  )
}

ConnectedCommitteeDocumentUserReadingStatusModal.propTypes = {
  modalVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

export default inject('connectedDocumentStore')(
  observer(ConnectedCommitteeDocumentUserReadingStatusModal),
)

import React, { useEffect, useState } from 'react'
// Component
import { CellEclipseBox } from '../../../components/Common/Table'
import {
  TextWrap,
  trimOverlengthString,
} from '../../../components/Common/CellText'
import EditUserGroupModal from './EditUserGroupModal'
// Styled component
import {
  ButtonItem,
  ButtonItemWrapper,
  ListUserGroupWrapper,
  TabUserGroupWrapper,
} from './ListUserGroupStyled'
// Ant design
import {
  Avatar,
  Comment,
  Empty,
  message,
  Popconfirm,
  Tabs,
  Tooltip,
} from 'antd'
import {
  DeleteTwoTone,
  QuestionCircleOutlined,
  SettingOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import { apiUrl } from '../../../config'
import TableComponent from '../../../components/Common/TableComponent'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { blue } from '../../../color'
import { ACL_ACTION_TYPE, ASSIGNEE_TYPE } from '../../../constants'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'
import AccessControlAction from '../../../components/AccessControlAction/AccessControlAction'
import authenticationStore from '../../../stores/authenticationStore'

const { TabPane } = Tabs

const ListUserGroup = props => {
  const {
    userGroupStore,
    loadingAnimationStore,
    selectPeopleStore,
    isNhomNhiemVu,
    authenticationStore,
  } = props

  const { dataGroupStoreList, groupActive } = userGroupStore

  const location = useLocation()
  const groupType =
    queryString.parse(location.search, {
      arrayFormat: 'index',
    }).group_type ||
    (location.pathname.includes('mission-management') ? 'nhiem_vu' : undefined)
  const {
    selectUserData,
    assigneePopupType,
    setSelectUserData,
  } = selectPeopleStore

  const [
    isEditUserGroupModalVisible,
    setIsEditUserGroupModalVisible,
  ] = useState(false)
  const [isVisibleSelectPeoplePopup, setIsVisibleSelectPeoplePopup] = useState(
    false
  )

  const handleOpenSelectPeoplePopup = type => {
    selectPeopleStore.setAssigneePopupType(type)
    const convertSelectDataObj =
      groupActive &&
      groupActive.user_list.map(user => {
        return {
          id: user.user_name,
          assignee_type: ASSIGNEE_TYPE.USER,
          full_name: user.name_lowercase,
          image_id: user.image,
          permission: ASSIGNEE_TYPE.HANDLER,
          department_code: undefined,
          department_name: user.deparment,
        }
      })
    if (convertSelectDataObj) {
      setSelectUserData({
        ...selectUserData,
        [assigneePopupType]: convertSelectDataObj,
      })
    }
    setIsVisibleSelectPeoplePopup(true)
  }

  const handleCancelSelectUser = () => setIsVisibleSelectPeoplePopup(false)

  useEffect(() => {
    ;(async () => {
      if (authenticationStore.currentUser) {
        selectPeopleStore.setAssigneePopupType(ASSIGNEE_TYPE.HANDLER)
        try {
          loadingAnimationStore.showSpinner(true)
          const responseGroupList = await userGroupStore.getGroupList(
            groupType,
            authenticationStore.currentCompanyCode()
          )
          if (responseGroupList.length > 0) {
            const responseMemberGroup = await userGroupStore.getMemberGroupByIdGroup(
              responseGroupList[0].id_group
            )
            userGroupStore.setGroupActive({
              ...responseGroupList[0],
              user_list: responseMemberGroup.map(user => user.user),
            })
          }
        } catch (err) {
          console.log(err)
          message.error('Đã có lỗi xảy ra!')
        } finally {
          loadingAnimationStore.showSpinner(false)
        }
      }
    })()
  }, [authenticationStore.currentUser])

  const tableColumn = [
    {
      title: 'Thông tin',
      width: 250,
      render: record => (
        <Comment
          author={
            <span style={{ textTransform: 'capitalize' }}>
              {record.name_lowercase}
            </span>
          }
          avatar={
            <Avatar
              style={{ backgroundColor: '#1890ff' }}
              src={record.image && `${apiUrl}/api/v1/images/${record.image}`}>
              {record?.name_lowercase?.split('')[0].toString().toUpperCase()}
            </Avatar>
          }
        />
      ),
    },
    {
      title: 'Email',
      render: record => (
        <Tooltip title={record?.email}>
          <CellEclipseBox>{record?.email}</CellEclipseBox>
        </Tooltip>
      ),
    },
    {
      title: 'Điện thoại',
      render: record => (
        <span>{record.mobileNume ? record?.mobileNume : 'Chưa rõ'}</span>
      ),
    },
    {
      title: 'Địa chỉ',
      render: record => (record.address ? record?.address : 'Chưa rõ'),
    },
    {
      title: 'Chức vụ',
      render: record => (record.job_title ? record?.job_title : 'Chưa rõ'),
    },
    {
      title: 'Phòng ban',
      render: record => (
        <Tooltip title={record.deparment}>
          <CellEclipseBox>{record.deparment}</CellEclipseBox>
        </Tooltip>
      ),
    },
    {
      title: (
        <ButtonItemWrapper>
          <AccessControlAction
            aclActionType={
              isNhomNhiemVu
                ? ACL_ACTION_TYPE.edit__NHOM_NHIEM_VU
                : ACL_ACTION_TYPE.edit__NHOM_NGUOI_DUNG
            }>
            <ButtonItem title={'Chọn người từ phòng ban'}>
              <UserOutlined
                style={{ color: blue }}
                onClick={() =>
                  handleOpenSelectPeoplePopup(ASSIGNEE_TYPE.HANDLER)
                }
              />
            </ButtonItem>
            <ButtonItem title={'Chỉnh sửa người nhóm'}>
              <SettingOutlined
                style={{ color: '#40A9FF' }}
                onClick={() => setIsEditUserGroupModalVisible(true)}
              />
            </ButtonItem>
          </AccessControlAction>
          <AccessControlAction
            aclActionType={
              isNhomNhiemVu
                ? ACL_ACTION_TYPE.delete__NHOM_NHIEM_VU
                : ACL_ACTION_TYPE.delete__NHOM_NGUOI_DUNG
            }>
            <Popconfirm
              onConfirm={() => handleDeleteGroup()}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xóa nhóm này không?'}>
              <ButtonItem title={'Xóa nhóm'}>
                <DeleteTwoTone
                  twoToneColor={'#e74c3c'}
                  onClick={event => event.stopPropagation()}
                />
              </ButtonItem>
            </Popconfirm>
          </AccessControlAction>
        </ButtonItemWrapper>
      ),
      fixed: 'right',
      align: 'center',
      width: 140,
      render: record => (
        <AccessControlAction
          aclActionType={
            isNhomNhiemVu
              ? ACL_ACTION_TYPE.remove_member__NHOM_NHIEM_VU
              : ACL_ACTION_TYPE.remove_member__NHOM_NGUOI_DUNG
          }>
          <ButtonItemWrapper>
            {groupActive && groupActive.user_list.length > 0 && (
              <Popconfirm
                onConfirm={() =>
                  handleDeleteMemberGroup(
                    record.id_group,
                    record.user_name,
                    record.name_lowercase
                  )
                }
                okText={'Đồng ý'}
                cancelText={'Không'}
                okType={'danger'}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                title={'Bạn có muốn xóa người dùng này?'}>
                <ButtonItem title={'Xóa người dùng khỏi nhóm'} placement="left">
                  <UserDeleteOutlined style={{ color: 'red' }} />
                </ButtonItem>
              </Popconfirm>
            )}
          </ButtonItemWrapper>
        </AccessControlAction>
      ),
    },
  ]

  const handleDeleteMemberGroup = async (id_group, username, user_name) => {
    const dataSubmit = {
      user_names: [username],
    }
    loadingAnimationStore.setTableLoading(true)
    try {
      await userGroupStore.deleteMemberGroup(id_group, dataSubmit)
      const response = await userGroupStore.getMemberGroupByIdGroup(
        groupActive.id_group
      )
      if (response && response.length > 0) {
        // response là 1 array ít nhất 1 phần từ, do UI k cho ng dùng xóa người cuối cùng
        userGroupStore.setGroupActive(
          dataGroupStoreList.find(
            group => group.id_group === response[0].group_id
          )
        )
      }
      message.success(
        <span>
          Xóa người dùng <b>{user_name}</b> thành công!
        </span>
      )
    } catch (err) {
      message.error('Đã có lỗi xảy ra')
      console.log(err)
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const handleDeleteGroup = async () => {
    loadingAnimationStore.setTableLoading(true)
    try {
      await userGroupStore.deleteGroup(groupActive.id_group)
      const responseGroupList = await userGroupStore.getGroupList(
        groupType,
        authenticationStore.currentCompanyCode()
      )
      if (responseGroupList.length === 0) {
        return message.success(
          <span>
            Xóa nhóm <b>{groupActive.name_group}</b> thành công!
          </span>
        )
      }
      const responseMemberGroup = await userGroupStore.getMemberGroupByIdGroup(
        responseGroupList[0].id_group
      )
      userGroupStore.setGroupActive({
        ...responseGroupList[0],
        user_list: responseMemberGroup.map(user => user.user),
      })
      message.success(
        <span>
          Xóa nhóm <b>{groupActive.name_group}</b> thành công!
        </span>
      )
    } catch (err) {
      console.log(err)
      // các đầu api trên k return response error
      message.error('Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const onChangeTabUserGroup = async key => {
    const groupTarget = dataGroupStoreList.find(
      group => group.id_group === parseInt(key)
    )
    if (groupTarget?.user_list.length > 0) {
      return userGroupStore.setGroupActive(groupTarget)
    }
    loadingAnimationStore.setTableLoading(true)
    try {
      const response = await userGroupStore.getMemberGroupByIdGroup(
        parseInt(key)
      )
      let groupActive = dataGroupStoreList.find(
        group => group?.id_group === response[0]?.group_id
      )
      if (!groupActive) {
        groupActive = {
          id_group: groupTarget.id_group,
          name_group: groupTarget.name_group,
          user_list: [],
        }
      }
      userGroupStore.setGroupActive(groupActive)
    } catch (err) {
      console.log(err)
      message.error('Lấy thông tin người dùng trong nhóm thất bại!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const handleSubmitSelectUser = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const responseGroupListFetchNameGroup = await userGroupStore.getGroupList(
        groupType,
        authenticationStore.currentCompanyCode()
      )
      const nameGroup = responseGroupListFetchNameGroup.find(
        group => group.id_group === groupActive.id_group
      ).name_group
      const usersSubmit = {
        name: nameGroup,
        group_member_list: selectUserData[ASSIGNEE_TYPE.HANDLER].map(
          user => user.id
        ),
      }
      await userGroupStore.updateGroup(groupActive.id_group, usersSubmit)
      const responseGroupList = await userGroupStore.getGroupList(
        groupType,
        authenticationStore.currentCompanyCode()
      )
      const response = await userGroupStore.getMemberGroupByIdGroup(
        groupActive.id_group
      )
      userGroupStore.setGroupActive({
        ...responseGroupList.find(
          group => group.id_group === groupActive.id_group
        ),
        user_list: response.map(user => user.user),
      })
      message.success('Cập nhật nhóm thành công!')
    } catch (err) {
      console.log(err)
      message.error('Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
    setIsVisibleSelectPeoplePopup(false)
  }

  useEffect(() => {
    console.log('ListUserGroupWrapper isNhomNhiemVu', isNhomNhiemVu)
  }, [isNhomNhiemVu])

  return (
    <ListUserGroupWrapper>
      <TabUserGroupWrapper
        tabPosition={'left'}
        onChange={onChangeTabUserGroup}
        activeKey={groupActive?.id_group + ''}>
        {dataGroupStoreList.map(group => {
          const userListHasIdGroup = group.user_list.map(user => {
            return {
              ...user,
              id_group: group.id_group,
            }
          })
          return (
            <TabPane
              tab={
                <>
                  <TextWrap>
                    {trimOverlengthString(group.name_group, 200)}
                  </TextWrap>
                </>
              }
              key={group.id_group}>
              <TableComponent
                rowKey={record => {
                  return record.user_name + group.id_group
                }}
                columns={tableColumn}
                dataSource={userListHasIdGroup}
                pagination={userListHasIdGroup.length > 10}
                scroll={{ x: 1180 }}
                loading={loadingAnimationStore.tableLoading}
              />
            </TabPane>
          )
        })}
      </TabUserGroupWrapper>
      {!dataGroupStoreList.length ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={'Không có nhóm nào!'}
        />
      ) : null}
      <EditUserGroupModal
        isEditUserGroupModalVisible={isEditUserGroupModalVisible}
        handleCloseEditUserGroupModal={() =>
          setIsEditUserGroupModalVisible(false)
        }
      />
      <SelectPeoplePopup
        disableSelectDepartment
        handleCancelSelectUser={handleCancelSelectUser}
        handleSubmitSelectUser={handleSubmitSelectUser}
        isVisibleSelectPeoplePopup={isVisibleSelectPeoplePopup}
      />
    </ListUserGroupWrapper>
  )
}

ListUserGroup.propTypes = {}

export default inject(
  'userGroupStore',
  'userStore',
  'loadingAnimationStore',
  'selectPeopleStore',
  'authenticationStore'
)(observer(ListUserGroup))

import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const WorkScheduleRequest = {
  createWorkSchedule: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/work-schedules`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  updateWorkSchedule: (scheduleCode, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/work-schedules/${scheduleCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  delete: scheduleCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/work-schedules/${scheduleCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getWorkSchedule: (from_date, to_date) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work-schedules`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        from_date: from_date,
        to_date: to_date,
      },
    }),
  getPublicWorkSchedule: (from_date, to_date) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work-schedules`,
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsicG9ydGFsLXZpbWMtYmUtcHJvZCJdLCJ1c2VyX25hbWUiOiJraG9haGEiLCJzY29wZSI6WyJvcGVuaWQiXSwiZXhwIjoxNjI5MTM2NTU5LCJhdXRob3JpdGllcyI6WyJFT0ZGSUNFX0FETUlOIiwiRU9GRklDRV9VU0VSIiwiVVNFUiIsIkVPRkZJQ0VfTEVBREVSIl0sImp0aSI6IjMxYzRjYzc0LWNlOGItNGIzZC1iNWZlLTY3OWE2N2IwYWM5ZiIsImNsaWVudF9pZCI6InZpbWMifQ.gYiQKGuImg5Ud0hrozAlowfBcLFWHflwCfXmxERmj5Y`,
        'Content-Type': 'application/json',
      },
      params: {
        from_date: from_date,
        to_date: to_date,
      },
    }),
  getWorkScheduleDetail: scheduleCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/work-schedules/${scheduleCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}

import { action, observable } from 'mobx'
import { ChamCongRequest } from '../requests/ChamCongRequest'

class ChamCongStore {
  @action createChamCong = payload => {
    return new Promise((resolve, reject) => {
      ChamCongRequest.createChamCong(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @observable listChamCong = []
  @action getListChamCong = () => {
    return new Promise((resolve, reject) => {
      ChamCongRequest.getListChamCong()
        .then(response => {
          this.listChamCong = response.data.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @observable chamCongDetails = {}
  @action getChamCongDetails = id => {
    return new Promise((resolve, reject) => {
      ChamCongRequest.getChamCongDetails(id)
        .then(response => {
          // console.log(response.data)
          this.chamCongDetails = response.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @action clearLeaveata = () => {
    this.listChamCong = []
  }

  @action clearChamCongDetails = () => {
    this.leaveRecordDetail = {}
  }
}

export default new ChamCongStore()

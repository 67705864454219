import React, { useState } from 'react'
// Ant design
import { Button, Form, Input, message, Modal } from 'antd'
// Styled Components
import {
  ButtonWrapper,
  SignatureCanvasWrapper,
} from './SignatureCanvasModalStyled'
// Mobx
import { inject, observer } from 'mobx-react'
// Other
import SignaturePad from 'react-signature-canvas'
import { DIGITAL_SIGN_PROVIDER, DIGITAL_TYPE_SIGN } from '../../../../constants'

const SignatureCanvasModal = props => {
  const {
    isVisibleSignatureCanvasModal,
    handleCancelSignatureCanvas,
    handleCloseCreateSignatureModal,
    handleCreatedSuccess,
    signedDocumentStore,
    loadingAnimationStore,
    fileStore,
  } = props

  const [form] = Form.useForm()

  const [sigPad, setSigPad] = useState({})
  const clearCanvas = () => {
    sigPad.clear()
  }

  const handleCreateSignature = async value => {
    const base64Img = sigPad.getTrimmedCanvas().toDataURL('image/png')
    const fetchedImg = await fetch(base64Img)
    const blobImg = await fetchedImg.blob()
    const fileImg = new File([blobImg], 'canvas sign', { type: 'image/png' })
    const formData = new FormData()
    formData.append('file', fileImg, value.signature_name)

    try {
      loadingAnimationStore.showSpinner(true)

      //upload file
      const uploadResponse = await fileStore.uploadFile(formData)
      const fileId = uploadResponse.data.file_id
      const fileName = value.signature_name
      console.log('uploadedFile', fileId)

      //sign
      const data = {
        file_id: fileId,
        file_name: fileName,
        provider: DIGITAL_SIGN_PROVIDER,
        type_sign: DIGITAL_TYPE_SIGN,
      }
      const createSignatureBySendFileIdResponse = await signedDocumentStore.createSignatureBySendFileId(
        data
      )

      message.success('Tạo chữ ký thành công')
      handleCreatedSuccess()
    } catch (error) {
      console.log(error)
      message.error(error.vi)
    } finally {
      clearCanvas()
      loadingAnimationStore.showSpinner(false)
      handleCancelSignatureCanvas()
      handleCloseCreateSignatureModal()
    }
  }

  return (
    <Modal
      title={
        <span style={{ color: '#1088E7', fontWeight: 'bold' }}>CHỮ KÝ TAY</span>
      }
      visible={isVisibleSignatureCanvasModal}
      footer={null}
      onCancel={handleCancelSignatureCanvas}
      width={650}>
      <Form layout="vertical" onFinish={handleCreateSignature} form={form}>
        <Form.Item
          label="Tên mẫu chữ ký"
          name="signature_name"
          rules={[{ required: true, message: 'Hãy nhập tên mẫu chữ ký!' }]}>
          <Input placeholder={'Nhập tên mẫu chữ ký'} />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <SignatureCanvasWrapper>
            <SignaturePad
              ref={ref => {
                setSigPad(ref)
              }}
              penColor={'#000'}
              canvasProps={{ width: 600, height: 300, className: 'sigCanvas' }}
            />
          </SignatureCanvasWrapper>
        </div>
        <ButtonWrapper>
          <Button danger ghost onClick={clearCanvas}>
            Xóa
          </Button>
          <Button type={'primary'} htmlType={'submit'}>
            Lưu chữ ký
          </Button>
        </ButtonWrapper>
      </Form>
    </Modal>
  )
}

SignatureCanvasModal.propTypes = {}

export default inject(
  'signedDocumentStore',
  'loadingAnimationStore',
  'fileStore'
)(observer(SignatureCanvasModal))

import React, { useEffect } from 'react'
import { Button, Col, Form, Input, message, Modal, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { TRUC_COMMAND } from '../../../../command_constant'

const PopupEditAccountCommand = props => {
  const {
    selectedAccount,
    selectedCommand,
    isVisibleEditPopup,
    onClose,
    onEditSuccess,
    commandStore,
    userStore,
    accountEditStore,
    authenticationStore,
    loadingAnimationStore,
  } = props

  const { selectedUser } = userStore

  const [form] = Form.useForm()

  const onFinish = async values => {
    try {
      loadingAnimationStore.showSpinner(true)

      let testLoginResult = null
      if (selectedCommand.code === TRUC_COMMAND) {
        try {
          testLoginResult = await authenticationStore.testLoginTruc(
            values.account_name,
            values.password
          )
        } catch (e) {
          message.error('Tài khoản trục không đúng')
        }
      } else {
        testLoginResult = 'ok'
      }

      if (testLoginResult) {
        if (selectedAccount?.id) {
          await accountEditStore.updateAccountById(selectedAccount.id, values)
          message.success('Cập nhật thông tin thành công!')
        } else {
          await accountEditStore.createAccount({
            alias: values.alias,
            account_name: values.account_name,
            command_code: selectedCommand.code,
            password: values.password,
            user_code: selectedUser.code,
          })

          message.success('Tạo tài khoản mới thành công!')
        }

        onClose()
        onEditSuccess()
      }
    } catch (e) {
      message.error(e.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      alias: selectedAccount?.alias ?? '',
      account_name: selectedAccount?.account_name ?? '',
      password: selectedAccount?.password ?? '',
    })
  }, [selectedAccount, isVisibleEditPopup])

  const closeAndResetForm = () => {
    onClose()
  }

  return (
    <>
      <Modal
        title="Tài khoản"
        footer={null}
        closable={false}
        onCancel={() => {
          closeAndResetForm()
        }}
        visible={isVisibleEditPopup}>
        <Form
          form={form}
          scrollToFirstError
          name={'account-edit-form'}
          onFinish={onFinish}
          layout={'vertical'}>
          <Form.Item
            label={'Tên tài khoản'}
            name={'alias'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên tài khoản' },
            ]}>
            <Input autoComplete={'alias'} placeholder={'Tên tài khoản'} />
          </Form.Item>
          <Form.Item
            label={'Username'}
            name={'account_name'}
            rules={[{ required: true, message: 'Vui lòng nhập username' }]}>
            <Input autoComplete={'account_name'} placeholder={'Username'} />
          </Form.Item>
          <Form.Item
            label={'Mật khẩu'}
            name={'password'}
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}>
            <Input.Password
              autoComplete={'new-password'}
              placeholder={'Mật khẩu'}
            />
          </Form.Item>

          <Row
            type={'flex'}
            style={{ marginTop: 30 }}
            justify={'space-between'}
            gutter={15}>
            <Col span={12}>
              <Button block type={'default'} onClick={onClose}>
                Đóng
              </Button>
            </Col>
            <Col span={12}>
              <Button block type={'primary'} htmlType="submit">
                Lưu
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

PopupEditAccountCommand.propTypes = {}

export default inject(
  'userStore',
  'authenticationStore',
  'loadingAnimationStore',
  'commandStore',
  'accountEditStore'
)(observer(PopupEditAccountCommand))

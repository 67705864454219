import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import { Button, message, Pagination, Popconfirm, Tooltip } from 'antd'
import uuid from 'uuid'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import signAccountStore from '../../stores/signAccountStore'
import EditSignAccountModal from './EditSignAccountModal/EditSignAccountModal'
import TableComponent from '../../components/Common/TableComponent'
import { ACL_ACTION_TYPE } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'

const SignAccountPage = props => {
  const { loadingAnimationStore, signAccountStore, history } = props

  const { list, totalCount, filter } = signAccountStore
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false)

  useEffect(() => {
    load()

    return () => signAccountStore.clear()
  }, [signAccountStore])

  function load() {
    loadingAnimationStore.showSpinner(true)

    signAccountStore
      .getList(filter.page, filter.size)
      .then(() => {})
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const onChangeTable = useCallback(
    (current, size) => {
      if (size !== filter.size) {
        filter.page = 1
        filter.size = size
      } else {
        filter.page = current
      }
      signAccountStore.setFilter(filter)
      load()
    },
    [signAccountStore, filter]
  )

  const handleCloseModal = () => {
    setIsVisibleModal(false)
    signAccountStore.clearDetails()
  }

  const handleDelete = async record => {
    try {
      loadingAnimationStore.showSpinner(true)
      await signAccountStore.delete(record)
      load()
    } catch (error) {
      message.error(error.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const onItemClick = record => {
    // console.log('record', toJS(record))
    signAccountStore.signAccountDetails = record
    setIsVisibleModal(true)
  }

  const tableColumn = [
    {
      title: 'Nhà cung cấp',
      render: record => record?.provider,
      onCell: record => {
        return {
          onClick: () => onItemClick(record),
        }
      },
    },
    {
      title: 'Người dùng',
      render: record => record?.user_name,
      onCell: record => {
        return {
          onClick: () => onItemClick(record),
        }
      },
    },
    {
      title: 'Tài khoản',
      render: record => record?.account,
      onCell: record => {
        return {
          onClick: () => onItemClick(record),
        }
      },
    },
    {
      title: 'Loại',
      render: record => record?.type,
      onCell: record => {
        return {
          onClick: () => onItemClick(record),
        }
      },
    },
    {
      title: 'Tác vụ',
      width: 100,
      render: record => (
        <>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.delete__TAI_KHOAN_KY_SO}>
            <Tooltip title={'Xóa'}>
              <Popconfirm
                onConfirm={() => {
                  handleDelete(record)
                }}
                okText={'Đồng ý'}
                cancelText={'Không'}
                okType={'danger'}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                T
                title={'Bạn có muốn xoá tài khoản này?'}>
                <DeleteOutlined style={{ color: '#fc0000' }} />
              </Popconfirm>
            </Tooltip>
          </AccessControlAction>
        </>
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Văn bản pháp chế | Tài liệu</title>
      </Helmet>

      <PageTitle
        location={props.location}
        title={'Tài khoản ký số'}
        hiddenGoBack>
        <div>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.create__TAI_KHOAN_KY_SO}>
            <Button
              style={{ margin: '0 15px' }}
              type={'primary'}
              onClick={() => {
                signAccountStore.clearDetails()
                setIsVisibleModal(true)
              }}>
              Tạo tài khoản
            </Button>
          </AccessControlAction>
        </div>
      </PageTitle>

      <ContentBlockWrapper>
        <TableComponent
          // onRow={record => {
          //   return {
          //     onClick: () => {
          //       // console.log('record', toJS(record))
          //       signAccountStore.signAccountDetails = record
          //       setIsVisibleModal(true)
          //     },
          //   }
          // }}
          rowKey={() => uuid()}
          columns={tableColumn}
          dataSource={list}
          pagination={false}
          // onChange={onChangeTable}
        />
        <TableBottomPaginationBlock>
          <Pagination
            current={filter.page}
            pageSize={filter.size}
            total={totalCount}
            onChange={onChangeTable}
            showSizeChanger={false}
            showLessItems
          />
        </TableBottomPaginationBlock>

        <EditSignAccountModal
          isVisibleModal={isVisibleModal}
          handleCloseModal={handleCloseModal}
        />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

SignAccountPage.propTypes = {}

export default memo(
  inject('loadingAnimationStore', 'signAccountStore')(observer(SignAccountPage)),
)

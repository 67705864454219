import React, { Fragment, memo, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Divider,
  Input,
  message,
  Popconfirm,
  Table,
  Tabs,
  Tooltip,
} from 'antd'
import { DOCUMENT_TYPE } from '../../../constants'
import { useLocation, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import utils from '../../../utils'
import AuthoritiesActionModal from '../../AuthoritiesPage/AuthoritiesActionModal'
import { inject, observer } from 'mobx-react'

const InternalDocumentConfigAuthorities = props => {
  const { authorityStore, history } = props

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)
  const { authorityIssuedsList, authorityIssuedsListOrigin } = authorityStore
  const [
    isLoadingAuthorityIssuedsList,
    setIsLoadingAuthorityIssuedsList,
  ] = useState(false)
  const [valueFilter, setValueFilter] = useState({
    value: '',
  })
  const [
    payloadAuthoritiesActionModal,
    setPayloadAuthoritiesActionModal,
  ] = useState({
    status: false,
    isCreate: true,
    updatePayLoad: null,
  })

  useEffect(() => {
    ;(async () => {
      try {
        authorityStore.setAuthorityIssuedsQueryParams({
          ...authorityStore.authorityIssuedsQueryParams,
          ...queryStringParse,
        })
        setIsLoadingAuthorityIssuedsList(true)
        await authorityStore.getAuthorityIssueds()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingAuthorityIssuedsList(false)
      }
    })()
    return () => {
      authorityStore.clearAuthorityIssuedsList()
    }
  }, [])

  const tableColumns = useMemo(
    () => [
      {
        title: 'Tên đơn vị',
        width: 90,
        render: record => record.name,
      },

      {
        title: 'Tác vụ',
        align: 'right',
        width: 10,
        render: record => (
          <Fragment>
            <Tooltip title={'Sửa thông tin cơ quan ban hành'}>
              <EditOutlined
                className={'anticon-color'}
                onClick={() =>
                  setPayloadAuthoritiesActionModal({
                    status: true,
                    isCreate: false,
                    updatePayLoad: record,
                  })
                }
              />
            </Tooltip>
            <Divider type={'vertical'} />
            <Tooltip title={'Xoá'}>
              <Popconfirm
                onConfirm={() => handleDeleteAuthority(record.id, record.name)}
                okText={'Đồng ý'}
                cancelText={'Không'}
                okType={'danger'}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                title={'Bạn có muốn xoá cơ quan ban hành này?'}>
                <DeleteOutlined style={{ color: '#fc0000' }} />
              </Popconfirm>
            </Tooltip>
          </Fragment>
        ),
      },
    ],
    []
  )

  const handleDeleteAuthority = async (id, name) => {
    try {
      setIsLoadingAuthorityIssuedsList(true)
      await authorityStore.deleteAuthority(id)
      await authorityStore.getAuthorityIssueds()
      setValueFilter({ value: valueFilter.value })
      message.success(
        <span>
          Bạn đã xóa <b>{name}</b>.
        </span>
      )
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingAuthorityIssuedsList(false)
    }
  }

  const handleCloseAuthoritiesActionModal = () => {
    setPayloadAuthoritiesActionModal({
      status: false,
      isCreate: true,
      updatePayLoad: null,
    })
  }

  const handleChangeTab = async type => {
    setValueFilter({ value: '' })
    authorityStore.setAuthorityIssuedsQueryParams({
      ...authorityStore.authorityIssuedsQueryParams,
      type: type,
    })
    try {
      setIsLoadingAuthorityIssuedsList(true)
      await authorityStore.getAuthorityIssueds()
      const queryStringStringify = queryString.stringify(
        {
          ...authorityStore.authorityIssuedsQueryParams,
          type: type,
        },
        {
          skipNull: true,
        }
      )
      let path = '/internal-document/config-document/authorities'

      if (props.historyPath) {
        path = props.historyPath
      }

      history.replace(`${path}?${queryStringStringify}`)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingAuthorityIssuedsList(false)
    }
  }

  useEffect(() => {
    if (valueFilter.value === '') {
      authorityStore.setAuthorityIssuedsList(authorityIssuedsListOrigin)
      return
    }
    const authorityIssuedsListFilter = authorityIssuedsListOrigin.filter(item =>
      utils
        .nonAccentVietnamese(item.name)
        .includes(utils.nonAccentVietnamese(valueFilter.value))
    )
    authorityStore.setAuthorityIssuedsList(authorityIssuedsListFilter)
  }, [valueFilter])

  const handleFilterAuthorityIssueds = event => {
    if (event.target.value.trim() === '') {
      setValueFilter({ value: '' })
      return
    }
    setValueFilter({ value: event.target.value })
  }

  const tabBarExtraContent = (
    <Button
      type={'primary'}
      onClick={() =>
        setPayloadAuthoritiesActionModal({
          status: true,
          isCreate: true,
          updatePayLoad: null,
        })
      }>
      <PlusCircleOutlined />
      Tạo mới cơ quan ban hành
    </Button>
  )

  return (
    <>
      <Tabs
        type={'card'}
        tabBarExtraContent={tabBarExtraContent}
        onChange={handleChangeTab}
        defaultActiveKey={queryStringParse.type || DOCUMENT_TYPE.INCOMING}>
        <Tabs.TabPane tab="Văn bản đến" key={DOCUMENT_TYPE.INCOMING} />
        <Tabs.TabPane tab="Văn bản đi" key={DOCUMENT_TYPE.OUTGOING} />
      </Tabs>
      <div
        style={{
          marginBottom: 12,
        }}>
        <Input.Search
          value={valueFilter.value}
          placeholder="Tìm kiếm đơn vị theo tên..."
          onChange={handleFilterAuthorityIssueds}
          style={{ width: 300 }}
        />
      </div>
      <Table
        columns={tableColumns}
        dataSource={authorityIssuedsList}
        pagination={false}
        rowKey={'id'}
        loading={{
          spinning: isLoadingAuthorityIssuedsList,
          tip: 'Đang tải...',
        }}
      />

      <AuthoritiesActionModal
        tabActive={queryStringParse.type || DOCUMENT_TYPE.INCOMING}
        handleSetValueFilter={isCreate => {
          setValueFilter({ value: isCreate ? '' : valueFilter.value })
        }}
        payloadModal={payloadAuthoritiesActionModal}
        handleCloseModal={handleCloseAuthoritiesActionModal}
      />
    </>
  )
}

InternalDocumentConfigAuthorities.propTypes = {}

export default memo(
  withRouter(
    inject(
      'authenticationStore',
      'authorityStore',
    )(observer(InternalDocumentConfigAuthorities)),
  ),
)

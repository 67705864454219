import { observable } from 'mobx'
import { ISODocumentRequest } from '../../requests/ISODocumentRequest'
import fileStore from '../../stores/fileStore'

class ISODocumentStore {
  @observable items = []
  @observable breadCrumb = []
  @observable currentFolderId = 'root'
  @observable isLoading = false

  @observable sortInfo = {
    sortedInfo: null,
  }

  @observable keyword = null

  getItems = async () => {
    this.isLoading = true

    const response = await ISODocumentRequest.getFolderDetails(
      this.currentFolderId
    )
    this.items = response.data.data
    this.breadCrumb = response.data.breadCrumb

    this.isLoading = false
  }

  search = async () => {
    this.isLoading = true

    const response = await ISODocumentRequest.search(
      this.currentFolderId,
      this.keyword
    )
    this.items = response.data.data
    this.breadCrumb = []

    this.isLoading = false
  }

  delete = async id => {
    this.isLoading = true

    await fileStore.deleteCloudObject(id)

    await this.getItems()
  }

  clear = () => {
    console.log('ISODocumentStore. clear')
    this.items = []
    this.breadCrumb = []
    this.currentFolderId = 'root'
    this.isLoading = false
  }
}

export default new ISODocumentStore()

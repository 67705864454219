import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, message, Modal, Row, Space } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { ButtonFooter } from '../../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import ApprovalLevelCreate from './ApprovalLevelCreate'
import ApprovalTemplateSortableLevels from './ApprovalTemplateSortableLevels'
import { AddButton, ApprovalLevel } from './ApproveTemplateStyled'

const ApprovalTemplateCreate = props => {
  const [form] = Form.useForm()

  const [isVisibleLevelCreateModal, setIsVisibleLevelCreateModal] = useState(
    false
  )
  const { approvalTemplateStore, selectPeopleStore } = props
  const [isCreating, setIsCreating] = useState(false)

  const defaultApprovalLevel = {
    level: 0,
    name: 'Bộ phận đề nghị',
    allow_edit: false,
    username: null,
    is_deleted: false,
  }

  useEffect(() => {
    return () => approvalTemplateStore.clearApprovalTemplateSortableLevels()
  }, [])

  const handleToggleLevelCreateModal = bool => event => {
    setIsVisibleLevelCreateModal(bool)
    if (!bool) {
      selectPeopleStore.clearSelected()
    }
  }

  const onFinish = async value => {
    try {
      setIsCreating(true)

      let level = 0

      if (
        approvalTemplateStore.approvalTemplateSortableLevels.filter(
          level => !level.is_deleted
        ).length === 0
      ) {
        message.error('Số cấp duyệt phải từ 2 cấp trở lên')
        return
      }

      const approvalTemplate = {
        name: value.name,
        description: value.description,
        approval_levels: [
          defaultApprovalLevel,
          ...approvalTemplateStore.approvalTemplateSortableLevels
            .filter(level => !level.is_deleted)
            .map(approvalLevel => {
              level++
              return {
                level,
                name: approvalLevel.name,
                allow_edit: approvalLevel.allow_edit,
                username: approvalLevel.handler?.username,
                is_deleted: approvalLevel.is_deleted,
              }
            }),
        ],
      }

      await approvalTemplateStore.createApprovalTemplate(approvalTemplate)
      message.success('Tạo mẫu duyệt thành công')
      props.onCancel()
      await approvalTemplateStore.getApprovalTemplateList()
    } catch (error) {
      console.log(error)
      props.onCancel()
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              label={'Tên:'}
              name={'name'}
              rules={[
                { required: true, message: 'Vui lòng nhập tên mẫu duyệt' },
                {
                  max: 500,
                  message: 'Tên mẫu duyệt không được dài quá 500 ký tự!',
                },
              ]}>
              <Input placeholder={'Nhập tên mẫu duyệt...'} />
            </Form.Item>

            <Form.Item
              name={'description'}
              label={'Mô tả:'}
              rules={[
                { required: true, message: 'Vui lòng nhập mô tả mẫu duyệt!' },
              ]}>
              <Input.TextArea rows={4} placeholder={'Nhập mô tả mẫu duyệt'} />
            </Form.Item>
          </Col>

          <Col span={16}>
            <p style={{ fontWeight: 500 }}>Cấp độ duyệt</p>

            <ApprovalLevel>
              <Row style={{ justifyContent: 'flex-end' }}>
                <Col span={22} className="approval-level__content">
                  <dt>Tên</dt>
                  <dd>Bộ phận đề nghị</dd>
                  <dt>Người xử lý</dt>
                  <dd>Mặc định</dd>
                </Col>
              </Row>
            </ApprovalLevel>

            <ApprovalTemplateSortableLevels />
            <AddButton onClick={handleToggleLevelCreateModal(true)}>
              <PlusOutlined size={18} />
              Thêm cấp duyệt
            </AddButton>
          </Col>
        </Row>
        <ButtonFooter>
          <Space>
            <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
              Đóng
            </Button>
            <Button
              icon={<EditOutlined />}
              type={'primary'}
              htmlType={'submit'}
              loading={isCreating}>
              Tạo mới
            </Button>
          </Space>
        </ButtonFooter>
      </Form>

      <Modal
        width={800}
        title={'Thêm cấp duyệt'}
        visible={isVisibleLevelCreateModal}
        maskClosable={false}
        onCancel={handleToggleLevelCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleLevelCreateModal && (
          <ApprovalLevelCreate onCancel={handleToggleLevelCreateModal(false)} />
        )}
      </Modal>
    </>
  )
}
export default inject(
  'approvalTemplateStore',
  'selectPeopleStore',
)(observer(ApprovalTemplateCreate))

import React, { useEffect, useState } from 'react'
import { SalaryStepWrapper } from './SalaryPageStyled'
import { message, Modal, Steps, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import { ACL_ACTION_TYPE, SALARY_STATUS } from '../../constants'
import { useHistory, useParams } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import loadingAnimationStore from '../../stores/loadingAnimationStore'
import AccessControlAction from '../../components/AccessControlAction'

const { Step } = Steps
const { confirm } = Modal

const SalaryStep = props => {
  const { salaryStore } = props

  const { salaryInfo } = salaryStore
  const { id: salaryCode } = useParams()

  const history = useHistory()

  const [currentStepSalary, setCurrentStepSalary] = useState(0)

  useEffect(() => {
    if (!salaryInfo.status) {
      setCurrentStepSalary(0)
    }
    if (salaryInfo.status === SALARY_STATUS.PENDING) {
      setCurrentStepSalary(1)
    }
    if (salaryInfo.status === SALARY_STATUS.TRUONG_BAN_APPROVED) {
      setCurrentStepSalary(2)
    }
    if (salaryInfo.status === SALARY_STATUS.TGD_APPROVED) {
      setCurrentStepSalary(3)
    }
  }, [salaryInfo])

  const userCreated =
    salaryInfo.action_history &&
    Array.isArray(salaryInfo.action_history) &&
    salaryInfo.action_history.find(
      item => item.action === SALARY_STATUS.PENDING
    )?.user
  const truongBanApprove =
    salaryInfo.action_history &&
    Array.isArray(salaryInfo.action_history) &&
    salaryInfo.action_history.find(
      item => item.action === SALARY_STATUS.TRUONG_BAN_APPROVED
    )?.user
  const TGDApprove =
    salaryInfo.action_history &&
    Array.isArray(salaryInfo.action_history) &&
    salaryInfo.action_history.find(
      item => item.action === SALARY_STATUS.TGD_APPROVED
    )?.user

  const handleDeleteSalary = async () => {
    if (!salaryCode) return
    try {
      loadingAnimationStore.showSpinner(true)
      await salaryStore.deleteSalary(salaryCode)
      history.push('/salary')
      message.success('Xóa bảng lương thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showDeleteConfirm = () => {
    confirm({
      title: 'Bạn có chắc muốn xóa bảng lương này ?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      okType: 'danger',
      cancelText: 'Không',
      onOk: async () => {
        await handleDeleteSalary()
      },
      onCancel() {},
    })
  }

  return (
    <SalaryStepWrapper>
      <div className={'salary-title'}>
        <span>
          {!!salaryCode
            ? salaryInfo.title || 'Bảng lương'
            : 'Trình duyệt bảng lương'}
        </span>
        {currentStepSalary === 1 && (
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.delete__SALARY}>
            <Tooltip title={'Xóa bảng lương'}>
              <span className={'btn-delete'} onClick={showDeleteConfirm}>
                <DeleteOutlined style={{ fontSize: 16, color: 'red' }} />
              </span>
            </Tooltip>
          </AccessControlAction>
        )}
      </div>
      <div className={'salary-step'}>
        <Steps labelPlacement={'vertical'} current={currentStepSalary}>
          <Step
            title="Tạo bảng lương"
            description={
              userCreated && (
                <div className={'description-step'}>
                  Bởi <b>{userCreated.name_uppercase}</b>
                </div>
              )
            }
          />
          <Step
            title="Trưởng ban duyệt"
            description={
              truongBanApprove && (
                <div className={'description-step'}>
                  Bởi <b>{truongBanApprove.name_uppercase}</b>
                </div>
              )
            }
          />
          <Step
            title={<div style={{ minWidth: 135 }}>Tổng giám đốc duyệt</div>}
            description={
              TGDApprove && (
                <div className={'description-step'}>
                  Bởi <b>{TGDApprove.name_uppercase}</b>
                </div>
              )
            }
          />
        </Steps>
      </div>
    </SalaryStepWrapper>
  )
}

SalaryStep.propTypes = {}

export default inject(
  'salaryStore',
  'loadingAnimationStore',
)(observer(SalaryStep))

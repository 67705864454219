import React, { useEffect } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd'
import { inject, observer } from 'mobx-react'
import {
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { yellowPrimary } from '../../../color'
import subTargetWorkCreateStore from './subTargetWorkCreateStore'
import Text from 'antd/es/typography/Text'
import date_format from '../../../date_format'
import { ASSIGNEE_TYPE } from '../../../constants'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import { EmptyText } from '../../../components/Common/CellText'
import selectUserStore from '../../../stores/selectUserStore'
import { isNil } from 'lodash-es'

const SubTargetWorkCreateModal = props => {
  const {
    isVisibleSubTargetWorkCreateModal,
    handleCloseSubTargetWorkCreateModal,
    onSelectedWorks,
  } = props

  const {
    queryParams,
    setQueryParams,
    workList,
    isLoading,
    totalWork,
    selectedWorks,
    setSelectedWorks,
    assignedWorks,
  } = subTargetWorkCreateStore

  useEffect(() => {
    if (isVisibleSubTargetWorkCreateModal) {
      subTargetWorkCreateStore.loadWorks()
    }

    return () => {
      subTargetWorkCreateStore.clearQueryParams()
    }
  }, [isVisibleSubTargetWorkCreateModal])

  const [formSearch] = Form.useForm()

  const NGAY_TAO_WIDTH = 120
  const TITLE_WIDTH = 800
  const NGUOI_XU_LY_WIDTH = 220

  const columns = [
    {
      title: 'Ngày tạo',
      width: NGAY_TAO_WIDTH,
      render: record => (
        <Text disabled={checkDisable(record)}>
          {date_format.DEFAULT_DATE(record.created_at)}
        </Text>
      ),
    },
    {
      title: 'Công việc',
      width: TITLE_WIDTH,
      render: record => (
        <Text disabled={checkDisable(record)}>{record.title}</Text>
      ),
    },
    {
      title: 'Người xử lý',
      width: NGUOI_XU_LY_WIDTH,
      render: record => {
        const handlerSubList = selectUserStore.convertListToDefault(
          record.assignees.filter(
            user =>
              user.assignee_code !== 'admin' &&
              user.permission === ASSIGNEE_TYPE.HANDLER
          )
        )

        return (
          <div className={'assign'} onClick={e => e.stopPropagation()}>
            {handlerSubList.length > 0 ? (
              <PopoverWatchTime
                userList={handlerSubList}
                title={'Danh sách người xử lý'}
              />
            ) : (
              <EmptyText>Không có người xử lý.</EmptyText>
            )}
          </div>
        )
      },
    },
  ]

  const onChangePagination = async pageIndex => {
    queryParams.page = pageIndex - 1
    setQueryParams(queryParams)
    try {
      await subTargetWorkCreateStore.loadWorks()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      subTargetWorkCreateStore.isLoading = false
    }
  }

  const handleSearch = async values => {
    queryParams.title = values.keyword ? values.keyword.trim() : null
    queryParams.page = 0
    setQueryParams(queryParams)
    try {
      await subTargetWorkCreateStore.loadWorks()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      subTargetWorkCreateStore.isLoading = false
    }
  }

  const rowSelection = {
    type: 'radio',
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log('selectedRowKeys', selectedRowKeys, 'selectedRows', selectedRows)
      setSelectedWorks(selectedRows)
    },
    selectedRowKeys: selectedWorks.map(doc => doc.code),
    getCheckboxProps: record => ({
      disabled: checkDisable(record),
      name: record.title,
    }),
  }

  const checkDisable = record => {
    let disabled = !isNil(assignedWorks.find(work => work.code === record.code))
    // console.log('checkDisable', record, 'disabled', disabled)
    return disabled
  }

  const onSelectDocument = document => {
    if (!checkDisable(document)) {
      setSelectedWorks([document])
    }
  }

  const onClose = () => {
    formSearch.resetFields()
    subTargetWorkCreateStore.clear()
    handleCloseSubTargetWorkCreateModal()
  }

  return (
    <Modal
      closable={false}
      footer={null}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span>Chọn công việc</span>
          <Space>
            <Popconfirm
              placement="top"
              title={'Bạn có muốn hủy chọn tất cả công việc?'}
              onConfirm={() => {
                subTargetWorkCreateStore.clearSelected()
                formSearch.resetFields()
              }}
              okText="Đồng ý"
              cancelText="Không">
              <Button
                icon={<WarningOutlined style={{ color: yellowPrimary }} />}>
                Hủy chọn
              </Button>
            </Popconfirm>
            <Button
              icon={<CheckOutlined />}
              type={'primary'}
              onClick={() => {
                formSearch.resetFields()
                onSelectedWorks()
              }}>
              Đồng ý
            </Button>
            <Tooltip title={'Đóng'}>
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => {
                  onClose()
                }}
              />
            </Tooltip>
          </Space>
        </div>
      }
      width={1100}
      visible={isVisibleSubTargetWorkCreateModal}
      onCancel={onClose}>
      <Form form={formSearch} layout={'horizontal'} onFinish={handleSearch}>
        <div
          style={{ display: 'flex', gap: 20, justifyContent: 'space-between' }}>
          <Row gutter={[16, 16]} style={{ flexGrow: 1 }}>
            <Col span={24}>
              <Form.Item label={'Tìm kiếm: '} name={'keyword'}>
                <Input placeholder={'Nhập từ khóa'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ flex: '0 0 70px' }}>
            <Button
              icon={<SearchOutlined />}
              htmlType={'submit'}
              type={'primary'}>
              Tìm kiếm
            </Button>
          </Form.Item>
        </div>
      </Form>

      <Table
        rowSelection={rowSelection}
        rowKey={record => record.code}
        columns={columns}
        dataSource={workList}
        pagination={false}
        loading={isLoading}
        rowClassName={'ant-row-pointer'}
        onRow={record => {
          return {
            onClick: () => onSelectDocument(record),
          }
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '15px 0',
        }}>
        <Pagination
          onChange={e => onChangePagination(e)}
          hideOnSinglePage={true}
          total={totalWork}
          pageSize={queryParams.size}
          current={queryParams.page + 1}
          showSizeChanger={false}
          showLessItems
        />
      </div>
    </Modal>
  )
}

SubTargetWorkCreateModal.propTypes = {}
export default inject('kpiStore')(observer(SubTargetWorkCreateModal))

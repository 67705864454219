import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const DocumentAccessibilityRequest = {
  getDocumentAccessibilities: (type, access_type) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/documents-accessibilities`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        type,
        access_type,
      },
    }),

  updateDocumentAccessibilities: data =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/documents-accessibility`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
}

import styled from 'styled-components'

export const ModalBox = styled.div`
  .ant-collapse-item {
    margin-bottom: 60px;

    .action {
      display: none;
    }
  }

  .ant-collapse-item-active {
    border: 1px solid #d9d9d9;
    border-radius: 5px;

    .action {
      display: block;
    }
  }

  .ant-collapse-item:last-child {
    margin-bottom: 30px;
  }

  .ant-collapse-header {
    margin-top: -33px;
  }

  .ant-collapse-arrow {
    padding: 5px;
    background: white;
  }
`

export const FlexCenter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const BGWhite = styled.div`
  background: white;
`
export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
`

export const StatusBox = styled.div`
  padding: 10px;
  margin-bottom: 40px;
`

export const PanelTitle = styled.div`
  padding: 5px;
  background: white;
  font-size: 16px;
  font-weight: bold;
`

export const SpacingBox = styled.div`
  padding: 5px;
  background: white;
`

export const LineBox = styled.div`
  flex: 1;
  border-bottom: 1px solid #d9d9d9;
`

export const ActionLevel = styled.div`
  padding: 0 16px;
`
export const ApproverBox = styled.div`
  margin: 10px 20px;
  font-size: 18px;

  .status,
  .name {
    margin-left: 10px;
  }

  .error {
    color: red;
  }

  .success {
    color: green;
  }

  .warning {
    color: blue;
  }

  .avatar {
    height: 40px;
    width: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
`

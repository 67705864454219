import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const SignAccountRequest = {
  getList: (pageSize, pageIndex, keyword) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/sign-account`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: pageIndex,
        size: pageSize,
        keyword: keyword,
      },
    }),

  delete: id =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/sign-account/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
    }),

  create: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/sign-account`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
      data: data,
    }),

  update: signAccount =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/sign-account`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
      },
      data: signAccount,
    }),
}

import React from 'react'
import authenticationStore from '../../stores/authenticationStore'
import date_utils from '../../date_utils'

const IFrameHttp = props => {
  const { url, isUBQLV, isFileTruc } = props

  // //for case component display in more than 1 on the page. Ex: chi tiet vb den
  const dt = date_utils.current().getMilliseconds()
  //
  // useEffect(() => {
  //
  //   function handler() {
  //     if (this.readyState === this.DONE) {
  //       if (this.status === 200) {
  //         let src = URL.createObjectURL(this.response)
  //         console.log('src', src)
  //         let element = document.querySelector(`#content-holder-${dt}`)
  //         console.log('element', element)
  //         element.src = src
  //       } else {
  //         console.error('XHR failed', this)
  //       }
  //     }
  //   }
  //
  //   var xhr = new XMLHttpRequest()
  //   xhr.open('GET', url)
  //   xhr.onreadystatechange = handler
  //   xhr.responseType = 'blob'
  //
  //   //for access file come from cloud
  //   xhr.setRequestHeader('Authorization', `Bearer ${JSON.parse(authenticationStore.appToken).access_token}`)
  //
  //   // xhr.send()
  //
  // }, [])

  return (
    <iframe
      id={`content-holder-${dt}`}
      src={
        isUBQLV || isFileTruc
          ? url
          : `${url}?access_token=${
              JSON.parse(authenticationStore.appToken).access_token
            }`
      }
      style={{
        border: 'none',
        height: '100%',
        width: '100%',
        minHeight: '89vh',
      }}
    />
  )
}

export default IFrameHttp

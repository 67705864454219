import React, { useCallback, useEffect, useState } from 'react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { EmptyText, TitleContentBox } from '../../components/Common/CellText'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import utils from '../../utils'
import { Button, message, Popconfirm, Tag, Tooltip, Upload } from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { blue } from '../../color'
import { inject, observer } from 'mobx-react'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import { toJS } from 'mobx'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'

const MissionDetailFiles = props => {
  const {
    isDetailList,
    files,
    resultTask,
    taskStore,
    fileStore,
    missionId,
    handleSetIsRefreshListTask,
    handleSetTaskDetail,
    title
  } = props

  const [fileList, setFileList] = useState([])
  const [fileUploadedList, setFileUploadedList] = useState([])
  const [loadingFile, setLoadingFile] = useState({
    status: false,
    tip: 'Đang tải...',
  })
  useEffect(() => {
    setFileUploadedList(files || [])
  }, [files])

  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }, [])

  const handleUpdateFiles = async () => {
    const percentComplete = resultTask.phan_tram_hoan_thanh
      ? parseInt(resultTask.phan_tram_hoan_thanh)
      : 0
    const uploadFileList = fileList.map(file => file.originFileObj)
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    try {
      setLoadingFile({
        status: true,
        tip: 'Đang cập nhật tài liệu...',
      })
      const response = await Promise.all(batchUploadArr)
      const submitData = {
        kien_nghi: resultTask.kien_nghi,
        noi_dung_ket_qua: resultTask.noi_dung_ket_qua_giao,
        ty_le_hoan_thanh: percentComplete,
        files: response
          .map(response => response.data.file_id)
          .concat(fileUploadedList.map(file => file.file_id)),
      }
      await taskStore.updateTask(missionId, submitData)
      if (isDetailList) {
        // await taskStore.getTaskListDetailByTaskCode(missionId)
        await taskStore.getTaskDetail(missionId)
        handleSetTaskDetail(toJS(taskStore.taskDetail))
        if (handleSetIsRefreshListTask) {
          handleSetIsRefreshListTask()
        }
      } else {
        await taskStore.getTaskDetail(missionId)
      }
      setFileList([])
      message.success('Cập nhật tài liệu thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      setLoadingFile({
        status: false,
        tip: 'Đang tải...',
      })
    }
  }

  const handleDeleteFileUploaded = async fileId => {
    if (fileList.length > 0) {
      setFileUploadedList(
        fileUploadedList.filter(file => file.file_id !== fileId)
      )
      return
    }

    const percentComplete = resultTask.phan_tram_hoan_thanh
      ? parseInt(resultTask.phan_tram_hoan_thanh)
      : 0

    try {
      setLoadingFile({
        status: true,
        tip: 'Đang xóa tài liệu...',
      })
      const submitData = {
        kien_nghi: resultTask.kien_nghi,
        noi_dung_ket_qua: resultTask.noi_dung_ket_qua_giao,
        ty_le_hoan_thanh: percentComplete,
        files: fileUploadedList
          .filter(file => file.file_id !== fileId)
          .map(file => file.file_id),
      }
      await taskStore.updateTask(missionId, submitData)
      if (isDetailList) {
        // await taskStore.getTaskListDetailByTaskCode(missionId)
        await taskStore.getTaskDetail(missionId)
        handleSetTaskDetail(toJS(taskStore.taskDetail))
        if (handleSetIsRefreshListTask) {
          handleSetIsRefreshListTask()
        }
      } else {
        await taskStore.getTaskDetail(missionId)
      }
      message.success('Xóa tài liệu thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      setLoadingFile({
        status: false,
        tip: 'Đang tải...',
      })
    }
  }

  return (
    <ContentBlockWrapper
      style={
        isDetailList
          ? {
              paddingTop: 5,
              position: 'relative',
              border: `1px solid ${blue}`,
              boxShadow:
                'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
            }
          : { position: 'relative' }
      }>
      {isDetailList ? (
        <h3
          style={{
            marginBottom: 8,
            color: blue,
            fontSize: 16,
            textDecoration: 'underline',
          }}>
          {title}
        </h3>
      ) : (
        <TitleContentBox>{title}</TitleContentBox>
      )}
      <div>
        {fileUploadedList.length !== 0 ? (
          fileUploadedList.map((file, index) => (
            <div
              key={file.file_id}
              style={{
                marginBottom: index + 1 === fileUploadedList.length ? 0 : 8,
                borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <FileItem
                file_id={file.file_id}
                file_name={file.file_title}
                file_type={utils.getExtensionFile(file.file_title)}
              />
              <Popconfirm
                title="Bạn có chắc chắn muốn xóa tài liệu này không?"
                onConfirm={() => handleDeleteFileUploaded(file.file_id)}
                okText="Xóa"
                okType={'danger'}
                cancelText="Không">
                <Tooltip title={'Xóa'}>
                  <span
                    style={{
                      cursor: 'pointer',
                      padding: 5,
                      borderRadius: 3,
                      transform: 'translateY(-6px)',
                    }}>
                    <DeleteOutlined style={{ color: '#ff4d4f' }} />
                  </span>
                </Tooltip>
              </Popconfirm>
            </div>
          ))
        ) : (
          <EmptyText>Chưa có tài liệu nào!</EmptyText>
        )}
        {fileList.length > 0 && (
          <div
            style={{
              paddingBottom: 8,
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
              margin: '8px 0',
              fontWeight: 500,
            }}>
            <Tag color={'blue'}>Tài liệu thêm mới:</Tag>
          </div>
        )}
        {fileList.map(file => (
          <div
            key={file.uid}
            style={{
              paddingBottom: 8,
              marginBottom: 8,
              borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <FileItem
              noAction
              file_id={file.file_id}
              file_name={file.name}
              file_type={utils.getExtensionFile(file.name)}
            />
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.cap_nhat__MISSION}>
              <Popconfirm
                title="Bạn có chắc chắn muốn xóa tài liệu này không?"
                onConfirm={() => {
                  const newFileList = fileList.filter(
                    item => item.uid !== file.uid
                  )
                  if (newFileList.length === 0) {
                    setFileUploadedList(files || [])
                  }
                  setFileList(newFileList)
                }}
                okText="Xóa"
                okType={'danger'}
                cancelText="Không">
                <Tooltip title={'Xóa'}>
                  <span
                    style={{
                      cursor: 'pointer',
                      padding: 5,
                      borderRadius: 3,
                      transform: 'translateY(-6px)',
                    }}>
                    <DeleteOutlined style={{ color: '#ff4d4f' }} />
                  </span>
                </Tooltip>
              </Popconfirm>
            </AccessControlAction>
          </div>
        ))}
      </div>
      <AccessControlAction aclActionType={ACL_ACTION_TYPE.cap_nhat__MISSION}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 15,
          }}>
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={true}
            onChange={handleChangeFile}
            showUploadList={false}
            beforeUpload={() => false}>
            <Button icon={<PlusOutlined />} type={'primary'} size={'small'}>
              Thêm tài liệu
            </Button>
          </Upload>
          {fileList.length > 0 && (
            <div>
              <Button
                style={{ marginRight: 8 }}
                onClick={() => {
                  setFileList([])
                  setFileUploadedList(files || [])
                }}
                size={'small'}
                danger
                icon={<CloseOutlined />}>
                Hủy bỏ
              </Button>
              <Button
                onClick={handleUpdateFiles}
                size={'small'}
                type={'primary'}
                icon={<CheckOutlined />}>
                Lưu lại
              </Button>
            </div>
          )}
        </div>
      </AccessControlAction>
      <SpinnerInlineComponent
        sizeSpin={'small'}
        isLoading={loadingFile.status}
        tip={loadingFile.tip}
      />
    </ContentBlockWrapper>
  )
}

MissionDetailFiles.propTypes = {}

export default inject(
  'taskStore',
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore',
)(observer(MissionDetailFiles))

import React, { useState } from 'react'
import { Collapse, Empty } from 'antd'
import { inject, observer } from 'mobx-react'
import SubTargetList from './SubTargetList'
import {
  CaretRightOutlined,
  LineChartOutlined,
  TrophyFilled,
} from '@ant-design/icons'
import '../KpiPage.css'
import { useHistory, useParams } from 'react-router-dom'

const { Panel } = Collapse

const TargetList = props => {
  const { targetList, kpiId, authenticationStore } = props

  let history = useHistory()
  const { kpi_quarter_code } = useParams()

  const [targetOpenList, setTargetOpenList] = useState([])

  // useEffect(() => {
  //   setTargetOpenList(targetList.map(item => item.code))
  // }, [targetList])

  return (
    <div>
      <div className={'header-kpi-list'}>
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 6,
            fontSize: 14,
          }}>
          <TrophyFilled
            style={{ fontSize: 16, color: '#f8e008', fontWeight: 700 }}
          />
          Danh sách mục tiêu
        </div>
        <div style={{ display: 'flex', gap: 10 }}>
          <div style={{ width: 120, textAlign: 'center' }}>
            <LineChartOutlined
              style={{ fontSize: 16, marginRight: 4, color: '#ff6870' }}
            />
            Tỉ trọng cần đạt
          </div>
          <div style={{ width: 120, textAlign: 'center' }}>
            <LineChartOutlined
              style={{ fontSize: 16, marginRight: 4, color: '#52c41a' }}
            />
            Tỉ trọng đạt được
          </div>
        </div>
      </div>
      {targetList.length > 0 ? (
        targetList.map(target => (
          <Collapse
            activeKey={targetOpenList}
            key={target.code}
            onChange={() => {
              if (targetOpenList.includes(target.code)) {
                setTargetOpenList(
                  [...targetOpenList].filter(item => item !== target.code)
                )
              } else {
                setTargetOpenList([...targetOpenList, target.code])
              }
            }}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                style={{ transform: 'translateY(7px)' }}
                rotate={isActive ? 90 : 0}
              />
            )}
            bordered={false}
            ghost>
            <Panel
              key={target.code}
              header={
                <div
                  className={'header-target-item'}
                  onClick={e => {
                    e.stopPropagation()
                    history.push(
                      `/kpi/target/view/${kpi_quarter_code}/${kpiId}/${target.code}`
                    )
                  }}>
                  <div style={{ flexGrow: 1 }}>{target.name}</div>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <div
                      style={{
                        width: 120,
                        textAlign: 'center',
                        fontWeight: 500,
                        color: '#f5222d',
                      }}>
                      {target.expect_weight}%
                    </div>
                    <div
                      style={{
                        width: 120,
                        textAlign: 'center',
                        fontWeight: 500,
                        color: '#52c41a',
                      }}>
                      {target.actual_weight}%
                    </div>
                  </div>
                </div>
              }
              className={'target-item'}
              style={
                targetOpenList.includes(target.code)
                  ? {
                      backgroundColor: '#e9f0fd',
                    }
                  : {}
              }>
              <SubTargetList subTargetList={target.children || []} />
            </Panel>
          </Collapse>
        ))
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={'Không có mục tiêu nào!'}
        />
      )}
    </div>
  )
}

TargetList.propTypes = {}

export default inject('kpiStore', 'authenticationStore')(observer(TargetList))

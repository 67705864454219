import React, { Fragment, memo, useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Pagination, Space } from 'antd'
import {
  CellEclipseBox,
  TableBottomPaginationBlock,
} from '../../components/Common/Table'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  CloseCircleOutlined,
  FilterOutlined,
  PaperClipOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import uuid from 'uuid'
import FileDownload from 'js-file-download'
import moment from 'moment'
import AdvanceFilterBlock from './AdvanceFilterBlock'
import TableComponent from '../../components/Common/TableComponent'
import { ACL_ACTION_TYPE, DATE_FORMAT_DEFAULT } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction'

const DocumentVBPCPage = props => {
  const { loadingAnimationStore, documentStore, history } = props

  const { listVBPC, totalCount, filterVBPC } = documentStore

  const downloadFile = useCallback(
    record => {
      loadingAnimationStore.showSpinner(true)
      documentStore
        .getAttachmentVBPC({
          recordId: record.id,
          fileId: record.file_id,
        })
        .then(response => {
          loadingAnimationStore.showSpinner(false)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          FileDownload(
            blob,
            `${record.document_name}-${record.document_number}`
          )
        })
        .catch(() => {
          loadingAnimationStore.showSpinner(false)
        })
    },
    [documentStore, loadingAnimationStore]
  )

  const tableColumn = [
    {
      title: 'Tên văn bản',
      align: 'left',
      render: record => record?.document_name,
    },
    {
      title: 'Số quyết định ban hành',
      render: record => (
        <CellEclipseBox>{record?.document_number}</CellEclipseBox>
      ),
    },
    {
      title: 'Ngày ban hành',
      render: record => (
        <CellEclipseBox>
          <time>{moment(record?.issue_date).format(DATE_FORMAT_DEFAULT)}</time>
        </CellEclipseBox>
      ),
    },
    {
      title: 'Cấp ban hành',
      render: record => (
        <CellEclipseBox>{record?.issue_level || '-'}</CellEclipseBox>
      ),
    },
    {
      title: 'Ban chủ trì xây dựng',
      render: record => (
        <CellEclipseBox>{record?.contruction_board || '-'}</CellEclipseBox>
      ),
    },
    {
      title: 'Trạng thái',
      render: record => (
        <CellEclipseBox>
          {
            ['Đã bãi bỏ', 'Hết hiệu lực', 'Còn hiệu lực'][
              parseInt(record.status) - 1
            ]
          }
        </CellEclipseBox>
      ),
    },
    {
      title: 'Tài liệu đính kèm',
      render: record => (
        <Button type="primary" onClick={() => downloadFile(record)}>
          <PaperClipOutlined className={'cursor-pointer'} /> Download
        </Button>
      ),
    },
  ]

  const onChangeTable = useCallback(
    (current, size) => {
      if (size !== filterVBPC.size) {
        filterVBPC.page = 1
        filterVBPC.size = size
      } else {
        filterVBPC.page = current
      }
      documentStore.setFilterVBPC(filterVBPC)
    },
    [documentStore, filterVBPC]
  )

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    documentStore
      .getVBPC(filterVBPC)
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => documentStore.clearDocument()
  }, [documentStore, filterVBPC, loadingAnimationStore])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Văn bản pháp chế | Tài liệu</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'Văn bản pháp chế'}
        hiddenGoBack>
        <div>
          <Button
            style={{ margin: '0 15px' }}
            type={filterVBPC.isOpen ? 'primary' : 'default'}
            ghost={filterVBPC.isOpen}
            onClick={() => {
              if (filterVBPC.isOpen) {
                documentStore.resetFilter()
              } else {
                documentStore.setFilterVBPC({
                  isOpen: !filterVBPC.isOpen,
                })
              }
            }}>
            {filterVBPC.isOpen ? (
              <Space>
                <CloseCircleOutlined />
                Tắt tìm kiếm nâng cao
              </Space>
            ) : (
              <Space>
                <FilterOutlined />
                Tìm kiếm nâng cao
              </Space>
            )}
          </Button>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__PHAP_CHE}>
            <Button
              onClick={() => history.push('/utility/vbpc/create')}
              style={{ marginBottom: 15 }}
              type={'primary'}>
              <PlusCircleOutlined />
              Tạo Văn bản pháp chế
            </Button>
          </AccessControlAction>
        </div>
      </PageTitle>
      {filterVBPC.isOpen ? (
        <Fragment>
          <AdvanceFilterBlock />
          <div style={{ marginBottom: 16 }} />
        </Fragment>
      ) : null}
      <ContentBlockWrapper>
        <TableComponent
          onRow={record => {
            return {
              onClick: () => history.push(`/utility/vbpc/view/${record.id}`),
            }
          }}
          rowKey={() => uuid()}
          columns={tableColumn}
          dataSource={listVBPC}
          pagination={false}
          onChange={onChangeTable}
        />
        <TableBottomPaginationBlock>
          <Pagination
            current={filterVBPC.page}
            pageSize={filterVBPC.size}
            total={totalCount}
            onChange={onChangeTable}
            showSizeChanger={false}
            showLessItems
          />
        </TableBottomPaginationBlock>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

DocumentVBPCPage.propTypes = {}

export default memo(
  inject('loadingAnimationStore', 'documentStore')(observer(DocumentVBPCPage)),
)

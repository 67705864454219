import React, { useCallback, useEffect, useState } from 'react'
import { ModalBody, ModalWrapper } from './SelectGroupPopupStyled'
import { inject, observer } from 'mobx-react'
import SelectGroupBox from './SelectGroupBox'
import { EmptyText } from '../Common/CellText'
import { Button, message, Popconfirm, Select, Space, Tag, Tooltip } from 'antd'
import { blue, yellowPrimary } from '../../color'
import {
  CloseOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { AvatarHasName } from '../Common/Avatar'
import { ASSIGNEE_TYPE } from '../../constants'
import SpinnerInlineComponent from '../SpinnerInlineComponent/SpinnerInlineComponent'
import authenticationStore from '../../stores/authenticationStore'
import EmptyContent from '../EmptyContent'

const { Option } = Select

const SelectGroupPopup = props => {
  const {
    isVisibleSelectGroupPopup,
    handleSubmitSelectGroup,
    handleCancelSelectGroup,
    selectPeopleStore,
    clearSelectGroup,
    loadingAnimationStore,
    groupType,
    onlySelectOne,
    noLoadSelectData,
    authenticationStore,
    companyStore,
  } = props

  const {
    assigneePopupType,
    selectGroupData,
    setSelectGroupData,
    setGroupList,
    originGroupList,
    companyCode,
  } = selectPeopleStore
  const { companyList } = companyStore

  const [disableGroupList, setDisableGroupList] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [prevSelectedGroupData, setPrevSelectedGroupData] = useState([])

  const handleRemoveSelect = useCallback(
    group => {
      setSelectGroupData({
        ...selectGroupData,
        [assigneePopupType]: selectGroupData[assigneePopupType].filter(
          el => el.id !== group.id
        ),
      })
    },
    [assigneePopupType, selectGroupData]
  )

  useEffect(() => {
    setPrevSelectedGroupData(selectGroupData[assigneePopupType])
  }, [isVisibleSelectGroupPopup])
  
  useEffect(() => {
    if (!assigneePopupType) return
    const selectedGroupList = [
      ...selectGroupData[ASSIGNEE_TYPE.LEADER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.COMBINER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ]
    setDisableGroupList(
      selectedGroupList
        .filter(el => el.permission !== assigneePopupType)
        .map(el => el.id)
    )
  }, [assigneePopupType])

  useEffect(() => {
    setGroupList(originGroupList)
  }, [isVisibleSelectGroupPopup])

  useEffect(() => {
    if (noLoadSelectData) return
    ;(async () => {
      if (companyCode) {
        if (authenticationStore.currentUser) {
          try {
            setIsLoading(true)
            await selectPeopleStore.getGroupList(
              groupType,
              authenticationStore.currentCompanyCode()
            )
          } catch (err) {
            console.log(err)
            message.error(err.vi || 'Đã có lỗi xảy ra!')
          } finally {
            setIsLoading(false)
          }
        }
      }
    })()
  }, [companyCode])

  useEffect(() => {
    if (authenticationStore.currentUser) {
      selectPeopleStore.companyCode = authenticationStore.currentCompanyCode()
    }
  }, authenticationStore.currentUser)

  const onCompanyChange = value => {
    console.log('onCompanyChange', value)

    // selectGroupData[assigneePopupType] = []
    // selectPeopleStore.clearSelectedByType(assigneePopupType)

    selectPeopleStore.companyCode = value
  }

  return (
    <ModalWrapper
      maskClosable={false}
      okText={'Đồng ý'}
      cancelText={'Hủy bỏ'}
      width={700}
      footer={null}
      style={{ top: 20 }}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Select
            // style={{ width: 200 }}
            notFoundContent={<EmptyContent />}
            onChange={onCompanyChange}
            value={companyCode}
            placeholder={'-- Chọn công ty --'}>
            {companyList.map(item => (
              <Option key={item.code} value={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
          <Space>
            <Popconfirm
              placement="top"
              title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
              onConfirm={() =>
                // clearSelectGroup
                //   ? clearSelectGroup()
                //   : handleCancelSelectGroup()

                {
                  selectPeopleStore.handleCancelSelectGroup()
                  handleCancelSelectGroup()
                }
              }
              okText="Đồng ý"
              cancelText="Không">
              <Button
                danger
                icon={<WarningOutlined style={{ color: yellowPrimary }} />}>
                Hủy chọn
              </Button>
            </Popconfirm>
            <Button
              type={'primary'}
              onClick={() => {
                JSON.stringify(selectGroupData[assigneePopupType]) !==
                JSON.stringify(prevSelectedGroupData)
                  ? handleSubmitSelectGroup()
                  : handleCancelSelectGroup()
              }}>
              Đồng ý
            </Button>
            <Tooltip title={'Đóng'}>
              <CloseOutlined
                onClick={() => {
                  setSelectGroupData({
                    ...selectGroupData,
                    [assigneePopupType]: prevSelectedGroupData,
                  })
                  handleCancelSelectGroup()
                }}
              />
            </Tooltip>
          </Space>
        </div>
      }
      closable={false}
      forceRender={true}
      visible={isVisibleSelectGroupPopup}>
      <ModalBody>
        <div className={'list-box'}>
          <label
            style={{
              width: 90,
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
            }}>
            <UnorderedListOutlined style={{ marginRight: 4, color: blue }} />
            Danh sách:
          </label>
          {assigneePopupType &&
          selectGroupData[assigneePopupType].length > 0 ? (
            selectGroupData[assigneePopupType].map(el => {
              return (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      )
                    }
                  />
                </Tag>
              )
            })
          ) : (
            <EmptyText>
              <span style={{ lineHeight: '34px' }}>
                Chưa có nhóm nào được chọn
              </span>
            </EmptyText>
          )}
        </div>
        <div className={'modal-body'} style={{ position: 'relative' }}>
          <SelectGroupBox
            disableGroupList={disableGroupList}
            onlySelectOne={onlySelectOne}
            isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
          />
          <SpinnerInlineComponent
            sizeSpin={'small'}
            isLoading={isLoading}
            noBackground
          />
          <div />
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}

SelectGroupPopup.propTypes = {}

export default inject(
  'selectPeopleStore',
  'loadingAnimationStore',
  'authenticationStore',
  'companyStore'
)(observer(SelectGroupPopup))

import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const SystemOptionRequest = {
  getDefaultGroup: key =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/system_option/default-group/${key}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  setDefaultGroup: (key, group_id) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/system_option/default-group/${key}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        group_id: group_id,
      },
    }),
}

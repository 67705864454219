import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import DocumentList from './TabContent/DocumentList'
import SignatureManagement from './TabContent/SignatureManagement'
import CreateSignatureModal from './CreateSignatureModal/CreateSignatureModal'
// Ant design
import { Button, message, Tabs } from 'antd'
import {
  AlibabaOutlined,
  FileTextOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
// Style Components
import { TabsNavigation } from './DigitalSignaturePageStyled'
// Mobx
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import MySign from './TabContent/MySign'
import PopupSign from '../../components/PopupSign'
import { ACL_ACTION_TYPE } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction'

const { TabPane } = Tabs

const DigitalSignaturePage = props => {
  const { signedDocumentStore, loadingAnimationStore, aclStore } = props
  const { aclActionsByUser } = aclStore

  const { pageIndex, pageSize, keyword } = signedDocumentStore

  const [pageContent, setPageContent] = useState(null)
  const [isVisiblePopupSign, setIsVisiblePopupSign] = useState(false)

  // 001 : Danh sách tài liệu đã ký
  // 002: Quản lý chữ ký
  const [titlePage, setTitlePage] = useState('')
  const [
    isCreateSignatureModalVisible,
    setIsCreateSignatureModalVisible,
  ] = useState(false)

  const handleClosePopupSign = async () => {
    setIsVisiblePopupSign(false)
    signedDocumentStore.clearFilePDFSign()
    loadingAnimationStore.setTableLoading(true)
    try {
      await signedDocumentStore.getAllDocumentSigned()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  useEffect(() => {
    if (titlePage === 'signed-document') {
      setPageContent(<DocumentList />)
    }
    if (titlePage === 'my-sign') {
      setPageContent(<MySign />)
    }
    if (titlePage === 'sign-manager') {
      setPageContent(<SignatureManagement />)
    }
  }, [titlePage])

  useEffect(() => {
    let title = ''
    if (aclStore.checkAccessControlAction(ACL_ACTION_TYPE.tab_quan_ly__KY_SO)) {
      title = 'sign-manager'
    }
    if (
      aclStore.checkAccessControlAction(
        ACL_ACTION_TYPE.tab_chu_ky_so_cua_toi__KY_SO
      )
    ) {
      title = 'my-sign'
    }
    if (
      aclStore.checkAccessControlAction(ACL_ACTION_TYPE.tab_tai_lieu__KY_SO)
    ) {
      title = 'signed-document'
    }
    setTitlePage(title)
  }, [aclActionsByUser])

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.setTableLoading(true)
      try {
        await signedDocumentStore.getAllDocumentSigned()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
    return () => signedDocumentStore.clearSignedDocumentListStore()
  }, [])

  return (
    <DashboardLayout>
      <Helmet>
        <title>{'Ký số | Liên thông văn bản'}</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={
          titlePage === '001' ? 'Danh sách tài liệu đã ký' : 'Quản lý chữ ký'
        }>
        <AccessControlAction
          aclActionType={ACL_ACTION_TYPE.create__TAI_LIEU_DA_KY}>
          <Button
            type="primary"
            size={'large'}
            icon={<FileTextOutlined />}
            onClick={() => setIsVisiblePopupSign(true)}>
            Ký số tài liệu
          </Button>
        </AccessControlAction>
      </PageTitle>
      <ContentBlockWrapper>
        <div style={{ minHeight: '83vh' }}>
          <TabsNavigation
            onTabClick={key => setTitlePage(key)}
            tabBarExtraContent={
              titlePage === 'my-sign' ? (
                <AccessControlAction
                  aclActionType={ACL_ACTION_TYPE.create__CHU_KY_CUA_TOI}>
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={() => setIsCreateSignatureModalVisible(true)}>
                    Tạo chữ ký mới
                  </Button>
                </AccessControlAction>
              ) : null
            }>
            {aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.tab_tai_lieu__KY_SO
            ) && (
              <TabPane
                tab={
                  <span>
                    <UnorderedListOutlined />
                    Tài liệu đã ký
                  </span>
                }
                key="signed-document"
              />
            )}
            {aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.tab_chu_ky_so_cua_toi__KY_SO
            ) && (
              <TabPane
                tab={
                  <span>
                    <AlibabaOutlined />
                    Chữ ký của tôi
                  </span>
                }
                key="my-sign"
              />
            )}
            {aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.tab_quan_ly__KY_SO
            ) && (
              <TabPane
                tab={
                  <span>
                    <SettingOutlined />
                    Quản lý chữ ký
                  </span>
                }
                key="sign-manager"
              />
            )}
          </TabsNavigation>
          {pageContent}
        </div>
      </ContentBlockWrapper>
      <CreateSignatureModal
        isCreateSignatureModalVisible={isCreateSignatureModalVisible}
        handleCloseCreateSignatureModal={() =>
          setIsCreateSignatureModalVisible(false)
        }
      />

      <PopupSign
        isVisiblePopupSign={isVisiblePopupSign}
        handleClosePopupSign={handleClosePopupSign}
      />
    </DashboardLayout>
  )
}

DigitalSignaturePage.propTypes = {}

export default inject(
  'signedDocumentStore',
  'loadingAnimationStore',
  'aclStore',
)(observer(DigitalSignaturePage))

import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const UserTrucRequest = {
  getUserListTruc: (pageSize, pageIndex, name, isEnabled) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/truc-users`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
        'truc-token': JSON.parse(authenticationStore.trucToken).access_token,
      },
      params: {
        page: pageIndex,
        size: pageSize,
        ...(!utils.isNullish(name) && { name: name }),
        ...(!utils.isNullish(isEnabled) && { isEnabled: isEnabled }),
      },
    }),

  getGroupListTruc: (pageIndex, pageSize) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/truc-group`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
        'truc-token': JSON.parse(authenticationStore.trucToken).access_token,
      },
      params: {
        page: pageIndex,
        size: pageSize,
      },
    }),

  getMemberListByIdGroupTruc: (groupId, pageIndex, pageSize) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/truc-group/${groupId}/member`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: pageIndex,
        size: pageSize,
      },
    }),
}

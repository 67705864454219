import { action, observable } from 'mobx'
import { ProposalSalaryRequest } from '../requests/ProposalSalaryRequest'
// Request
import { ProposalsRequest } from '../requests/ProposalsRequest'

class ProposalSalaryStore {
  /** Position list */
  @observable proposalSalaryList = []
  @observable proposalSalaryTotal = 0
  @observable salaryInfo = {}
  @observable employeeSalaryData = {}
  @observable managerSalaryData = {}
  // filter
  @observable filterPage = 0
  @observable filterSize = 10
  @observable filterProposalPermission = null
  @observable filterKeyword = null
  @observable filterProposalStatus = null
  @action setQueryParams = (param, value) => {
    this[param] = value
  }
  @action clearQueryParams = () => {
    this.filterPage = 0
    this.filterSize = 10
    this.filterProposalPermission = null
    this.filterKeyword = null
    this.filterProposalStatus = null
  }
  // end filter

  @action getProposalSalaryList = () => {
    return new Promise((resolve, reject) => {
      ProposalsRequest.getProposals(
        this.filterPage,
        this.filterSize,
        this.filterKeyword,
        this.filterProposalStatus,
        'type=PROPOSAL_SALARY',
        this.filterProposalPermission
      )
        .then(response => {
          this.proposalSalaryList = response.data.data
          this.proposalSalaryTotal = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createSalary = payload => {
    return new Promise((resolve, reject) => {
      ProposalSalaryRequest.createSalary(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action clearProposalSalaryList = () => {
    this.proposalSalaryList.length = 0
    this.proposalSalaryTotal = 0
  }

  @action getSalaryDetail = async salaryCode => {
    const proposalSalaryRes = await ProposalSalaryRequest.getSalaryDetail(
      salaryCode
    )

    const salaryRequestRes = await ProposalSalaryRequest.getSalaryRequestDetail(
      proposalSalaryRes.data.salary_request_code
    )

    this.salaryInfo = {
      ...proposalSalaryRes.data,
      file_name: salaryRequestRes.data.file_summary.file_name,
      file_id: salaryRequestRes.data.file_summary.file_id,
      salary_month: salaryRequestRes.data.salary_month,
    }

    this.employeeSalaryData = salaryRequestRes.data.file_summary.data_employee
    this.managerSalaryData = salaryRequestRes.data.file_summary.data_manager
  }

  @action updateProposalSalary = (salaryCode, payload) => {
    return new Promise((resolve, reject) => {
      ProposalSalaryRequest.updateProposalSalary(salaryCode, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action clearSalary = () => {
    this.employeeSalaryData = {}
    this.managerSalaryData = {}
    this.salaryInfo = {}
  }
}
export default new ProposalSalaryStore()

import React, { useEffect, useState } from 'react'
import { Button, InputNumber, message, Popconfirm, Tooltip } from 'antd'
import {
  DeleteOutlined,
  PlusOutlined,
  TagOutlined,
  WarningOutlined,
} from '@ant-design/icons'
// import './KpiEditSubTargetPage.css'
import { blue } from '../../color'
import { inject, observer } from 'mobx-react'
import SubTargetWorkCreateModal from './SubTargetWorkCreateModal/SubTargetWorkCreateModal'
import { EmptyText } from '../../components/Common/CellText'
import subTargetWorkCreateStore from './SubTargetWorkCreateModal/subTargetWorkCreateStore'
import { ACL_ACTION_TYPE, KPI_TYPE } from '../../constants'
import { useParams } from 'react-router-dom'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'

const SubTargetWorkList = React.forwardRef((props, ref) => {
  const {
    authenticationStore,
    workList,
    kpiStore,
    subTargetId,
    onDelete,
  } = props
  const { subTargetListByTargetId } = kpiStore
  const [workListState, setWorkListState] = useState([])
  const [originWorkListState, setOriginWorkListState] = useState([])

  const { kpiId, targetId } = useParams()

  useEffect(() => {
    setWorkListState(workList)
    setOriginWorkListState(workList)

    kpiStore.setWorkListStateBy(subTargetId, workList)
  }, [workList])

  const [validateInputExpectWeight, setValidateInputExpectWeight] = useState({
    correct: true,
    workId: null,
  })

  useEffect(() => {
    const expectWeightTotal = workListState.reduce(
      (previousValue, currentValue) => {
        return previousValue + currentValue.expect_weight
      },
      0
    )
    if (expectWeightTotal === 100) {
      setValidateInputExpectWeight({
        ...validateInputExpectWeight,
        correct: true,
      })
    } else {
      setValidateInputExpectWeight({
        ...validateInputExpectWeight,
        correct: false,
      })
    }

    kpiStore.setWorkListStateBy(subTargetId, workListState)
    // console.log('workListState', toJS(kpiStore.mapWorkListState))
  }, [workListState])

  const onChangeExpectWeight = (value, workId) => {
    const newWorkList = [...workListState]
    const indexWork = newWorkList.findIndex(item => item.code === workId)
    const originExpectWeightWork = originWorkListState[indexWork].expect_weight
    const originActualWeightWork = originWorkListState[indexWork].actual_weight
    const newActualWeightWork =
      originExpectWeightWork > 0
        ? Math.round(
            ((originActualWeightWork * value) / originExpectWeightWork) * 100
          ) / 100
        : 0
    newWorkList[indexWork] = {
      ...newWorkList[indexWork],
      expect_weight: value,
      actual_weight: newActualWeightWork,
    }
    setValidateInputExpectWeight({
      ...validateInputExpectWeight,
      workId: workId,
    })
    setWorkListState(newWorkList)
    // Tính toán actual_weight của subTarget khi thay đổi expect_weight của work
    const newSubTargetListByTargetId = [...subTargetListByTargetId]
    const indexSubTarget = newSubTargetListByTargetId.findIndex(
      item => item.code === subTargetId
    )
    const newActualWeightSubTarget = Math.round(
      (((newWorkList.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.actual_weight
      }, 0) *
        newSubTargetListByTargetId[indexSubTarget].expect_weight) /
        100) *
        100) /
        100
    )
    newSubTargetListByTargetId[indexSubTarget] = {
      ...newSubTargetListByTargetId[indexSubTarget],
      actual_weight: newActualWeightSubTarget,
    }
    kpiStore.setSubTargetListByTargetId(newSubTargetListByTargetId)
  }

  const onSelectedWorks = async () => {
    let workCode = subTargetWorkCreateStore.selectedWorks[0].code

    setIsVisibleSubTargetWorkCreateModal(false)
    subTargetWorkCreateStore.clear()

    await kpiStore.createKpi(null, KPI_TYPE.WORK, subTargetId, workCode)

    await kpiStore.getTargetKpiDetail(targetId)

    message.success('Gán công việc thành công')
  }

  const [
    isVisibleSubTargetWorkCreateModal,
    setIsVisibleSubTargetWorkCreateModal,
  ] = useState(false)

  return (
    <div>
      {workListState.length > 0 ? (
        workListState.map(work => (
          <div className={'work-edit-item'} key={work.code}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <TagOutlined
                style={{
                  color: '#13c2c2',
                  fontSize: 14,
                  marginRight: 8,
                }}
              />
              {work.name}
            </div>
            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#f5222d',
                  gap: 6,
                }}>
                <InputNumber
                  min={0}
                  max={100}
                  step={5}
                  addonAfter="%"
                  formatter={value => `${value}`}
                  // parser={value => value.replace('%', '')}
                  style={{
                    width: 86,
                    color: '#f5222d',
                    borderColor: `${
                      (!validateInputExpectWeight.correct &&
                        work.code !== validateInputExpectWeight.workId) ||
                      (!validateInputExpectWeight.correct &&
                        workListState.length === 1)
                        ? '#fa8c16'
                        : '#d9d9d9'
                    }`,
                  }}
                  onChange={value =>
                    onChangeExpectWeight(value ?? 0, work.code)
                  }
                  value={work.expect_weight}
                />

                <Tooltip
                  title={
                    'Tổng số tỉ trọng cần đạt của các công việc trong một chỉ tiêu phải bằng 100%!'
                  }>
                  <WarningOutlined
                    style={{
                      color: '#fa8c16',
                      marginRight: 6,
                      opacity: `${
                        (!validateInputExpectWeight.correct &&
                          work.code !== validateInputExpectWeight.workId) ||
                        (!validateInputExpectWeight.correct &&
                          workListState.length === 1)
                          ? 1
                          : 0
                      }`,
                    }}
                  />
                </Tooltip>
              </div>
              <div
                style={{
                  width: 120,
                  textAlign: 'center',
                  fontWeight: 500,
                  color: '#52c41a',
                }}>
                {work.actual_weight}%
              </div>

              <AccessControlAction aclActionType={ACL_ACTION_TYPE.delete__KPI}>
                <Tooltip title={'Xóa'}>
                  <Popconfirm
                    placement="topRight"
                    title={'Bạn có chắc chắn muốn xóa công việc này không?'}
                    onConfirm={() => onDelete(work)}
                    okText="Xóa"
                    okType={'danger'}
                    cancelText="Không">
                    <Button
                      block
                      size={'small'}
                      danger
                      type="text"
                      style={{ fontWeight: 500, width: 30 }}
                      icon={<DeleteOutlined style={{ color: 'f5222d' }} />}
                    />
                  </Popconfirm>
                </Tooltip>
              </AccessControlAction>
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            padding: '12px 16px',
            border: '1px solid #f0f0f0',
            borderTop: 'none',
            backgroundColor: '#fff',
          }}>
          <EmptyText>Chưa có công việc nào!</EmptyText>
        </div>
      )}
      <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__KPI}>
        <div
          style={{
            padding: '10px 16px',
            color: blue,
            border: '1px solid #f0f0f0',
            borderTop: 'none',
            textDecoration: 'underline',
          }}>
          <Button
            icon={<PlusOutlined />}
            type={'dashed'}
            size={'small'}
            onClick={() => setIsVisibleSubTargetWorkCreateModal(true)}
            style={{
              fontWeight: 500,
              color: blue,
            }}>
            Thêm công việc
          </Button>
        </div>
      </AccessControlAction>
      .
      <SubTargetWorkCreateModal
        isVisibleSubTargetWorkCreateModal={isVisibleSubTargetWorkCreateModal}
        handleCloseSubTargetWorkCreateModal={() =>
          setIsVisibleSubTargetWorkCreateModal(false)
        }
        onSelectedWorks={onSelectedWorks}
      />
    </div>
  )
})

SubTargetWorkList.propTypes = {}

export default inject(
  'kpiStore',
  'authenticationStore',
)(observer(SubTargetWorkList))

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

const AccessControlAction = props => {
  const { aclStore, authenticationStore, children, aclActionType } = props

  const { aclActionsByUser } = aclStore
  const { isAccountAdmin, isSuperAdmin } = authenticationStore

  const [actionStatus, setActionStatus] = useState(true)

  useEffect(() => {
    const actionType = aclActionsByUser.find(
      item => item.code === aclActionType
    )
    setActionStatus(!!actionType?.status || isAccountAdmin || isSuperAdmin)
  }, [aclActionType, aclActionsByUser, isAccountAdmin, isSuperAdmin])

  return <>{actionStatus && children}</>
}

AccessControlAction.propTypes = {
  aclActionType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default inject(
  'aclStore',
  'authenticationStore'
)(observer(AccessControlAction))

const merge = require('lodash/merge')

const config = {
  all: {
    env: process.env.NODE_ENV || 'development',
    isDev: process.env.NODE_ENV !== 'production',
    basename: process.env.PUBLIC_URL,
    systemName: process.env.REACT_APP_SYSTEM_NAME,
    isBrowser: typeof window !== 'undefined',
    vimcTaiLieuUrl: process.env.REACT_APP_VIMC_TAI_LIEU_URL,
    envName: process.env.REACT_APP_ENV_NAME,
    useSSO: process.env.REACT_APP_USE_SSO === 'true',
  },
  test: {},
  development: {
    apiUrl: process.env.REACT_APP_API_URL,
    apiUrlTruc: process.env.REACT_APP_API_URL_TRUC,
    apiUrlCommonService: process.env.REACT_APP_API_URL_COMMON_SERVICE,
    oauth: {
      clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
      clientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
    },
    oauthTruc: {
      clientId: process.env.REACT_APP_OAUTH_TRUC_CLIENT_ID,
      clientSecret: process.env.REACT_APP_OAUTH_TRUC_CLIENT_SECRET,
    },
    notificationURL: process.env.REACT_APP_NOTIFICATION_URL,
    lowDisplay: process.env.REACT_APP_LOW_DISPLAY,
    vapidKey: process.env.REACT_APP_VAPIKEY,
  },
  production: {
    apiUrl: process.env.REACT_APP_API_URL,
    apiUrlTruc: process.env.REACT_APP_API_URL_TRUC,
    apiUrlCommonService: process.env.REACT_APP_API_URL_COMMON_SERVICE,
    oauth: {
      clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
      clientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
    },
    oauthTruc: {
      clientId: process.env.REACT_APP_OAUTH_TRUC_CLIENT_ID,
      clientSecret: process.env.REACT_APP_OAUTH_TRUC_CLIENT_SECRET,
    },
    notificationURL: process.env.REACT_APP_NOTIFICATION_URL,
    lowDisplay: process.env.REACT_APP_LOW_DISPLAY,
    vapidKey: process.env.REACT_APP_VAPIKEY,
  },
}

module.exports = merge(config.all, config[config.all.env])

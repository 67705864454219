import styled from 'styled-components'

export const ListCommentWrapper = styled.div`
  max-height: 47vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding-right: 15px;
`

export const ComponentHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;

  .title {
    font-size: 15px;
    //font-weight: 700;
    color: rgba(0, 0, 0);
    line-height: 32px;
  }
`

export const FlexBetweenBox = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0.2rem;
`

export const FormButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 14px 0 10px;
`

export const CommentBox = styled.div`
  display: flex;
  color: #928e8ecc;
  margin-top: 10px;
  opacity: ${props => (props.deleted ? 0.5 : 1)};

  .left {
    margin-right: 6px;
  }

  .right {
    width: 50%;
    flex-grow: 1;

    .content {
      background-color: #f0f2f5;
      padding: 8px 12px;
      border-radius: 10px;
      color: black;
      margin: 0;

      .name-create {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
      }

      .content-comment {
        div {
          padding: 0;
        }

        textarea {
          padding: 0;
        }
      }
    }

    .file {
      margin-left: 10px;
    }

    .time {
      font-weight: 500;
      margin-left: 10px;
    }
  }
`

export const WorkCommentBox = styled.div`
  color: #928e8ecc;
  margin-top: 10px;
  opacity: ${props => (props.deleted ? 0.5 : 1)};

  .name-create {
    font-size: 10px;
    color: black;
  }

  .time {
    font-size: 8px;
  }

  .content {
    color: black;
    margin: 0;

    .content-comment {
      div {
        padding: 0;
      }

      textarea {
        padding: 0;
      }
    }
  }
`

export const FormInputComment = styled.div`
  display: flex;
  .comment-form {
    width: calc(100% - 37px);
    ul {
      max-height: 120px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`

export const CommentHidden = styled.div`
  .anticon-ellipsis:hover {
    background-color: #f0f2f5;
    transition: 1s;
  }
`

export const UploadFileListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: all ease 0.3s;
  padding: 3px 0;
  border-bottom: 1px solid #ebebeb;

  &:hover {
    background-color: #ebebeb;
    transition: all ease 0.3s;
    text-decoration: none !important;

    .anticon-check,
    .anticon-delete {
      opacity: 1;
      visibility: visible;
      transition: all ease 0.3s;
    }
  }

  .anticon {
    color: #8c8c8c;

    &:not(.anticon-paper-clip) {
      margin-left: 5px;
    }

    &.anticon-delete {
      margin-right: 5px;
    }

    &:hover {
      &.anticon-check {
        color: ${props => props.theme.solidColor};
      }

      &.anticon-delete {
        color: red;
      }
    }
  }

  .anticon-check,
  .anticon-delete {
    visibility: hidden;
    opacity: 0;
    transition: all ease 0.3s;

    &:hover {
      cursor: pointer;
    }
  }
`

export const UploadFileListWrapper = styled.ul`
  margin: 10px 0 15px;
  padding-left: 0 !important;
`

export const UploadWrapper = styled.span`
  margin: 0 4px;
  position: relative;

  .ant-upload-list {
    position: absolute;
  }
`

export const SortWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
`

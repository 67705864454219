import React, { memo, useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Col, Form, Input, message, Row, Upload } from 'antd'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import { CloudUploadOutlined, PaperClipOutlined } from '@ant-design/icons'
import FileDownload from 'js-file-download'
import {
  DocumentInfoContent,
  DraggerUploadStyled,
  FileViewBlock,
  FormButtonGroup,
  TitleBlock,
  ValueBlock,
  ViewDetailContent,
} from './FormStyled'
import validator from '../../../validator'
import PDFViewer from '../../../components/PDFViewer'
import EmptyContent from '../../../components/EmptyContent'
import { Container } from '../../../layouts/Container/Container'

const DocumentISOFormPage = props => {
  const { Dragger } = Upload
  const {
    loadingAnimationStore,
    documentStore,
    history,
    match,
    fileStore,
  } = props

  const [form] = Form.useForm()
  const [editMode, setEditMode] = useState(true)
  const [fileList, setFileList] = useState([])

  const { documentId } = match.params
  const { ISODetail } = documentStore
  const {
    id,
    file_id,
    document_name,
    document_number,
    create_user,
    file_name,
  } = ISODetail
  const handleAddFileToUpload = () => false
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const downloadFile = useCallback(
    (id, file_id, file_name) => {
      loadingAnimationStore.showSpinner(true)
      documentStore
        .getAttachmentISO({
          recordId: id,
          fileId: file_id,
        })
        .then(response => {
          console.log(response)
          loadingAnimationStore.showSpinner(false)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          FileDownload(blob, `${file_name}`)
        })
        .catch(() => {
          loadingAnimationStore.showSpinner(false)
        })
    },
    [documentStore, loadingAnimationStore]
  )
  const handleChangeFile = useCallback(
    info => {
      form.setFieldsValue({
        file_id: info.fileList.slice(
          info.fileList.length - 1,
          info.fileList.length
        ),
      })
      setFileList(
        info.fileList.slice(info.fileList.length - 1, info.fileList.length)
      )
    },
    [form]
  )

  useEffect(() => {
    setEditMode(documentId === 'create')
    if (documentId === 'create') {
      return
    }
    loadingAnimationStore.showSpinner(true)
    documentStore.getISODetail(documentId).then(res => {
      loadingAnimationStore.showSpinner(false)
      documentStore.getViewAttachmentISO({
        recordId: res.data.id,
        fileId: res.data.file_id,
      })
    })
    return () => documentStore.clearDocumentDetail()
  }, [documentId, documentStore, loadingAnimationStore])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitForm = useCallback(values => {
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', values.file_id[0].originFileObj)
    fileStore
      .uploadFile(formData)
      .then(response => {
        values.file_id = response.data.file_id
        handleCreateISO(values)
      })
      .catch(error => {
        message.error(error.vi)
        loadingAnimationStore.showSpinner(false)
      })
  })

  const handleCreateISO = useCallback(
    submitData => {
      documentStore
        .createISO(submitData)
        .then(() => {
          history.push('/utility/iso')
          loadingAnimationStore.showSpinner(false)
          message.success(
            `Tạo tài liệu ISO ${submitData.document_name} với số hiệu ${submitData.document_number} thành công`
          )
        })
        .catch(error => {
          loadingAnimationStore.showSpinner(false)
          message.error(error.vi)
        })
    },
    [documentStore, history, loadingAnimationStore]
  )

  const renderViewContent = () => (
    <Row type={'flex'} gutter={30}>
      <Col span={24} xs={24} md={16}>
        {documentStore.attachment ? (
          <FileViewBlock>
            <PDFViewer
              file={documentStore.attachment}
              customStyleObj={{ height: 'calc(100vh - 210px)' }}
            />
          </FileViewBlock>
        ) : (
          <ContentBlockWrapper>
            <EmptyContent description={'Không có văn bản'} />
          </ContentBlockWrapper>
        )}
      </Col>
      <Col span={24} xs={24} md={8}>
        <ContentBlockWrapper>
          {/* <DocumentInfoTitle>Thông tin chung</DocumentInfoTitle> */}

          <DocumentInfoContent>
            <Row>
              <TitleBlock>
                <strong>Tên tài liệu:</strong>
              </TitleBlock>
              <ValueBlock>{document_name}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock>
                <strong>Số hiệu:</strong>
              </TitleBlock>
              <ValueBlock>{document_number}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock>
                <strong>Người tạo:</strong>
              </TitleBlock>
              <ValueBlock>{create_user}</ValueBlock>
            </Row>

            <Row>
              <Col>
                <Button
                  onClick={() => downloadFile(id, file_id, file_name)}
                  type={'primary'}>
                  <PaperClipOutlined className={'cursor-pointer'} /> Download
                </Button>
              </Col>
            </Row>
          </DocumentInfoContent>
        </ContentBlockWrapper>
      </Col>
    </Row>
  )

  const renderFormContent = () => (
    <ContentBlockWrapper>
      <Container maxWidth={820}>
        <Form
          scrollToFirstError={true}
          {...layout}
          form={form}
          name={'add-iso-form'}
          onFinish={handleSubmitForm}>
          <Form.Item
            name={'document_name'}
            label={'Tên tài liệu'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên tài liệu!' },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên tài liệu'} />
          </Form.Item>

          <Form.Item
            name={'document_number'}
            label={'Số hiệu'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên tài liệu!' },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên tài liệu'} />
          </Form.Item>

          <Form.Item
            name={'file_id'}
            label={'Tài liệu đính kèm'}
            rules={[{ required: true, message: 'Vui lòng thêm tài liệu!' }]}>
            <Dragger
              valuePropName={'fileList'}
              fileList={fileList}
              onChange={handleChangeFile}
              beforeUpload={handleAddFileToUpload}>
              <DraggerUploadStyled>
                <CloudUploadOutlined />
                <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
              </DraggerUploadStyled>
            </Dragger>
          </Form.Item>

          <FormButtonGroup>
            <Button
              htmlType={'submit'}
              style={{ marginLeft: 10 }}
              type={'primary'}>
              Tạo
            </Button>
          </FormButtonGroup>
        </Form>
      </Container>
    </ContentBlockWrapper>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết tài liệu ISO | Tài liệu</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={`Chi tiết ${document_name}`}
        routerGoback={'/utility/iso'}
      />
      <ViewDetailContent>
        {editMode ? renderFormContent() : renderViewContent()}
      </ViewDetailContent>
    </DashboardLayout>
  )
}

DocumentISOFormPage.propTypes = {}

export default memo(
  inject(
    'loadingAnimationStore',
    'commonStore',
    'documentStore',
    'fileStore',
  )(observer(DocumentISOFormPage)),
)

import { action, observable } from 'mobx'
// Request
import { PositionRequest } from '../requests/PositionRequest'

class PositionStore {
  @observable totalCount = 0
  @observable filter = {
    page: 1,
    size: 10,
  }

  /** Position list */
  @observable positionList = []
  @observable positionOriginalList = []

  @observable positionDetail = {}

  @action setFilter = filter => {
    this.filter = Object.assign({}, this.filter, filter)
  }

  @action clearPositionList = () => {
    this.positionList = []
    this.positionOriginalList = []
  }

  @action setPositionList = positionList => {
    this.positionList = positionList
  }
  @action getPositionList = () => {
    return new Promise((resolve, reject) => {
      PositionRequest.getPositionList()
        .then(response => {
          this.positionList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  
  @action clearStore = () => {
    this.positionList.length = 0
  }
  @action create = position => {
    return new Promise((resolve, reject) => {
      PositionRequest.create(position)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action update = (code, position) => {
    return new Promise((resolve, reject) => {
      PositionRequest.update(code, position)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action delete = code => {
    return new Promise((resolve, reject) => {
      PositionRequest.delete(code)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  sort = async codes => {
    await PositionRequest.sort(codes)
  }
  @action clear = () => {
    this.positionList.length = 0
    this.totalCount = 0
    this.filter = {
      page: 1,
      size: 10,
    }
  }

  @action clearDetails = () => {
    this.positionDetail = {}
  }
}

export default new PositionStore()

import { action, observable } from 'mobx'
// Request
import { FileRequest } from '../requests/FileRequest'
import loadingAnimationStore from './loadingAnimationStore'
import FileDownload from 'js-file-download'
import { message } from 'antd'

class FileStore {
  @observable documentAttachment = undefined

  @action clearDocumentAttachment = () => {
    this.documentAttachment = undefined
  }

  /** File upload */
  @action uploadFile = formData => {
    return new Promise((resolve, reject) => {
      FileRequest.uploadFile(formData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** File upload trục */
  @action uploadFileTruc = formData => {
    return new Promise((resolve, reject) => {
      FileRequest.uploadFileTruc(formData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** File upload cloud */
  @action uploadFileCloud = formData => {
    return new Promise((resolve, reject) => {
      FileRequest.uploadCloudFile(formData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action downloadAttachment = file_id => {
    return new Promise((resolve, reject) => {
      FileRequest.downloadAttachment(file_id)
        .then(response => {
          this.documentAttachment = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action downloadFile = file_id => {
    return new Promise((resolve, reject) => {
      FileRequest.downloadAttachment(file_id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action downloadFileTruc = (file_id, file_name) => {
    return new Promise((resolve, reject) => {
      FileRequest.downloadFileTruc(file_id, file_name)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action renameFile = (file_id, file_name) => {
    return new Promise((resolve, reject) => {
      FileRequest.renameFile(file_id, file_name)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action renameFileTruc = (file_id, file_name) => {
    return new Promise((resolve, reject) => {
      FileRequest.renameFileTruc(file_id, file_name)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action downloadAttachmentTruc = file_id => {
    return new Promise((resolve, reject) => {
      FileRequest.downloadAttachmentTruc(file_id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action uploadFileLTVB = formData => {
    return new Promise((resolve, reject) => {
      FileRequest.uploadFileLTVB(formData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action uploadExcel = formData => {
    return new Promise((resolve, reject) => {
      FileRequest.uploadExcel(formData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getFileByEdocOutgoingId = (documentId, attachmentName) => {
    return new Promise((resolve, reject) => {
      FileRequest.getFileByEdocOutgoingId(documentId, attachmentName)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  @action getFileByEdocIncoingId = (documentId, attachmentName) => {
    return new Promise((resolve, reject) => {
      FileRequest.getFileByEdocIncomingId(documentId, attachmentName)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action getReceivedFileByEdocId = (documentId, attachmentName) => {
    return new Promise((resolve, reject) => {
      FileRequest.getReceivedFileByEdocId(documentId, attachmentName)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action getFileExcelById = fileId => {
    return new Promise((resolve, reject) => {
      FileRequest.getFileExcelById(fileId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action getFileInternalEdocIncomingById = (documentId, attachmentName) => {
    return new Promise((resolve, reject) => {
      FileRequest.getFileInternalEdocIncomingById(documentId, attachmentName)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action getFileInternalEdocOutgoingById = (documentId, attachmentName) => {
    return new Promise((resolve, reject) => {
      FileRequest.getFileInternalEdocOutgoingById(documentId, attachmentName)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action handleDownloadFile = async (id_file, name_file) => {
    loadingAnimationStore.showSpinner(true)
    try {
      const { data } = await this.downloadFile(id_file)
      const blob = new Blob([data], { type: 'application/pdf' })
      FileDownload(blob, name_file)
    } catch (err) {
      message.error(err?.vi || 'Đã có lỗi xảy ra')
      console.log(err)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  @action handleDownloadFileTruc = async (id_file, name_file) => {
    loadingAnimationStore.showSpinner(true)
    try {
      const { data } = await this.downloadFileTruc(id_file, name_file)
      const blob = new Blob([data], { type: 'application/pdf' })
      FileDownload(blob, name_file)
    } catch (err) {
      message.error(err?.vi || 'Đã có lỗi xảy ra')
      console.log(err)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  @action handleDownloadAttachmentTruc = async (id_file, name_file) => {
    loadingAnimationStore.showSpinner(true)
    try {
      const { data } = await this.downloadAttachmentTruc(id_file)
      const blob = new Blob([data], { type: 'application/pdf' })
      FileDownload(blob, name_file)
    } catch (err) {
      message.error(err?.vi || 'Đã có lỗi xảy ra')
      console.log(err)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  @action handleRenameFile = async (file_id, file_name) => {
    loadingAnimationStore.showSpinner(true)
    try {
      await this.renameFile(file_id, file_name)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  @action deleteCloudObject = async objectId => {
    await FileRequest.deleteCloudObject(objectId)
  }
}

export default new FileStore()

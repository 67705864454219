import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const SalaryRequest = {
  getSalaryList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/salary`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),

  importSalaryFile: formData =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/salary/import`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }),

  createSalary: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/salary/create`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  deleteSalary: salaryCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/salary/${salaryCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getSalaryDetail: salaryCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/salary/${salaryCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  approveSalary: salaryCode =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/salary/${salaryCode}/approve`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  getMySalaryList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/user_salary/`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),
}

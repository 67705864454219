import { UserOutlined } from '@ant-design/icons'
import { Button, Space, Tooltip } from 'antd'
import React from 'react'
import { blue } from '../../../color'

const SelectUserButton = ({ handleOnClick }) => {
  return (
    <div
      style={{
        marginBottom: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <span>Chọn người:</span>
      <Space>
        <Tooltip title="Chọn người vote" color={blue}>
          <Button
            icon={<UserOutlined style={{ color: blue }} />}
            onClick={handleOnClick}
          />
        </Tooltip>
      </Space>
    </div>
  )
}

export default SelectUserButton

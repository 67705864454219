import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../ContentBlockWrapper'
import moment from 'moment'
import validator from '../../validator'
import StaffModal from '../StaffModal'
import utils from '../../utils'
import FileDownload from 'js-file-download'
import TaskManagementStatusTag from '../TaskManagementStatusTag'
import { withRouter } from 'react-router-dom'
import {
  AttachmentFile,
  AttachmentList,
  CommentAction,
  CommentContent,
  CommentDatetimeInfo,
  CommentSectionWrapper,
  FormButtonGroup,
  StepsWrapper,
  TaskManagementDetailContent,
  TaskManagementDetailWrapper,
  UploadFileListItem,
  UploadFileListWrapper,
  UploadWrapper,
} from './TaskManagementDetailStyled'
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Steps,
  Tooltip,
  Upload,
} from 'antd'
import {
  CommentOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  MoreOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined,
  StopOutlined,
  UploadOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import TaskApprovalModal from './TaskApprovalModal'
import { toJS } from 'mobx'
import PDFViewer from '../PDFViewer'
import { subStringAvatar, WordBreak } from '../Common/CellText'

const { Step } = Steps

const TaskManagementDetail = props => {
  const [commentForm] = Form.useForm()
  const [showStaffModal, setShowStaffModal] = useState(false)
  const [modalType, setModalType] = useState(undefined)
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [showAttachmentDropzone, setShowAttachmentDropzone] = useState(false)
  const [fileList, setFileList] = useState([])

  const {
    history,
    loadingAnimationStore,
    commonStore,
    taskManagementStore,
    departmentStore,
    staffStore,
    taskManagementCommentStore,
    authenticationStore,
    fileStore,
    taskId,
  } = props
  const { currentUser } = authenticationStore
  const {
    selectedTask,
    taskList,
    selectedTaskViewAttachment,
  } = taskManagementStore
  const { taskCommentList, taskCommentCurrentStep } = taskManagementCommentStore
  const { title, attachments, task_description } = selectedTask

  const handleOpenStaffModal = useCallback(modalType => {
    setShowStaffModal(true)
    setModalType(modalType)
  }, [])
  const handleCloseModal = useCallback(() => {
    setShowStaffModal(false)
    setModalType(undefined)
  }, [])
  const handleAddAssignStaff = useCallback(() => {
    const submitValues = {
      ...(modalType === 'followers'
        ? {
            followers: {
              department_codes: [],
              user_codes: [],
            },
          }
        : {
            co_ops: {
              department_codes: [],
              user_codes: [],
            },
          }),
    }
    staffStore.selectedStaffList.length !== 0 &&
      staffStore.selectedStaffList.forEach(entry => {
        entry.value.includes('USR')
          ? modalType === 'followers'
            ? submitValues.followers.user_codes.push(entry.value)
            : submitValues.co_ops.user_codes.push(entry.value)
          : modalType === 'followers'
          ? submitValues.followers.department_codes.push(entry.value)
          : submitValues.co_ops.department_codes.push(entry.value)
      })
    loadingAnimationStore.showSpinner(true)
    taskManagementStore
      .updateAssignStaff(selectedTask.code, submitValues)
      .then(() => {
        taskManagementStore
          .getTaskDetail(selectedTask.code)
          .then(() => {
            setShowStaffModal(false)
            staffStore.clearSelectedStaffList()
            loadingAnimationStore.showSpinner(false)
            message.success(
              `Đã cập nhật ${
                modalType === 'followers' ? 'người theo dõi' : 'người phối hợp'
              }!`
            )
          })
          .catch(error => {
            loadingAnimationStore.showSpinner(false)
            message.error(error.vi)
          })
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [modalType, selectedTask.code])

  const handleCancelTask = useCallback(() => {
    loadingAnimationStore.showSpinner(true)
    const newTaskList = [...toJS(taskList)]
    const cancelComment = `Công việc đã bị thu hồi bởi bởi ${utils.getNameInCapitalize(
      currentUser?.name_lowercase
    )}`
    newTaskList.forEach(task => {
      if (selectedTask.code !== task.code) return
      task.status = '031'
    })
    taskManagementCommentStore
      .postTaskComment(selectedTask.code, cancelComment)
      .then(() => {
        return new Promise((resolve, reject) => {
          taskManagementStore
            .updateTaskStatus(selectedTask.code, '031')
            .then(response => resolve(response))
            .catch(error => {
              loadingAnimationStore.showSpinner(false)
              message.error(error.vi)
              reject(error)
            })
        })
      })
      .then(() => {
        return new Promise((resolve, reject) => {
          taskManagementStore
            .getTaskDetail(selectedTask.code)
            .then(response => resolve(response))
            .catch(error => {
              loadingAnimationStore.showSpinner(false)
              message.error(error.vi)
              reject(error)
            })
        })
      })
      .then(() => {
        return new Promise((resolve, reject) => {
          taskManagementCommentStore
            .getTaskComment(selectedTask.code)
            .then(response => resolve(response))
            .catch(error => {
              message.error(error.vi)
              loadingAnimationStore.showSpinner(false)
              reject(error)
            })
        })
      })
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        taskManagementStore.updateTaskList(newTaskList)
        message.success('Công việc đã bị thu hồi!')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [currentUser?.name_lowercase, selectedTask.code, taskList])
  const handleDeleteTask = useCallback(() => {
    loadingAnimationStore.showSpinner(true)
    taskManagementStore
      .deleteTask(selectedTask.code)
      .then(() => {
        taskManagementStore.clearSelectedTask()
        taskManagementStore
          .getTaskList() // TODO: xử lý
          .then(() => {
            loadingAnimationStore.showSpinner(false)
            message.success('Đã xoá công việc')
          })
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
        message.success('Có lỗi xảy ra, không thể xoá công việc')
      })
  }, [selectedTask.code])
  const handleAddComment = useCallback(
    values => {
      const updatedTaskList = [...taskList]
      let uploadList = []
      updatedTaskList.forEach(task => {
        if (selectedTask.code !== task.code) return
        if (task.status === '030') {
          task.status = '032'
        }
      })
      const postComment = () => {
        return taskManagementCommentStore
          .postTaskComment(selectedTask.code, values.comment, uploadList)
          .then(() => {
            Promise.all([
              taskManagementCommentStore.getTaskComment(selectedTask.code),
              taskManagementStore.getTaskDetail(selectedTask.code),
            ])
              .then(response => Promise.resolve(response))
              .catch(error => Promise.reject(error))
          })
          .then(() => {
            commentForm.resetFields()
            taskManagementStore.updateTaskList(updatedTaskList)
            setShowAttachmentDropzone(false)
            setFileList([])
            return Promise.resolve()
          })
          .catch(() => {
            message.error('Có lỗi xảy ra, xin vui lòng thử lại!')
            return Promise.reject()
          })
      }
      if (fileList.length === 0) {
        postComment().finally(() => loadingAnimationStore.showSpinner(false))
        return
      }
      const uploadFileList = fileList.map(file => file.originFileObj)
      const batchUploadArr = []
      uploadFileList.forEach(file => {
        const formData = new FormData()
        formData.append('file', file)
        batchUploadArr.push(fileStore.uploadFile(formData))
      })
      Promise.all(batchUploadArr)
        .then(response => {
          uploadList = response.map(response => response.data.file_id)
          return Promise.resolve(response)
        })
        .then(() => {
          postComment()
            .then(() => Promise.resolve())
            .catch(() => Promise.reject())
        })
        .catch(error => {
          return Promise.reject(error)
        })
        .finally(() => loadingAnimationStore.showSpinner(false))
    },
    [taskList, fileList, selectedTask.code, commentForm]
  )
  const handleDeleteComment = useCallback((taskCode, commentId) => {
    loadingAnimationStore.showSpinner(true)
    taskManagementCommentStore
      .deleteTaskComment(taskCode, commentId)
      .then(() => {
        taskManagementCommentStore.getTaskComment(taskCode)
      })
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        message.success('Đã xoá ý kiến!')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [])
  const handleDownloadAttachment = useCallback(
    (taskCode, fileId, fileTitle, fileType) => {
      loadingAnimationStore.showSpinner(true)
      taskManagementStore
        .downloadAttachment(taskCode, fileId)
        .then(response => {
          loadingAnimationStore.showSpinner(false)
          const blob = new Blob([response.data], {
            type: fileType,
          })
          FileDownload(blob, fileTitle)
        })
        .catch(() => {
          loadingAnimationStore.showSpinner(false)
        })
    },
    []
  )
  const handleApproveTask = useCallback(
    picCode => {
      loadingAnimationStore.showSpinner(true)
      const endTaskMessage = `Công việc đã được duyệt bởi ${utils.getNameInCapitalize(
        currentUser?.name_lowercase
      )}`
      const approvedMessage = `Quy trình bước ${
        selectedTask.step
      } đã được duyệt bởi ${utils.getNameInCapitalize(
        currentUser?.name_lowercase
      )}`
      const newTaskList = [...toJS(taskList)]
      const endTask = () => {
        newTaskList.forEach(task => {
          if (selectedTask.code !== task.code) return
          task.status = '034'
        })
        taskManagementCommentStore
          .postTaskComment(selectedTask.code, endTaskMessage)
          .then(() => {
            return new Promise((resolve, reject) => {
              taskManagementStore
                .updateTaskStatus(selectedTask.code, '034')
                .then(response => resolve(response))
                .catch(error => {
                  message.error(error.vi)
                  reject(error)
                })
            })
          })
          .then(() => {
            return new Promise((resolve, reject) => {
              taskManagementStore
                .getTaskDetail(selectedTask.code)
                .then(response => resolve(response))
                .catch(error => {
                  message.error(error.vi)
                  reject(error)
                })
            })
          })
          .then(() => {
            taskManagementStore.updateTaskList(newTaskList)
            loadingAnimationStore.showSpinner(false)
            message.success('Công việc đã được duyệt và hoàn thành!')
          })
          .catch(error => {
            loadingAnimationStore.showSpinner(false)
            message.error(error.vi)
          })
      }
      const approvedTask = () => {
        newTaskList.forEach(task => {
          if (selectedTask.code !== task.code) return
          task.status = '032'
        })
        taskManagementCommentStore
          .postTaskComment(selectedTask.code, approvedMessage)
          .then(() => {
            return new Promise((resolve, reject) => {
              taskManagementCommentStore
                .changeStep(
                  selectedTask.code,
                  parseInt(selectedTask.step) + 1,
                  picCode
                )
                .then(response => resolve(response))
                .catch(error => {
                  message.error(error.vi)
                  reject(error)
                })
            })
          })
          .then(() => {
            return new Promise((resolve, reject) => {
              taskManagementStore
                .getTaskDetail(selectedTask.code)
                .then(response => resolve(response))
                .catch(error => {
                  message.error(error.vi)
                  reject(error)
                })
            })
          })
          .then(() => {
            return new Promise((resolve, reject) => {
              taskManagementCommentStore
                .getTaskComment(
                  selectedTask.code,
                  parseInt(selectedTask.step) + 1
                )
                .then(response => resolve(response))
                .catch(error => {
                  message.error(error.vi)
                  reject(error)
                })
            })
          })
          .then(() => {
            loadingAnimationStore.showSpinner(false)
            taskManagementStore.updateTaskList(newTaskList)
            message.success(`Công việc bước ${selectedTask.step} đã được duyệt`)
          })
          .catch(error => {
            loadingAnimationStore.showSpinner(false)
            message.error(error.vi)
          })
      }
      // eslint-disable-next-line default-case
      switch (selectedTask.process) {
        case '020':
          endTask()
          return
        case '021':
          if (selectedTask.step === '2') {
            endTask()
            return
          }
          approvedTask()
          return
        case '022':
          if (selectedTask.step === '3') {
            endTask()
            return
          }
          approvedTask()
          break
      }
    },
    [
      currentUser?.name_lowercase,
      selectedTask.code,
      selectedTask.process,
      selectedTask.step,
      taskList,
    ]
  )
  const handleDenyTask = useCallback(() => {
    loadingAnimationStore.showSpinner(true)
    const denyComment = `Công việc đã bị từ chối bởi bởi ${utils.getNameInCapitalize(
      currentUser?.name_lowercase
    )}`
    const newTaskList = [...toJS(taskList)]
    newTaskList.forEach(task => {
      if (selectedTask.code !== task.code) return
      task.status = '033'
    })
    taskManagementCommentStore
      .postTaskComment(selectedTask.code, denyComment)
      .then(() => {
        return new Promise((resolve, reject) => {
          taskManagementStore
            .updateTaskStatus(selectedTask.code, '033')
            .then(response => resolve(response))
            .catch(error => {
              loadingAnimationStore.showSpinner(false)
              message.error(error.vi)
              reject(error)
            })
        })
      })
      .then(() => {
        return new Promise((resolve, reject) => {
          taskManagementStore
            .getTaskDetail(selectedTask.code)
            .then(response => resolve(response))
            .catch(error => {
              loadingAnimationStore.showSpinner(false)
              message.error(error.vi)
              reject(error)
            })
        })
      })
      .then(() => {
        return new Promise((resolve, reject) => {
          taskManagementCommentStore
            .getTaskComment(selectedTask.code)
            .then(response => resolve(response))
            .catch(error => {
              loadingAnimationStore.showSpinner(false)
              message.error(error.vi)
              reject(error)
            })
        })
      })
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        taskManagementStore.updateTaskList(newTaskList)
        message.success('Công việc đã bị từ chối!')
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
  }, [currentUser?.name_lowercase, selectedTask.code, taskList])
  const handleChangeStep = useCallback(
    step => {
      loadingAnimationStore.showSpinner(true)
      taskManagementCommentStore.changeCommentStep(step + 1).then(() => {
        taskManagementCommentStore
          .getTaskComment(selectedTask.code)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
    },
    [selectedTask.code]
  )
  const showConfirmCancelTask = useCallback(() => {
    Modal.confirm({
      title: 'Thu hồi công việc',
      icon: <ExclamationCircleOutlined style={{ color: '#fc0000' }} />,
      content: 'Bạn có chắc muốn thu hồi công việc này?',
      okType: 'danger',
      okText: 'Đồng ý',
      cancelText: 'Không',
      onOk() {
        handleCancelTask()
      },
    })
  }, [handleCancelTask])
  const showConfirmDeleteTask = useCallback(() => {
    Modal.confirm({
      title: 'Xoá công việc',
      icon: <ExclamationCircleOutlined style={{ color: '#fc0000' }} />,
      content: 'Bạn có chắc muốn xoá công việc này?',
      okType: 'danger',
      okText: 'Đồng ý',
      cancelText: 'Không',
      onOk() {
        handleDeleteTask()
      },
    })
  }, [handleDeleteTask])
  const showConfirmDenyTask = useCallback(() => {
    Modal.confirm({
      title: 'Từ chối công việc',
      icon: <ExclamationCircleOutlined style={{ color: '#fc0000' }} />,
      content: 'Bạn có chắc muốn từ chối công việc này?',
      okText: 'Đồng ý',
      okType: 'danger',
      cancelText: 'Không',
      onOk() {
        handleDenyTask()
      },
    })
  }, [handleDenyTask])
  const showApprovalModalHandler = useCallback(() => {
    setShowApprovalModal(true)
  }, [])
  const hideApprovalModalHandler = useCallback(() => {
    setShowApprovalModal(false)
  }, [])
  const handleRemoveFileFromUploadList = useCallback(
    fileUID => {
      const newFileList = fileList.filter(file => file.uid !== fileUID)
      setFileList(newFileList)
    },
    [fileList]
  )
  const handleAddFileToUpload = useCallback(() => false, [])
  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }, [])
  const renderCommentInput = useCallback(() => {
    if (!selectedTask.status) {
      return null
    }
    if (!currentUser) {
      return null
    }
    switch (selectedTask.status) {
      case '031':
      case '034':
      case '033':
        return null
      default:
        if (taskCommentCurrentStep < parseInt(selectedTask.step)) return null
        if (
          selectedTask.co_op.findIndex(
            item => item.user_code === currentUser.code
          ) === -1 &&
          selectedTask.creator_code.user_code !== currentUser.code &&
          selectedTask.person_in_charge.user_code !== currentUser.code
        )
          return null
        return (
          <CommentSectionWrapper>
            <ContentBlockWrapper>
              <Form
                onFinish={handleAddComment}
                scrollToFirstError
                hideRequiredMark
                layout="inline"
                form={commentForm}
                name={'comment-form'}>
                <Form.Item
                  name={'comment'}
                  rules={[
                    { required: true, message: 'Vui lòng nhập ý kiến' },
                    { validator: validator.validateInputString },
                  ]}>
                  <Input placeholder={'Nhập ý kiến'} />
                </Form.Item>
                <FormButtonGroup>
                  <UploadWrapper>
                    <Upload
                      style={{ marginLeft: 4, marginRight: 4 }}
                      valuePropName={'fileList'}
                      fileList={fileList}
                      multiple={true}
                      onChange={handleChangeFile}
                      beforeUpload={handleAddFileToUpload}
                      showUploadList={false}>
                      <Button htmlType={'button'}>
                        <UploadOutlined /> Tải file
                      </Button>
                    </Upload>
                  </UploadWrapper>
                  <Button htmlType={'submit'} type={'primary'}>
                    Gửi ý kiến
                  </Button>
                </FormButtonGroup>
              </Form>
              {fileList.length !== 0 && (
                <UploadFileListWrapper>
                  {fileList.map((file, index) => (
                    <UploadFileListItem key={file.uid}>
                      <PaperClipOutlined />
                      <span>{file.name}</span>
                      <Tooltip title={'Xoá tập tin'}>
                        <DeleteOutlined
                          onClick={() =>
                            handleRemoveFileFromUploadList(file.uid)
                          }
                        />
                      </Tooltip>
                    </UploadFileListItem>
                  ))}
                </UploadFileListWrapper>
              )}
            </ContentBlockWrapper>
          </CommentSectionWrapper>
        )
    }
  }, [
    commentForm,
    currentUser,
    fileList,
    handleAddComment,
    handleAddFileToUpload,
    handleChangeFile,
    selectedTask.co_op,
    selectedTask.creator_code.user_code,
    selectedTask.person_in_charge.user_code,
    selectedTask.status,
    selectedTask.step,
    taskCommentCurrentStep,
  ])
  const renderCommentList = useCallback(() => {
    if (!selectedTask.code) return
    if (!currentUser) return
    if (taskCommentList.length === 0) {
      return (
        <Empty
          style={{ color: '#ccc' }}
          image={<CommentOutlined style={{ fontSize: '60px' }} />}
          imageStyle={{ height: 60 }}
          description={
            <span style={{ fontStyle: 'italic' }}>Không có ý kiến</span>
          }></Empty>
      )
    }
    return taskCommentList.map((comment, index) => {
      return (
        <div
          style={{ marginBottom: '15px', position: 'relative' }}
          key={comment.id}>
          <ContentBlockWrapper>
            <CommentContent>{comment.content}</CommentContent>
            {selectedTask.status === '031' ||
            selectedTask.status === '033' ||
            selectedTask.status === '034' ||
            // || index !== taskCommentList.length - 1
            currentUser.code !== comment.user_post_code ||
            taskCommentCurrentStep < parseInt(selectedTask.step) ? null : (
              <CommentAction>
                <Popconfirm
                  title={'Bạn có muốn xoá bình luận này'}
                  okType={'danger'}
                  okText={'Xoá'}
                  onConfirm={() =>
                    handleDeleteComment(selectedTask.code, comment.id)
                  }
                  placement={'topRight'}
                  cancelText={'Không'}
                  icon={
                    <QuestionCircleOutlined style={{ color: '#fc0000' }} />
                  }>
                  <DeleteOutlined />
                </Popconfirm>
              </CommentAction>
            )}
            <CommentDatetimeInfo>
              <span>
                Đăng bởi{' '}
                {` ${utils.getNameInCapitalize(comment?.name_lowercase)}`}
              </span>
              <span>
                {moment(comment.date_post).format('DD-MM-YYYY HH:mm')}
              </span>
            </CommentDatetimeInfo>
          </ContentBlockWrapper>
        </div>
      )
    })
  }, [
    selectedTask.code,
    selectedTask.status,
    selectedTask.step,
    currentUser,
    taskCommentList,
    taskCommentCurrentStep,
    handleDeleteComment,
  ])
  const renderPostAction = useCallback(() => {
    return selectedTask.person_in_charge.user_code === currentUser.code ||
      selectedTask.co_op.findIndex(
        item => item.user_code === currentUser.code
      ) !== -1 ? (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={showApprovalModalHandler}>
              Duyệt công việc
            </Menu.Item>
            <Menu.Item onClick={showConfirmDenyTask} style={{ color: 'red' }}>
              Từ chối công việc
            </Menu.Item>
          </Menu>
        }>
        <Tooltip title={'Xử lý công việc'}>
          <span style={{ fontSize: 16 }} className={'action-icon'}>
            <MoreOutlined />
          </span>
        </Tooltip>
      </Dropdown>
    ) : null
  }, [
    currentUser?.code,
    selectedTask?.co_op,
    selectedTask?.person_in_charge.user_code,
    showApprovalModalHandler,
    showConfirmDenyTask,
  ])
  const renderAction = useCallback(() => {
    if (!selectedTask.status) return
    if (!currentUser) return
    switch (selectedTask.status) {
      case '030':
        if (
          currentUser.code !== selectedTask.creator_code.user_code &&
          currentUser.code !== selectedTask.person_in_charge.user_code
        )
          return null
        return (
          <Space>
            <Tooltip title={'Thêm người theo dõi'}>
              <a
                onClick={() => handleOpenStaffModal('followers')}
                style={{ fontSize: 16 }}
                className={'action-icon'}>
                <UserAddOutlined />
              </a>
            </Tooltip>
            <Tooltip title={'Thêm người phối hợp'}>
              <a
                onClick={() => handleOpenStaffModal('co_op')}
                style={{ fontSize: 16 }}
                className={'action-icon'}>
                <UsergroupAddOutlined />
              </a>
            </Tooltip>
            {renderPostAction()}
            {currentUser.code !== selectedTask.creator_code.user_code ? null : (
              <Tooltip title={'Thu hồi công việc'}>
                <a
                  onClick={showConfirmCancelTask}
                  style={{ fontSize: 14 }}
                  className={'action-icon'}>
                  <StopOutlined />
                </a>
              </Tooltip>
            )}
          </Space>
        )
      case '032':
      case '034':
      case '035':
        if (
          currentUser.code !== selectedTask.creator_code.user_code &&
          currentUser.code !== selectedTask.person_in_charge.user_code
        )
          return null
        return (
          <Space>
            <Tooltip title={'Thêm người theo dõi'}>
              <a
                onClick={() => handleOpenStaffModal('followers')}
                style={{ fontSize: 16 }}
                className={'action-icon'}>
                <UserAddOutlined />
              </a>
            </Tooltip>
            <Tooltip title={'Thêm người phối hợp'}>
              <a
                onClick={() => handleOpenStaffModal('co_op')}
                style={{ fontSize: 16 }}
                className={'action-icon'}>
                <UsergroupAddOutlined />
              </a>
            </Tooltip>
            {renderPostAction()}
          </Space>
        )
      case '031':
        if (currentUser.code !== selectedTask.creator_code.user_code)
          return null
        return (
          <Tooltip title={'Xoá công việc'}>
            <a
              onClick={showConfirmDeleteTask}
              style={{ fontSize: 16 }}
              className={'action-icon'}>
              <DeleteOutlined />
            </a>
          </Tooltip>
        )
      default:
        return null
    }
  }, [
    currentUser,
    handleOpenStaffModal,
    selectedTask.co_op,
    selectedTask.creator_code.user_code,
    selectedTask.person_in_charge.user_code,
    selectedTask.status,
    showApprovalModalHandler,
    showConfirmCancelTask,
    showConfirmDeleteTask,
    showConfirmDenyTask,
  ])
  const renderFileIcon = useCallback(type => {
    switch (type) {
      case 'image/jpeg':
      case 'image/png':
        return <FileImageOutlined />
      case 'application/pdf':
        return <FilePdfOutlined />
      default:
        return <FileOutlined />
    }
  }, [])
  const renderDropdownMenu = useCallback(
    attachment => {
      return (
        <Menu>
          <Menu.Item
            onClick={() =>
              handleDownloadAttachment(
                selectedTask.code,
                attachment.file_id,
                attachment.file_title,
                attachment.file_type
              )
            }>
            Tải về
          </Menu.Item>
        </Menu>
      )
    },
    [handleDownloadAttachment, history, selectedTask.code]
  )
  const renderAttachmentList = useCallback(() => {
    return attachments.map(attachment => {
      return (
        <Dropdown
          key={attachment.file_id}
          overlay={() => renderDropdownMenu(attachment)}>
          <AttachmentFile theme={commonStore.appTheme}>
            <div className="file-icon">
              {renderFileIcon(attachment.file_type)}
            </div>
            <div className="file-info">
              <Tooltip title={attachment.file_title}>
                <div className="title">
                  {attachment.file_title.length >= 20
                    ? attachment.file_title.slice(0, 20).concat('...')
                    : attachment.file_title}
                </div>
              </Tooltip>
              <div className="size">
                {`${(attachment.file_size / 1024).toFixed(2)} KB`}
              </div>
            </div>
          </AttachmentFile>
        </Dropdown>
      )
    })
  }, [attachments, commonStore.appTheme, renderDropdownMenu, renderFileIcon])
  const renderStepsNavigator = useCallback(() => {
    switch (selectedTask.process) {
      case '021':
        return (
          <StepsWrapper>
            <Steps
              size="small"
              onChange={handleChangeStep}
              current={parseInt(taskCommentCurrentStep) - 1}>
              <Step title="Bước 1" />
              <Step title="Bước 2" disabled={selectedTask.step < 2} />
            </Steps>
          </StepsWrapper>
        )
      case '022':
        return (
          <StepsWrapper onChange={handleChangeStep}>
            <Steps
              size="small"
              onChange={handleChangeStep}
              current={parseInt(taskCommentCurrentStep) - 1}>
              <Step title="Bước 1" />
              <Step title="Bước 2" disabled={selectedTask.step < 2} />
              <Step title="Bước 3" disabled={selectedTask.step < 3} />
            </Steps>
          </StepsWrapper>
        )
      default:
        return null
    }
  }, [
    handleChangeStep,
    selectedTask.process,
    selectedTask.step,
    taskCommentCurrentStep,
  ])

  const renderPersonInCharge = useCallback(() => {
    return (
      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
        <div style={{ marginBottom: 12 }}>
          <strong>Người xử lý</strong>
        </div>
        <Tooltip
          title={selectedTask.person_in_charge?.name_lowercase}
          placement="top">
          <Avatar style={{ backgroundColor: '#FF7A45' }}>
            {subStringAvatar(selectedTask.person_in_charge?.name_lowercase)}
          </Avatar>
        </Tooltip>
      </Col>
    )
  }, [selectedTask])

  const toBubblePeople = (labelTitle, persons) => {
    if (persons.length === 0) return null

    let items = persons.map(staff => (
      <Tooltip
        title={staff?.name_lowercase}
        placement="top"
        key={staff.user_code}>
        <Avatar style={{ backgroundColor: '#FF7A45' }}>
          {subStringAvatar(staff?.name_lowercase)}
        </Avatar>
      </Tooltip>
    ))
    if (persons.length > 3) {
      items = items.slice(0, 3)

      let countRemain = persons.length - 3
      const nameListRemain = persons
        .slice(3, persons.length)
        .map(e => `${e.name_lowercase}\n`)

      items.push(
        <Tooltip
          title={nameListRemain}
          placement="top"
          key={'more'}
          overlayStyle={{ whiteSpace: 'pre-line' }}>
          <Avatar style={{ backgroundColor: '#c9c9c9' }}>
            <b>...</b>
          </Avatar>
        </Tooltip>
      )
    }

    return (
      <Col xs={24} sm={24} md={24} lg={12} xl={8}>
        <div style={{ marginBottom: 12 }}>
          <strong>{labelTitle}</strong>{' '}
          <span style={{ color: '#999' }}>{persons.length}</span>
        </div>
        {items}
      </Col>
    )
  }

  const renderCoopsList = useCallback(() => {
    // if (selectedTask.co_op.length === 0) return null
    //
    // let items = selectedTask.co_op
    //   .map(staff =>
    //     <Tooltip title={staff?.name_lowercase} placement='top' key={staff.user_code}>
    //       <Avatar style={{ backgroundColor: '#FF7A45' }}>
    //         {subStringAvatar(staff?.name_lowercase)}
    //       </Avatar>
    //     </Tooltip>,
    //   )
    // if (selectedTask.co_op.length > 3) {
    //   items = items.slice(0, 3)
    //
    //   let countRemain = selectedTask.co_op.length - 3
    //   const nameListRemain = selectedTask.co_op.slice(3, selectedTask.co_op.length)
    //     .map(e => `${e.name_lowercase}\n`)
    //
    //   items.push(
    //     <Tooltip title={nameListRemain} placement='top' key={'more'} overlayStyle={{ whiteSpace: 'pre-line' }}>
    //       <Avatar style={{ backgroundColor: '#FFD8BF' }}>
    //         {countRemain}
    //       </Avatar>
    //     </Tooltip>,
    //   )
    // }
    //
    // return (
    //   <Col xs={24} sm={24} md={24} lg={12} xl={8}>
    //     <div style={{ marginBottom: 12 }}>
    //       <strong>Người phối hợp</strong> <span style={{ color: '#999' }}>{selectedTask.co_op.length}</span>
    //     </div>
    //     {
    //       items
    //     }
    //   </Col>
    // )

    return toBubblePeople('Người phối hợp', selectedTask.co_op)
  }, [selectedTask])

  const renderFollowersList = useCallback(() => {
    return toBubblePeople('Người theo dõi', selectedTask.followers)
  }, [selectedTask])

  useEffect(() => {
    if (!attachments) return
    if (attachments.length === 0) return
    loadingAnimationStore.showSpinner(true)
    taskManagementStore
      .getSelectedTaskViewAttachment(attachments[0].file_id, selectedTask.code)
      .then(() => loadingAnimationStore.showSpinner(false))
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        message.error(error.vi)
      })
    return () => taskManagementStore.clearSelectedTaskViewAttachment()
  }, [attachments, selectedTask.code])
  useEffect(() => {
    commentForm.resetFields()
  }, [commentForm, taskId])
  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    departmentStore
      .getDepartmentWithUsers()
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => {
      staffStore.clearSelectedStaffList()
      taskManagementCommentStore.clearTaskCommentList()
      taskManagementStore.clearSelectedTask()
      setShowAttachmentDropzone(false)
    }
  }, [])

  return (
    <TaskManagementDetailWrapper>
      <div className="scrollBox">
        <div className="d-flex">
          <h1>{title} </h1>
          <div>
            <TaskManagementStatusTag status={selectedTask.status} />
          </div>
          <div className="action-wrapper">{renderAction()}</div>
        </div>
        {renderStepsNavigator()}
        <TaskManagementDetailContent>
          <div className="content">
            {task_description ? (
              WordBreak(task_description, true)
            ) : (
              <div>Không có nội dung</div>
            )}
          </div>
          {selectedTaskViewAttachment && selectedTask.type === '300' && (
            <Row justify={'center'}>
              <PDFViewer
                file={selectedTaskViewAttachment}
                customStyleObj={{ height: '500px', width: '100%' }}
              />
            </Row>
          )}
          <Row type={'flex'} gutter={15}>
            {renderPersonInCharge()}
            {selectedTask.co_op.length !== 0 ||
            selectedTask.followers.length !== 0 ? (
              <>
                {renderCoopsList()}
                {renderFollowersList()}
              </>
            ) : null}
          </Row>

          {attachments.length !== 0 ? (
            <>
              <div style={{ marginBottom: 12, marginTop: 16 }}>
                <strong>File đính kèm</strong>{' '}
                <span style={{ color: '#999' }}>{attachments.length}</span>
              </div>
              <AttachmentList>{renderAttachmentList()}</AttachmentList>
            </>
          ) : null}
        </TaskManagementDetailContent>
        {renderCommentList()}

        <StaffModal
          modalVisible={showStaffModal}
          title={
            modalType === 'followers'
              ? 'Thêm người theo dõi'
              : 'Thêm người phối hợp'
          }
          onOk={handleAddAssignStaff}
          onClose={handleCloseModal}
          isMultiSelect={true}
        />

        <TaskApprovalModal
          visible={showApprovalModal}
          onCancel={hideApprovalModalHandler}
          onOk={handleApproveTask}
        />
      </div>
      {renderCommentInput()}
    </TaskManagementDetailWrapper>
  )
}

TaskManagementDetail.propTypes = {
  taskManagementStore: PropTypes.object,
  loadingAnimationStore: PropTypes.object,
  commonStore: PropTypes.object,
  departmentStore: PropTypes.object,
  staffStore: PropTypes.object,
  taskManagementCommentStore: PropTypes.object,
  authenticationStore: PropTypes.object,
}

export default withRouter(
  inject(
    'taskManagementStore',
    'loadingAnimationStore',
    'commonStore',
    'departmentStore',
    'staffStore',
    'taskManagementCommentStore',
    'authenticationStore',
    'fileStore',
  )(observer(TaskManagementDetail)),
)

import React, { memo } from 'react'
import { inject, observer } from 'mobx-react'
import uuid from 'uuid'
import TableComponent from '../../../../components/Common/TableComponent'

const ChamCongExcelTable = props => {
  const { excel_json } = props

  const tableColumn = () => {
    let columns = []
    columns.push({ title: 'Họ và tên', render: record => record?.name })

    for (let index = 1; index <= 31; index++) {
      let colName = index + ''
      columns.push({
        title: colName,
        render: record => {
          let day = record?.[index + '']
          let textStart = day?.start ?? ''
          let textEnd = day?.end ?? ''
          return textStart + '\n' + textEnd
        },
      })
    }

    columns.push({ title: 'SN', render: record => record?.sn })
    columns.push({ title: 'TG\nLV', render: record => record?.tglv })
    columns.push({ title: 'ĐM', render: record => record?.dm })
    columns.push({ title: 'VS', render: record => record?.vs })
    columns.push({ title: 'ĐM VS', render: record => record?.dm_vs })

    return columns
  }

  const renderCommentForm = () => {
    // console.log(excel_json)
    let jsonData = JSON.parse(unescape(excel_json)).data
    // console.log(jsonData)

    let rows = []
    for (let index = 0; index < jsonData.length; index++) {
      const row = {}
      // console.log(element)
      const jsonRow = jsonData[index]

      //ho va ten
      row.name = jsonRow.employee.employee_name

      //day
      const jTimeSheets = jsonRow.time_sheet
      for (let index = 0; index < jTimeSheets.length; index++) {
        const jTimeSheet = jTimeSheets[index]
        row[jTimeSheet.day] = {
          day: jTimeSheet.day,
          start: jTimeSheet.start,
          end: jTimeSheet.end,
        }
      }

      row['sn'] = jsonRow.sn
      row['tglv'] = jsonRow.tglv
      row['dm'] = jsonRow.dm
      row['vs'] = jsonRow.vs
      row['dm_vs'] = jsonRow.dm_vs

      rows.push(row)
    }

    console.log(rows)

    return (
      <TableComponent
        scroll={{ x: 1200 }}
        rowKey={() => uuid()}
        bordered
        columns={tableColumn()}
        dataSource={rows}
        pagination={false}
      />
    )
  }

  if (excel_json) return renderCommentForm()
  else return undefined
}

export default memo(
  inject(
    'authenticationStore',
    'loadingAnimationStore'
  )(observer(ChamCongExcelTable))
)

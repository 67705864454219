import React, { useCallback, useEffect, useRef, useState } from 'react'
// Component
import ApprovalList from '../../pages/Administrative/LeaveForm/ApproverListModal/Modal'
// Styled Component
import { FormButtonRow } from './SubmissionFormStyled'
import { SelectList } from '../../pages/MissionCreatePage/MissionCreatePageStyled'
// Ant design
import {
  BankOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import { blue } from '../../color'
import { ASSIGNEE_TYPE, PROPOSAL_STATUS } from '../../constants'
import RichEditor from '../RichEditor/RichEditor'
import SelectPeoplePopup from '../SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../SelectGroupPopup/SelectGroupPopup'
import { AvatarHasName } from '../Common/Avatar'
import ProposalStepApprove from './ProposalStepApprove'
import Checkbox from 'antd/es/checkbox/Checkbox'

const { TextArea } = Input

const currentStep = 0
const OtherSubmissionForm = props => {
  const {
    proposalStore,
    fileStore,
    loadingAnimationStore,
    authenticationStore,
    onCloseModal,
    proposalType,
    handleDownloadAttachment,
    selectPeopleStore,
  } = props

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
  } = selectPeopleStore

  const { formProposalType, formInputTemplate } = proposalStore

  const { form } = Form.useForm()
  const EDITOR_REF = useRef()

  const [fileList, setFileList] = useState([])
  const [editorContent, setEditorContent] = useState()
  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const [applyBieuMau, setApplyBieuMau] = useState(false)

  const [approvalLevels, setApprovalLevels] = useState([])
  useEffect(() => {
    const approvalLevelList = [
      ...proposalStore.proposalApproveConfig.approvalLevels,
    ]
    if (approvalLevelList.length === 0) {
      setApprovalLevels([])
      return
    }
    approvalLevelList[0].username = authenticationStore.currentUser?.username
    setApprovalLevels(approvalLevelList)
  }, [proposalStore.proposalApproveConfig, authenticationStore.currentUser])

  useEffect(() => {
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  // useEffect(() => {
  //   // console.log('formInputTemplate', formInputTemplate)
  //
  //   if (formInputTemplate) {
  //     setEditorContent(formInputTemplate)
  //   }
  //
  //   // console.log('editorContent', editorContent)
  // }, [formInputTemplate])

  /** Xử lý thay đổi file upload */
  const handleChangeFile = useCallback(
    info => {
      const filteredFileList = info.fileList.filter(
        (elem, index, fileArray) =>
          fileArray.findIndex(file => file.name === elem.name) === index
      )
      setFileList(filteredFileList)
    },
    [fileList]
  )

  /** Xử lý ghép API tạo phiếu trình nghỉ phép */
  const handleCreateOther = async data => {
    try {
      await proposalStore.createOther(data)
      proposalStore.setActiveTabProposal('')
      proposalStore.clearFilterProposal()
      proposalStore.resetFormProposalType()
      onCloseModal()
      await proposalStore.getProposals()
      message.success('Tạo mới thành công!')
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }

  const onFinish = async values => {
    const userNextStep = proposalStore.defaultApprovalConfigUpdate.approvalLevels.find(
      item => item.level === currentStep + 1
    )
    if (!userNextStep?.username) {
      return message.warning(`Hãy chọn ${userNextStep.name}!`)
    }
    const submitValues = {
      ...values,
      proposal_type: proposalType,
      followers: followerList.map(el => el.id),
      // assignees: [...handlerAssignees, ...followerAssignees],
      reason: EDITOR_REF.current.editor.getData(),
      approval_levels: proposalStore.defaultApprovalConfigUpdate.approvalLevels.map(
        item => ({
          level: item.level,
          name: item.name,
          username: item.username,
          allow_edit: item.allow_edit,
          status:
            item.level === currentStep + 1
              ? PROPOSAL_STATUS.PENDING
              : item.status,
        })
      ),
    }
    const uploadFileList =
      submitValues.file_ids && submitValues.file_ids.fileList
        ? submitValues.file_ids.fileList.map(file => file.originFileObj)
        : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    try {
      loadingAnimationStore.showSpinner(true)
      const response = await Promise.all(batchUploadArr)
      uploadedFiles = response.map(response => response.data.file_id)
      submitValues.file_ids = uploadedFiles
      await handleCreateOther(submitValues)
      form?.resetFields()
      setFileList([])
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const onApplyBieuMauChange = value => {
    setApplyBieuMau(value)
    // console.log('onApplyBieuMauChange', value)
    if (value) {
      if (formInputTemplate) {
        // console.log('setEditorContent', formInputTemplate)
        setEditorContent(formInputTemplate)
      }
    }
  }

  return (
    <Form
      scrollToFirstError={true}
      form={form}
      name={'phieu-trinh-khac-form'}
      layout={'vertical'}
      onFinish={onFinish}>
      <Row type={'flex'} gutter={15}>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'title'}
            label={'Tiêu đề:'}
            rules={[
              { required: true, message: 'Vui lòng nhập tiêu đề' },
              { max: 500, message: 'Tiêu đề không được dài quá 500 kí tự!' },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            label={
              <div>
                <p>Nội dung:</p>
                <Checkbox
                  checked={applyBieuMau}
                  onChange={e => onApplyBieuMauChange(e.target.checked)}>
                  <span>Áp dụng Biểu mẫu</span>
                </Checkbox>
              </div>
            }>
            <RichEditor
              EDITOR_REF={EDITOR_REF}
              placeholder={'Nhập nội dung'}
              editorContent={editorContent && editorContent}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item name={'file_ids'} label={'Tài liệu đính kèm:'}>
            <Upload
              valuePropName={'fileList'}
              fileList={fileList}
              onChange={handleChangeFile}
              beforeUpload={() => false}
              multiple={true}
              iconRender={() => <FileTextOutlined />}>
              <Button icon={<UploadOutlined />}>Chọn tài liệu đính kèm</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <label style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>
            Người theo dõi:
          </label>

          <Space>
            <Tooltip title="Chọn người dùng" color={blue}>
              <Button
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() =>
                  handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
                }
              />
            </Tooltip>
          </Space>

          <SelectList>
            {followerList.map(el => (
              <Tag
                className={'tag-selected'}
                color={'blue'}
                key={el.id}
                closable
                onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                <AvatarHasName
                  imgId={el.image_id}
                  size={22}
                  name={el.full_name}
                  icon={
                    (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                      <BankOutlined />
                    )) ||
                    (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                      <TeamOutlined />
                    ))
                  }
                />
              </Tag>
            ))}
          </SelectList>
        </Col>
        <Col xs={24} lg={24} style={{ marginTop: 10 }}>
          <Form.Item label={'Văn bản mẫu:'}>
            {/* <a style={{ display: 'block' }}
               onClick={() => handleDownloadAttachment('6177d2e6a4090000d3004ea2', 'Đơn xin nghỉ phép (sửa).docx')}
            >
              <FileWordOutlined />&nbsp;Đơn
              xin nghỉ
              phép (sửa).docx</a>
            <a style={{ display: 'block' }}
               onClick={() => handleDownloadAttachment('6177d2f7a4090000d3004ea3', 'BM Kế hoạch đi công tác (QĐ494 - 18.12.2018).docx')}
            ><FileWordOutlined />&nbsp;BM Kế hoạch đi công tác (QĐ494 -
              18.12.2018).docx</a>
            <a style={{ display: 'block' }}
               onClick={() => handleDownloadAttachment('61781dd5d40e000016003052', 'Mau phieu dang ky xe oto di cong tac_ver1.docx')}
            ><FileWordOutlined />&nbsp;Mau phieu dang ky xe oto di cong tac_ver1.docx</a>
             */}
          </Form.Item>
        </Col>
      </Row>

      <ProposalStepApprove approvalLevelList={approvalLevels} />

      <FormButtonRow>
        <Button
          onClick={() => {
            onCloseModal()
          }}>
          Hủy bỏ
        </Button>
        <Button style={{ marginLeft: 10 }} htmlType={'submit'} type={'primary'}>
          Tạo mới
        </Button>
      </FormButtonRow>

      <ApprovalList />

      <SelectPeoplePopup
        noLoadSelectData
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </Form>
  )
}

OtherSubmissionForm.propTypes = {}

export default inject(
  'leavePageStore',
  'proposalStore',
  'authenticationStore',
  'fileStore',
  'loadingAnimationStore',
  'selectUserStore',
  'selectPeopleStore',
)(observer(OtherSubmissionForm))

export const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 14 },
}

export const CONTAINER_MAX_WIDTH = 820
export const CKEDITOR_HEIGHT = '280px'

export const iconMoreVert = {
  fontSize: '1.2em',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const stylePagination = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 20,
  marginBottom: 10,
}

export const hozDivider = {
  height: 0.5,
  margin: '0 15px',
  backgroundColor: '#f0f0f0',
}

export const hozDividerNoMarginSide = {
  height: 0.5,
  margin: '0 0px',
  backgroundColor: '#f0f0f0',
}

export const MODAL_MODE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
}

import React, { useState } from 'react'
import { Button, message, Modal, Upload } from 'antd'
import { SendOutlined, SyncOutlined } from '@ant-design/icons'
import { blue } from '../../../color'
import { observer } from 'mobx-react'
import uploadISOFileStore from './uploadISOFileStore'

const UploadIsoFileModal = props => {
  const { visible, parentFolderId, onClose } = props
  const { isLoading } = uploadISOFileStore
  const [fileList, setFileList] = useState([])

  const handleImportFile = async () => {
    if (fileList.length === 0) {
      return message.warning('Hãy chọn file!')
    }

    try {
      const formData = new FormData()
      formData.append('file', fileList[0])
      formData.append('folderId', parentFolderId)
      await uploadISOFileStore.upload(formData)

      closeAndResetForm()

      message.success('Tải file thành công')
    } catch (err) {
      console.log(err)
      if (err && err.errorCode && err.errorCode == 'FILE_NAME_DUPLICATE') {
        message.error('Tên file đã tồn tại')
      } else {
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    } finally {
      uploadISOFileStore.isLoading = false
    }
  }

  const handleChangeFile = info => {
    console.log('handleChangeFile', info)
    setFileList([info.file])
  }

  const closeAndResetForm = () => {
    setFileList([])
    onClose()
  }

  return (
    <Modal
      title="Tải file lên thư mục"
      footer={null}
      closable={true}
      visible={visible}
      onCancel={() => {
        closeAndResetForm()
      }}>
      <Upload
        valuePropName={'fileList'}
        fileList={fileList}
        onChange={handleChangeFile}
        beforeUpload={() => false}
        showUploadList={true}>
        <Button
          icon={<SyncOutlined />}
          style={{
            color: blue,
            fontWeight: 500,
          }}>
          Chọn file
        </Button>
      </Upload>

      <Button
        style={{ marginTop: 16, width: '100%' }}
        icon={<SendOutlined />}
        loading={isLoading}
        onClick={handleImportFile}
        type={'primary'}>
        Tải lên
      </Button>
    </Modal>
  )
}

export default observer(UploadIsoFileModal)

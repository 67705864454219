import React, { useEffect } from 'react'
import { UnreadDocModalWrapper } from './UnreadDocModalStyled'
import { DOCUMENT_TYPE } from '../../constants'
import EmptyContent from '../EmptyContent'
import {
  BranchesOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Button, message, Modal, Tooltip } from 'antd'
import { blue, green } from '../../color'
import { DocumentList } from '../../pages/MissionDetailPage/MissionDetailPageStyled'
import unreadDocStore from './unreadDocStore'
import { inject, observer } from 'mobx-react'

const UnreadDocModal = props => {
  const { items, isVisible, onClose } = props

  const { isLoading } = unreadDocStore

  const markAsRead = async () => {
    try {
      await unreadDocStore.markAsRead(items.map(item => item.code))
      onClose(true)
    } catch (err) {
      message.error(err.vi)
    } finally {
      unreadDocStore.isLoading = false
    }
  }

  useEffect(() => {
    return () => {
      unreadDocStore.clear()
    }
  }, [])

  return (
    <>
      <UnreadDocModalWrapper
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>
              {props.title}
              {isLoading && (
                <LoadingOutlined style={{ marginLeft: 10 }} spin={true} />
              )}
            </div>
            <div style={{ display: 'flex', gap: 10 }}>
              <Button onClick={onClose} danger>
                Đóng
              </Button>
              <Button
                type={'primary'}
                icon={<BranchesOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: 'Đánh dấu đã đọc',
                    icon: (
                      <ExclamationCircleOutlined style={{ color: '#fc0000' }} />
                    ),
                    content:
                      'Bạn có chắc muốn đánh dấu đã đọc tất cả các văn bản này?',
                    okType: 'danger',
                    okText: 'Đồng ý',
                    cancelText: 'Không',
                    onOk() {
                      markAsRead()
                    },
                  })
                }}
                loading={isLoading}>
                Đánh dấu tất cả đã đọc
              </Button>
            </div>
          </div>
        }
        width={1300}
        style={{ top: 40 }}
        visible={isVisible}
        closable={false}
        maskClosable={false}
        onCancel={onClose}
        footer={null}>
        <div>
          <DocumentList>
            {items.length > 0 ? (
              items.map(item => (
                <Tooltip key={item.code} title={'Xem'}>
                  <li
                    onClick={() =>
                      window.open(
                        `/internal-document/${
                          item.type === DOCUMENT_TYPE.INCOMING
                            ? 'incoming-document'
                            : 'outgoing-document'
                        }/view/${item.code}`,
                        '_blank'
                      )
                    }>
                    <CopyOutlined
                      style={{
                        color:
                          item.type === DOCUMENT_TYPE.INCOMING ? green : blue,
                        marginRight: 8,
                      }}
                    />
                    {`${item.document_number} - ${item.title}`}
                  </li>
                </Tooltip>
              ))
            ) : (
              <EmptyContent />
            )}
          </DocumentList>
        </div>
      </UnreadDocModalWrapper>
    </>
  )
}

export default inject()(observer(UnreadDocModal))

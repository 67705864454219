import { message } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { MODULE_CODE } from '../../../constants'
import ConnectedCommitteeOrganizationPage from '../../ConnectedCommitteeOrganizationPage'
import ConnectedCommiteeDocumentLayout from './ConnectedCommiteeDocumentLayout'

const ConnectedCommitteeOrganization = (props) => {
  const history = useHistory()

  useEffect(() => {
    if(props.moduleStore.moduleList.length === 0) return 

    if (!props.moduleStore.checkAccessModule(MODULE_CODE.lien_thong_van_ban_ubqlv)) {
      message.error('Không được quyền truy cập vào trang này')
      history.push('/dashboard')
    }
  }, [props.moduleStore.moduleList])

  return (
    <ConnectedCommiteeDocumentLayout selectedKey="organization">
      <Helmet>
        <title>
          Công ty nhận | UBQLV | Quản trị | VIMC Quản lý VB VIMC
        </title>
      </Helmet>
      <ConnectedCommitteeOrganizationPage isTab />
    </ConnectedCommiteeDocumentLayout>
  )
}

export default inject(
  'moduleStore',
)(observer(ConnectedCommitteeOrganization))

import React, { useMemo } from 'react'
import { Col, message, Popconfirm, Row, Tag, Tooltip } from 'antd'
import {
  CommentHidden,
  FlexBetweenBox,
  WorkCommentBox,
} from '../../../../components/CommentComponent/CommentComponentStyled'
import { DeleteTwoTone, QuestionCircleOutlined } from '@ant-design/icons'
import utils from '../../../../utils'
import RichText from '../../../../components/RichEditor/RichText'
import FileItem from '../../../../components/Common/FileItemComponent/FileItem'
import date_format from '../../../../date_format'
import loadingAnimationStore from '../../../../stores/loadingAnimationStore'
import workStore from '../../../../stores/workStore'
import { hozDividerNoMarginSide } from '../../../../ui_constants'

const WorkCommentList = props => {
  const { commentList, setCommentList, code } = props
  const handleDeleteComment = async (comment_id, index) => {
    try {
      loadingAnimationStore.setShowSpinInline(true)
      await workStore.deleteCommentWork(code, comment_id)
      const newCommentList = [...commentList]
      newCommentList[index] = {
        ...newCommentList[index],
        deleted: true,
      }
      setCommentList(newCommentList)
      props.handleShowDeletedComments(newCommentList, props.isShowDelComments)
      message.success('Xóa ý kiến thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  const renderCommentList = useMemo(() => {
    if (commentList.length === 0) {
      return
    }
    return (
      commentList &&
      commentList.map((comment, index) => {
        return (
          <CommentHidden key={comment.comment_id}>
            {!comment.isHidden && (
              <WorkCommentBox
                key={comment.comment_id}
                deleted={comment.deleted}>
                <Row align={'middle'} style={{ marginBottom: 0 }}>
                  <Col flex={'auto'}>
                    <b className={'name-create'}>
                      {utils.getNameInCapitalize(comment.name_uppercase)}
                    </b>
                  </Col>
                  <Col>
                    <p className={'time'}>
                      {date_format.renderTime(comment.created_at)}
                    </p>
                  </Col>
                </Row>

                <Row style={{ marginBottom: 2 }} wrap={false} gutter={8}>
                  <Col flex={'auto'} className={'content'}>
                    <RichText htmlText={comment.content.trim()} />
                  </Col>
                  <Col>
                    {!comment.deleted ? (
                      <div>
                        <Popconfirm
                          title={'Bạn có muốn xoá ý kiến này'}
                          okType={'danger'}
                          okText={'Xoá'}
                          onConfirm={() =>
                            handleDeleteComment(comment.comment_id, index)
                          }
                          placement={'topRight'}
                          cancelText={'Không'}
                          icon={
                            <QuestionCircleOutlined
                              style={{ color: '#fc0000' }}
                            />
                          }>
                          <Tooltip title={'Xóa ý kiến'}>
                            <DeleteTwoTone twoToneColor={'#fc0000'} />
                          </Tooltip>
                        </Popconfirm>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}>
                        <Tag
                          style={{
                            marginRight: 0,
                            fontSize: 10,
                            borderRadius: '10px',
                          }}
                          color={'red'}>
                          Đã xóa
                        </Tag>
                      </div>
                    )}
                  </Col>
                </Row>

                <FlexBetweenBox>
                  {comment.files
                    ? comment.files.map(file => (
                        <FileItem
                          key={file.file_id}
                          file_id={file.file_id}
                          file_name={file.file_name}
                          file_type={utils.getExtensionFile(file.file_name)}
                          deleted={comment.deleted}
                        />
                      ))
                    : []}
                </FlexBetweenBox>

                {comment.files && <div style={{ height: 12 }} />}

                <div style={hozDividerNoMarginSide} />
              </WorkCommentBox>
            )}
          </CommentHidden>
        )
      })
    )
  }, [commentList])

  return <div>{renderCommentList}</div>
}

WorkCommentList.propTypes = {}

export default WorkCommentList

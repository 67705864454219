import { message } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { useEffect } from 'react'
import { memo } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, withRouter } from 'react-router-dom'
import { MODULE_CODE } from '../../constants'
import UserGroupPage from '../UserGroupPage'
import MissionManagementLayout from './MissionManagementLayout'

const MissionManagementMissionGroup = props => {
  const history = useHistory()

  useEffect(() => {
    if(props.moduleStore.moduleList.length === 0) return 

    if (!props.moduleStore.checkAccessModule(MODULE_CODE.nhom_nhiem_vu)) {
      message.error('Không được quyền truy cập vào trang này')
      history.push('/dashboard')
    }
  }, [props.moduleStore.moduleList])

  return (
    <MissionManagementLayout selectedKey="mission-group">
      <Helmet>
        <title>Nhóm nhiệm vụ | Quản lý nhiệm vụ| Nhiệm vụ</title>
      </Helmet>
      <UserGroupPage isTab isNhomNhiemVu />
    </MissionManagementLayout>
  )
}

export default memo(
  withRouter(
    inject(
      'authenticationStore',
      'aclStore',
      'moduleStore'
    )(observer(MissionManagementMissionGroup))
  )
)

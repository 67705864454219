import styled from 'styled-components'
import { green } from '../../../color'

export const FilterButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  transition: 0.3s;

  .count-filter {
    z-index: 9;
    background-color: ${green};
    color: #fff;
    font-size: 11px;
    text-align: center;
    position: absolute;
    top: -7px;
    right: -7px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
  }
`

import React from 'react'
import ModuleParentItem from './ModuleParentItem'
import ModuleChildrenItem from './ModuleChildrenItem'
import { inject, observer } from 'mobx-react'
import { blue } from '../../color'
import {
  ApartmentOutlined,
  BarChartOutlined,
  CalendarOutlined,
  FileTextOutlined,
  FormOutlined,
  HddOutlined,
  ProfileOutlined,
  SnippetsOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import { MODULE_CODE } from '../../constants'

const styleIcon = { marginRight: 10, color: blue, fontSize: 16 }
const renderIcon = type => {
  if (type === MODULE_CODE.van_ban) {
    return <FileTextOutlined style={styleIcon} />
  }
  if (type === MODULE_CODE.lich_co_quan) {
    return <CalendarOutlined style={styleIcon} />
  }
  if (type === MODULE_CODE.nhiem_vu) {
    return <ProfileOutlined style={styleIcon} />
  }
  if (type === MODULE_CODE.cong_viec) {
    return <SnippetsOutlined style={styleIcon} />
  }
  if (type === MODULE_CODE.bang_luong) {
    return <BarChartOutlined style={styleIcon} />
  }
  if (type === MODULE_CODE.hanh_chinh) {
    return <HddOutlined style={styleIcon} />
  }
  if (type === MODULE_CODE.tien_ich) {
    return <SolutionOutlined style={styleIcon} />
  }
  if (type === MODULE_CODE.quan_tri) {
    return <ApartmentOutlined style={styleIcon} />
  }
  if (type === MODULE_CODE.ky_so) {
    return <FormOutlined style={styleIcon} />
  }
}

const ModuleList = props => {
  const { moduleStore } = props

  return (
    <div>
      {moduleStore.moduleList.map(item => (
        <div key={item.code}>
          <ModuleParentItem record={item} icon={renderIcon(item.code)} />
          <div>
            {item.sub_modules.map((el, i) => (
              <ModuleChildrenItem
                record={el}
                last={item.sub_modules.length - 1 === i}
                key={el.code}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

ModuleList.propTypes = {}

export default inject('moduleStore')(observer(ModuleList))

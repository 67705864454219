import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'
import styled from 'styled-components'

const CustomTag = styled(Tag)`
  min-width: unset !important;
  margin-right: 0;
  border-radius: 1rem;
`

const renderStatusTag = status => {
  switch (status) {
    case '030':
      return <CustomTag color={'orange'}>Chưa xử lý</CustomTag>
    case '031':
      return <CustomTag color={'default'}>Đã huỷ</CustomTag>
    case '032':
      return <CustomTag color={'green'}>Đang xử lý</CustomTag>
    case '033':
      return <CustomTag color={'black'}>Bị từ chối</CustomTag>
    case '034':
      return <CustomTag color={'geekblue'}>Hoàn thành</CustomTag>
    case '035':
      return <CustomTag color={'red'}>Quá hạn</CustomTag>
    default:
      return null
  }
}

const TaskManagementStatusTag = props => {
  return <>{renderStatusTag(props.status)}</>
}

TaskManagementStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
}

export default TaskManagementStatusTag

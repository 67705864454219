import styled from 'styled-components'

export const TableWrapper = styled.div`
  .ant-table-tbody {
    .ant-table-row {
      cursor: pointer;
    }
  }
`

export const HeaderTableWrapper = styled.div`
  height: 51px;
  background-color: rgb(44, 101, 172);
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-align: center;

  .date {
    padding: 16px;
    width: 100px;
  }

  .element {
    padding: 16px;
    width: calc((100% - 100px) / 5);
  }
`

export const BodyTableWrapper = styled.div``
export const BodyTableLeft = styled.strong`
  width: 100px;
  flex: 0 0 100px;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 16px;

  div {
    text-align: center;
  }
`
export const BodyTableRight = styled.div`
  display: flex;
  transition: 0.3s;

  &:hover {
    background-color: #edf3ff;
    transition: 0.3s;
  }
`

export const ItemTableRight = styled.div`
  cursor: pointer;
  padding: 16px;
  word-break: break-word;
  width: calc(100% / 5);
  border: 1px solid #cddaf4 !important;
`

import { inject, observer } from 'mobx-react'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { memo } from 'react'
import { withRouter } from 'react-router-dom'
import { MODULE_CODE } from '../../constants'
import MissionManagementAccessibility from './MissionManagementAccessibility'
import MissionManagementMissionGroup from './MissionManagementMissionGroup'
const MissionManagement = props => {
  const { moduleStore } = props

  const [isAccessMissionGroupTab, setIsAccessMissionGroupTab] = useState(false)

  useEffect(() => {
    if (moduleStore.checkAccessModule(MODULE_CODE.nhom_nhiem_vu))
      setIsAccessMissionGroupTab(true)
  }, [moduleStore.moduleList])

  if (isAccessMissionGroupTab) {
    return <MissionManagementMissionGroup />
  }

  return <MissionManagementAccessibility />
}

export default memo(
  withRouter(
    inject(
      'authenticationStore',
      'aclStore',
      'moduleStore'
    )(observer(MissionManagement))
  )
)

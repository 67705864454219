import { action, observable } from 'mobx'
import { ConsultCommentRequest } from '../requests/ConsultCommentRequest'

class consultCommentStore {
  /** Consult comment list */
  @observable consultCommentList = []

  /** Get comments */
  @action getConsultComment = taskCode => {
    return new Promise((resolve, reject) => {
      ConsultCommentRequest.getConsultComment(taskCode)
        .then(response => {
          this.consultCommentList = response.data.reverse()
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Post new comment */
  @action postConsultComment = (taskCode, data) => {
    return new Promise((resolve, reject) => {
      ConsultCommentRequest.postConsultComment(taskCode, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /** Delete comment */
  @action deleteConsultComment = (taskCode, commentId) => {
    return new Promise((resolve, reject) => {
      ConsultCommentRequest.deleteConsultComment(taskCode, commentId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /** Clear data */
  @action clearConsultCommentList = () => {
    this.consultCommentList.length = 0
  }
}

export default new consultCommentStore()

import React from 'react'
import TableComponent from '../../components/Common/TableComponent'
import { EmptyText } from '../../components/Common/CellText'
import { AvatarHasName } from '../../components/Common/Avatar'
import { StatusTag } from '../../components/Common/StatusTag'
import { TYPE_STATUS } from '../../constants'
import { Empty } from 'antd'
import ProposalAdvanceListPagination from './ProposalAdvanceListPagination'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

const ProposalAdvanceList = props => {
  const { proposalAdvanceStore, isLoading } = props

  const history = useHistory()

  const tableColumns = [
    {
      title: 'Tiêu đề',
      render: record =>
        record.title ? (
          <span>{record.title}</span>
        ) : (
          <EmptyText>Không có tiêu đề.</EmptyText>
        ),
    },
    {
      title: 'Người gửi',
      width: 180,
      render: record => (
        <AvatarHasName
          imgId={record.create_user?.image_id}
          name={record.create_user?.name_lowercase}
        />
      ),
    },
    {
      title: 'Trạng thái',
      width: 150,
      align: 'center',
      render: record => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {StatusTag(TYPE_STATUS.PROPOSAL, record.proposal_status)}
        </div>
      ),
    },
  ]

  return (
    <>
      <TableComponent
        onRow={record => {
          return {
            onClick: () =>
              history.push(`/proposal-advance/view/${record.proposal_code}`),
          }
        }}
        rowKey={'proposal_code'}
        dataSource={proposalAdvanceStore.proposalAdvanceList}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
      <ProposalAdvanceListPagination />
    </>
  )
}

ProposalAdvanceList.propTypes = {}

export default inject('proposalAdvanceStore')(observer(ProposalAdvanceList))

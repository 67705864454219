import React, { memo } from 'react'
import { inject, observer } from 'mobx-react'
import { Avatar, Comment, Pagination, Tooltip } from 'antd'
import { CellEclipseBox } from '../../../components/Common/Table'
import AdvanceFilterBlock from './AdvanceFilterBlock'
import { apiUrl } from '../../../config'
import TableComponent from '../../../components/Common/TableComponent'
import { EmptyText } from '../../../components/Common/CellText'

const ListUserPage = props => {
  const { userStore, loadingAnimationStore } = props

  const {
    userList,
    userListTotalCount,
    userListPageSize,
    userListPageIndex,
    setFilter,
  } = userStore

  const onChangePagination = e => {
    setFilter('userListPageIndex', e - 1)
    loadingAnimationStore.setTableLoading(true)
    userStore
      .getUserList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
  }

  const tableColumn = [
    {
      title: 'Thông tin',
      render: record => (
        <Comment
          author={
            <span style={{ textTransform: 'capitalize' }}>
              {record.name_lowercase}
            </span>
          }
          avatar={
            <Avatar
              style={{ backgroundColor: '#1890ff' }}
              src={
                record.imageId && `${apiUrl}/api/v1/images/${record.imageId}`
              }>
              {record?.name_uppercase.split('')[0].toString().toUpperCase()}
            </Avatar>
          }
        />
      ),
    },
    {
      title: 'ID',
      width: 100,
      render: record => record?.username,
    },
    {
      title: 'Mã nhân viên',
      width: 120,
      render: record =>
        record?.ma_nv ? record?.ma_nv : <EmptyText>Không rõ</EmptyText>,
    },
    {
      title: 'Email',
      render: record => (
        <Tooltip title={record?.email}>
          <CellEclipseBox>{record?.email}</CellEclipseBox>
        </Tooltip>
      ),
    },
    {
      title: 'Điện thoại',
      render: record => (
        <strong>
          {record.phone ? record?.phone : <EmptyText>Chưa rõ</EmptyText>}
        </strong>
      ),
    },
    {
      title: 'Số máy nội bộ',
      render: record => (
        <strong>
          {record.home_phone ? (
            record?.home_phone
          ) : (
            <EmptyText>Chưa rõ</EmptyText>
          )}
        </strong>
      ),
    },
    {
      title: 'Địa chỉ',
      width: 100,
      render: record =>
        record.address ? record?.address : <EmptyText>Chưa rõ</EmptyText>,
    },
    {
      title: 'Chức vụ',
      render: record =>
        record.position ? (
          record?.position.name
        ) : (
          <EmptyText>Chưa rõ</EmptyText>
        ),
    },
    {
      title: 'Phòng ban',
      render: record => (
        <Tooltip title={record?.department?.name}>
          <CellEclipseBox>{record?.department?.name}</CellEclipseBox>
        </Tooltip>
      ),
    },
  ]

  return (
    <>
      <AdvanceFilterBlock />
      <TableComponent
        rowKey={record => record.code}
        columns={tableColumn}
        dataSource={userList}
        pagination={false}
        loading={loadingAnimationStore.tableLoading}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '15px 0',
        }}>
        <Pagination
          onChange={e => onChangePagination(e)}
          hideOnSinglePage={true}
          total={userListTotalCount}
          pageSize={userListPageSize}
          current={userListPageIndex + 1}
          showSizeChanger={false}
          showLessItems
        />
      </div>
    </>
  )
}

ListUserPage.propTypes = {}

export default memo(
  inject('userStore', 'loadingAnimationStore')(observer(ListUserPage)),
)

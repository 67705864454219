import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import TableComponent from '../../../../components/Common/TableComponent'
import { Button, Dropdown, Menu, message, Modal } from 'antd'
import {
  AlignLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import PopupEditAccountCommand from '../PopupEditAccountCommand/PopupEditAccountCommand'
import { blue, greenPrimary, redPrimary } from '../../../../color'

const { confirm } = Modal

const ListAccountOfCommand = props => {
  const {
    selectedCommand,
    commandAccounts,
    onClose,
    userStore,
    accountEditStore,
    authenticationStore,
    loadingAnimationStore,
  } = props

  const { selectedUser } = userStore

  const [selectedAccount, setSelectedAccount] = useState()
  const [isVisibleEditPopup, setIsVisibleEditPopup] = useState()

  const menuButton = record => {
    return (
      <Menu>
        <Menu.Item
          key="active"
          icon={<SettingOutlined style={{ color: greenPrimary }} />}
          onClick={() => {
            handleSetActive(record)
          }}>
          <span style={{ color: greenPrimary }}>Đặt làm sử dụng mặc định</span>
        </Menu.Item>

        <Menu.Item
          key="edit"
          icon={<EditOutlined style={{ color: blue }} />}
          onClick={() => handleEdit(record)}>
          <span style={{ color: blue }}>Sửa</span>
        </Menu.Item>

        <Menu.Item
          key="delete"
          icon={<DeleteOutlined style={{ color: redPrimary }} />}
          onClick={() => {
            handleDelete(record)
          }}>
          <span style={{ color: redPrimary }}>Xóa</span>
        </Menu.Item>
      </Menu>
    )
  }

  const actionColumn = {
    title: 'Tác vụ',
    key: 'action',
    render: record => (
      <Dropdown overlay={menuButton(record)} trigger={['click']}>
        <Button type="primary">
          <AlignLeftOutlined />
          Thao tác
        </Button>
      </Dropdown>
    ),
  }

  const tableColumns = [
    {
      title: 'Tên tài khoản',
      key: 'alias',
      render: record => (
        <strong style={{ textTransform: 'capitalize' }}>{record.alias}</strong>
      ),
    },
    {
      title: 'Username',
      key: 'account_name',
      render: record => record.account_name,
    },
    {
      title: 'Trạng thái',
      key: 'is_active',
      render: record => (record.is_active ? 'Đang sử dụng' : '--'),
    },
    actionColumn,
  ]

  const handleEdit = itemAccount => {
    setSelectedAccount(itemAccount)
    setIsVisibleEditPopup(true)
  }

  const handleSetActive = async itemAccount => {
    try {
      loadingAnimationStore.showSpinner(true)
      await accountEditStore.setActiveAccount(itemAccount.id)

      message.success('Đặt tài khoản làm sử dụng mặc định thành công')

      onEditSuccess()
    } catch (e) {
      message.error(e.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleDelete = itemAccount => {
    if (!itemAccount.is_active) {
      confirm({
        title: 'Bạn có muốn xóa tài khoản này không?',
        icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
        okText: 'Đồng ý',
        cancelText: 'Không',
        okType: 'danger',
        onOk() {
          doDelete(itemAccount)
        },
      })
    } else {
      message.warning('Tài khoản này đang được sử dụng')
    }
  }

  const doDelete = async itemAccount => {
    try {
      loadingAnimationStore.showSpinner(true)
      await accountEditStore.deleteAccountById(itemAccount.id)

      message.success('Xóa tài khoản thành công')

      onEditSuccess()
    } catch (e) {
      message.error(e.vi)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleCreate = () => {
    setSelectedAccount(null)
    setIsVisibleEditPopup(true)
  }

  const onCloseEditPopup = () => {
    setIsVisibleEditPopup(false)
  }

  const onEditSuccess = () => {
    accountEditStore.getAccountsByUserCode(selectedUser.code)
  }

  return (
    <>
      <TableComponent
        rowKey={record => record.id}
        dataSource={commandAccounts}
        columns={tableColumns}
        pagination={false}
      />

      <PopupEditAccountCommand
        selectedCommand={selectedCommand}
        selectedAccount={selectedAccount}
        isVisibleEditPopup={isVisibleEditPopup}
        onClose={onCloseEditPopup}
        onEditSuccess={onEditSuccess}
      />

      <Button
        style={{ marginTop: 8 }}
        onClick={handleCreate}
        icon={<PlusOutlined />}>
        Tạo mới tài khoản
      </Button>
    </>
  )
}

ListAccountOfCommand.propTypes = {}

export default inject(
  'userStore',
  'authenticationStore',
  'loadingAnimationStore',
  'commandStore',
  'accountEditStore'
)(observer(ListAccountOfCommand))

import React from 'react'
import PropTypes from 'prop-types'
import DashboardLayout from '../DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

const InternalMessageLayout = props => {
  const {
    children,
    title,
    selectedKey,
    linkToInternalMessageRecieve,
    linkToInternalMessageSend,
  } = props

  return (
    <DashboardLayout>
      {title}
      <ContentBlockWrapper>
        <Menu
          mode="horizontal"
          selectedKeys={[selectedKey]}
          style={{ marginBottom: 16 }}>
          <Menu.Item key="receive">
            <Link to={linkToInternalMessageRecieve}>Tin nhắn đến</Link>
          </Menu.Item>
          <Menu.Item key="send">
            <Link to={linkToInternalMessageSend}>Tin nhắn đi</Link>
          </Menu.Item>
        </Menu>
        {children}
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

InternalMessageLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  selectedKey: PropTypes.string,
}

export default inject('aclStore')(observer(InternalMessageLayout))

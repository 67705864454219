import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  message,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from 'antd'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  DOCUMENT_TYPE,
  TASK_DOCUMENT_TYPE,
} from '../../constants'
import EmptyContent from '../../components/EmptyContent'
import {
  ButtonsWrapper,
  DocumentList,
  FormActionFooter,
  MissionCreateModal,
  SelectList,
  SelectTitle,
} from './MissionCreatePageStyled'
import { blue, green, yellowPrimary } from '../../color'
import {
  BankOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { AvatarHasName } from '../../components/Common/Avatar'
import RichEditor from '../../components/RichEditor/RichEditor'
import { EmptyText } from '../../components/Common/CellText'
import { inject, observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import ModalIncomingDocumentList from './ModalIncomingDocumentList'
import ModalOutgoingDocumentList from './ModalOutgoingDocumentList'
import SelectPeoplePopup from '../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../components/SelectGroupPopup/SelectGroupPopup'
import SelectPeoplePopupTruc from '../../components/SelectPeoplePopupTruc/SelectPeoplePopupTruc'
import moment from 'moment'
import { toJS } from 'mobx'
import TaskTagPicker from '../../components/Shared/TaskTagPicker'
import { TaskRequest } from '../../requests/TaskRequest'

const { Option, OptGroup } = Select
const { Title } = Typography

const MissionCreatePopup = props => {
  const {
    loadingAnimationStore,
    taskStore,
    fileStore,
    internalDocumentStore,
    authenticationStore,
    userTrucStore,
    selectPeopleStore,
    messagesStore,
    isMissionFull,
    onClearForm,
    noLoadSelectData,
    isModalOpen,
    handleCancel,
  } = props
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)
  
  const [tagList, setTagList] = useState([])
  useEffect(() => {
    ;(async () => {
      await TaskRequest.getTagList().then(res => {
        setTagList(
          res.data.map(tag => ({
            code: tag.code,
            name: tag.name,
            type: tag.type,
          }))
        )
      })
    })()
  }, [])

  useEffect(() => {
    if (!onClearForm) return
    onClearForm.current = handleClearFormMission
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        await taskStore.getTaskLevelList()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
    return () => {
      taskStore.clearCreateMissionStore()
    }
  }, [])

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
  } = selectPeopleStore

  const [isVisibleSelectGroupPopup, setIsVisibleSelectGroupPopup] = useState(
    false
  )
  const [
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
  ] = useState(false)

  const [form] = Form.useForm()
  const CONTENT_REF = useRef()
  const NOTE_REF = useRef()

  const {
    taskLevelList,
    selectedIncomingDocument,
    selectedOutgoingDocument,
  } = taskStore
  const {
    handleOpenSelectUserPopupTruc,
    isVisibleSelectUserPopupTruc,
    handleCancelSelectUserTruc,
    selectUserDataTruc,
  } = userTrucStore

  const [fileList, setFileList] = useState({
    fileAttachments: [],
    documentAttachments: [],
  })
  // Incoming document
  const [
    isVisibleModalIncomingDocument,
    setIsVisibleModalIncomingDocument,
  ] = useState(false)
  // Ougoing document
  const [
    isVisibleModalOutgoingDocument,
    setIsVisibleModalOutgoingDocument,
  ] = useState(false)
  const [editorContent, setEditorContent] = useState(null)
  const [editorNote, setEditorNote] = useState(null)
  const [isMessageTruc, setIsMessageTruc] = useState(false)

  const [selectedUserListTruc, setSelectedUserListTruc] = useState([])

  useEffect(() => {
    setSelectedUserListTruc([...toJS(selectUserDataTruc)])
  }, [selectUserDataTruc])

  useEffect(() => {
    if (!queryStringParse.document_code || !queryStringParse.type) return
    if (queryStringParse.type === DOCUMENT_TYPE.INCOMING) {
      taskStore.setSelectedIncomingDocument([
        {
          code: queryStringParse.document_code,
          title: queryStringParse.title || '',
          document_number: queryStringParse.document_number || '',
        },
      ])
    }
    if (queryStringParse.type === DOCUMENT_TYPE.OUTGOING) {
      taskStore.setSelectedOutgoingDocument([
        {
          code: queryStringParse.document_code,
          title: queryStringParse.title || '',
          document_number: queryStringParse.document_number || '',
        },
      ])
    }
  }, [])

  const [leaderList, setLeaderList] = useState([])
  const [handlerList, setHandlerList] = useState([])
  const [combinerList, setCombinerList] = useState([])

  useEffect(() => {
    setLeaderList([
      ...selectUserData[ASSIGNEE_TYPE.LEADER],
      ...selectDepartmentData[ASSIGNEE_TYPE.LEADER],
      ...selectGroupData[ASSIGNEE_TYPE.LEADER],
    ])
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
    setCombinerList([
      ...selectUserData[ASSIGNEE_TYPE.COMBINER],
      ...selectDepartmentData[ASSIGNEE_TYPE.COMBINER],
      ...selectGroupData[ASSIGNEE_TYPE.COMBINER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    return () => {
      userTrucStore.clearStore()
    }
  }, [])

  const handleClearFormMission = (refesh = false) => {
    if (isMissionFull) {
      setTimeout(() => {
        taskStore.clearCreateMissionStore()
        setCombinerList([])
        setHandlerList([])
        setLeaderList([])
        setSelectedUserListTruc([])
        setIsMessageTruc(false) 
        setFileList({})
      }, 300)
    }
    selectPeopleStore.clearSelected()
    form.resetFields()
    handleCancel(refesh)
  }

  const handleUpdateFile = fileList => {
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    return batchUploadArr
  }

  const onFinish = async value => {
    if (isMessageTruc && selectedUserListTruc.length === 0) {
      return message.warning('Vui lòng chọn người gửi tới!')
    }

    const documentList = [
      ...selectedIncomingDocument.map(el => ({
        code_van_ban: el.code,
        type: TASK_DOCUMENT_TYPE.INCOMING,
      })),
      ...selectedOutgoingDocument.map(el => ({
        code_van_ban: el.code,
        type: TASK_DOCUMENT_TYPE.OUTGOING,
      })),
    ]
    const {
      id_cap_giao,
      // loai_nhiem_vu,
      ngay_bat_dau,
      ngay_ket_thuc,
      tag_codes,
    } = value
    const assigneesList = [...leaderList, ...handlerList, ...combinerList].map(
      el => ({
        assignee_code: el.id,
        assignee_type: el.assignee_type,
        permission: el.permission,
      })
    )
    if (leaderList.length === 0) {
      return message.info('Bạn chưa chọn lãnh đạo giao việc.')
    }
    loadingAnimationStore.showSpinner(true)
    try {
      const documentFileResponse = await Promise.all(
        handleUpdateFile(fileList.documentAttachments)
      )
      const fileResponse = await Promise.all(
        handleUpdateFile(fileList.fileAttachments)
      )
      const submitData = {
        ghi_chu: NOTE_REF.current.editor.getData(),
        id_cap_giao: id_cap_giao,
        id_don_vi: '',
        id_file_dinh_kem_vb: documentFileResponse.map(
          response => response.data.file_id
        ),
        id_file_dinh_kem: fileResponse.map(response => response.data.file_id),
        // loai_nhiem_vu: loai_nhiem_vu,
        ngay_bat_dau: ngay_bat_dau ? moment(ngay_bat_dau).toISOString() : null,
        ngay_ket_thuc: ngay_ket_thuc
          ? moment(ngay_ket_thuc).toISOString()
          : null,
        noi_dung: CONTENT_REF.current.editor.getData(),
        van_ban_lq: documentList,
        gui_thong_bao: '001',
        assignees: assigneesList,
        tag_codes: [tag_codes],
      }
      await taskStore.createTask(submitData)
      if (isMessageTruc) {
        const batchUploadArrTruc = []
        uploadFileList.forEach(file => {
          const formData = new FormData()
          formData.append('file', file)
          batchUploadArrTruc.push(fileStore.uploadFileTruc(formData))
        })
        const responseFileTruc = await Promise.all(batchUploadArrTruc)
        const submitValuesMessage = {
          title: 'Bạn được phân nhiệm vụ',
          fileIds: responseFileTruc.map(response => response.data.fileId),
          content: CONTENT_REF.current.editor.getData(),
          receiver: selectedUserListTruc.map(user => user.id),
          type: 'TASK',
        }
        await messagesStore.create(submitValuesMessage)
      }
      if (isMissionFull) {
        handleClearFormMission(true)
      }
      message.success(`Tạo nhiệm vụ thành công`)
      handleCancel()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleChangeFile = info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    return filteredFileList
  }

  const onChangeCheckbox = async e => {
    if (e.target.checked) return setIsMessageTruc(true)
    setIsMessageTruc(false)
    userTrucStore.clearSelectUserDataTruc()
    userTrucStore.clearSelectGroupDataTruc()
  }

  const handleOpenModalIncomingDocumentList = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await internalDocumentStore.getInternalDocumentIncomingSystemList()
      setIsVisibleModalIncomingDocument(true)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleOpenModalOutgoingDocumentList = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await internalDocumentStore.getInternalDocumentOutgoingSystemList()
      setIsVisibleModalOutgoingDocument(true)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleSubmitSelectGroup = () => {
    setIsVisibleSelectGroupPopup(false)
  }
  const handleSubmitSelectUser = () => {
    setIsVisibleSelectUserDepartmentPopup(false)
  }

  const handleChangeTag = value => {
    form.setFieldsValue({
      tag_codes: value,
    })
  }
  return (
    <>
      <MissionCreateModal
        title="Tạo nhiệm vụ mới"
        visible={isModalOpen}
        footer={false}
        closable={false}>
        <Form
          scrollToFirstError={true}
          name={'create-task'}
          layout={'vertical'}
          onFinish={onFinish}
          form={form}>
          <div className="content-wrapper">
            <div className="content-left">
              <div className="editor-content">
                <Form.Item
                  label={<div className="label">Nội dung:</div>}
                  name={'content'}>
                  <RichEditor
                    EDITOR_REF={CONTENT_REF}
                    placeholder={'Nhập nội dung...'}
                    editorContent={editorContent}
                  />
                </Form.Item>
              </div>
              <Form.Item label={'Ghi chú:'} name={'ghi_chu'}>
                <RichEditor
                  EDITOR_REF={NOTE_REF}
                  placeholder={'Nhập ghi chú...'}
                  editorContent={editorNote}
                />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span style={{ marginRight: 20 }}>Văn bản liên quan:</span>
                    <ButtonsWrapper>
                      <Button
                        size={'small'}
                        onClick={handleOpenModalIncomingDocumentList}
                        icon={<CopyOutlined style={{ color: green }} />}>
                        VB đến
                      </Button>
                      <Button
                        size={'small'}
                        onClick={handleOpenModalOutgoingDocumentList}
                        icon={<CopyOutlined style={{ color: blue }} />}>
                        VB đi
                      </Button>
                    </ButtonsWrapper>
                  </>
                }
                name={'van_ban_lq'}>
                {!selectedIncomingDocument.length &&
                !selectedOutgoingDocument.length ? (
                  <EmptyText>Bạn chưa chọn văn bản nào</EmptyText>
                ) : (
                  <DocumentList>
                    {selectedIncomingDocument.map(el => (
                      <li key={el.code}>
                        <CopyOutlined
                          style={{ color: green, marginRight: 8 }}
                        />
                        {`${el.document_number} - ${el.title}`}
                      </li>
                    ))}
                    {selectedOutgoingDocument.map(el => (
                      <li key={el.code}>
                        <CopyOutlined style={{ color: blue, marginRight: 8 }} />
                        {`${el.document_number} - ${el.title}`}
                      </li>
                    ))}
                  </DocumentList>
                )}
              </Form.Item>

              <Form.Item
                label={'Tài liệu đính kèm văn bản'}
                name={'id_file_dinh_kem_vb'}>
                <Upload
                  valuePropName={'documentFileList'}
                  fileList={fileList.documentAttachments}
                  multiple={true}
                  onChange={info =>
                    setFileList({
                      ...fileList,
                      documentAttachments: handleChangeFile(info),
                    })
                  }
                  beforeUpload={() => false}
                  iconRender={() => <FileTextOutlined />}>
                  <Button htmlType={'button'}>
                    <UploadOutlined />
                    Chọn tài liệu đính kèm văn bản
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item label={'Tài liệu đính kèm'} name={'id_file_dinh_kem'}>
                <Upload
                  valuePropName={'fileList'}
                  fileList={fileList.fileAttachments}
                  multiple={true}
                  onChange={info =>
                    setFileList({
                      ...fileList,
                      fileAttachments: handleChangeFile(info),
                    })
                  }
                  beforeUpload={() => false}
                  iconRender={() => <FileTextOutlined />}>
                  <Button htmlType={'button'}>
                    <UploadOutlined />
                    Chọn tài liệu đính kèm
                  </Button>
                </Upload>
              </Form.Item>

              {/* <div style={{ marginTop: 20 }}>
                <span style={{ marginRight: 10 }}>
                  Gửi tin nhắn liên thông VIMC:
                </span>
                <Checkbox checked={isMessageTruc} onChange={onChangeCheckbox} />
              </div>
              {isMessageTruc && (
                <div
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
                    padding: '10px',
                    marginTop: 20,
                  }}>
                  <Title level={5} style={{ margin: '10px 0 15px 0' }}>
                    Thông tin gửi tin nhắn liên thông VIMC
                  </Title>
                  <div style={{ marginBottom: 8 }}>
                    <span>
                      <span
                        style={{
                          color: '#ff4d4f',
                          fontFamily: 'SimSun, sans-serif',
                          marginRight: '-4px',
                        }}>
                        *{' '}
                      </span>
                      <span style={{ marginRight: '10px' }}>Gửi tới:</span>
                    </span>
                    <Space>
                      <Tooltip title="Chọn người dùng" color={blue}>
                        <Button
                          icon={<UserOutlined style={{ color: blue }} />}
                          onClick={() => handleOpenSelectUserPopupTruc(false)}
                        />
                      </Tooltip>
                    </Space>
                  </div>
                  <SelectList>
                    {selectedUserListTruc.map(el => (
                      <Tag
                        className={'tag-selected'}
                        color={'blue'}
                        key={el.id}
                        closable
                        onClose={() =>
                          userTrucStore.handleRemoveSelectTruc(el)
                        }>
                        <AvatarHasName
                          imgId={el.image_id}
                          size={22}
                          name={el.name}
                          icon={typeof el.id === 'number' && <TeamOutlined />}
                        />
                      </Tag>
                    ))}
                  </SelectList>
                </div>
              )} */}
            </div>
            <div className="content-right">
              <Col>
                <SelectTitle>
                  <span>
                    <span
                      style={{
                        color: '#ff4d4f',
                        fontFamily: 'SimSun, sans-serif',
                        marginRight: '-4px',
                      }}>
                      *{' '}
                    </span>
                    <span style={{ marginRight: '10px' }}>
                      Lãnh đạo giao việc:
                    </span>
                  </span>
                  <Space>
                    {/*<Tooltip title="Chọn người dùng" color={blue}>*/}
                    {/*  <Button*/}
                    {/*    icon={<UserOutlined style={{ color: blue }} />}*/}
                    {/*    onClick={() =>*/}
                    {/*    {*/}
                    {/*      setIsVisibleSelectUserDepartmentPopup(true)*/}
                    {/*      handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.LEADER)*/}
                    {/*    }*/}
                    {/*    }*/}
                    {/*  />*/}
                    {/*</Tooltip>*/}
                    <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                      <Button
                        icon={<TeamOutlined />}
                        style={{ color: yellowPrimary }}
                        onClick={() => {
                          setIsVisibleSelectGroupPopup(true)
                          handleOpenSelectGroupPopup(ASSIGNEE_TYPE.LEADER)
                        }}
                      />
                    </Tooltip>
                  </Space>
                </SelectTitle>
                <SelectList>
                  {leaderList.map(el => (
                    <Tag
                      className={'tag-selected'}
                      color={'blue'}
                      key={el.id}
                      closable
                      onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                      <AvatarHasName
                        imgId={el.image_id}
                        size={22}
                        name={el.full_name}
                        icon={
                          (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                            <BankOutlined />
                          )) ||
                          (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                            <TeamOutlined />
                          ))
                        }
                      />
                    </Tag>
                  ))}
                </SelectList>
              </Col>

              <Col>
                <SelectTitle>
                  <span style={{ marginRight: '10px' }}>Xử lý chính:</span>
                  <Space>
                    {/*<Tooltip title="Chọn người dùng" color={blue}>*/}
                    {/*  <Button*/}
                    {/*    icon={<UserOutlined style={{ color: blue }} />}*/}
                    {/*    onClick={() => {*/}
                    {/*      setIsVisibleSelectUserDepartmentPopup(true)*/}
                    {/*      handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*</Tooltip>*/}
                    <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                      <Button
                        icon={<TeamOutlined />}
                        style={{ color: yellowPrimary }}
                        onClick={() => {
                          setIsVisibleSelectGroupPopup(true)
                          handleOpenSelectGroupPopup(ASSIGNEE_TYPE.HANDLER)
                        }}
                      />
                    </Tooltip>
                  </Space>
                </SelectTitle>
                <SelectList>
                  {handlerList.map(el => (
                    <Tag
                      className={'tag-selected'}
                      color={'blue'}
                      key={el.id}
                      closable
                      onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                      <AvatarHasName
                        imgId={el.image_id}
                        size={22}
                        name={el.full_name}
                        icon={
                          (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                            <BankOutlined />
                          )) ||
                          (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                            <TeamOutlined />
                          ))
                        }
                      />
                    </Tag>
                  ))}
                </SelectList>
              </Col>
              <Col>
                <SelectTitle>
                  <span style={{ marginRight: '10px' }}>Người phối hợp:</span>
                  <Space>
                    {/*<Tooltip title="Chọn người dùng" color={blue}>*/}
                    {/*  <Button*/}
                    {/*    icon={<UserOutlined style={{ color: blue }} />}*/}
                    {/*    onClick={() => {*/}
                    {/*      setIsVisibleSelectUserDepartmentPopup(true)*/}
                    {/*      handleOpenSelectUserDepartmentPopup(*/}
                    {/*        ASSIGNEE_TYPE.COMBINER*/}
                    {/*      )*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*</Tooltip>*/}
                    <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                      <Button
                        icon={<TeamOutlined />}
                        style={{ color: yellowPrimary }}
                        onClick={() => {
                          setIsVisibleSelectGroupPopup(true)
                          handleOpenSelectGroupPopup(ASSIGNEE_TYPE.COMBINER)
                        }}
                      />
                    </Tooltip>
                  </Space>
                </SelectTitle>
                <SelectList>
                  {combinerList.map(el => (
                    <Tag
                      className={'tag-selected'}
                      color={'blue'}
                      key={el.id}
                      closable
                      onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                      <AvatarHasName
                        imgId={el.image_id}
                        size={22}
                        name={el.full_name}
                        icon={
                          (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                            <BankOutlined />
                          )) ||
                          (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                            <TeamOutlined />
                          ))
                        }
                      />
                    </Tag>
                  ))}
                </SelectList>
              </Col>
              <Col>
                <Form.Item
                  label={'Ngày bắt đầu:'}
                  name={'ngay_bat_dau'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ngày bắt đầu!',
                    },
                  ]}>
                  <DatePicker
                    placeholder={'Chọn ngày bắt đầu'}
                    style={{ width: '100%' }}
                    format={DATE_FORMAT_LIST}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={'Ngày kết thúc:'} name={'ngay_ket_thuc'}>
                  <DatePicker
                    placeholder={'Chọn ngày kết thúc'}
                    style={{ width: '100%' }}
                    format={DATE_FORMAT_LIST}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={'Cấp giao nhiệm vụ:'}
                  name={'id_cap_giao'}
                  rules={[
                    { required: true, message: 'Vui lòng cấp giao nhiệm vụ!' },
                  ]}>
                  <Select
                    notFoundContent={<EmptyContent />}
                    placeholder={'-- Chọn Cấp giao nhiệm vụ --'}>
                    {taskLevelList?.map(taskLevel => (
                      <Option
                        key={taskLevel.id_level}
                        value={taskLevel.id_level}>
                        {taskLevel.ten_cap}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={'Loại nhiệm vụ:'}
                  name="tag_codes"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn loại nhiệm vụ',
                      // type: 'array',
                    },
                  ]}>
                  {/* <TaskTagPicker /> */}
                  <Select
                    defaultValue={props.defaultValue}
                    showSearch
                    allowClear
                    showArrow
                    notFoundContent={<EmptyContent />}
                    style={{ width: '100%' }}
                    placeholder="--Chọn loại nhiệm vụ--"
                    value={props.value}>
                    {tagList.map(tag => (
                      <Option name={tag.name} value={tag.code} key={tag.code}>
                        {tag.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </div>
          </div>
          <div className="footer">
            <FormActionFooter>
              <Button
                icon={<CloseOutlined />}
                danger
                onClick={handleClearFormMission}>
                Huỷ bỏ
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                icon={<CheckOutlined />}
                type={'primary'}
                htmlType={'submit'}>
                Thêm nhiệm vụ
              </Button>
            </FormActionFooter>
          </div>
        </Form>
        <ModalIncomingDocumentList
          handleCloseModal={() => setIsVisibleModalIncomingDocument(false)}
          onClearDocument={() => {
            setIsVisibleModalIncomingDocument(false)
            taskStore.setSelectedIncomingDocument([])
          }}
          onSubmitModal={() => setIsVisibleModalIncomingDocument(false)}
          isVisibleModalIncomingDocument={isVisibleModalIncomingDocument}
        />
        <ModalOutgoingDocumentList
          handleCloseModal={() => setIsVisibleModalOutgoingDocument(false)}
          onClearDocument={() => {
            setIsVisibleModalOutgoingDocument(false)
            taskStore.setSelectedOutgoingDocument([])
          }}
          onSubmitModal={() => setIsVisibleModalOutgoingDocument(false)}
          isVisibleModalOutgoingDocument={isVisibleModalOutgoingDocument}
        />
        <SelectPeoplePopup
          noLoadSelectData={noLoadSelectData}
          handleCancelSelectUser={() => {
            setIsVisibleSelectUserDepartmentPopup(false)
            // handleCancelSelectUserDepartment()
          }}
          handleSubmitSelectUser={handleSubmitSelectUser}
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />
        <SelectGroupPopup
          noLoadSelectData={noLoadSelectData}
          groupType={'nhiem_vu'}
          isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
          handleCancelSelectGroup={() => {
            setIsVisibleSelectGroupPopup(false)
            // handleCancelSelectGroup()
          }}
          handleSubmitSelectGroup={handleSubmitSelectGroup}
        />
        {isMessageTruc && (
          <SelectPeoplePopupTruc
            isVisibleSelectPeoplePopupTruc={isVisibleSelectUserPopupTruc}
            handleCancelSelectUserTruc={handleCancelSelectUserTruc}
            handleSubmitSelectUserTruc={() =>
              userTrucStore.setIsVisibleSelectUserPopupTruc(false)
            }
          />
        )}
      </MissionCreateModal>
    </>
  )
}

MissionCreatePopup.propTypes = {}

export default inject(
  'departmentStore',
  'authenticationStore',
  'loadingAnimationStore',
  'taskStore',
  'commonStore',
  'fileStore',
  'selectUserStore',
  'internalDocumentStore',
  'selectPeopleStore',
  'userTrucStore',
  'messagesStore'
)(observer(MissionCreatePopup))

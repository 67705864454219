import React, { useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import validator from '../../validator'
import kpiStore from '../../stores/kpiStore'
import { KPI_TYPE } from '../../constants'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'

const TargetCreateModal = props => {
  const { isVisibleTargetCreateModal, handleCloseTargetCreateModal } = props

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  const onFinish = async values => {
    setIsLoading(true)

    const { target_name } = values

    await kpiStore.createKpi(
      target_name,
      KPI_TYPE.TARGET,
      kpiStore.kpiDetail.code,
      null
    )

    kpiStore.getKpiDetail(kpiStore.kpiDetail.code)

    closeAndResetForm()

    setIsLoading(false)

    message.success('Tạo mục tiêu thành công')
  }

  const closeAndResetForm = () => {
    form.resetFields()
    handleCloseTargetCreateModal()
  }

  return (
    <Modal
      title="Thêm mới mục tiêu"
      footer={null}
      closable={true}
      visible={isVisibleTargetCreateModal}
      onCancel={handleCloseTargetCreateModal}>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'target_name'}
          rules={[
            { required: true, message: 'Vui lòng nhập tên mục tiêu!' },
            { validator: validator.validateInputString },
          ]}
          label={'Tên mục tiêu:'}>
          <Input placeholder={'Nhập tên mục tiêu'} />
        </Form.Item>

        <Form.Item>
          <Button
            icon={<PlusOutlined />}
            loading={isLoading}
            htmlType={'submit'}
            type={'primary'}>
            Tạo
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

TargetCreateModal.propTypes = {}

export default TargetCreateModal

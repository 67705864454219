import { action, observable } from 'mobx'
import chuTruongCommentStore from './chuTruongCommentStore'
import { ChuTruongRequest } from '../requests/ChuTruongRequest'

class ChuTruongStore {
  /** Policy list */
  @observable policyList = []
  @observable policyListPageIndex = 0
  @observable policyListPageSize = 10
  @observable policyListTotalCount = 0
  @observable policyListTotalPage = 0
  @observable policyListFilterByNumOfStep = undefined
  @observable policyListFilterByStatus = undefined
  @observable policyListFilterByTitle = undefined
  @observable policyListFilterByPermission = undefined

  @action getPolicyList = () => {
    return new Promise((resolve, reject) => {
      ChuTruongRequest.getPolicyList(
        this.policyListPageIndex,
        this.policyListPageSize,
        this.policyListFilterByStatus,
        this.policyListFilterByPermission,
        this.policyListFilterByTitle,
        this.policyListFilterByNumOfStep
      )
        .then(response => {
          if (this.policyListPageIndex === 0) {
            this.policyList = response.data.data
          } else {
            this.policyList = [...this.policyList, ...response.data.data]
          }

          this.policyListTotalCount = response.data.total_count
          this.policyListTotalPage = response.data.total_page
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action setPolicyListPageIndex = pageIndex => {
    this.policyListPageIndex = pageIndex
  }

  /** Filter policy */
  @action setPolicyListFilterByNumOfStep = numOfStep => {
    this.policyListPageIndex = 0
    this.policyList.length = 0
    this.policyListFilterByNumOfStep = numOfStep
  }
  @action setPolicyListFilterByTitle = title => {
    this.policyListPageIndex = 0
    this.policyList.length = 0
    this.policyListFilterByTitle = title
  }
  @action setPolicyListFilterByPermission = permission => {
    this.policyListPageIndex = 0
    this.policyList.length = 0
    this.policyListFilterByPermission = permission
  }
  @action setPolicyListFilterByStatus = statusCode => {
    this.policyListPageIndex = 0
    this.policyList.length = 0
    this.policyListFilterByStatus = statusCode
  }

  /** Fix bug same code khi search không lấy được detail */
  @observable sameCodeDetail = 0

  @action setSameCodeDetail = () => {
    this.sameCodeDetail = this.sameCodeDetail + 1
  }

  /** Selected policy */
  @observable selectedPolicy = {}
  @action getPolicyDetail = policyCode => {
    return new Promise((resolve, reject) => {
      ChuTruongRequest.getPolicyDetail(policyCode)
        .then(response => {
          this.selectedPolicy = response.data
          this.policyList.forEach(item => {
            if (item.code === policyCode) {
              item.read = true
            }
          })
          chuTruongCommentStore
            .changeCommentStep(response.data.currentStep)
            .then()
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Create policy */
  @action createPolicy = data => {
    return new Promise((resolve, reject) => {
      ChuTruongRequest.createPolicy(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Delete policy */
  @action deletePolicy = policyCode => {
    return new Promise((resolve, reject) => {
      ChuTruongRequest.deletePolicy(policyCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Update policy list */
  @action updatePolicyList = newPolicyList => {
    this.policyList = newPolicyList
  }

  /** Change step */
  @action changeStep = (policyCode, userName) => {
    return new Promise((resolve, reject) => {
      ChuTruongRequest.changeStep(policyCode, userName)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action updatePolicyAssignees = (policyCode, data) => {
    return new Promise((resolve, reject) => {
      ChuTruongRequest.updatePolicyAssignees(policyCode, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear */

  @action clearPolicyList = () => {
    this.policyList.length = 0
  }

  @action clearSelectedPolicy = () => {
    this.selectedPolicy = {}
  }

  @action clearAllPolicyListFilter = () => {
    this.policyList.length = 0
    this.policyListPageIndex = 0
    this.policyListPageSize = 10
    this.policyListTotalCount = 0
    this.policyListTotalPage = 0
    this.policyListFilterByNumOfStep = undefined
    this.policyListFilterByStatus = undefined
    this.policyListFilterByTitle = undefined
    this.policyListFilterByPermission = undefined
  }

  @action clearAllPolicyListFilterAdvance = () => {
    this.policyList.length = 0
    this.policyListPageIndex = 0
    this.policyListPageSize = 10
    this.policyListTotalCount = 0
    this.policyListTotalPage = 0
    this.policyListFilterByNumOfStep = undefined
    this.policyListFilterByPermission = undefined
    this.policyListFilterByStatus = undefined
  }
}

export default new ChuTruongStore()

import { ISODocumentRequest } from '../../../requests/ISODocumentRequest'
import isoDocumentStore from '../isoDocumentStore'
import { observable } from 'mobx'
import { CLOUD_DOCUMENT_TYPE } from '../../../constants'

class MoveDocumentStore {
  @observable isLoading = false
  @observable parentFolderId = null
  @observable folders = []
  @observable breadCrumb = []

  getListFolder = async currentDocumentId => {
    if (this.parentFolderId) {
      this.isLoading = true

      const response = await ISODocumentRequest.getFolderDetails(
        this.parentFolderId,
        CLOUD_DOCUMENT_TYPE.FOLDER
      )
      this.folders = response.data.data.filter(
        item => item.id !== currentDocumentId
      )
      this.breadCrumb = response.data.breadCrumb

      this.isLoading = false
    }
  }

  move = async (documentId, folderDestinationId) => {
    this.isLoading = true

    await ISODocumentRequest.move(documentId, folderDestinationId)

    this.isLoading = false

    isoDocumentStore.getItems()
  }

  clearStore = () => {
    this.isLoading = false
    this.folders = []
    this.breadCrumbs = []
    this.parentFolderId = null
  }
}

export default new MoveDocumentStore()

import React, { useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import validator from '../../validator'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'
import kpiQuarterPageStore from './KpiQuarterPageStore'

const KpiQuarterCreateModal = props => {
  const { isVisible, handleCloseModal } = props

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  const onFinish = async values => {
    setIsLoading(true)

    const { name } = values

    await kpiQuarterPageStore.create(name)

    closeAndResetForm()

    setIsLoading(false)

    message.success('Tạo KPI Quý thành công')
  }

  const closeAndResetForm = () => {
    form.resetFields()
    handleCloseModal()
  }

  return (
    <Modal
      title="Thêm mới KPI Quý"
      footer={null}
      closable={true}
      visible={isVisible}
      onCancel={handleCloseModal}>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'name'}
          rules={[
            { required: true, message: 'Vui lòng nhập tên KPI Quý!' },
            { validator: validator.validateInputString },
          ]}
          label={'Tên KPI Quý:'}>
          <Input placeholder={'Nhập tên KPI Quý'} />
        </Form.Item>

        <Form.Item>
          <Button
            icon={<PlusOutlined />}
            loading={isLoading}
            htmlType={'submit'}
            type={'primary'}>
            Tạo
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

KpiQuarterCreateModal.propTypes = {}

export default KpiQuarterCreateModal

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Form, Input, message, Modal, Upload } from 'antd'
import RichEditor from '../../../components/RichEditor/RichEditor'
import { FileTextOutlined, UploadOutlined } from '@ant-design/icons'
import ProposalAdvanceStepApprove from './ProposalAdvanceStepApprove'
import { inject, observer } from 'mobx-react'
import SelectFollower from './SelectFollower'
import { ASSIGNEE_TYPE, PROPOSAL_STATUS } from '../../../constants'

const { TextArea } = Input

const currentStep = 0
const ProposalAdvanceCreateModal = props => {
  const {
    isModalVisible,
    handleCloseModal,
    proposalAdvanceStore,
    selectPeopleStore,
    proposalStore,
    fileStore,
    authenticationStore,
  } = props

  const {
    defaultApprovalConfigUpdate,
    defaultApprovalConfig,
  } = proposalAdvanceStore
  const { selectUserData, selectDepartmentData } = selectPeopleStore
  const { currentUser } = authenticationStore

  const EDITOR_REF = useRef()
  const [form] = Form.useForm()

  const [isCreating, setIsCreating] = useState(false)
  const [editorContent, setEditorContent] = useState(null)
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    ;(async () => {
      try {
        await proposalAdvanceStore.getDefaultApprovalConfig()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
      }
    })()
  }, [])

  const onFinish = async value => {
    const userNextStep = defaultApprovalConfigUpdate.approvalLevels.find(
      item => item.level === currentStep + 1
    )
    if (!userNextStep?.username) {
      return message.warning(`Hãy chọn ${userNextStep.name}!`)
    }
    const batchUploadArr = []
    fileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file.originFileObj)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    try {
      setIsCreating(true)
      const response = await Promise.all(batchUploadArr)
      const payload = {
        title: value.reason,
        content: EDITOR_REF.current.editor.getData(),
        followers: [
          ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
          ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
        ].map(item => item.id),
        attachments: response.map(response => response.data.file_id),
        approval_levels: defaultApprovalConfigUpdate.approvalLevels.map(
          item => ({
            ...item,
            status:
              item.level === currentStep + 1
                ? PROPOSAL_STATUS.PENDING
                : item.status,
            user: undefined,
          })
        ),
      }
      await proposalAdvanceStore.createProposalAdvance(payload)
      await proposalAdvanceStore.getProposalAdvanceList()
      handleCancel()
      message.success('Tạo trình tạm ứng thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsCreating(false)
    }
  }

  const handleChangeFile = useCallback(
    info => {
      const filteredFileList = info.fileList.filter(
        (elem, index, fileArray) =>
          fileArray.findIndex(file => file.name === elem.name) === index
      )
      setFileList(filteredFileList)
    },
    [fileList]
  )

  const handleBeforeUpload = () => false

  const handleCancel = () => {
    handleCloseModal()
    setTimeout(() => {
      selectPeopleStore.clearSelected()
      setFileList([])
      form.resetFields()
      setEditorContent(null)
    }, 100)
  }

  const [approvalLevels, setApprovalLevels] = useState([])

  useEffect(() => {
    const approvalLevelList = [...defaultApprovalConfig.approvalLevels]
    if (approvalLevelList.length === 0) return
    approvalLevelList[0].username = currentUser?.username
    setApprovalLevels(approvalLevelList)
  }, [defaultApprovalConfig, currentUser])

  return (
    <Modal
      width={1000}
      style={{ top: 40 }}
      title={'Tạo trình tạm ứng'}
      visible={isModalVisible}
      maskClosable={false}
      onCancel={handleCancel}
      footer={null}
      forceRender>
      <Form
        scrollToFirstError={true}
        form={form}
        name={'phieu-trinh-khac-form'}
        layout={'vertical'}
        onFinish={onFinish}>
        <Form.Item
          name={'reason'}
          label={'Tiêu đề:'}
          rules={[
            { required: true, message: 'Vui lòng nhập tiêu đề' },
            { max: 500, message: 'Tiêu đề không được dài quá 500 kí tự!' },
          ]}>
          <TextArea autoSize={{ minRows: 1 }} placeholder={'Diễn giải...'} />
        </Form.Item>
        <Form.Item name={'file_ids'} label={'Tài liệu đính kèm:'}>
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            onChange={handleChangeFile}
            beforeUpload={handleBeforeUpload}
            multiple={true}
            iconRender={() => <FileTextOutlined />}>
            <Button icon={<UploadOutlined />}>Chọn tài liệu đính kèm</Button>
          </Upload>
        </Form.Item>
        <Form.Item label={'Nội dung:'}>
          <RichEditor
            EDITOR_REF={EDITOR_REF}
            placeholder={'Nhập nội dung'}
            editorContent={editorContent}
          />
        </Form.Item>
        <SelectFollower />
        <div style={{ marginBottom: 15, marginTop: 10 }}>Chọn người duyệt:</div>
        <ProposalAdvanceStepApprove approvalLevelList={approvalLevels} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
          <Button onClick={handleCancel} danger>
            Đóng
          </Button>
          <Button type={'primary'} htmlType={'submit'} loading={isCreating}>
            Tạo trình tạm ứng
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

ProposalAdvanceCreateModal.propTypes = {}

export default inject(
  'proposalAdvanceStore',
  'selectPeopleStore',
  'proposalStore',
  'fileStore',
  'authenticationStore',
)(observer(ProposalAdvanceCreateModal))

import React, { useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import KpiQuarterList from './KpiQuarterList'
import { inject, observer } from 'mobx-react'
import KpiQuarterCreateModal from './KpiQuarterCreateModal'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'

const KpiQuarterPage = props => {
  const [isVisibleCreateModal, setVisibleCreateModal] = useState(false)

  const handleClickCreate = () => {
    setVisibleCreateModal(true)
  }

  const onClose = () => {
    setVisibleCreateModal(false)
  }

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>KPI</title>
        </Helmet>
        <PageTitle location={props.location}>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__KPI}>
            <Button
              onClick={handleClickCreate}
              type={'primary'}
              icon={<PlusOutlined />}>
              Tạo mới
            </Button>
          </AccessControlAction>
        </PageTitle>
        <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
          <KpiQuarterList />
        </ContentBlockWrapper>
      </DashboardLayout>
      <KpiQuarterCreateModal
        isVisible={isVisibleCreateModal}
        handleCloseModal={onClose}
      />
    </>
  )
}

KpiQuarterPage.propTypes = {}

export default inject('kpiQuarterPageStore')(observer(KpiQuarterPage))

import React from 'react'
import PropTypes from 'prop-types'
import { SalaryListDepartmentWrapper } from './SalaryPageStyled'
import SalaryListDepartmentList from './SalaryListDepartmentList'

const SalaryListDepartment = props => {
  const { salaryItem } = props

  return (
    <SalaryListDepartmentWrapper>
      <div className={'title-table'}>{salaryItem.department_name}</div>
      <SalaryListDepartmentList
        salaryInfo={salaryItem.employee_salary_list || []}
        salaryItem={salaryItem}
      />
    </SalaryListDepartmentWrapper>
  )
}

SalaryListDepartment.propTypes = {
  salaryItem: PropTypes.object.isRequired,
}

export default SalaryListDepartment

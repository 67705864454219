import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ContentTemplateRequest = {
  getDetails: key =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/content_template/${key}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  update: (key, content) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/content_template/${key}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        content: content,
      },
    }),
}

import React, { useEffect, useState } from 'react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import { useHistory, useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { message } from 'antd'
import styled from 'styled-components'
import ProposalAdvanceInfo from './ProposalAdvanceInfo'
import ProposalAdvanceComment from './ProposalAdvanceComment'
import ProposalAdvanceAction from './ProposalAdvanceAction'
import { StatusTag } from '../../components/Common/StatusTag'
import { TYPE_STATUS } from '../../constants'

const ProposalDetailWrapper = styled.div`
  .proposal-row {
    display: ${props => (props.isHidden ? 'none' : 'flex')};
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    .proposal-info {
      flex-grow: 1;
      align-self: stretch;
    }
    .proposal-comment {
      flex: 0 0 400px;
      width: 400px;
    }
    @media screen and (max-width: 1299px) {
      display: ${props => (props.isHidden ? 'none' : 'block')};
      .proposal-info {
        margin-bottom: 20px;
      }
      .proposal-comment {
        width: 100%;
      }
    }
  }
`

const ProposalAdvanceDetailPage = props => {
  const { id: proposalAdvanceCode } = useParams()
  const { proposalAdvanceStore, selectPeopleStore } = props
  const { proposalAdvanceDetail } = proposalAdvanceStore
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!proposalAdvanceCode) return
    ;(async () => {
      try {
        setIsLoading(true)
        await proposalAdvanceStore.getProposalAdvanceDetail(proposalAdvanceCode)
      } catch (err) {
        console.log(err)
        history.push('/proposal-advance')
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoading(false)
      }
    })()
    return () => {
      proposalAdvanceStore.clearProposalAdvanceDetail()
      selectPeopleStore.clearSelected()
    }
  }, [proposalAdvanceCode])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạm ứng | VIMC Tạm ứng</title>
      </Helmet>
      <PageTitle
        title={'Chi tiết trình tạm ứng'}
        showTitle={true}
        location={props.location}
        status={StatusTag(
          TYPE_STATUS.PROPOSAL,
          proposalAdvanceDetail.proposal_status
        )}
      />
      {isLoading && (
        <ContentBlockWrapper
          style={{ position: 'relative', minHeight: 'calc(100vh - 200px)' }}>
          <SpinnerInlineComponent
            alignItems
            isLoading={isLoading}
            size={'small'}
            noBackground
            tip={'Đang tải chi tiết trình tạm ứng...'}
          />
        </ContentBlockWrapper>
      )}
      <ProposalDetailWrapper isHidden={isLoading}>
        <div className={'proposal-row'}>
          <ProposalAdvanceInfo className={'proposal-info'} />
          <ContentBlockWrapper
            style={{ position: 'relative' }}
            className={'proposal-comment'}>
            <ProposalAdvanceComment />
            <SpinnerInlineComponent sizeSpin={'small'} />
          </ContentBlockWrapper>
        </div>
        {!isLoading && <ProposalAdvanceAction />}
      </ProposalDetailWrapper>
    </DashboardLayout>
  )
}

ProposalAdvanceDetailPage.propTypes = {}
export default inject(
  'proposalAdvanceStore',
  'selectPeopleStore',
)(observer(ProposalAdvanceDetailPage))

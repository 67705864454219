import { action, observable, toJS } from 'mobx'
import { UserGroupRequest } from '../requests/UserGroupRequest'

class UserGroupStore {
  @observable dataGroupStoreList = []
  @observable userGroupListPageIndex = 0
  @observable userGroupListPageSize = 100
  @observable userGroupListFilterByName = undefined

  // Đây là group đang được hiển thị trên UI
  @observable groupActive = undefined

  @action setGroupActive = group => {
    this.groupActive = group
  }

  @action setDataGroupStoreList = dataGroupList => {
    this.dataGroupStoreList = dataGroupList
  }

  // Có được danh sách group, nhưng user_list chưa có dữ liêu
  @action getGroupList = (group_type, companyCode) => {
    return new Promise((resolve, reject) => {
      UserGroupRequest.getGroupList(
        this.userGroupListPageIndex,
        this.userGroupListPageSize,
        this.userGroupListFilterByName,
        group_type,
        companyCode
      )
        .then(response => {
          const groupListCustom = response.data.data.map(group => {
            return {
              name_group: group.name,
              id_group: group.id,
              user_list: [],
            }
          })
          this.dataGroupStoreList = groupListCustom
          resolve(groupListCustom)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // Lấy dữ liệu user_list của từng group và thay đổi dataGroupStoreList (thêm data user_list)
  @action getMemberGroupByIdGroup = id_group => {
    return new Promise((resolve, reject) => {
      UserGroupRequest.getMemberGroupByIdGroup(id_group, 0, 100)
        .then(response => {
          const dataUserList = response.data.data.map(user => user.user)
          const groupSelected = this.dataGroupStoreList.find(
            group => group.id_group === id_group
          )
          const indexGroupSelected = this.dataGroupStoreList.findIndex(
            group => group.id_group === id_group
          )
          const newDataGroup = this.dataGroupStoreList
          newDataGroup.splice(indexGroupSelected, 1, {
            ...groupSelected,
            user_list: dataUserList,
          })
          this.dataGroupStoreList = toJS(newDataGroup)
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createGroup = data => {
    return new Promise((resolve, reject) => {
      UserGroupRequest.createGroup(data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @action deleteGroup = id_group => {
    return new Promise((resolve, reject) => {
      UserGroupRequest.deleteGroup(id_group)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateGroup = (id_group, data) => {
    return new Promise((resolve, reject) => {
      UserGroupRequest.updateGroup(id_group, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteMemberGroup = (id_group, data) => {
    return new Promise((resolve, reject) => {
      UserGroupRequest.deleteMemberGroup(id_group, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearGroupList = () => {
    this.dataGroupStoreList.length = 0
    this.userGroupListPageIndex = 0
    this.userGroupListPageSize = 100
    this.userGroupListFilterByName = undefined
  }
}

export default new UserGroupStore()

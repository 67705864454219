import React, { memo } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import DashboardLayout from '../../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import { Menu } from 'antd'

const PhieuTrinhLayout = props => {
  const { children, title, selectedKey } = props

  return (
    <DashboardLayout>
      {title}
      <ContentBlockWrapper>
        <Menu mode="horizontal" selectedKeys={[selectedKey]}>
          <Menu.Item key="xe-co">
            <a href="/administrative/phieu-trinh/xe-co">Xe cộ</a>
          </Menu.Item>
          <Menu.Item key="cong-tac-phi">
            <a href="/administrative/phieu-trinh/cong-tac-phi">Công tác phí</a>
          </Menu.Item>
          <Menu.Item key="tiep-khach">
            <a href="/administrative/phieu-trinh/tiep-khach">Tiếp khách</a>
          </Menu.Item>
          <Menu.Item key="tam-ung">
            <a href="/administrative/phieu-trinh/tam-ung">Tạm ứng</a>
          </Menu.Item>
          <Menu.Item key="quyet-toan">
            <a href="/administrative/phieu-trinh/quyet-toan">Quyết toán</a>
          </Menu.Item>
        </Menu>
        {children}
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

PhieuTrinhLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  selectedKey: PropTypes.string,
}

export default memo(inject()(observer(PhieuTrinhLayout)))

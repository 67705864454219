import { action, observable } from 'mobx'
import { SalaryRequest } from '../requests/SalaryRequest'

class SalaryStore {
  // Page list salary

  @observable salaryListPage = []

  @observable salaryListPageTotal = 0

  @observable salaryQueryParam = {
    page: 0,
    size: 10,
  }

  @action getSalaryList = () => {
    return new Promise((resolve, reject) => {
      SalaryRequest.getSalaryList(this.salaryQueryParam)
        .then(response => {
          this.salaryListPage = response.data.data || []
          this.salaryListPageTotal = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  setSalaryQueryParam = payload => {
    this.salaryQueryParam = payload
  }

  @action clearSalaryListPage = () => {
    this.salaryListPage.length = 0
    this.salaryQueryParam = {
      page: 0,
      size: 10,
    }
    this.salaryListPageTotal = 0
  }

  // Create + detail salary
  @observable salaryList = []

  @observable employeeSalaryData = {}
  @observable managerSalaryData = {}
  @observable salaryInfo = {}

  @observable isShowSalaryInfo = false

  @action setIsShowSalaryInfo = bool => {
    this.isShowSalaryInfo = bool
  }

  @action importSalaryFile = formData => {
    return new Promise((resolve, reject) => {
      SalaryRequest.importSalaryFile(formData)
        .then(response => {
          console.log('response import', response)
          this.salaryList = response.data.data || []
          this.salaryInfo = response.data
          this.employeeSalaryData = response.data.data_employee
          this.managerSalaryData = response.data.data_manager
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createSalary = payload => {
    return new Promise((resolve, reject) => {
      SalaryRequest.createSalary(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteSalary = payload => {
    return new Promise((resolve, reject) => {
      SalaryRequest.deleteSalary(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getSalaryDetail = salaryCode => {
    return new Promise((resolve, reject) => {
      SalaryRequest.getSalaryDetail(salaryCode)
        .then(response => {
          console.log('response', response)
          this.employeeSalaryData = response.data.file_summary.data_employee
          this.managerSalaryData = response.data.file_summary.data_manager
          this.salaryInfo = {
            ...response.data,
            file_name: response.data.file_summary.file_name,
            file_id: response.data.file_summary.file_id,
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action approveSalary = salaryCode => {
    return new Promise((resolve, reject) => {
      SalaryRequest.approveSalary(salaryCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearSalaryList = () => {
    this.salaryList = []
    this.salaryInfo = {}
    this.isShowSalaryInfo = false
  }

  @action clearSalary = () => {
    this.employeeSalaryData = {}
    this.managerSalaryData = {}
    this.salaryInfo = {}
  }

  // Lương của tôi

  @observable mySalaryList = []
  @observable mySalaryTotal = []

  @observable filterPageMySalary = 0
  @observable filterSizeMySalary = 10

  @action getMySalaryList = () => {
    return new Promise((resolve, reject) => {
      SalaryRequest.getMySalaryList({
        page: this.filterPageMySalary,
        size: this.filterSizeMySalary,
      })
        .then(response => {
          this.mySalaryList = response.data.data || []
          this.mySalaryTotal = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action setQueryParams = (param, value) => {
    this[param] = value
  }
  @action clearMySalaryList = () => {
    this.mySalaryList.length = 0
    this.filterPageMySalary = 0
    this.filterSizeMySalary = 10
  }
}

export default new SalaryStore()

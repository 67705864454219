import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import {
  FilterButton,
  SearchBar,
  TableFooterActionWrapper,
} from './ConnectedCommitteeDocumentIncomingDocPageStyled'
import {
  Button,
  Col,
  Empty,
  Input,
  message,
  Pagination,
  Row,
  Space,
  Tag,
} from 'antd'
import {
  CloseCircleOutlined,
  FilterOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import utils from '../../utils'
import AdvanceFilterBlock from './AdvanceFilterBlock'
import DocumentsLayout from '../../layouts/DocumentsLayout'
import { DATE_FORMAT_DEFAULT, TCY_HANG_HAI_VIET_NAM } from '../../constants'
import UnreadText from '../../components/UnreadText/UnreadText'
import TableComponent from '../../components/Common/TableComponent'

const { Search } = Input

const ConnectedCommitteeDocumentIncomingDocPage = props => {
  const {
    history,
    connectedCommitteeDocumentStore,
    loadingAnimationStore,
    authenticationStore,
  } = props

  const {
    incomingCommitteeDocumentList,
    incomingCommitteeDocumentListTotalCount,
    incomingCommitteeDocumentListPageIndex,
    incomingCommitteeDocumentListPageSize,
    setFilter,
    incomingCommitteeDocumentListFilterByKeyword,
  } = connectedCommitteeDocumentStore

  const { currentUser } = authenticationStore
  const {
    isSuperAdmin,
    isAccountAdmin,
    isEOfficeSecretary,
  } = authenticationStore

  const isVinalines =
    currentUser?.company?.code &&
    currentUser.company.code === TCY_HANG_HAI_VIET_NAM

  const renderBussinessDocTypeName = typeId => {
    switch (typeId) {
      case '0':
        return 'Tạo văn bản mới'
      case '1':
        return 'Thu hồi văn bản đã gửi'
      case '2':
        return 'Cập nhật văn bản đã gửi'
      case '3':
        return 'Thay thế văn bản đã gửi'
      default:
        return ''
    }
  }

  const NGAY_NHAN_WIDTH = 100
  const SO_HIEU_WIDTH = 120
  const TRICH_YEU_WIDTH = 220
  const TRANG_THAI_WIDTH = 100
  const CO_QUAN_BAN_HANH_WIDTH = 200
  const LOAI_NGHIEP_VU_WIDTH = 150
  const SCROLL_WIDTH = 1000

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)
  const [disabledSearchBtn, setDisabledSearchBtn] = useState(true)
  const [reload, setReload] = useState(false)
  let [searchOptionTitle, setSearchOptionTitle] = useState('')
  let [searchOption, setSearchOption] = useState({
    bussiness_doc_type: undefined,
    status: undefined,
    title: undefined,
  })

  const [exportLoading, setExportLoading] = useState(false)

  const tableColumns = [
    {
      title: 'Ngày nhận',
      width: NGAY_NHAN_WIDTH,
      render: record => (
        <UnreadText
          text={moment(record.sent_time).format(DATE_FORMAT_DEFAULT)}
          isUnread={checkUnread(record)}
          width={NGAY_NHAN_WIDTH}
        />
      ),
    },
    {
      title: 'Số hiệu',
      width: SO_HIEU_WIDTH,
      render: record => (
        <UnreadText
          text={record.code}
          isUnread={checkUnread(record)}
          width={SO_HIEU_WIDTH}
        />
      ),
    },
    {
      title: 'Trích yếu',
      width: TRICH_YEU_WIDTH,
      render: record => (
        <UnreadText
          text={record.title}
          isUnread={checkUnread(record)}
          width={TRICH_YEU_WIDTH}
        />
      ),
    },
    {
      title: 'Trạng thái',
      width: TRANG_THAI_WIDTH,
      align: 'center',
      render: record => (
        <Tag color={utils.renderDocumentCommitteeTag(record.status).color}>
          {utils.renderDocumentCommitteeTag(record.status).name}
        </Tag>
      ),
    },
    {
      title: 'Cơ quan ban hành',
      width: CO_QUAN_BAN_HANH_WIDTH,
      render: record => (
        <UnreadText
          text={record.organ_from}
          isUnread={checkUnread(record)}
          width={CO_QUAN_BAN_HANH_WIDTH}
        />
      ),
    },
    {
      title: 'Loại nghiệp vụ',
      width: LOAI_NGHIEP_VU_WIDTH,
      render: record =>
        record.bussiness_doc_type ? (
          <UnreadText
            text={renderBussinessDocTypeName(record.bussiness_doc_type)}
            isUnread={checkUnread(record)}
            width={LOAI_NGHIEP_VU_WIDTH}
          />
        ) : null,
    },
  ]

  const checkUnread = record => false

  const handleCloseAdvanceFilter = () => {
    if (!reload) return
    setReload(false)
    connectedCommitteeDocumentStore.clearIncomingCommitteeDocumentListFilter()
    loadingAnimationStore.setTableLoading(true)
    connectedCommitteeDocumentStore
      .getIncomingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
  }

  const exportDocument = useCallback(() => {
    setExportLoading(true)

    if (!showAdvanceFilter) {
      setFilter(
        'incomingCommitteeDocumentListFilterByKeyword',
        searchOptionTitle ? searchOptionTitle.trim() : undefined
      )
    } else {
      setFilter('incomingCommitteeDocumentListAllPageIndex', 0)
      setFilter(
        'incomingCommitteeDocumentListFilterByBussinessDocType',
        searchOption.bussiness_doc_type
      )
      setFilter(
        'incomingCommitteeDocumentListFilterByStatus',
        searchOption.status
      )
      setFilter(
        'incomingCommitteeDocumentListFilterByKeyword',
        searchOption.title ? searchOption.title.trim() : undefined
      )
    }

    const col = [
      'Ngày nhận',
      'Số hiệu',
      'Trích yếu',
      'Trạng thái',
      'Cơ quan ban hành',
      'Loại nghiệp vụ',
    ]

    let rows = []
    rows.push(col)
    connectedCommitteeDocumentStore
      .getIncomingCommitteeDocumentListAll()
      .then(res => {
        if (res && res.data && res.data.data) {
          res.data.data.map(item => {
            rows.push([
              moment(item.sent_time).format(DATE_FORMAT_DEFAULT),
              item.code,
              item.title,
              utils.renderDocumentCommitteeTag(item.status).name,
              item.organ_from,
              renderBussinessDocTypeName(item.bussiness_doc_type),
            ])
          })
        }
        utils.exportExcel(
          rows,
          'VBLT - VB đến - ' + moment().format('YYYY-MM-DD hmms')
        )
      })
      .finally(() => setExportLoading(false))
  }, [searchOptionTitle, searchOption, showAdvanceFilter])

  const handleSearch = values => {
    const { status, bussiness_doc_type, title } = values

    /** Kiểm tra việc reload lại trang để lấy lại list vb */
    if (title || bussiness_doc_type || status) {
      setReload(true)
    } else {
      setReload(false)
    }

    setFilter('incomingCommitteeDocumentListPageIndex', 0)
    setFilter(
      'incomingCommitteeDocumentListFilterByBussinessDocType',
      bussiness_doc_type
    )
    setFilter('incomingCommitteeDocumentListFilterByStatus', status)
    setFilter(
      'incomingCommitteeDocumentListFilterByKeyword',
      title ? title.trim() : undefined
    )

    loadingAnimationStore.setTableLoading(true)
    connectedCommitteeDocumentStore
      .getIncomingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
    setDisabledSearchBtn(true)
  }

  const onQuickSearch = useCallback(title => {
    setFilter('incomingCommitteeDocumentListPageIndex', 0)
    setFilter(
      'incomingCommitteeDocumentListFilterByKeyword',
      title ? title.trim() : undefined
    )

    loadingAnimationStore.setTableLoading(true)
    connectedCommitteeDocumentStore
      .getIncomingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
    setDisabledSearchBtn(true)
  }, [])
  const onChangePagination = pageIndex => {
    setFilter('incomingCommitteeDocumentListPageIndex', pageIndex - 1)
    loadingAnimationStore.setTableLoading(true)
    connectedCommitteeDocumentStore
      .getIncomingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.setTableLoading(false))
  }

  useEffect(() => {
    return () => {
      connectedCommitteeDocumentStore.clearIncomingCommitteeDocumentList()
      connectedCommitteeDocumentStore.clearIncomingCommitteeDocumentListFilter()
    }
  }, [])
  useEffect(() => {
    if (!currentUser) return
    if (
      !isVinalines &&
      !isSuperAdmin &&
      !isAccountAdmin &&
      !isEOfficeSecretary
    ) {
      history.push('/dashboard')
      message.error(
        'Bạn không có quyền truy cập trang này, vui lòng liên hệ admin'
      )
      return
    }
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore
      .getIncomingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [currentUser])

  return (
    <DocumentsLayout
      title={
        <PageTitle location={props.location} title="Văn bản đến" hiddenGoBack />
      }
      selectedKey="incoming-document"
      linkToIncomingDocument="/connected-committee-document/incoming-document"
      linkToOutgoingDocument="/connected-committee-document/outgoing-document"
      linkToConnectedCommitteeOrg="/connected-committee-document/organization">
      <Helmet>
        <title>Văn bản đến | VIMC Liên thông lên UBQLV</title>
      </Helmet>
      <div>
        <Row>
          <Col span={12}>
            {!showAdvanceFilter ? (
              <SearchBar>
                <Search
                  allowClear
                  onSearch={onQuickSearch}
                  defaultValue={incomingCommitteeDocumentListFilterByKeyword}
                  placeholder={'Tìm kiếm văn bản theo trích yếu'}
                  onChange={e => {
                    setSearchOptionTitle(e.target.value)
                  }}
                  value={searchOptionTitle}
                />
              </SearchBar>
            ) : null}
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <FilterButton
              type={showAdvanceFilter ? 'primary' : 'default'}
              ghost={showAdvanceFilter}
              onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}
              style={{ marginBottom: showAdvanceFilter ? 5 : 0 }}>
              {showAdvanceFilter ? (
                <Space onClick={handleCloseAdvanceFilter}>
                  <CloseCircleOutlined />
                  Tắt tìm kiếm nâng cao
                </Space>
              ) : (
                <Space>
                  <FilterOutlined />
                  Tìm kiếm nâng cao
                </Space>
              )}
            </FilterButton>
            <Button
              loading={exportLoading}
              onClick={exportDocument}
              style={{ marginLeft: '10px' }}>
              <PrinterOutlined />
              In
            </Button>
          </Col>
        </Row>
        {showAdvanceFilter ? (
          <>
            <AdvanceFilterBlock
              onChangeSearchForm={value => {
                setSearchOption(Object.assign(searchOption, value))
                setDisabledSearchBtn(false)
              }}
              handleSearch={handleSearch}
              titleSearch={incomingCommitteeDocumentListFilterByKeyword}
              disabledSearchBtn={disabledSearchBtn}
            />
          </>
        ) : null}
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(
                  `/connected-committee-document/incoming-document/view/${record.doc_id}`
                ),
            }
          }}
          rowKey={record => record.doc_id}
          dataSource={incomingCommitteeDocumentList}
          columns={tableColumns}
          pagination={false}
          scroll={{ x: SCROLL_WIDTH }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
          loading={loadingAnimationStore.tableLoading}
        />
        <TableFooterActionWrapper>
          <Pagination
            onChange={e => onChangePagination(e)}
            pageSize={incomingCommitteeDocumentListPageSize}
            showSizeChanger={false}
            showLessItems
            current={incomingCommitteeDocumentListPageIndex + 1}
            total={incomingCommitteeDocumentListTotalCount}
            hideOnSinglePage={true}
          />
        </TableFooterActionWrapper>
      </div>
    </DocumentsLayout>
  )
}

ConnectedCommitteeDocumentIncomingDocPage.propTypes = {}

export default memo(
  withRouter(
    inject(
      'connectedCommitteeDocumentStore',
      'loadingAnimationStore',
      'authenticationStore',
    )(observer(ConnectedCommitteeDocumentIncomingDocPage)),
  ),
)

import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import validator from '../../validator'
import departmentStore from '../../stores/departmentStore'
import authenticationStore from '../../stores/authenticationStore'
import { MODAL_MODE } from '../../ui_constants'

const CreateDepartmentModal = props => {
  const {
    isVisibleCreateDepartmentModal,
    mode,
    handleCloseCreateDepartmentModal,
    currentDepartment,
  } = props

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisibleCreateDepartmentModal && mode === MODAL_MODE.UPDATE) {
      form.setFieldsValue({
        name: currentDepartment?.name,
      })
    }
  }, [isVisibleCreateDepartmentModal])

  const onFinish = async values => {
    try {
      setIsLoading(true)

      const { name } = values
      if (mode === MODAL_MODE.UPDATE) {
        await departmentStore.update(currentDepartment.code, name)
      } else {
        await departmentStore.create(name)
      }

      closeAndResetForm()

      message.success(
        `${
          mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`
        } phòng ban thành công`
      )

      await departmentStore.getAllDepartmentList(
        authenticationStore.currentCompanyCode()
      )
    } catch (e) {
      if (e.errorCode && e.errorCode === 'DEPARTMENT-102') {
        message.error('Tên phòng ban đã tồn tại')
      } else {
        message.error('Đã có lỗi xảy ra!')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const closeAndResetForm = () => {
    form.resetFields()
    handleCloseCreateDepartmentModal()
  }

  return (
    <Modal
      title={`${
        mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`
      } phòng ban`}
      footer={null}
      closable={true}
      visible={isVisibleCreateDepartmentModal}
      onCancel={closeAndResetForm}>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'name'}
          rules={[
            { required: true, message: 'Vui lòng nhập tên phòng ban!' },
            { validator: validator.validateInputString },
          ]}
          label={'Tên phòng ban:'}>
          <Input placeholder={'Nhập tên phòng ban'} />
        </Form.Item>

        <Form.Item>
          <Button loading={isLoading} htmlType={'submit'} type={'primary'}>
            {mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

CreateDepartmentModal.propTypes = {}

export default CreateDepartmentModal

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import { withRouter } from 'react-router-dom'
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Empty,
  Form,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  MoreOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import {
  EmptyText,
  subStringAvatar,
  trimOverLengthString,
  WordBreak,
} from '../Common/CellText'
import MentionTextInput from '../MentionTextInput/MentionTextInput'
import {
  AttachmentFile,
  AttachmentList,
  CommentBox,
  ConsultManagementDetailContent,
  ConsultManagementDetailWrapper,
  FlexBetweenBox,
  FormButtonGroup,
  FormInputComment,
  ModalDescription,
  ModalTitle,
  UploadFileListItem,
  UploadFileListWrapper,
  UploadWrapper,
} from './ConsultDetailStyled'
import { apiUrl } from '../../config'
import { blue } from '../../color'
import date_format from '../../date_format'
import ContentBlockWrapper from '../ContentBlockWrapper'
import { toJS } from 'mobx'
import {
  ASSIGNEE_TYPE,
  CONSULT_STATUS,
  DATE_FORMAT_DEFAULT,
  TYPE_STATUS,
} from '../../constants'
import PopupUpdateAssignees from '../../pages/Administrative/Consult/PopupUpdateAssignees'
import PopoverWatchTime from '../PopoverWatchTime'
import { CommentHidden } from '../CommentComponent/CommentComponentStyled'
import { StatusTag } from '../Common/StatusTag'
import moment from 'moment'

const ConsultDetail = props => {
  const {
    loadingAnimationStore,
    commonStore,
    consultStore,
    selectUserStore,
    authenticationStore,
    fileStore,
    consultCommentStore,
    history,
  } = props

  const { selectedConsult } = consultStore
  const { currentUser, isSuperAdmin } = authenticationStore
  const { consultCommentList } = consultCommentStore
  const {
    title,
    attachments,
    task_description,
    assignees,
    status,
  } = selectedConsult

  const [comment, setComment] = useState('')
  const [fileList, setFileList] = useState([])
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [isGetCommentList, setIsGetCommentList] = useState(true) // Chỉ chạy useEffect lần đầu tiên
  const [commentList, setCommentList] = useState([])
  const [
    isVisiblePopupUpdateAssignees,
    setisVisiblePopupUpdateAssignees,
  ] = useState(false)

  const createList = toJS(assignees).filter(
    el => el.permission === ASSIGNEE_TYPE.CREATE
  )
  const handlerList = toJS(assignees).filter(
    el => el.permission === ASSIGNEE_TYPE.HANDLER
  )
  const followerList = toJS(assignees).filter(
    el => el.permission === ASSIGNEE_TYPE.FOLLOWER
  )

  const isCreateConsult = currentUser?.username === createList[0].assignee_code
  const isCompleteConsult = selectedConsult.status === CONSULT_STATUS.COMPLETE

  const renderContentComment = (del, id, username, index) => {
    if (isSuperAdmin || currentUser?.username === username) {
      if (!del) {
        return (
          <Popconfirm
            title={'Bạn có muốn xoá bình luận này?'}
            okType={'danger'}
            okText={'Xoá'}
            onConfirm={() => handleDeleteComment(id, index)}
            placement={'topRight'}
            cancelText={'Không'}
            icon={<QuestionCircleOutlined style={{ color: '#fc0000' }} />}>
            <Tooltip title={'Xóa bình luận'}>
              <DeleteTwoTone
                style={{
                  display:
                    isCompleteConsult &&
                    currentUser?.username === username &&
                    !isSuperAdmin &&
                    'none',
                }}
                twoToneColor={'#fc0000'}
              />
            </Tooltip>
          </Popconfirm>
        )
      } else {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Tag
              style={{ marginRight: 0, fontSize: 10, borderRadius: '10px' }}
              color={'red'}>
              Đã xóa
            </Tag>
          </div>
        )
      }
    } else {
      return null
    }
  }

  const renderCommentList = useMemo(() => {
    if (commentList.length === 0 && !loadingAnimationStore.showSpinInline) {
      return (
        <Empty
          style={{ color: '#ccc' }}
          image={<CommentOutlined style={{ fontSize: '60px' }} />}
          imageStyle={{ height: 60 }}
          description={
            <span style={{ fontStyle: 'italic' }}>Không có ý kiến</span>
          }></Empty>
      )
    }
    return (
      commentList &&
      commentList.map((comment, index) => {
        return (
          <CommentHidden key={comment.comment_id}>
            {comment.isHidden && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip
                  title={`${comment.arrCommentDel.length} bình luận được xóa đi`}>
                  <EllipsisOutlined
                    onClick={() => handleShowCommentDel(comment.comment_id)}
                    style={{ fontSize: '30px', width: '30px' }}
                  />
                </Tooltip>
              </div>
            )}
            {!comment.isHidden && (
              <CommentBox
                key={comment.comment_id}
                deleted={comment.deleted}
                className={'listCommentWrapper'}>
                <div className={'left'}>
                  <Avatar
                    style={{ backgroundColor: blue }}
                    src={
                      comment.image_id &&
                      `${apiUrl}/api/v1/images/${comment.image_id}`
                    }>
                    {subStringAvatar(comment.name_uppercase)}
                  </Avatar>
                </div>
                <div className={'right'}>
                  <div className={'content'}>
                    <div className={'name-create'}>
                      <div>
                        {utils.getNameInCapitalize(comment.name_uppercase)}
                      </div>
                      {renderContentComment(
                        comment.deleted,
                        comment.comment_id,
                        comment.user_name,
                        index
                      )}
                    </div>
                    <div className={'content-comment'}>
                      <MentionTextInput
                        value={comment.content.trim()}
                        readonly={true}
                      />
                    </div>
                  </div>
                  <div className={'file'}>
                    <FlexBetweenBox>
                      {comment.files
                        ? comment.files.map(file => (
                            <li
                              style={{
                                listStyle: 'none',
                                fontSize: '12px',
                              }}
                              key={file.file_id}>
                              <PaperClipOutlined style={{ color: '#1890ff' }} />
                              &nbsp;
                              <a
                                onClick={() => {
                                  if (comment.deleted) return
                                  return fileStore.handleDownloadFile(
                                    file.file_id,
                                    file.file_name
                                  )
                                }}>
                                {trimOverLengthString(file.file_name, 30)}
                              </a>
                            </li>
                          ))
                        : []}
                    </FlexBetweenBox>
                  </div>
                  <div className={'time'}>
                    {date_format.renderTime(comment.created_at)}
                  </div>
                </div>
              </CommentBox>
            )}
          </CommentHidden>
        )
      })
    )
  }, [commentList])

  const handleOnchangeComment = (event, newValue) => {
    setComment(newValue)
  }
  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }
  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }, [])

  const sendComment = async () => {
    if (utils.isNullish(comment)) return
    if (comment.length > 500) {
      return message.error('Bình luận không vượt quá 500 ký tự!')
    }
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    loadingAnimationStore.setShowSpinInline(true)
    try {
      const response = await Promise.all(batchUploadArr)
      uploadedFiles = response.map(response => response.data.file_id)
      let commentData = {
        content: comment,
        fileIds: uploadedFiles,
      }
      const scrollCommentWrapper = document.querySelector('.scrollBox')
      let newCommentList = [...commentList]
      await consultCommentStore.postConsultComment(
        selectedConsult.code,
        commentData
      )
      const getCommentResponse = await consultCommentStore.getConsultComment(
        selectedConsult.code
      )
      const newComment =
        getCommentResponse.data[getCommentResponse.data.length - 1]
      newCommentList.push(newComment)
      setCommentList(newCommentList)
      setComment('')
      scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
      setFileList([])
      message.success('Bình luận thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  const renderCommentForm = () => {
    // EOfficeAdmin, SuperAdmin, người tạo và người cho ý kiến hiển thị FORM COMMENT (Khi consult chưa hoàn thành)
    if (
      (isSuperAdmin ||
        isCreateConsult ||
        handlerList
          .map(el => el.assignee_code)
          .includes(currentUser?.username)) &&
      !isCompleteConsult
    ) {
      return (
        <div className={'comment-form'}>
          <ContentBlockWrapper>
            <MentionTextInput
              value={comment}
              readonly={false}
              onChange={handleOnchangeComment}
              placeHolder={'Viết ý kiến...'}
            />
            <FormButtonGroup>
              <UploadWrapper>
                <Upload
                  style={{ marginLeft: 4, marginRight: 4 }}
                  valuePropName={'fileList'}
                  fileList={fileList}
                  multiple={true}
                  onChange={handleChangeFile}
                  beforeUpload={() => false}
                  showUploadList={false}>
                  <Button htmlType={'button'}>
                    <UploadOutlined /> Tải file
                  </Button>
                </Upload>
              </UploadWrapper>
              <Button
                onClick={sendComment}
                type={'primary'}
                icon={<SendOutlined />}>
                Đăng ý kiến
              </Button>
            </FormButtonGroup>
            {fileList.length !== 0 && (
              <UploadFileListWrapper>
                {fileList.map((file, index) => (
                  <UploadFileListItem key={file.uid}>
                    <PaperClipOutlined />
                    <span>{file.name}</span>
                    <Tooltip title={'Xoá tập tin'}>
                      <DeleteOutlined
                        onClick={() => handleRemoveFileFromUploadList(file.uid)}
                      />
                    </Tooltip>
                  </UploadFileListItem>
                ))}
              </UploadFileListWrapper>
            )}
          </ContentBlockWrapper>
        </div>
      )
    }
    return null
  }
  const handleDeleteComment = async (commentID, index) => {
    try {
      loadingAnimationStore.setShowSpinInline(true)
      await consultCommentStore.deleteConsultComment(
        selectedConsult.code,
        commentID
      )
      const newCommentList = [...commentList]
      newCommentList[index] = {
        ...newCommentList[index],
        deleted: true,
      }
      setCommentList(newCommentList)
      message.success('Xóa bình luận thành công!')
    } catch (e) {
      console.log(e)
      message.error('Xóa bình luận thất bại!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  const handleShowCommentDel = id_comment => {
    const indexArrCommentDel = commentList.findIndex(
      cmt => cmt.comment_id === id_comment
    )
    commentList[indexArrCommentDel] = {
      ...commentList[indexArrCommentDel],
      isHidden: false,
    }
    /** Danh sách comment mới gồm mảng những comment đã xóa đi */
    const newCommentList = []
    commentList.map(el => {
      if (el.comment_id === commentList[indexArrCommentDel].comment_id) {
        newCommentList.push(...commentList[indexArrCommentDel].arrCommentDel)
      }
      if (el.comment_id !== commentList[indexArrCommentDel].comment_id) {
        newCommentList.push(el)
      }
    })
    setCommentList(newCommentList)
  }

  const handleDeleteConsult = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await consultStore.deleteConsult(selectedConsult.code)
      await consultStore.clearSelectedConsult()
      await consultStore.getConsultList()
      history.push('/administrative/consult')
      message.success('Xoá ý kiến thành công!')
    } catch (e) {
      console.log(e)
      message.error(e.vi || 'Có lỗi xảy ra, xóa ý kiến thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleUpdateStatusConsult = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await consultStore.updateStatusConsult(selectedConsult.code, {
        status: CONSULT_STATUS.COMPLETE,
      })
      await consultStore.getConsultList()
      await consultStore.getConsultDetail(selectedConsult.code)
      setShowApprovalModal(false)
      message.success('Đã hoàn thành xin ý kiến!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  const showConfirmDeleteConsult = () => {
    Modal.confirm({
      title: 'Xoá ý kiến',
      icon: <ExclamationCircleOutlined style={{ color: '#fc0000' }} />,
      content: 'Bạn có chắc muốn xoá ý kiến này?',
      okType: 'danger',
      okText: 'Đồng ý',
      cancelText: 'Hủy',
      onOk() {
        handleDeleteConsult().then()
      },
    })
  }

  const renderPostAction = () => {
    if (
      !isSuperAdmin &&
      !isCreateConsult &&
      !handlerList.map(el => el.assignee_code).includes(currentUser?.username)
    )
      return
    return (
      <>
        {/* EOfficeAdmin SuperAdmin và người tạo ý kiến và chưa hoàn thành hiện thị btn Duyệt*/}
        {(isSuperAdmin || isCreateConsult) &&
          !isCompleteConsult && (
            <Button
              onClick={() => setShowApprovalModal(true)}
              type={'primary'}
              style={{ marginRight: '10px' }}
              icon={<CheckOutlined />}>
              Hoàn thành
            </Button>
          )}
        <Dropdown
          trigger={'click'}
          overlay={
            <Menu>
              {/*EOfficeAdmin SuperAdmin và người tạo ý kiến (CREATE)  và người cho ý kiến (PIC) hiển thị btn Chỉnh sửa người tham gia*/}
              {(isSuperAdmin ||
                isCreateConsult ||
                handlerList
                  .map(el => el.assignee_code)
                  .includes(currentUser?.username)) && (
                <Menu.Item
                  onClick={() => setisVisiblePopupUpdateAssignees(true)}
                  style={{ color: blue, display: isCompleteConsult && 'none' }}>
                  <EditOutlined />
                  Thêm người tham gia
                </Menu.Item>
              )}
              {/* SuperAdmin và người tạo ý kiến hiển thị nút xóa */}
              {(isSuperAdmin || isCreateConsult) && (
                <Menu.Item
                  danger
                  icon={<DeleteOutlined />}
                  onClick={showConfirmDeleteConsult}>
                  Xóa ý kiến
                </Menu.Item>
              )}
            </Menu>
          }>
          {
            //  SuperAdmin và EOfficeAdmin sẽ hiển thị nút MoreOutlined (3 chấm) khi ý kiến chưa COMPLETE
            !isSuperAdmin &&
            !isCreateConsult &&
            isCompleteConsult ? (
              <div />
            ) : (
              <Tooltip title={'Xử lý ý kiến'}>
                <span
                  style={{ fontSize: 16, cursor: 'pointer' }}
                  className={'action-icon'}>
                  <MoreOutlined />
                </span>
              </Tooltip>
            )
          }
        </Dropdown>
      </>
    )
  }

  const renderDropdownMenu = attachment => {
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            fileStore.handleDownloadFile(
              attachment.file_id,
              attachment.file_name
            )
          }>
          Tải về
        </Menu.Item>
      </Menu>
    )
  }

  const renderAttachmentList = useCallback(() => {
    if (attachments.length === 0) {
      return (
        <div style={{ marginBottom: '10px' }}>
          <EmptyText>Không có tài liệu đính kèm</EmptyText>
        </div>
      )
    }
    return attachments.map(attachment => {
      return (
        <Dropdown
          key={attachment.file_id}
          overlay={() => renderDropdownMenu(attachment)}>
          <AttachmentFile theme={commonStore.appTheme}>
            <div className="file-icon">
              <FileOutlined />
            </div>
            <div className="file-info">
              <Tooltip title={attachment.file_name}>
                <div className="title">
                  {attachment.file_name.length >= 20
                    ? attachment.file_name.slice(0, 20).concat('...')
                    : attachment.file_name}
                </div>
              </Tooltip>
            </div>
          </AttachmentFile>
        </Dropdown>
      )
    })
  }, [])

  useEffect(() => {
    /** Danh sách comment sau khi xóa chứa các comment đã xóa liền nhau gộp thành 1 mảng */
    if (!isGetCommentList) return
    if (consultCommentList.length > 0) setIsGetCommentList(false)

    let commentAfterDeleteList = []
    let key = -1
    consultCommentList.map((el, i) => {
      if (!el.deleted) {
        commentAfterDeleteList.push(toJS(el))
      }
      if (el.deleted && i > key) {
        const arrCommentDel = []
        for (let index = i; index < consultCommentList.length; index++) {
          key = index
          if (consultCommentList[index].deleted === el.deleted) {
            arrCommentDel.push(toJS(consultCommentList[index]))
          }
          if (
            consultCommentList[index].deleted === !el.deleted ||
            index === consultCommentList.length - 1
          )
            break
        }
        commentAfterDeleteList.push({
          comment_id: arrCommentDel[0].comment_id,
          arrCommentDel: arrCommentDel,
          isHidden: true,
        })
      }
    })
    setCommentList(commentAfterDeleteList)
  }, [consultCommentList])

  useEffect(() => {
    return () => consultCommentStore.clearConsultCommentList()
  }, [])

  return (
    <ConsultManagementDetailWrapper
      showFormComment={
        (isSuperAdmin ||
          isCreateConsult ||
          handlerList
            .map(el => el.assignee_code)
            .includes(currentUser?.username)) &&
        !isCompleteConsult
      }>
      <div className="scrollBox">
        <div className="d-flex">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 'calc(100% - 160px)',
            }}>
            <Tooltip placement="top" title={title}>
              <h1
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}>
                {title}
              </h1>
            </Tooltip>
            {StatusTag(TYPE_STATUS.CONSULT, status)}
          </div>
          <div className="action-wrapper">{renderPostAction()}</div>
          <Modal
            visible={showApprovalModal}
            onCancel={() => setShowApprovalModal(false)}
            footer={null}>
            <ModalTitle>
              <ExclamationCircleOutlined
                style={{ marginRight: 8, color: blue }}
              />
              Hoàn thành xin ý kiến
            </ModalTitle>
            <ModalDescription>Đã hoàn thành việc xin ý kiến?</ModalDescription>
            <Form
              layout={'vertical'}
              style={{ paddingLeft: 25, marginTop: 15 }}>
              <FormButtonGroup>
                <Space>
                  <Button
                    danger
                    icon={<CloseOutlined />}
                    onClick={() => setShowApprovalModal(false)}>
                    Huỷ
                  </Button>
                  <Button
                    icon={<CheckOutlined />}
                    type={'primary'}
                    onClick={handleUpdateStatusConsult}>
                    Xác nhận
                  </Button>
                </Space>
              </FormButtonGroup>
            </Form>
          </Modal>
        </div>
        <ConsultManagementDetailContent>
          <div className="content">
            {task_description ? (
              WordBreak(task_description, true)
            ) : (
              <EmptyText>Không có nội dung</EmptyText>
            )}
          </div>
          <Row type={'flex'} gutter={[15, 15]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <div style={{ marginBottom: 12 }}>
                <strong>Người gửi</strong>
              </div>
              {
                <PopoverWatchTime
                  userList={selectUserStore.convertListToDefault(createList)}
                  title={'Danh sách người cho ý kiến'}
                />
              }
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <div style={{ marginBottom: 12 }}>
                <strong style={{ marginRight: 2 }}>Người cho ý kiến</strong>
                <span style={{ color: '#999' }}>{handlerList.length}</span>
              </div>
              {handlerList.length > 0 ? (
                <PopoverWatchTime
                  userList={selectUserStore.convertListToDefault(handlerList)}
                  title={'Danh sách người cho ý kiến'}
                />
              ) : (
                <EmptyText>Không có người cho ý kiến</EmptyText>
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <div style={{ marginBottom: 12 }}>
                <strong style={{ marginRight: 2 }}>Người theo dõi</strong>
                <span style={{ color: '#999' }}>{followerList.length}</span>
              </div>
              {followerList.length > 0 ? (
                <PopoverWatchTime
                  userList={selectUserStore.convertListToDefault(followerList)}
                  title={'Danh sách người theo dõi'}
                />
              ) : (
                <EmptyText>Không có người theo dõi</EmptyText>
              )}
            </Col>
          </Row>
          <div>
            <strong>Thời hạn: </strong>
            <span>
              {moment(selectedConsult?.deadline).format(DATE_FORMAT_DEFAULT)}
            </span>
          </div>
          <div style={{ marginBottom: 12, marginTop: 16 }}>
            <strong>Tài liệu đính kèm</strong>{' '}
            <span style={{ color: '#999' }}>{attachments.length}</span>
          </div>
          <AttachmentList>{renderAttachmentList()}</AttachmentList>
          {renderCommentList}
        </ConsultManagementDetailContent>
      </div>
      <FormInputComment>{renderCommentForm()}</FormInputComment>
      <PopupUpdateAssignees
        type={'consult'}
        isVisiblePopupUpdateAssignees={isVisiblePopupUpdateAssignees}
        handleClosePopupUpdateAssignees={() =>
          setisVisiblePopupUpdateAssignees(false)
        }
      />
    </ConsultManagementDetailWrapper>
  )
}

ConsultDetail.propTypes = {}

export default withRouter(
  inject(
    'loadingAnimationStore',
    'commonStore',
    'fileStore',
    'consultStore',
    'authenticationStore',
    'consultCommentStore',
    'selectUserStore',
  )(observer(ConsultDetail)),
)

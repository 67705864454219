import styled, { css } from 'styled-components'

export const DraggerUploadStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .anticon {
    font-size: 24px;
  }
`

export const SelectList = styled.div`
  max-height: 80px;
  overflow-y: auto;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .tag-selected {
    border-radius: 35px;
    padding: 3px 8px 3px 4px;
    display: flex;
    margin-right: 0;
    align-items: center;
  }
`

export const SelectTitle = styled.div`
  margin-bottom: 8px;
`

export const FormActionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 30px 0;
`

export const UploadFileListWrapper = styled.ul`
  margin-bottom: 15px;
  padding-left: 0 !important;
`

export const UploadFileListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: all ease 0.3s;
  padding: 3px 0;
  border-bottom: 1px solid #ebebeb;

  .digital-signature,
  .rename {
    color: #1890ff;
    font-size: 12px;
    opacity: 0.8;

    &:hover {
      opacity: 1;

      span {
        text-decoration-line: underline;
      }
    }
  }

  .digital-signature {
    ${props =>
      props.isNotPDF
        ? css`
            cursor: no-drop !important;
            color: #ccc !important;
          `
        : css`
            cursor: pointer;
          `}
    ${props =>
      props.isSigned
        ? css`
            color: #2ecc71;
            cursor: no-drop;
          `
        : css`
            cursor: pointer;
            color: #1890ff;
          `}
  }

  .anticon-check,
  .anticon-delete {
    transition: all ease 0.3s;
    color: red;
    font-size: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  .ant-tag {
    cursor: pointer;
    padding: 2px 13px;
  }

  .anticon-file-text {
    margin: 0 8px 0 5px;
    color: ${props => (!props.isSigned ? 'rgba(0, 0, 0, 0.85)' : '#1890ff')};

    + span {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: auto;
      padding-right: 60px;
      color: ${props => (!props.isSigned ? 'rgba(0, 0, 0, 0.85)' : '#1890ff')};
    }
  }
`

import React from 'react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { EmptyText, TitleContentBox } from '../../components/Common/CellText'
import { TaskInfoList } from './MissionDetailPageStyled'
import { ASSIGNEE_TYPE } from '../../constants'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { blue, green, yellowPrimary } from '../../color'

const MissionDetailAssign = props => {
  const { leader, combiner, handler, capGiao, isDetailList } = props

  const renderIcon = type => {
    const styleIcon = {
      marginRight: 4,
      fontSize: 10,
    }
    if (type === ASSIGNEE_TYPE.USER)
      return <UserOutlined style={{ ...styleIcon, color: blue }} />
    if (type === ASSIGNEE_TYPE.DEPARTMENT)
      return <BankOutlined style={{ ...styleIcon, color: green }} />
    if (type === ASSIGNEE_TYPE.GROUP)
      return <TeamOutlined style={{ ...styleIcon, color: yellowPrimary }} />
    return <UserOutlined style={{ ...styleIcon, color: blue }} />
  }

  return (
    <ContentBlockWrapper
      style={
        isDetailList
          ? {
              paddingTop: 5,
              border: `1px solid ${blue}`,
              boxShadow:
                'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
            }
          : {}
      }>
      {isDetailList ? (
        <h3
          style={{
            marginBottom: 0,
            color: blue,
            fontSize: 16,
            textDecoration: 'underline',
          }}>
          Người tham gia
        </h3>
      ) : (
        <TitleContentBox>Người tham gia</TitleContentBox>
      )}
      <TaskInfoList>
        <dt>Lãnh đạo giao việc</dt>
        <dd>
          {leader && leader.length !== 0 ? (
            leader.map(el => (
              <div key={el.assignee_code}>
                {renderIcon(el.assignee_type)}
                <span
                  style={{
                    wordBreak: 'normal',
                    whiteSpace: 'pre-line',
                    textTransform: 'capitalize',
                    lineHeight: '22px',
                  }}>
                  {el.name_uppercase.toLowerCase()}
                </span>
              </div>
            ))
          ) : (
            <EmptyText>Không có lãnh đạo</EmptyText>
          )}
        </dd>
        <dt>Xử lý chính</dt>
        <dd>
          {handler && handler.length !== 0 ? (
            handler.map(el => (
              <div key={el.assignee_code}>
                {renderIcon(el.assignee_type)}
                <span
                  style={{
                    textTransform: 'capitalize',
                    lineHeight: '22px',
                    wordBreak: 'normal',
                    whiteSpace: 'pre-line',
                  }}>
                  {el.name_uppercase.toLowerCase()}
                </span>
              </div>
            ))
          ) : (
            <EmptyText>Không có xử lý chính</EmptyText>
          )}
        </dd>
        <dt>Phối hợp xử lý</dt>
        <dd>
          {combiner && combiner.length !== 0 ? (
            combiner.map(el => (
              <div key={el.assignee_code}>
                {renderIcon(el.assignee_type)}
                <span
                  style={{
                    textTransform: 'capitalize',
                    lineHeight: '22px',
                    wordBreak: 'normal',
                    whiteSpace: 'pre-line',
                  }}>
                  {el.name_uppercase.toLowerCase()}
                </span>
              </div>
            ))
          ) : (
            <EmptyText>Không có xử lý chính</EmptyText>
          )}
        </dd>
        <dt>Cấp giao</dt>
        <dd
          style={{
            wordBreak: 'normal',
            whiteSpace: 'pre-line',
          }}>
          {capGiao?.ten_cap_giao}
        </dd>
      </TaskInfoList>
    </ContentBlockWrapper>
  )
}

MissionDetailAssign.propTypes = {}

export default MissionDetailAssign

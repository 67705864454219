import React, { memo, useCallback, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, DatePicker, Form, Input, message, Select, Upload } from 'antd'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import { CloudUploadOutlined } from '@ant-design/icons'
import {
  DraggerUploadStyled,
  FormActionFooter,
  ViewDetailContent,
} from './DocumentVBPCCreateStyled'
import validator from '../../../validator'
import { Container } from '../../../layouts/Container/Container'
import { DATE_FORMAT_LIST } from '../../../constants'

const DocumentVBPCCreatePage = props => {
  const { Option } = Select
  const { Dragger } = Upload
  const { loadingAnimationStore, documentStore, history, fileStore } = props

  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])

  const handleAddFileToUpload = () => false
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const handleChangeFile = useCallback(
    info => {
      form.setFieldsValue({
        file_id: info.fileList.slice(
          info.fileList.length - 1,
          info.fileList.length
        ),
      })
      setFileList(
        info.fileList.slice(info.fileList.length - 1, info.fileList.length)
      )
    },
    [form]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitForm = async values => {
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', values.file_id[0].originFileObj)
    fileStore
      .uploadFile(formData)
      .then(response => {
        values.file_id = response.data.file_id
        handleCreateVBPC(values)
      })
      .catch(error => {
        message.error(error.vi)
        loadingAnimationStore.showSpinner(false)
      })
  }

  const handleCreateVBPC = useCallback(
    submitData => {
      documentStore
        .createVBPC(submitData)
        .then(() => {
          history.push('/utility/vbpc')
          loadingAnimationStore.showSpinner(false)
          message.success(
            `Tạo văn bản pháp chế ${submitData.document_name} thành công`
          )
        })
        .catch(error => {
          loadingAnimationStore.showSpinner(false)
          message.error(error.vi)
        })
    },
    [documentStore, history, loadingAnimationStore]
  )

  const handleCancel = () => {
    form.resetFields()
    history.push(`/utility/vbpc`)
  }

  const renderFormContent = () => (
    <ContentBlockWrapper>
      <Container maxWidth={820}>
        <Form
          scrollToFirstError={true}
          {...layout}
          form={form}
          name={'add-vbpc-form'}
          onFinish={handleSubmitForm}>
          <Form.Item
            name={'document_name'}
            label={'Tên văn bản'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên tài liệu!' },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên tài liệu'} />
          </Form.Item>

          <Form.Item
            name={'document_number'}
            label={'Số quyết định ban hành'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số quyết định ban hành!',
              },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập số quyết định ban hành'} />
          </Form.Item>

          <Form.Item
            name={'issue_date'}
            label={'Ngày ban hành'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số quyết định ban hành!',
              },
            ]}>
            <DatePicker style={{ width: '100%' }} format={DATE_FORMAT_LIST} />
          </Form.Item>

          <Form.Item name={'issue_level'} label={'Cấp ban hành'}>
            <Input placeholder={'Nhập cấp ban hành'} />
          </Form.Item>

          <Form.Item name={'contruction_board'} label={'Ban chủ trì xây dựng'}>
            <Input placeholder={'Nhập ban chủ trì xây dựng'} />
          </Form.Item>

          <Form.Item name={'status'} label={'Trạng thái'}>
            <Select
              showSearch
              placeholder={'Trạng thái'}
              filterOption={true}
              optionFilterProp={'name'}>
              {['Đã bãi bỏ', 'Hết hiệu lực', 'Còn hiệu lực'].map(
                (status, index) => {
                  return (
                    <Option key={index} name={status} value={index + 1}>
                      {status}
                    </Option>
                  )
                }
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name={'file_id'}
            label={'Tài liệu đính kèm'}
            rules={[{ required: true, message: 'Vui lòng thêm tài liệu!' }]}>
            <Dragger
              valuePropName={'fileList'}
              fileList={fileList}
              onChange={handleChangeFile}
              beforeUpload={handleAddFileToUpload}>
              <DraggerUploadStyled>
                <CloudUploadOutlined />
                <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
              </DraggerUploadStyled>
            </Dragger>
          </Form.Item>
          <FormActionFooter>
            <Button onClick={handleCancel}>Huỷ bỏ</Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              htmlType={'submit'}>
              Tạo nhật văn bản
            </Button>
          </FormActionFooter>
        </Form>
      </Container>
    </ContentBlockWrapper>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết văn bản pháp chế | Tài liệu</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'Chi tiết văn bản pháp chế'}
        routerGoback={'/utility/vbpc'}
      />
      <ViewDetailContent>{renderFormContent()}</ViewDetailContent>
    </DashboardLayout>
  )
}

DocumentVBPCCreatePage.propTypes = {}

export default memo(
  inject(
    'loadingAnimationStore',
    'commonStore',
    'documentStore',
    'fileStore',
  )(observer(DocumentVBPCCreatePage)),
)

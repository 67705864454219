import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const NotificationRequest = {
  getCurrentUserNotification: (
    page,
    size,
    only_news_notification,
    filter_unread
  ) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/users/curent-user/notifications`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
        only_news_notification: only_news_notification,
        filter_unread: filter_unread,
      },
    }),
  readNotification: notification_id =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/users/curent-user/notifications/${notification_id}/read`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  sendTokenToSever: token =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/users/curent-user/notifications/fcm-tokens`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        platform: 'wp', // wp - Web, apns - Apple, android - Android
        token: token,
      },
    }),

  markAllAsRead: only_news_notification =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/users/curent-user/notifications/mark-all-as-read`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { only_news_notification },
    }),
}

// export { default } from './InternalDocument'

import { inject, observer } from 'mobx-react'
import React, { memo, useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { MODULE_CODE } from '../../constants'
import ConnectedCommitteeDocument from './ConnectedCommitteeDocument'
import ConnectedDocument from './ConnectedDocument'
import DocumentManagementLayout from './DocumentManagementLayout'
import {
  DocumentManagementTabContent,
  DocumentManagementTabLayout,
} from './DocumentManagementStyled'
import InternalDocument from './InternalDocument'
import UserGroup from './UserGroup'

const DocumentManagement = props => {
  const { moduleStore } = props

  const [accessVBNoiBo, setCheckVBNoiBo] = useState(false)
  const [accessLienThongVB, setAccessLienThongVB] = useState(false)
  const [accessLienThongVanBanUBQLV, setAccessLienThongVanBanUBQLV] = useState(
    false
  )
  const [accessNhomNguoiDung, setAccessNhomNguoiDung] = useState(false)


  useEffect(() => {
    if (moduleStore.checkAccessModule(MODULE_CODE.van_ban_noi_bo))
      setCheckVBNoiBo(true)

    if (moduleStore.checkAccessModule(MODULE_CODE.lien_thong_van_ban)) {
      setAccessLienThongVB(true)
    }

    if (moduleStore.checkAccessModule(MODULE_CODE.lien_thong_van_ban_ubqlv)) {
      setAccessLienThongVanBanUBQLV(true)
    }
    
    if (moduleStore.checkAccessModule(MODULE_CODE.nhom_nguoi_dung)) {
      setAccessNhomNguoiDung(true)
    }
  }, [moduleStore.moduleList])

  if (accessVBNoiBo) {
    return <InternalDocument />
  }

  if (accessLienThongVB) {
    return <ConnectedDocument />
  }

  if (accessLienThongVanBanUBQLV) {
    return <ConnectedCommitteeDocument />
  }

  if(accessNhomNguoiDung){
    return <UserGroup />
  }
  return (
    <DocumentManagementLayout>
      <DocumentManagementTabLayout>
        <DocumentManagementTabContent>
          <p>Không có module quản trị nào được phân hệ</p>
        </DocumentManagementTabContent>
      </DocumentManagementTabLayout>
    </DocumentManagementLayout>
  )
}

export default memo(
  withRouter(
    inject(
      'authenticationStore',
      'aclStore',
      'moduleStore'
    )(observer(DocumentManagement))
  )
)

import { action, observable } from 'mobx'
import { WorkRequest } from '../../../requests/WorkRequest'
import { KpiRequest } from '../../../requests/KpiRequest'
import { isNil } from 'lodash-es'

class SubTargetWorkCreateStore {
  @observable workList = []
  @observable assignedWorks = []
  @observable isLoading = false
  @observable totalWork = 0

  @observable selectedWorks = []
  @action setSelectedWorks = data => {
    this.selectedWorks = data
  }

  @observable queryParams = {
    page: 0,
    size: 5,
    title: null,
    from_date: null,
    to_date: null,
    status: undefined,
    work_type: null,
    permission: undefined,
    parent_id: null,
    is_important: null,
  }

  @action setQueryParams = payload => {
    this.queryParams = payload
  }

  loadWorks = async () => {
    this.isLoading = true

    let responses = await Promise.all([
      await WorkRequest.getWorkList(this.queryParams),
      await KpiRequest.getAssignedWorks(),
    ])

    let response = responses[0]
    this.totalWork = response.data.total_count
    this.workList = response.data.data

    let workResponse = responses[1]
    this.assignedWorks = workResponse.data

    console.log(
      'assignedWorks found',
      isNil(
        this.assignedWorks.find(work => work.code === 'WRK11286426677354770')
      ),
      isNil(this.assignedWorks.find(work => work.code === '123'))
    )

    this.isLoading = false
  }

  clear = () => {
    this.workList = []
    this.selectedWorks = []
    this.isLoading = false
    this.clearQueryParams()
  }

  clearSelected = () => {
    this.selectedWorks = []
  }

  @action clearQueryParams = () => {
    this.queryParams = {
      page: 0,
      size: 5,
      title: null,
      from_date: null,
      to_date: null,
      status: undefined,
      work_type: null,
      permission: undefined,
      parent_id: null,
      is_important: null,
    }
  }
}

export default new SubTargetWorkCreateStore()

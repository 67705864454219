import React, { memo, useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Pagination, Tag } from 'antd'
import {
  CellEclipseBox,
  TableBottomPaginationBlock,
} from '../../../components/Common/Table'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import { PlusCircleOutlined } from '@ant-design/icons'
import uuid from 'uuid'
import moment from 'moment'
import TableComponent from '../../../components/Common/TableComponent'
import { DATE_FORMAT_DEFAULT } from '../../../constants'

const LeaveDocumentPage = props => {
  const {
    authenticationStore,
    loadingAnimationStore,
    leavePageStore,
    accountStore,
    history,
  } = props

  const { currentUser } = authenticationStore
  const { filterLeaveTable, listLeaveRecord, totalCount } = leavePageStore
  const { accountList } = accountStore

  const tableColumn = [
    {
      title: 'Số Đề Xuất',
      align: 'left',
      width: 150,
      render: record => record?.id,
    },
    {
      title: 'Lý Do',
      render: record => <CellEclipseBox>{record?.title}</CellEclipseBox>,
    },
    {
      title: 'Diễn Giải',
      render: record => <CellEclipseBox>{record?.description}</CellEclipseBox>,
    },
    {
      title: 'Trạng thái',
      align: 'left',
      width: 250,
      render: record => (
        <Tag color={['orange', 'green', 'red', 'blue'][record?.status]}>
          {
            ['Chờ phê duyệt', 'Đã duyệt phiếu', 'Huỷ phiếu', 'Cần phê duyệt'][
              record?.status
            ]
          }
        </Tag>
      ),
    },
    {
      title: 'Nghỉ từ ngày',
      width: 250,
      render: record => moment(record.from_date).format(DATE_FORMAT_DEFAULT),
    },
    {
      title: 'Nghỉ tới ngày',
      width: 250,
      render: record => moment(record.to_date).format(DATE_FORMAT_DEFAULT),
    },
    {
      title: 'Người tạo',
      render: record => (
        <CellEclipseBox>
          {record.createdBy === currentUser?.email ? 'Bạn' : record.createdBy}
        </CellEclipseBox>
      ),
    },
  ]

  const onChangeTable = useCallback(
    (current, size) => {
      console.log(accountList[0])
      if (size !== filterLeaveTable.size) {
        filterLeaveTable.page = 1
        filterLeaveTable.size = size
      } else if (current !== filterLeaveTable.page) {
        filterLeaveTable.page = current
      }
      leavePageStore.setFilter(filterLeaveTable)
    },
    [accountList, filterLeaveTable, leavePageStore]
  )

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    leavePageStore
      .getListLeave(filterLeaveTable)
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => leavePageStore.clearLeaveData()
  }, [filterLeaveTable, leavePageStore, loadingAnimationStore])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Phiếu Nghỉ Phép | Hành Chính</title>
      </Helmet>
      <PageTitle location={props.location} title={'Danh Sách Phiếu Nghỉ Phép'}>
        (
        <Button
          onClick={() => history.push('/administrative/leave-form/create')}
          type={'primary'}>
          <PlusCircleOutlined /> Đăng ký nghỉ phép
        </Button>
        )
      </PageTitle>
      <ContentBlockWrapper>
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(`/administrative/leave-form/${record.id}`),
            }
          }}
          rowKey={() => uuid()}
          columns={tableColumn}
          dataSource={listLeaveRecord}
          pagination={false}
          onChange={onChangeTable}
        />
        {totalCount > 0 ? (
          <TableBottomPaginationBlock>
            <Pagination
              current={filterLeaveTable.page}
              pageSize={filterLeaveTable.size}
              total={totalCount}
              onChange={onChangeTable}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              showSizeChanger={false}
              showLessItems
            />
          </TableBottomPaginationBlock>
        ) : null}
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

LeaveDocumentPage.propTypes = {}

export default memo(
  inject(
    'authenticationStore',
    'loadingAnimationStore',
    'accountStore',
    'leavePageStore'
  )(observer(LeaveDocumentPage))
)

import { CommonServiceRequest } from '../requests/CommonServiceRequest'
import { action } from 'mobx'

class CommonServiceStore {
  @action reportBug = payload => {
    return new Promise((resolve, reject) => {
      CommonServiceRequest.reportBug(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new CommonServiceStore()

import React, { useEffect } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import validator from '../../../validator'
import renameDocumentStore from './renameDocumentStore'
import { observer } from 'mobx-react'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'

const RenameDocumentModal = props => {
  const { visible, onClose } = props
  const { currentDocument } = props
  const { isLoading } = renameDocumentStore
  const [form] = Form.useForm()

  useEffect(() => {
    return () => {
      renameDocumentStore.clearStore()
    }
  }, [])

  useEffect(() => {
    // console.log('RenameDocumentModal', currentDocument)

    form.setFieldsValue({
      object_name: currentDocument?.displayName,
    })
  }, [currentDocument])

  const onFinish = async values => {
    // console.log('onFinish', values)
    const { object_name } = values

    await renameDocumentStore.rename(object_name, currentDocument.id)

    closeAndResetForm()
  }

  const closeAndResetForm = () => {
    form.resetFields()
    onClose()
  }

  return (
    <>
      <Modal
        footer={null}
        closable={true}
        visible={visible}
        onCancel={() => {
          closeAndResetForm()
        }}
        title="Đổi tên tài liệu">
        <Form form={form} layout={'vertical'} onFinish={onFinish}>
          <Form.Item
            name={'object_name'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên tài liệu!' },
              { validator: validator.validateInputString },
            ]}
            label={'Tên tài liệu:'}>
            <Input placeholder={'Nhập tên tài liệu'} />
          </Form.Item>

          <Form.Item>
            <Button
              icon={<PlusOutlined />}
              loading={isLoading}
              htmlType={'submit'}
              type={'primary'}>
              Đổi tên
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default observer(RenameDocumentModal)

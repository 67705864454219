import React, { Fragment, memo, useEffect, useMemo, useState } from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  Button,
  Divider,
  Input,
  message,
  Popconfirm,
  Table,
  Tabs,
  Tooltip,
} from 'antd'
import { DOCUMENT_TYPE, EOFFICE_ADMIN } from '../../constants'
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import DashboardLayout from '../../layouts/DashboardLayout'
import AuthoritiesActionModal from './AuthoritiesActionModal'
import queryString from 'query-string'
import utils from '../../utils'

const AuthoritiesPage = props => {
  const { authenticationStore, authorityStore, history } = props

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)
  const { authorityIssuedsList, authorityIssuedsListOrigin } = authorityStore
  const [
    isLoadingAuthorityIssuedsList,
    setIsLoadingAuthorityIssuedsList,
  ] = useState(false)
  const [valueFilter, setValueFilter] = useState({
    value: '',
  })
  const [
    payloadAuthoritiesActionModal,
    setPayloadAuthoritiesActionModal,
  ] = useState({
    status: false,
    isCreate: true,
    updatePayLoad: null,
  })

  useEffect(() => {
    ;(async () => {
      try {
        authorityStore.setAuthorityIssuedsQueryParams({
          ...authorityStore.authorityIssuedsQueryParams,
          ...queryStringParse,
        })
        setIsLoadingAuthorityIssuedsList(true)
        await authorityStore.getAuthorityIssueds()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingAuthorityIssuedsList(false)
      }
    })()
    return () => {
      authorityStore.clearAuthorityIssuedsList()
    }
  }, [])

  const tableColumns = useMemo(
    () => [
      {
        title: 'Tên đơn vị',
        width: 90,
        render: record => record.name,
      },

      {
        title: 'Tác vụ',
        align: 'right',
        width: 10,
        render: record => (
          <Fragment>
            <Tooltip title={'Sửa thông tin cơ quan ban hành'}>
              <EditOutlined
                className={'anticon-color'}
                onClick={() =>
                  setPayloadAuthoritiesActionModal({
                    status: true,
                    isCreate: false,
                    updatePayLoad: record,
                  })
                }
              />
            </Tooltip>
            <Divider type={'vertical'} />
            <Tooltip title={'Xoá'}>
              <Popconfirm
                onConfirm={() => handleDeleteAuthority(record.id, record.name)}
                okText={'Đồng ý'}
                cancelText={'Không'}
                okType={'danger'}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                title={'Bạn có muốn xoá cơ quan ban hành này?'}>
                <DeleteOutlined style={{ color: '#fc0000' }} />
              </Popconfirm>
            </Tooltip>
          </Fragment>
        ),
      },
    ],
    []
  )

  const handleDeleteAuthority = async (id, name) => {
    try {
      setIsLoadingAuthorityIssuedsList(true)
      await authorityStore.deleteAuthority(id)
      await authorityStore.getAuthorityIssueds()
      setValueFilter({ value: valueFilter.value })
      message.success(
        <span>
          Bạn đã xóa <b>{name}</b>.
        </span>
      )
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingAuthorityIssuedsList(false)
    }
  }

  const handleCloseAuthoritiesActionModal = () => {
    setPayloadAuthoritiesActionModal({
      status: false,
      isCreate: true,
      updatePayLoad: null,
    })
  }

  const handleChangeTab = async type => {
    setValueFilter({ value: '' })
    authorityStore.setAuthorityIssuedsQueryParams({
      ...authorityStore.authorityIssuedsQueryParams,
      type: type,
    })
    try {
      setIsLoadingAuthorityIssuedsList(true)
      await authorityStore.getAuthorityIssueds()
      const queryStringStringify = queryString.stringify(
        {
          ...authorityStore.authorityIssuedsQueryParams,
          type: type,
        },
        {
          skipNull: true,
        }
      )

      let path = `/eoffice-config/authorities?${queryStringStringify}`

      if (props.historyPath) {
        path = `${props.historyPath}?${queryStringStringify}`
      }

      history.replace(path)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingAuthorityIssuedsList(false)
    }
  }

  useEffect(() => {
    if (valueFilter.value === '') {
      authorityStore.setAuthorityIssuedsList(authorityIssuedsListOrigin)
      return
    }
    const authorityIssuedsListFilter = authorityIssuedsListOrigin.filter(item =>
      utils
        .nonAccentVietnamese(item.name)
        .includes(utils.nonAccentVietnamese(valueFilter.value))
    )
    authorityStore.setAuthorityIssuedsList(authorityIssuedsListFilter)
  }, [valueFilter])

  const handleFilterAuthorityIssueds = event => {
    if (event.target.value.trim() === '') {
      setValueFilter({ value: '' })
      return
    }
    setValueFilter({ value: event.target.value })
  }

  return (
    <>
      {!props.isTab ? (
        <DashboardLayout>
          <Helmet>
            <title>Cấu hình cơ quan ban hành | VIMC Portal</title>
          </Helmet>
          <PageTitle
            location={props.location}
            title={'Cấu hình cơ quan ban hành'}>
            <Button
              type={'primary'}
              onClick={() =>
                setPayloadAuthoritiesActionModal({
                  status: true,
                  isCreate: true,
                  updatePayLoad: null,
                })
              }>
              <PlusCircleOutlined />
              Tạo mới cơ quan ban hành
            </Button>
          </PageTitle>
          <ContentBlockWrapper>
            <Tabs
              onChange={handleChangeTab}
              defaultActiveKey={
                queryStringParse.type || DOCUMENT_TYPE.INCOMING
              }>
              <Tabs.TabPane tab="Văn bản đến" key={DOCUMENT_TYPE.INCOMING} />
              <Tabs.TabPane tab="Văn bản đi" key={DOCUMENT_TYPE.OUTGOING} />
            </Tabs>
            <div
              style={{
                marginBottom: 12,
              }}>
              <Input.Search
                value={valueFilter.value}
                placeholder="Tìm kiếm đơn vị theo tên..."
                onChange={handleFilterAuthorityIssueds}
                style={{ width: 300 }}
              />
            </div>
            <Table
              columns={tableColumns}
              dataSource={authorityIssuedsList}
              pagination={false}
              rowKey={'id'}
              loading={{
                spinning: isLoadingAuthorityIssuedsList,
                tip: 'Đang tải...',
              }}
            />
          </ContentBlockWrapper>
        </DashboardLayout>
      ) : (
        <>
          <Tabs
            onChange={handleChangeTab}
            tabBarExtraContent={
              <Button
                type={'primary'}
                onClick={() =>
                  setPayloadAuthoritiesActionModal({
                    status: true,
                    isCreate: true,
                    updatePayLoad: null,
                  })
                }>
                <PlusCircleOutlined />
                Tạo mới cơ quan ban hành
              </Button>
            }
            defaultActiveKey={queryStringParse.type || DOCUMENT_TYPE.INCOMING}>
            <Tabs.TabPane tab="Văn bản đến" key={DOCUMENT_TYPE.INCOMING} />
            <Tabs.TabPane tab="Văn bản đi" key={DOCUMENT_TYPE.OUTGOING} />
          </Tabs>
          <div
            style={{
              marginBottom: 12,
            }}>
            <Input.Search
              value={valueFilter.value}
              placeholder="Tìm kiếm đơn vị theo tên..."
              onChange={handleFilterAuthorityIssueds}
              style={{ width: 300 }}
            />
          </div>
          <Table
            columns={tableColumns}
            dataSource={authorityIssuedsList}
            pagination={false}
            rowKey={'id'}
            loading={{
              spinning: isLoadingAuthorityIssuedsList,
              tip: 'Đang tải...',
            }}
          />
        </>
      )}

      <AuthoritiesActionModal
        tabActive={queryStringParse.type || DOCUMENT_TYPE.INCOMING}
        handleSetValueFilter={isCreate => {
          setValueFilter({ value: isCreate ? '' : valueFilter.value })
        }}
        payloadModal={payloadAuthoritiesActionModal}
        handleCloseModal={handleCloseAuthoritiesActionModal}
      />
    </>
  )
}
export default memo(
  withRouter(
    inject('authenticationStore', 'authorityStore')(observer(AuthoritiesPage))
  )
)

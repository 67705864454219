import styled, { css } from 'styled-components'

export const DocumentHeadingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const HeadingTitleWrapper = styled.div`
  margin-right: 30px;
  margin-bottom: 5px;
`
export const HeadingButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  //margin-bottom: 15px;
  //margin-top: 5px;

  .btn-style {
    color: #1890ff;
    width: 32px;
    height: 32px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 5px;
    background: rgb(255, 255, 255);
    cursor: pointer;

    &:hover {
      transition: 0.2s;
      box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
      opacity: 0.9;
    }
  }
`
export const DocumentContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const BlockLeft = styled.div`
  width: calc(100% - 415px);
  @media screen and (max-width: 1299px) {
    width: 100%;
    margin-bottom: 15px;
  }
`
export const BlockRight = styled.div`
  width: 400px;
  @media screen and (max-width: 1299px) {
    width: 100%;
  }
`
export const DocumentInfoTitle = styled.h2`
  background-color: #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 600;
`
export const DocumentInfoList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: -10px;
  }

  dt {
    width: 160px;
    font-weight: 700;
    padding: 0 10px;

    &:after {
      display: inline-block;
      content: ':';
    }
  }

  dd {
    width: calc(100% - 160px);
    word-break: break-all;
  }

  dt,
  dd {
    min-height: 36px;
    margin-bottom: 0;
  }
`
export const ViewerWrapper = styled.div`
  min-height: 115vh;
  height: 100%;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  ${props =>
    props.fullHeight === null &&
    css`
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    `}
`

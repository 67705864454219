import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SalaryListDepartmentListWrapper } from './SalaryPageStyled'
import { Empty, Table } from 'antd'
import { useHistory } from 'react-router-dom'
import { toJS } from 'mobx'
import SalaryDetailModal from './SalaryDetailModal'
import utils from '../../utils'
import { EmptyText } from '../../components/Common/CellText'

const SalaryListDepartmentList = props => {
  const { salaryInfo, salaryItem } = props

  const history = useHistory()

  const [salaryInfoConvert, setSalaryInfoConvert] = useState([])

  useEffect(() => {
    if (salaryInfo.length === 0) return
    setSalaryInfoConvert([
      ...toJS(salaryInfo),
      {
        ngay_cong_di_lam_thuc_te: salaryItem.tong_so_ngay_di_lam_thuc_te,
        tien_luong_thang_duoc_nhan: salaryItem.tong_luong_thang,
        tong_tien_phu_cap_thang: salaryItem.tong_phu_cap,
        tong_tien_khau_tru: salaryItem.tong_khau_tru,
        ma_nv: 1,
        is_total: true,
      },
    ])
  }, [salaryInfo, salaryItem])

  const tableColumns = [
    {
      align: 'center',
      title: 'STT',
      width: 70,
      render: (value, record, index) => {
        return <span>{record.is_total ? '' : index + 1}</span>
      },
    },
    {
      align: 'center',
      width: 130,
      title: 'Mã nhân viên',
      render: record => {
        if (record.is_total) {
          return <b>Tổng cộng</b>
        }
        return <span>{record.ma_nv}</span>
      },
    },
    {
      align: 'center',
      width: 180,
      title: 'Họ tên',
      render: record => <span>{record.full_name}</span>,
    },
    {
      align: 'center',
      title: <div>Chức danh quy chuẩn</div>,
      render: record => <span>{record.chuc_danh_quy_chuan}</span>,
    },
    {
      align: 'center',
      width: 130,
      title: (
        <div>
          Ngày công
          <br />
          đi làm thực tế
        </div>
      ),
      render: record => (
        <span style={{ fontWeight: record.is_total ? 700 : 400 }}>
          {record.ngay_cong_di_lam_thuc_te ? (
            utils.formatCurrency(record.ngay_cong_di_lam_thuc_te)
          ) : (
            <EmptyText>Không rõ</EmptyText>
          )}
        </span>
      ),
    },
    {
      align: 'center',
      width: 130,
      title: 'Tổng phụ cấp',
      render: record => (
        <span style={{ fontWeight: record.is_total ? 700 : 400 }}>
          {record.tong_tien_phu_cap_thang ? (
            utils.formatCurrency(record.tong_tien_phu_cap_thang)
          ) : (
            <EmptyText>Không rõ</EmptyText>
          )}
        </span>
      ),
    },
    {
      align: 'center',
      width: 130,
      title: 'Tổng khấu trừ',
      render: record => (
        <span style={{ fontWeight: record.is_total ? 700 : 400 }}>
          {record.tong_tien_khau_tru ? (
            utils.formatCurrency(record.tong_tien_khau_tru)
          ) : (
            <EmptyText>Không rõ</EmptyText>
          )}
        </span>
      ),
    },
    {
      align: 'center',
      width: 200,
      title: 'Lương tháng nhận được',
      render: record => (
        <span style={{ fontWeight: record.is_total ? 700 : 400 }}>
          {record.tien_luong_thang_duoc_nhan ? (
            utils.formatCurrency(record.tien_luong_thang_duoc_nhan)
          ) : (
            <EmptyText>Không rõ</EmptyText>
          )}
        </span>
      ),
    },
  ]

  const [isVisibleSalaryDetailModal, setIsVisibleSalaryDetailModal] = useState(
    false
  )
  const [salaryDetail, setSalaryDetail] = useState({})

  return (
    <SalaryListDepartmentListWrapper>
      <Table
        onRow={record => {
          return {
            onClick: () => {
              if (record.is_total) return
              setSalaryDetail(record)
              setIsVisibleSalaryDetailModal(true)
            },
          }
        }}
        rowClassName={'row-table'}
        rowKey={'ma_nv'}
        dataSource={salaryInfoConvert}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 1300 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={{
          tip: 'Đang tải...',
          spinning: false,
        }}
      />

      <SalaryDetailModal
        salaryDetail={salaryDetail}
        isVisibleSalaryDetailModal={isVisibleSalaryDetailModal}
        handleCloseSalaryDetailModal={() =>
          setIsVisibleSalaryDetailModal(false)
        }
      />
    </SalaryListDepartmentListWrapper>
  )
}

SalaryListDepartmentList.propTypes = {
  salaryInfo: PropTypes.array.isRequired,
}

export default SalaryListDepartmentList

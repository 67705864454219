import styled from 'styled-components'
import { Tabs } from 'antd'

export const ListDepartmentContent = styled.div`
  margin-top: 1rem;

  .ant-collapse-borderless {
    background-color: transparent;

    > .ant-collapse-item {
      border-bottom-color: #f0f0f0;
    }
  }

  .ant-collapse-item-active {
    background: #cddaf4;
  }
`

export const TabsDepartmentWrapper = styled(Tabs)`
  .ant-tabs-nav {
    max-height: 85vh;
  }

  .ant-tabs-content {
    min-height: 85vh;
  }

  .ant-tabs-tabpane {
    padding-left: 10px !important;
  }

  .ant-tabs-tab-active {
    background-color: #fafafa;

    a {
      color: #1890ff !important;
    }

    .edit-icon {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }

  .ant-tabs-tab:hover {
    transition: 0.3s;
    background-color: #fafafa;
  }

  .ant-tabs-tab {
    padding-right: 10px;

    .edit-icon {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
    }

    &:hover {
      .edit-icon {
        opacity: 1;
        visibility: visible;
      }
    }

    a {
      display: inline-block !important;
      text-align: left;
    }

    .ant-tabs-tab-btn {
      display: flex;
      align-items: center;
    }
  }
`

import { observable } from 'mobx'
import { PROPOSAL_TYPE } from '../../constants'
import { ContentTemplateRequest } from '../../requests/ContentTemplateRequest'

class ContentTemplateStore {
  @observable items = []

  //
  @observable isLoadingForm = false
  @observable content = null

  load = () => {
    this.items = Object.values(PROPOSAL_TYPE).map(item => {
      return {
        key: item.key,
        label: item.label,
      }
    })
  }

  update = async (key, content) => {
    await ContentTemplateRequest.update(key, content)
  }

  getDetails = async key => {
    let response = await ContentTemplateRequest.getDetails(key)
    this.content = response.data.content ?? ''
  }

  clear = () => {
    this.items = []
    this.isLoadingForm = false
  }
}

export default new ContentTemplateStore()

import React, { useCallback, useEffect, useState } from 'react'
import { Button, Empty, Input, message, Pagination, Row } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import {
  SearchBar,
  TableFooterActionWrapper,
} from './ConnectedDocumentOutgoingDocPageStyled'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import TrucWrapper from '../../components/TrucWrapper/TrucWrapper'
import DocumentsLayout from '../../layouts/DocumentsLayout/DocumentsLayout'
import UnreadText from '../../components/UnreadText/UnreadText'
import TableComponent from '../../components/Common/TableComponent'
import moment from 'moment'
import { ACL_ACTION_TYPE, DATE_FORMAT_DEFAULT } from '../../constants'
import utils from '../../utils'
import AccessControlAction from '../../components/AccessControlAction'
import RefreshTrucButton from '../../components/TrucWrapper/RefreshTrucButton'

const { Search } = Input

const ConnectedDocumentOutgoingDocPage = props => {
  const {
    history,
    connectedDocumentStore,
    loadingAnimationStore,
    authenticationStore,
  } = props

  const { trucToken } = authenticationStore

  const {
    outgoingDocumentList,
    outgoingDocumentListTotalCount,
    outgoingDocumentListPageIndex,
    outgoingDocumentListPageSize,
    outgoingDocumentListFilterBySubject,
    setOutgoingDocumentListAllFilterBySubject,
    getOutgoingDocumentAll,
  } = connectedDocumentStore

  const NGAY_GUI_WIDTH = 120
  const SO_VAN_BAN_WIDTH = 150
  const TRICH_YEU_WIDTH = 450
  const SCROLL_WIDTH = 1000

  let [searchOptionTitle, setSearchOptionTitle] = useState('')

  const [exportLoading, setExportLoading] = useState(false)

  const tableColumns = [
    {
      title: 'Ngày gửi',
      width: NGAY_GUI_WIDTH,
      render: record => (
        <UnreadText
          text={moment(record.createdAt).format(DATE_FORMAT_DEFAULT)}
          isUnread={checkUnread(record)}
          width={NGAY_GUI_WIDTH}
        />
      ),
    },
    {
      title: 'Số văn bản',
      width: SO_VAN_BAN_WIDTH,
      render: record => (
        <UnreadText
          text={record.code}
          isUnread={checkUnread(record)}
          width={SO_VAN_BAN_WIDTH}
        />
      ),
    },
    {
      title: 'Trích yếu',
      width: TRICH_YEU_WIDTH,
      render: record =>
        record.subject ? (
          <UnreadText
            text={record.subject}
            isUnread={checkUnread(record)}
            width={TRICH_YEU_WIDTH}
          />
        ) : (
          <i style={{ color: '#ccc' }}>Không có trích yếu</i>
        ),
    },
  ]

  const checkUnread = record => !record.isViewed

  const exportDocument = useCallback(() => {
    setExportLoading(true)
    setOutgoingDocumentListAllFilterBySubject(
      searchOptionTitle && searchOptionTitle.trim()
    )

    const col = ['Ngày nhận', 'Số hiệu', 'Trích yếu']

    let rows = []
    rows.push(col)
    getOutgoingDocumentAll()
      .then(res => {
        if (res && res.data && res.data.data) {
          res.data.data.map(item => {
            rows.push([
              moment(item.createdAt).format(DATE_FORMAT_DEFAULT),
              item.code,
              item.subject,
              item.userFrom,
            ])
          })
        }
        utils.exportExcel(
          rows,
          'VBLT-VIMC - VB đi - ' + moment().format('YYYY-MM-DD hmms')
        )
      })
      .finally(() => setExportLoading(false))
  }, [searchOptionTitle])

  useEffect(() => {
    if (!localStorage.getItem('jwtTruc')) return
    loadingAnimationStore.showSpinner(true)
    connectedDocumentStore
      .getOutgoingDocument()
      .catch(err => {
        message.warning(err.vi)
      })
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [
    outgoingDocumentListPageIndex,
    outgoingDocumentListPageSize,
    outgoingDocumentListFilterBySubject,
    trucToken,
  ])

  return (
    <TrucWrapper>
      <DocumentsLayout
        title={
          <PageTitle location={props.location} title="Văn bản đi" hiddenGoBack>
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.create__LIEN_THONG_OUTGOING}>
              <Button
                onClick={() => {
                  connectedDocumentStore.clearDataReceiveDocument()
                  history.push('/connected-document/outgoing-document/create')
                }}
                type={'primary'}>
                Tạo mới văn bản đi
              </Button>
            </AccessControlAction>
          </PageTitle>
        }
        selectedKey="outgoing-document"
        linkToIncomingDocument="/connected-document/incoming-document"
        linkToOutgoingDocument="/connected-document/outgoing-document">
        <Helmet>
          <title>Văn bản đi | Liên thông văn bản</title>
        </Helmet>
        <Row>
          <SearchBar>
            <Search
              allowClear
              onSearch={
                connectedDocumentStore.setOutgoingDocumentListFilterBySubject
              }
              placeholder={'Tìm kiếm văn bản theo trích yếu'}
              onChange={e => {
                setSearchOptionTitle(e.target.value)
              }}
              value={searchOptionTitle}
            />
          </SearchBar>
          <Button loading={exportLoading} onClick={exportDocument}>
            <PrinterOutlined />
            In
          </Button>
          <RefreshTrucButton />
        </Row>
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(
                  `/connected-document/outgoing-document/view/${record.id}`
                ),
            }
          }}
          rowKey={record => record.id}
          dataSource={outgoingDocumentList}
          columns={tableColumns}
          pagination={false}
          scroll={{ x: SCROLL_WIDTH }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
        />
        <TableFooterActionWrapper>
          <Pagination
            onChange={e =>
              connectedDocumentStore.changeOutgoingDocumentPageIndex(e)
            }
            pageSize={outgoingDocumentListPageSize}
            showSizeChanger={false}
            showLessItems
            current={outgoingDocumentListPageIndex + 1}
            total={outgoingDocumentListTotalCount}
            hideOnSinglePage={true}
          />
        </TableFooterActionWrapper>
      </DocumentsLayout>
    </TrucWrapper>
  )
}

ConnectedDocumentOutgoingDocPage.propTypes = {}

export default withRouter(
  inject(
    'connectedDocumentStore',
    'loadingAnimationStore',
    'bookStore',
    'authorityStore',
    'authenticationStore',
  )(observer(ConnectedDocumentOutgoingDocPage)),
)

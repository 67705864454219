import { action, observable } from 'mobx'
import { UserTrucRequest } from '../requests/UserTrucRequest'

class UserTrucStore {
  @observable userListTruc = []
  @observable totalCountTruc = 0
  @observable dataGroupStoreListTruc = []
  @observable selectPopupNameTruc = 'Bảng chọn'
  @observable originGroupListTruc = []
  @observable groupListTruc = []
  @observable pageIndex = 0
  @observable pageSize = 10000
  @observable name = null
  @observable isEnabled = null

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  @action getUserListTruc = () => {
    return new Promise((resolve, reject) => {
      UserTrucRequest.getUserListTruc(
        this.pageSize,
        this.pageIndex,
        this.name,
        this.isEnabled
      )
        .then(response => {
          const data = response.data.data
          const totalCount = response.data.totalElements - 1
          /** Trừ đi username là admin */
          this.userListTruc = data.filter(el => el.userName !== 'admin')
          this.totalCountTruc = totalCount
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getGroupListTruc = () => {
    return new Promise((resolve, reject) => {
      UserTrucRequest.getGroupListTruc(0, 100000)
        .then(response => {
          const groupListCustom = response.data.data.map(group => ({
            ...group,
            users: [],
          }))
          this.originGroupListTruc = groupListCustom
          this.groupListTruc = groupListCustom
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action setGroupListTruc = newList => {
    this.groupListTruc = newList
  }

  @action getMemberGroupTruc = id_group => {
    return new Promise((resolve, reject) => {
      UserTrucRequest.getMemberListByIdGroupTruc(id_group, 0, 100000)
        .then(response => {
          const resultGroupList = this.originGroupListTruc.map(group =>
            group.groupId !== id_group
              ? group
              : {
                  ...group,
                  users: response.data.data,
                }
          )
          this.originGroupListTruc = resultGroupList
          this.groupListTruc = resultGroupList
          resolve(response.data.data)
        })
        .catch(error => reject(error))
    })
  }

  /** Select User */
  @observable selectUserDataTruc = []
  @action setSelectUserDataTruc = payloadSelect => {
    this.selectUserDataTruc = payloadSelect
  }

  @observable isVisibleSelectUserPopupTruc = false
  @observable setIsVisibleSelectUserPopupTruc = bool => {
    this.isVisibleSelectUserPopupTruc = bool
  }

  @action handleOpenSelectUserPopupTruc = () => {
    this.isVisibleSelectUserPopupTruc = true
  }

  @action handleCancelSelectUserTruc = () => {
    this.isVisibleSelectUserPopupTruc = false
    this.selectUserDataTruc = []
  }

  @action clearSelectUserDataTruc = () => {
    this.selectUserDataTruc = []
  }

  /** Select Group */
  @observable selectGroupDataTruc = []
  @action setSelectGroupDataTruc = payloadSelect => {
    this.selectGroupDataTruc = payloadSelect
  }

  @observable isVisibleSelectGroupPopupTruc = false
  @observable setIsVisibleSelectGroupPopupTruc = bool => {
    this.isVisibleSelectGroupPopupTruc = bool
  }

  @action clearSelectGroupDataTruc = () => {
    this.selectGroupDataTruc = []
  }

  @action handleOpenSelectGroupPopupTruc = () => {
    this.isVisibleSelectGroupPopupTruc = true
  }

  @action handleCancelSelectGroupTruc = () => {
    this.isVisibleSelectGroupPopupTruc = false
    this.selectGroupDataTruc = []
  }

  @action handleRemoveSelectTruc = item => {
    this.selectUserDataTruc = [
      ...this.selectUserDataTruc.filter(el => el.id !== item.id),
    ]
    this.selectGroupDataTruc = [
      ...this.selectGroupDataTruc.filter(el => el.id !== item.id),
    ]
  }

  @action clearStore = () => {
    this.userListTruc = []
    this.clearSelectUserDataTruc()
    this.clearSelectGroupDataTruc()
  }
}

export default new UserTrucStore()

import styled, { css } from 'styled-components'

export const MisionGroupWrap = styled.div`
  position: relative;
`
export const MissionWrapper = styled.div`
  position: relative;
`
export const MissionGroupsList = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  font-size: 10px;
  margin: 24px -16px 24px 0;
`
export const MissionGroupItem = styled.div`
  width: calc(20% - 16px);
  flex-shrink: 0;
  min-width: 230px;
  cursor: pointer;
  transition: all linear 0.25s;

  .mission-performer {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 6px;
    display: block;
    display: -webkit-box;
    height: 12px * 1.3 * 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* .title {
    display: inline-block;
    min-width: 90px;
  } */
`

export const ContentBoxItem = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: -6px;
  }

  dt,
  dd {
    padding: 0.5rem 0;
    margin-bottom: 0;
  }

  dt {
    width: 120px;
    font-weight: 700;

    &:after {
      display: inline-block;
      content: ':';
    }
  }

  .no-two-dots {
    &:after {
      display: inline-block;
      content: '';
    }
  }

  dd {
    width: calc(100% - 120px);
    word-break: break-all;
  }
`

import { action, observable } from 'mobx'
// Request
import { TaskManagementCommentRequest } from '../requests/TaskManagementCommentRequest'

class TaskManagementCommentStore {
  /** Task comment list */
  @observable taskCommentList = []
  @observable taskCommentCurrentStep = undefined

  /** Change comment step */
  @action changeCommentStep = step => {
    return new Promise(resolve => {
      this.taskCommentCurrentStep = step
      resolve()
    })
  }

  /** Clear data */
  @action clearTaskCommentList = () => {
    this.taskCommentList.length = 0
    this.taskCommentCurrentStep = undefined
  }
  @action clearTaskCommentCurrentStep = () => {
    this.taskCommentCurrentStep = undefined
  }

  /** Get comments */
  @action getTaskComment = taskCode => {
    return new Promise((resolve, reject) => {
      TaskManagementCommentRequest.getTaskComment(
        taskCode,
        this.taskCommentCurrentStep
      )
        .then(response => {
          this.taskCommentList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Post new comment */
  @action postTaskComment = (taskCode, taskComment, fileListArr) => {
    return new Promise((resolve, reject) => {
      TaskManagementCommentRequest.postTaskComment(
        taskCode,
        taskComment,
        fileListArr
      )
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /** Delete comment */
  @action deleteTaskComment = (taskCode, commentId) => {
    return new Promise((resolve, reject) => {
      TaskManagementCommentRequest.deleteTaskComment(taskCode, commentId)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  /** Change step */
  @action changeStep = (taskCode, stepNumber, picCode) => {
    return new Promise((resolve, reject) => {
      TaskManagementCommentRequest.changeStep(taskCode, stepNumber, picCode)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new TaskManagementCommentStore()

import React, { useCallback, useEffect, useRef, useState } from 'react'
// Component
import ContentBlockWrapper from '../../../../../components/ContentBlockWrapper'
import { TitleContentBox } from '../../../../../components/Common/CellText'
import EditableText from '../EditableText'
// Styled Component
import {
  DocumentWrapper,
  PopupRegisNumberWrapper,
} from './PopupRegisNumberStyled'
import {
  DocumentController,
  NameDocument,
} from '../../../../../components/PopupSign/PopupSignStyled'

// Ant design
import {
  Button,
  Empty,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Tooltip,
} from 'antd'
import {
  CloseOutlined,
  DownloadOutlined,
  FormOutlined,
  InfoCircleTwoTone,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import { blue } from '../../../../../color'
import { Layer, Stage } from 'react-konva'
import { Document, Page } from 'react-pdf'
import { PDFDocument } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import { ELECTRONIC_DOCUMENT_STATUS } from '../../../../../constants'

const { confirm } = Modal

const PopupRegisNumber = props => {
  const {
    isVisiblePopupRegisNumber,
    electronicDocumentStore,
    fileActive,
    loadingAnimationStore,
    fileStore,
    handleClosePopupRegisNumber,
  } = props

  const { documentAttachment } = fileStore

  const { selectedElectronicDocument } = electronicDocumentStore

  const [styleCanvas, setStyleCanvas] = useState({
    width: 0,
    height: 60,
    border: null,
  })
  const [pageNumber, setPageNumber] = useState(1)

  const [fileBlob, setFileBlob] = useState()

  function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
  }

  useEffect(() => {
    if (!documentAttachment) return
    setFileBlob(blobToFile(documentAttachment, fileActive?.file_name))
  }, [documentAttachment])

  useEffect(() => {
    if (!fileBlob) {
      setStyleCanvas({
        width: 0,
        height: 60,
        border: null,
      })
    }
  }, [fileBlob])

  const renderPage = async (pdf, pageNumber) => {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1 })
    setStyleCanvas({
      width: viewport.width,
      height: viewport.height,
      border: '1px solid #000',
    })
  }

  const [numberElectronic, setNumberElectronic] = useState({
    text: '001',
    position: { x: 50, y: 50 },
    fontSize: 12,
  })

  const [dayElectronic, setDayElectronic] = useState({
    text: '002',
    position: { x: 50, y: 100 },
    fontSize: 12,
  })

  const [monthElectronic, setMonthElectronic] = useState({
    text: '003',
    position: { x: 50, y: 150 },
    fontSize: 12,
  })

  const clearDataPopupRegisNumber = () => {
    setPageNumber(1)
    setNumberElectronic({
      text: '001',
      position: { x: 50, y: 50 },
      fontSize: 12,
    })
    setDayElectronic({
      text: '002',
      position: { x: 50, y: 100 },
      fontSize: 12,
    })
    setMonthElectronic({
      text: '003',
      position: { x: 50, y: 150 },
      fontSize: 12,
    })
  }

  const [loadedPdf, setLoadedPdf] = useState()
  const [numPages, setNumPages] = useState(null)
  const onDocumentLoadSuccess = pdf => {
    const { numPages } = pdf
    setNumPages(numPages)
    setLoadedPdf(pdf)
    renderPage(pdf, pageNumber)
  }
  const stageRef = useRef(null)

  const handleChangeIncomingNumber = useCallback(
    event => {
      let newText = ''
      if (event) {
        if (event.value) {
          newText = event.text
        } else {
          newText = event.target.value
        }

        setNumberElectronic(prevState => ({
          ...prevState,
          text: newText,
        }))
      }
    },
    [numberElectronic]
  )

  const handleChangeDayElectronic = useCallback(
    event => {
      let newText = ''
      if (event) {
        if (event.value) {
          newText = event.text
        } else {
          newText = event.target.value
        }
        setDayElectronic(prevState => ({
          ...prevState,
          text: newText,
        }))
      }
    },
    [dayElectronic]
  )

  const handleChangeMonthElectronic = useCallback(
    event => {
      let newText = ''
      if (event) {
        if (event.value) {
          newText = event.text
        } else {
          newText = event.target.value
        }
        setMonthElectronic(prevState => ({
          ...prevState,
          text: newText,
        }))
      }
    },
    [monthElectronic]
  )

  const handleRegisNumber = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      const arrayBuffer = await fileBlob.arrayBuffer()
      const pdfDoc = await PDFDocument.load(arrayBuffer)
      pdfDoc.registerFontkit(fontkit)
      const pages = pdfDoc.getPages()
      const renderPage = pages[pageNumber - 1]
      const fontBytes = await fetch('/assets/fonts/times.ttf').then(res =>
        res.arrayBuffer()
      )
      const font = await pdfDoc.embedFont(fontBytes)
      const { width, height } = renderPage.getSize()
      renderPage.drawText(numberElectronic.text, {
        x: numberElectronic.position.x,
        y: height - numberElectronic.position.y - numberElectronic.fontSize,
        size: numberElectronic.fontSize,
        font,
      })
      renderPage.drawText(dayElectronic.text, {
        x: dayElectronic.position.x,
        y: height - dayElectronic.position.y - dayElectronic.fontSize,
        size: dayElectronic.fontSize,
        font,
      })
      renderPage.drawText(monthElectronic.text, {
        x: monthElectronic.position.x,
        y: height - monthElectronic.position.y - monthElectronic.fontSize,
        size: monthElectronic.fontSize,
        font,
      })
      const pdfBytes = await pdfDoc.save()
      const formData = new FormData()
      formData.append(
        'file',
        new Blob([pdfBytes], { type: 'application/pdf' }),
        fileActive.file_name
      )
      const response = await fileStore.uploadFile(formData)
      const updateStatusData = {
        action: ELECTRONIC_DOCUMENT_STATUS.REGIS_NUMBER,
        file_id: response.data.file_id,
        register_code: {
          number: numberElectronic.text,
          date: dayElectronic.text,
          month: monthElectronic.text,
        },
      }
      await electronicDocumentStore.updateElectronicDocumentStatus(
        updateStatusData,
        selectedElectronicDocument.code
      )
      const { data } = await electronicDocumentStore.getElectronicDocumentById(
        selectedElectronicDocument.code
      )
      await fileStore.downloadAttachment(
        data.action_history.find(el => el.action === data.status).file_id
      )
      clearDataPopupRegisNumber()
      handleClosePopupRegisNumber()
      message.success('Cấp số thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showRegisNumberConfirm = () => {
    confirm({
      title: 'Bạn có chắc chắn cấp số tài liệu này?',
      icon: <InfoCircleTwoTone />,
      content: '',
      okText: 'Đồng ý',
      okType: 'primary',
      cancelText: 'Hủy bỏ',
      onOk() {
        handleRegisNumber()
      },
      onCancel() {
        console.log('Hủy cấp số')
      },
    })
  }

  return (
    <PopupRegisNumberWrapper
      visible={isVisiblePopupRegisNumber}
      footer={null}
      width={1300}
      style={{ top: 20 }}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span>Cấp số tài liệu</span>
          <Space>
            <Button
              onClick={showRegisNumberConfirm}
              icon={<FormOutlined />}
              type={'primary'}>
              Cấp số tài liệu
            </Button>
            <Tooltip title={'Đóng'}>
              <Button
                icon={<CloseOutlined />}
                danger
                onClick={() => {
                  clearDataPopupRegisNumber()
                  handleClosePopupRegisNumber()
                }}
              />
            </Tooltip>
          </Space>
        </div>
      }
      closable={false}>
      <div style={{ display: 'flex', columnGap: 20 }}>
        <div style={{ flexGrow: 1 }}>
          <DocumentWrapper>
            <DocumentController maxWidth={styleCanvas.width}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Button
                  style={{ width: 42 }}
                  onClick={() => {
                    if (pageNumber === 1) return
                    setPageNumber(pageNumber - 1)
                    renderPage(loadedPdf, pageNumber - 1)
                  }}
                  icon={<LeftOutlined />}
                />
                <p style={{ margin: '0 10px' }}>
                  {pageNumber}/ {numPages}
                </p>
                <Button
                  style={{ width: 42 }}
                  onClick={() => {
                    if (pageNumber === numPages) return
                    setPageNumber(pageNumber + 1)
                    renderPage(loadedPdf, pageNumber + 1)
                  }}
                  icon={<RightOutlined />}
                />
              </div>
            </DocumentController>
            <NameDocument maxWidth={styleCanvas.width} signed={false}>
              <Tooltip title={'Tải xuống'}>
                <div
                  color={'file_name'}
                  onClick={() =>
                    fileStore.handleDownloadFile(
                      fileActive.file_id,
                      fileActive.file_name
                    )
                  }>
                  <DownloadOutlined
                    style={{
                      color: blue,
                      fontSize: 16,
                      marginRight: 10,
                    }}
                  />
                  <span style={{ lineHeight: '20px' }}>
                    {fileActive?.file_name}
                  </span>
                </div>
              </Tooltip>
            </NameDocument>
            {fileBlob ? (
              <div className={'document-wrapper'}>
                <Document
                  style={{ display: 'flex', justifyContent: 'center' }}
                  file={fileBlob}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={
                    <div className={'spin-box'}>
                      <Spin tip="Đang tải tài liệu" />
                    </div>
                  }>
                  <Page pageNumber={pageNumber} loading={null} />
                </Document>
              </div>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Vui lòng chọn tài liệu tài liệu!'}
              />
            )}
            <Stage
              ref={stageRef}
              width={styleCanvas.width}
              height={styleCanvas.height}
              style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                border: styleCanvas.border,
                top: 92,
                zIndex: 9,
              }}>
              <Layer>
                <>
                  <EditableText
                    stageRef={stageRef}
                    value={numberElectronic}
                    setValue={setNumberElectronic}
                  />
                  <EditableText
                    stageRef={stageRef}
                    value={dayElectronic}
                    setValue={setDayElectronic}
                  />

                  <EditableText
                    stageRef={stageRef}
                    value={monthElectronic}
                    setValue={setMonthElectronic}
                  />
                </>
              </Layer>
            </Stage>
          </DocumentWrapper>
        </div>
        <div style={{ flex: '0 0 300px', maxWidth: 300 }}>
          <ContentBlockWrapper style={{ marginBottom: 20 }}>
            <TitleContentBox>Tùy chọn cấp số</TitleContentBox>
            <div
              style={{
                marginBottom: '1rem',
                alignItems: 'center',
              }}>
              <label style={{ fontWeight: 600, marginRight: 10, width: 60 }}>
                Số:{''}
              </label>
              <Input
                placeholder="Nhập số"
                maxLength={10}
                value={numberElectronic.text}
                onChange={handleChangeIncomingNumber}
              />
            </div>
            <div
              style={{
                marginBottom: '1rem',
                alignItems: 'center',
              }}>
              <label style={{ fontWeight: 600, marginRight: 10, width: 60 }}>
                Ngày:{' '}
              </label>
              <Input
                placeholder="Nhập ngày"
                maxLength={10}
                value={dayElectronic.text}
                onChange={handleChangeDayElectronic}
              />
            </div>
            <div
              style={{
                marginBottom: '1rem',
                alignItems: 'center',
              }}>
              <label style={{ fontWeight: 600, marginRight: 10, width: 60 }}>
                Tháng:{' '}
              </label>
              <Input
                placeholder="Nhập tháng"
                maxLength={10}
                value={monthElectronic.text}
                onChange={handleChangeMonthElectronic}
              />
            </div>
          </ContentBlockWrapper>
        </div>
      </div>
    </PopupRegisNumberWrapper>
  )
}

PopupRegisNumber.propTypes = {}

export default inject(
  'signedDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore',
  'electronicDocumentStore',
)(observer(PopupRegisNumber))

import styled from 'styled-components'

export const FormWrapper = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 820px;
  padding: 2rem 15px;
`
export const DraggerUploadStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .anticon {
    font-size: 24px;
  }
`
export const FormButtonGroup = styled.div`
  margin: 15px 0 30px;
  text-align: end;
`

import React, { useCallback, useEffect, useState } from 'react'
import TableComponent from '../../components/Common/TableComponent'
import { EmptyText } from '../../components/Common/CellText'
import { DATE_FORMAT_DEFAULT, SALARY_TYPE } from '../../constants'
import { Empty } from 'antd'
import MySalaryListPagination from './MySalaryListPagination'
import { inject, observer } from 'mobx-react'
import SalaryDetailModal from '../SalaryPage/SalaryDetailModal'
import moment from 'moment'
import SalaryManagerModal from '../SalaryPage/SalaryManagerModal'
import utils from '../../utils'

const scrollX = { x: 600 }
const MySalaryList = props => {
  const { salaryStore, isLoading } = props

  const { mySalaryList } = salaryStore
  const [isManagerSalary, setIsManagerSalary] = useState(true)

  useEffect(() => {
    if (mySalaryList.length === 0) return
    setIsManagerSalary(mySalaryList[0].type === SALARY_TYPE.MANAGER)
  }, [mySalaryList])

  const tableColumns = [
    {
      align: 'center',
      title: 'Mã nhân viên',
      render: record =>
        record.ma_nv ? (
          <span>{record.ma_nv}</span>
        ) : (
          <EmptyText>Không rõ mã nhân viên.</EmptyText>
        ),
    },
    {
      title: 'Ngày công đi làm thực tế',
      align: 'center',
      render: record => {
        let ngay_cong
        if (isManagerSalary) {
          ngay_cong = record.raw_data?.so_ngay_cong_di_lam
        } else {
          ngay_cong = record.raw_data?.ngay_cong_di_lam_thuc_te
        }
        return (
          <div>{ngay_cong ? ngay_cong : <EmptyText>Không rõ.</EmptyText>}</div>
        )
      },
    },
    {
      align: 'center',
      title: (
        <div>
          {isManagerSalary ? 'Lương tháng còn nhận' : 'Lương tháng nhận được'}
        </div>
      ),
      render: record => {
        let luong
        if (isManagerSalary) {
          luong = record.raw_data?.con_nhan
        } else {
          luong = record.raw_data?.tien_luong_thang_duoc_nhan
        }
        return (
          <div>
            {luong ? (
              utils.formatCurrency(luong)
            ) : (
              <EmptyText>Không rõ.</EmptyText>
            )}
          </div>
        )
      },
    },
    {
      align: 'center',
      title: 'Ngày duyệt',
      render: record => (
        <span>
          {record.approved_at ? (
            moment(record.approved_at).format(DATE_FORMAT_DEFAULT)
          ) : (
            <EmptyText>Không rõ ngày duyệt.</EmptyText>
          )}
        </span>
      ),
    },
  ]

  const [isVisibleSalaryDetailModal, setIsVisibleSalaryDetailModal] = useState(
    false
  )
  const [
    isVisibleSalaryManagerModal,
    setIsVisibleSalaryManagerModal,
  ] = useState(false)

  const [salaryDetail, setSalaryDetail] = useState({})

  const handleCloseSalaryDetailModal = useCallback(() => {
    setIsVisibleSalaryDetailModal(false)
    setSalaryDetail({})
  }, [])
  const handleCloseSalaryManagerModal = useCallback(() => {
    setIsVisibleSalaryManagerModal(false)
    setSalaryDetail({})
  }, [])

  const handleClickRow = useCallback(record => {
    if (record.type === SALARY_TYPE.EMPLOYEE) {
      setIsVisibleSalaryDetailModal(true)
    }
    if (record.type === SALARY_TYPE.MANAGER) {
      setIsVisibleSalaryManagerModal(true)
    }
    setSalaryDetail(record.raw_data)
  }, [])

  return (
    <>
      <h3 style={{ color: '#e67e22', marginBottom: 15 }}>
        Danh sách bảng lương
      </h3>
      <TableComponent
        onRow={record => {
          return {
            onClick: () => {
              handleClickRow(record)
            },
          }
        }}
        rowKey={'salary_request_code'}
        dataSource={mySalaryList}
        columns={tableColumns}
        pagination={false}
        scroll={scrollX}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
      <MySalaryListPagination />
      <SalaryDetailModal
        handleCloseSalaryDetailModal={handleCloseSalaryDetailModal}
        salaryDetail={salaryDetail}
        isVisibleSalaryDetailModal={isVisibleSalaryDetailModal}
      />
      <SalaryManagerModal
        salaryDetail={salaryDetail}
        isVisibleSalaryManagerModal={isVisibleSalaryManagerModal}
        handleCloseSalaryManagerModal={handleCloseSalaryManagerModal}
      />
    </>
  )
}

MySalaryList.propTypes = {}
export default inject('salaryStore')(observer(MySalaryList))

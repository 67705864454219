import React, { useEffect, useState } from 'react'
import { Button, Empty, InputNumber, message, Popconfirm, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import TargetList from './TargetList'
import { blue } from '../../../color'
import {
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  SignalFilled,
  WarningOutlined,
} from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'
import '../KpiPage.css'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import { toJS } from 'mobx'
import KpiCreateModal from '../KpiCreateModal'
import { ACL_ACTION_TYPE } from '../../../constants'
import AccessControlAction from '../../../components/AccessControlAction/AccessControlAction'

const KpiList = props => {
  const { kpiStore, authenticationStore, isLoading } = props
  const [isLoadingList, setIsLoadingList] = useState(isLoading)
  const [isVisibleCreateModal, setVisibleCreateModal] = useState(isLoading)

  let history = useHistory()
  const { kpi_quarter_code } = useParams()

  const { kpiList, originKpiList, actualWeightQuarter } = kpiStore

  const [validateInputExpectWeight, setValidateInputExpectWeight] = useState({
    correct: true,
    kpiCode: null,
  })

  const onChangeExpectWeight = (value, kpiCode) => {
    console.log('kpiList', toJS(kpiList))
    const newKpiList = [...kpiList]
    const index = newKpiList.findIndex(item => item.code === kpiCode)
    const originExpectWeight = originKpiList[index].expect_weight
    const originActualWeight = originKpiList[index].actual_weight

    const newActualWeight =
      originExpectWeight > 0
        ? Math.round(
            ((originActualWeight * value) / originExpectWeight) * 100
          ) / 100
        : 0
    newKpiList[index] = {
      ...newKpiList[index],
      expect_weight: value,
      actual_weight: newActualWeight,
    }

    setValidateInputExpectWeight({
      ...validateInputExpectWeight,
      kpiCode: kpiCode,
    })
    kpiStore.setKpiList(newKpiList)
  }

  useEffect(() => {
    kpiStore.quarterCode = kpi_quarter_code
  }, [kpi_quarter_code])

  useEffect(() => {
    const expectWeightTotal = kpiList.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.expect_weight
    }, 0)
    const actualWeightTotal = kpiList.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.actual_weight
    }, 0)
    if (expectWeightTotal === 100) {
      setValidateInputExpectWeight({
        ...validateInputExpectWeight,
        correct: true,
      })
    } else {
      setValidateInputExpectWeight({
        ...validateInputExpectWeight,
        correct: false,
      })
    }
    kpiStore.setActualWeightQuarter(Math.round(actualWeightTotal * 100) / 100)
  }, [kpiList])

  useEffect(() => {
    kpiStore.setAllowSaveKpi(validateInputExpectWeight.correct)
  }, [validateInputExpectWeight])

  const save = async () => {
    setIsLoadingList(true)

    await kpiStore.updateKpiValues()
    await kpiStore.getKpiList()

    setIsLoadingList(false)

    message.success('Cập nhật thành công')
  }

  const deleteKpi = async kpi => {
    setIsLoadingList(true)

    await kpiStore.delete(kpi.code)
    await kpiStore.getKpiList()

    setIsLoadingList(false)

    message.success('Xóa thành công')
  }

  return (
    <ContentBlockWrapper
      style={{
        position: 'relative',
        minHeight: 'calc(100vh - 130px)',
        overflow: 'hidden',
      }}>
      {
        <div className={'title-kpi-list'}>
          {!isLoadingList
            ? `KPI tổng hợp đạt ${actualWeightQuarter}%`
            : 'Đang tải KPI...'}
        </div>
      }

      <div
        style={{
          display: 'flex',
          gap: 10,
          marginBottom: 12,
        }}>
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__KPI}>
          <Button
            block
            type={'dashed'}
            icon={<PlusOutlined style={{ color: blue }} />}
            onClick={() => setVisibleCreateModal(true)}
            style={{
              fontWeight: 500,
              textTransform: 'uppercase',
              borderColor: blue,
            }}>
            Thêm KPI
          </Button>
        </AccessControlAction>
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.edit__KPI}>
          <Tooltip
            title={
              !kpiStore.allowSaveKpi ? 'Số liệu tỉ trọng chưa hợp lệ!' : ''
            }>
            <Button
              block
              icon={<SaveOutlined style={{ color: '#237804' }} />}
              type={'dashed'}
              disabled={!kpiStore.allowSaveKpi}
              onClick={save}
              style={{
                fontWeight: 500,
                textTransform: 'uppercase',
                borderColor: blue,
                cursor: 'pointer',
              }}>
              Lưu lại
            </Button>
          </Tooltip>
        </AccessControlAction>
      </div>

      {kpiList.length > 0 ? (
        kpiList.map(kpi => (
          <div className={'kpi-item'} key={kpi.code}>
            <div className={'title-kpi-item'}>
              <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    history.push(`/kpi/view/${kpi_quarter_code}/${kpi.code}`)
                  }>
                  <SignalFilled
                    style={{
                      fontSize: 16,
                      margin: '0 6px 0 14px',
                      color: '#52c41a',
                    }}
                  />
                  {kpi.name}
                </div>
              </div>
              <div
                className={'title-right'}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <div className={'weight-number-kpi'}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 4,
                    }}>
                    <InputNumber
                      min={0}
                      max={100}
                      step={5}
                      addonAfter="%"
                      formatter={value => `${value}`}
                      // parser={value => value.replace('%', '')}
                      style={{
                        width: 86,
                        color: '#f5222d',
                        borderColor: `${
                          !validateInputExpectWeight.correct &&
                          kpi.code !== validateInputExpectWeight.kpiCode
                            ? '#fa8c16'
                            : '#d9d9d9'
                        }`,
                      }}
                      value={kpi.expect_weight}
                      onChange={value =>
                        onChangeExpectWeight(value ?? 0, kpi.code)
                      }
                    />
                    <Tooltip
                      title={
                        'Tổng số tỉ trọng cần đạt của các mục tiêu phải bằng 100%!'
                      }>
                      <WarningOutlined
                        style={{
                          color: '#fa8c16',
                          marginRight: 6,
                          opacity: `${
                            !validateInputExpectWeight.correct &&
                            kpi.code !== validateInputExpectWeight.kpiCode
                              ? 1
                              : 0
                          }`,
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div>
                    Đạt:
                    <span
                      style={{
                        color: '#389e0d',
                        fontWeight: 700,
                        marginLeft: 4,
                      }}>
                      {kpi.actual_weight}%
                    </span>
                  </div>
                </div>

                <AccessControlAction
                  aclActionType={ACL_ACTION_TYPE.delete__KPI}>
                  <Tooltip title={'Xóa'}>
                    <Popconfirm
                      placement="topRight"
                      title={'Bạn có chắc chắn muốn xóa KPI này không?'}
                      onConfirm={() => {
                        deleteKpi(kpi)
                      }}
                      okText="Xóa"
                      okType={'danger'}
                      cancelText="Không">
                      <Button
                        block
                        size={'small'}
                        danger
                        type="text"
                        style={{ fontWeight: 500, width: 30 }}
                        icon={<DeleteOutlined style={{ color: 'f5222d' }} />}
                      />
                    </Popconfirm>
                  </Tooltip>
                </AccessControlAction>
              </div>
            </div>

            <TargetList targetList={kpi.children || []} kpiId={kpi.code} />
          </div>
        ))
      ) : !isLoadingList ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={'Không có KPI nào!'}
        />
      ) : (
        <div />
      )}

      <KpiCreateModal
        isVisible={isVisibleCreateModal}
        handleCloseModal={() => setVisibleCreateModal(false)}
      />
      <SpinnerInlineComponent
        isLoading={isLoadingList}
        alignItems={'start'}
        tip={'Đang tải KPI...'}
      />
    </ContentBlockWrapper>
  )
}

KpiList.propTypes = {}

export default inject('kpiStore', 'authenticationStore')(observer(KpiList))

import React, { useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import {
  TableFooterActionWrapper,
  TableSelectUserWrapper,
} from './SelectUserDepartmentBoxTrucStyled'
import { Avatar, Input, message, Pagination, Space, Table } from 'antd'
import { subStringAvatar } from '../../Common/CellText'
import { apiUrl } from '../../../config'
import { blue } from '../../../color'
import { runInAction } from 'mobx'

const { Search } = Input

const SelectUserDepartmentBoxTruc = props => {
  const {
    userTrucStore,
    loadingAnimationStore,
    onSelectUser,
    rowSelection,
  } = props
  const { userListTruc, totalCountTruc, pageIndex, pageSize } = userTrucStore

  const tableColumn = useMemo(
    () => [
      {
        title: 'Thông tin',
        render: record => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              style={{ backgroundColor: blue }}
              src={record.imgId && `${apiUrl}/api/v1/images/${record.imgId}`}>
              {subStringAvatar(record.fullName)}
            </Avatar>
            <div style={{ marginLeft: 10 }}>{record.fullName}</div>
          </div>
        ),
      },
      {
        title: 'Tài khoản',
        render: record => record.userName,
      },
      {
        title: 'Email',
        render: record => record.email,
      },
    ],
    [userListTruc]
  )

  const handleChangePage = page => {
    userTrucStore.setFilter('pageIndex', page - 1)
    loadingAnimationStore.setTableLoading(true)
    userTrucStore
      .getUserListTruc()
      .finally(() => loadingAnimationStore.setTableLoading(false))
  }

  const onSearchByName = value => {
    userTrucStore.setFilter('pageIndex', 0)
    userTrucStore.setFilter('name', value && value.trim())
    loadingAnimationStore.setTableLoading(true)
    userTrucStore.getUserListTruc().finally(() => {
      loadingAnimationStore.setTableLoading(false)
    })
  }
  useEffect(() => {
    return () => {
      runInAction(() => {
        userTrucStore.userListTruc = []
        userTrucStore.name = null
      })
    }
  }, [])

  useEffect(() => {
    userTrucStore.setFilter('userOrFullname', null)
    userTrucStore.setFilter('isEnabled', true)
    ;(async () => {
      loadingAnimationStore.setTableLoading(true)
      try {
        await userTrucStore.getUserListTruc()
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
    // return () => userTrucStore.clearStore()
  }, [])

  return (
    <TableSelectUserWrapper>
      <Space style={{ marginBottom: 16 }}>
        <span>Tìm kiếm người dùng: </span>
        <Search
          allowClear
          onSearch={onSearchByName}
          placeholder={'Tìm kiếm người dùng theo tên...'}
          style={{ minWidth: 400, marginLeft: 6 }}
        />
      </Space>
      <Table
        dataSource={userListTruc}
        columns={tableColumn}
        rowKey={record => record.userName}
        pagination={false}
        loading={loadingAnimationStore.tableLoading}
        onRow={record => {
          return {
            onClick: () => onSelectUser(record),
          }
        }}
        rowSelection={{ ...rowSelection }}
      />
      <TableFooterActionWrapper>
        <Pagination
          current={pageIndex + 1}
          pageSize={pageSize}
          total={totalCountTruc}
          hideOnSinglePage={true}
          onChange={handleChangePage}
        />
      </TableFooterActionWrapper>
    </TableSelectUserWrapper>
  )
}

SelectUserDepartmentBoxTruc.propTypes = {}

export default inject(
  'userTrucStore',
  'loadingAnimationStore',
)(observer(SelectUserDepartmentBoxTruc))

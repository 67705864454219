import {
  BankOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import Checkbox from 'antd/es/checkbox/Checkbox'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { blue } from '../../../../color'
import { AvatarHasName } from '../../../../components/Common/Avatar'
import FileItem from '../../../../components/Common/FileItemComponent/FileItem'
import RichEditor from '../../../../components/RichEditor/RichEditor'
import SelectPeoplePopup from '../../../../components/SelectPeoplePopup'
import { ASSIGNEE_TYPE, PROPOSAL_TYPE } from '../../../../constants'
import utils from '../../../../utils'
import ApprovalList from '../../../Administrative/LeaveForm/ApproverListModal/Modal'
import {
  FormActionFooter,
  OriginFilesList,
  SelectList,
} from '../../../MissionEditPage/MissionEditPageStyled'
import { submissionMenu } from '../ProposalPage/ProposalPage'

const ProposalDetailUpdate = props => {
  const {
    proposalStore,
    selectPeopleStore,
    loadingAnimationStore,
    selectUserStore,
    fileStore,
  } = props

  const {
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    selectUserData,
    selectDepartmentData,
    selectGroupData,
  } = selectPeopleStore

  const [form] = Form.useForm()

  const CONTENT_REF = useRef()

  const [fileList, setFileList] = useState([])
  const [editorContent, setEditorContent] = useState()
  const [followerList, setFollowerList] = useState([])
  const [applyBieuMau, setApplyBieuMau] = useState(false)

  const { formInputTemplate, otherDetail, formProposalType } = proposalStore

  const [originFiles, setOriginFiles] = useState([])

  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (!otherDetail.id) return

    form.setFieldsValue({
      title: otherDetail.title,
      proposal_type: PROPOSAL_TYPE[otherDetail.proposal_type].label,
    })

    setEditorContent(otherDetail.reason)

    proposalStore.setFormProposalType(otherDetail.proposal_type)

    let followerList = []

    otherDetail.followers.forEach(follower => {
      if (follower?.user_name) {
        followerList.push({
          assignee_type: ASSIGNEE_TYPE.USER,
          full_name: follower.name_lowercase.toUpperCase(),
          id: follower.user_name,
          image_id: follower.image_id,
          permission: ASSIGNEE_TYPE.FOLLOWER,
        })
      }
    })

    if (followerList.length > 0) {
      selectPeopleStore.setSelectUserData({
        ...selectUserData,
        VIEW: followerList,
      })
    }

    setOriginFiles(otherDetail.attachments)
    ;(async () => {
      await proposalStore.getProposalTypeApprovalConfig(
        otherDetail.proposal_type
      )
    })()

    return () => {
      selectPeopleStore.clearSelectUserData('VIEW')
    }
  }, [])

  const handleChangeFile = useCallback(
    info => {
      const filteredFileList = info.fileList.filter(
        (elem, index, fileArray) =>
          fileArray.findIndex(file => file.name === elem.name) === index
      )
      setFileList(filteredFileList)
    },
    [fileList]
  )

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const onApplyBieuMauChange = value => {
    setApplyBieuMau(value)
    if (value) {
      if (formInputTemplate) {
        setEditorContent(formInputTemplate)
      }
    }
  }

  const onFinish = async values => {
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []

    const batchUploadArr = []

    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })

    const response = await Promise.all(batchUploadArr)

    const originFileList = (otherDetail.attachments || []).map(
      file => file.file_id
    )
    const afterFileList = [
      ...response.map(el => el.data.file_id),
      ...originFiles.map(file => file.file_id),
    ]

    let newAttachments = []
    let removeAttachments = []

    afterFileList.forEach(file => {
      if (!originFileList.includes(file)) {
        newAttachments.push(file)
      }
    })
    originFileList.forEach(file => {
      if (!afterFileList.includes(file)) {
        removeAttachments.push(file)
      }
    })

    const removeFollowers = otherDetail.followers.filter(follower => {
      if (!followerList.some(item => item.id === follower.user_name)) {
        return follower
      }
    })

    const newFollowers = followerList.filter(follower => {
      if (!otherDetail.followers.some(item => item.user_name === follower.id)) {
        return follower
      }
    })

    let proposal = {
      ...values,
      code: otherDetail.proposal_code,
      title: values.title,
      proposal_type: otherDetail.proposal_type,
      attachments: {
        remove_items: removeAttachments,
        new_items: newAttachments,
      },
      assignees: {
        remove_items: removeFollowers.map(follower => ({
          assignee_code: follower.user_name,
          assignee_type: 'USER',
          permission: 'VIEW',
        })),
        new_items: newFollowers.map(follower => ({
          assignee_code: follower.id,
          assignee_type: 'USER',
          permission: 'VIEW',
        })),
      },
      reason: CONTENT_REF.current.editor.getData(),
    }

    try {
      setIsUpdating(true)
      await proposalStore.updateProposal(proposal)
      await proposalStore.getOtherDetail(proposal.code)
    } catch (error) {
      message.error(error.vi || 'Có lỗi xảy ra')
      console.log(error)
    } finally {
      setIsUpdating(false)
      props.onCancel()
    }
  }

  const showSubmissionMenu = useMemo(() => {
    return submissionMenu.map((unit, index) => {
      return (
        <Select.OptGroup
          label={
            <b
              style={{
                fontSize: 15,
                fontWeight: 500,
                color: '#000',
              }}>
              {unit.name_group}
            </b>
          }
          key={unit.id_group}>
          {submissionMenu[index].child.map(child => {
            return (
              <Select.Option value={child.id_child} key={child.id_child}>
                {child.name_child}
              </Select.Option>
            )
          })}
        </Select.OptGroup>
      )
    })
  }, [])

  return (
    <>
      <Form
        scrollToFirstError={true}
        form={form}
        name={'phieu-trinh-khac-form'}
        layout={'vertical'}
        onFinish={onFinish}>
        <Row gutter={15}>
          <Col xs={24} lg={24}>
            <Form.Item name={'proposal_type'} label={'Loại:'}>
              <Select
                disabled
                placeholder="Chọn loại"
                value={formProposalType?.value}>
                {showSubmissionMenu}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              name={'title'}
              label={'Tiêu đề:'}
              rules={[
                { required: true, message: 'Vui lòng nhập tiêu đề' },
                {
                  max: 500,
                  message: 'Tiêu đề không được dài quá 500 kí tự!',
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={
                <div>
                  <p>Nội dung:</p>
                  <Checkbox
                    checked={applyBieuMau}
                    onChange={e => onApplyBieuMauChange(e.target.checked)}>
                    <span>Áp dụng Biểu mẫu</span>
                  </Checkbox>
                </div>
              }>
              <RichEditor
                key={editorContent}
                EDITOR_REF={CONTENT_REF}
                placeholder={'Nhập nội dung'}
                editorContent={editorContent}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item name={'file_ids'} label={'Tài liệu đính kèm:'}>
              <Upload
                valuePropName={'fileList'}
                fileList={fileList}
                multiple={true}
                onChange={handleChangeFile}
                beforeUpload={() => false}
                iconRender={() => <FileTextOutlined />}>
                <Button htmlType={'button'}>
                  <UploadOutlined />
                  Chọn tài liệu đính kèm
                </Button>
              </Upload>
            </Form.Item>

            <OriginFilesList>
              {originFiles.map(file => (
                <div className={'file'} key={file.file_id}>
                  <FileItem
                    file_id={file.file_id}
                    file_name={file.file_name}
                    file_type={utils.getExtensionFile(file.file_name)}
                  />
                  <span
                    className={'delete-icon'}
                    onClick={() =>
                      setOriginFiles(
                        originFiles.filter(el => el.file_id !== file.file_id)
                      )
                    }>
                    <DeleteOutlined />
                  </span>
                </div>
              ))}
            </OriginFilesList>
          </Col>
          <Col xs={24} lg={12}>
            <label
              style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>
              Người theo dõi:
            </label>

            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
                  }
                />
              </Tooltip>
            </Space>

            {followerList.length > 0 && (
              <SelectList>
                {followerList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={
                        (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                          <BankOutlined />
                        )) ||
                        (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                          <TeamOutlined />
                        ))
                      }
                    />
                  </Tag>
                ))}
              </SelectList>
            )}
          </Col>
        </Row>

        <ApprovalList />
        <SelectPeoplePopup
          disableSelectDepartment
          handleCancelSelectUser={handleCancelSelectUserDepartment}
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />

        <FormActionFooter>
          <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
            Huỷ bỏ
          </Button>
          <Button
            loading={isUpdating}
            style={{ marginLeft: 10 }}
            icon={<CheckOutlined />}
            type={'primary'}
            htmlType={'submit'}>
            Đồng ý chỉnh sửa
          </Button>
        </FormActionFooter>
      </Form>
    </>
  )
}

ProposalDetailUpdate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  showSubmissionMenu: PropTypes.func,
}
export default inject(
  'proposalStore',
  'selectUserStore',
  'loadingAnimationStore',
  'selectPeopleStore',
  'fileStore',
)(observer(ProposalDetailUpdate))

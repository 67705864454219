import React, { memo, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, message, Spin } from 'antd'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import PageTitle from '../../components/PageTitle'
import MyFirstGrid from './MyFirstGrid'
import InfiniteScroll from 'react-infinite-scroll-component'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import { ACL_ACTION_TYPE } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction'

const TinTucPage = props => {
  const ref = useRef(null)
  let [width, setWidth] = useState(0)

  const { loadingAnimationStore, tintucStore, history } = props
  const { newsList, tinTucTotalCount } = tintucStore

  const [isLoadingTinTuc, setIsLoadingTinTuc] = useState(false)

  const loadTinTuc = async () => {
    try {
      setIsLoadingTinTuc(true)
      await tintucStore.getAllNews()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingTinTuc(false)
    }
  }

  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0)
  }, [ref.current])

  useEffect(() => {
    ;(async () => {
      await loadTinTuc()
    })()
    return () => {
      tintucStore.clear()
    }
  }, [])

  const onItemClick = tinTuc => {
    // history.push(`/general-notifications/update/${tinTuc.id}`)
  }

  const onClickEdit = id => {
    history.push(`/utility/general-notifications/update/${id}`)
  }

  const onClickView = id => {
    history.push(`/utility/general-notifications/view/${id}`)
  }

  const onClickDelete = async id => {
    try {
      await tintucStore.delete(id)
      tintucStore.clear()
      await loadTinTuc()
    } catch (err) {
      console.log(err)
      message.success(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const [hasMore, setHasMore] = useState(true)
  const fetchMoreData = async () => {
    const scrollBox = document.getElementById('infinityScrollableDiv')
    if (newsList.length >= tinTucTotalCount) {
      setHasMore(false)
      scrollBox.scrollTo(
        0,
        scrollBox.offsetHeight * tintucStore.tintucQueryParams.page
      )
      return
    }
    tintucStore.setTintucQueryParams({
      size: 10,
      page: tintucStore.tintucQueryParams.page + 1,
    })
    try {
      await tintucStore.getAllNews()
      scrollBox.scrollTo(
        0,
        scrollBox.offsetHeight * tintucStore.tintucQueryParams.page
      )
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    }
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Thông báo chung</title>
      </Helmet>
      <PageTitle location={props.location} title="Thông báo chung">
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__NEWS}>
          <Button
            onClick={() => {
              history.push('/utility/general-notifications/create')
            }}
            type={'primary'}>
            Đăng thông báo
          </Button>
        </AccessControlAction>
      </PageTitle>
      {isLoadingTinTuc && (
        <ContentBlockWrapper style={{ height: 400, position: 'relative' }}>
          <SpinnerInlineComponent
            sizeSpin={'small'}
            isLoading={true}
            noBackground
            tip={'Đang tải...'}
          />
        </ContentBlockWrapper>
      )}
      <InfiniteScroll
        dataLength={newsList.length}
        next={fetchMoreData}
        hasMore={hasMore}
        style={{
          scrollSnapStop: 'always',
        }}
        scrollableTarget="infinityScrollableDiv"
        loader={
          <div
            style={{
              paddingTop: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {newsList.length > 10 ? <Spin tip="Đang tải..." /> : ''}
          </div>
        }>
        <div ref={ref}>
          {width > 0 && (
            <MyFirstGrid
              parentWidth={width}
              onItemClick={onItemClick}
              onClickView={onClickView}
              onClickEdit={onClickEdit}
              onClickDelete={onClickDelete}
            />
          )}
        </div>
      </InfiniteScroll>
    </DashboardLayout>
  )
}

TinTucPage.propTypes = {}

export default memo(
  inject(
    'tintucStore',
    'loadingAnimationStore',
    'authenticationStore',
    'commonStore',
    'history'
  )(observer(TinTucPage))
)

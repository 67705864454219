import { observable } from 'mobx'
import { InternalDocumentRequest } from '../../requests/InternalDocumentRequest'

class UnreadDocStore {
  @observable isLoading = false

  markAsRead = async documentCodes => {
    this.isLoading = true

    await InternalDocumentRequest.markAsRead(documentCodes)

    this.isLoading = false
  }

  clear = () => {
    console.log('UnreadDocStore. clear')
    this.isLoading = false
  }
}

export default new UnreadDocStore()

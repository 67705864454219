import React from 'react'
import { Spin } from 'antd'
import { inject, observer } from 'mobx-react'
import { LoadingOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'


const SpinnerInlineComponent = props => {
  const {
    loadingAnimationStore,
    sizeSpin,
    isLoading,
    alignItems,
    tip,
    noBackground,
    backGround,
    style,
  } = props

  const { showSpinInline } = loadingAnimationStore

  const styleSpinWrapper = {
    textAlign: 'center',
    background: noBackground ? 'unset' : backGround? backGround: 'rgba(44, 101, 172, 0.3)',
    position: 'absolute',
    zIndex: 29,
    inset: 0,
    display: showSpinInline || isLoading ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: alignItems || 'center',
    paddingTop: alignItems ? 100 : 0,
    ...style,
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  return (
    <div style={{ ...styleSpinWrapper }}>
      <Spin
        size={sizeSpin || 'middle'}
        tip={tip || ''}
        indicator={antIcon}
      />
    </div>
  )
}

SpinnerInlineComponent.propTypes = {sizeSpin: PropTypes.string,noBackground:PropTypes.any ,style: PropTypes.object,isLoading:PropTypes.bool,tip:PropTypes.string,backGround:PropTypes.string}

export default inject('loadingAnimationStore')(observer(SpinnerInlineComponent))

import React, { useEffect } from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import {
  EmptyText,
  TitleContentBox,
  WordBreak,
} from '../../../components/Common/CellText'
import { MessageInfo } from './InternalMessageSendDetailPageStyled'
import moment from 'moment'
import { ASSIGNEE_TYPE, DATE_FORMAT_SLASH, HH_mm } from '../../../constants'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import { message } from 'antd'
import FileItem from '../../../components/Common/FileItemComponent/FileItem'
import utils from '../../../utils'
import ReplyMessageInternal from '../../../components/ReplyMessageInternal'

const InternalMessageDetailPage = props => {
  const {
    location,
    internalMessageStore,
    match,
    history,
    loadingAnimationStore,
  } = props
  const { internalMessageDetail, replyInternalMessages } = internalMessageStore

  const receiverList = internalMessageDetail.receiver
    ? internalMessageDetail.receiver.map(item => ({
        id: item.code,
        full_name: item.fullname,
        assignee_type: ASSIGNEE_TYPE.USER,
        last_date_read: item.is_read? item.updated_time : null,
      }))
    : []

  const { code } = match.params

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        await internalMessageStore.getSendMessageById(code)
      } catch (error) {
        history.push('/utility/internal-message/send')
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => internalMessageStore.clearMessageStore()
  }, [code])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tin nhắn liên thông | VIMC Portal</title>
      </Helmet>
      <PageTitle
        location={location}
        showTitle={true}
        title={internalMessageDetail.title}
      />
      <ContentBlockWrapper>
        <TitleContentBox>Thông tin</TitleContentBox>
        <MessageInfo>
          <dt>Người nhận</dt>
          <dd>
            <div className={'assign'}>
              {receiverList.length > 0 ? (
                <PopoverWatchTime
                  showFullTag={true}
                  userList={receiverList}
                  title={'Danh sách người nhận'}
                />
              ) : (
                <EmptyText>Không rõ.</EmptyText>
              )}
            </div>
          </dd>
          <dt>Ngày gửi</dt>
          <dd>
            {internalMessageDetail.created_time ? (
              `${moment(internalMessageDetail.created_time).format(
                DATE_FORMAT_SLASH
              )} - ${moment(internalMessageDetail.created_time).format(HH_mm)}`
            ) : (
              <EmptyText>Không rõ.</EmptyText>
            )}
          </dd>
          <dt>Nội dung</dt>
          <dd>
            {internalMessageDetail.content ? (
              WordBreak(internalMessageDetail.content, true)
            ) : (
              <EmptyText>Không rõ.</EmptyText>
            )}
          </dd>
          <dt>Tài liệu đính kèm</dt>
          <dd>
            {internalMessageDetail.file_ids?.length > 0 ? (
              internalMessageDetail.file_ids.map(file => (
                <FileItem
                  key={file.file_id}
                  file_id={file.file_id}
                  file_name={file.name}
                  file_type={utils.getExtensionFile(file.name)}
                />
              ))
            ) : (
              <EmptyText>Không có.</EmptyText>
            )}
          </dd>
        </MessageInfo>
      </ContentBlockWrapper>
      <ReplyMessageInternal
          replyInternalMessages={replyInternalMessages}
          code={code}
          internalMessageDetail={internalMessageDetail}
          isReceive={false}
        />
    </DashboardLayout>
  )
}

InternalMessageDetailPage.propTypes = {}

export default inject(
  'internalMessageStore',
  'selectUserStore',
  'loadingAnimationStore',
)(observer(InternalMessageDetailPage))

import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const ISODocumentRequest = {
  getFolderDetails: (folder_id, type) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/iso_document/${folder_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        ...(!utils.isNullish(type) && { type: type }),
      },
    }),
  createFolder: (folder_name, parent_id) =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/iso_document`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        name: folder_name,
        parentId: parent_id,
      },
    }),
  search: (folder_id, keyword) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/iso_document/search`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        folder_id: folder_id,
        keyword: keyword,
      },
    }),
  move: (itemSrcId, folderDesId) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/cloud/document/move`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        itemSrcId: itemSrcId,
        folderDesId: folderDesId,
      },
    }),
}

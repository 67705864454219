import React, { useState } from 'react'
import { ListWrapper } from '../../ManageDepartment/SortDepartmentModal/SortDepartmentModalStyled'
import {
  arrayMove,
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { runInAction } from 'mobx'
import { ApprovalLevel } from './ApproveTemplateStyled'
import { Button, Col, Modal, Row } from 'antd'
import ApprovalLevelEdit from './ApprovalLevelEdit'

const ApprovalTemplateSortableLevels = props => {
  const { approvalTemplateStore, selectPeopleStore } = props
  const { approvalTemplateSortableLevels } = approvalTemplateStore

  const [isVisibleEditLevelModal, setIsVisibleEditLevelModal] = useState(false)
  const [selectedLevel, setSelectedLevel] = useState({})

  const handleOpenEditLevelModal = level => event => {
    setIsVisibleEditLevelModal(true)
    setSelectedLevel(level)
  }

  const handleCancelEditLevelModal = () => {
    setIsVisibleEditLevelModal(false)
    selectPeopleStore.clearSelected()
  }

  const SortableItem = sortableElement(({ value }) => {
    const handleDeleteLevel = delLevel => event => {
      const mapped = approvalTemplateStore.approvalTemplateSortableLevels.map(
        item => {
          if (item.code === delLevel.code) {
            return {
              ...item,
              is_deleted: true,
            }
          }

          return item
        }
      )

      runInAction(() => {
        approvalTemplateStore.approvalTemplateSortableLevels = mapped
      })
    }

    return (
      <ApprovalLevel>
        <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
          <Col span={2}>
            <DragHandle />
          </Col>

          <Col span={18} className="approval-level__content">
            <dt>Tên</dt>
            <dd>{value?.name}</dd>
            <dt>Người xử lý</dt>
            <dd style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {value?.handler?.full_name}
              {value.allow_edit && <SyncOutlined size={20} />}
            </dd>
          </Col>

          <Col span={4} className="approval-level__action">
            <Button
              icon={<EditOutlined size={20} />}
              onClick={handleOpenEditLevelModal(value)}
            />
            <Button
              icon={<DeleteOutlined size={20} />}
              onClick={handleDeleteLevel(value)}
            />
          </Col>
        </Row>
      </ApprovalLevel>
    )
  })

  const SortableContainer = sortableContainer(({ children }) => {
    return <ListWrapper>{children}</ListWrapper>
  })

  const DragHandle = sortableHandle(() => (
    <span>
      <HolderOutlined />
    </span>
  ))

  const onSortEnd = props => {
    runInAction(() => {
      approvalTemplateStore.approvalTemplateSortableLevels = arrayMove(
        approvalTemplateSortableLevels.filter(level => !level.is_deleted),
        props.oldIndex,
        props.newIndex
      ).concat(approvalTemplateSortableLevels.filter(level => level.is_deleted))
    })
  }

  return (
    <>
      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
        {approvalTemplateSortableLevels
          .filter(level => !level.is_deleted)
          .map((value, index) => {
            return <SortableItem key={value.code} index={index} value={value} />
          })}
      </SortableContainer>

      <Modal
        width={800}
        title={'Sửa cấp duyệt'}
        visible={isVisibleEditLevelModal}
        maskClosable={false}
        onCancel={handleCancelEditLevelModal}
        footer={null}
        destroyOnClose>
        <ApprovalLevelEdit
          level={selectedLevel}
          onCancel={handleCancelEditLevelModal}
        />
      </Modal>
    </>
  )
}

export default inject(
  'approvalTemplateStore',
  'selectPeopleStore',
)(observer(ApprovalTemplateSortableLevels))

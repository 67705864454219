import React from 'react'
import { ItemBg, ItemSubject } from '../TinTuc/GridTinTucStyled'
import { Col, Divider, Row } from 'antd'
import { EmptyText, WordBreak } from '../../components/Common/CellText'
import { UploadFileListWrapper } from '../../components/CommentComponent/CommentComponentStyled'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import utils from '../../utils'
import { inject, observer } from 'mobx-react'

const TinTucDetail = props => {
  const { tintucStore } = props

  const { newDetails } = tintucStore

  return (
    <ItemBg>
      <Row wrap={false}>
        <Col flex="auto">
          <ItemSubject>{newDetails.subject}</ItemSubject>
        </Col>
      </Row>
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      {WordBreak(newDetails.content, true)}
      <div style={{ display: 'flex' }}>
        <span style={{ margin: '10px 10px 15px 0px', fontWeight: 'bold' }}>
          Tài liệu đính kèm:
        </span>
        <UploadFileListWrapper>
          {newDetails.attachments ? (
            newDetails.attachments.map(file => (
              <FileItem
                key={file.file_id}
                file_id={file.file_id}
                file_name={file.file_name}
                file_type={utils.getExtensionFile(file.file_name)}
              />
            ))
          ) : (
            <EmptyText>Không có tài liệu đính kèm.</EmptyText>
          )}
        </UploadFileListWrapper>
      </div>
    </ItemBg>
  )
}

TinTucDetail.propTypes = {}

export default inject('tintucStore')(observer(TinTucDetail))

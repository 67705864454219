import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import utils from '../utils'

export const ConnectedCommitteeDocumentRequest = {
  // Incoming
  getIncomingCommitteeDocumentList: (
    page,
    size,
    bussiness_doc_type,
    keyword,
    status
  ) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/edocs/received`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
        ...(!utils.isNullish(bussiness_doc_type) && {
          bussiness_doc_type: bussiness_doc_type,
        }),
        ...(!utils.isNullish(keyword) && { keyword: keyword }),
        ...(!utils.isNullish(status) && { status: status.toString() }),
      },
    }),
  getIncomingCommitteeDocumentById: doc_id =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/edocs/received/${doc_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getSelectedIncomingCommitteeDocumentEdxml: doc_id =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/edocs/received/${doc_id}/edxml`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },

      responseType: 'arraybuffer',
    }),
  getSelectedIncomingCommitteeDocumentAttachment: (doc_id, filename) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/edocs/received/${doc_id}/attachments/${filename}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        doc_id: doc_id,
        filename: filename,
      },
      responseType: 'blob',
    }),
  updateStatusIncomingCommitteeDocumentById: (doc_id, edocStatusRequest) =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/edocs/received/${doc_id}/status`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: edocStatusRequest,
    }),
  // Outgoing
  getOutgoingCommitteeDocumentList: (
    page,
    size,
    bussiness_doc_type,
    keyword,
    status
  ) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/edocs/sent`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
        ...(!utils.isNullish(bussiness_doc_type) && {
          bussiness_doc_type: bussiness_doc_type,
        }),
        ...(!utils.isNullish(keyword) && { keyword: keyword }),
        ...(!utils.isNullish(status) && { status: status.toString() }),
      },
    }),
  getOutgoingCommitteeDocumentById: doc_id =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/edocs/sent/${doc_id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {},
    }),
  getSelectedOutgoingCommitteeDocumentEdxml: doc_id =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/edocs/sent/${doc_id}/edxml`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },

      responseType: 'arraybuffer',
    }),
  getSelectedOutgoingCommitteeDocumentAttachment: (doc_id, filename) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/edocs/sent/${doc_id}/attachments/${filename}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        doc_id: doc_id,
        filename: filename,
      },
      responseType: 'blob',
    }),
  createOutgoingCommitteeDocument: documentInfo =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/edocs/sent`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: documentInfo,
    }),
  revocationOutgoingCommitteeDocument: documentInfo =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/edocs/sent/revocation`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: documentInfo,
    }),
  replaceOutgoingCommitteeDocument: documentInfo =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/edocs/sent/replacement`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: documentInfo,
    }),
  updateOutgoingCommitteeDocument: documentInfo =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/edocs/sent/update`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: documentInfo,
    }),
  updateStatusOutgoingCommitteeDocumentById: (doc_id, edocStatusRequest) =>
    axios({
      method: 'patch',
      url: `${apiUrl}/api/v1/edocs/sent/${doc_id}/status`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: edocStatusRequest,
    }),
}

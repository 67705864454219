import { action, observable } from 'mobx'
// Request
import { RoleRequest } from '../requests/RoleRequest'

class RoleStore {
  /** Roles */
  @observable roleList = []
  @action getRoles = () => {
    return new Promise((resolve, reject) => {
      RoleRequest.getRoles()
        .then(response => {
          this.roleList = response.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export default new RoleStore()

import styled from 'styled-components'
import { blue } from '../../color'
import { Modal } from 'antd'

export const SalaryFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  .btn-group-left {
    display: flex;
    align-items: flex-end;
    gap: 12px;
    margin-bottom: 10px;
  }

  .label-date-picker {
    margin-bottom: 4px;
  }
`

export const SalaryStepWrapper = styled.div`
  margin-bottom: 10px;
  .salary-title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500;
    color: #e67e22;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-delete {
    transform: translateY(-5px);
    padding: 0 8px 2px 8px;
    border-radius: 5px;
    background-color: #fafafa;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }

  .salary-step {
    max-width: 600px;
    margin: 0 auto;
  }
  .description-step {
    color: #000000;
    text-transform: capitalize;
  }

  .ant-steps-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-steps-item-description {
    width: 250px;
    text-align: center;
  }
`

export const SalaryListWrapper = styled.div`
  flex-grow: 1;
  transition: 0.5s;
  max-width: ${props =>
    props.isShowSalaryInfo ? 'calc(100% - 380px)' : '100%'};
  margin-right: ${props => (props.isShowSalaryInfo ? '20px' : '0px')};
  position: relative;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  .salary-total {
    font-weight: bold;
    text-align: center;
  }
  .salary-total-content {
    display: flex;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    div {
      flex: 0 0 150px;
    }
  }
  .ant-tabs-tab {
    width: 250px;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 500;
  }
`

export const SalaryListDepartmentWrapper = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  margin-bottom: 16px;

  .title-table {
    padding-left: 10px;
    color: ${blue};
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
`

export const SalaryListDepartmentListWrapper = styled.div`
  .ant-table-thead > tr > th {
    background-color: #d9e9f7;
  }
  .row-table {
    cursor: pointer;
  }
`

export const SalaryListTableWrapper = styled.div``

export const ModalSalaryDetailWrapper = styled(Modal)`
  .row-card {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 6px;
    margin-bottom: 8px;
    .label-card {
      flex: 0 0 250px;
      width: 250px;
    }
    .content-row-card {
      flex-grow: 1;
    }
  }
  .row-card-end {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .ant-card-head {
    background-color: #d9e9f7 !important;
  }
  .ant-card-body {
    background-color: #fcfcfc;
  }
`
export const SalaryManagerWrapper = styled.div`
  .ant-table-thead > tr > th {
    background-color: #d9e9f7;
  }
  .row-table {
    cursor: pointer;
  }
`

import React, { useEffect, useState } from 'react'
import { Button, Form, message, Modal } from 'antd'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { ListWrapper, SortItem } from './SortDepartmentModalStyled'
import { HolderOutlined } from '@ant-design/icons'
import departmentStore from '../../../stores/departmentStore'

const SortDepartmentModal = props => {
  const {
    isVisibleSortDepartmentModal,
    handleCancelSortDepartmentModal,
    departmentList,
    onRefreshListDepartment,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState(departmentList)

  useEffect(() => {
    if (isVisibleSortDepartmentModal) {
      setItems(departmentList)
    }
  }, [isVisibleSortDepartmentModal])

  const DragHandle = sortableHandle(() => (
    <span style={{ marginRight: 8 }}>
      <HolderOutlined />
    </span>
  ))

  const SortableItem = sortableElement(({ value }) => (
    <SortItem>
      <DragHandle />
      {value}
    </SortItem>
  ))

  const SortableContainer = sortableContainer(({ children }) => {
    return <ListWrapper>{children}</ListWrapper>
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex))
  }

  const save = async () => {
    try {
      setIsLoading(true)
      await departmentStore.sort(items.map(item => item.code))

      onRefreshListDepartment()

      message.success('Sắp xếp thứ tự phòng ban thành công')

      handleCancelSortDepartmentModal()
    } catch (e) {
      console.log(e)
      message.error(e.vi || 'Có sự cố xảy ra')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={`Sắp xếp thứ tự hiển thị`}
      footer={null}
      closable={true}
      visible={isVisibleSortDepartmentModal}
      onCancel={handleCancelSortDepartmentModal}>
      <i>Kéo giữ và nhả để sắp xếp</i>
      <Form layout={'vertical'}>
        <Form.Item>
          <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {items.map((value, index) => (
              <SortableItem key={value.code} index={index} value={value.name} />
            ))}
          </SortableContainer>
        </Form.Item>

        <Form.Item>
          <Button
            style={{ float: 'right' }}
            loading={isLoading}
            onClick={save}
            type={'primary'}>
            Cập nhật
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

SortDepartmentModal.propTypes = {}

export default SortDepartmentModal

import { action, observable } from 'mobx'
import ApprovalTemplateRequest from '../requests/ApprovalTemplateRequest'

class ApprovalTemplateStore {
  @observable approvalTemplateSortableLevels = []

  @observable approvalTemplateList = []

  @observable approvalTemplateDetail = {
    code: null,
    name: null,
    description: null,
    approval_levels: [],
  }

  @action getApprovalTemplateList = () => {
    return new Promise((resolve, reject) => {
      ApprovalTemplateRequest.getApprovalTemplateList()
        .then(response => {
          this.approvalTemplateList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getApprovalTemplateDetail = code => {
    return new Promise((resolve, reject) => {
      ApprovalTemplateRequest.getApprovalTemplateDetail(code)
        .then(response => {
          this.approvalTemplateDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createApprovalTemplate = payload => {
    return new Promise((resolve, reject) => {
      ApprovalTemplateRequest.createApprovalTemplate(payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action updateApprovalTemplate = (code, payload) => {
    return new Promise((resolve, reject) => {
      ApprovalTemplateRequest.updateApprovalTemplate(code, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action applyApprovalTemplate = (code, payload) => {
    return new Promise((resolve, reject) => {
      ApprovalTemplateRequest.applyApprovalTemplate(code, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action delete = code => {
    return ApprovalTemplateRequest.delete(code)
  }

  @action clearApprovalTemplateDetail = () => {
    this.approvalTemplateDetail = {
      code: null,
      name: null,
      description: null,
      approval_levels: [],
    }
  }

  @action setApprovalTemplateSortableLevels = payload => {
    this.approvalTemplateSortableLevels = payload
  }

  @action clearApprovalTemplateSortableLevels = () => {
    this.approvalTemplateSortableLevels = []
  }
}

export default new ApprovalTemplateStore()

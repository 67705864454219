import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { ListPositionContent, TabsPositionWrapper } from './PositionStyled'
import {
  TextWrap,
  trimOverlengthString,
} from '../../components/Common/CellText'
import TableComponent from '../../components/Common/TableComponent'
import uuid from 'react-uuid'
import {
  Avatar,
  Button,
  Comment,
  message,
  Pagination,
  Popconfirm,
  Space,
  Tabs,
} from 'antd'
import { inject, observer } from 'mobx-react'
import authenticationStore from '../../stores/authenticationStore'
import { apiUrl } from '../../config'
import {
  DeleteTwoTone,
  EyeOutlined,
  OrderedListOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import CreatePositonModal from './CreatePositionModal'
import {
  ButtonItem,
  ButtonItemWrapper,
} from '../UserGroupPage/ListUserGroup/ListUserGroupStyled'
import { find } from 'lodash-es'
import { MODAL_MODE } from '../../ui_constants'
import roleStore from '../../stores/roleStore'
import SortPositionodal from './SortPositionModal/SortPositionModal'
import { ACL_ACTION_TYPE } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import UserOverview from '../UserProfilePage/UserOverview'

const { TabPane } = Tabs

const PositionPage = props => {
  const { positionStore, userStore, loadingAnimationStore } = props

  const { positionList } = positionStore

  const {
    userOfPositionListTotalCount,
    userOfPositionListPageIndex,
    userOfPositionListPageSize,
  } = userStore

  const [userOfPositionData, setUserOfPositionData] = useState([])
  const [currentPosition, setCurrentPosition] = useState()

  //create/edit Position modal
  const [
    isVisibleCreatePositionModal,
    setIsVisibleCreatePositionModal,
  ] = useState(false)
  const [mode, setMode] = useState(null)

  //edit user
  const [isVisibleUser, setIsVisibleUser] = useState(false)
  //sort Position
  const [isVisibleSortPositionModal, setIsVisibleSortPositionModal] = useState(
    false
  )

  const tableColumn = [
    {
      title: 'Thông tin',
      width: 248,
      render: record => (
        <Comment
          author={
            <span style={{ textTransform: 'capitalize' }}>
              {record.name_lowercase}
            </span>
          }
          avatar={
            <Avatar
              style={{ backgroundColor: '#1890ff' }}
              src={
                record.imageId && `${apiUrl}/api/v1/images/${record.imageId}`
              }>
              {record?.name_uppercase.split('')[0].toString().toUpperCase()}
            </Avatar>
          }
        />
      ),
    },
    {
      title: 'ID',
      width: 100,
      render: record => record?.username,
    },
    {
      title: 'Phân quyền',
      width: 160,
      render: record => record?.acl_group?.name ?? 'Mặc định',
    },
    {
      title: 'Trạng thái',
      render: record => (record?.status ? 'Đang hoạt động' : 'Dừng hoạt động'),
    },
    {
      fixed: 'right',
      align: 'center',
      title: (
        <ButtonItemWrapper>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.edit__CHUC_DANH}>
            <ButtonItem title={'Chỉnh sửa chức danh'}>
              <SettingOutlined
                style={{ color: '#40A9FF' }}
                onClick={() => {
                  setIsVisibleCreatePositionModal(true)
                  setMode(MODAL_MODE.UPDATE)
                }}
              />
            </ButtonItem>
          </AccessControlAction>

          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.delete__CHUC_DANH}>
            <Popconfirm
              onConfirm={() => handleDelete()}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xóa chức danh này không?'}>
              <ButtonItem title={'Xóa'}>
                <DeleteTwoTone
                  twoToneColor={'#e74c3c'}
                  onClick={event => event.stopPropagation()}
                />
              </ButtonItem>
            </Popconfirm>
          </AccessControlAction>
        </ButtonItemWrapper>
      ),
      render: record => (
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.view__CHUC_DANH}>
          <Button onClick={() => handleViewUserInfo(record)} type="primary">
            <EyeOutlined />
             Chi tiết
          </Button>
        </AccessControlAction>
      ),
    },
  ]

  const handleViewUserInfo = useCallback(
    userInfo => {
      userStore.setSelectedUser(userInfo)
      setIsVisibleUser(true)
    },
    [positionStore, loadingAnimationStore, userStore]
  )

  const onChangeTabPosition = async key => {
    try {
      loadingAnimationStore.setTableLoading(true)
      userStore.setFilter('userOfPositionListByPositionCode', key)
      userStore.setFilter('userOfPositionListPageIndex', 0)
      await loadUserList()

      setCurrentPosition(find(positionList, { code: key }))
    } catch (e) {
      console.log(e)
      message.error(e.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const loadUserList = async () => {
    let response = await userStore.getUserListByPosition()
    setUserOfPositionData(response.data)
  }

  const onChangePagination = async (pageIndex, pageSize) => {
    try {
      loadingAnimationStore.setTableLoading(true)

      userStore.setFilter('userOfPositionListPageIndex', pageIndex - 1)
      userStore.setFilter('userOfPositionListPageSize', pageSize)

      let response = await userStore.getUserListByPosition()
      setUserOfPositionData(response.data)
    } catch (e) {
      console.log(e)
      message.warning('Lỗi lấy thông tin người dùng trong chức danh!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const handleDelete = async () => {
    if (userOfPositionListTotalCount === 0) {
      try {
        loadingAnimationStore.showSpinner(true)
        await positionStore.delete(currentPosition.code)

        await positionStore.getPositionList()
        let positionListUpdate = positionStore.positionList
        if (positionListUpdate.length > 0) {
          onChangeTabPosition(positionListUpdate[0].code)
        }
        message.success('Xóa chức danh thành công')
      } catch (e) {
        console.log(e)
        message.error(e.vi || 'Đã có sự cố xảy ra')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    } else {
      message.warning('Không thể xóa chức danh đã có nhân viên')
    }
  }

  const showCreatePositionModal = () => {
    setIsVisibleCreatePositionModal(true)
    setMode(MODAL_MODE.CREATE)
  }

  const handleCloseCreatePositionModal = () => {
    setIsVisibleCreatePositionModal(false)
    setMode(null)
  }

  const handleCancelViewUser = () => {
    setIsVisibleUser(false)
  }

  const showSortPositionModal = () => {
    setIsVisibleSortPositionModal(true)
  }

  const handleCancelSortPositionModal = () => {
    setIsVisibleSortPositionModal(false)
  }

  const onRefreshListPosition = async () => {
    loadingAnimationStore.showSpinner(true)
    await positionStore.getPositionList(
      authenticationStore.currentCompanyCode()
    )
    loadingAnimationStore.showSpinner(false)
  }

  useEffect(() => {
    if (authenticationStore.currentUser) {
      loadingAnimationStore.showSpinner(true)
      Promise.all([
        //for create user account
        positionStore.getPositionList(),
        roleStore.getRoles(),
      ])
        .then(async responses => {
          let firstDept = responses[0].data[0]
          setCurrentPosition(firstDept)

          userStore.setFilter(
            'userOfPositionListByPositionCode',
            firstDept.code
          )
          let response = await userStore.getUserListByPosition()
          setUserOfPositionData(response.data)
        })
        .catch(e => {
          console.log(e)
          message.warning('Lỗi lấy thông tin chức danh!').then()
        })
        .finally(() => {
          loadingAnimationStore.showSpinner(false)
        })
    }

    return () => {
      positionStore.clearPositionList()
    }
  }, [authenticationStore.currentUser])

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Quản lý chức danh</title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Quản lý chức danh'}
          hiddenGoBack>
          <Space direction={'horizontal'}>
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.sort__CHUC_DANH}>
              <Button
                icon={<OrderedListOutlined />}
                onClick={showSortPositionModal}>
                Sắp xếp
              </Button>
            </AccessControlAction>
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.create__CHUC_DANH}>
              <Button
                icon={<PlusOutlined />}
                onClick={showCreatePositionModal}
                type="primary">
                Tạo chức danh
              </Button>
            </AccessControlAction>
          </Space>
        </PageTitle>
        <ContentBlockWrapper>
          <ListPositionContent>
            <TabsPositionWrapper
              tabPosition={'left'}
              activeKey={currentPosition?.code}
              onChange={onChangeTabPosition}>
              {positionList.length !== 0 &&
                positionList.map(position => {
                  return (
                    <TabPane
                      tab={
                        <>
                          <TextWrap>
                            {trimOverlengthString(position.name, 180)}
                          </TextWrap>
                        </>
                      }
                      key={position.code}>
                      <TableComponent
                        rowKey={() => uuid()}
                        columns={tableColumn}
                        dataSource={userOfPositionData}
                        loading={loadingAnimationStore.tableLoading}
                        pagination={false}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          padding: '15px 0',
                        }}>
                        <Pagination
                          onChange={onChangePagination}
                          hideOnSinglePage={true}
                          total={userOfPositionListTotalCount}
                          pageSize={userOfPositionListPageSize}
                          current={userOfPositionListPageIndex + 1}
                          showSizeChanger={false}
                          showLessItems
                        />
                      </div>
                    </TabPane>
                  )
                })}
            </TabsPositionWrapper>
          </ListPositionContent>
        </ContentBlockWrapper>
      </DashboardLayout>

      <CreatePositonModal
        isVisibleCreatePositionModal={isVisibleCreatePositionModal}
        mode={mode}
        handleCloseCreatePositionModal={handleCloseCreatePositionModal}
        currentPosition={currentPosition}
      />

      <UserOverview
        isVisibleUser={isVisibleUser}
        handleCancelViewUser={handleCancelViewUser}
      />

      <SortPositionodal
        positionList={positionList}
        isVisibleSortPositionModal={isVisibleSortPositionModal}
        onCancel={handleCancelSortPositionModal}
        handleCancelSortPositionModal={handleCancelSortPositionModal}
        onRefreshListPosition={onRefreshListPosition}
      />
    </>
  )
}

PositionPage.propTypes = {}

export default memo(
  inject(
    'positionStore',
    'userStore',
    'loadingAnimationStore'
  )(observer(PositionPage))
)

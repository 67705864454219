import styled, { css } from 'styled-components'

export const DocumentHeadingWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
`
export const HeadingTitleWrapper = styled.div`
  margin-right: 30px;
`
export const HeadingButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const DocumentContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const BlockLeft = styled.div`
  width: calc(100% - 415px);
  overflow: hidden;
`
export const BlockRight = styled.div`
  width: 400px;
`
export const DocumentInfoTitle = styled.h2`
  background-color: #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 600;
`
export const DocumentInfoList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: -10px;
  }
  dt {
    width: 150px;
    font-weight: 700;
    padding: 0 10px;
    &:after {
      display: inline-block;
      content: ':';
    }
  }
  dd {
    width: calc(100% - 150px);
    word-break: break-all;
  }
  dt,
  dd {
    min-height: 36px;
    margin-bottom: 0;
  }
`
export const ViewerWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  ${props =>
    props.fullHeight === null &&
    css`
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    `}
`

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'
import {
  AddButton,
  AttachmentFile,
  AttachmentList,
  AvatarWrapper,
  FieldDescription,
  FieldTitle,
  FieldWrapper,
  FormButtonRow,
} from './ProposalDetailStyled'
import { Avatar, Button, Col, Dropdown, Menu, message, Tooltip } from 'antd'
import {
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import { apiUrl } from '../../../../../config'
import SelectUserModal from '../../../../../components/SelectUserModal'
import { DATE_FORMAT_DEFAULT } from '../../../../../constants'
import moment from 'moment'

const ProposalDetailLeaves = props => {
  const {
    proposalStore,
    commonStore,
    selectUserStore,
    loadingAnimationStore,
    proposalId,
    handleDownloadAttachment,
  } = props

  const { proposalLeaveDetail } = proposalStore
  const { selectedListData } = selectUserStore

  const [isVisibleSelectUserModal, setIsVisibleSelectUserModal] = useState(
    false
  )
  const [selectUserModalType, setSelectUserModalType] = useState(undefined)
  const [activeTabKey, setActiveTabKey] = useState('user')

  const renderTitlePage = useMemo(() => {
    switch (proposalLeaveDetail.proposal_type) {
      case 'LEAVE':
        return 'Phiếu trình nghỉ phép'
      case 'LEAVE_ANNUAL':
        return 'Phiếu trình nghỉ phép hàng năm'
      case 'LEAVE_NO_SALARY':
        return 'Phiếu trình nghỉ phép không lương'
    }
  }, [proposalLeaveDetail])

  /** Hiển thị danh sách file đính kèm */
  const renderFileIcon = type => {
    switch (type) {
      case 'image/jpeg':
      case 'image/png':
        return <FileImageOutlined />
      case 'application/pdf':
        return <FilePdfOutlined />
      default:
        return <FileOutlined />
    }
  }
  const renderDropdownMenu = attachment => (
    <Menu>
      <Menu.Item
        onClick={() =>
          handleDownloadAttachment(
            attachment.file_id,
            attachment.file_title,
            attachment.file_type
          )
        }>
        Tải về
      </Menu.Item>
    </Menu>
  )
  const renderAttachmentList = useCallback(() => {
    return proposalLeaveDetail.files?.map(attachment => {
      return (
        <Dropdown
          key={attachment.file_id}
          overlay={() => renderDropdownMenu(attachment)}>
          <AttachmentFile key={attachment.file_id} theme={commonStore.appTheme}>
            <div className="file-icon">
              {renderFileIcon(attachment.file_type)}
            </div>
            <div className="file-info">
              <Tooltip title={attachment.file_title}>
                <div className="title">
                  {attachment.file_title.length >= 20
                    ? attachment.file_title.slice(0, 20).concat('...')
                    : attachment.file_title}
                </div>
              </Tooltip>
              <div className="size">
                {`${(attachment.file_size / 1024).toFixed(2)} KB`}
              </div>
            </div>
          </AttachmentFile>
        </Dropdown>
      )
    })
  }, [commonStore.appTheme, renderFileIcon, proposalLeaveDetail])

  /** Hiển thị avatar, số lượng danh sách người phụ trách */
  const renderAvatar = type => {
    return (
      <Avatar.Group
        maxCount={4}
        maxStyle={{
          color: '#f56a00',
          backgroundColor: '#fde3cf',
        }}>
        {proposalLeaveDetail?.peopel_involves
          ? proposalLeaveDetail?.peopel_involves.map((user, index) => {
              if (user.type === type) {
                return (
                  <Tooltip
                    title={
                      <span style={{ textTransform: 'capitalize' }}>
                        {user?.full_name}
                      </span>
                    }
                    placement={'top'}
                    key={user.user_name}>
                    <Avatar
                      style={{ backgroundColor: '#1890ff' }}
                      src={
                        user?.imageId &&
                        `${apiUrl}/api/v1/images/${user?.imageId}`
                      }>
                      {user?.full_name.split('')[0].toString().toUpperCase()}
                    </Avatar>
                  </Tooltip>
                )
              }
            })
          : ''}
      </Avatar.Group>
    )
  }
  const showLengthDataSelected = type => {
    return (
      proposalLeaveDetail?.peopel_involves &&
      proposalLeaveDetail?.peopel_involves.filter(user => user.type === type)
        .length
    )
  }

  /** Mở modal select */
  const showSelectedModal = typeModal => {
    setIsVisibleSelectUserModal(true)
    setSelectUserModalType(typeModal)
    /** Hiển thị tab người dùng */
    setActiveTabKey('user')
  }

  /** Thay đổi Tab người dùng phòng ban, nhóm người dùng */
  const onChangeTabs = key => {
    setActiveTabKey(key)
  }

  /**  Render title modal */
  const renderTitleModal = () => {
    switch (selectUserModalType) {
      case 'person_in_charge':
        return 'Chọn người xử lý'
      case 'follower':
        return 'Chọn người xem'
      case 'hand_over':
        return 'Chọn người nhận bàn giao'
      default:
        return 'Chọn người dùng'
    }
  }

  /** Trả về danh sách người chọn tích hợp vào để gửi lên API yêu cầu */
  const returnSelectedListData = () => {
    switch (selectUserModalType) {
      case 'person_in_charge':
        return {
          follower: renderArrListUser('VIEWER').map(el => el.username),
          proposal_type: proposalLeaveDetail.proposal_type,
        }
      case 'follower':
        return {
          person_in_charge: renderArrListUser('APPROVAL').map(
            el => el.username
          ),
          proposal_type: proposalLeaveDetail.proposal_type,
        }
    }
  }

  const submitUpdate = useCallback(() => {
    let submitValue = {}
    switch (selectUserModalType) {
      case 'person_in_charge':
        submitValue = {
          ...returnSelectedListData(),
          person_in_charge: selectedListData.person_in_charge.user.map(
            el => el.username
          ),
        }
        break
      case 'follower':
        submitValue = {
          ...returnSelectedListData(),
          follower: selectedListData.follower.user.map(el => el.username),
        }
        break
    }
    loadingAnimationStore.showSpinner(true)
    proposalStore
      .updateLeaveAssignPerson(proposalId, submitValue)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        proposalStore.getProposalLeavesDetail(proposalId)
        return message.success('Cập nhật thành công!')
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
        return message.error('Cập nhật thất bại!')
      })
    setIsVisibleSelectUserModal(false)
  }, [proposalId, selectUserModalType, selectedListData])

  /** Render danh sách người phụ trách trong modal khi chọn thêm người */
  const renderArrListUser = type => {
    const filterType =
      proposalLeaveDetail &&
      proposalLeaveDetail?.peopel_involves?.filter(user => user.type === type)
    return filterType?.map(user => ({
      user_code: null,
      user_name: user.full_name.toUpperCase(),
      username: user.user_name,
    }))
  }

  /** Mỗi khi click modal nghỉ phép set cấu trúc selectedListData và set type modal select list */
  useEffect(() => {
    selectUserStore.setSelectedListData({
      /** Người xử lý tương ứng mới modal 'person_in_charge' */
      person_in_charge: {
        user: renderArrListUser('APPROVAL'),
        department: [],
        group: [],
      },

      /** Người xem tương ứng mới modal 'follower' */
      follower: {
        user: renderArrListUser('VIEWER'),
        department: [],
        group: [],
      },
    })
  }, [proposalLeaveDetail])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    proposalStore
      .getProposalLeavesDetail(proposalId)
      .then(() => {
        loadingAnimationStore.showSpinner(false)
        return Promise.resolve()
      })
      .catch(error => {
        loadingAnimationStore.showSpinner(false)
        console.log(error)
      })
  }, [proposalId])

  return (
    <>
      <h2 style={{ borderBottom: '1px solid #F0F0F0', paddingBottom: '1rem' }}>
        {renderTitlePage}
      </h2>
      <FieldWrapper gutter={24}>
        <Col span={24}>
          <FieldTitle style={{ marginTop: '10px' }}>Lý do nghỉ phép</FieldTitle>
          <FieldDescription>
            {proposalLeaveDetail.reason ? (
              proposalLeaveDetail.reason
            ) : (
              <i style={{ color: '#ccc' }}>Không xác định</i>
            )}
          </FieldDescription>
        </Col>
      </FieldWrapper>
      <FieldWrapper gutter={24}>
        <Col span={24}>
          <FieldTitle>Diễn giải</FieldTitle>
          <FieldDescription>
            {proposalLeaveDetail.details ? (
              proposalLeaveDetail?.details
            ) : (
              <i style={{ color: '#ccc' }}>Không xác định</i>
            )}
          </FieldDescription>
        </Col>
      </FieldWrapper>
      <FieldWrapper gutter={24}>
        <Col span={12}>
          <FieldTitle>Ngày nghỉ</FieldTitle>
          <FieldDescription>
            {proposalLeaveDetail?.from_date ? (
              moment(proposalLeaveDetail?.from_date).format(DATE_FORMAT_DEFAULT)
            ) : (
              <i style={{ color: '#ccc' }}>Không xác định</i>
            )}
          </FieldDescription>
        </Col>
        <Col span={12}>
          <FieldTitle>Đến ngày</FieldTitle>
          <FieldDescription>
            {proposalLeaveDetail?.to_date ? (
              moment(proposalLeaveDetail?.to_date).format(DATE_FORMAT_DEFAULT)
            ) : (
              <i style={{ color: '#ccc' }}>Không xác định</i>
            )}
          </FieldDescription>
        </Col>
      </FieldWrapper>
      {/*<FieldWrapper gutter={24}>*/}
      {/*  <Col span={24}>*/}
      {/*    <FieldTitle>*/}
      {/*      Bàn giao công việc cho*/}
      {/*    </FieldTitle>*/}
      {/*    <FieldDescription>*/}
      {/*      {proposalLeaveDetail?.hand_over_person?.name_lowercase}*/}
      {/*    </FieldDescription>*/}
      {/*  </Col>*/}
      {/*</FieldWrapper>*/}
      <FieldWrapper gutter={24}>
        <Col span={24}>
          <FieldTitle>Nội dung bàn giao công việc</FieldTitle>
          <FieldDescription>
            {proposalLeaveDetail.hand_over_content ? (
              proposalLeaveDetail?.hand_over_content
            ) : (
              <i style={{ color: '#ccc' }}>Không xác định</i>
            )}
          </FieldDescription>
        </Col>
      </FieldWrapper>
      <FieldWrapper gutter={24}>
        <Col span={24}>
          <FieldTitle>
            File đính kèm &nbsp;
            <span style={{ color: '#999' }}>
              {proposalLeaveDetail.files?.length}
            </span>
          </FieldTitle>
          <FieldDescription>
            <AttachmentList>{renderAttachmentList()}</AttachmentList>
          </FieldDescription>
        </Col>
      </FieldWrapper>
      <FieldWrapper gutter={24}>
        <Col span={8}>
          <FieldTitle>
            Người nhận bàn giao &nbsp;
            <span style={{ color: '#999' }}>
              {showLengthDataSelected('HAND_OVER')}
            </span>
          </FieldTitle>
          <FieldDescription>
            <AvatarWrapper>{renderAvatar('HAND_OVER')}</AvatarWrapper>
            {/*<AddButton*/}
            {/*  onClick={() => showSelectedModal('hand_over')}*/}
            {/*  shape='round' icon={< PlusCircleOutlined />}>*/}
            {/*  Thêm người*/}
            {/*</AddButton>*/}
          </FieldDescription>
        </Col>
        <Col span={8}>
          <FieldTitle>
            Người xử lý &nbsp;
            <span style={{ color: '#999' }}>
              {showLengthDataSelected('APPROVAL')}
            </span>
          </FieldTitle>
          <FieldDescription>
            <AvatarWrapper>{renderAvatar('APPROVAL')}</AvatarWrapper>
            <AddButton
              onClick={() => showSelectedModal('person_in_charge')}
              shape="round"
              icon={<PlusCircleOutlined />}>
              Chỉnh sửa
            </AddButton>
          </FieldDescription>
        </Col>
        <Col span={8}>
          <FieldTitle>
            Người theo dõi &nbsp;
            <span style={{ color: '#999' }}>
              {showLengthDataSelected('VIEWER')}
            </span>
          </FieldTitle>
          <FieldDescription>
            <AvatarWrapper>{renderAvatar('VIEWER')}</AvatarWrapper>
            <AddButton
              onClick={() => showSelectedModal('follower')}
              shape="round"
              icon={<PlusCircleOutlined />}>
              Chỉnh sửa
            </AddButton>
          </FieldDescription>
        </Col>
      </FieldWrapper>

      <SelectUserModal
        isVisibleSelectUserModal={isVisibleSelectUserModal}
        handleCloseSelectUserModal={() => setIsVisibleSelectUserModal(false)}
        handleSubmitSelect={submitUpdate}
        selectUserModalType={selectUserModalType}
        onChangeTabs={onChangeTabs}
        activeTabKey={activeTabKey}
        renderTitleModal={renderTitleModal}
        forceRender={false}
        disabled={true}
      />

      <FormButtonRow>
        <Button>Hủy bỏ</Button>
        <Button type={'primary'}>Chỉnh sửa</Button>
      </FormButtonRow>
    </>
  )
}

ProposalDetailLeaves.propTypes = {}

export default inject(
  'proposalStore',
  'commonStore',
  'selectUserStore',
  'loadingAnimationStore',
)(observer(ProposalDetailLeaves))

import React, { useCallback, useEffect, useState } from 'react'
import { Button, Empty, message, Popconfirm, Select, Tag, Tooltip } from 'antd'
import { ModalBody, ModalWrapper } from './SelectPeoplePopupStyled'
import { inject, observer } from 'mobx-react'
import SelectUserDepartmentBox from './SelectUserDepartmentBox'
import { ASSIGNEE_TYPE } from '../../constants'
import { AvatarHasName } from '../Common/Avatar'
import { BankOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons'
import { yellowPrimary } from '../../color'
import SpinnerInlineComponent from '../SpinnerInlineComponent/SpinnerInlineComponent'
import EmptyContent from '../EmptyContent'

const { Option } = Select

const SelectPeoplePopup = props => {
  const {
    isVisibleSelectPeoplePopup,
    handleSubmitSelectUser,
    handleCancelSelectUser,
    clearSelectUser,
    selectPeopleStore,
    loadingAnimationStore,
    authenticationStore,
    companyStore,
    onlyOneUser,
    onlySelectOne,
    disableSelectDepartment,
    noLoadSelectData,
    title,
    okText,
    cancelText,
    isOpenCollapse,
    allowChangeCompany,
  } = props
  const {
    selectPopupName,
    companyCode,
    assigneePopupType,
    selectUserData,
    setSelectUserData,
    selectDepartmentData,
    setSelectDepartmentData,
    setDepartmentWithUserList,
    originDepartmentWithUserList,
  } = selectPeopleStore

  const [selectedList, setSelectedList] = useState([])
  const [userDisableList, setUserDisableList] = useState([])
  const [departmentDisableList, setDepartmentDisableList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [prevUserList, setPrevUserList] = useState([])
  const [prevDepartmentList, setPrevDepartmentList] = useState([])

  const { companyList } = companyStore
  const showDropdownCompany = allowChangeCompany ?? true

  useEffect(() => {
    if (!assigneePopupType) return
    setPrevUserList(selectUserData[assigneePopupType])
    setPrevDepartmentList(selectDepartmentData[assigneePopupType])
  }, [isVisibleSelectPeoplePopup])

  useEffect(() => {
    if (noLoadSelectData) return //
    ;(async () => {
      if (companyCode) {
        try {
          setIsLoading(true)
          await selectPeopleStore.getDepartmentWithUsers()
        } catch (err) {
          console.log(err)
          message.error(err.vi || 'Đã có lỗi xảy ra!')
        } finally {
          setIsLoading(false)
        }
      }
    })()
  }, [companyCode])

  useEffect(() => {
    if (authenticationStore.currentUser) {
      selectPeopleStore.companyCode = authenticationStore.currentCompanyCode()
    }
  }, authenticationStore.currentUser)

  useEffect(() => {
    if (!assigneePopupType) return
    const selectedUserList = [
      ...selectUserData[ASSIGNEE_TYPE.LEADER],
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectUserData[ASSIGNEE_TYPE.COMBINER],
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...(selectUserData[ASSIGNEE_TYPE.CREATE]
        ? selectUserData[ASSIGNEE_TYPE.CREATE]
        : []),
    ]

    const selectedDepartmentList = [
      ...selectDepartmentData[ASSIGNEE_TYPE.LEADER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.COMBINER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...(selectUserData[ASSIGNEE_TYPE.CREATE]
        ? selectDepartmentData[ASSIGNEE_TYPE.CREATE]
        : []),
    ]

    setUserDisableList(
      selectedUserList
        .filter(el => el.permission !== assigneePopupType)
        .map(el => el.id)
    )
    setDepartmentDisableList(
      selectedDepartmentList
        .filter(el => el.permission !== assigneePopupType)
        .map(el => el.id)
    )
  }, [assigneePopupType])

  useEffect(() => {
    if (!assigneePopupType) return
    setSelectedList([
      ...selectUserData[assigneePopupType],
      ...selectDepartmentData[assigneePopupType],
    ])
  }, [selectUserData, selectDepartmentData, assigneePopupType])

  const handleRemoveSelect = useCallback(
    item => {
      if (!assigneePopupType) return
      setSelectUserData({
        ...selectUserData,
        [assigneePopupType]: selectUserData[assigneePopupType].filter(
          el => el.id !== item.id
        ),
      })
      setSelectDepartmentData({
        ...selectDepartmentData,
        [assigneePopupType]: selectDepartmentData[assigneePopupType].filter(
          el => el.id !== item.id
        ),
      })
    },
    [assigneePopupType, selectUserData, selectDepartmentData]
  )

  useEffect(() => {
    setDepartmentWithUserList(originDepartmentWithUserList)
  }, [isVisibleSelectPeoplePopup])

  const onCompanyChange = value => {
    console.log('onCompanyChange', value)

    // setSelectedList([])
    // selectPeopleStore.clearSelectedByType(assigneePopupType)

    selectPeopleStore.companyCode = value
  }

  return (
    <ModalWrapper
      maskClosable={false}
      okText={okText || 'Đồng ý'}
      cancelText={'Hủy bỏ'}
      width={800}
      style={{ top: 20 }}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {showDropdownCompany ? (
            <Select
              // style={{ width: 200 }}
              notFoundContent={<EmptyContent />}
              onChange={onCompanyChange}
              value={companyCode}
              placeholder={'-- Chọn công ty --'}>
              {companyList.map(item => (
                <Option key={item.code} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          ) : (
            <span>{title || selectPopupName}</span>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 8,
              alignItems: 'center',
            }}>
            {disableSelectDepartment || cancelText ? (
              <Button
                danger
                onClick={handleCancelSelectUser}
                icon={
                  !cancelText && (
                    <WarningOutlined style={{ color: yellowPrimary }} />
                  )
                }>
                {cancelText || 'Hủy chọn'}
              </Button>
            ) : (
              <Popconfirm
                placement="top"
                title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
                onConfirm={() => {
                  clearSelectUser ? clearSelectUser() : handleCancelSelectUser()
                  selectPeopleStore.handleCancelSelectUserDepartment()
                }}
                okText="Đồng ý"
                cancelText="Không">
                <Button
                  danger
                  icon={<WarningOutlined style={{ color: yellowPrimary }} />}>
                  {cancelText || 'Hủy chọn'}
                </Button>
              </Popconfirm>
            )}
            <Button
              type={'primary'}
              onClick={() => {
                JSON.stringify(selectDepartmentData[assigneePopupType]) !==
                  JSON.stringify(prevDepartmentList) ||
                JSON.stringify(selectUserData[assigneePopupType]) !==
                  JSON.stringify(prevUserList)
                  ? handleSubmitSelectUser()
                  : handleCancelSelectUser()
              }}>
              {okText || 'Đồng ý'}
            </Button>
            {!cancelText && (
              <div>
                {/* {disableSelectDepartment ? (
                  <Tooltip title={'Đóng'}>
                    <CloseOutlined onClick={handleCancelSelectUser} />
                  </Tooltip>
                ) : ( */}
                <Tooltip title={'Đóng'}>
                  <CloseOutlined
                    onClick={() => {
                      setSelectUserData({
                        ...selectUserData,
                        [assigneePopupType]: prevUserList,
                      })
                      setSelectDepartmentData({
                        ...selectDepartmentData,
                        [assigneePopupType]: prevDepartmentList,
                      })
                      handleCancelSelectUser()
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      }
      closable={false}
      forceRender={true}
      footer={null}
      visible={isVisibleSelectPeoplePopup}>
      <ModalBody>
        <div
          style={{
            display: 'flex',
            gap: 4,
            paddingTop: 10,
          }}>
          <div style={{ flexGrow: 1 }}>
            <div className={'modal-body'} style={{ position: 'relative' }}>
              <SelectUserDepartmentBox
                isOpenCollapse={isOpenCollapse}
                isVisibleSelectPeoplePopup={isVisibleSelectPeoplePopup}
                disableSelectDepartment={disableSelectDepartment}
                onlyOneUser={onlyOneUser}
                onlySelectOne={onlySelectOne}
                userDisableList={userDisableList}
                departmentDisableList={departmentDisableList}
              />
              <SpinnerInlineComponent
                sizeSpin={'small'}
                isLoading={isLoading}
                noBackground
              />
            </div>
          </div>
          <div
            className={'selected-list'}
            style={{
              flex: '0 0 250px',
              maxHeight: 'calc(100vh - 111px)',
              overflowY: 'auto',
              maxWidth: 250,
              backgroundColor: '#fff',
              margin: '-10px 0 -15px 0',
              borderLeft: '1px solid #f0f0f0',
              padding: '15px 10px',
            }}>
            <div
              style={{
                margin: '-15px -10px 10px -10px',
                padding: 15,
                borderBottom: '1px solid #f0f0f0',
                fontWeight: 500,
                backgroundColor: '#e9f7ff',
              }}>
              Danh sách đã chọn:
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 8,
              }}>
              {selectedList.length > 0 ? (
                selectedList.map(el => {
                  return (
                    <Tag
                      className={'tag-selected'}
                      color={'blue'}
                      key={el.id}
                      closable
                      onClose={() => handleRemoveSelect(el)}>
                      <AvatarHasName
                        imgId={el.image_id}
                        size={22}
                        name={el.full_name}
                        icon={
                          el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                            <BankOutlined />
                          )
                        }
                      />
                    </Tag>
                  )
                })
              ) : (
                <Empty
                  style={{ width: '100%' }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}

SelectPeoplePopup.propTypes = {}

export default inject(
  'selectPeopleStore',
  'loadingAnimationStore',
  'companyStore',
  'authenticationStore'
)(observer(SelectPeoplePopup))

import axios from 'axios'
import { apiUrlCommonService } from '../config'

export const CommonServiceRequest = {
  reportBug: payload =>
    axios({
      method: 'post',
      url: `${apiUrlCommonService}/common-service/api/v1/issue_report`,
      headers: {
        Authorization: 'Basic YWRtaW46YWRtaW4=',
        'Content-Type': 'application/json',
      },
      data: payload,
    }),
}

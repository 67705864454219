import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const KpiQuarterRequest = {
  getList: (page, size) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/kpi_quarter`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        page: page,
        size: size,
      },
    }),
  getDetail: kpiQuarterCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/kpi_quarter/${kpiQuarterCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  delete: kpiQuarterCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/kpi_quarter/${kpiQuarterCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  create: name =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/kpi_quarter`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: {
        name: name,
        company_code: authenticationStore.currentUser.company_code,
        create_user: authenticationStore.currentUser.username,
      },
    }),
}

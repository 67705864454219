import styled from 'styled-components'

export const ModuleChildrenItemWrapper = styled.div`
  background-color: #fefefe;
  cursor: pointer;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  color: ${props => (props.checked ? '#262626' : '#787878')};
  padding-left: 50px;
  padding-right: 20px;
  border-left: 5px solid #d6e0f5;
  border-bottom: 1px solid #c3cfe7;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    position: relative;
    z-index: 1;
  }

  .module-name {
    position: relative;
    &:before {
      content: '';
      width: 23px;
      height: 25px;
      border-bottom: ${props =>
        `3px solid ${props.checked ? '#2c65ac' : '#ccc'}`};
      border-left: 3px solid #2c65ac;

      position: absolute;
      top: -12px;
      left: -34px;
    }
    &:not(.module-last):after {
      content: '';
      width: 0px;
      height: 21px;
      border-left: 3px solid #2c65ac;
      position: absolute;
      top: 12px;
      left: -34px;
    }
  }
`
export const ModuleParentItemWrapper = styled.div`
  background-color: #f0f5ff;
  cursor: pointer;
  font-weight: 500;
  color: ${props => (props.checked ? '#262626' : '#787878')};
  height: 45px;
  font-size: 14px;
  padding: 0 20px 0 10px;
  border-left: ${props => `5px solid ${props.checked ? '#2c65ac' : '#ccc'}`};
  border-bottom: 1px solid #c3cfe7;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    position: relative;
    z-index: 1;
  }

  .anticon {
    color: ${props => (props.checked ? '#2c65ac' : '#787878')} !important;
  }
`

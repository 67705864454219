import React, { useCallback, useEffect } from 'react'
import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import { FormButtonGroup } from './UserAccountEditFormStyled'
import validator from '../../validator'

const { Option } = Select

const UserAccountEditForm = props => {
  const [form] = Form.useForm()

  const {
    userStore,
    companyStore,
    loadingAnimationStore,
    modalVisible,
    onCancel,
    positionStore,
    departmentStore,
    roleStore,
    authenticationStore,
    onRefreshListUser,
  } = props

  const { companyList } = companyStore
  const { selectedUser } = userStore
  const { positionList } = positionStore
  const { departmentList } = departmentStore
  const { roleList } = roleStore
  const { currentUser } = authenticationStore

  const onFinish = useCallback(
    async values => {
      loadingAnimationStore.showSpinner(true)
      try {
        await userStore.updateUserRoles(selectedUser.code, values.roles)
        await userStore.updateUser(values)
        await userStore.getUserList()

        //handle refresh
        if (onRefreshListUser) {
          onRefreshListUser()
        }

        if (currentUser.code !== selectedUser.code) {
          loadingAnimationStore.showSpinner(false)
          message.success(`Cập nhật thành công!`)
          handleCancelEdit()
          return
        }
        await authenticationStore.checkCurrentUser()
        message.success(`Cập nhật thành công!`)

        handleCancelEdit()
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    },
    [form, selectedUser, currentUser]
  )

  const handleCancelEdit = useCallback(() => {
    onCancel()
    setDefaultInfo()
  }, [form, onCancel])
  const renderRoleOptions = useCallback(() => {
    return roleList.map(role => (
      <Option value={role.name} key={role.id} name={role.explain}>
        {role.explain}
      </Option>
    ))
  }, [roleList])
  const handleChangeCompany = useCallback(companyCode => {
    form.setFieldsValue({ department_code: null })
    loadingAnimationStore.showSpinner(true)
    departmentStore
      .getDepartmentListFromCompanyCode(companyCode)
      .catch(error => message.error(error.vi))
      .finally(() => loadingAnimationStore.showSpinner(false))
  }, [])
  const setDefaultInfo = useCallback(() => {
    form.setFieldsValue({
      email: selectedUser.email || undefined,
      name: selectedUser.name_lowercase || undefined,
      phone: selectedUser.phone || undefined,
      username: selectedUser.username || undefined,
      company_code:
        (selectedUser.company && selectedUser.company.code) || undefined,
      department_code:
        (selectedUser.department && selectedUser.department.code) || undefined,
      position_code:
        (selectedUser.position && selectedUser.position.code) || undefined,
      gender:
        (selectedUser.gender !== undefined && selectedUser.gender.toString()) ||
        undefined,
      roles: selectedUser.roles,
      home_phone: selectedUser.home_phone || undefined,
      ma_nv: selectedUser.ma_nv || undefined,
    })
  }, [selectedUser])

  useEffect(() => {
    setDefaultInfo()
  }, [selectedUser])

  return (
    <Modal
      onCancel={handleCancelEdit}
      scrollToFirstError
      getContainer={false}
      title={'Sửa thông tin người dùng'}
      visible={modalVisible}
      footer={null}>
      <Form
        form={form}
        scrollToFirstError={true}
        name="user-edit-form"
        onFinish={onFinish}
        layout={'vertical'}>
        <Row type={'flex'} justify={'space-between'} gutter={15}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Tên đăng nhập"
              name="username"
              rules={[
                { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
                { validator: validator.validateUsername },
              ]}>
              <Input placeholder={'Tên đăng nhập'} disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Mật khẩu" name="password">
              <Input.Password
                autoComplete={'new-password'}
                placeholder={'Mật khẩu'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Công ty"
          style={{ display: `none` }}
          name="company_code"
          vis
          rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
          <Select
            showSearch
            placeholder={'Công ty'}
            filterOption={true}
            onChange={handleChangeCompany}
            optionFilterProp={'name'}>
            {companyList.map(company => (
              <Option
                key={company.code}
                name={company.name}
                value={company.code}>
                {company.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Phòng ban"
          style={{ display: `${onRefreshListUser != null ? 'none' : 'block'}` }}
          name="department_code"
          rules={[{ required: true, message: 'Vui lòng chọn phòng ban!' }]}>
          <Select
            showSearch
            placeholder={'Phòng ban'}
            filterOption={true}
            optionFilterProp={'name'}>
            {departmentList.map(department => {
              return (
                <Option
                  key={department.code}
                  name={department.name}
                  value={department.code}>
                  {department.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Họ tên"
          name="name"
          rules={[
            { required: true, message: 'Vui lòng nhập họ tên!' },
            { validator: validator.validateInputString },
          ]}>
          <Input placeholder={'Họ tên'} />
        </Form.Item>
        <Form.Item label="Mã nhân viên" name="ma_nv">
          <Input placeholder={'Mã nhân viên'} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Vui lòng nhập email!' },
            { validator: validator.validateEmail },
          ]}>
          <Input placeholder={'Email'} />
        </Form.Item>
        <Form.Item
          label={'Giới tính'}
          name={'gender'}
          rules={[{ required: true, message: 'Vui lòng chọn giới tính' }]}>
          <Select placeholder={'Giới tính'}>
            <Option value={'1'}>Nam</Option>
            <Option value={'0'}>Nữ</Option>
            <Option value={'2'}>Khác</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Điện thoại"
          name="phone"
          rules={[
            { required: true, message: 'Vui lòng nhập số điện thoại!' },
            { validator: validator.validatePhoneNumber },
          ]}>
          <Input placeholder={'Số điện thoại'} />
        </Form.Item>
        <Form.Item label="Số máy nội bộ" name="home_phone">
          <Input placeholder={'Số máy nội bộ'} />
        </Form.Item>
        <Form.Item
          label="Chức danh"
          name="position_code"
          rules={[{ required: true, message: 'Vui lòng chọn chức danh!' }]}>
          <Select
            showSearch
            placeholder={'Chức danh'}
            filterOption={true}
            optionFilterProp={'name'}>
            {positionList.map(position => {
              return (
                <Option
                  key={position.code}
                  name={position.name}
                  value={position.code}>
                  {position.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name={'roles'}
          label={'Chức năng'}
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn chức năng cho người dùng này!',
            },
          ]}>
          <Select
            mode={'multiple'}
            showSearch
            showArrow
            filterOption={true}
            optionFilterProp={'name'}
            placeholder={'-- Chức năng --'}>
            {renderRoleOptions()}
          </Select>
        </Form.Item>
        <FormButtonGroup>
          <Button
            onClick={handleCancelEdit}
            style={{ margin: '0 10px 0 auto' }}>
            Huỷ
          </Button>
          <Button type={'primary'} htmlType={'submit'}>
            Lưu thông tin
          </Button>
        </FormButtonGroup>
      </Form>
    </Modal>
  )
}

export default inject(
  'userStore',
  'companyStore',
  'loadingAnimationStore',
  'positionStore',
  'departmentStore',
  'roleStore',
  'authenticationStore'
)(observer(UserAccountEditForm))

import {
  BankOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { blue } from '../../color'
import { AvatarHasName } from '../../components/Common/Avatar'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import { FormActionFooter } from '../../components/UpdateTaskForm/FormUpdateTaskStyled'
import { ASSIGNEE_TYPE } from '../../constants'
import utils from '../../utils'
import { SelectList } from '../MessagesPage/MessagesPageStyled'
import { OriginFilesList } from '../MissionEditPage/MissionEditPageStyled'

const ProposalSalaryDetailUpdate = props => {
  const { selectPeopleStore, proposalSalaryStore, fileStore } = props

  const {
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    selectUserData,
    selectDepartmentData,
    selectGroupData,
  } = selectPeopleStore

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const [monthCreateSalary, setMonthCreateSalary] = useState(moment())
  const [originFiles, setOriginFiles] = useState([])

  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    form.setFieldsValue({
      title: proposalSalaryStore.salaryInfo.title,
    })

    proposalSalaryStore.salaryInfo.followers.forEach(follower => {
      if (follower?.user_name) {
        followerList.push({
          assignee_type: ASSIGNEE_TYPE.USER,
          full_name: follower.name_lowercase.toUpperCase(),
          id: follower.user_name,
          image_id: follower.image_id,
          permission: ASSIGNEE_TYPE.FOLLOWER,
        })
      }
    })

    if (followerList.length > 0) {
      selectPeopleStore.setSelectUserData({
        ...selectUserData,
        VIEW: followerList,
      })
    }

    setOriginFiles(proposalSalaryStore.salaryInfo.attachments)

    setMonthCreateSalary(moment(proposalSalaryStore.salaryInfo.salary_month))
    return () => {
      selectPeopleStore.clearSelectUserData('VIEW')
    }
  }, [proposalSalaryStore.salaryInfo])

  const handleChangeFile = useCallback(
    info => {
      const filteredFileList = info.fileList.filter(
        (elem, index, fileArray) =>
          fileArray.findIndex(file => file.name === elem.name) === index
      )
      setFileList(filteredFileList)
    },
    [fileList]
  )

  const onFinish = async values => {
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []

    const batchUploadArr = []

    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })

    const response = await Promise.all(batchUploadArr)

    const originFileList = (
      proposalSalaryStore.salaryInfo.attachments || []
    ).map(file => file.file_id)
    const afterFileList = [
      ...response.map(el => el.data.file_id),
      ...originFiles.map(file => file.file_id),
    ]

    let newAttachments = []
    let removeAttachments = []

    afterFileList.forEach(file => {
      if (!originFileList.includes(file)) {
        newAttachments.push(file)
      }
    })
    originFileList.forEach(file => {
      if (!afterFileList.includes(file)) {
        removeAttachments.push(file)
      }
    })

    const removeFollowers = proposalSalaryStore.salaryInfo.followers.filter(
      follower => {
        if (!followerList.some(item => item.id === follower.user_name)) {
          return follower
        }
      }
    )

    const newFollowers = followerList.filter(follower => {
      if (
        !proposalSalaryStore.salaryInfo.followers.some(
          item => item.user_name === follower.id
        )
      ) {
        return follower
      }
    })

    let proposal = {
      ...values,
      salary_month: monthCreateSalary,
      attachments: {
        remove_items: removeAttachments,
        new_items: newAttachments,
      },
      assignees: {
        remove_items: removeFollowers.map(follower => ({
          assignee_code: follower.user_name,
          assignee_type: 'USER',
          permission: 'VIEW',
        })),
        new_items: newFollowers.map(follower => ({
          assignee_code: follower.id,
          assignee_type: 'USER',
          permission: 'VIEW',
        })),
      },
    }

    try {
      setIsUpdating(true)
      await proposalSalaryStore.updateProposalSalary(
        proposalSalaryStore.salaryInfo.proposal_code,
        proposal
      )
      await proposalSalaryStore.getSalaryDetail(
        proposalSalaryStore.salaryInfo.proposal_code
      )
    } catch (error) {
      message.error(error.vi || 'Có lỗi xảy ra')
      console.log(error)
    } finally {
      setIsUpdating(false)
      props.onCancel()
    }
  }

  return (
    <Form
      scrollToFirstError={true}
      form={form}
      name={'phieu-trinh-khac-form'}
      layout={'vertical'}
      onFinish={onFinish}>
      <Row gutter={15}>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'title'}
            label={'Tiêu đề:'}
            rules={[
              { required: true, message: 'Vui lòng nhập tiêu đề' },
              {
                max: 500,
                message: 'Tiêu đề không được dài quá 500 kí tự!',
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label={'Đây là bảng lương của tháng:'}>
            <Space align="end" direction="horizontal">
              <div>
                <DatePicker
                  key={monthCreateSalary}
                  style={{ width: 173.7 }}
                  allowClear={false}
                  value={monthCreateSalary}
                  placeholder={'Chọn tháng'}
                  onChange={date => setMonthCreateSalary(moment(date))}
                  format={'MM-YYYY'}
                  picker="month"
                />
              </div>
            </Space>
          </Form.Item>
        </Col>

        <Col xs={24} lg={24}>
          <Form.Item name={'file_ids'} label={'Tài liệu đính kèm:'}>
            <Upload
              valuePropName={'fileList'}
              fileList={fileList}
              multiple={true}
              onChange={handleChangeFile}
              beforeUpload={() => false}
              iconRender={() => <FileTextOutlined />}>
              <Button htmlType={'button'}>
                <UploadOutlined />
                Chọn tài liệu đính kèm
              </Button>
            </Upload>
          </Form.Item>

          <OriginFilesList>
            {originFiles.map(file => (
              <div className={'file'} key={file.file_id}>
                <FileItem
                  file_id={file.file_id}
                  file_name={file.file_name}
                  file_type={utils.getExtensionFile(file.file_name)}
                />
                <span
                  className={'delete-icon'}
                  onClick={() =>
                    setOriginFiles(
                      originFiles.filter(el => el.file_id !== file.file_id)
                    )
                  }>
                  <DeleteOutlined />
                </span>
              </div>
            ))}
          </OriginFilesList>
        </Col>
        <Col xs={24} lg={12}>
          <label style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>
            Người theo dõi:
          </label>

          <Space>
            <Tooltip title="Chọn người dùng" color={blue}>
              <Button
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() =>
                  handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
                }
              />
            </Tooltip>
          </Space>

          {followerList.length > 0 && (
            <SelectList>
              {followerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          )}
        </Col>
      </Row>

      <SelectPeoplePopup
        disableSelectDepartment
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />

      <FormActionFooter>
        <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
          Huỷ bỏ
        </Button>
        <Button
          loading={isUpdating}
          style={{ marginLeft: 10 }}
          icon={<CheckOutlined />}
          type={'primary'}
          htmlType={'submit'}>
          Đồng ý chỉnh sửa
        </Button>
      </FormActionFooter>
    </Form>
  )
}

export default inject(
  'selectPeopleStore',
  'proposalSalaryStore',
  'fileStore'
)(observer(ProposalSalaryDetailUpdate))

import React, { useState } from 'react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { TitleContentBox } from '../../components/Common/CellText'
import LinkedWork from '../../components/Common/Linked/LinkedWork'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { blue } from '../../color'
import { useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import CreateWorkFromMissionModal from './CreateWorkFromMissionModal'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'

const MissionDetailWorks = props => {
  const history = useHistory()

  const { works, isDetailList, missionId, handleSetTaskDetail } = props
  const [
    isVisibleCreateWorkFromMissionModal,
    setIsVisibleCreateWorkFromMissionModal,
  ] = useState(false)

  return (
    <ContentBlockWrapper
      style={
        isDetailList
          ? {
              paddingTop: 5,
              border: `1px solid ${blue}`,
              boxShadow:
                'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
            }
          : {}
      }>
      {isDetailList ? (
        <h3
          style={{
            marginBottom: 8,
            color: blue,
            fontSize: 16,
            textDecoration: 'underline',
          }}>
          Danh sách công việc
        </h3>
      ) : (
        <TitleContentBox>Danh sách công việc</TitleContentBox>
      )}
      <div>
        <LinkedWork
          style={{
            paddingBottom: 8,
            borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
          }}
          linkedWorkList={works}
          emptyText={'Chưa có công việc nào!'}
        />
        <AccessControlAction
          aclActionType={ACL_ACTION_TYPE.create_work__MISSION}>
          <Button
            icon={<PlusOutlined />}
            type={'primary'}
            onClick={() => {
              setIsVisibleCreateWorkFromMissionModal(true)
            }}
            size={'small'}
            style={{
              marginTop: 15,
            }}>
            Thêm công việc
          </Button>
        </AccessControlAction>
      </div>
      <CreateWorkFromMissionModal
        handleSetTaskDetail={handleSetTaskDetail}
        isDetailList={isDetailList}
        missionId={missionId}
        isVisibleCreateWorkFromMissionModal={
          isVisibleCreateWorkFromMissionModal
        }
        handleCloseCreateWorkModal={() =>
          setIsVisibleCreateWorkFromMissionModal(false)
        }
      />
    </ContentBlockWrapper>
  )
}

MissionDetailWorks.propTypes = {}

export default inject('authenticationStore')(observer(MissionDetailWorks))

import React, { useCallback, useEffect, useState } from 'react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { TitleContentBox } from '../../components/Common/CellText'
import ProposalAdvanceStepApprove from '../ProposalAdvancePage/ProposalAdvanceCreateModal/ProposalAdvanceStepApprove'
import { inject, observer } from 'mobx-react'
import { Button } from 'antd'
import ProposalAdvanceActionConfirmModal from './ProposalAdvanceActionConfirmModal'
import { ACL_ACTION_TYPE, PROPOSAL_STATUS } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'

const groupBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 10,
  marginBottom: 10,
}

const ProposalAdvanceAction = props => {
  const { proposalAdvanceStore, authenticationStore } = props
  const { proposalAdvanceDetail } = proposalAdvanceStore
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [confirmModalType, setConfirmModalType] = useState(null)
  const { currentUser } = authenticationStore

  const [isShowButtonAction, setIsShowButtonAction] = useState(false)

  useEffect(() => {
    if (!currentUser) return
    const currentStepInfo = proposalAdvanceDetail.approval_levels?.find(
      item => item.status === PROPOSAL_STATUS.PENDING
    )
    if (currentUser.username === currentStepInfo?.username) {
      setIsShowButtonAction(true)
    } else {
      setIsShowButtonAction(false)
    }
  }, [proposalAdvanceDetail, currentUser])

  const handleCloseModal = useCallback(() => {
    setIsConfirmModalVisible(false)
    setConfirmModalType(null)
  }, [])

  const handleOpenConfirmModal = modalType => {
    setConfirmModalType(modalType)
    setIsConfirmModalVisible(true)
  }

  return (
    <ContentBlockWrapper style={{ marginBottom: 30 }}>
      <TitleContentBox>Tiến trình duyệt</TitleContentBox>
      <ProposalAdvanceStepApprove
        approvalLevelList={proposalAdvanceDetail.approval_levels || []}
      />
      {isShowButtonAction && (
        <div style={groupBtnStyle}>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.approve__PROPOSAL_ADVANCE}>
            <Button
              danger
              onClick={() => handleOpenConfirmModal(PROPOSAL_STATUS.REJECT)}>
              Từ chối
            </Button>
          </AccessControlAction>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.approve__PROPOSAL_ADVANCE}>
            <Button
              type={'primary'}
              onClick={() => handleOpenConfirmModal(PROPOSAL_STATUS.APPROVAL)}>
              Duyệt yêu cầu
            </Button>
          </AccessControlAction>
        </div>
      )}

      <ProposalAdvanceActionConfirmModal
        isModalVisible={isConfirmModalVisible}
        handleCloseModal={handleCloseModal}
        confirmModalType={confirmModalType}
      />
    </ContentBlockWrapper>
  )
}

ProposalAdvanceAction.propTypes = {}

export default inject(
  'proposalAdvanceStore',
  'authenticationStore'
)(observer(ProposalAdvanceAction))

import React, { Fragment, memo, useCallback, useEffect } from 'react'
import { Col, Form, message, Row, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import { FilterBoxWrapper } from './UserListFilterBlockStyled'
import authenticationStore from '../../stores/authenticationStore'

const { Option } = Select

const UserListFilterBlock = props => {
  const [form] = Form.useForm()

  const {
    userStore,
    departmentStore,
    loadingAnimationStore,
    authenticationStore,
  } = props

  const { departmentList } = departmentStore

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    ;(async () => {
      if (authenticationStore.currentCompanyCode()) {
        try {
          departmentStore.getDepartmentListFromCompanyCode(
            authenticationStore.currentCompanyCode()
          )
        } catch (error) {
          console.log(error)
          message.error(error?.vi || 'Đã có lỗi xảy ra!')
        } finally {
          loadingAnimationStore.showSpinner(false)
        }
      }
    })()
  }, [authenticationStore.currentUser])

  const handleFilterByDepartment = value => {
    userStore.changeUserListDepartment(value)
  }

  const handleFilterByStatus = useCallback(
    value => {
      userStore.changeUserListStatus(value)
    },
    [userStore]
  )
  const handleSortByDirection = useCallback(
    value => {
      userStore.changeUserListSortDirection(value)
    },
    [userStore]
  )
  const handleSortByType = useCallback(
    value => {
      userStore.changeUserListSortBy(value)
    },
    [userStore]
  )

  return (
    <Fragment>
      <FilterBoxWrapper>
        <Form
          form={form}
          scrollToFirstError={true}
          name={'user-filter-form'}
          layout={'vertical'}>
          <Row type={'flex'} gutter={16}>
            <Col xs={24} md={6}>
              <Form.Item label={'Sắp xếp theo'} name="sort_by">
                <Select
                  allowClear
                  onChange={handleSortByType}
                  placeholder={'Sắp xếp theo'}
                  showArrow>
                  <Option value={'nameUppercase'}>Họ tên</Option>
                  <Option value={'username'}>Tên đăng nhập</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label={'Thứ tự'} name="direction">
                <Select
                  allowClear
                  onChange={handleSortByDirection}
                  placeholder={'Lựa chọn'}
                  showArrow>
                  <Option value={'ASC'}>Tăng dần</Option>
                  <Option value={'DESC'}>Giảm dần</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label={'Trạng thái'} name="status">
                <Select
                  allowClear
                  onChange={handleFilterByStatus}
                  placeholder={'Trạng thái'}
                  showArrow>
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label={'Phòng ban'} name="department">
                <Select
                  allowClear
                  showSearch
                  onChange={handleFilterByDepartment}
                  placeholder={'Phòng ban'}
                  filterOption={true}
                  optionFilterProp={'name'}>
                  {departmentList.map(department => {
                    return (
                      <Option
                        key={department.code}
                        name={department.name}
                        value={department.code}>
                        {department.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FilterBoxWrapper>
      <div style={{ marginBottom: 15 }} />
    </Fragment>
  )
}

export default memo(
  inject(
    'userStore',
    'departmentStore',
    'loadingAnimationStore',
    'authenticationStore'
  )(observer(UserListFilterBlock))
)

import { Button, Modal, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { DocumentList } from '../../pages/MissionDetailPage/MissionDetailPageStyled'
import utils from '../../utils'
import EmptyContent from '../EmptyContent'

const IncompleteListModal = props => {
  const renderTaskListItem = taskItem => {
    if (!taskItem.noi_dung) {
      return 'Không có nội dung'
    }
    return utils.removeHtml(taskItem.noi_dung)
  }

  const renderIncompleteListItem = item => {
    if (props.listType === 'WORK')
      return (
        <Tooltip key={item.code} title={'Xem'}>
          <li
            onClick={() => {
              window.open(`works/view/${item.code}`, '_blank')
            }}>
            {item.title}
          </li>
        </Tooltip>
      )
    if (props.listType === 'TASK')
      return !item.isUser ? (
        <li
          style={{
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.85)',
            pointerEvents: 'none',
          }}>
          {renderTaskListItem(item)}
        </li>
      ) : null
  }

  return (
    <Modal
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <div>{props.modalTitle}</div>
          <div style={{ display: 'flex', gap: 10 }}>
            {props.listType === 'TASK' && (
              <Button
                onClick={() => {
                  window.open(`/mission`)
                }}>
                Xem tất cả nhiệm vụ
              </Button>
            )}
            <Button onClick={props.onClose} danger>
              Đóng
            </Button>
          </div>
        </div>
      }
      width={1300}
      style={{ top: 40 }}
      visible={props.isVisible}
      closable={false}
      maskClosable={false}
      onCancel={props.onClose}
      footer={null}>
      <DocumentList>
        {props.items.length > 0 ? (
          props.items.map(item => renderIncompleteListItem(item))
        ) : (
          <EmptyContent />
        )}
      </DocumentList>
    </Modal>
  )
}

export default inject('userStore')(observer(IncompleteListModal))
